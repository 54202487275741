import * as React from 'react';
import { useTable, Row, Column, usePagination } from 'react-table';
import { CSVLink } from 'react-csv';
import { Styles } from './styles';
import { ProvisionDataContext } from '../context/ProvisionDataContext';
import Pagination from '@components/ReactTable/Pagination';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Button, makeStyles } from '@material-ui/core';

const newStyles = {
  formControlMargins: {
    margin: '3px 0 !important',
  },
  gridContainer: {
    justifyContent: 'center',
  },
};

const useStyles = makeStyles(newStyles);

// Be sure to pass our updateMyData and the skipPageReset option
function Table({
  columns,
  data,
  updateMyData,
  skipPageReset,
  RenderDownloadBtn,
}: {
  columns: Column[];
  data: any;
  setData?: Function;
  updateMyData: Function;
  skipPageReset: boolean;
  RenderDownloadBtn: React.ElementType;
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 20, pageIndex: 0 },
    },
    usePagination
  );

  const classes = useStyles();

  // Render the UI for your table
  return (
    <Styles>
      <GridContainer className={classes.gridContainer}>
        <GridItem xs={12} sm={4} md={4}>
          <RenderDownloadBtn />
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <div>
            <div style={{ float: 'right', marginTop: 20 }}>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
            <Pagination
              visiblePages={[1]}
              onClick={gotoPage}
              pageIndex={pageIndex + 1}
              canPreviousPage={canPreviousPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
            />
          </div>
        </GridItem>
      </GridContainer>
      <table
        {...getTableProps()}
        className='rttable table sticky'
        style={{ width: '100%' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
}

const PreviewTable: React.FC<{ provision?: () => void }> = ({ provision }) => {
  const { data, setData, fileName } = React.useContext(ProvisionDataContext);
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'index',
      },
      {
        Header: 'Productitem OEM_SN',
        accessor: 'productitemoem_sn',
      },
      {
        Header: 'ProductItem PAYG_SN',
        accessor: 'productitempayg_sn',
      },
      {
        Header: 'lifeCycleStatus',
        accessor: 'lifecyclestatus',
      },
      {
        Header: 'Firmware Version',
        accessor: 'firmwareversion',
      },
      {
        Header: 'Product Model Name',
        accessor: 'productmodelname',
      },
      {
        Header: 'Product Batch Number',
        accessor: 'productbatchnumber',
      },
      {
        Header: 'product Batch ID',
        accessor: 'productbatchid',
      },
      {
        Header: 'PAYG_security_hash_top',
        accessor: 'hash_top',
      },
      {
        Header: 'PAYG_security_hash_root',
        accessor: 'hash_root',
      },
      {
        Header: 'otpGeneratorCurrent_Hash_Index',
        accessor: 'hash_index',
      },
      {
        Header: 'Last_Generated_Code',
        accessor: 'codevalue',
      },
      {
        Header: 'otpGeneratorCurrent_Count',
        accessor: 'otp_count',
      },
      {
        Header: 'product QID',
        accessor: 'product_qid',
      },
      {
        Header: 'Product Item ID',
        accessor: 'productitemid',
      },
      {
        Header: 'Angaza ID',
        accessor: 'angaza_id',
      },
    ],
    []
  );

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex: number, columnId: string, value: string) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    /// @ts-ignore
    const rows: Row[] = (old: Row<{}>[]) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }) as Row[];
    setData(rows as Row<{}>[]);
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const headers = [
    {
      label: 'productItemOEM_SN',
      key: 'productitemoem_sn',
    },
    {
      label: 'productItemPAYG_SN',
      key: 'productitempayg_sn',
    },
    {
      label: 'lifeCycleStatus',
      key: 'lifecyclestatus',
    },
    {
      label: 'firmwareVersion',
      key: 'firmwareversion',
    },
    {
      label: 'productModelName',
      key: 'productmodelname',
    },
    {
      label: 'productBatchNumber',
      key: 'productbatchnumber',
    },
    {
      label: 'product Batch ID',
      key: 'productbatchid',
    },
    {
      label: 'PAYG_security_hash_top',
      key: 'hash_top',
    },
    {
      label: 'PAYG_security_hash_root',
      key: 'hash_root',
    },
    {
      label: 'otpGeneratorCurrent_Hash_Index',
      key: 'hash_index',
    },
    {
      label: 'Last_Generated_Code',
      key: 'codevalue',
    },
    {
      label: 'otpGeneratorCurrent_Count',
      key: 'otp_count',
    },
    {
      label: 'product QID',
      key: 'product_qid',
    },
    {
      label: 'ProductItemID',
      key: 'productitemid',
    },
    {
      label: 'Angaza ID',
      key: 'angaza_id',
    },
  ];

  const RenderDownloadBtn = () => {
    return (
      <>
        <CSVLink
          className='btn btn-light'
          headers={headers}
          data={data}
          filename={(fileName || 'provision') + '.csv'}
          style={{ color: '#212121' }}
        >
          Export CSV
        </CSVLink>
      </>
    );
  };
  return (
    <Styles>
      <GridContainer className='container'>
        <GridItem sm={6} className='col-sm-6 float-left'></GridItem>
        <GridItem sm={6} className='col-sm-6 float-right'>
          {provision && (
            <Button
              color='inherit'
              size='small'
              type='button'
              className='btn btn-primary float-right'
              onClick={provision}
            >
              Provision
            </Button>
          )}
        </GridItem>
      </GridContainer>
      <div className='container overflow-auto' style={{ overflow: 'scroll' }}>
        <Table
          columns={columns}
          data={data}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          RenderDownloadBtn={RenderDownloadBtn}
        />
      </div>
    </Styles>
  );
};

export default PreviewTable;
