import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import { Formik, FormikProps } from 'formik';
import { resetDistributorValidationSchema } from 'admin/auth/validation/loginFormValidation';
import ResetForm, { IResetFormValues } from './ResetForm';
import { useNavigate, useParams } from 'react-router-dom';
import useResetPasswordToken from 'apps/clientService/app/distributor/hooks/useResetPasswordToken';

// @ts-ignore noqa:
const useStyles = makeStyles(styles);
interface IProps {
}
const ResetPasswordTokenPage = (props: IProps) => {
	const params: { token?: string } = useParams();
	const classes = useStyles();
	const history = useNavigate()
	const onComplete = () => {
		history('/auth/login-page')
	}
	const { mutation, mutationOpts } = useResetPasswordToken({ onComplete })

	return (
		<Formik
			onSubmit={(values) => {

				mutation({
					variables: {
						resetDistributorPasswordInput: {
							token: (params.token as string) || '',
							password: values.password || '',
						}

					}
				});
			}}
			initialValues={{
				password: '',
				confirmPassword: '',
				token: ''
			}}
			validationSchema={resetDistributorValidationSchema}
			validateOnChange={true}
		>
			{(formBag) => (
				<ResetForm
					classes={classes}
					// @ts-ignore
					formBag={formBag as FormikProps<IResetFormValues>}
					isLoading={mutationOpts.loading}
				/>
			)}
		</Formik>
	);
};

export default ResetPasswordTokenPage;
