import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { handleGQLErrors } from 'utils/gqlErrors';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';
import { SupplierContext } from '../context/SupplierProvider';
import {
  CreateSupplier,
  CreateSupplierVariables,
} from '../types/CreateSupplier';
import { createSupplierMutation } from '../mutations';
import clientClientService from '../../utils/clientClientService';
import { UpdateSupplierInput } from '../../types/clientServiceGlobalTypes';

const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const { refetchItems } = React.useContext(SupplierContext);

  const [createItem, createItemOpts] = useMutation<
    CreateSupplier,
    CreateSupplierVariables
  >(createSupplierMutation, {
    client: clientClientService,
    onCompleted: async (data) => {
      notify({
        status: 'success',
        text: 'Created successfully.',
      });
      refetchItems && refetchItems();
      history(itemRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleSubmit = (input: UpdateSupplierInput) => {
    const address = input.supplierAddress;
    createItem({
      variables: {
        createSupplierInput: {
          contactRole: input.contactRole || '',
          supplierAddress: {
            ...address,
            city: address?.city || '',
            country: address?.country || '',
            postcode: address?.postcode || '',
            srpc: address?.srpc || '',
            street: address?.street || '',
            unit: address?.unit || '',
            locationAddressDto: {
              inputAddressLatitude: address?.UpdateLocationAddressDto?.lat || 0,
              inputAddressLongitude:
                address?.UpdateLocationAddressDto?.lng || 0,
            },
          },

          supplierDescription: input.supplierDescription || '',
          supplierName: input.supplierName,
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
