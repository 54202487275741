import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import TitleCaseCell from '@components/ReactTable/TitleCaseCell';
import { Row } from 'react-table';
import SkuTableActions from '../components/TableActions';


export const itemTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <SkuTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },

    {
      Header: 'name',
      accessor: 'node.name',
      Cell: TitleCaseCell
    },
    {
      Header: 'Email',
      accessor: 'node.contact.email',
    },
    {
      Header: 'Phone',
      accessor: 'node.contact.phone',
    },
    {
      Header: 'Social',
      accessor: 'node.social',
    },

    {
      Header: 'type',
      accessor: 'node.type',
    },
    {
      Header: 'distributor',
      accessor: 'node.distributor.name',
    },
    {
      Header: 'Description',
      accessor: 'node.description',
      Cell: DescriptionCell,
    },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
    { Header: 'ID', accessor: 'node._id' },
  ],
});
