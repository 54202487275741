import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, {useEffect, useState } from 'react';
import { useLazyGetAllMessageTemplateQuery } from '../queries';
import { 
  GetAllMessageTemplates, 
  GetAllMessageTemplatesVariables, 
  GetAllMessageTemplates_getAllMessageTemplates_page_edges } from '../types/GetAllMessageTemplates';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';

export interface IMessageTemplateContext {
  items: GetAllMessageTemplates_getAllMessageTemplates_page_edges[];
  setItems: (
    itemSKUs: GetAllMessageTemplates_getAllMessageTemplates_page_edges[]
  ) => void;
  loading: boolean;
  refetchItems:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllMessageTemplates>>)
    | undefined;
  getItems: () => void;

  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
}

export const MessageTemplateContext = React.createContext<IMessageTemplateContext>(
  {} as IMessageTemplateContext
);

const MessageTemplateProvider: React.FC<IProps> = ({ children }) => {
  const [items, setItems] = React.useState<
  GetAllMessageTemplates_getAllMessageTemplates_page_edges[]
  >([] as GetAllMessageTemplates_getAllMessageTemplates_page_edges[]);
  const [loading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();


  const [
    getItems,
    { refetch: refetchItems, fetchMore, data, loading: dataLoading },
  ] = useLazyGetAllMessageTemplateQuery({
    first: pageListLimit,
  });

  useEffect(() => {
    if (data && !dataLoading) {
      setItems(data?.getAllMessageTemplates?.page?.edges || items);
      setPaging({
        ...data?.getAllMessageTemplates?.pageData,
        ...data?.getAllMessageTemplates?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [data]);
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }
  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllMessageTemplatesVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllMessageTemplates } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllMessageTemplates?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllMessageTemplates?.pageData,
        ..._data?.data?.getAllMessageTemplates?.page?.pageInfo,
      });
    }
  };

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    if (fetchMore) {
      const variables: GetAllMessageTemplatesVariables = {
        first: pageListLimit,
      };
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllMessageTemplates } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllMessageTemplates?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllMessageTemplates?.pageData,
        ..._data?.data?.getAllMessageTemplates?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading: loading || dataLoading,
      refetchItems,
      getItems,
      goTo,
      paging,
      setSearchQuery,
      searchQuery, setPageLimit,
    }), // eslint-disable-next-line
    [items, paging, searchQuery]
  );

  return (
    <MessageTemplateContext.Provider value={value}>
      {children}
    </MessageTemplateContext.Provider>
  );
};

export default MessageTemplateProvider;
