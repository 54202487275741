import { getToken } from 'admin/auth/utils';
import axios from 'axios';
import { ERM_API_ENDPOINT } from 'config';

export const ermAPI = axios.create({
    baseURL: ERM_API_ENDPOINT,
    timeout: 360000,
    headers: {
    //   'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()?.auth
    },
  });