import { defaultAddress } from '../../customer/constants';
import {
  ClientExclusiveServiceTypes,
  OrgTypes,
} from '../../types/clientServiceGlobalTypes';

const url = 'servicer';

export const itemRoutes = {
  list: `/admin/${url}`,
  assignDistributor: `/admin/${url}-assign-distributor`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};

export const updateItemFields = {
  contactRole: 'contactRole',
  servicerAddress: 'servicerAddress',
  servicerAddressLat: 'servicerAddress.UpdateLocationAddressDto.lat', 
  servicerAddressLng: 'servicerAddress.UpdateLocationAddressDto.lng',
  servicerAddressCity: 'servicerAddress.city',
  servicerAddressCountry: 'servicerAddress.country',
  servicerAddressPostcode: 'servicerAddress.postcode',
  servicerAddressSrpc: 'servicerAddress.srpc',
  servicerAddressStreet: 'servicerAddress.street',
  servicerAddressUnit: 'servicerAddress.unit' ,
  servicerContactPerson: 'servicerContactPerson',
  servicerContactPersonLat: 'servicerContactPerson.UpdateLocationAddressDto.lat',
  servicerContactPersonLng: 'servicerContactPerson.UpdateLocationAddressDto.lng',
  servicerContactPersonCity: 'servicerContactPerson.city',
  servicerContactPersonCountry: 'servicerContactPerson.country',
  servicerContactPersonPostcode: 'servicerContactPerson.postcode',
  servicerContactPersonSrpc: 'servicerContactPerson.srpc',
  servicerContactPersonStreet: 'servicerContactPerson.street',
  servicerContactPersonUnit: 'servicerContactPerson.unit',
  servicerContactPersonEmail: 'servicerContactPerson.contact.email',
  servicerContactPersonPhone: 'servicerContactPerson.contact.phone',
  servicerContactPersonSocial: 'servicerContactPerson.contact.social',
  servicerDescription: 'servicerDescription',
  servicerId: 'servicerId',
  servicerName: 'servicerName',
  servicerType: 'servicerType',
  email: 'servicerContactPerson.contact.email'
}

export const itemFields = {
  roleId: 'roleId',
  contactRole: 'contactRole',
  email: 'email',
  servicerDescription: 'servicerDescription',
  servicerName: 'servicerName',
  servicerType: 'servicerType',

  city: 'servicerAddress.city',
  country: 'servicerAddress.country',
  postcode: 'servicerAddress.postcode',
  srpc: 'servicerAddress.srpc',
  street: 'servicerAddress.street',
  unit: 'servicerAddress.unit',
  inputAddressLongitude:
    'servicerAddress.locationAddressDto.inputAddressLongitude',
  inputAddressLatitude:
    'servicerAddress.locationAddressDto.inputAddressLatitude',
};

export const defaultItem = {
  contactRole: 'Servicer',
  email: '',
  roleId: '',
  servicerAddress: defaultAddress,
  servicerDescription: 'Standard PAYG Distribution Established in 2021',
  servicerName: '',
  servicerType: OrgTypes.SERVICER,
};

export const orgTypesOptions = [
  { _id: OrgTypes.DISTRIBUTOR, name: OrgTypes.DISTRIBUTOR },
  { _id: OrgTypes.FINANCIAL, name: OrgTypes.FINANCIAL },
  { _id: OrgTypes.GOVERNMENT, name: OrgTypes.GOVERNMENT },
  { _id: OrgTypes.INSTITUTION, name: OrgTypes.INSTITUTION },
  { _id: OrgTypes.INVESTOR, name: OrgTypes.INVESTOR },
  { _id: OrgTypes.SERVICE, name: OrgTypes.SERVICE },
  { _id: OrgTypes.SERVICER, name: OrgTypes.SERVICER },
  { _id: OrgTypes.SUPPLIER, name: OrgTypes.SUPPLIER },
];

export const clientExclusiveServiceTypesOptions = [
  // {
  //   _id: ClientExclusiveServiceTypes.PAYGO,
  //   name: ClientExclusiveServiceTypes.PAYGO,
  // },
  {
    _id: ClientExclusiveServiceTypes.PAYMENTS,
    name: ClientExclusiveServiceTypes.PAYMENTS,
  }    
];
