import CardWrapper from "@components/Card/CardWrapper";
import GridContainer from "@components/Grid/GridContainer";
import GridItem from "@components/Grid/GridItem";
import React, { useMemo } from "react";

import {
  useUploadAuthentication,
  useUploadCodeEventsDockerStandalone,
  useUploadCodeSystemsDockerStandalone,
  useUploadCustomerDetailsDockerStandalone,
  useUploadDistributorDetailsDockerStandalone,
  useUploadItemBatchesDockerStandalone,
  useUploadItemFirmwaresDockerStandalone,
  useUploadItemFleetsDockerStandalone,
  useUploadItemsDockerStandalone,
  useUploadItemSKUsDockerStandalone,
  useUploadItemStocksDockerStandalone,
  useUploadRolesDockerStandalone,
  useUploadRolesPermissionsDockerStandalone,
} from "../hooks/useUploadCheckin";
import UploadFileComponent from "./UploadFileComponent";

interface IProps {}

const CheckIn: React.FC<IProps> = () => {
  const { mutation: uploadAuth, loading: loadingUploadAuth } =
    useUploadAuthentication();
  const { mutation: uploadCodeEvent, loading: loadingCodeEvent } =
    useUploadCodeEventsDockerStandalone();
  const { mutation: uploadCodeSystemMutation, loading: loadingCodeSystem } =
    useUploadCodeSystemsDockerStandalone();
  const { mutation: uploadCustomerDetails, loading: loadingCustomer } =
    useUploadCustomerDetailsDockerStandalone();
  const { mutation: uploadDistributor, loading: loadingDistributor } =
    useUploadDistributorDetailsDockerStandalone();
  const { mutation: uploadBatch, loading: loadingBatch } =
    useUploadItemBatchesDockerStandalone();
  const { mutation: uploadFirmware, loading: loadingFirmware } =
    useUploadItemFirmwaresDockerStandalone();
  const { mutation: uploadFleet, loading: loadingFleets } =
    useUploadItemFleetsDockerStandalone();
  const { mutation: uploadSku, loading: loadingSKU } =
    useUploadItemSKUsDockerStandalone();
  const { mutation: uploadStock, loading: loadingStock } =
    useUploadItemStocksDockerStandalone();
  const { mutation: uploadItems, loading: loadingItems } =
    useUploadItemsDockerStandalone();
  const { mutation: uploadRoles, loading: loadingRoles } =
    useUploadRolesDockerStandalone();
  const { mutation: uploadPerms, loading: loadingPerms } =
    useUploadRolesPermissionsDockerStandalone();

  const loading = useMemo(() => {
    return (
      loadingUploadAuth ||
      loadingCodeEvent ||
      loadingCodeSystem ||
      loadingCustomer ||
      loadingDistributor ||
      loadingBatch ||
      loadingFirmware ||
      loadingFleets ||
      loadingSKU ||
      loadingStock ||
      loadingItems ||
      loadingRoles ||
      loadingPerms
    );
  }, [
    loadingUploadAuth,
    loadingCodeEvent,
    loadingCodeSystem,
    loadingRoles,
    loadingPerms,
    loadingCustomer,
    loadingDistributor,
    loadingFirmware,
    loadingFleets,
    loadingSKU,
    loadingStock,
    loadingItems,
    loadingBatch,
  ]);
  return (
    <div style={{ zIndex: 4 }}>
      <GridContainer>
        <GridItem md={12}>
          <CardWrapper>
            <GridContainer>
              <UploadFileComponent
                title="Upload Authentication"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadAuth({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Code Events"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadCodeEvent({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Permissions"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadPerms({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Roles"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadRoles({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Items"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadItems({
                    variables: {
                      file,
                    },
                  });
                }}
              />

              <UploadFileComponent
                title="Upload Item Stocks"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadStock({
                    variables: {
                      file,
                    },
                  });
                }}
              />

              <UploadFileComponent
                title="Upload SKUs"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadSku({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Fleet"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadFleet({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Firmware"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadFirmware({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Batch"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadBatch({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Distributor Details"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadDistributor({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Customer Details"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadCustomerDetails({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Code System"
                loading={loading}
                onSubmit={(file: File) => {
                  uploadCodeSystemMutation({
                    variables: {
                      file,
                    },
                  });
                }}
              />
              <UploadFileComponent
                title="Upload Code Generators"
                loading={loading}
                onSubmit={(file: File) => {}}
              />
            </GridContainer>
          </CardWrapper>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CheckIn;
