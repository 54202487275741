import React from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldHookConfig, useField } from 'formik';
// @ts-ignore noqa:
const useStyles = makeStyles(styles);
interface IProps {
  selectOptions: { _id: string; name: string }[];
  selectTitle?: string;
  value?: string;
  label?: string;
  loading?: boolean;
  onChange: (e: any) => void;
  multiple?: boolean;
  name?: string;
}

const CustomSelect: React.FC<IProps & FieldHookConfig<any>> = ({
  selectOptions,
  selectTitle = 'Choose one',
  value,
  onChange,
  label,
  loading,
  multiple,
  ...rest
}) => {

  const classes = useStyles();
  // @ts-ignore
  const [field, meta] = useField(rest);
  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      {label && (
        <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
          {label}
        </InputLabel>
      )}
      <Select
        multiple={multiple}
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={value}
        onChange={onChange}
        inputProps={{
          name: multiple? 'multipleSelect': 'simpleSelect',
          id: multiple? 'multiple-select': 'simple-select',
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          {loading ? 'Loading...' : selectTitle}
        </MenuItem>
        {selectOptions &&
          selectOptions.map((item) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              style={{ textAlign: 'start' }}
              value={item._id}
              key={item._id}
            >
              {item.name}
            </MenuItem>
          ))}
      </Select>
      <FormControl>
        {meta.touched && meta.error ? (
          <div {...field} className='error-message'>
            {meta.error}
          </div>
        ) : null}
      </FormControl>
    </FormControl>
  );
};

export default CustomSelect;
