import React from 'react';
import { Formik, FormikProps } from 'formik';
// @material-ui/core components
import { withStyles, WithStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js';
import ResetPasswordForm, {
  IResetPasswordFormValues,
} from './ResetPasswordForm';

import { authFormFields } from 'admin/auth/constants/formFields';
import { resetPasswordSchema } from 'admin/auth/validation/loginFormValidation';
import useResetPassword from 'apps/clientService/app/distributor/hooks/useResetPassword';
import { useNavigate } from 'react-router-dom';

const ResetPasswordPage = ({ classes }: WithStyles) => {
  const history = useNavigate()
  const onComplete = () => {
    history('/auth/login-page')
  }
  const { mutation } = useResetPassword({ onComplete })
  return (
    <Formik
      onSubmit={async (values) => {
        await mutation({ variables: { email: values.email } })
      }} // TODO: implement reset password logic
      initialValues={{
        [authFormFields.login.email]: '',
      }}
      validationSchema={resetPasswordSchema}
      validateOnChange={true}
    >
      {(formBag) => (
        <ResetPasswordForm
          classes={classes}
          formBag={formBag as FormikProps<IResetPasswordFormValues>}
        />
      )}
    </Formik>
  );
};

export default withStyles(styles as any)(ResetPasswordPage as any) as any;
