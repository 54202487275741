import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext, useState } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useMutation } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import useNotifier from 'hooks/useNotifier';
import { useNavigate } from 'react-router-dom';
import { DeleteItem, DeleteItemVariables } from '../types/DeleteItem';
import { deleteItemMutation } from '../mutations';
import { itemsRoutes } from '../constants';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { ItemContext } from '../context/ItemProvider';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import CodeEventContainer from '../../codeEvent/containers/CodeEventContainer';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
import { CodeEventContext } from '../../codeEvent/context/CodeEventProvider';

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const CustomTableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();
  const { isDistributor } = useAuth();
  const { refetchItems: refetch } = useContext(ItemContext);
  const { setCodeEvents } = React.useContext(CodeEventContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [codeEventModalOpen, setCodeEventModalOpen] = useState(false)
  const [deleteItemSKU] = useMutation<DeleteItem, DeleteItemVariables>(
    deleteItemMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: 'success',
          text: 'Item deleted successfully',
        });
        refetch && refetch();
        setIsModalOpen(false);
      },
    }
  );

  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      {!isDistributor ? (
        <>
          <Tooltip
            id='expander'
            onClick={() => {
              history(itemsRoutes.getEditLink(row.values['node._id']));
            }}
            title='Edit'
            placement='top'
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              id='expander'
              aria-label='Edit'
              className={classes.tableActionButton}
            >
              <Edit
                className={classes.tableActionButtonIcon + ' ' + classes.edit}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title='Delete'
            placement='top'
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              id='expander'
              aria-label='Delete'
              className={classes.tableActionButton}
              onClick={() => setIsModalOpen(true)}
            >
              <Delete
                className={classes.tableActionButtonIcon + ' ' + classes.edit}
              />
            </IconButton>
          </Tooltip>

        </>
      ) : null}
      <HoverDropdown
        menus={[
          {
            name: 'Code History',
            action: () => {
              setCodeEvents(row.values['node.codeGenerator.codeHistory'])
              setCodeEventModalOpen(true);
            },
          },
        ]} />
      {isModalOpen ? (<ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deleteItemSKU({ variables: { itemId: row.values['node._id'] } })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this item?</div>
      </ActionModal>) : null}
      <DrawerSidebar
        isModalOpen={codeEventModalOpen}
        toggleModal={setCodeEventModalOpen}
        title='Code Events'
        anchor="left"
        styles={{ minWidth: '80vw' }}
      >
        {codeEventModalOpen && <CodeEventContainer />}
      </DrawerSidebar>
    </div>
  );
};

export default CustomTableActions;
