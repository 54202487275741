import CardGridLoader from '@components/Loaders/CardGridLoader';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { UpdateSignalInput } from '../../types/thingsGlobalTypes';
import AddItem from '../components/AddItem';
import { itemRoutes } from '../constants';
import { SignalContext } from '../context/SignalProvider';
import useUpdateSignal from '../hooks/useUpdateSignal';

import { useGetSpecificSignalQuery } from '../queries';
import { Signal } from '../types/Signal';

const EditItemContainer = () => {
  const { itemId='' } = useParams();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(SignalContext);

  const [item, setItem] = React.useState({} as Signal);

  const { data, loading } = useGetSpecificSignalQuery({ id: itemId });

  useEffect(() => {
    setItem({
      ...(data?.getSpecificSignal || ({} as Signal)),
    }); // eslint-disable-next-line
  }, [data]);

  const { mutation, mutationOpts } = useUpdateSignal({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateSignalInput) => {
    mutation({
      variables: {
        updateSignalInput: {
          signalId: itemId,
          jmp: input.jmp,
          time: input.time,
          publisher: input.publisher
        },
      },
    });
  };

  if (!item?._id || loading) return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleSubmit}
      isLoading={mutationOpts.loading}
      item={{
        signalId: item?._id,
        publisher: item?.publisher?._id || '',
        jmp: JSON.stringify(item.jmp),
        time: item?.time,
      }}
    />
  );
};

export default EditItemContainer;
