import React from "react";

// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import { Formik, FormikProps } from "formik";
import CardWrapper from "@components/Card/CardWrapper";
import { codeGeneratorFields } from "../constants";
import { createCodeGeneratorFieldsValidationSchema } from "apps/things/app/validations";
import CodeGenerationRequestForm from "./CodeGenerationRequestForm";
import { InitializeCodeGenInput } from "apps/things/app/types/thingsGlobalTypes";

interface IRTable {
  handleDaysCode: (days: number) => void;
  handleSendSms: (telephone: string, message:string) => void;
  itemId?: string;
  isLoading: boolean;
}

const AddCodeGenerationRequest: React.FC<IRTable> = ({
  isLoading,
  itemId,
  ...rest
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) => {}}
            initialValues={{
              [codeGeneratorFields.codeSystemId]: "",
              [codeGeneratorFields.hashRoot]: "",
              [codeGeneratorFields.hashTop]: "",
              [codeGeneratorFields.hashIndex]: 100000,
              [codeGeneratorFields.codeReversalCount]: 0,
              [codeGeneratorFields.codeGenId]: "",
              [codeGeneratorFields.codeCount]: 0,
              [codeGeneratorFields.itemId]: itemId,
              [codeGeneratorFields.oemItemID]: "",
            }}
            validationSchema={createCodeGeneratorFieldsValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <CodeGenerationRequestForm
                formBag={
                  formBag as unknown as FormikProps<InitializeCodeGenInput>
                }
                loading={isLoading}
                {...rest}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddCodeGenerationRequest;
