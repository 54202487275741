import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import { QueryOrder } from 'apps/things/app/types/thingsGlobalTypes';
import clientSimulator from 'utils/clientSimulator';
import { GetAllOrganizationsResponse_page_edges } from '../types/GetAllOrganizationsResponse';
import { useLazyGetAllOrganizationsQuery } from '../queries';
import {
  DeleteOrganization,
  DeleteOrganizationVariables,
} from '../types/DeleteOrganization';
import { GetAllOrganizations, GetAllOrganizationsVariables } from '../types/GetAllOrganizations';
import { deleteOrganizationMutation } from '../mutations';
import useOVSPagination from 'hooks/useOVSPagination';
import { funNumberAgr } from '@types';
export interface IOganizationContext {
  items: GetAllOrganizationsResponse_page_edges[];
  setItems: (itemSKUs: GetAllOrganizationsResponse_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllOrganizations>>)
    | undefined;
  setPageLimit: funNumberAgr;
  paging: any;
  goTo: (nextPrev: boolean) => void;
  
}


export const OrganizationContext = React.createContext<IOganizationContext>(
  {} as IOganizationContext
);

const OrganizationProvider: React.FC<IProps> = ({ children }) => {
  const [items, setItems] = React.useState<
    GetAllOrganizationsResponse_page_edges[]
  >([] as GetAllOrganizationsResponse_page_edges[]);

  const { setTableActionLoading } = useContext(GlobalSimulatorContext);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  /// hooks
  const notify = useNotifier();

  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,
    setPageListLimit,
    pageListLimit
  } = useOVSPagination();
  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllOrganizationsQuery({
    first: pageListLimit,
  });

  useEffect(() => {
    setItems(data?.getAllOrganizations?.page?.edges || []);
    setPaging({
      ...data?.getAllOrganizations?.pageData,
      ...data?.getAllOrganizations?.page?.pageInfo,
    }); // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllOrganizationsVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllOrganizations?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllOrganizations?.pageData,
        ..._data?.data?.getAllOrganizations?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const [deleteItem] = useMutation<
    DeleteOrganization,
    DeleteOrganizationVariables
  >(deleteOrganizationMutation, {
    client: clientSimulator,
    onCompleted: (data) => {},
  });

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            organizationId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
            queryorder: QueryOrder.DESC,
          });
          setItems(
            itemsFetched?.data?.getAllOrganizations?.page?.edges || items
          );
          setTableActionLoading(false);
        } else {
          getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
            queryorder: QueryOrder.DESC,
          });
          setItems(
            itemsFetched?.data?.getAllOrganizations?.page?.edges || items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      getItems,
      refetchItems,
      setPageLimit,
      paging,
      goTo,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} organization(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading]
  );

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
