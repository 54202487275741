import { gql } from '@apollo/client';

/**
 * assignClientExclusiveServicePermissions(
assignClientExclusiveServicePermissionToClientExclusiveServiceInput: AssignClientExclusiveServicePermissionToClientExclusiveServiceInput!
): Success!
 */
export const assignClientExclusiveServicePermissionsMutation = gql`
  mutation AssignClientExclusiveServicePermissions(
    $assignClientExclusiveServicePermissionToClientExclusiveServiceInput: AssignClientExclusiveServicePermissionToClientExclusiveServiceInput!
  ) {
    assignClientExclusiveServicePermissions(
      assignClientExclusiveServicePermissionToClientExclusiveServiceInput: $assignClientExclusiveServicePermissionToClientExclusiveServiceInput
    ) {
      message
      status
    }
  }
`;

/**
 * deleteClientExclusiveServicePermissions(
ClientExclusiveServicePermissionsId: String!
): String!
 */
export const deleteClientExclusiveServicePermissionsMutation = gql`
  mutation DeleteClientExclusiveServicePermissions(
    $ClientExclusiveServicePermissionsId: String!
  ) {
    deleteClientExclusiveServicePermissions(
      ClientExclusiveServicePermissionsId: $ClientExclusiveServicePermissionsId
    )
  }
`;
