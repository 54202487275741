import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import { CreateSignal, CreateSignalVariables } from 'apps/events/app/signal/types/CreateSignal';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { createSignalMutation } from '../mutations';

const useCreateSignal = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    CreateSignal,
    CreateSignalVariables
  >(createSignalMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Created successfully', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useCreateSignal;
