import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';


import useOVSPagination from 'hooks/useOVSPagination';
import { useLazyGetAllClientExclusiveServicesForServicerQuery } from '../queries';
import { funNumberAgr } from '@types';
import { GetAllClientExclusiveServiceResponse_page_edges } from '../types/GetAllClientExclusiveServiceResponse';
import { PAGE_LIST_LIMIT } from '@constants';
import { GetAllClientExclusiveServicesForServicer, GetAllClientExclusiveServicesForServicerVariables, GetAllClientExclusiveServicesForServicer_getAllClientExclusiveServicesForServicer_page_edges } from '../types/GetAllClientExclusiveServicesForServicer';
import { GetAllClientExclusiveServicesVariables } from '../types/GetAllClientExclusiveServices';
import { useAuth } from 'admin/auth/context/AuthProvider';

export interface IClientExclusiveServicerContext {
  items: GetAllClientExclusiveServicesForServicer_getAllClientExclusiveServicesForServicer_page_edges[];
  setItems: (item: GetAllClientExclusiveServicesForServicer_getAllClientExclusiveServicesForServicer_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  activeStage: number;
  setActiveStage: (stage: number) => void;
  tableActions: ITableActions[];
  refetchItems: ((variables?: Partial<GetAllClientExclusiveServicesForServicerVariables> | undefined) => Promise<ApolloQueryResult<GetAllClientExclusiveServicesForServicer>>) | undefined
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setPageLimit: funNumberAgr
}

export const ClientExclusiveServiceContext = React.createContext(
  {} as IClientExclusiveServicerContext
);

const ClientExclusiveServicerProvider: React.FC<IProps> = ({ children }) => {
  const [activeStage, setActiveStage] = useState(0);
  const [item, setItem] = React.useState(
    {} as GetAllClientExclusiveServicesForServicer_getAllClientExclusiveServicesForServicer_page_edges
  );
  const [items, setItems] = React.useState<
  GetAllClientExclusiveServiceResponse_page_edges[]
  >([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const {userPref} = useAuth()

  /// hooks
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,pageListLimit, setPageListLimit
  } = useOVSPagination();

  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllClientExclusiveServicesForServicerQuery({
    first: PAGE_LIST_LIMIT,
    servicerId: userPref._id
  });

  useEffect(() => {
    setItems(data?.getAllClientExclusiveServicesForServicer?.page?.edges || []);
    setPaging({
      ...data?.getAllClientExclusiveServicesForServicer?.pageData,
      ...data?.getAllClientExclusiveServicesForServicer?.page?.pageInfo,
    });
    // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllClientExclusiveServicesVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllClientExclusiveServicesForServicer?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllClientExclusiveServicesForServicer?.pageData,
        ..._data?.data?.getAllClientExclusiveServicesForServicer?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const value = React.useMemo(
    () => ({
      item,
      setItem,
      items,
      setItems,
      loading: loading,
      getItems: getItems,
      refetchItems,
      tableActions: [],
      activeStage,
      setActiveStage,
      paging,
      goTo, setPageLimit
    }), // eslint-disable-next-line
    [items, loading, paging]
  );

  return (
    <ClientExclusiveServiceContext.Provider value={value}>{children}</ClientExclusiveServiceContext.Provider>
  );
};

export default ClientExclusiveServicerProvider;
