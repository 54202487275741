import React from 'react';
// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';

import { addLegacyFamilyValidationSchema as validationSchema } from 'apps/things/app/validations';
import ItemForm from './LegacyForm';
import CardWrapper from '@components/Card/CardWrapper';
import { IProductFamily as ItemType } from '../types';

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: ItemType) => void;
  isLoading: boolean;
  initialValues: ItemType;
}

const LegacyAddItem: React.FC<IRTable> = ({
  handleSubmit,
  isLoading,
  initialValues,
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleSubmit(
                {
                  productCategoryID: values.productCategoryID,
                  productFamilyName: values.productFamilyName,
                  productFamilyDescription: values.productFamilyDescription,
                  image: values.image,
                  productFamilyID: values.productFamilyID
                }
              )
            }
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemForm
                formBag={(formBag as unknown) as FormikProps<ItemType>}
                disableLoginButton={isLoading}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default LegacyAddItem;
