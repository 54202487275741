import { gql, useLazyQuery } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import {
  GetAllClientExclusiveServicePermissions,
  GetAllClientExclusiveServicePermissionsVariables,
} from './types/GetAllClientExclusiveServicePermissions';

/**
 * type ClientExclusiveServicePermissions {
_id: ID!
authenticationInstance: AuthenticationInstance
clientExclusiveService: ClientExclusiveService
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
distributor: Distributor
distributorId: Distributor
name: PermissionInput
updatedAt: DateTime
}
 */
const clientExclusiveServicePermissionsFragment = gql`
  fragment ClientExclusiveServicePermissions on ClientExclusiveServicePermissions {
    _id
    authenticationInstance {
      _id
      name
    }
    clientExclusiveService {
      _id
      servicer {
        _id
        name
      }
    }
    createdAt
    deleteAt
    deleteStatus
    distributor {
      _id
      orgContactPerson {
        _id
        contact {
          email
          phone
          social
        }
      }
    }
    name
    updatedAt
  }
`;
/**
 * type ClientExclusiveServicePermissionsEdge {
cursor: String
node: ClientExclusiveServicePermissions
}
 */
const clientExclusiveServicePermissionsEdgeFragment = gql`
  ${clientExclusiveServicePermissionsFragment}
  fragment ClientExclusiveServicePermissionsEdge on ClientExclusiveServicePermissionsEdge {
    cursor
    node {
      ...ClientExclusiveServicePermissions
    }
  }
`;
/**
 * type ClientExclusiveServicePermissionsConnection {
edges: [ClientExclusiveServicePermissionsEdge!]
pageInfo: ClientExclusiveServicePermissionsPageInfo
}
 */
const clientExclusiveServicePermissionsConnectionFragment = gql`
  ${clientExclusiveServicePermissionsEdgeFragment}
  fragment ClientExclusiveServicePermissionsConnection on ClientExclusiveServicePermissionsConnection {
    edges {
      ...ClientExclusiveServicePermissionsEdge
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
`;
/**type GetAllClientExclusiveServicePermissionsResponse {
page: ClientExclusiveServicePermissionsConnection!
pageData: PageData
} */
const getAllClientExclusiveServicePermissionsResponseFragment = gql`
  ${clientExclusiveServicePermissionsConnectionFragment}
  fragment GetAllClientExclusiveServicePermissionsResponse on GetAllClientExclusiveServicePermissionsResponse {
    page {
      ...ClientExclusiveServicePermissionsConnection
    }
    pageData {
      count
      limit
      offset
    }
  }
`;
/**
 * 
 * getAllClientExclusiveServicePermissionsForClientExclusivePermission(
after: String
before: String
clientExclusiveServiceId: ID!
first: Int
last: Int
): GetAllClientExclusiveServicePermissionsResponse!
 */
export const getAllClientServicePermissionsQuery = gql`
  ${getAllClientExclusiveServicePermissionsResponseFragment}
  query getAllClientExclusiveServicePermissionsForClientExclusivePermission(
    $after: String
    $before: String
    $clientExclusiveServiceId: ID!
    $first: Int
    $last: Int
  ) {
    getAllClientExclusiveServicePermissionsForClientExclusivePermission(
      after: $after
      before: $before
      clientExclusiveServiceId: $clientExclusiveServiceId
      first: $first
      last: $last
    ) {
      ...GetAllClientExclusiveServicePermissionsResponse
    }
  }
`;
/**
 * getAllClientExclusiveServicePermissions(
after: String
before: String
first: Int
last: Int
):
 */
const getAllClientExclusiveServicePermissionsQuery = gql`
  ${getAllClientExclusiveServicePermissionsResponseFragment}
  query GetAllClientExclusiveServicePermissions(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllClientExclusiveServicePermissions(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...GetAllClientExclusiveServicePermissionsResponse
    }
  }
`;

export const useLazyGetAllClientServicePermissionsQuery = (
  variables: GetAllClientExclusiveServicePermissionsVariables
) =>
  useLazyQuery<
    GetAllClientExclusiveServicePermissions,
    GetAllClientExclusiveServicePermissionsVariables
  >(getAllClientExclusiveServicePermissionsQuery, {
    client: clientSimulator,
    variables,
  });

/**
 * getSpecificClientExclusiveServicePermissions(
id: ID!
): ClientExclusiveServicePermissions
 */
export const getSpecificClientExclusiveServicePermissionsFragment = gql`
  query GetSpecificClientExclusiveServicePermissions($id: ID!) {
    getSpecificClientExclusiveServicePermissions(id: $id) {
      ...ClientExclusiveServicePermissions
    }
  }
`;
