import { gql } from '@apollo/client';

/**
 * type PageData {
count: Float!
limit: Float!
offset: Float!
}
 */
export const pageDataFragment = gql`
  fragment PageData on PageData {
    count
    limit
    offset
  }
`;

/**
 * type Success {
message: String!
status: Float!
}
 */
export const successFragment = gql`
  fragment Success on Success {
    message
    status
  }
`;
