import { PAGE_LIST_LIMIT } from '@constants';
import { isEmptyArray } from 'formik';
import { useEffect, useMemo } from 'react';
import {  useLazyGetAllRolesQuery } from '../queries';
import * as _ from 'lodash'
import { GetAllRoles } from '../types/GetAllRoles';

export interface IOptions {name: string, value: string}
export const useRoles = (roleName='') => {
  const [getItems, { data, loading, refetch }] = useLazyGetAllRolesQuery();

  useEffect(() => {
    if (isEmptyArray(data)) {
      getItems();
    } // eslint-disable-next-line
  }, []);
  const getData = (data: GetAllRoles | undefined) => {
    const _options  = _.map(_.get(data, 'getAllRoles.page.edges'), (it => ({

           name: _.get(it, 'node.name', ''),
           _id: _.get(it, 'node._id', ''),
          }))) || [] as IOptions[]
    if (roleName) {
      
        const filtedOptions  =  _.filter(_options, (it) => it.name === roleName) || []
        return filtedOptions
    }
    return _options
  }
  const search = async (search: string) => {
    if (!refetch) {      
        return Promise.resolve(
          getData(data)
        )     
    }
    const refetchData = await refetch({
      first: PAGE_LIST_LIMIT,
    });
    return Promise.resolve(getData(refetchData?.data));
  };

  return useMemo(
    () => ({
      data,
      getItems,
      loading,
      search,
      options: getData(data),
    }), // eslint-disable-next-line
    [data, loading]
  );
};

export default useRoles;
