import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import WallpaperIcon from '@material-ui/icons/Wallpaper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  })
);
interface IProps {
  value: string[];
}

const ImageCell: React.FC<IProps> = ({ value }) => {
  const classes = useStyles();

  if (typeof value === 'string' || !value) {
    if (!value) return <WallpaperIcon fontSize='large' color="disabled" />;
    return (
      <Avatar
        alt={value}
        src={value}
        className={classes.large}
        variant='square'
      />
    );
  }

  return (
    <div>
      {value.map((item, index) => {
        if (index !== 0) return null;
        return (
          <Avatar
            key={item + '-' + index}
            alt={item}
            src={item}
            className={classes.large}
            variant='square'
          />
        );
      })}
    </div>
  );
};

export default ImageCell;
