import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';

interface IProps {
  assetAccountId?: string;
  active: boolean;
}

const AssetPayComplete: React.FC<IProps> = ({active}) => {


  return (
    <GridContainer>
      <GridItem md={12}>

      </GridItem>
    </GridContainer>
  );
};

export default AssetPayComplete;
