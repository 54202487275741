import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/itemTableData';
import CardWrapper from '@components/Card/CardWrapper';
import { itemRoutes } from '../constants';
import { FleetContext } from '../context/FleetProvider';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { ItemContext } from 'apps/things/app/item/context/ItemProvider';
import DefaultModal from '@components/Modal/DefaultModal';
import DistributorItemsPage from 'apps/things/app/item/components/DistributorItemsPage';
import useServicerPermission from 'hooks/useServicerPermission';

interface IRTable { }

const ItemPage: React.FC<IRTable> = () => {
  const { isDistributor } = useAuth();
  const {
    items,
    tableActions,
    getItems,
    getDistributorItems,
    distributorItems,
    goTo,
    paging,
    setSearchQuery,
    searchQuery,
    loading, setPageLimit, clientError
  } = useContext(FleetContext);
  const { isModalOpen, setIsModalOpen, itemFleetId } = useContext(ItemContext);

  useEffect(() => {
    if (isDistributor) {
      getDistributorItems();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isDistributor) return;
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);

  useServicerPermission({ loading, clientError })
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              itemTableData().header as Column[]
            }
            data={!isDistributor ? items : distributorItems}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={itemRoutes.add}
            addItemBtnText='Add'
            tableActions={tableActions}
            hideRightToolbar={isDistributor}
            goTo={goTo}
            paging={paging}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
      <DefaultModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() => { }}
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        {itemFleetId && <DistributorItemsPage />}
      </DefaultModal>
    </GridContainer>
  );
};

export default ItemPage;
