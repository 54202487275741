import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import { Formik, FormikProps } from 'formik';
import CodeGenSettingsForm from './CodeGeneratorSettingsForm';
import CardWrapper from '@components/Card/CardWrapper';
import { UpdateDistributorSettingInput } from '../../types/clientServiceGlobalTypes';
import { DistributorSetting } from '../types/DistributorSetting';
interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: UpdateDistributorSettingInput) => void;
  isLoading: boolean;
  item: DistributorSetting;
}

const EditCodeGeneratorSetting: React.FC<IRTable> = ({ handleSubmit, isLoading, item }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
            { item._id && 
            <Formik
              onSubmit={async (values: any) => handleSubmit(values)}
              initialValues={item}
              validateOnChange={true}
            >
              {(formBag) => (
                <CodeGenSettingsForm
                  formBag={
                    (formBag as unknown) as FormikProps<DistributorSetting>
                  }
                  disableLoginButton={isLoading}
                />
              )}
            </Formik>
}
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default EditCodeGeneratorSetting;
