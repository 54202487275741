import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Row } from "react-table";
import { RoleContext } from "admin/Role/context/RoleContext";

import {
  ExpansionPanelActions,
  ExpansionPanelDetails,
} from "@material-ui/core";
import useNotifier from "hooks/useNotifier";

import { PermissionInput } from "admin/types/globalTypes";
import _ from "lodash";
import { useAnyMutation } from "hooks/useAnyMutation";
import {
  assignClientExclusiveServicePermissionsMutation,
  deleteClientExclusiveServicePermissionsMutation,
} from "../mutations";
import { useAuth } from "admin/auth/context/AuthProvider";
import { ClientServiceContext } from "../../clientService/context/ClientServiceProvider";
import TableLoader from "@components/Loaders/TableLoader";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 300,
      height: 440,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: string[], b: string[]) {
  return [...a, ...not(b, a)];
}

interface IProps {
  row: Row;
  clientExclusiveServiceId: string;
  distributorId: string;
  permissionsRoleId: string;
}

const PermissionsTransfer: React.FC<IProps> = ({
  row,
  clientExclusiveServiceId,
  distributorId,
  permissionsRoleId,
}) => {
  const { specificRolePermissions, getSpecificRolePermissions } =
    React.useContext(RoleContext);
  const { refetchItems } = useContext(ClientServiceContext);

  useEffect(() => {
    if (specificRolePermissions.length === 0) {
      getSpecificRolePermissions({
        variables: {
          first: 100,
          getSpecificRolePermissionsInput: {
            roleId: permissionsRoleId,
            authenticationInstanceId: _.get(
              userPref,
              "authenticationInstance._id"
            ),
          },
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const notify = useNotifier();
  const {
    mutation: updatePermissions,
    called,
    error,
  } = useAnyMutation(assignClientExclusiveServicePermissionsMutation);
  useEffect(() => {
    if (called) {
      if (error) {
        return notify({
          status: "error",
          text: JSON.stringify(error),
        });
      }
      refetchItems && refetchItems();
      notify({
        status: "success",
        text: "Role/Permissions updated successfully.",
      });
    } // eslint-disable-next-line
  }, [called]);

  const permissions = useMemo(() => {
    return _.map(specificRolePermissions, (item) => ({
      id: item.node?.permission?._id,
      name: item.node?.permission?.name,
    }));
  }, [specificRolePermissions]);

  useEffect(() => {
    setLeft(
      (permissions &&
        permissions
          ?.filter(
            (it) =>
              !row.values["node.clientExclusiveServicePermissions"]
                ?.map((_it: any) => _it?.permission?._id)
                .includes(it.id)
          )
          ?.map((it) => it.id || "")) ||
        []
    ); // eslint-disable-next-line
  }, [permissions]);

  const classes = useStyles();
  const [checked, setChecked] = React.useState<string[]>([]);
  const [left, setLeft] = React.useState<string[]>(
    (permissions &&
      permissions
        ?.filter(
          (it) =>
            !row.values["node.clientExclusiveServicePermissions"]
              ?.map((_it: any) => _it?.permission?._id)
              .includes(it.id)
        )
        ?.map((it) => it.id || "")) ||
      []
  );

  const [right, setRight] = React.useState<string[]>(
    row.values["node.clientExclusiveServicePermissions"]?.map((item: any) =>
      _.get(item, "permission._id")
    ) || []
  );

  const getName = useCallback(
    (id: string) => {
      // @ts-ignore-next-line
      const item = permissions?.find((i) => i?.id === id);
      if (item) return item.name || id;
      return "" || id;
    }, // eslint-disable-next-line
    [permissions]
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: string[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const { userPref } = useAuth();
  const { mutation: deleteRolePermMutation } = useAnyMutation(
    deleteClientExclusiveServicePermissionsMutation
  );

  const handleUpdate = async () => {
    // keep track of removed permissions
    if (row.values["node.clientExclusiveServicePermissions"]?.length) {
      const removedIds = _.filter(
        row.values["node.clientExclusiveServicePermissions"],
        (it) => !right?.includes(it?.permission?._id)
      )?.map((it: any) => it?._id);
      removedIds?.map((it: any) => {
        return deleteRolePermMutation({
          variables: {
            ClientExclusiveServicePermissionsId: it,
          },
        });
      });
    }

    await updatePermissions({
      variables: {
        assignClientExclusiveServicePermissionToClientExclusiveServiceInput: {
          distributorId,
          clientExclusiveServiceId,
          permissions: permissions?.filter(it => {
            return it.name
          })
            ?.filter((it) => right.includes(it?.id || ""))
            .map((it) => it.name) as PermissionInput[],
        },
      },
    });
  };

  const customList = (title: React.ReactNode, items: string[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={getName(value)} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  if (!permissions.length) {
    return <TableLoader colSpan={10} />;
  }

  return (
    <React.Fragment>
      <ExpansionPanelDetails>
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList("Available Permissions", left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList("Selected Permissions", right)}</Grid>
        </Grid>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button size="small">Cancel</Button>
        <Button
          size="small"
          color="primary"
          onClick={() => {
            handleUpdate();
          }}
        >
          Save
        </Button>
      </ExpansionPanelActions>
    </React.Fragment>
  );
};

export default PermissionsTransfer;
