import { ApolloProvider } from '@apollo/client';
import MessageManagerProvider from '@components/messages';
import AuthProvider from 'admin/auth/context/AuthProvider';
import ProductProvider from 'admin/product/context/ProductContext';
import React from 'react';
import AppRoutes from 'routes';
import client from 'utils/client';
import { SnackbarProvider } from 'notistack';
import { SettingsProvider } from 'admin/settings/context/SettingsContext';
import RoleProvider from 'admin/Role/context/RoleContext';
import { getToken } from 'admin/auth/utils';
import SimulatorAppProvider from 'apps/things/app/provider/SimulatorAppProvider';
import AgentProvider from 'admin/agents/context/AgentContext';
import DistributorProvider from 'admin/distributor/context/DistributorProvider';
import ProvisionDataProvider from 'apps/migration/app/provision/context/ProvisionDataContext';
import EcommerceProvider from 'apps/ecommerce/app/providers/EcommerceProvider';
import LegacyProvider from 'apps/migration/app/providers/LegacyProviders';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventAppProvider from 'apps/events/app/providers/EventAppProvider';

const AppContext: React.FC = () => {
  if (!getToken().auth) {
    // get load dashboard context data if user is not logged in
    return (
      <ApolloProvider client={client}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider dense>
            <MessageManagerProvider>
              <AuthProvider>
                <AppRoutes />
              </AuthProvider>
            </MessageManagerProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    );
  }
  return (
    <ApolloProvider client={client}>
      <SnackbarProvider dense>
        <MessageManagerProvider>
          <AuthProvider>
            <ProductProvider>
              <SettingsProvider>
                <AgentProvider>
                  <DistributorProvider>
                    <RoleProvider>
                      <SimulatorAppProvider>
                        <EcommerceProvider>
                          <ProvisionDataProvider>
                            <LegacyProvider>
                              <EventAppProvider>
                                <AppRoutes />
                              </EventAppProvider>
                            </LegacyProvider>
                          </ProvisionDataProvider>
                        </EcommerceProvider>
                      </SimulatorAppProvider>
                    </RoleProvider>
                  </DistributorProvider>
                </AgentProvider>
              </SettingsProvider>
            </ProductProvider>
          </AuthProvider>
        </MessageManagerProvider>
      </SnackbarProvider>
    </ApolloProvider>
  );
};

export default AppContext;
