import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { Row } from 'react-table';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';

import { useEffect } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { useLazyGetAllBrandsQuery } from '../queries';
import { useDeleteBrand } from '../mutations';
import {
  GetAllBrands,
  GetAllBrandsVariables,
  GetAllBrands_getAllBrands_page_edges,
} from '../types/GetAllBrands';

export interface IBrandContext {
  items: GetAllBrands_getAllBrands_page_edges[];
  setItems: (items: GetAllBrands_getAllBrands_page_edges[]) => void;
  loading: boolean;
  tableActions: ITableActions[];
  refetch:
    | ((
        variables?: Partial<GetAllBrandsVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllBrands>>)
    | undefined;
  getItems: () => void;
}

export const BrandContext = React.createContext<IBrandContext>(
  {} as IBrandContext
);

const BrandProvider: React.FC<IProps> = ({ children }) => {
  // state
  const [items, setItems] = React.useState<
    GetAllBrands_getAllBrands_page_edges[]
  >([] as GetAllBrands_getAllBrands_page_edges[]);

  // context
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  // hooks
  const notify = useNotifier();
  const [getItems, { data, refetch, loading }] = useLazyGetAllBrandsQuery({
    first: 100,
  });

  useEffect(() => {
    if (data) setItems(data?.getAllBrands?.page?.edges || []);
  }, [data]);

  const { deleteBrand } = useDeleteBrand(() => {});

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteBrand({
          variables: {
            brandId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Brand deleted successfully',
        });
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllBrands?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllBrands?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      refetch,
      getItems,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} brand(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading]
  );

  return (
    <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
  );
};

export default BrandProvider;
