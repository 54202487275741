import { gql, useLazyQuery } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import { getAllClientExclusiveServiceResponseFragment } from '../clientService/queries';
import {
  GetAllClientExclusiveServicesForServicer,
  GetAllClientExclusiveServicesForServicerVariables,
} from './types/GetAllClientExclusiveServicesForServicer';

/**
 * getAllClientExclusiveServicesForServicer(
before: String
after: String
first: Int
last: Int
servicerId: String!
): GetAllClientExclusiveServiceResponse!
 */
const getAllClientExclusiveServicesForServicerQuery = gql`
  ${getAllClientExclusiveServiceResponseFragment}
  query GetAllClientExclusiveServicesForServicer(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $servicerId: String!
  ) {
    getAllClientExclusiveServicesForServicer(
      before: $before
      after: $after
      first: $first
      last: $last
      servicerId: $servicerId
    ) {
      ...GetAllClientExclusiveServiceResponse
    }
  }
`;

export const useLazyGetAllClientExclusiveServicesForServicerQuery = (
  variables: GetAllClientExclusiveServicesForServicerVariables
) =>
  useLazyQuery<
    GetAllClientExclusiveServicesForServicer,
    GetAllClientExclusiveServicesForServicerVariables
  >(getAllClientExclusiveServicesForServicerQuery, {
    variables,
    client: clientSimulator,
  });
