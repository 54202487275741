import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik } from 'formik';
import { itemBatchFields } from '../constants';
import { UpdateItemBatchInput } from 'apps/things/app/types/thingsGlobalTypes';
import { itemBatchFieldsValidationSchema } from 'apps/things/app/validations';
import ItemBatchForm from './ItemBatchForm';
import CardWrapper from '@components/Card/CardWrapper';
import { GetSpecificItemBatch_getSpecificItemBatch } from '../types/GetSpecificItemBatch';
interface IRTable {
  handleUpdate: (payload: UpdateItemBatchInput) => void;
  isLoading: boolean;
  itemBatch: GetSpecificItemBatch_getSpecificItemBatch;
  lastSequence: string;
}

const EditItemBatch: React.FC<IRTable> = ({
  handleUpdate,
  isLoading,
  itemBatch,
  lastSequence='001',
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleUpdate({
                itemBatchId: itemBatch._id,
                batchNumber: values.batchNumber,
                batchDate: values.batchDate,
                description: values.description,
                itemSKUId: values.itemSKUId,
              })
            }
            initialValues={{
              [itemBatchFields.batchNumber]: itemBatch.batchNumber,
              [itemBatchFields.batchDate]: itemBatch.batchDate,
              [itemBatchFields.description]: itemBatch.description,
              [itemBatchFields.itemSKUId]: itemBatch.itemSKU?._id,
            }}
            validationSchema={itemBatchFieldsValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemBatchForm
                formBag={formBag}
                disableLoginButton={isLoading}
                actionButtonName='Update Item Batch'
                lastSequence={lastSequence}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default EditItemBatch;
