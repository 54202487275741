import { NetworkStatus, useMutation } from '@apollo/client';
import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryOrder } from 'apps/things/app/types/thingsGlobalTypes';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import AddItemSKU from '../components/AddItemSKU';
import { skuRoutes } from '../constants';
import { ItemSKUContext } from '../context/SKUProvider';
import { createItemSkuMutation } from '../mutations';
import { useGetAllItemSKUsQuery } from '../queries';
import { CreateItemSKU, CreateItemSKUVariables } from '../types/CreateItemSKU';
import { CreateItemSKUInput } from 'apps/things/app/types/thingsGlobalTypes';

const AddItemSKUContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const { setItemSKUs } = React.useContext(ItemSKUContext);
  const { refetch, data, networkStatus } = useGetAllItemSKUsQuery({
    first: 100,
    queryorder: QueryOrder.DESC,
  });

  React.useEffect(() => {
    setItemSKUs(data?.getAllItemSKUs?.page?.edges || []);
    // eslint-disable-next-line
  }, [data]);

  const [createItemSKU, createItemSKUOpts] = useMutation<
    CreateItemSKU,
    CreateItemSKUVariables
  >(createItemSkuMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
      notify({
        status: 'success',
        text: 'Item SKU created successfully.',
      });
      await refetch();
      history(skuRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleSubmit = (createItemSKUInput: CreateItemSKUInput) => {
    createItemSKU({
      variables: {
        createItemSKUInput: {
          ...createItemSKUInput,
          gatt: {
            name: createItemSKUInput?.gatt?.name || '--',
            attributes: createItemSKUInput?.gatt?.attributes?.map((item) => ({
              prop: item?.prop || '--',
              value: item?.value || '--',
              meta: item?.meta || '--',
            })),
          },
        },
      },
    });
  };

  if (networkStatus === NetworkStatus.refetch) return <GridLoader />;

  return (
    <AddItemSKU
      handleSubmit={handleSubmit}
      isLoading={createItemSKUOpts.loading}
    />
  );
};

export default AddItemSKUContainer;
