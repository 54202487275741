import OVSForm from '@components/Form/OVSForm';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { IProps } from '@components/messages/types';
import { IAdvancedQuery } from '@components/ReactTable/TableToolBar/AdvancedSearch/types';
import { generateQueryParams } from 'apps/migration/app/utils';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { Formik } from 'formik';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-table';
import { RootState } from 'store/epics/userPrefEpics';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { LegacyDistributorContext } from '../../LegacyDistributor/context/LegacyDistributorContext';
import { LegacyActions } from '../redux/actions';
import { LegacyActions as DistributorLegacyActions } from '../../LegacyDistributor/redux/actions';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import useNotifier from 'hooks/useNotifier';
import { LegacyBatchActions } from '../../LegacyBatch/redux/actions';
import ActionModal from '@components/Modal/ActionModal';
import { useAuth } from 'admin/auth/context/AuthProvider';

export interface ILegacyItem {
  productCategoryID: string;
  productCategoryName: string;
  productCategoryDescription: string;
  image: string;
}
export interface ILegacyItemContext {
  items: ILegacyItem[];
  setItems: (item: any) => void;
  loading: boolean;
  getItems: () => void;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  advancedSearch: (queries: IAdvancedQuery[]) => void;
  queries: IAdvancedQuery[];
  setQueries: (queries: IAdvancedQuery[]) => void;
  protocolVersions: { _id: string; name: string }[];
  setProtocolVersions: (versions: { _id: string; name: string }[]) => void;
  getProtocolVersions: () => void;
  setOffset: (offset: string | number) => void;
  tableActions: ITableActions[];
}

export const LegacyItemContext = createContext<ILegacyItemContext>(
  {} as ILegacyItemContext
);

const LegacyItemProvider: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const itemsSelector = useSelector(
    (state: RootState) => state.legacyItem.items
  );
  const isProgress = useSelector(
    (state: RootState) => state.legacyItem.isProgress
  );
  const [protocolVersions, setProtocolVersions] = useState<
    { _id: string; name: string }[]
  >([]);
  const [items, setItems] = useState<ILegacyItem[]>([]);
  const [distributorID, setDistributorId] = useState('');
  const [paging, setPaging] = useState({});
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [queries, setQueries] = useState<IAdvancedQuery[]>([
    {
      column: '',
      value: '',
      hidden: false,
    },
  ]);
  const { setTableActionLoading, tableActionLoading } = useContext(
    GlobalSimulatorContext
  );
  const notify = useNotifier();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setItems(itemsSelector.data);
    setPaging(itemsSelector.paging);
  }, [itemsSelector]);

  const handleServerError = () => {
     notify({ status: "error", text: "Server error. Please contact support." });
  }
  // context

  const goTo = (next = true) => {
    let params = '';
    if (searchQuery !== undefined) {
      params += 'search=' + searchQuery;
    }
    const q = generateQueryParams(queries);
    if (q) {
      params += `&${q}`;
    }
    if (next) {
      if (!itemsSelector.paging?.next) {
        return;
      }
      params += '&' + itemsSelector.paging?.next;
    } else {
      if (!itemsSelector.paging?.prev) {
        return;
      }
      params += '&' + itemsSelector.paging?.prev;
    }
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy(params)
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        handleServerError()
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  const search = () => {
    if (searchQuery === undefined) return;
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy('search=' + searchQuery)
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        handleServerError();
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  const advancedSearch = (_queries: IAdvancedQuery[]) => {
    const q = generateQueryParams(_queries);
    if (!q) return;
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy(q)
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        handleServerError();
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  const setOffset = (offset: number | string) => {
    let params = '';
    params += `offset=${offset}`;
    if (searchQuery !== undefined) {
      params += '&search=' + searchQuery;
    }

    const q = generateQueryParams(queries);
    if (q) {
      params += `&${q}`;
    }
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy(params)
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        dispatch(LegacyActions.listLegacyFail(err));
      });
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const getItems = () => {
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy('')
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        handleServerError();
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  const getProtocolVersions = () => {
    return LegacyActions.getProtocolVersions().then((res: any) => {
      setProtocolVersions(
        res.data.map((item: any) => ({
          _id: JSON.stringify({
            protocol: item.protocolVersion,
            firmware: item.firmwareVersion,
          }),
          name: JSON.stringify({
            protocol: item.protocolVersion,
            firmware: item.firmwareVersion,
          }),
        }))
      );
    });
  };

  const { items: distributorOptions, getItems: getDistributors } = useContext(
    LegacyDistributorContext
  );
  const {isDistributor, isServicer} = useAuth()
  useEffect(() => {
    if (isDistributor || isServicer) {
      return
    }
    if (items.length === 0) {
      getDistributors && getDistributors();
    } // eslint-disable-next-line
  }, []);

  const searchPromise = async (search: string) => {
    const _res = await DistributorLegacyActions.getDistributorsLegacy(
      `search=${search}`
    ).then((res) => {
      return res;
    });
    return _res?.data?.results?.map((it: any) => ({
      value: it.distributorID,
      label: it.contactEmail,
    }));
  };

  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            md: 12,
            type: 'select-async',
            name: 'distributor',
            label: 'Distributor',
            options: distributorOptions.map((it) => ({
              _id: it.distributorID,
              name: it.contactEmail,
            })),
            onChange: (e: any) => {
              setDistributorId(e);
            },
            value: distributorID || '',
            searchPromise: async (search: string) => {
              const _res = await DistributorLegacyActions.getDistributorsLegacy(
                `search=${search}`
              ).then((res) => {
                return res;
              });
              const options = _res?.data?.map((it: any) => ({
                value: it.distributorID,
                label: it.contactEmail,
              }));

              return options;
            },
          },
        ],
      },
    ], // eslint-disable-next-line
    [distributorOptions, searchPromise]
  );

  const [selectedItems, _setSelectedItems] = useState([] as Row[]);
  const setSelectedItems = (itemsToAssign: Row[]) => {
    _setSelectedItems(itemsToAssign);
    setModalOpen(true);
  };

  const multiAssignToDistributor = () => {
    try {
      if (!distributorID) {
        return notify({
          text: 'Please select a distributor',
          status: 'error',
        });
      }
      setTableActionLoading(true);
      Promise.all(
        selectedItems.map((item) =>
          LegacyBatchActions.assignItem({
            productItemID: item.values['productItemID'],
            distributorID,
          })
        )
      )
        .then(async (res) => {
          notify({
            status: 'success',
            text: 'Items assigned successfully',
          });
          setModalOpen(false)

          setTableActionLoading(false);
        })
        .catch((error) => {
          notify({ text: error?.toString(), status: 'error' });
          setModalOpen(false)
          setTableActionLoading(false);
        });
    } catch (error) {
      notify({
        text: JSON.stringify(error),
        status: 'error',
      });
    }
  };
  const tableActions = useMemo(() => {
    return [
      {
        action: setSelectedItems,
        title: 'Assign to distributor',
        description: (arg: string) => '',
        showConfirmationModal: false,
      },
    ]; // eslint-disable-next-line
  }, [formFieldsData]);

  const value = useMemo(
    () => ({
      items,
      setItems,
      loading: isProgress,
      getItems,
      setSearchQuery,
      searchQuery,
      goTo,
      paging,
      advancedSearch,
      queries,
      setQueries,
      protocolVersions,
      setProtocolVersions,
      getProtocolVersions,
      setOffset,
      tableActions,
    }), // eslint-disable-next-line
    [
      items,
      isProgress,
      searchQuery,
      queries,
      protocolVersions,
      paging,
      tableActions,
      distributorOptions,
    ]
  );

  return (
    <>
      <LegacyItemContext.Provider value={value}>
        {children}
      </LegacyItemContext.Provider>
      <ActionModal
        isModalOpen={modalOpen}
        toggleModal={setModalOpen}
        handleOnClickOkey={multiAssignToDistributor}
        loading={tableActionLoading}
        handleOnClickCancel={() => setModalOpen(false)}
        okText='Migrate'
      >
        <div>
          <Formik
            onSubmit={async (values: any) => ({})}
            initialValues={{ distributorID }}
          >
            {(formBag) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h6>Assign selected items to a distributor..</h6>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <OVSForm
                    formFieldsData={[
                      {
                        name: '',
                        fields: [
                          {
                            md: 12,
                            type: 'select-async',
                            name: 'distributor',
                            label: 'Distributor',
                            options: distributorOptions.map((it) => ({
                              _id: it.distributorID,
                              name: it.contactEmail,
                            })),
                            onChange: (e: any) => {
                              setDistributorId(e);
                            },
                            value: distributorID || '',
                            searchPromise,
                          },
                        ],
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
            )}
          </Formik>
        </div>
      </ActionModal>
    </>
  );
};

export default LegacyItemProvider;
