import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import { addItemSKUValidationSchema } from 'apps/things/app/validations';
import ItemSKUForm from './ItemSKUForm';
import CardWrapper from '@components/Card/CardWrapper';
import { CreateItemSKUInput } from '../../types/thingsGlobalTypes';
import { ItemSKUContext } from '../context/SKUProvider';
import { defaultItemSKU } from '../constants';

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: CreateItemSKUInput) => void;
  isLoading: boolean;
}

const AddItemSKU: React.FC<IRTable> = ({ handleSubmit, isLoading }) => {
  const { defaultItem, setDefaultItem } = useContext(ItemSKUContext);
  useEffect(() => {
    return () => {
      setDefaultItem(defaultItemSKU)
    } // eslint-disable-next-line
  }, [])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleSubmit({
                skuName: values.skuName,
                oemDescription: values.oemDescription,
                productBase: values.productBase,
                gatt: values.gatt,
              })
            }
            initialValues={defaultItem}
            validationSchema={addItemSKUValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemSKUForm
                formBag={
                  (formBag as unknown) as FormikProps<CreateItemSKUInput>
                }
                disableLoginButton={isLoading}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItemSKU;
