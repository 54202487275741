import { Epic, ofType } from 'redux-observable';
import { of, defer } from 'rxjs';
import {
  catchError,
  switchMap,
  pluck,
  map,
  filter,
  mergeMap,
} from 'rxjs/operators';
import { RootAction, RootState } from 'store/epics/userPrefEpics';
import { isOfType } from 'typesafe-actions';
import { legacyBatchTypes } from './constants';

export class LegacyBatchEpics {
  public static listLegacyBatch: Epic<RootAction, RootAction, RootState> = (
    actions$: any,
    state$,
    { ajaxGet }
  ) =>
    actions$.pipe(
      ofType(legacyBatchTypes.LIST_LEGACY_BATCH),
      mergeMap((action: any) =>
        ajaxGet('product/batch').pipe(
          map(
            (res: any) => {
              return {
                type: legacyBatchTypes.LIST_LEGACY_BATCH_SUCCESS,
                payload: res,
              };
            },
            catchError((error: any) =>
              of({
                type: legacyBatchTypes.LIST_LEGACY_BATCH_FAIL,
                payload: error,
              })
            )
          )
        )
      )
    );
  static listLegacyBatchxx: Epic<RootAction, RootAction, RootState> = (
    action$,
    state$,
    { ajaxGet }
  ) => {
    return action$.pipe(
      ofType(legacyBatchTypes.LIST_LEGACY_BATCH),
      filter(isOfType(legacyBatchTypes.LIST_LEGACY_BATCH)),
      switchMap(({ payload }) => {
        return defer(() => {
          return ajaxGet('product/batch');
        }).pipe(
          pluck('response'),
          map((obj) => {
            return {
              type: legacyBatchTypes.LIST_LEGACY_BATCH_SUCCESS,
              payload: obj,
            };
          }),
          catchError((err: any) => {
            return of({
              type: legacyBatchTypes.LIST_LEGACY_BATCH_FAIL,
              payload: err,
            });
          })
        );
      })
    );
  };
}
