import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import { Row } from 'react-table';
import SkuTableActions from '../components/SkuTableActions';

export const itemSKUTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <SkuTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: 'skuName',
      accessor: 'node.skuName',
      Cell: DescriptionCell
    },
    {
      Header: 'oemDescription',
      accessor: 'node.oemDescription',
      Cell: DescriptionCell,
    },
    {
      Header: 'productBase',
      accessor: 'node.productBase',
    },
    {
      Header: 'actionScope',
      accessor: 'node.actionScope',
    },{
      Header: 'actorName',
      accessor: 'node.actorName',
    },

    {
      Header: 'type',
      accessor: 'node.type',
    },   
    {
      Header: 'Profile',
      accessor: 'node.profile',
    },
    {
      Header: 'Distributor',
      accessor: 'node.distributor.orgContactPerson.name',
    },
    {
      Header: 'Supplier',
      accessor: 'node.supplier.orgContactPerson.name',
    },

    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
    { Header: 'ID', accessor: 'node._id' },
  ],
});
