import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import clientSimulator from 'utils/clientSimulator';
import { pageDataFragment } from '../sku/queries';
import { GetAllAvatars, GetAllAvatarsVariables } from './types/GetAllAvatars';
import { GetSpecificAvatar, GetSpecificAvatarVariables } from './types/GetSpecificAvatar'
import {
  GetSpecificAvatarforItem,
  GetSpecificAvatarforItemVariables,
} from './types/GetSpecificAvatarforItem';

const attributeFragment = gql`
  fragment Attribute on Attribute {
    meta
    prop
    value
  }
`;
/**
 * type Avatar {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
type: ActorTypes!
actionScope: ActionScope!
actorName: String!
profile: String
item: Item!
itemAlive: Boolean!
lastSync: DateTime!
lastPub: String!
lastSub: String!
att: [Attribute!]
sts: [Attribute!]
sta: [Attribute!]
}
 */
export const avatarFragment = gql`
  ${attributeFragment}
  fragment Avatar on Avatar {
    _id
    itemAlive
    lastPub
    lastSub
    lastSync
    # actionScope
    # actorName
    att {
      ...Attribute
    }
    cmd {
      ...Attribute
    }
    createdAt
    deleteAt
    deleteStatus
    dia {
      ...Attribute
    }
    dta {
      ...Attribute
    }
    item {
      _id
    }

    profile
    sts {
      ...Attribute
    }
    triggers
    # type
    updatedAt
  }
`;

/**
 * type AvatarEdge {
cursor: String
node: Avatar
}
 */
const avatarEdgeFragment = gql`
  ${avatarFragment}
  fragment AvatarEdge on AvatarEdge {
    cursor
    node {
      ...Avatar
    }
  }
`;
/**
 * type AvatarPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const avatarPageInfoFragment = gql`
  fragment AvatarPageInfo on AvatarPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type AvatarConnection {
edges: [AvatarEdge!]
pageInfo: AvatarPageInfo
}
 */
const avatarConnectionFragment = gql`
  ${avatarPageInfoFragment}
  ${avatarEdgeFragment}
  fragment AvatarConnection on AvatarConnection {
    edges {
      ...AvatarEdge
    }
    pageInfo {
      ...AvatarPageInfo
    }
  }
`;
/**
 * type GetAllAvatarsResponse {
page: AvatarConnection!
pageData: PageData
}
 */
const getAllAvatarsResponseFragment = gql`
  ${avatarConnectionFragment}
  ${pageDataFragment}
  fragment GetAllAvatarsResponse on GetAllAvatarsResponse {
    page {
      ...AvatarConnection
    }
    pageData {
      ...PageData
    }
  }
`;

/**
 * getAllAvatars(
before: String
after: String
first: Int
last: Int
): GetAllAvatarsResponse!
 */
export const getAllAvatarsQuery = gql`
  ${getAllAvatarsResponseFragment}
  query GetAllAvatars(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllAvatars(before: $before, after: $after, first: $first, last: $last) {
      ...GetAllAvatarsResponse
    }
  }
`;

/**
 * Query.getSpecificAvatar(
  id: String!
  ): Avatar
 */

export const getSpecificAvatar = gql`
  query GetSpecificAvatar($id: String!) {
    getSpecificAvatar(id: $id) {
      ...Avatar
    }
  }
  ${avatarFragment}
  ${attributeFragment}
`;

export const useGetSpecificAvatar = (
  variables: GetSpecificAvatarVariables
) =>
  useLazyQuery<GetSpecificAvatar, GetSpecificAvatarVariables>(
    getSpecificAvatar,
    {
      client: clientSimulator,
      variables,
      onError: (err) => {
        console.error(err.message);
      },
    }
  );

/**
 * Query.getSpecificAvatarforItem(
itemId: String!
): Avatar!
 */
const getSpecificAvatarforItemQuery = gql`
  ${avatarFragment}
  query GetSpecificAvatarforItem($itemId: String!) {
    getSpecificAvatarforItem(itemId: $itemId) {
      ...Avatar
    }
  }
`;

export const useGetSpecificAvatarForItemQuery = (
  variables: GetSpecificAvatarforItemVariables
) =>
  useLazyQuery<GetSpecificAvatarforItem, GetSpecificAvatarforItemVariables>(
    getSpecificAvatarforItemQuery,
    {
      client: clientSimulator,
      variables,
      onError: (err) => {
        console.error(err.message);
      },
    }
  );
export const useLazyGetSpecificAvatarForItemQuery = (
  variables: GetSpecificAvatarforItemVariables
) =>
  useQuery<GetSpecificAvatarforItem, GetSpecificAvatarforItemVariables>(
    getSpecificAvatarforItemQuery,
    {
      client: clientSimulator,
      variables,
      onError: (err) => {
        console.error(err.message);
      },
    }
  );
export const useLazyGetAllAvatarsQuery = (variables: GetAllAvatarsVariables) =>
  useLazyQuery<GetAllAvatars, GetAllAvatarsVariables>(getAllAvatarsQuery, {
    client: clientSimulator,
    variables,
    onError: (err) => {
      console.error(err.message);
    },
  });
