import React from 'react';
import moment from 'moment';

interface IProps {
  value: string;
}

const DateCell: React.FC<IProps> = ({ value }) => {
  let _value = moment(value).format('MMM Do YYYY, h:mm a');
  if (_value === 'Invalid date') {
    _value = '---';
  }
  return <div>{_value}</div>;
};

export default DateCell;
