import ActionModal from "@components/Modal/ActionModal";
import useNotifier from "hooks/useNotifier";
import React, { useContext, useState } from "react";
import useBatchInitialization from "../../hooks/useBatchInitialization";
import { ItemContext } from "apps/things/app/item/context/ItemProvider";
import { CodeSystemType } from "apps/things/app/types/thingsGlobalTypes";
interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  loading: boolean;
  handleOnClickOky: () => void;
  progress: number;
  itemId: string;
  codeGenType: string | null;
}

const BatchTokenizeModal: React.FC<IProps> = ({
  isModalOpen,
  setIsModalOpen,
  loading,
  itemId,
  codeGenType
}) => {
  // state

  const [initializerLoading, setInitializerLoading] = useState(false);


  const { refetchItems } = useContext(ItemContext);

  // hooks
  const notify = useNotifier();
  const { create: batchInitialize, options: batchInitializeOpts } =
    useBatchInitialization((data) => {
      setIsModalOpen(false)
      refetchItems && refetchItems();
      // notify({ status: "success", text: "Successfully Initialized" });
    });



  const handleInitializeCodeGen = async () => {
    setInitializerLoading(true);
    notify({
      text: `Initializing...`,
    });
    await batchInitialize({
      variables: {
        batchInitializeInput: {
          itembatchId: itemId,
          codeGenType: codeGenType as CodeSystemType || null
        },
      },
    });
    notify({
      status: "success",
      text: `items initialization Started Successfully.`,
    });
    setInitializerLoading(false);
  };


  return (
    <ActionModal
      isModalOpen={isModalOpen}
      toggleModal={setIsModalOpen}
      handleOnClickOkey={ handleInitializeCodeGen}
      handleOnClickCancel={() => setIsModalOpen(false)}
      okText={
        initializerLoading
          ? "Generating..."
          : "Generate"
      }
      loading={
        loading ||
        batchInitializeOpts.loading ||
        initializerLoading
      }
    >
      Generate tokens for item(s)
      {/* {!confirmed && <>{renderMessage()}</>}
      {confirmed && batchItems.length > 0 && <>{renderMessage()}</>}
      {confirmed && batchItems.length < 1 && (
        <Alert severity="info">All items has been initialized.</Alert>
      )} */}
    </ActionModal>
  );
};

export default BatchTokenizeModal;
