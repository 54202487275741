import { IProps } from '@components/messages/types';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import useNotifier from 'hooks/useNotifier';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-table';
import { RootState } from 'store/epics/userPrefEpics';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { LegacyBatchActions } from '../redux/actions';
export interface ILegacyBatch {
  productBatchID: string;
  productBatchNumber: string;
  productBatchDate: string;
  productBatchNotes: string;
  productBatchState: string;
}
export interface ILegacyBatchContext {
  items: ILegacyBatch[];
  setItems: (item: any) => void;
  loading: boolean;
  getItems: () => void;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setOffset: (offset: string | number) => void;
  tableActions: ITableActions[];
}

export const LegacyBatchContext = createContext<ILegacyBatchContext>(
  {} as ILegacyBatchContext
);

const LegacyBatchProvider: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const notify = useNotifier()
  const itemsSelector = useSelector(
    (state: RootState) => state.legacyBatch.items
  );
  const isProgress = useSelector(
    (state: RootState) => state.legacyBatch.isProgress
  );
  const [items, setItems] = useState<ILegacyBatch[]>([]);
  const [paging, setPaging] = useState({});
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  useEffect(() => {
    setItems(itemsSelector.data);
    setPaging(itemsSelector.paging);
  }, [itemsSelector]);
  // context
  const handleServerError = () => {
    notify({ status: "error", text: "Server error. Please contact support." });
  };

  const getItems = () => {
    dispatch(LegacyBatchActions.listLegacyBatch({}));
    LegacyBatchActions.getLegacyBatch('')
      .then((res) => {
        dispatch(LegacyBatchActions.listLegacyBatchSuccess(res.data));
      })
      .catch((err) => {
        handleServerError()
        dispatch(LegacyBatchActions.listLegacyBatchFail(err));
      });
    return [];
  };

  const setOffset = (offset: number | string) => {
    let params = '';
    params += `offset=${offset}`
    if (searchQuery !== undefined) {
      params += '&search=' + searchQuery;
    }

    dispatch(LegacyBatchActions.listLegacyBatch({}));
    LegacyBatchActions.getLegacyBatch(params)
      .then((res) => {
        dispatch(LegacyBatchActions.listLegacyBatchSuccess(res.data));
      })
      .catch((err) => {
        handleServerError();
        dispatch(LegacyBatchActions.listLegacyBatchFail(err));
      });
  };

  const goTo = (next = true) => {
    let params = '';
    if (searchQuery !== undefined) {
      params += 'search=' + searchQuery;
    }
    if (next) {
      params += '&' + itemsSelector.paging?.next;
    } else {
      params += '&' + itemsSelector.paging?.prev;
    }
    dispatch(LegacyBatchActions.listLegacyBatch({}));
    LegacyBatchActions.getLegacyBatch(params)
      .then((res) => {
        dispatch(LegacyBatchActions.listLegacyBatchSuccess(res.data));
      })
      .catch((err) => {
        handleServerError();
        dispatch(LegacyBatchActions.listLegacyBatchFail(err));
      });
    return [];
  };
  const search = () => {
    if (searchQuery === undefined) return;
    dispatch(LegacyBatchActions.listLegacyBatch({}));
    LegacyBatchActions.getLegacyBatch('search=' + searchQuery)
      .then((res) => {
        dispatch(LegacyBatchActions.listLegacyBatchSuccess(res.data));
      })
      .catch((err) => {
        handleServerError();
        dispatch(LegacyBatchActions.listLegacyBatchFail(err));
      });
    return [];
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const value = useMemo(
    () => ({
      items,
      setItems,
      loading: isProgress,
      getItems,
      setSearchQuery,
      searchQuery,
      goTo,
      paging,
      setOffset,
      tableActions: [{
        action: (args: Row[]) => {
          LegacyBatchActions.bulkMigrateItemsInBatch();
          notify({ text: 'Migration initiated. Please follow the progress on the jobs page.', status: 'success' })
        },
        description: (arg: string) => 'This action will initiate bulk productItems migrations for all batches. Please follow the progress on the jobs page.',
        title: 'Bulk Migrate',
        showConfirmationModal: true,
        bulkAction: true
      }]
    }), // eslint-disable-next-line
    [items, isProgress]
  );

  return (
    <LegacyBatchContext.Provider value={value}>
      {children}
    </LegacyBatchContext.Provider>
  );
};

export default LegacyBatchProvider;
