import {
  IMessage,
  IMessageContext,
  MessageContext,
} from '@components/messages';
import { useContext } from 'react';

export type UseNotifierResult = IMessageContext;

function useNotifier(): UseNotifierResult {
  const notificationContext = useContext(MessageContext);

  const notify = (options: IMessage) => {
    notificationContext.show(options);
  };
  return notify;
}

export default useNotifier;
