import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { legacyRoutes } from './constants';
import Add from "./containers/AddItemContainer";
import Edit from "./containers/EditItemContainer";
import List from "./containers/LegacyModel";
interface IProps { }
export const routes = {
         path: legacyRoutes.list,
         element: <DefaultOutlet />,
         children: [
           { index: true, element: <List /> },
           { path: legacyRoutes.add, element: <Add /> },
           { path: legacyRoutes.edit, element: <Edit /> },
         ],
       };
const LegacyCategoryIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default LegacyCategoryIndex;
