import { useMutation } from "@apollo/client";
import CardGridLoader from "@components/Loaders/CardGridLoader";
import useNotifier from "hooks/useNotifier";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import clientSimulator from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import { UpdateMessageTemplate, UpdateMessageTemplateVariables } from "../types/UpdateMessageTemplate";
import { updateMessageTemplateMutation } from "../mutations";
import {MessageTemplateContext} from "../context/MessageTemplateProvider";
import { useGetSpecificMessageTemplateQuery } from "../queries";
import { UpdateMessageTemplateInput } from "../../types/accountsGlobalTypes";
import { messageTemplateRoutes } from "../constants";
import EditMessageTemplate from "../components/EditMessageTemplate";
enum MessageCourierEnum {
    TELERIVET,
    LIPAY
  }
  enum IntentsEnum {
    KEYCODE,
    PAYMENT,
    REFUND,
    TWOXDAYS,
    THREEXDAYS,
    TENPLUSDAYS,
    UNLOCK,
    RESET,
    }
const EditMessageTemplateContainer = () => {
    const notify = useNotifier();
    const { itemId = "" } = useParams();
    const history = useNavigate();
    const { data, loading } = useGetSpecificMessageTemplateQuery({
        // @ts-ignore
        id: itemId
    })
    const { refetchItems: refetch } = useContext(MessageTemplateContext);
    const [item, setItem] = React.useState({} as UpdateMessageTemplateInput);
    React.useEffect(() => {

        setItem({
            name: data?.getSpecificMessageTemplate?.name || "" ,
            description: data?.getSpecificMessageTemplate?.description || "" ,
            messageBody: data?.getSpecificMessageTemplate?.messageBody || "",
            // @ts-ignore
            intent: data?.getSpecificMessageTemplate?.intent || IntentsEnum.KEYCODE,
              // @ts-ignore
            distributor: data?.getSpecificMessageTemplate?.distributor?._id || "",
            // @ts-ignore
            messageCourier: data?.getSpecificMessageTemplate?.messageCourier || MessageCourierEnum.LIPAY
        })
    }, [data]);


    const [updateItem, updateItemOpts] = useMutation<
        UpdateMessageTemplate, 
        UpdateMessageTemplateVariables
    >(updateMessageTemplateMutation, {
        client: clientSimulator,
        onCompleted: (data) => {
            notify({
                status: "success",
                text: "Message Template Updated successfully",
            });
            refetch && refetch();
            history(messageTemplateRoutes.list);
        },
        onError: (err) => {
            handleGQLErrors(notify, err);
        },
    });

    const handleUpdate = (updateMessageTemplateInput: UpdateMessageTemplateInput) => {
        updateItem({
            variables: {
                id: itemId,
                updateMessageTemplateInput: updateMessageTemplateInput
            }
        })
    };

    if (!item?.name){ return <CardGridLoader />} 
    
    return (
        <EditMessageTemplate
            handleSubmit={handleUpdate}
            isLoading={updateItemOpts.loading || loading}
            item={item}
        />
    );
}

export default EditMessageTemplateContainer;
