import { gql } from '@apollo/client';

/**
 * Mutation.deleteSignal(
SignalId: String!
): String!
 */
export const deleteSignalMutation = gql`
  mutation DeleteThingSignal($SignalId: String!) {
    deleteSignal(SignalId: $SignalId)
  }
`;

/**
 * Mutation.emitSignal(
id: ID!
): String!
 */
export const emitSignalMutation = gql`
  mutation EmitThingSignal($id: ID!) {
    emitSignal(signalId: $id)
  }
`;

/**
 * updateSignal(
updateSignalInput: UpdateSignalInput!
): Signal!
 */
export const updateSignalMutation = gql`
  mutation UpdateThingSignal($updateSignalInput: UpdateSignalInput!) {
    updateSignal(updateSignalInput: $updateSignalInput) {
      _id
    }
  }
`;

/**
 * createSignal(
createSignalInput: CreateSignalInput!
): Signal!
 */
export const createSignalMutation = gql`
  mutation CreateThingSignal($createSignalInput: CreateSignalInput!) {
    createSignal(createSignalInput: $createSignalInput) {
      _id
    }
  }
`;

/**
 * archiveSignalClassByTimeStamp(
end: DateTime
start: DateTime
): String!
 */
export const archiveSignalClassByTimeStampMutation = gql`
  mutation ArchiveThingSignalClassByTimeStamp($end: DateTime, $start: DateTime) {
    archiveSignalClassByTimeStamp(end: $end, start: $start)
  }
`;

/**
 * restoreSignalClassWithTimeStamp(
end: DateTime
start: DateTime
): String!
 */
export const restoreSignalClassWithTimeStampMutation = gql`
  mutation RestoreThingSignalClassWithTimeStamp($end: DateTime, $start: DateTime) {
    restoreSignalClassWithTimeStamp(end: $end, start: $start)
  }
`;
