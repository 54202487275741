import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import Add from '../servicer/containers/AssignDistributorToServicer';
import { itemRoutes } from './constants';
import Edit from './containers/EditItemContainer';
import List from "./containers/Item";

interface IProps { }


export const routes = {
         path: itemRoutes.list,
         element: <DefaultOutlet />,
         children: [
           { index: true, element: <List /> },
           { path: itemRoutes.add, element: <Add /> },
           { path: itemRoutes.edit, element: <Edit /> },
         ],
       };

const ClientServiceIndex: React.FC<IProps> = () => {
  return <DefaultOutlet  />;
};

export default ClientServiceIndex;
