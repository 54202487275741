import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import clientSimulator from 'utils/clientSimulator';

import { GetAllItemFleetsResponse_page_edges } from '../types/GetAllItemFleetsResponse';
import {
  GetAllItemFleets,
  GetAllItemFleetsVariables,
} from '../types/GetAllItemFleets';
import {
  useLazyGetAllItemFleetsQuery,
  useLazyGetItemFleetsForClientQuery,
} from '../queries';
import {
  DeleteItemFleet,
  DeleteItemFleetVariables,
} from '../types/DeleteItemFleet';
import { deleteItemFleetMutation } from '../mutations';
import { GetItemFleetsForClient, GetItemFleetsForClient_getItemFleetsForClient_page_edges } from '../types/GetItemFleetsForClient';
import { useAuth } from 'admin/auth/context/AuthProvider';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';

export interface IFleetContext {
  items: GetAllItemFleetsResponse_page_edges[];
  distributorItems: GetItemFleetsForClient_getItemFleetsForClient_page_edges[];
  setDistributorItems: (
    itemSKUs: GetItemFleetsForClient_getItemFleetsForClient_page_edges[]
  ) => void;
  setItems: (itemSKUs: GetAllItemFleetsResponse_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  getDistributorItems: () => void;
  tableActions: ITableActions[];
  refetchItems:
  | ((
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllItemFleets>>)
  | undefined;
  refetchDistributorItems: any;
  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
  clientError: ApolloError | undefined
  
}

export const FleetContext = React.createContext<IFleetContext>(
  {} as IFleetContext
);

const FleetProvider: React.FC<IProps> = ({ children }) => {
  const { userPref, isDistributor } = useAuth();
  const [items, setItems] = React.useState<
    GetAllItemFleetsResponse_page_edges[]
  >([] as GetAllItemFleetsResponse_page_edges[]);

  const [distributorItems, setDistributorItems] = React.useState<
    GetAllItemFleetsResponse_page_edges[]
  >([] as GetItemFleetsForClient_getItemFleetsForClient_page_edges[]);

  const { setTableActionLoading } = useContext(GlobalSimulatorContext);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  /// hooks
  const notify = useNotifier();
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, setPageListLimit, pageListLimit
  } = useOVSPagination();
  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllItemFleetsQuery({
    first: pageListLimit,
  });

  const [
    getDistributorItems,
    {
      data: distributorData,
      loading: dLoading,
      fetchMore: fetchMoreDistributorItems,
      error: clientError,
      refetch: refetchDistributorItems
    },
  ] = useLazyGetItemFleetsForClientQuery({
    first: pageListLimit,
    clientId: userPref?._id,
  });

  useEffect(() => {
    if (distributorData && isDistributor)
      setDistributorItems(
        distributorData?.getItemFleetsForClient?.page?.edges || []
      );
    setPaging({
      ...distributorData?.getItemFleetsForClient?.pageData,
      ...distributorData?.getItemFleetsForClient?.page?.pageInfo,
    }); // eslint-disable-next-line
  }, [distributorData]);

  useEffect(() => {
    setItems(data?.getAllItemFleets?.page?.edges || []);
    setPaging({
      ...data?.getAllItemFleets?.pageData,
      ...data?.getAllItemFleets?.page?.pageInfo,
    }); // eslint-disable-next-line
  }, [data]);

  const [deleteItem] = useMutation<DeleteItemFleet, DeleteItemFleetVariables>(
    deleteItemFleetMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => { },
    }
  );
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllItemFleetsVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMoreDistributorItems && isDistributor) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetItemFleetsForClient } = await fetchMoreDistributorItems({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setDistributorItems(
        _data?.data?.getItemFleetsForClient?.page?.edges || []
      );

      setPaging({
        ..._data?.data?.getItemFleetsForClient?.pageData,
        ..._data?.data?.getItemFleetsForClient?.page?.pageInfo,
      });
    }

    if (fetchMore && !isDistributor) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllItemFleets?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemFleets?.pageData,
        ..._data?.data?.getAllItemFleets?.page?.pageInfo,
      });
    }
  };

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }

    const variables: GetAllItemFleetsVariables = {
      first: pageListLimit,
    };
    if (searchQuery) {
      variables['search'] = searchQuery;
    }
    setIsLoadingMore(true);
    if (isDistributor && fetchMoreDistributorItems) {
      const _data: { data: GetItemFleetsForClient } = await fetchMoreDistributorItems({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getItemFleetsForClient?.page?.edges || []);

      setPaging({
        ..._data?.data?.getItemFleetsForClient?.pageData,
        ..._data?.data?.getItemFleetsForClient?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
    if (!isDistributor && fetchMore) {
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllItemFleets?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemFleets?.pageData,
        ..._data?.data?.getAllItemFleets?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            itemFleetId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllItemFleets?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllItemFleets?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading: loading || dLoading || isLoadingMore,
      getItems,
      refetchItems,
      getDistributorItems,
      distributorItems,
      setDistributorItems,
      paging,
      goTo,
      refetchDistributorItems,
      setSearchQuery,
      searchQuery, setPageLimit, clientError,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} fleet(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, distributorItems, loading, dLoading, searchQuery, paging, isLoadingMore, clientError]
  );

  return (
    <FleetContext.Provider value={value}>{children}</FleetContext.Provider>
  );
};

export default FleetProvider;
