import { IProps } from '@components/messages/types';
import { GetAllLoginUsers_getAllLoginUsers } from 'admin/distributor/types/GetAllLoginUsers';
import { GetAllLoginUsersResponse } from 'admin/distributor/types/GetAllLoginUsersResponse';
import React, { useState } from 'react';

export interface IUserContext {
  users: GetAllLoginUsersResponse;
  setUsers: (users: GetAllLoginUsers_getAllLoginUsers) => void;
  loading: boolean;
}

export const DistributorContext = React.createContext<IUserContext>(
  {} as IUserContext
);

const DistributorProvider: React.FC<IProps> = ({ children }) => {
  const [users, setUsers] = React.useState<GetAllLoginUsers_getAllLoginUsers>(
    {} as GetAllLoginUsers_getAllLoginUsers
  );
  const [loading] = useState(false);

  const value = React.useMemo(
    () => ({
      users,
      setUsers,
      loading,
    }),
    [users, loading]
  );

  return (
    <DistributorContext.Provider value={value as IUserContext}>
      {children}
    </DistributorContext.Provider>
  );
};

export default DistributorProvider;
