import DescriptionCell from "@components/ReactTable/DescriptionCell";
import DateCell from "@components/ReactTable/DateCell";
import { Row } from "react-table";
import ItemTableActions from "../components/tableActions";

export const itemTableData = [
  {
    Header: 'Actions',
    id: 'expander', // It needs an ID
    Cell: ({ row }: { row: Row }) => (
      <>
        {<ItemTableActions {...row.getToggleRowExpandedProps()} row={row} />}
      </>

    ),
  },
  {
    Header: 'Name',
    accessor: 'node.name', 
  },
  {
    Header: 'Message Description',
    accessor: 'node.description',
    Cell: DescriptionCell
  },
  {
    Header: 'Message Body',
    accessor: 'node.messageBody',
    Cell: DescriptionCell
  },
  {
    Header: 'Intent',
    accessor: 'node.intent',
  },
  {
    Header: 'Message Courier',
    accessor: 'node.messageCourier',
  },
  {
    Header: 'Created At',
    accessor: 'node.createdAt',
    Cell: DateCell
  },
  {
    Header: 'ID',
    accessor: 'node._id',
  },
];
