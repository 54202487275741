import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import KeyValueInput from './KeyValueInput';
import AddIcon from '@material-ui/icons/Add';
import { ButtonGroup, Tooltip } from '@material-ui/core';
import { IAdvancedQuery } from './types';

interface IProps {
  columns: { value: string; label: string }[];
  advancedSearch: (queries: IAdvancedQuery[]) => void;
  queries?: IAdvancedQuery[];
  setQueries?: (queries: IAdvancedQuery[]) => void;
  refetchData?: () => void;
}

const AdvancedSearch: React.FC<IProps> = ({
  columns,
  advancedSearch,
  refetchData,
  queries = [
    {
      column: '',
      value: '',
      hidden: false,
    },
  ],
  setQueries,
}) => {
  return (
    <PopupState variant='popover' popupId='demo-popup-popover'>
      {(popupState) => (
        <div>
          <Button
            style={{ marginTop: 17 }}
            size='small'
            {...bindTrigger(popupState)}
          >
            Advanced
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box p={2} style={{ minWidth: 600 }}>
              <div>
                {queries.map((query, index) => (
                  <KeyValueInput
                    // formBag={formBag}
                    index={index}
                    key={index + 'query-map'}
                    removeItem={(i: number) => {}}
                    queries={queries}
                    setQueries={setQueries}
                    columns={columns}
                    hidden={query.hidden || false}
                  />
                ))}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>
                    <Tooltip
                      id='tooltip-top'
                      title='Add a column'
                      placement='top'
                    >
                      <Button
                        onClick={() => {
                          setQueries &&
                            setQueries([
                              ...queries,
                              {
                                column:
                                  (columns.length && columns[0].value) || '',
                                value: '',
                                hidden: false,
                              },
                            ]);
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip>
                  </Typography>
                  <ButtonGroup aria-label=' primary button group'>
                    <Button
                      style={{}}
                      size='small'
                      onClick={() => {
                        let _queries = [...queries];
                        _queries = _queries.map((item) => ({
                          ...item,
                          hidden: true,
                        }));
                        setQueries && setQueries(_queries);
                        refetchData && refetchData();
                      }}
                    >
                      Clear all
                    </Button>
                    <Button
                      style={{}}
                      size='small'
                      onClick={() => advancedSearch(queries)}
                    >
                      Apply
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default AdvancedSearch;
