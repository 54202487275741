import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyTableActions';
import StatusCell from '../components/StatusCell';

export const legacyTableData = {
  header: [
    {
      Header: 'Progress',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <Actions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    { Header: 'id', accessor: 'id' },
    {
      Header: 'title',
      accessor: 'title',
    },
    {
      Header: 'Meta',
      accessor: 'meta',
      Cell: DescriptionCell,
    },
    {
      Header: 'total',
      accessor: 'total',
    },
    {
      Header: 'completed',
      accessor: 'completed',
    },
    {
      Header: 'failed',
      accessor: 'failed',
    },
    {
      Header: 'is_complete',
      accessor: 'is_complete',
      Cell: StatusCell,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: DescriptionCell
    },
    {
      Header: 'created_at',
      accessor: 'created_at',
      Cell: DateCell,
    },
    {
      Header: 'updated_at',
      accessor: 'updated_at',
      Cell: DateCell,
    },
  ],
};
