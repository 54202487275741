// @ts-nocheck

import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import clientSimulator from "utils/clientSimulator";
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { PaymentPlanInput} from '../../payPlanTemplate/constants';
import AddItem from "../components/AddItem"
import { createPayPlanMutation } from '../mutations';
import { CreatePayPlan, CreatePayPlanVariables } from '../types/CreatePayPlan';
import { PayPlanTemplateContext } from '../context/EventProvider';
import { itemRoutes } from '../../payPlanTemplate/constants';
const AddItemContainer: React.FC<IProps> = () => {
    const {refetchItems} = useContext(PayPlanTemplateContext)
    const notify = useNotifier();
    const history = useNavigate();
    const [createPayPlanTemplate, { loading }] = useMutation<CreatePayPlan, CreatePayPlanVariables >(
        createPayPlanMutation,
        {
          client: clientSimulator,
          onCompleted: (data) => {
            notify({
              status: "success",
              text: "Payment plan created successfully",
            });
            refetchItems && refetchItems();
            history(itemRoutes.list)
          },
        }
      );
    const handleSubmit = (values: PaymentPlanInput) => {
        let planDetails = []
        for (const key in values) {
            if(key !== "planName" && key !== "planDescription" && key !== "useUpfront") {
                planDetails.push({pName: key, pValue: String(values[key])})
            }
          }
        createPayPlanTemplate({
            variables: {
                createPayPlanTemplateInput: {
                    planName: values.planName,
                    planDescription: values.planDescription,
                    useUpfront: values.useUpfront,
                    planDetails: planDetails,
                }
            }
        })
    }
    
    return (
        <AddItem
            handleSubmit={handleSubmit}
            isLoading={loading}
            item={{}}
        />

    );
};

export default AddItemContainer;
