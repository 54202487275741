import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { useLazyGetSpecificMessageGroupsQuery } from '../queries';
import {
  GetSpecificMessageGroup,
  GetSpecificMessageGroupVariables,
  GetSpecificMessageGroup_getSpecificMessageGroup_persons_page_edges
} from '../types/GetSpecificMessageGroup';
import useOVSPagination from 'hooks/useOVSPagination';
import { funNumberAgr } from '@types';

export interface IPeopleViewContext {
  items: GetSpecificMessageGroup_getSpecificMessageGroup_persons_page_edges[];
  setItems: (
    itemSKUs: GetSpecificMessageGroup_getSpecificMessageGroup_persons_page_edges[]
  ) => void;
  loading: boolean;
  refetchItems:
  | ((
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetSpecificMessageGroup>>)
  | undefined;
  getItems: () => void;

  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  setItemId: (itemID: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
}

export const PeopleViewContext = React.createContext<IPeopleViewContext>(
  {} as IPeopleViewContext
);

const PeopleViewProvider: React.FC<IProps> = ({ children }) => {
  const [items, setItems] = React.useState<
    GetSpecificMessageGroup_getSpecificMessageGroup_persons_page_edges[]
  >([] as GetSpecificMessageGroup_getSpecificMessageGroup_persons_page_edges[]);
  const [loading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [itemId, setItemId] = useState<string>()
  const {
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();


  const [
    getItems,
    { refetch: refetchItems, fetchMore, data, loading: dataLoading },
  ] = useLazyGetSpecificMessageGroupsQuery({
    id: itemId || "",
    first: pageListLimit,
  });

  useEffect(() => {
    if (data && !dataLoading) {
      setItems(data?.getSpecificMessageGroup?.persons.page?.edges || []);
      setPaging({
        ...data?.getSpecificMessageGroup?.persons.pageData,
        ...data?.getSpecificMessageGroup?.persons.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    getItems()
    // eslint-disable-next-line
  }, [itemId])
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }
  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetSpecificMessageGroupVariables = {
      id: itemId || "",
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      // if (searchQuery) {
      //   variables['search'] = searchQuery;
      // }
      setIsLoadingMore(true);
      const _data: { data: GetSpecificMessageGroup } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getSpecificMessageGroup?.persons.page?.edges || []);

      setPaging({
        ..._data?.data?.getSpecificMessageGroup?.persons.pageData,
        ..._data?.data?.getSpecificMessageGroup?.persons.page?.pageInfo,
      });
    }
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading: loading || dataLoading,
      refetchItems,
      getItems,
      goTo,
      paging,
      setSearchQuery,
      setItemId,
      searchQuery, setPageLimit,
    }), // eslint-disable-next-line
    [items, paging, searchQuery]
  );

  return (
    <PeopleViewContext.Provider value={value}>
      {children}
    </PeopleViewContext.Provider>
  );
};

export default PeopleViewProvider;
