import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { pageDataFragment } from '../../../things/app/fleet/queries';
import clientClientService from '../utils/clientClientService';
import {
  GetAllDistributors,
  GetAllDistributorsVariables,
} from './types/GetAllDistributors';
import {
  GetSpecificDistributor,
  GetSpecificDistributorVariables,
} from './types/GetSpecificDistributor';

import {
  GetSpecificDistributorSetting,
  GetSpecificDistributorSettingVariables
} from './types/GetSpecificDistributorSetting'

/**
 * type Distributor {
_id: ID!
activeSubRolePermission: PermissionInput
authenticationInstance: AuthenticationInstance!
authenticationSubInstance: AuthenticationSubInstance!
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
description: String
itemFleet: [ItemFleet!]!
itemSKU: [ItemSKU!]
name: String!
orgAddress: Address!
orgContactPerson: Person
role: Roles!
subRolePermissions: SubRolePermissions!
type: OrgTypes!
updatedAt: DateTime
}
 */
const distributorFragment = gql`
  fragment Distributor on Distributor {
    _id
    activeSubRolePermission
    createdAt
    deleteAt
    deleteStatus
    description
    type
    updatedAt
    orgContactPerson {
      _id
      name
      address {
        city
        country
        postcode
        srpc
        street
        unit
        addressLocation {
          addressLatitude
          addressLongitude
        }
      }
      contact {
        email
        phone
        social
      }
    }
  }
`;
/**
 * type DistributorEdge {
cursor: String
node: Distributor
}
 */
const distributorEdgeFragment = gql`
  ${distributorFragment}
  fragment DistributorEdge on DistributorEdge {
    cursor
    node {
      ...Distributor
    }
  }
`;
/**
 * type DistributorPageInfo {
endCursor: String
hasNextPage: Boolean!
hasPreviousPage: Boolean!
startCursor: String
}
 */
const distributorPageInfoFragment = gql`
  fragment DistributorPageInfo on DistributorPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type DistributorConnection {
edges: [DistributorEdge!]
pageInfo: DistributorPageInfo
}
 */
const distributorConnectionFragment = gql`
  ${distributorEdgeFragment}
  ${distributorPageInfoFragment}
  fragment DistributorConnection on DistributorConnection {
    edges {
      ...DistributorEdge
    }
    pageInfo {
      ...DistributorPageInfo
    }
  }
`;
/**
 * type GetAllDistributorsResponse {
page: DistributorConnection!
pageData: PageData
}
 */
const getAllDistributorsResponseFragment = gql`
  ${distributorConnectionFragment}
  ${pageDataFragment}
  fragment GetAllDistributorsResponse on GetAllDistributorsResponse {
    page {
      ...DistributorConnection
    }

    pageData {
      ...PageData
    }
  }
`;
/**
 * getAllDistributors(
after: String
before: String
first: Int
last: Int
): GetAllDistributorsResponse!
 */
const getAllDistributorsQuery = gql`
  ${getAllDistributorsResponseFragment}
  query GetAllDistributors(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $search: String
  ) {
    getAllDistributors(
      after: $after
      before: $before
      first: $first
      last: $last
      search: $search
    ) {
      ...GetAllDistributorsResponse
    }
  }
`;

/**
 * 
Query.getSpecificDistributor(
id: ID!
): Distributor
 */
const getSpecificDistributorQuery = gql`
  ${distributorFragment}
  query GetSpecificDistributor($id: ID!) {
    getSpecificDistributor(id: $id) {
      ...Distributor
    }
  }
`;

/**
 * 
type DistributorSetting {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
freeCodeCount: Int
resetCodeCount: Int
dayCodeCountLimit: Int
totalCodeCountLimit: Int
codeGenInterval: Int
}
 */
const distributorSettingFragment = gql ` 
fragment DistributorSetting on DistributorSetting {
  _id
  deleteStatus
  deleteAt
  createdAt
  updatedAt
  freeCodeCount
  resetCodeCount
  dayCodeCountLimit
  totalCodeCountLimit
  codeGenInterval
  maxCreditStackDays
  maxCreditStackCodeEvents

}
`

/**
 * 
getSpecificDistributorSetting(
distributorId: String!
): DistributorSetting!
 */

const getSpecificDistributorSettingQuery = gql `
  ${distributorSettingFragment}
query GetSpecificDistributorSetting($id: String!) {
  getSpecificDistributorSetting(distributorId: $id) {
    ...DistributorSetting
  }
}
`

export const useGetSpecificDistributorSettingQuery = (
  variables: GetSpecificDistributorSettingVariables
) =>
  useQuery<GetSpecificDistributorSetting, GetSpecificDistributorSettingVariables>(
    getSpecificDistributorSettingQuery,
    {
      variables,
      client: clientClientService,
    }
  );
  export const useLazyGetSpecificDistributorSettingQuery = (
    variables: GetSpecificDistributorSettingVariables
  ) =>
    useLazyQuery<GetSpecificDistributorSetting, GetSpecificDistributorSettingVariables>(
      getSpecificDistributorSettingQuery,
      {
        variables,
        client: clientClientService,
      }
    );
  
export const useGetSpecificDistributorQuery = (
  variables: GetSpecificDistributorVariables
) =>
  useQuery<GetSpecificDistributor, GetSpecificDistributorVariables>(
    getSpecificDistributorQuery,
    {
      variables,
      client: clientClientService,
    }
  );

export const useGetAllDistributorsQuery = (
  variables: GetAllDistributorsVariables
) =>
  useQuery<GetAllDistributors, GetAllDistributorsVariables>(
    getAllDistributorsQuery,
    {
      variables,
      client: clientClientService,
    }
  );

export const useLazyGetAllDistributorsQuery = (
  variables: GetAllDistributorsVariables
) =>
  useLazyQuery<GetAllDistributors, GetAllDistributorsVariables>(
    getAllDistributorsQuery,
    {
      variables,
      client: clientClientService,
    }
  );
