import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { getToken } from 'admin/auth/utils';
import { FEDERATION_API } from 'config';

// Instantiate required constructor fields
const cache = new InMemoryCache();

const link = createUploadLink({
  uri: FEDERATION_API,
});

export const tokenLink = setContext((_, context) => {
  const authToken = getToken();
  return {
    ...context,
    headers: {
      ...context.headers,
      Authorization: authToken ? `Bearer ${authToken.auth}` : null,
      universe: 'ECOMMERCE',
    },
  };
});

export const errorLink = onError((error) => {
  console.error('OnError: ', error.networkError);
});

const clientEcommerceService = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  link: tokenLink.concat(link),

  // Provide some optional constructor fields
  name: 'react-web-client',
  version: '1.3',
  queryDeduplication: false,
  ssrForceFetchDelay: 60000,
  defaultOptions: {
    mutate: { errorPolicy: 'ignore' },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default clientEcommerceService;
