
import React from 'react';
import EncoderDecoder from '../components/EncoderDecoder';

const EncoderDecoderContainer: React.FC<{}> = (props) => {
  
  return (
    <EncoderDecoder />
  );
};

export default EncoderDecoderContainer;
