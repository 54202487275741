import { useMutation } from "@apollo/client";
import CardGridLoader from "@components/Loaders/CardGridLoader";
import useNotifier from "hooks/useNotifier";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import clientSimulator from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import { UpdateClientPersonInput } from "../../types/clientServiceGlobalTypes";
import AddItem from "../components/AddItem";
import { itemRoutes } from "../constants";
import { CustomerContext } from "../context/CustomerProvider";
import { updatePersonMutation } from "../mutations";
import { useGetSpecificCustomerOrPersonQuery } from "../queries";
import { UpdatePerson, UpdatePersonVariables } from "../types/UpdatePerson";

const EditItemSKUContainer = () => {
  const notify = useNotifier();
  const { itemId = "" } = useParams();
  const history = useNavigate();
  const { data, loading } = useGetSpecificCustomerOrPersonQuery({
    personId: itemId,
  });
  const { refetchItems: refetch } = useContext(CustomerContext);
  const [item, setItem] = React.useState({} as UpdateClientPersonInput);

  React.useEffect(() => {
    const address = data?.getSpecificCustomerOrPerson?.address;
    const contact = data?.getSpecificCustomerOrPerson?.contact;

    setItem({
      clientContactPerson: {
        email: contact?.email || "",
        phone: contact?.phone || "",
        social: contact?.social || "",
      },
      clientPersonAddress: {
        city: address?.city || "",
        country: address?.country || "",
        postcode: address?.postcode || "",
        srpc: address?.srpc || "",
        street: address?.street || "",
        unit: address?.unit || "",
        locationAddressDto: {
          inputAddressLatitude: parseFloat(
            address?.addressLocation?.addressLatitude?.toString() || "0.0"
          ),
          inputAddressLongitude: parseFloat(
            address?.addressLocation?.addressLongitude?.toString() || "0.0"
          ),
        },
      },
      clientPersonName: data?.getSpecificCustomerOrPerson?.name,

      personId: data?.getSpecificCustomerOrPerson?._id || "",
      clientPersonDescription: data?.getSpecificCustomerOrPerson?.description,
    }); // eslint-disable-next-line
  }, [data]);

  const [updateItem, updateItemOpts] = useMutation<
    UpdatePerson,
    UpdatePersonVariables
  >(updatePersonMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: "success",
        text: "Updated successfully",
      });
      refetch && refetch();
      history(itemRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleUpdate = (updateClientPersonInput: UpdateClientPersonInput) => {
    updateItem({
      variables: {
        updateClientPersonInput: {
          ...updateClientPersonInput,
          clientPersonAddress: {
            city: updateClientPersonInput?.clientPersonAddress?.city || "",
            country: updateClientPersonInput?.clientPersonAddress?.city || "",
            postcode: updateClientPersonInput?.clientPersonAddress?.city || "",
            srpc: updateClientPersonInput?.clientPersonAddress?.city || "",
            street: updateClientPersonInput?.clientPersonAddress?.city || "",
            unit: updateClientPersonInput?.clientPersonAddress?.city || "",
            locationAddressDto: {
              inputAddressLatitude: parseFloat(
                updateClientPersonInput?.clientPersonAddress?.locationAddressDto?.inputAddressLatitude?.toString() ||
                  "0.00"
              ),
              inputAddressLongitude: parseFloat(
                updateClientPersonInput?.clientPersonAddress?.locationAddressDto?.inputAddressLatitude?.toString() ||
                  "0.00"
              ),
            },
          },
        },
      },
    });
  };

  if (!data?.getSpecificCustomerOrPerson?._id) return <CardGridLoader />;
  return (
    <AddItem
      handleSubmit={handleUpdate}
      isLoading={updateItemOpts.loading || loading}
      item={item}
    />
  );
};

export default EditItemSKUContainer;
