import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, {  useEffect, useMemo, useState } from 'react';

import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields, orgTypesOptions } from '../../constants';
import OVSForm, { IFormFields } from '@components/Form/OVSForm';
import { AdminRegisterServicerInput } from 'apps/clientService/app/types/clientServiceGlobalTypes';
import { USER_TYPES } from 'admin/auth/constants/auth';
import useRoles from 'admin/Role/hooks/useRoles';
const ItemForm: React.FC<{
  formBag: FormikProps<AdminRegisterServicerInput>;
  disableLoginButton: boolean;
}> = (props) => {
  const { formBag, disableLoginButton } = props;

  const { options: items, getItems, loading, search } = useRoles(USER_TYPES.SERVICER);

  useEffect(() => {
    // if (items.length === 0) {
      getItems();
     // eslint-disable-next-line
  }, []);

  const [roleOptions, setRoleOptions] = useState<
    { _id: string; name: string }[]
  >([]);

  useEffect(() => {
    let options = items.filter((item) => item?.name === USER_TYPES.SERVICER).map((item) => ({
      _id: item?._id || '',
      name: item?.name || '',
    })) || []
    if (options.length) {
      formBag.setFieldValue(itemFields.roleId, options[0]._id)
    }
    setRoleOptions(
      options

    ); // eslint-disable-next-line
  }, [items]);

  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            type: 'select-async',
            name: 'roleId',
            label: loading ? 'Loading ...' : 'Role',
            options: items,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.roleId, e);
            },
            value: formBag.values.roleId || '',
            error: formBag.errors.roleId as string,
            searchPromise: search
          },

          {
            type: 'text',
            name: itemFields.contactRole,
            label: 'Contact Role',
            error: formBag.errors.contactRole as string,
          },
          {
            type: 'text',
            name: itemFields.email,
            label: 'Email',
            error: formBag.errors.email as string,
          },
          {
            type: 'text',
            name: itemFields.servicerName,
            label: 'Servicer Name',
            error: formBag.errors.servicerName as string,
          },
          {
            type: 'select',
            name: 'servicerType',
            label: 'Servicer Type',
            options: orgTypesOptions,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.servicerType, e.target.value);
            },
            value: formBag.values.servicerType || '',
            error: formBag.errors.servicerType as string,
          },
          {
            type: 'textarea',
            name: itemFields.servicerDescription,
            label: 'Description',
            error: formBag.errors.servicerDescription as string,
          },
        ],
      },
      {
        name: 'Address',
        fields: [
          {
            type: 'text',
            name: itemFields.city,
            label: 'City',
            error: formBag.errors?.servicerAddress?.city as string,
          },
          {
            type: 'text',
            name: itemFields.country,
            label: 'Country',
            error: formBag.errors?.servicerAddress?.country as string,
          },
          {
            type: 'text',
            name: itemFields.postcode,
            label: 'Postal code',
            error: formBag.errors?.servicerAddress?.postcode as string,
          },
          {
            type: 'text',
            name: itemFields.srpc,
            label: 'srpc',
            error: formBag.errors?.servicerAddress?.srpc as string,
          },
          {
            type: 'text',
            name: itemFields.street,
            label: 'Street',
            error: formBag.errors?.servicerAddress?.street as string,
          },
          {
            type: 'text',
            name: itemFields.unit,
            label: 'Unit',
            error: formBag.errors?.servicerAddress?.unit as string,
          },
          {
            type: 'number',
            name: itemFields.inputAddressLongitude,
            label: 'Longitude',
            error: formBag.errors?.servicerAddress?.unit as string,
          },
          {
            type: 'number',
            name: itemFields.inputAddressLatitude,
            label: 'Latitude',
            error: formBag.errors.servicerAddress?.locationAddressDto?.inputAddressLatitude as string,
          },
        ]
      }
    ], // eslint-disable-next-line
    [formBag.values, items, roleOptions, loading]
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader />
        <Form>
          <GridContainer>
            <OVSForm formFieldsData={formFieldsData as IFormFields[]} />

          </GridContainer>
          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer >
  );
};

export default ItemForm;
