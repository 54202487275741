import { IProps } from '@components/messages/types';
import React from 'react';
import ApplicationSiteProvider from '../applicationSite/context/ApplicationSiteContext';
import BrandProvider from '../brand/context/BrandContext';
import CategoryProvider from '../category/context/CategoryContext';
import CategorySelectorProvider from '../categorySelector/context/CategorySelectorContext';
import MediaProvider from '../cloudinaryImages/context/MediaContext';
import ProductEcommerceContext from '../products/context/ProductEcommerceContext';
import ProductSelectorProvider from '../productSelector/context/ProductSelectorContext';

const EcommerceProvider: React.FC<IProps> = ({ children }) => (
  <CategoryProvider>
    <ProductSelectorProvider>
      <CategorySelectorProvider>
        <ApplicationSiteProvider>
          <BrandProvider>
            <MediaProvider>
              <ProductEcommerceContext>{children}</ProductEcommerceContext>
            </MediaProvider>
          </BrandProvider>
        </ApplicationSiteProvider>
      </CategorySelectorProvider>
    </ProductSelectorProvider>
  </CategoryProvider>
);

export default EcommerceProvider;
