import { useMutation } from "@apollo/client";
import CardGridLoader from "@components/Loaders/CardGridLoader";
import useNotifier from "hooks/useNotifier";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import clientSimulator from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import { UpdateMessageGroup, UpdateMessageGroupVariables } from "../types/UpdateMessageGroup";
import { updateMessageGroupMutation } from "../mutations";
import { MessageGroupContext } from "../context/MessageGroupProvider";
import { useGetSpecificMessageGroupQuery } from "../../peopleView/queries";
import { UpdateMessageGroupInput } from "../../types/accountsGlobalTypes";
import { itemFirmwareRoutes } from "../constants";
import EditMessageGroup from "../components/editMessageGroup";

const EditMessageGroupContainer = () => {
    const notify = useNotifier();
    const { itemId = "" } = useParams();
    const history = useNavigate();
    const { data, loading } = useGetSpecificMessageGroupQuery({
        // @ts-ignore
        id: itemId,
        first: 10
    })
    const { refetchItems: refetch } = useContext(MessageGroupContext);
    const [item, setItem] = React.useState({} as UpdateMessageGroupInput);
    React.useEffect(() => {
    if(data?.getSpecificMessageGroup?._id){
        // @ts-ignore
        setItem({
            name: data?.getSpecificMessageGroup?.name,
            description: data?.getSpecificMessageGroup?.description,
        })
        }
    }, [data]);


    const [updateItem, updateItemOpts] = useMutation<
        UpdateMessageGroup,
        UpdateMessageGroupVariables
    >(updateMessageGroupMutation, {
        client: clientSimulator,
        onCompleted: (data) => {
            notify({
                status: "success",
                text: "Message Group Updated successfully",
            });
            refetch && refetch();
            history(itemFirmwareRoutes.list);
        },
        onError: (err) => {
            handleGQLErrors(notify, err);
        },
    });

    const handleUpdate = (updateMessageGroupInput: UpdateMessageGroupInput) => {
        updateItem({
            variables: {
                id: itemId,
                updateMessageGroupInput
            }
        })
    };

    if (!item?.name){ return <CardGridLoader />} 
    
    return (
        <EditMessageGroup
            handleSubmit={handleUpdate}
            isLoading={updateItemOpts.loading || loading}
            item={item}
        />
    );
}

export default EditMessageGroupContainer;
