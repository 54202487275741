import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import { createAvatarValidationSchema } from 'apps/things/app/validations';
import ItemForm from './AvatarForm/ItemForm';
import CardWrapper from '@components/Card/CardWrapper';
import { UpdateAvatarByItemIDInput } from 'admin/types/globalTypes';

interface IRTable {
  handleSubmit: (payload: UpdateAvatarByItemIDInput) => void;
  isLoading: boolean;
  item: UpdateAvatarByItemIDInput;
}

const AddItem: React.FC<IRTable> = ({ handleSubmit, isLoading, item }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) => handleSubmit(values)}
            initialValues={{
              ...item,
            }}
            validationSchema={createAvatarValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemForm
                formBag={(formBag as unknown) as FormikProps<UpdateAvatarByItemIDInput>}
                disableLoginButton={isLoading}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItem;
