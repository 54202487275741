  import { IFilter } from "@components/ReactTable/FilterChips";
  // Step 1: Extract the logic into a separate function
  export const processItemsData = (items: any, data: any, filterDates: any, from: any, fleetData: IFilter) => {
    let creditActivitiesData: any = [];
    for (let i = 0; i < items.length; i++) {
      let json: any
      if (from === "table") {
        json = items[i]?.node;
      } else if (from === "download") {
        json = items[i]

      }


    const fleetName = json?.asset?.itemFleet?.fleetName;
    // Only filter by fleetId if requiredFleetId is provided
    if (fleetData?.field === "fleet" && fleetName !== fleetData.value && fleetData.value !== undefined) {
      continue; // Skip items that don't match the required fleet ID
    }
      const creditActivities = json?.credit?.activities;

      if (creditActivities) {
        const result = creditActivities
          .filter((activity: any) => activity.action === "CREDIT")
          .filter((activity: any) => isDateInRange(activity.datetime, filterDates?.value?.from, filterDates?.value?.to))
          .map((activity: any) => {
            // Find matching code events and sort them by createdAt
            const matchingCodeEvents = data.getCodeEventsByCodeGeneratorIds
              .filter((codeEvent: any) =>
                codeEvent.codeGenerator._id === json?.asset.codeGenerator?._id &&
                new Date(codeEvent.createdAt) > new Date(activity.datetime)
              )
              .sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())

            // Select the first matching code event
            const firstMatchingCodeEvent = matchingCodeEvents[0];
            return {
              AccountStage: json?.accountStage,
              SellerItemId: json?.asset?.sellerItemID,
              OemItemId: json?.asset?.oemItemID,
              FleetName: json?.asset?.itemFleet?.fleetName,
              Status: json?.credit?.accountStatus,
              Phone: json?.credit?.owner?.contact?.phone,
              CustomerName: json?.credit?.owner?.name,
              Street: json?.credit?.owner?.address?.street,
              City: json?.credit?.owner?.address?.city,
              SRPC: json?.credit?.owner?.address?.srpc,
              Country: json?.credit?.owner?.address?.country,
              PostCode: json?.credit?.owner?.address?.postcode,
              AddressLatitude: json?.credit?.owner?.address?.addressLocation?.addressLatitude,
              AddressLongitude: json?.credit?.owner?.address?.addressLocation?.addressLongitude,
              Balance: json?.credit?.balance,
              Currency: json?.credit?.currency,
              PlanName: json?.paymentPlan?.planName,
              action: activity.action,
              amount: activity.amount,
              datetime: activity.datetime,
              // Add first matching code event details
              codeEvent: firstMatchingCodeEvent?.codeDecString,
              codeDays: firstMatchingCodeEvent?.codeDays,
              codeEventCreatedAt: firstMatchingCodeEvent?.createdAt,
            };
          });
        creditActivitiesData = [...creditActivitiesData, ...result];
      }
    }

    creditActivitiesData.sort((a: any, b: any) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());
    const totalAmount = creditActivitiesData.reduce((sum: any, activity: any) => sum + activity.amount, 0);

    return { creditActivitiesData, totalAmount };
  };

  function isDateInRange(dateString: any, fromDate: any, toDate: any) {
    const date = new Date(dateString);
    return (!fromDate || date >= new Date(fromDate)) && (!toDate || date <= new Date(toDate));
  }
