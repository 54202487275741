import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import { Row } from 'react-table';
import ItemBatchActions from '../components/ItemBatchActions';

export const itemProcessData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // Cell: ({ row }: { row: Row }) => (
      //   <ItemBatchActions {...row.getToggleRowExpandedProps()} row={row} />
      // ),
    },
    {
      Header: 'actionName',
      accessor: 'actionName',
    },
    {
      Header: 'Status',
      accessor: 'state',
    },
    {
      Header: 'PendingItems',
      accessor: 'pendingItems',
    },
    {
      Header: 'UpdatedOn',
      accessor: 'updatedOn',
      Cell: DateCell,
    },
  ]
})

export const itemBatchTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <ItemBatchActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: 'batchNumber',
      accessor: 'node.batchNumber',
    },
    {
      Header: 'batchState',
      accessor: 'node.batchState',
    },
    {
      Header: 'Description',
      accessor: 'node.description',
      Cell: DescriptionCell,
    },
    {
      Header: 'Create Items',
      accessor: 'node.actionProgress.createItems.state',
    },
    {
      Header: 'Batch Initialization',
      accessor: 'node.actionProgress.batchInitialize.state',
    },
    {
      Header: 'Batch Provisioning',
      accessor: 'node.actionProgress.batchProvisioning.state',
    },
    {
      Header: 'Batch Code',
      accessor: 'node.actionProgress.batchCode.state',
    },
    {
      Header: 'skuName',
      accessor: 'node.actionProgress.skuName',
    },
    {
      Header: 'skuName',
      accessor: 'node.itemSKU.skuName',
    },
    {
      Header: 'CodeGenType',
      accessor: 'node.code_gen_type',
    },
    {
      Header: 'secretKey',
      accessor: 'node.secret_key',
    },
    {
      Header: 'startingCode',
      accessor: 'node.starting_code',
    },
    {
      Header: 'ActionProgress',
      accessor: 'node.actionProgress',
    },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'type',
      accessor: 'node.type',
    },
    {
      Header: 'actionScope',
      accessor: 'node.actionScope',
    },
    {
      Header: 'actorName',
      accessor: 'node.actorName',
    },

    {
      Header: 'Profile',
      accessor: 'node.profile',
    },

    {
      Header: 'batchDate',
      accessor: 'node.batchDate',
      Cell: DateCell,
    },

    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },

    { Header: 'ID', accessor: 'node._id' },
  ],
});
