import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useContext, useEffect, useMemo } from 'react';

import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { updateItemFields, orgTypesOptions } from '../../constants';
import OVSForm, { IFormFields } from '@components/Form/OVSForm';
import { AdminRegisterServicerInput } from 'apps/clientService/app/types/clientServiceGlobalTypes';
import { RoleContext } from 'admin/Role/context/RoleContext';

const EditItemForm: React.FC<{
  formBag: FormikProps<AdminRegisterServicerInput>;
  disableLoginButton: boolean;
}> = (props) => {
  const { formBag, disableLoginButton } = props;

  const { items, getItems, loading } = useContext(RoleContext);

  useEffect(() => {
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);




  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [          
          {
            type: 'text',
            name: updateItemFields.contactRole,
            label: 'Contact Role',
            error: formBag.errors.contactRole as string,
          },
          {
            type: 'text',
            name: updateItemFields.servicerName,
            label: 'Servicer Name',
            error: formBag.errors.servicerName as string,
          },
          {
            type: 'select',
            name: 'servicerType',
            label: 'Servicer Type',
            options: orgTypesOptions,
            onChange: (e: any) => {
              formBag.setFieldValue(updateItemFields.servicerType, e.target.value);
            },
            value: formBag.values.servicerType || '',
            error: formBag.errors.servicerType as string,
          },
          {
            type: 'textarea',
            name: updateItemFields.servicerDescription,
            label: 'Description',
            error: formBag.errors.servicerDescription as string,
          },
        ],
      },
      {
        name: 'Address Details',
        fields: [
          {
            type: 'text',
            name: updateItemFields.servicerAddressCity,
            label: 'City',
            error: formBag.errors?.servicerAddress?.city as string,
          },
          {
            type: 'text',
            name: updateItemFields.servicerAddressCountry,
            label: 'Country',
            error: formBag.errors?.servicerAddress?.country as string,
          },
          {
            type: 'text',
            name: updateItemFields.servicerAddressPostcode,
            label: 'Postal code',
            error: formBag.errors?.servicerAddress?.postcode as string,
          },
          {
            type: 'text',
            name: updateItemFields.servicerAddressSrpc,
            label: 'srpc',
            error: formBag.errors?.servicerAddress?.srpc as string,
          },
          {
            type: 'text',
            name: updateItemFields.servicerAddressStreet,
            label: 'Street',
            error: formBag.errors?.servicerAddress?.street as string,
          },
          {
            type: 'text',
            name: updateItemFields.servicerAddressUnit,
            label: 'Unit',
            error: formBag.errors?.servicerAddress?.unit as string,
          },
          {
            type: 'number',
            name: updateItemFields.servicerAddressLat,
            label: 'Latitude',
            error: formBag.errors.servicerAddress?.locationAddressDto?.inputAddressLatitude as string,
          },
          {
            type: 'number',
            name: updateItemFields.servicerAddressLng,
            label: 'Longitude',
            error: formBag.errors?.servicerAddress?.locationAddressDto?.inputAddressLongitude as string,
          },

        ]
      },
    ], // eslint-disable-next-line
    [formBag.values, items, loading]
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader />
        <Form>
          <GridContainer>
            <OVSForm formFieldsData={formFieldsData as IFormFields[]} />

          </GridContainer>
          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer >
  );
};

export default EditItemForm;
