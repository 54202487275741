
import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
 import { 
  GetAllAssetAccountsTempData,   
  GetAllAssetAccountsTempDataVariables,  
  GetAllAssetAccountsTempData_getAllAssetAccountsTempData_page_edges } from '../types/GetAllAssetAccountsTempData';
import { useLazyGetAllAssetAccountsTempDataQuery } from '../queries';
import { PAGE_LIST_LIMIT } from '@constants';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { useAuth } from 'admin/auth/context/AuthProvider';
export interface IAssetAccountTempDataContext {
  items: GetAllAssetAccountsTempData_getAllAssetAccountsTempData_page_edges[];
  setItems: (item: GetAllAssetAccountsTempData_getAllAssetAccountsTempData_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  activeStage: number;
  setActiveStage: (stage: number) => void;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<GetAllAssetAccountsTempDataVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllAssetAccountsTempData>>)
    | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;

}

export const AssetAccountTempDataContext = React.createContext<IAssetAccountTempDataContext>(
  {} as IAssetAccountTempDataContext
);

const AssetAccountTempDataProvider: React.FC<IProps> = ({ children }) => {
  const {loggedInUserId} = useAuth()
  const [activeStage, setActiveStage] = useState(0);
  const [item, setItem] = React.useState(
    {} as GetAllAssetAccountsTempData_getAllAssetAccountsTempData_page_edges
  );
  const [items, setItems] = React.useState<
    GetAllAssetAccountsTempData_getAllAssetAccountsTempData_page_edges[]
  >([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  /// hooks
  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllAssetAccountsTempDataQuery({
    first: PAGE_LIST_LIMIT,
  });

  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,
  } = useOVSPagination();

  useEffect(() => {
      loggedInUserId === "662901b8082c0b1a656894d2" && setItems(data?.getAllAssetAccountsTempData?.page?.edges || []);
      loggedInUserId === "662901b8082c0b1a656894d2" &&  setPaging({
      ...data?.getAllAssetAccountsTempData?.pageData,
      ...data?.getAllAssetAccountsTempData?.page?.pageInfo,
    });
    // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllAssetAccountsTempDataVariables = {
      first: PAGE_LIST_LIMIT,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAssetAccountsTempData?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccountsTempData?.pageData,
        ..._data?.data?.getAllAssetAccountsTempData?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };
  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }

    const variables: GetAllAssetAccountsTempDataVariables = {
      first: PAGE_LIST_LIMIT,
    };
    if (searchQuery) {
      variables['search'] = searchQuery;
    }
    setIsLoadingMore(true);
    if (fetchMore) {
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAssetAccountsTempData?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccountsTempData?.pageData,
        ..._data?.data?.getAllAssetAccountsTempData?.page?.pageInfo,
      });
    }

  }


  useDebouncedEffect(search, [searchQuery], 1000);

  const value = React.useMemo(
    () => ({
      item,
      setItem,
      items,
      setItems,
      goTo,
      paging,
      loading: loading,
      getItems: getItems,
      refetchItems,
      searchQuery,
      setSearchQuery,
      tableActions: [],
      activeStage,
      setActiveStage,
    }), // eslint-disable-next-line
    [items, loading, paging, searchQuery]
  );

  return (
    <AssetAccountTempDataContext.Provider value={value}>{children}</AssetAccountTempDataContext.Provider>
  );
};

export default AssetAccountTempDataProvider;
