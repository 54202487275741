import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import {
  cardTitle,
  roseColor,
} from 'assets/jss/material-dashboard-pro-react.js';
import Card from '@components/Card/Card';
import CardHeader from '@components/Card/CardHeader';
import CardText from '@components/Card/CardText';
import CardBody from '@components/Card/CardBody';
import Paper from '@material-ui/core/Paper';
import Button from '@components/CustomButtons/Button';

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0',
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.875rem',
  },
  cardCategory: {
    color: '#999999',
    marginTop: '10px',
  },
  icon: {
    color: '#333333',
    margin: '10px auto 0',
    width: '130px',
    height: '130px',
    border: '1px solid #E5E5E5',
    borderRadius: '50%',
    lineHeight: '174px',
    '& svg': {
      width: '55px',
      height: '55px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      width: '55px',
      fontSize: '55px',
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: '30px',
  },
  testimonialIcon: {
    marginTop: '30px',
    '& svg': {
      width: '40px',
      height: '40px',
    },
  },
  cardTestimonialDescription: {
    fontStyle: 'italic',
    color: '#999999',
  },
  table: {
    minWidth: 650,
  },
};

// @ts-ignore
const useStyles = makeStyles(styles);
interface IProps {
  // onBtnClick: () => void;
  btnText?: string;
  type?: string;
  loading?: boolean;
  errors?: string;
}

const KENYA_FACTORY_DASHBOARD= 'http://localhost:3000/kenya-factory-dashboard.bat'

const TigStack: React.FC<IProps> = ({
  btnText = 'Download',
  loading,
  errors,
}) => {
  const classes = useStyles();

  const onBtnClick = (fileUrl: any) => {
    alert('Hello, you can download')
    const fileName = fileUrl.split('/').pop();
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);// Set the desired filename for the downloaded file
    document.body.appendChild(link);

    // Trigger the click event to start the download
    link.click();

    // Clean up the temporary link element
    link.remove();
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary' text>
              <CardText color='primary'>
                <h4 className={classes.cardTitleWhite}>TigStack</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Download tigstack
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Documentation</TableCell>
                      <TableCell align='right'>Download</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Local TigStack Download</TableCell>
                      <TableCell component='th' scope='row'>
                        <a
                          rel='noopener noreferrer'
                          href='https://omnivoltaic.notion.site/Tickstack-Automation-Startscript-17476165ef3b407e8d1f7c503a1eb80b#6742546673194528ab26a512ed2d0464'
                          target='_blank'
                        >
                          View Documentation
                        </a>
                      </TableCell>
                      <TableCell align='right'>
                        <Button
                          color='primary'
                          className=''
                          aria-label='directions'
                          onClick={() => {
                            onBtnClick(KENYA_FACTORY_DASHBOARD)
                          }}
                          disabled={loading || !!errors}
                          size='sm'
                        >
                          {loading ? 'loading...' : btnText}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default TigStack;
