import { Form, Formik, FormikProps } from "formik";
// @material-ui/icons
import Typography from "@material-ui/core/Typography";

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
import React, { useMemo, useState } from "react";
import {
  DISABLED,
  ENABLED,
  FREE,
  PAYG,
} from "../constants";
import { codeGenerator } from "../utils";
import {
  InitializeCodeGenInput,
} from "apps/things/app/types/thingsGlobalTypes";
import DaysGenInput from "./DaysGenInput";
import DeviceDetailsPreview from "./DeviceDetailsPreview";
import CodeGeneratorPreview from "./CodeGeneratorPreview";
import { CodeGeneratorContext } from "../context/CodeGeneratorProvider";
import { ButtonGroup, FormControl } from "@material-ui/core";
import Button from "@components/CustomButtons/Button";
import { useAuth } from "admin/auth/context/AuthProvider";
import ActionModal from "@components/Modal/ActionModal";
import useNotifier from "hooks/useNotifier";
import useIncreaseFreeCodeCount from "../hooks/useIncreaseFreeCodeCount";
import useIncreaseResetCodeCount from "../hooks/useIncreaseResetCodeCount";
import OVSForm from "@components/Form/OVSForm";
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
const CodeGeneratorForm: React.FC<{
  formBag: FormikProps<InitializeCodeGenInput>;
  loading: boolean;
  actionButtonName?: string;
  handleResetCode: () => void;
  handleFreeCode: () => void;
  handleDaysCode: (days: number) => void;
  oemItemID?: string;
  itemId?: string;
  handleCreateCodeEvent: (payload: any) => void;
  handleCreateOrUpdateAvatar: (
    remDays: string,
    payGoState: string,
    deviceState: string,
    decHashTop: string,
    daysPassed: string
  ) => void;
}> = (props) => {
  const {
    formBag,
    loading,
    handleCreateOrUpdateAvatar,
    handleDaysCode,
    handleFreeCode: handleCreateFreeCode,
    handleResetCode: handleCreateResetCode,
  } = props;

  const {
    setRemDays,
    setDecHashTop,
    itemID,
    item,
    oemItemID,
    daysRemaining,
    daysPassed,
    setDaysRemaining,
    setDaysPassed,
    setHashIndex,
    codeDays,
    hashTop,
    hashRoot,
    hashIndex,
    setOtpHex,
    otpCount,
    maxHashJump,
    setCodeDays,
    setCodeAccepted,
    otpHex,
    otpDec,
    otpDecEntry,
    setOtpDecEntry,
    hashChainLength,
    codeAccepted,
    payGoState,
    setPayGoState,
    deviceState,
    setDeviceState,
    // remDays,
  } = React.useContext(CodeGeneratorContext);
  const [errors, setErrors] = useState("");
  const [codeGenErrors, setCodeGenErrors] = useState("");
  const { isDistributor, disableFreeCode } = useAuth();
  const notify = useNotifier();
  const [confirmDayCode, setConfirmDayCode] = useState(false);
  const [confirmResetCode, setConfirmResetCode] = useState(false);
  const [confirmFreeCode, setConfirmFreeCode] = useState(false);
  const [increaseResetCodeModalOpen, setIncreaseResetCodeModalOpen] =
    useState(false);
  const [increaseFreeCodeModalOpen, setIncreaseFreeCodeModalOpen] =
    useState(false);
  const [freeCodeCount, setFreeCodeCount] = useState(0);
  const [resetCodeCount, setResetCodeCount] = useState(0);
  const { mutation: increaseFreeCode, mutationOpts: increaseFreeCodeOpts } =
    useIncreaseFreeCodeCount({
      onComplete: async () => {},
    });
  const { mutation: increaseResetCode, mutationOpts: increaseResetCodeOpts } =
    useIncreaseResetCodeCount({
      onComplete: async () => {},
    });

  const handleIncreaseResetCode = async () => {
    if (!resetCodeCount) {
      return notify({ text: "Please add reset code count.", status: "error" });
    }

    await increaseResetCode({
      variables: {
        itemId: formBag.values.itemId,
        resetCodeCount: resetCodeCount,
      },
    });

    setIncreaseResetCodeModalOpen(false);
  };

  const handleIncreaseFreeCode = async () => {
    if (!freeCodeCount) {
      return notify({ text: "Please add free code count.", status: "error" });
    }

    await increaseFreeCode({
      variables: {
        itemId: formBag.values.itemId,
        freeCodeCount: freeCodeCount,
      },
    });
    setIncreaseFreeCodeModalOpen(false);
  };

  const freeFormFieldsData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "number",
            name: "freeCodeCount",
            label: "Free Code Count",
            onChange: (e: any) => {
              setFreeCodeCount(parseInt(e.target.value));
            },
          },
        ],
      },
    ], // eslint-disable-next-line
    [freeCodeCount]
  );
  const formFieldsData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "number",
            name: "resetCodeCount",
            label: "Reset Code Count",
            onChange: (e: any) => {
              setResetCodeCount(parseInt(e.target.value));
            },
          },
        ],
      },
    ], // eslint-disable-next-line
    [resetCodeCount]
  );

  const handleDaysPassedPlusOne = () => {
    const days = daysRemaining - 1;
    if (days > -1) {
      let total_days = daysRemaining - 1;
      let days_passed = daysPassed + 1;
      let device_state = deviceState;
      if (total_days > -1) {
        device_state = DISABLED;
      }
      setDaysPassed(daysPassed + 1);
      handleCreateOrUpdateAvatar(
        total_days.toString(),
        payGoState,
        device_state,
        otpDecEntry,
        days_passed.toString()
      );
    } else {
      setDeviceState("DISABLED");
    }
  };

  const handleDaysChange = () => {
    if (codeGenErrors) return;
    handleDaysCode(codeDays);
    setHashIndex(hashIndex - codeDays);
    setCodeDays(0);
  };

  const handleFreeCode = () => {
    const code_days = codeGenerator.max_HCJ;
    handleCreateFreeCode();
    setCodeDays(code_days);
    // const hash_top = codeGenerator.OTP_hex(hashRoot, hashIndex, code_days);
    // generateCodeGenInformation(hash_top, code_days, CodeTypes.FREECODE);
  };

  const handleResetCode = () => {
    handleCreateResetCode();
  };

  const handleSetDayCode = (e: number) => {
    if (e > codeGenerator.codeLimit || e === 0) {
      setCodeGenErrors(`Maximum day code is ${codeGenerator.codeLimit}.`);
      return;
    }
    setCodeGenErrors("");
    setCodeDays(e);
  };

  const generateDeviceInformation = async () => {
    // validate otp dec entry
    if (otpDecEntry.trim().length !== 29) {
      setErrors("Please enter a valid OTP code");
      return;
    }

    if (!otpDecEntry.includes("*") || !otpDecEntry.includes("#")) {
      setErrors("Please enter a valid OTP code");
      return;
    }

    setErrors("");

    const otp_hex = codeGenerator.padded_decimal_to_hex(otpDecEntry);
    setDecHashTop(otpDecEntry);
    const current_hash_index = codeGenerator.get_hash_index(hashRoot, otp_hex);
    let accepted = true;
    let pay_go_state = PAYG;
    let device_state = ENABLED;

    // Error conditions
    if (current_hash_index < codeGenerator.freeCodeJump) {
      console.warn(
        "// condition 1: a hit of daysCode in the range [1, $freeCodeJump-1]"
      );
      setPayGoState(PAYG);
      setDeviceState(ENABLED);
    } else if (
      current_hash_index >= codeGenerator.freeCodeJump &&
      current_hash_index < codeGenerator.resetCodeJump
    ) {
      console.warn(
        "// condition 2: a hit of FREECODE in the range [$freeCodeJump, $resetCodeJump-1]"
      );
      setPayGoState(FREE);
      pay_go_state = FREE;
      setDeviceState(ENABLED);
    } else if (
      current_hash_index >= codeGenerator.resetCodeJump &&
      current_hash_index < codeGenerator.codeLimit
    ) {
      console.warn(
        "// condition 3: a hit of RESETCODE in the range [$resetCodeJump, $codeLimit -1]"
      );
      setPayGoState(PAYG);
      setDeviceState(DISABLED);
      device_state = DISABLED;
    } else {
      console.warn("code rejected", current_hash_index);
      setCodeAccepted(false);
      accepted = false;
      setErrors("Code rejected.");
    }

    //set state
    if (accepted) {
      const total_days = current_hash_index - daysPassed + "d";
      setCodeAccepted(true);
      setDaysRemaining(current_hash_index - daysPassed);
      setRemDays(current_hash_index + "d");
      setOtpHex(otp_hex);

      // update avatar
      handleCreateOrUpdateAvatar(
        total_days,
        pay_go_state,
        device_state,
        otpDecEntry,
        daysPassed.toString()
      );
    }
  };


  const handleSetDecEntry = (e: string) => {
    setErrors("");
    setOtpDecEntry(e.trim());
  };
  const thresholdWidth = 600

  const formStyleMobile = {paddingTop: 10, marginBottom: 10, marginTop: -10}
  const formStyleDesktop = {paddingTop: 10, marginBottom: 10}

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Form>
          <GridContainer>
            <GridItem md={isDistributor ? 12 : 6} xs={12}>
              <GridContainer>
                <GridItem xs={12}>
                  <GoBackHeader/>
                  <Typography variant="h5" gutterBottom>
                    Code Generator
                  </Typography>
                </GridItem>
                <GridItem md={6} xs={12}>
                  <DaysGenInput
                    onChange={handleSetDayCode}
                    onBtnClick={() => {
                      if (!codeDays) {
                        notify({
                          status: "error",
                          text: "OTP code days should be more than 0",
                        });
                        return;
                      }
                      setConfirmDayCode(true);
                    }}
                    loading={loading}
                    errors={codeGenErrors}
                  />
                </GridItem>
                <GridItem md={6} xs={12}>
                  <FormControl style={{ paddingTop: 21 }}>
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      color="primary"
                      orientation={window.innerWidth <= thresholdWidth? "vertical": "horizontal"}
                      style= {{width: "200px"}}
                    >
                      <Button
                        size="sm"
                        onClick={() => setConfirmResetCode(true)}
                      >
                        RESET CODE
                      </Button>
                      {!disableFreeCode ? (
                        <Button // TODO disable free code for Buvilian client>
                          size="sm"
                          onClick={() => setConfirmFreeCode(true)}
                        >
                          FREE CODE
                        </Button>
                      ) : null}
                    </ButtonGroup>
                  </FormControl>
                </GridItem>

                <GridItem md={8} xs={12}>
                  <FormControl style={window.innerWidth <= thresholdWidth ? formStyleMobile: formStyleDesktop}>
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      color="primary"
                      orientation="vertical"
                      style= {{width: "200px"}}
                    >
                      <Button
                        title="Increase Reset Code"
                        size="sm"
                        onClick={() => setIncreaseResetCodeModalOpen(true)}
                      >
                        INCREASE RESET CODE
                      </Button>
                      <Button // TODO disable free code for Buvilian client>
                        size="sm"
                        onClick={() => setIncreaseFreeCodeModalOpen(true)}
                      >
                        INCREASE FREE CODE
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </GridItem>

                <GridItem xs={12}></GridItem>
                <GridItem xs={12}>
                  <CodeGeneratorPreview
                    otpHex={otpHex}
                    otpDec={otpDec}
                    hashIndex={hashIndex}
                    otpCount={otpCount}
                    hashTop={hashTop}
                    hashRoot={hashRoot}
                    hashChainLength={hashChainLength}
                    maxHashJump={maxHashJump}
                    oemItemID={oemItemID}
                    itemID={itemID}
                    customerName={item?.assetAccount?.credit?.owner?.name || ""}
                    paymentHistory={[]}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            {!isDistributor ? (
              <GridItem xs={12} md={6}>
                <GridItem xs={12}>
                  <div style={{visibility: 'hidden', marginBottom: '0.6rem'}}>
                    Position
                  </div>
                  <Typography variant="h5" gutterBottom>
                    Device
                  </Typography>
                </GridItem>
                <GridItem xs={12}>
                  <DaysGenInput
                    type="text"
                    placeholder="Enter an OTP string here..."
                    btnText="Parse"
                    onChange={handleSetDecEntry}
                    onBtnClick={generateDeviceInformation}
                    success={codeAccepted}
                    errors={errors}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <DeviceDetailsPreview
                    daysPassed={daysPassed}
                    daysRemaining={daysRemaining}
                    payGoState={payGoState}
                    deviceState={deviceState}
                    hashTop={hashTop}
                    hashRoot={hashRoot}
                    hashChainLength={hashChainLength}
                    maxHashJump={maxHashJump}
                    oemItemID={oemItemID}
                    itemID={itemID}
                    daysPassedPlusOne={handleDaysPassedPlusOne}
                    loading={loading}
                  />
                </GridItem>
              </GridItem>
            ) : null}
          </GridContainer>
        </Form>

        <ActionModal
          isModalOpen={confirmDayCode}
          toggleModal={setConfirmDayCode}
          handleOnClickOkey={() => {
            handleDaysChange();
            setConfirmDayCode(false);
          }}
          handleOnClickCancel={() => setConfirmDayCode(false)}
          okText={"Confirm"}
        >
          <div>
            Are you sure you want to generate day code? This action is not
            reversible.
          </div>
        </ActionModal>

        <ActionModal
          isModalOpen={confirmFreeCode}
          toggleModal={setConfirmFreeCode}
          handleOnClickOkey={() => {
            handleFreeCode();
            setConfirmFreeCode(false);
          }}
          handleOnClickCancel={() => setConfirmFreeCode(false)}
          okText={"Confirm"}
        >
          <div>
            Are you sure you want to generate Free code? This action is not
            reversible.
          </div>
        </ActionModal>

        <ActionModal
          isModalOpen={confirmResetCode}
          toggleModal={setConfirmResetCode}
          handleOnClickOkey={() => {
            handleResetCode();
            setConfirmResetCode(false);
          }}
          handleOnClickCancel={() => setConfirmResetCode(false)}
          okText={"Confirm"}
        >
          <div>
            Are you sure you want to generate Reset code? This action is not
            reversible.
          </div>
        </ActionModal>
        {increaseResetCodeModalOpen ? (
          <ActionModal
            isModalOpen={increaseResetCodeModalOpen}
            toggleModal={setIncreaseResetCodeModalOpen}
            handleOnClickOkey={() => {
              handleIncreaseResetCode();
            }}
            handleOnClickCancel={() => setIncreaseResetCodeModalOpen(false)}
            okText={"Save"}
            loading={increaseResetCodeOpts.loading}
          >
            <GridContainer>
              <Formik onSubmit={(e) => {}} initialValues={{}}>
                {(formBag) => (
                  <>
                    <OVSForm formFieldsData={formFieldsData} />
                  </>
                )}
              </Formik>
            </GridContainer>
          </ActionModal>
        ) : null}

        {increaseFreeCodeModalOpen ? (
          <ActionModal
            isModalOpen={increaseFreeCodeModalOpen}
            toggleModal={setIncreaseFreeCodeModalOpen}
            handleOnClickOkey={() => {
              handleIncreaseFreeCode();
            }}
            handleOnClickCancel={() => setIncreaseFreeCodeModalOpen(false)}
            okText={"Save"}
            loading={increaseFreeCodeOpts.loading}
          >
            <GridContainer>
              <Formik onSubmit={(e) => {}} initialValues={{}}>
                {(formBag) => (
                  <>
                    <OVSForm formFieldsData={freeFormFieldsData} />
                  </>
                )}
              </Formik>
            </GridContainer>
          </ActionModal>
        ) : null}
      </GridItem>
    </GridContainer>
  );
};

export default CodeGeneratorForm;
