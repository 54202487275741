import moment from 'moment';
import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LegacyBatchContext } from '../context/LegacyBatchContext';
import { legacyBatchRoutes, itemFields } from '../constants';
import LegacyAddItem from '../components/LegacyAddItem';
import { IProductBatch } from '../types';
import * as actions from '../redux/actions';

const AddItemContainer = () => {
  const { addItem } = itemFields;
  const notify = useNotifier();
  const history = useNavigate();

  const { getItems, loading } = React.useContext(LegacyBatchContext);

  const handleSubmit = (createItemInput: IProductBatch, totalItems: number) => {
    actions.LegacyBatchActions.createLegacyRequest({
      ...createItemInput,
    })
      .then(async (res) => {
        notify({
          status: 'success',
          text: `Batch created successfully.`,
        });
        getItems();
        history(legacyBatchRoutes.list);
      })
      .catch((err) => {
        const error = err?.response?.data?.error;
        notify({
          status: 'error',
          text: error || JSON.stringify(err),
        });
      });
  };

  if (loading) return <GridLoader />;

  return (
    <LegacyAddItem
      initialValues={{
        productBatchNumber: '',
        [addItem.productBatchDate]: moment().format(),
        [addItem.productBatchNotes]: '',
        [addItem.productBatchState]: '',
      }}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
};

export default AddItemContainer;
