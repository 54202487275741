import _ from "lodash";
import {
  ApolloError,
  ApolloQueryResult,
  QueryLazyOptions,
  useMutation,
} from "@apollo/client";
import { IProps } from "@components/messages/types";
import useNotifier from "hooks/useNotifier";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Row } from "react-table";
import { GlobalSimulatorContext } from "apps/things/app/provider/SimulatorAppProvider";
import { QueryOrder } from "apps/things/app/types/thingsGlobalTypes";
import clientSimulator from "utils/clientSimulator";
import { useLazyGetAllClientItemsQuery } from "../../item/queries";

import {
  GetAllItems, 
  GetAllItemsVariables, 
  GetAllItems_getAllItems_page_edges } from "../../item/types/GetAllItems";
import { ISelectOption } from "utils";
import useFleet from "apps/things/app/fleet/hooks/useFleet";
import GridContainer from "@components/Grid/GridContainer";
import { Formik } from "formik";
import {
  AssignItemToItemFleet,
  AssignItemToItemFleetVariables,
} from "apps/things/app/fleet/types/AssignItemToItemFleet";
import {ReAssignItemsFromItemFleet, ReAssignItemsFromItemFleetVariables} from "apps/things/app/fleet/types/ReAssignItemsFromItemFleet"
import { assignItemToItemFleetMutation, reAssigItemToItemFleetMutation } from "apps/things/app/fleet/mutations";
import { 
  GetAllClientItems, 
  GetAllClientItemsVariables, 
  GetAllClientItems_getAllClientItems_page_edges 
} from "../../item/types/GetAllClientItems";
import { useAuth } from "admin/auth/context/AuthProvider";
import { useDebouncedEffect } from "utils/useDebouncedEffect";
import useOVSPagination from "hooks/useOVSPagination";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import OVSForm from "@components/Form/OVSForm";
import Button from "@components/CustomButtons/Button";
import { FleetContext } from "../../fleet/context/FleetProvider";
import GridItem from "@components/Grid/GridItem";
import { funNumberAgr } from "@types";
import { useLazyGetAllItemBatches } from "../../batch/queries";
import { handleGQLErrors } from "utils/gqlErrors";
import { useLazyGetAllItemsQuery } from "../../item/queries";
export interface ITableActions {
  action: (items: Row[]) => void;
  title: string;
  description: (arg: string) => string | React.ReactNode;
  showConfirmationModal: boolean;
  bulkAction?: boolean;
}
export interface IOpenTokenSimulatorContext {
  items: GetAllItems_getAllItems_page_edges[];
  setItems: (items: GetAllItems_getAllItems_page_edges[]) => void;

  // track prev items cursor
  endCursorStack: string[];

  clientItems: GetAllClientItems_getAllClientItems_page_edges[];
  setClientItems: (
    items: GetAllClientItems_getAllClientItems_page_edges[]
  ) => void;
  itemFleetId: string;
  setItemFleetId: (itemFleetId: string) => void;
  loading: boolean;
  tableActions: ITableActions[];
  refetchItems:
  | ((
    variables?: Partial<GetAllItemsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllItems>>)
  | undefined;
  refetchClientItems:
  | ((
    variables?: Partial<GetAllClientItemsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllClientItems>>)
  | undefined;
  getItems: (
    options?: QueryLazyOptions<GetAllItemsVariables> | undefined
  ) => void;
  getClientItems: () => void;
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  _fetchMore: (batchNumber: string) => Promise<ApolloQueryResult<GetAllItems>>;
  reload: boolean;
  setReload: (arg: boolean) => void;
  setPageLimit: funNumberAgr;
  clientError: ApolloError | undefined;
}

export const OpenTokenSimulatorContext = React.createContext<IOpenTokenSimulatorContext>(
  {} as IOpenTokenSimulatorContext
);

const OpenTokenSimulatorProvider: React.FC<IProps> = ({ children }) => {
  // state
  const [items, setItems] = React.useState<
    GetAllItems_getAllItems_page_edges[]
  >([] as GetAllItems_getAllItems_page_edges[]);

  const [clientItems, setClientItems] = useState(
    [] as GetAllClientItems_getAllClientItems_page_edges[]
  );

  const [itemFleetId, setItemFleetId] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [reload, setReload] = useState(true);
  // context
  const { tableActionLoading, setTableActionLoading } = useContext(
    GlobalSimulatorContext
  );

  // hooks
  const notify = useNotifier();

  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,
    setPageListLimit,
    pageListLimit,
  } = useOVSPagination();

  const { loggedInUserId, selectedDistributorId, isServicer, isDistributor } =
    useAuth();

  const clientId = useMemo(() => {
    if (isServicer) {
      return selectedDistributorId;
    }
    return loggedInUserId;
  }, [loggedInUserId, isServicer, selectedDistributorId]);

  const [
    getItems,
    { refetch: refetchItems, fetchMore, data: itemData, loading: itemLoading },
  ] = useLazyGetAllItemsQuery({
    first: pageListLimit,
    queryorder: QueryOrder.DESC,
  });

  const [
    getItemsSearch,
    { refetch: refetchItemsSearch, data: itemDataSearch},
  ] = useLazyGetAllItemsQuery({
    first: pageListLimit,
    queryorder: QueryOrder.DESC,
    search: searchQuery

  });

  const [
    getClientItems,
    {
      data,
      fetchMore: fetchMoreClientItems,
      loading,
      refetch: refetchClientItems,
      error: clientError,
    },
  ] = useLazyGetAllClientItemsQuery({
    first: pageListLimit,
    clientId: clientId,
    assetaccount: false,
    queryorder: QueryOrder.DESC,
    isOpenTokenSimulator: true

  });

  useEffect(() => {

    let dataCopy = itemData
    if(itemDataSearch) {
      dataCopy = itemDataSearch
    }
    if (dataCopy) {
      setItems(
        dataCopy?.getAllItems?.page?.edges ||
        ({} as GetAllItems_getAllItems_page_edges[])
      );

      setPaging({
        ...dataCopy?.getAllItems?.pageData,
        ...dataCopy?.getAllItems?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [itemData, itemDataSearch]);

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit);
    if(!searchQuery){
    setTimeout(() => {
      if (!isDistributor) refetchItems && refetchItems();
      if (isDistributor) refetchClientItems && refetchClientItems();
    }, 100);
  } else if(searchQuery) {
        setTimeout(() => {
          // @ts-ignore
          if (!isDistributor && getItemsSearch) refetchItemsSearch && refetchItemsSearch();
    }, 100); 
  }
  };

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllItemsVariables = {
      first: pageListLimit,
      queryorder: QueryOrder.DESC,
      ...initPagAction(next),
    };

    if (fetchMore && !isDistributor) {
      if (searchQuery) {
        variables["search"] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllItems?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItems?.pageData,
        ..._data?.data?.getAllItems?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }

    if (fetchMoreClientItems && isDistributor) {
      let _variables: GetAllClientItemsVariables = {
        first: pageListLimit,
        clientId: clientId,
        assetaccount: false,
        queryorder: QueryOrder.DESC,
        after: variables.after,
      };

      setIsLoadingMore(true);
      const _data = await fetchMoreClientItems({
        variables: _variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllClientItems?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllClientItems?.pageData,
        ..._data?.data?.getAllClientItems?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setClientItems(data?.getAllClientItems?.page?.edges || []);
      setPaging({
        ...data?.getAllClientItems?.pageData,
        ...data?.getAllClientItems?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [data]);

  const [fleetId, setFleetId] = useState("");
  const [itemOptions, setItemOptions] = useState<ISelectOption[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assignToFleetModalOpen, setAssignToFleetModalOpen] = useState(false);
  const [reAssignToFleetModalOpen, setReAssignToFleetModalOpen] = useState(false);
  const [selectedItems, _setSelectedItems] = useState([] as Row[]);
  const setSelectedItems = (itemsToAssign: Row[]) => {
    _setSelectedItems(itemsToAssign);
    setAssignToFleetModalOpen(true);
    getFleets();
  };
  const setSelectedItemsReassign = (itemsToAssign: Row[]) => {
    _setSelectedItems(itemsToAssign);
    setReAssignToFleetModalOpen(true);
    getFleets();
  };
  const { options: _itemOptions, loading: iLoading } = useFleet();

  const _fetchMore = async (batchNumber: string) => {
    if (fetchMore) {
      const _data = await fetchMore({
        variables: { search: batchNumber },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });
      return _data;
    }
    return {} as ApolloQueryResult<GetAllItems>;
  };

  const [getItemBatches, { data: batches, loading: batchLoading }] =
    useLazyGetAllItemBatches({
      first: pageListLimit,
      queryorder: QueryOrder.DESC,
    });

  useEffect(() => {
    if (!_.get(batches, "getAllItemBatches.page.edges[0].node._id")) return;
    setSearchQuery(_.get(batches, "getAllItemBatches.page.edges[0].node._id"));
    setTimeout(() => {
      search();
    }, 100); // eslint-disable-next-line
  }, [batches]);

  // search batch and get the batch id then use it to search items
  const getBatchNumber = async () => {
    if (searchQuery) {
      getItemBatches({
        variables: {
          search: searchQuery,
          queryorder: QueryOrder.DESC,
          first: 1,
        },
      });
    }
  };

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    if (!fetchMore) {
      getItems({
        variables: {
          search: searchQuery,
          queryorder: QueryOrder.DESC,
          first: pageListLimit,
        },
      });
    }
    if (fetchMore && !isDistributor) {
      const variables: GetAllItemsVariables = {
        first: pageListLimit,
        queryorder: QueryOrder.DESC,
      };
      if (searchQuery) {
        variables["search"] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      if (!_.get(_data, "data.getAllItems.page.edges.length")) {
        getBatchNumber();
      }

      setItems(_data?.data?.getAllItems?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItems?.pageData,
        ..._data?.data?.getAllItems?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }

    if (fetchMoreClientItems && isDistributor) {

      setIsLoadingMore(true);
      const _data = await fetchMoreClientItems({
        variables: searchQuery ? { search: searchQuery } : {},
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllClientItems?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllClientItems?.pageData,
        ..._data?.data?.getAllClientItems?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length >= 0,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  useEffect(() => {
    if (_itemOptions.length > 0 && !iLoading) setItemOptions(_itemOptions); // eslint-disable-next-line
  }, [_itemOptions]);


  const [assign] = useMutation<
    AssignItemToItemFleet,
    AssignItemToItemFleetVariables
  >(assignItemToItemFleetMutation, {
    client: clientSimulator,
    onCompleted: async (data) => { },
  });
  const [reassignItemsFromFleet, {loading: reassingLoading}] = useMutation<ReAssignItemsFromItemFleet ,ReAssignItemsFromItemFleetVariables>(
    reAssigItemToItemFleetMutation, {
      client: clientSimulator,
      onCompleted: () => {
        notify({
          status: "success",
          text: "Items Re-Assigned successfully",
        });
        setFleetId("")
        setReAssignToFleetModalOpen(false)
        if (!isDistributor) refetchItems && refetchItems();
        if (isDistributor) refetchClientItems && refetchClientItems();
      },
      onError: (err) => {
        handleGQLErrors(notify, err)
      }
    });
  const multiAssignItems = (itemsToAssign: Row[]) => {
    try {
      if (!fleetId) {
        notify({
          text: "Please select a fleet Id",
          title: "",
          status: "error",
        });
        return;
      }
      setTableActionLoading(true);
      Promise.all(
        itemsToAssign
          .filter((item) => !item.values["node.itemFleet.fleetName"])
          .map((item) =>
            assign({
              variables: {
                assignItemToItemFleetInput: {
                  itemId: item.values["node._id"],
                  itemFleetId: fleetId,
                },
              },
            })
          )
      )
        .then(async (res) => {
          notify({
            status: "success",
            text: "Items assigned successfully",
          });
          if (refetchItems) {
            const itemsFetched = await refetchItems({
              first: pageListLimit,
              queryorder: QueryOrder.DESC,
            });
            setItems(itemsFetched?.data?.getAllItems?.page?.edges || items);
            setTableActionLoading(false);
          } else {
            await getItems();
            setTableActionLoading(false);
          }
        })
        .catch((error) => {
          notify({ text: error?.toString(), status: "error" });
          setTableActionLoading(false);
        });
    } catch (error) {
      console.warn(error);
    }
  };

  const multiReAssignItems = (itemsToReAssign: Row[]) => {
    if (!fleetId) {
      notify({
        text: "Please select a fleet Id",
        title: "",
        status: "error",
      });
      return;
    }
    let items = []
    for (let i = 0; i<itemsToReAssign.length; i++) {
      items.push({"itemId": itemsToReAssign[i].values["node._id"]})
    }
    reassignItemsFromFleet({variables: {itemFleetId: fleetId, items:items }})
  }


  const {
    getItems: getFleets,
    items: fleetItems,
    refetchItems: refetch,
  } = useContext(FleetContext);
  const searchFleet = async (search: string) => {
    if (!refetch) {
      return Promise.resolve(
        fleetItems?.map((it) => ({
          name: it?.node?.fleetName,
          value: it?.node?._id,
        }))
      );
    }
    const refetchData = await refetch({
      first: pageListLimit,
      search,
    });

    const items = refetchData?.data?.getAllItemFleets?.page?.edges?.map(
      (item) => {
        return {
          label: item?.node?.fleetName || "",
          value: item.node?._id || "",
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  };

  const formFieldsData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "select-async",
            name: "fleetId",
            label: "Fleet",
            options: fleetItems?.map((it) => ({
              _id: it?.node?._id || "",
              name: it?.node?.fleetName || "",
            })),
            onChange: (e: any) => {
              setFleetId(e);
            },
            value: fleetId || "",
            searchPromise: searchFleet as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [fleetItems, fleetId]
  );
  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading: loading || itemLoading || isLoadingMore || batchLoading,
      refetchItems,
      getItems,
      itemFleetId,
      isModalOpen,
      setIsModalOpen,
      setItemFleetId,
      clientItems,
      setClientItems,
      getClientItems,
      paging,
      goTo,
      endCursorStack,
      searchQuery,
      setSearchQuery,
      setPageLimit,
      _fetchMore,
      refetchClientItems,
      clientError,
      tableActions: [
        {
          action: setSelectedItems,
          title: "Assign to fleet",
          description: (arg: string) => "",
          showConfirmationModal: false,
        },
        {
          action: setSelectedItemsReassign,
          title: "Re-Assign to fleet",
          description: (arg: string) => "",
          showConfirmationModal: false,
        },
      ],
      reload,
      setReload,
    }), // eslint-disable-next-line
    [
      items,
      itemOptions,
      fleetId,
      itemFleetId,
      isModalOpen,
      clientItems,
      paging,
      itemData,
      endCursorStack,
      searchQuery,
      isLoadingMore,
      itemLoading,
      reload,
      loading,
      clientError,
    ]
  );

  return (
    <OpenTokenSimulatorContext.Provider value={value}>
      <>
        {children}
        {/* assign items to fleet */}
        {assignToFleetModalOpen ? (
          <DrawerSidebar
            isModalOpen={assignToFleetModalOpen}
            toggleModal={setAssignToFleetModalOpen}
            title="Assign Items to fleet"
            styles={{ maxWidth: "30vw", minWidth: "400px" }}
          >
            <GridContainer>
              <Formik onSubmit={(e) => { }} initialValues={{}}>
                {(formBag) => (
                  <>
                    <OVSForm formFieldsData={formFieldsData} />
                    <GridItem sm={12}>
                      <Button
                        color="primary"
                        onClick={() => {
                          multiAssignItems(selectedItems);
                        }}
                        style={{ marginLeft: 12 }}
                        disabled={tableActionLoading}
                      >
                        Save
                      </Button>
                    </GridItem>
                  </>
                )}
              </Formik>
            </GridContainer>
          </DrawerSidebar>
        ) : null}
        {reAssignToFleetModalOpen ? (
          <DrawerSidebar
            isModalOpen={reAssignToFleetModalOpen}
            toggleModal={setReAssignToFleetModalOpen}
            title="Re Assign Items to fleet"
            styles={{ maxWidth: "30vw", minWidth: "400px" }}
          >
            <GridContainer>
              <Formik onSubmit={(e) => { }} initialValues={{}}>
                {(formBag) => (
                  <>
                    <OVSForm formFieldsData={formFieldsData} />
                    <GridItem sm={12}>
                      <Button
                        color="primary"
                        onClick={() => {
                          multiReAssignItems(selectedItems);
                        }}
                        style={{ marginLeft: 12 }}
                        disabled={tableActionLoading}
                      >
                        {reassingLoading? "Loading...": "Save"}
                      </Button>
                    </GridItem>
                  </>
                )}
              </Formik>
            </GridContainer>
          </DrawerSidebar>
        ) : null}
      </>
    </OpenTokenSimulatorContext.Provider>
  );
};

export const useOpenTokenSimulatorState = () => {
  const context = React.useContext(OpenTokenSimulatorContext);

  if (context === undefined) {
    throw new Error("useItemState must be used within an OpenTokenSimulatorContext");
  }

  return context;
};
export default OpenTokenSimulatorProvider;
