import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { messageGroupFields } from '../constants';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { UpdateMessageGroupInput } from '../../types/accountsGlobalTypes';

const MessageGroupForm: React.FC<{
    formBag: FormikProps<UpdateMessageGroupInput>;
    disableLoginButton: boolean;
    actionButtonName?: string;
}> = (props) => {
    const { formBag, disableLoginButton, actionButtonName = 'Add' } = props;

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <GoBackHeader title={actionButtonName} />
                <Form>
                    <GridContainer>
                        <GridHalfWrapper>
                            <TextInput
                                label='Name'
                                name={messageGroupFields.name}
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Description'
                                name={messageGroupFields.description}
                            />
                        </GridHalfWrapper>
                    </GridContainer>

                    <Grid>
                        <Button
                            color='primary'
                            onClick={() => {
                                formBag.submitForm();
                            }}
                            disabled={disableLoginButton}
                        >
                            Save
                        </Button>
                    </Grid>
                </Form>
            </GridItem>
        </GridContainer>
    );
};

export default MessageGroupForm;
