import { FormControl, WithStyles } from '@material-ui/core';
import { ErrorMessage, Form, FormikProps } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import CustomInput from '@components/CustomInput/CustomInput.js';
import Button from '@components/CustomButtons/Button';
import Card from '@components/Card/Card.js';
import CardBody from '@components/Card/CardBody.js';
import CardHeader from '@components/Card/CardHeader.js';
import CardFooter from '@components/Card/CardFooter.js';
import React from 'react';
import { authFormFields } from 'admin/auth/constants/formFields';
import ovsLogo from 'assets/img/ovs-logo.png';
import { useNavigate } from 'react-router-dom';

export interface IResetFormValues {
  // [key: string]: string | undefined;
  password?: string;
  confirmPassword?: string;

}
const RegisterForm: React.FC<
  WithStyles & { isLoading: boolean; formBag: FormikProps<IResetFormValues> }
> = (props) => {
  const { classes, formBag, isLoading } = props;
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('');
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const history = useNavigate();
  return (
    <div className={classes.container}>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={6} md={4}>
          <Form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color='rose'
              >
                <img src={ovsLogo} alt='Ovs Logo' />
                <h4 className={classes.cardTitle}>Reset Password</h4>
              </CardHeader>
              <CardBody>

                <CustomInput
                  labelText='Password'
                  name='password'
                  id='password'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onChange={(e) =>
                    formBag.setFieldValue(
                      authFormFields.register.password,
                      e.target.value
                    )
                  }
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                  }}
                />
                <FormControl>
                  <ErrorMessage name={authFormFields.register.password}>
                    {(msg) => <div className='error-message'>{msg}</div>}
                  </ErrorMessage>
                </FormControl>
                <CustomInput
                  labelText='Confirm Password'
                  name='confirmPassword'
                  id='password'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onChange={(e) =>
                    formBag.setFieldValue(
                      authFormFields.register.confirmPassword,
                      e.target.value
                    )
                  }
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                  }}
                />
                <FormControl>
                  <ErrorMessage name={authFormFields.register.confirmPassword}>
                    {(msg) => <div className='error-message'>{msg}</div>}
                  </ErrorMessage>
                </FormControl>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <FormControl>
                  <Button
                    color='rose'
                    simple
                    size='lg'
                    block
                    onClick={() => {
                      formBag.submitForm();
                    }}
                    disabled={isLoading}
                  >
                    Reset Password
                  </Button>
                </FormControl>
              </CardFooter>
            </Card>
          </Form>
          <span className={`${classes.floatRight} ${classes[cardAnimaton]}`}>
            <p className={classes.whiteBold}>
              {' '}
              Have an account?{' '}
              <span
                className={classes.bolder}
                onClick={() => history('auth/login-page')}
              >
                Login.
              </span>
            </p>
          </span>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default RegisterForm;
