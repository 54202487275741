import { gql, useLazyQuery, useQuery } from "@apollo/client";
// import clientSimulator from "utils/clientSimulator";
import thingMakeClientSimulator from "utils/thingMakeSimulator";
import { itemSkuFragment, pageDataFragment } from "../sku/queries";
import {
  GetAllItemBatches,
  GetAllItemBatchesVariables,
} from "./types/GetAllItemBatches";
import {
  GetAllItemsforItemBatchInDescendingOrder,
  GetAllItemsforItemBatchInDescendingOrderVariables,
} from "./types/GetAllItemsforItemBatchInDescendingOrder";
import {
  GetTotalItemsInItemBatch,
  GetTotalItemsInItemBatchVariables
}
from "./types/GetTotalItemsInItemBatch"
import {
  GetSpecificItemBatch,
  GetSpecificItemBatchVariables,
} from "./types/GetSpecificItemBatch";
import {
  BatchFactoryExport,
  BatchFactoryExportVariables,
} from "./types/BatchFactoryExport";
import { handleGQLErrors } from "utils/gqlErrors";
import useNotifier from "hooks/useNotifier";
/**
 * type ItemBatch {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
type: ActorTypes!
actionScope: ActionScope!
actorName: String!
profile: String!
batchNumber: String!
batchDate: DateTime!
description: String!
batchState: BatchState!
}
 */

export const itemBatchFragmentWithoutProgress = gql`
  ${itemSkuFragment}
  fragment ItemBatch on ItemBatch {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    type
    actionScope
    actorName
    profile
    batchNumber
    batchDate
    description
    batchState
    starting_code
    secret_key
    code_gen_type
    itemSKU {
      ...ItemSKU
    }
  }
`;

export const itemBatchFragment = gql`
  ${itemSkuFragment}
  fragment ItemBatch on ItemBatch {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    type
    actionScope
    actorName
    profile
    batchNumber
    actionProgress
    batchDate
    description
    batchState
    starting_code
    secret_key
    code_gen_type
    itemSKU {
      ...ItemSKU
    }
  }
`;
/**
 * type ItemBatchEdge {
cursor: String
node: ItemBatch
}
 */
export const itemBatchEdgeFragment = gql`
  ${itemBatchFragment}
  fragment ItemBatchEdge on ItemBatchEdge {
    cursor
    node {
      ...ItemBatch
    }
  }
`;
/**
 * type ItemBatchPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
export const itemBatchPageInfo = gql`
  fragment ItemBatchPageInfo on ItemBatchPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type ItemBatchConnection {
edges: [ItemBatchEdge!]
pageInfo: ItemBatchPageInfo
}
 */
export const itemBatchConnectionFragment = gql`
  ${itemBatchEdgeFragment}
  ${itemBatchPageInfo}
  fragment ItemBatchConnection on ItemBatchConnection {
    edges {
      ...ItemBatchEdge
    }
    pageInfo {
      ...ItemBatchPageInfo
    }
  }
`;

/**
 * type GetAllItemBatchesResponse {
page: ItemBatchConnection!
pageData: PageData
}
 */
export const getAllItemBatchesResponseFragment = gql`
  ${itemBatchConnectionFragment}
  ${pageDataFragment}
  fragment GetAllItemBatchesResponse on GetAllItemBatchesResponse {
    page {
      ...ItemBatchConnection
    }
    pageData {
      ...PageData
    }
  }
`;

/**
 * query {
  getAllItemBatches {
    _id
    profile
  }
}
 */
export const getAllItemBatches = gql`
  ${getAllItemBatchesResponseFragment}
  query GetAllItemBatches(
    $queryorder: QueryOrder!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $search: String
  ) {
    getAllItemBatches(
      before: $before
      after: $after
      first: $first
      last: $last
      queryorder: $queryorder
      search: $search
    ) {
      ...GetAllItemBatchesResponse
    }
  }
`;

export const getAllItemBatchesSimple = gql`
  query GetAllItemBatchesSimple(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllItemBatches(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      page {
        edges {
          node {
            _id
            batchNumber
          }
        }
      }
      __typename
    }
  }
`;

/**
 * 
 * getSpecificItemBatch(
id: String!
): ItemBatch!
 */
const getSpecificItemBatchQuery = gql`
  ${itemBatchFragment}
  query GetSpecificItemBatch($id: String!) {
    getSpecificItemBatch(id: $id) {
      ...ItemBatch
    }
  }
`;

/**
 * 
 *  getAllItems(
      first: 1
      search: "45AH24020002"
      queryorder: DESC
    ) {
      pageData {
        count
      }
    }

 */

const getTotalItemsInItemBatchQuery = gql`
  query GetTotalItemsInItemBatch($search: String) {
    getAllItems(
      first: 1
      search: $search
      queryorder: DESC
    ) {
      pageData {
        count
      }
    }
  }
`;

/**
 * 
 * getAllItemsforItemBatchInDescendingOrder(
itemBatch: ID!
before: String
after: String
first: Int
last: Int
): GetAllItemsResponse!
 * @returns 
 */
export const getAllItemsforItemBatchInDescendingOrderQuery = gql`
  query GetAllItemsforItemBatchInDescendingOrder(
    $itemBatch: ID!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllItemsforItemBatchInDescendingOrder(
      itemBatch: $itemBatch
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageData {
        count
      }
      page {
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        edges {
          node {
            _id
            codeGenerator {
              _id
            }
            itemFleet {
              _id
            }
          }
        }
      }
    }
  }
`;

/*
Query.batchFactoryExport(
batchId: String!
): [BatchFactoryExportDto!]!*/

export const batchFactoryExportQuery = gql`
  query BatchFactoryExport($batchId: String!) {
    batchFactoryExport(batchId: $batchId) {
      oemItemID
      sellerItemID
      hashTop
      hashRoot
      batchNumber
      skuName
      codeDecString
    }
  }
`;

export const useBatchFactoryExportQuery = (
  variables: BatchFactoryExportVariables
) =>
  useLazyQuery<BatchFactoryExport, BatchFactoryExportVariables>(
    batchFactoryExportQuery,
    {
      variables,
      client: thingMakeClientSimulator,
    }
  );
export const useGetAllItemsforItemBatchInDescendingOrderQuery = (
  variables: GetAllItemsforItemBatchInDescendingOrderVariables
) =>
  useLazyQuery<
    GetAllItemsforItemBatchInDescendingOrder,
    GetAllItemsforItemBatchInDescendingOrderVariables
  >(getAllItemsforItemBatchInDescendingOrderQuery, {
    client: thingMakeClientSimulator,
    variables,
  });
export const useLazyGetAllItemsforItemBatchInDescendingOrderQuery = (
  variables: GetAllItemsforItemBatchInDescendingOrderVariables
) =>
  useLazyQuery<
    GetAllItemsforItemBatchInDescendingOrder,
    GetAllItemsforItemBatchInDescendingOrderVariables
  >(getAllItemsforItemBatchInDescendingOrderQuery, {
    client: thingMakeClientSimulator,
    variables,
  });
export const useGetAllItemBatches = (variables: GetAllItemBatchesVariables) =>
  useQuery<GetAllItemBatches, GetAllItemBatchesVariables>(getAllItemBatches, {
    variables,
    client: thingMakeClientSimulator,
  });

export const useLazyGetAllItemBatches = (
  variables: GetAllItemBatchesVariables
) =>
  useLazyQuery<GetAllItemBatches, GetAllItemBatchesVariables>(
    getAllItemBatches,
    {
      variables,
      client: thingMakeClientSimulator,
    }
  );

export const useGetSpecificItemBatchQuery = (
  variables: GetSpecificItemBatchVariables
) =>
  useQuery<GetSpecificItemBatch, GetSpecificItemBatchVariables>(
    getSpecificItemBatchQuery,
    {
      client: thingMakeClientSimulator,
      variables,
    }
  );

  export const useLazyGetTotalItemsInItemBatchQuery = (
    variables: GetTotalItemsInItemBatchVariables
  ) => {
    const notify = useNotifier();
    return useLazyQuery<
      GetTotalItemsInItemBatch,
      GetTotalItemsInItemBatchVariables
    >(getTotalItemsInItemBatchQuery, {
      variables,
      client: thingMakeClientSimulator,
      onError: (error) => {
        handleGQLErrors(notify, error);
      },
    });
  };
