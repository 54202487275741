import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
// import { handleGQLErrors } from 'utils/gqlErrors';
import clientAccount from '../../utils/clientAccount';
import { activateCreditAccountMutation } from '../mutations';
import {
  ActivateCreditAccount,
  ActivateCreditAccountVariables,
} from '../types/ActivateCreditAccount';

const useActivateCreditccount = ({
  onComplete,
}: {
  onComplete?: () => void;
}) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    ActivateCreditAccount,
    ActivateCreditAccountVariables
  >(activateCreditAccountMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      } else {
        notify({ text: 'Accounted activated successfully', status: 'success' });
      }
    },
    onError: (err) => {
      // handleGQLErrors(notify, err); Todo: handle credit activation errors
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useActivateCreditccount;
