import { useQuery, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import clientSimulator from "utils/clientSimulator";

import { 
  GetSpecificMessageGroup, 
  GetSpecificMessageGroupVariables 
} from "./types/GetSpecificMessageGroup";

/**
 * 
 *getSpecificMessageGroup(
id: ID!
): MessageGroup!
 */

/**
 * type Person {
_id: ID!
address: Address!
assetAccount: AssetAccount
contact: Contact!
createdAt: DateTime
creditAccount: CreditAccount
deleteAt: DateTime
deleteStatus: Boolean
description: String
distributor: Distributor
name: String!
organization: Organization
servicer: Servicer
supplier: Supplier
type: PersonTypes!
updatedAt: DateTime
}
 */
export const personFragment = gql`
  fragment Person on Person {
    _id
    address {
      addressLocation {
        addressLatitude
        addressLongitude
      }
      city
      country
      postcode
      srpc
      street
      unit
    }

    contact {
      email
      phone
      social
    }
    createdAt

    deleteAt
    deleteStatus
    description
    distributor {
      _id
      name
    }
    name
    type
    updatedAt
  }
`;
/**
 * type PersonEdge {
cursor: String
node: Person
}
 */
const personEdgeFragment = gql`
  ${personFragment}
  fragment PersonEdge on PersonEdge {
    cursor
    node {
      ...Person
    }
  }
`;
/**
 * type PersonPageInfo {
endCursor: String
hasNextPage: Boolean!
hasPreviousPage: Boolean!
startCursor: String
}
 */
const personPageInfoFragment = gql`
  fragment PersonPageInfo on PersonPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type PersonConnection {
edges: [PersonEdge!]
pageInfo: PersonPageInfo
}
 */
const personConnectionFragment = gql`
  ${personPageInfoFragment}
  ${personEdgeFragment}
  fragment PersonConnection on PersonConnection {
    edges {
      ...PersonEdge
    }
    pageInfo {
      ...PersonPageInfo
    }
  }
`;
/**
 * type PageData {
count: Float!
limit: Float!
offset: Float!
}
 */
const pageDataFragment = gql`
  fragment PageData on PageData {
    count
    limit
    offset
  }
`;
/**
 * type GetAllPersonsResponse {
page: PersonConnection!
pageData: PageData
}
 */
export const getAllPersonsResponseFragment = gql`
  ${personConnectionFragment}
  ${pageDataFragment}
  fragment GetAllPersonsResponse on GetAllPersonsResponse {
    page {
      ...PersonConnection
    }
    pageData {
      ...PageData
    }
  }
`;
export const getSpecificMessageGroupQuery = gql`
  ${getAllPersonsResponseFragment}
  query GetSpecificMessageGroup($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
    getSpecificMessageGroup(id: $id) {
      _id
      name
      description
      personIds
      persons(before: $before, after: $after, first: $first, last: $last) {
        ...GetAllPersonsResponse
      }
    }
  }
`;



export const useLazyGetSpecificMessageGroupsQuery = (
  variables: GetSpecificMessageGroupVariables

) =>
  useLazyQuery<GetSpecificMessageGroup, GetSpecificMessageGroupVariables>(getSpecificMessageGroupQuery, {
    variables,
    client: clientSimulator,
  });

export const useGetSpecificMessageGroupQuery = (
  variables: GetSpecificMessageGroupVariables
) =>
  useQuery<GetSpecificMessageGroup, GetSpecificMessageGroupVariables>(
    getSpecificMessageGroupQuery,
    {
      client: clientSimulator,
      variables,
    }
  );

  
