import { legacyApi } from 'apps/migration/app/services/legacyApi';
import { legacyBatchTypes } from './constants';
export class LegacyBatchActions {
  static listLegacyBatch(data: any) {
    return {
      type: legacyBatchTypes.LIST_LEGACY_BATCH,
      payload: { data },
    };
  }

  static listLegacyBatchSuccess(data: any) {
    return {
      type: legacyBatchTypes.LIST_LEGACY_BATCH_SUCCESS,
      payload: { data },
    };
  }
  static listLegacyBatchFail(data: any) {
    return {
      type: legacyBatchTypes.LIST_LEGACY_BATCH_FAIL,
      payload: { data },
    };
  }

  static getLegacyBatch(params: string) {
    return legacyApi.get(`product/batch?${params}`);
  }

  static getOneItem(id: string) {
    return legacyApi.get(`products/batch/${id}`);
  }

  static deleteItem(id: string) {
    return legacyApi.delete(`products/batch/${id}`);
  }

  static updateLegacyItem(id: string, payload: any) {
    return legacyApi.put(`products/batch/${id}`, payload);
  }

  static createLegacyRequest(payload: any) {
    return legacyApi.post(`product/batch`, payload);
  }

  static provisionBatchRequest(payload: any) {
    return legacyApi.post(`provision/`, payload);
  }

  static initializeBatchItems(payload: any) {
    return legacyApi.post(
      `products/initialization/batch/${payload.productBatchID}`,
      payload
    );
  }

  static batchAssign(payload: any) {
    return legacyApi.post(
      `products/assign/batch/${payload.productBatchID}`,
      payload
    );
  }

  static migrateItemsInBatch(payload: {
    productBatchID: string;
    productBatchNumber: string;
  }) {
    return legacyApi.post(`batch/migration/${payload.productBatchID}`, payload);
  }

  static bulkMigrateItemsInBatch() {
    return legacyApi.post(`/batch/bulk-migrate`);
  }

  static downloadProductItemsCSV(productBatchID: string) {
    return legacyApi.get(`products/export/${productBatchID}`);
  }

  static downloadProductsForFactoryCSV(productBatchID: string) {
    return legacyApi.get(
      `batch/export-batch-factory-products/${productBatchID}`
    );
  }

  static updateOtpState(productBatchID: string) {
    return legacyApi.post(`/batch/update-otp-state/${productBatchID}`);
  }

  static updateOtpStateFrom(productBatchID: string) {
    return legacyApi.post(`/batch/update-otp-state-from/${productBatchID}`);
  }

  static downloadERMProductsForFactoryCSV(productBatchID: string) {
    return legacyApi.get(
      `batch/export-erm-batch-factory-products/${productBatchID}`
    );
  }
  static batchCode(payload: any) {
    return legacyApi.post(
      `products/batch-code/${payload.productBatchID}`,
      payload
    );
  }

  static batchFreeCode(payload: any) {
    return legacyApi.post(
      `products/batch-free-code/${payload.productBatchID}`,
      payload
    );
  }
  
  static assignItem(payload: any) {
    return legacyApi.post(`product/assign`, payload);
  }
}
