import { routes as avatarRoutes } from "./avatar"
import { routes as batchRoutes } from "./batch"
import { routes as brokerRoutes } from "./broker"
import { routes as checkinRoutes } from "./checkInOut"
import { routes as codeGenRoutes } from "./codeGenerator"
import { routes as adminRoutes } from "./dashboard"
import { routes as tigStackRoutes } from "./tigstack"
import { routes as firmwareRoutes } from "./firmware"
import { routes as fleetRoutes } from "./fleet"
import { routes as itemRoutes } from "./item"
import { routes as signalRoutes } from "./signal"
import {routes as skuRoutes} from './sku'
import {routes as codeEventsRoutes} from "./codeEvent"
import {routes as openTokenSimulatorRoutes} from "./openTokenSimulator"
export const thingRoutes = [
   avatarRoutes,
   batchRoutes,
   brokerRoutes,
   checkinRoutes,
   codeGenRoutes,
   adminRoutes,
   tigStackRoutes,
   firmwareRoutes,
   fleetRoutes,
   itemRoutes,
   signalRoutes,
   skuRoutes,
   codeEventsRoutes,
   openTokenSimulatorRoutes
]