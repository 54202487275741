import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import { Row } from 'react-table';
import ItemFirmwareActions from '../components/ItemFirmwareActions';

export const itemFirmwareTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <ItemFirmwareActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: 'Code System',
      accessor: 'node.codeSystem',
    },
    {
      Header: 'Version',
      accessor: 'node.version',
    },
    {
      Header: 'Description',
      accessor: 'node.description',
      Cell: DescriptionCell,
    },
    {
      Header: 'Actor Name',
      accessor: 'node.actorName',
    },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Profile',
      accessor: 'node.profile',
    },
    {
      Header: 'Type',
      accessor: 'node.type',
    },

    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
    { Header: 'ID', accessor: 'node._id' },
  ],
});
