import GridContainer from '@components/Grid/GridContainer';
import { Grid } from '@material-ui/core';
import React from 'react';
import Loader from './Loader';

const GridLoader = () => {
  return (
    <GridContainer justify='center'>
      <Grid
        style={{ textAlign: 'center' }}
      >
        <Loader />
      </Grid>
    </GridContainer>
  );
};

export default GridLoader;
