import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { isEmptyArray } from 'formik';
import { GetApp } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import useNotifier from 'hooks/useNotifier';
const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

export interface Links { name: string, url: string }

const LinksTable = ({ rows }: { rows: Links[] }) => {
	const classes = useStyles();
	const notify = useNotifier()

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell >URL</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.name}>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell >{row.url}</TableCell>
							<TableCell>
								<div>
									<CopyToClipboard text={row.url}
										onCopy={() => notify({ text: 'URL copied.', status: 'success' })}>
										<FileCopyIcon />
									</CopyToClipboard>
									<a
										rel='noopener noreferrer'
										href={row.url}
										target='_blank'
									>
										<GetApp color="primary" />
									</a>
								</div>

							</TableCell>
						</TableRow>
					))}
					{isEmptyArray(rows) ? (
						<TableRow> <TableCell colSpan={2}>no records found</TableCell> </TableRow>
					) : null}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default LinksTable
