import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    maxWidth: '80vh',
  },
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '29px',
    width: '24px',
    height: '24px',
  },
  header: {
    backgroundColor: '#2EACC1',
    position: 'relative',
    margin: '0 0 15px',
    padding: '14px 0',
    textAlign: 'center',
  },
  heading: {
    fontSize: '1.2rem',
    color: 'white',
  },
  content: {
    padding: '0 32px 30px',
  },
});

interface IProps {
  children: React.ReactNode;
  isModalOpen: boolean;
  toggleModal: (open: boolean) => void;
  title?: string;
  styles?: any;
  anchor?: 'right' | 'left'
}
const DrawerSidebar: React.FC<IProps> = ({
  children,
  isModalOpen,
  toggleModal,
  title = '',
  styles = {},
  anchor='right'
}) => {
  const classes = useStyles();
  return (
    <div>
      <Drawer
        anchor={anchor}
        open={isModalOpen}
        onClose={(e, r) => {
          toggleModal(false);
        }}
      >
        <div className={classes.container} style={styles}>
          <div className={classes.header}>
            <header className={classes.heading}>{title}</header>
            <div className={classes.closeBtn}>
              <IconButton onClick={() => toggleModal(false)}>
                <ClearIcon style={{ fill: 'white' }} />
              </IconButton>
            </div>
          </div>
          <div className={classes.content}>{children}</div>
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerSidebar;
