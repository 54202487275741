import React from 'react';
import LegacyPage from '../components/LegacyPage';

interface IProps {}

const LegacyContainer: React.FC<IProps> = () => {
  return <LegacyPage />;
};

export default LegacyContainer;
