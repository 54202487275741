import {
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const chipStyle = {
  chip: {
    // margin: '0.5rem',
    '& .MuiChip-deleteIcon': {
      color: `${dangerColor[0]}`,
    },
    fontWeight: 400,
    height:  '26px'
  },
  fullWidth: {
    width: "100%"
  },
  primary: {
    backgroundColor: `rgba(${hexToRgb(primaryColor[0])}, 0.1)`,
    color: `${blackColor[0]}`,
    borderColor: `rgba(${hexToRgb(primaryColor[0])}, 0.6)`,
  },
  primaryNoBackground: {
    color: `${primaryColor[0]}`,
    border: ` 1px solid rgba(${hexToRgb(primaryColor[0])})`,
  },
  info: {
    backgroundColor: `rgba(${hexToRgb(infoColor[0])}, 0.1)`,
    color: `${blackColor[0]}`,
    borderColor: `rgba(${hexToRgb(infoColor[0])}, 0.6)`,
  },
  infoNoBackground: {
    color: `${infoColor[0]}`,
    border: ` 1px solid rgba(${hexToRgb(infoColor[0])})`,
  },
  success: {
    backgroundColor: `rgba(${hexToRgb(successColor[0])}, 0.1)`,
    color: `${blackColor[0]}`,
    borderColor: `rgba(${hexToRgb(successColor[0])}, 0.6)`,
  },
  successNoBackground: {
    color: `${successColor[0]}`,
    border: ` 1px solid rgba(${hexToRgb(successColor[0])})`,
  },
  warning: {
    backgroundColor: `rgba(${hexToRgb(warningColor[0])}, 0.1)`,
    color: `${blackColor[0]}`,
    borderColor: `rgba(${hexToRgb(warningColor[0])}, 0.6)`,
  },
  warningNoBackground: {
    color: `${warningColor[0]}`,
    border: ` 1px solid rgba(${hexToRgb(warningColor[0])})`,
  },
  danger: {
    backgroundColor: `rgba(${hexToRgb(dangerColor[0])}, 0.1)`,
    color: `${blackColor[0]}`,
    borderColor: `rgba(${hexToRgb(dangerColor[0])}, 0.6)`,
  },
  dangerNoBackground: {
    color: `${dangerColor[0]}`,
    border: ` 1px solid rgba(${hexToRgb(dangerColor[0])})`,
  },
  default: {
    backgroundColor: `rgba(${hexToRgb(blackColor)}, 0.1)`,
    color: `${blackColor}`,
    borderColor: `rgba(${hexToRgb(blackColor)}, 0.6)`,
  },
  defaultNoBackground: {
    color: `${primaryColor[0]}`,
    border: ` 1px solid rgba(${hexToRgb(primaryColor[0])})`,
  },
  primaryIcon: {
    color: `${primaryColor[0]}`,
  },
  infoIcon: {
    color: `${infoColor[0]}`,
  },
  successIcon: {
    color: `${successColor[0]}`,
  },
  warningIcon: {
    color: `${warningColor[0]}`,
  },
  dangerIcon: {
    color: `${dangerColor[0]}`,
  },
  defaultIcon: {
    color: `${primaryColor[0]}`,
  },
  accountNumber: {
    height: "18px"
  },
  successAccountNumber: {
    color: `${successColor[0]}`,
    height: "18px",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginRight: "-10px"
  },
  dangerAccountNumber: {
    color: `${dangerColor[0]}`,
    height: "18px",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginRight: "-10px"
  },
  defaultAccountNumber: {
    color: `${blackColor}`,
    height: "18px",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginRight: "-10px"
  }
  
};


export default chipStyle