import DescriptionCell from '@components/ReactTable/DescriptionCell';
import ImageCell from 'apps/ecommerce/app/products/components/TableCells/ImageCell';
import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyTableActions';

export const legacyTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <Actions
          {...row.getToggleRowExpandedProps()}
          row={row}
        />
      ),
    },
    { Header: 'productCategoryID', accessor: 'productCategoryID' },
    {
      Header: 'productCategoryName',
      accessor: 'productCategoryName',
    },
    {
      Header: 'productCategoryDescription',
      accessor: 'productCategoryDescription',
      Cell: DescriptionCell,
    },
    {
      Header: 'Image',
      accessor: 'image',
      Cell: ImageCell
    },
  ],
};
