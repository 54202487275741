import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { UpdateOrganizationInput } from '../../types/clientServiceGlobalTypes';

const ItemForm: React.FC<{
  formBag: FormikProps<UpdateOrganizationInput>;
  disableLoginButton: boolean;
  actionButtonName?: string;
  productBase?: string;
}> = (props) => {
  const { formBag, disableLoginButton, actionButtonName = 'Add' } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridHalfWrapper>
              <TextInput label='orgName' name={itemFields.orgName} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='orgProfile' name={itemFields.orgProfile} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Description'
                name={itemFields.orgDescription}
                multiline
              />
            </GridHalfWrapper>
            <GridItem xs={12} sm={12} md={12}>
              <h6>Address Details</h6>
            </GridItem>
            <GridHalfWrapper>
              <TextInput label='city' name={itemFields.city} multiline />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Country' name={itemFields.country} multiline />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Postcode'
                name={itemFields.postcode}
                multiline
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='srpc' name={itemFields.srpc} multiline />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='street' name={itemFields.street} multiline />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='unit' name={itemFields.unit} multiline />
            </GridHalfWrapper>
            <GridItem xs={12} sm={12} md={12}>
              <h6>Location</h6>
            </GridItem>
            <GridHalfWrapper>
              <TextInput
                label='inputAddressLongitude'
                name={itemFields.inputAddressLongitude}
                multiline
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='inputAddressLatitude'
                name={itemFields.inputAddressLatitude}
                multiline
              />
            </GridHalfWrapper>
          </GridContainer>

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
