import GridItem from "@components/Grid/GridItem";
import { Tooltip, Typography, Button as MDButton } from "@material-ui/core";
import { FieldArray, FormikProps } from "formik";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import AssignItemForm from "./AssignItemForm";
import _ from "lodash";
import { useLazyGetAllItemsQuery } from "apps/things/app/item/queries";
import { QueryOrder } from "admin/types/globalTypes";
import { useMutation } from "@apollo/client";
import {
  AssignItemToItemFleet,
  AssignItemToItemFleetVariables,
} from "../../types/AssignItemToItemFleet";
import { assignItemToItemFleetMutation } from "../../mutations";
import clientSimulator from "utils/clientSimulator";
import useNotifier from "hooks/useNotifier";
import { ItemContext } from "apps/things/app/item/context/ItemProvider";
import { FleetContext } from "../../context/FleetProvider";
import Button from "@components/CustomButtons/Button";
interface IProps {
  oemItemIds: { oemItemId: string; itemId: string }[];
}

const AssignItems: React.FC<{
  formBag: FormikProps<IProps>;
  setAssignItemsOpen: (open: boolean) => void;
  itemFleetId: string;
}> = ({ formBag, setAssignItemsOpen, itemFleetId }) => {
  const [oemItemIds, setOemItems] = useState([] as string[]);
  const [itemIds, setItemIds] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const notify = useNotifier();
  const { refetchItems } = useContext(ItemContext);
  const { refetchItems: refetch } = useContext(FleetContext);

  const [searchItem, { data, loading: searching }] = useLazyGetAllItemsQuery({
    first: 1,
    search: "",
    queryorder: QueryOrder.DESC,
  });

  useEffect(() => {
    if (!data || searching) return;
    const itemId = _.get(data, "getAllItems.page.edges[0].node._id");
    if (itemId) {
      const oemItemId = _.get(data, "getAllItems.page.edges[0].node.oemItemID");

      const index = formBag.values.oemItemIds.findIndex(
        (item) => item.oemItemId === oemItemId
      );
      if (index !== -1) {
        formBag.setFieldValue(`oemItemIds[${index}].itemId`, itemId);

        if (_.get(formBag, `values.oemItemIds[${index + 1}].oemItemId`)) {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            searchItem({
              variables: {
                first: 1,
                search: formBag.values.oemItemIds[
                  index + 1
                ].oemItemId?.replaceAll(" ", ""),
                queryorder: QueryOrder.DESC,
              },
            });
          }, 1000);
        } else {
          const idx = formBag.values.oemItemIds?.findIndex(
            (item) => !item.itemId
          );

          if (idx === -1) {
            notify({ text: "Some oemItemId are not valid!", status: "error" });
          } else {
            handleAssignItemsToFleet();
          }
        }
      } else {
        alert("not found");
      }
    } else {
      notify({ text: "Some oemItemId are not valid!", status: "error" });
    }
    setItemIds((prev) => [...prev, itemId || ""]); // eslint-disable-next-line
  }, [data, searching]);

  const searchItemIds = () => {
    if (oemItemIds.length) {
      searchItem({
        variables: {
          first: 1,
          search: oemItemIds[0],
          queryorder: QueryOrder.DESC,
        },
      });
    }
  };

  useEffect(() => {
    searchItemIds(); // eslint-disable-next-line
  }, [oemItemIds]);

  const [assign] = useMutation<
    AssignItemToItemFleet,
    AssignItemToItemFleetVariables
  >(assignItemToItemFleetMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {},
  });

  const handleAssignItemsToFleet = () => {
    if (oemItemIds.length === 0) {
      return notify({
        status: "error",
        text: "Please upload a list of oemItemIds",
      });
    }

    setLoading(true);
    Promise.all(
      formBag.values.oemItemIds
        .filter((item) => item.itemId)
        .map((item) =>
          assign({
            variables: {
              assignItemToItemFleetInput: {
                itemId: item.itemId,
                itemFleetId,
              },
            },
          })
        )
    )
      .then(async (res) => {
        notify({
          status: "success",
          text: "Items assigned successfully",
        });
        setOemItems([]);
        /// refetch items & fleets
        refetchItems && refetchItems();
        refetch && refetch();
        setAssignItemsOpen(false);
      })
      .catch((error) => {
        notify({ text: error?.toString(), status: "error" });
        setLoading(false);
      });
  };
  useEffect(() => {
    if (itemIds.length && itemIds.length === oemItemIds.length) {
      // handleAssignItemsToFleet()
      console.warn("all resolved submit", itemIds);
    } // eslint-disable-next-line
  }, [oemItemIds]);
  return (
    <>
      <FieldArray
        name={"oemItemIds"}
        render={(arrayHelpers) => {
          const remove = (index: number) => {
            arrayHelpers.remove(index);
          };
          const add = () => {
            arrayHelpers.push({
              oemItemId: "",
              itemId: "",
            });
          };
          return (
            <>
              {formBag.values.oemItemIds.map((item, index) => {
                return (
                  <AssignItemForm
                    formBag={formBag}
                    index={index}
                    key={`oemItemIds-${index}`}
                    value={item.oemItemId || ""}
                    add={add}
                    remove={remove}
                  />
                );
              })}
              <Typography>
                <Tooltip id="tooltip-top" title="Add" placement="top">
                  <MDButton
                    onClick={() => {
                      add();
                    }}
                  >
                    <AddIcon />
                  </MDButton>
                </Tooltip>
              </Typography>
              <GridItem md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    setOemItems([]);
                    setOemItems(
                      formBag.values.oemItemIds
                        ?.filter((item) => item.oemItemId)
                        .map((it) => it.oemItemId) || []
                    );
                  }}
                  disabled={loading || searching}
                >
                  Save
                </Button>
              </GridItem>
            </>
          );
        }}
      />
    </>
  );
};

export default AssignItems;
