import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import { itemFirmwareRoutes as itemRoutes } from "./constants";
import Add from "./containers/AddItemFirmwareContainer";
import Edit from "./containers/EditItemFirmwareContainer";
import List from "./containers/ItemFirmContainer";

interface IProps {}

export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: itemRoutes.add, element: <Add /> },
    { path: itemRoutes.edit, element: <Edit /> },
  ],
};

const ItemFirmwareIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default ItemFirmwareIndex;
