export const itemFields = {
  assignDate: 'assignDate',
  description: 'description',
  fleetName: 'fleetName',
  itemList: 'itemList',
  clientId: 'clientId',
  resetCodeCount: 'resetCodeCount',
  freeCodeCount: 'freeCodeCount',
  dayCodeCountLimit: 'dayCodeCountLimit',
  totalCodeCountLimit: 'totalCodeCountLimit',
  codeGenInterval: 'codeGenInterval',
  maxCreditStackDays: 'maxCreditStackDays',
  maxCreditStackCodeEvents: 'maxCreditStackCodeEvents',
  disableFreeCode: 'disableFreeCode',
  daysToCheckDuration: "daysToCheckDuration",
  minimumDayCodesGenerated: "minimumDayCodesGenerated",
  disableResetCodeCount: "disableResetCodeCount",
  disableDayCodeCountLimit: "disableDayCodeCountLimit",
  disableTotalCodeCountLimit: "disableTotalCodeCountLimit",
  disableCodeGenInterval: "disableCodeGenInterval",
  disableMaxCreditStackDays: "disableMaxCreditStackDays",
  disableMaxCreditStackCodeEvents: "disableMaxCreditStackCodeEvents",
  disableDaysToCheckDuration: "disableDaysToCheckDuration",
  disableMinimumDayCodesGenerated: "disableMinimumDayCodesGenerated"
};

export const defaultItem = {
  assignDate: new Date(),
  description: '',
  fleetName: '',
  itemList: [],
  clientId: '',
  itemFleetId: '',
  resetCodeCount: 10,
  freeCodeCount: 10,
  dayCodeCountLimit: null,
  totalCodeCountLimit: null,
  codeGenInterval: 6000,
  maxCreditStackDays: null,
  maxCreditStackCodeEvents: null,
  disableResetCodeCount: true,
  disableDayCodeCountLimit: false,
  disableTotalCodeCountLimit: false,
  disableCodeGenInterval: true,
  disableMaxCreditStackDays: false,
  disableMaxCreditStackCodeEvents: false,
  disableDaysToCheckDuration: false,
  disableMinimumDayCodesGenerated: false,
  disableFreeCode: true
};

export const itemRoutes = {
  list: '/admin/thing-fleet',
  add: '/admin/thing-fleet/add',
  edit: '/admin/thing-fleet/:itemId',
  getEditLink: (itemId: string) => `/admin/thing-fleet/${itemId}`,
};
