import TextInput from '@components/CustomInput/TextInput';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { itemSKUFields } from '../../constants';
import RemoveIcon from '@material-ui/icons/Remove';
import { useMutation } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import {
  DeleteGATTPropertiesFromItemSKU,
  DeleteGATTPropertiesFromItemSKUVariables,
} from '../../types/DeleteGATTPropertiesFromItemSKU';
import { deleteGATTPropertiesFromItemSKUMutation } from '../../mutations';

interface IProps {
  index: number;
  remove: (index: number) => void;
  isEdit?: boolean;
  itemSkuId?: string;
  propValue?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(3),
      width: 50,
    },
  })
);

const GattForm: React.FC<IProps> = ({
  index,
  remove,
  isEdit = false,
  itemSkuId = '',
  propValue = '',
}) => {
  const classes = useStyles();
  const [deleteAttribute] = useMutation<
    DeleteGATTPropertiesFromItemSKU,
    DeleteGATTPropertiesFromItemSKUVariables
  >(deleteGATTPropertiesFromItemSKUMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
    },
  });
  return (
    <GridContainer>
      <GridItem sm={1}>
        <Button
          title='remove'
          className={classes.selectEmpty}
          onClick={() => {
            remove(index);
            if (isEdit) {
              deleteAttribute({
                variables: {
                  itemSkuId,
                  propValue,
                },
              });
            }
          }}
        >
          <RemoveIcon />
        </Button>
      </GridItem>
      <GridItem sm={3}>
        <TextInput
          label='Prop'
          name={`${itemSKUFields.addItemSKU.gattAttribute}[${index}].prop`}
        />
      </GridItem>
      <GridItem sm={3}>
        <TextInput
          label='Value'
          name={`${itemSKUFields.addItemSKU.gattAttribute}[${index}].value`}
        />
      </GridItem>
      <GridItem sm={3}>
        <TextInput
          label='Meta'
          name={`${itemSKUFields.addItemSKU.gattAttribute}[${index}].meta`}
        />
      </GridItem>
    </GridContainer>
  );
};

export default GattForm;
