import React, { useState, useContext } from 'react';
import { Row } from 'react-table';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from "@material-ui/icons/Delete";
import { useNavigate } from 'react-router-dom';
import { messageTemplateRoutes } from '../constants';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { useMutation } from '@apollo/client';
import { MessageTemplateContext } from '../context/MessageTemplateProvider';
import { DeleteMessageTemplate, DeleteMessageTemplateVariables } from "../types/DeleteMessageTemplate"
import { deleteMessageTemplateMutation } from '../mutations';
import clientSimulator from 'utils/clientSimulator';
import useNotifier from 'hooks/useNotifier';
import ActionModal from '@components/Modal/ActionModal';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
interface IProps {
    row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
    const classes = useStyles();
    const history = useNavigate();
    const notify = useNotifier()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { refetchItems: refetch } = useContext(MessageTemplateContext)
    const [deleteMessageTemplate] = useMutation<DeleteMessageTemplate, DeleteMessageTemplateVariables>(
        deleteMessageTemplateMutation,
        {
            client: clientSimulator,
            onCompleted: (data) => {
                notify({
                    status: "success",
                    text: "Item deleted successfully",
                });
                refetch && refetch();
                setIsModalOpen(false);
            },
        }
    );
    return (
        <div className='actions-right' style={{ display: 'flex' }}>
            <Tooltip
                title="Delete"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
            >
                <IconButton
                    id="expander"
                    aria-label="Delete"
                    className={classes.tableActionButton}
                    onClick={() => setIsModalOpen(true)}
                >
                    <Delete
                        className={classes.tableActionButtonIcon + " " + classes.delete}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip
                id='expander'
                onClick={() => {
                    history(messageTemplateRoutes.getEditLink(row.values['node._id']));
                }}
                title='Edit'
                placement='top'
                classes={{ tooltip: classes.tooltip }}
            >
                <IconButton
                    id='expander'
                    aria-label='Edit'
                    className={classes.tableActionButton}
                >
                    <Edit
                        className={classes.tableActionButtonIcon + ' ' + classes.edit}
                    />
                </IconButton>
            </Tooltip>

            <HoverDropdown
                isDistributor={true}
                menus={[
                    {
                        name: "",
                        action: () => {
                        },
                    },
                ]}
            />
            {isModalOpen ? (
                <ActionModal
                    isModalOpen={isModalOpen}
                    toggleModal={setIsModalOpen}
                    handleOnClickOkey={() =>
                        deleteMessageTemplate({ variables: { id: row.values["node._id"] } })
                    }
                    handleOnClickCancel={() => setIsModalOpen(false)}
                    okText="Delete"
                >
                    <div>Are you sure you want to delete this Message Template?</div>
                </ActionModal>
            ) : null}
        </div>
    );
};

export default TableActions;
