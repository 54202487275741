import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { GetAllAuthenticationInstances_getAllAuthenticationInstances } from 'admin/Role/types/GetAllAuthenticationInstances';
import { GetAllPermissions_getAllPermissions } from 'admin/Role/types/getAllPermissions';
import { GetAllPermissionsResponse } from 'admin/Role/types/GetAllPermissionsResponse';
import { GetAllRoles_getAllRoles } from '../types/GetAllRoles';
import {
  GetAllRolesResponse,
  GetAllRolesResponse_page_edges,
} from '../types/GetAllRolesResponse';
import { useLazyGetAllRolesQuery, useLazyGetSpecificRolePermissionsQuery } from '../queries';
import {  GetSpecificRolePermissionsVariables, GetSpecificRolePermissions_getSpecificRolePermissions_page_edges } from '../types/GetSpecificRolePermissions';
import { useAuth } from 'admin/auth/context/AuthProvider';
import _ from 'lodash'
import { QueryLazyOptions } from '@apollo/client';
export interface IRoleContext {
  roles: GetAllRolesResponse;
  items: GetAllRolesResponse_page_edges[];
  setItems: (arg: GetAllRolesResponse_page_edges[]) => void;
  setRoles: (roles: GetAllRoles_getAllRoles) => void;
  loading: boolean;
  permissions: GetAllPermissionsResponse;
  setPermissions: (permissions: GetAllPermissions_getAllPermissions) => void;
  authenticationInstances: GetAllAuthenticationInstances_getAllAuthenticationInstances;
  setAuthenticationIntances: (
    authInstances: GetAllAuthenticationInstances_getAllAuthenticationInstances
  ) => void;
  getItems: () => void;

  specificRolePermissions: GetSpecificRolePermissions_getSpecificRolePermissions_page_edges[]
  setSpecificRolePermissions: (permissions:GetSpecificRolePermissions_getSpecificRolePermissions_page_edges[] ) => void
  getSpecificRolePermissions: (options?: QueryLazyOptions<GetSpecificRolePermissionsVariables> | undefined) => void
}

export const RoleContext = React.createContext<IRoleContext>(
  {} as IRoleContext
);

const RoleProvider: React.FC<IProps> = ({ children }) => {
  const {userPref} = useAuth()
  const [items, setItems] = useState<GetAllRolesResponse_page_edges[]>(
    [] as GetAllRolesResponse_page_edges[]
  );
  const [roles, setRoles] = React.useState<GetAllRoles_getAllRoles>(
    {} as GetAllRoles_getAllRoles
  );
  const [specificRolePermissions, setSpecificRolePermissions] = React.useState<
  GetSpecificRolePermissions_getSpecificRolePermissions_page_edges[] 
  >([] as GetSpecificRolePermissions_getSpecificRolePermissions_page_edges[]);

  const [permissions, setPermissions] = React.useState<
    GetAllPermissions_getAllPermissions
  >({} as GetAllPermissions_getAllPermissions);

  const [authenticationInstances, setAuthenticationIntances] = React.useState<
    GetAllAuthenticationInstances_getAllAuthenticationInstances
  >({} as GetAllAuthenticationInstances_getAllAuthenticationInstances);

  const [getItems, { data, loading }] = useLazyGetAllRolesQuery();
  const [getSpecificRolePermissions, {data: specificPerms, loading: permsLoading }] = useLazyGetSpecificRolePermissionsQuery({
    first: 100, // todo: allow pagination,
    getSpecificRolePermissionsInput: {
      roleId: _.get(userPref, 'role._id'),
      authenticationInstanceId: _.get(userPref, 'authenticationInstance._id')
    }
  })

  useEffect(() => {
    if (specificPerms) {
      setSpecificRolePermissions(specificPerms?.getSpecificRolePermissions?.page?.edges || [])
    }
  }, [specificPerms])

  useEffect(() => {
    if (data) {
      setItems(data?.getAllRoles?.page?.edges || []);
    }
  }, [data]);

  

  const value = React.useMemo(
    () => ({
      roles,
      setRoles,
      loading: permsLoading || loading,
      permissions,
      setPermissions,
      authenticationInstances,
      setAuthenticationIntances,
      items,
      setItems,
      getItems,
      getSpecificRolePermissions,
      setSpecificRolePermissions,
      specificRolePermissions
    }), // eslint-disable-next-line
    [roles, loading, permissions, items, specificRolePermissions]
  );

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export default RoleProvider;
