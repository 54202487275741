import DateCell from "@components/ReactTable/DateCell";
import CodeDaysCell from "../components/CodeDaysCell";
import DescriptionCell from "@components/ReactTable/DescriptionCell";
import { Row } from "react-table";
import CodeEventTableActions from "../components/ItemTableActions";
export const codeEventTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
         <CodeEventTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: "Code Value",
      accessor: "codeDecString"
    },
    {
      Header: "Code Days",
      accessor: "codeDays",
      Cell: CodeDaysCell,
    },
    {
      Header: "Code Type",
      accessor: "codeType",
    },
    {
      Header: "hashIndex",
      accessor: "hashIndex",
    },
    {
      Header: "hashTop",
      accessor: "hashTop",
    },
    {
      Header: "hashRoot",
      accessor: "hashRoot",
    },
    {
      Header: "codeCount",
      accessor: "codeCount",
    },
    {
      Header: "Code Number",
      accessor: "codeNumber",
    },
    {
      Header: "Triggers",
      accessor: "triggers",
      Cell: DescriptionCell,
    },

    {
      Header: "userWhoCausedTheChange",
      accessor: "userWhoCausedTheChange",
      Cell: DescriptionCell,
    },
    {
      Header: "typeOfChangeChange",
      accessor: "typeOfChangeChange",
      Cell: DescriptionCell,
    },
    {
      Header: "typeOfChangeChange",
      accessor: "descriptionOfChangeChange",
      Cell: DescriptionCell,
    },
    {
     Header: "ID",
     accessor: "_id"
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
      Cell: DateCell,
    }
  ],
});
