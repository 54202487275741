import { IProps } from '@components/messages/types';
import { WS_OVS_PRODUCTS_ENDPOINT } from 'config';
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { Socket, io } from 'socket.io-client';

export interface ISocketContext {
  socket: Socket | null;
}
export const SocketContext = createContext<ISocketContext>(
  {} as ISocketContext
);

const SocketProvider: React.FC<IProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = io(WS_OVS_PRODUCTS_ENDPOINT);
    setSocket(newSocket);
    // client-side
    newSocket.on('connect', () => {
      console.log('Sockets connected @', newSocket.id); // x8WIv7-mJelg7on_ALbx
    });
    return () => {
      newSocket.close();
    }; // eslint-disable-next-line
  }, [setSocket]);

  const value = useMemo(
    () => ({
      socket,
    }),
    [socket]
  );
  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;
