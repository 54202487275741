import { useLazyQuery, useQuery } from '@apollo/client';

import gql from 'graphql-tag';
import client from 'utils/client';
import {
  GetAllLoginUsers,
  GetAllLoginUsersVariables,
} from './types/GetAllLoginUsers';

/**
 * type LoginUsers {
_id: ID!
actionScope: String!
activeSubRolePermission: PermissionInput!
authenticationInstance: AuthenticationInstance!
birthDate: DateTime!
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
email: String!
firstName: String!
idString: String!
idType: String!
lastName: String!
name: String!
profile: String!
role: Roles
roleName: String!
type: String!
updatedAt: DateTime
}
 */
export const loginUsersFragment = gql`
  fragment LoginUsers on LoginUsers {
    _id
    activeSubRolePermission
    createdAt
    deleteAt
    deleteStatus
    email
    firstName
    lastName
    role {
      _id
    }
    updatedAt
  }
`;
/**
 * type LoginUsersEdge {
cursor: String
node: LoginUsers
}
 */
const loginUsersEdgeFragment = gql`
  ${loginUsersFragment}
  fragment LoginUsersEdge on LoginUsersEdge {
    cursor
    node {
      ...LoginUsers
    }
  }
`;
/**
 * type LoginUsersPageInfo {
endCursor: String
hasNextPage: Boolean!
hasPreviousPage: Boolean!
startCursor: String
}
 */
const loginUsersPageInfoFragment = gql`
  fragment LoginUsersPageInfo on LoginUsersPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type LoginUsersConnection {
edges: [LoginUsersEdge!]
pageInfo: LoginUsersPageInfo
}
 */
const loginUsersConnectionFragment = gql`
  ${loginUsersPageInfoFragment}
  ${loginUsersEdgeFragment}
  fragment LoginUsersConnection on LoginUsersConnection {
    edges {
      ...LoginUsersEdge
    }
    pageInfo {
      ...LoginUsersPageInfo
    }
  }
`;

/**
 * type GetAllLoginUsersResponse {
page: LoginUsersConnection!
pageData: PageData
}
 */
const getAllLoginUsersResponseFragment = gql`
  ${loginUsersConnectionFragment}
  fragment GetAllLoginUsersResponse on GetAllLoginUsersResponse {
    page {
      ...LoginUsersConnection
    }
  }
`;
/**
 * Query.getAllLoginUsers(
after: String
before: String
first: Int
last: Int
): GetAllLoginUsersResponse!
 */
const getAllLoginUsersQuery = gql`
  ${getAllLoginUsersResponseFragment}
  query GetAllLoginUsers(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllLoginUsers(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...GetAllLoginUsersResponse
    }
  }
`;

export const useGetAllLoginUsersQuery = (
  variables: GetAllLoginUsersVariables
) =>
  useQuery<GetAllLoginUsers, GetAllLoginUsersVariables>(getAllLoginUsersQuery, {
    variables,
    client: client,
  });

export const useLazyGetAllLoginUsersQuery = (
  variables: GetAllLoginUsersVariables
) =>
  useLazyQuery<GetAllLoginUsers, GetAllLoginUsersVariables>(
    getAllLoginUsersQuery,
    {
      variables,
      client: client,
    }
  );
