
const url = 'payplan-template';

export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};

export interface PaymentPlanInput {
  upFrontPrice: number,
  uFrontDaysIncluded: number,
  freecodePrice: number,
  hourPrice: number,
  daysToCutOff: number,
  expectedPaid: number,
  minimumPaymentAmount: number,
  totalPaid? :number,
  planName: String,
  planDescription: String,
  useUpfront: boolean
}

export const paymentPlanFields = {
  upFrontPrice: "upFrontPrice",
  uFrontDaysIncluded: "uFrontDaysIncluded",
  freecodePrice: "freecodePrice",
  hourPrice: "hourPrice",
  daysToCutOff: "daysToCutOff",
  expectedPaid: "expectedPaid",
  minimumPaymentAmount: "minimumPaymentAmount",
  totalPaid: "totalPaid",
  planName: "planName",
  planDescription: "planDescription",
  useUpfront: "useUpfront"
}

export const initialPaymentPlan = {
  upFrontPrice: null,
  uFrontDaysIncluded: null,
  freecodePrice: null,
  hourPrice: null,
  daysToCutOff: null,
  expectedPaid: null,
  minimumPaymentAmount: null,
  totalPaid: null,
  planName: null,
  planDescription: null,
  useUpfront: false
}


