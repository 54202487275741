import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { resetPasswordMutation } from '../mutations';
import {
  CheckDistributorEmailForResetPassword,
  CheckDistributorEmailForResetPasswordVariables,
} from '../types/CheckDistributorEmailForResetPassword';

const useResetPassword = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    CheckDistributorEmailForResetPassword,
    CheckDistributorEmailForResetPasswordVariables
  >(resetPasswordMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Success', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useResetPassword;
