import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { categoryFragment } from '../category/queries';
import { pageDataFragment } from '../common/queries';
import {
  GetAllProducts,
  GetAllProductsVariables,
} from './types/GetAllProducts';
import {
  GetEcommerceProductsByCategory,
  GetEcommerceProductsByCategoryVariables,
} from './types/GetEcommerceProductsByCategory';
import {
  GetEcommerceProductsByProductName,
  GetEcommerceProductsByProductNameVariables,
} from './types/GetEcommerceProductsByProductName';
import {
  GetSpecificEcommerceProduct,
  GetSpecificEcommerceProductVariables,
} from './types/GetSpecificEcommerceProduct';
/**
 * type FeatureProductsDto {
title: String!
subTitle: String!
content: String!
btnText: String!
btnLink: String!
bgImage: String!
}

 */
const featureProductsDtoFragment = gql`
  fragment FeatureProducts on FeatureProducts {
    title
    subTitle
    content
    btnText
    btnLink
    bgImage
  }
`;
/**
 * type Product {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
sku: String!
name: String!
ratings: Int!
discount: String!
discountDuration: String!
badge: String!
totalSale: Int!
vendor: String!
type: [String!]!
categories: [Category!]!
tags: [String!]!
thumbs: [String!]!
previewImages: [String!]!
excerpt: String!
description: String!
variations: [Variation!]!
}
 */
export const productFragment = gql`
  ${categoryFragment}
  ${featureProductsDtoFragment}
  fragment Product on Product {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    sku
    name
    ratings
    discount
    discountDuration
    badge
    totalSale
    vendor
    type
    feature
    categories {
      ...Category
    }
    tags
    slug
    thumbs
    previewImages
    excerpt
    description
    price
    stock
    related {
      _id
      name
      previewImages
      slug
    }
    featureProducts {
      ...FeatureProducts
    }
  }
`;

export const productWithoutRelatedFragment = gql`
  ${categoryFragment}
  ${featureProductsDtoFragment}
  fragment Product on Product {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    sku
    name
    ratings
    discount
    discountDuration
    badge
    totalSale
    vendor
    type
    feature
    categories {
      ...Category
    }
    tags
    slug
    thumbs
    previewImages
    excerpt
    description
    price
    stock
    featureProducts {
      ...FeatureProducts
    }
  }
`;
/**
 * type ProductEdge {
cursor: String
node: Product
}
 */
const productEdgeFragment = gql`
  ${productWithoutRelatedFragment}
  fragment ProductEdge on ProductEdge {
    cursor
    node {
      ...Product
    }
  }
`;
/**
 * type ProductPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const productPageInfoFragment = gql`
  fragment ProductPageInfo on ProductPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type ProductConnection {
edges: [ProductEdge!]
pageInfo: ProductPageInfo
}
 */
const productConnectionFragment = gql`
  ${productEdgeFragment}
  ${productPageInfoFragment}
  fragment ProductConnection on ProductConnection {
    edges {
      ...ProductEdge
    }
    pageInfo {
      ...ProductPageInfo
    }
  }
`;

/**
 * type GetAllProductsResponse {
page: ProductConnection!
pageData: PageData
}
 */
const getAllProductsResponseFragment = gql`
  ${productConnectionFragment}
  ${pageDataFragment}
  fragment GetAllProductsResponse on GetAllProductsResponse {
    page {
      ...ProductConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * getAllProducts(
before: String
after: String
first: Int
last: Int
): GetAllProductsResponse!
 */
const getAllProductsQuery = gql`
  ${getAllProductsResponseFragment}
  query GetAllProducts(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllEcommerceProducts(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllProductsResponse
    }
  }
`;

/**
 * getEcommerceProductsByCategory(
before: String
after: String
first: Int
last: Int
categoryId: String!
): GetAllProductsResponse!
 */
const getEcommerceProductsByCategoryQuery = gql`
  ${getAllProductsResponseFragment}
  query GetEcommerceProductsByCategory(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $categoryId: String!
  ) {
    getEcommerceProductsByCategory(
      before: $before
      after: $after
      first: $first
      last: $last
      categoryId: $categoryId
    ) {
      ...GetAllProductsResponse
    }
  }
`;

/**
 getSpecificEcommerceProduct(
id: String!
): Product!
 */
const getSpecificEcommerceProductQuery = gql`
  ${categoryFragment}
  query GetSpecificEcommerceProduct($id: String!) {
    getSpecificEcommerceProduct(id: $id) {
      _id
      deleteStatus
      deleteAt
      createdAt
      updatedAt
      sku
      name
      ratings
      discount
      discountDuration
      badge
      totalSale
      vendor
      type
      feature
      categories {
        ...Category
      }
      tags
      slug
      thumbs
      previewImages
      excerpt
      description
      price
      stock
      related {
        _id
        name
        previewImages
        slug
      }
    }
  }
`;

/**
getEcommerceProductsByProductName(
before: String
after: String
first: Int
last: Int
productName: String!
): GetAllProductsResponse!
 */
const getEcommerceProductsByProductNameQuery = gql`
  ${getAllProductsResponseFragment}
  query GetEcommerceProductsByProductName(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $productName: String!
  ) {
    getEcommerceProductsByProductName(
      before: $before
      after: $after
      first: $first
      last: $last
      productName: $productName
    ) {
      ...GetAllProductsResponse
    }
  }
`;

export const useGetEcommerceProductsByProductNameQuery = (
  variables: GetEcommerceProductsByProductNameVariables
) =>
  useQuery<
    GetEcommerceProductsByProductName,
    GetEcommerceProductsByProductNameVariables
  >(getEcommerceProductsByProductNameQuery, {
    client: clientEcommerce,
    variables,
  });

export const useLazyGetEcommerceProductsByProductNameQuery = (
  variables: GetEcommerceProductsByProductNameVariables
) =>
  useLazyQuery<
    GetEcommerceProductsByProductName,
    GetEcommerceProductsByProductNameVariables
  >(getEcommerceProductsByProductNameQuery, {
    client: clientEcommerce,
    variables,
  });

export const useGetSpecificEcommerceProductQuery = (
  variables: GetSpecificEcommerceProductVariables
) =>
  useLazyQuery<
    GetSpecificEcommerceProduct,
    GetSpecificEcommerceProductVariables
  >(getSpecificEcommerceProductQuery, { variables, client: clientEcommerce });

export const useLazyGetSpecificEcommerceProductQuery = (
  variables: GetSpecificEcommerceProductVariables
) =>
  useLazyQuery<
    GetSpecificEcommerceProduct,
    GetSpecificEcommerceProductVariables
  >(getSpecificEcommerceProductQuery, { client: clientEcommerce, variables });

export const useLazyGetAllProductsQuery = (
  variables: GetAllProductsVariables
) =>
  useLazyQuery<GetAllProducts, GetAllProductsVariables>(getAllProductsQuery, {
    client: clientEcommerce,
    variables,
  });

export const useGetAllProductsQuery = (variables: GetAllProductsVariables) =>
  useQuery<GetAllProducts, GetAllProductsVariables>(getAllProductsQuery, {
    client: clientEcommerce,
    variables,
  });

export const useGetEcommerceProductsByCategoryQuery = (
  variables: GetEcommerceProductsByCategoryVariables
) =>
  useQuery<
    GetEcommerceProductsByCategory,
    GetEcommerceProductsByCategoryVariables
  >(getEcommerceProductsByCategoryQuery, {
    client: clientEcommerce,
    variables,
  });
