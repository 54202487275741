export const legacyRoutes = {
  list: '/admin/legacy/family',
  add: '/admin/legacy/family/add',
  edit: '/admin/legacy/family/:itemId',
  getEditLink: (itemId: string) => `/admin/legacy/family/${itemId}`,
};

export const itemFields = {
  productFamilyID: 'productFamilyID',
  productFamilyName: 'productFamilyName',
  productFamilyDescription: 'productFamilyDescription',
  image: 'image',
  productCategoryID: 'productCategoryID',
};
