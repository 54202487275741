import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import clientEcommerceService from '../utils/clientEcommerceService';
import {
  GetAllAmazonProductCategories,
  GetAllAmazonProductCategoriesVariables,
} from './types/GetAllAmazonProductCategories';
import {
  GetSpecificAmazonProductCategory,
  GetSpecificAmazonProductCategoryVariables,
} from './types/GetSpecificAmazonProductCategory';

/**
 * type AmazonProductCategory {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
categoryName: String!
categoryTree: String!
categoryDescription: String!
}
 */

export const amazonProductCategoryFragment = gql`
  fragment AmazonProductCategory on AmazonProductCategory {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    categoryName
    categoryTree
    categoryDescription
  }
`;
/**
 * type AmazonProductCategoryEdge {
cursor: String
node: AmazonProductCategory
}
 */
export const amazonProductCategoryEdgeFragment = gql`
  ${amazonProductCategoryFragment}
  fragment AmazonProductCategoryEdge on AmazonProductCategoryEdge {
    cursor
    node {
      ...AmazonProductCategory
    }
  }
`;
/**
 * type AmazonProductCategoryPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const amazonProductCategoryPageInfoFragment = gql`
  fragment AmazonProductCategoryPageInfo on AmazonProductCategoryPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type AmazonProductCategoryConnection {
edges: [AmazonProductCategoryEdge!]
pageInfo: AmazonProductCategoryPageInfo
}
 */
export const amazonProductCategoryConnectionFragment = gql`
  ${amazonProductCategoryEdgeFragment}
  ${amazonProductCategoryPageInfoFragment}
  fragment AmazonProductCategoryConnection on AmazonProductCategoryConnection {
    edges {
      ...AmazonProductCategoryEdge
    }
    pageInfo {
      ...AmazonProductCategoryPageInfo
    }
  }
`;
/**
 * type GetAllAmazonProductCategoriesResponse {
page: AmazonProductCategoryConnection!
pageData: PageData
}
 */
export const getAllAmazonProductCategoriesResponseFragment = gql`
  ${amazonProductCategoryConnectionFragment}
  fragment GetAllAmazonProductCategoriesResponse on GetAllAmazonProductCategoriesResponse {
    page {
      ...AmazonProductCategoryConnection
    }
    pageData {
      limit
      count
      offset
    }
  }
`;
/**
 * query {
  getAllAmazonProductCategories {
  
  }
}
 */
export const getAllAmazonProductCategoriesQuery = gql`
  ${amazonProductCategoryFragment}
  ${getAllAmazonProductCategoriesResponseFragment}
  query GetAllAmazonProductCategories(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllAmazonProductCategories(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllAmazonProductCategoriesResponse
    }
  }
`;

/**
 * getSpecificAmazonProductCategory(
id: String!
): AmazonProductCategory!
 */
export const getSpecificAmazonProductCategoryQuery = gql`
  ${amazonProductCategoryFragment}
  query GetSpecificAmazonProductCategory($id: String!) {
    getSpecificAmazonProductCategory(id: $id) {
      ...AmazonProductCategory
    }
  }
`;

export const useGetAllAmazonProductCategoriesQuery = (
  variables: GetAllAmazonProductCategoriesVariables
) =>
  useQuery<GetAllAmazonProductCategories>(getAllAmazonProductCategoriesQuery, {
    variables,
    client: clientEcommerceService,
  });

export const useLazyGetAllAmazonProductCategoriesQuery = (
  variables: GetAllAmazonProductCategoriesVariables
) =>
  useLazyQuery<GetAllAmazonProductCategories>(
    getAllAmazonProductCategoriesQuery,
    {
      variables,
      client: clientEcommerceService,
    }
  );

export const useGetSpecificAmazonProductCategoryQuery = (
  variables: GetSpecificAmazonProductCategoryVariables
) =>
  useQuery<
    GetSpecificAmazonProductCategory,
    GetSpecificAmazonProductCategoryVariables
  >(getSpecificAmazonProductCategoryQuery, {
    client: clientEcommerceService,
    variables,
  });
