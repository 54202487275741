// @ts-nocheck

import useNotifier from 'hooks/useNotifier';
import React, { useContext, useState, useEffect } from 'react';
import { getAccountStageValue } from '../../constants';
import { AssetAccountContext } from '../../context/AssetAccountProvider';
import AddPaymentPlan from './AddPaymentPlan';
import { updateAssetAccountMutation } from '../../mutations';
import clientSimulator from "utils/clientSimulator";
import { useMutation } from '@apollo/client';
import { UpdateAssetAccount, UpdateAssetAccountVariables } from '../../types/UpdateAssetAccount';
import { PayPlanTemplateContext } from 'apps/accounts/app/payPlanTemplate/context/EventProvider';
import CardGridLoader from '@components/Loaders/CardGridLoader';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import GridItem from '@components/Grid/GridItem';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import Button from '@components/CustomButtons/Button';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import AssetAccountPaymentPlan from '../PaymentPlanComponent';
interface IProps {
  assetAccountId: string;
}

const SetUpPaymentSchedule: React.FC<IProps> = ({ assetAccountId }) => {
  const thresholdWidth = 600;
  const drawerWidthPaymentPlanDesktop = { minWidth: "30vw", maxWidth: "30vw" }
  const drawerWidthMobile = { minWidth: "100vw", maxWidth: "100vw" }
  const { item, refetchItem } = useContext(AssetAccountContext);
  const { items, getItems } = useContext(
    PayPlanTemplateContext
  );
  const notify = useNotifier();
  const [payPlanOptions, setPayPlanOptions] = useState([])
  const [defaultP, setDefaultP] = useState([])
  const [currentPayPlan, setCurrentPayPlan] = useState([])
  const [payPlanId, setPayPlanId] = useState("")
  const [isPaymentplanModalOpen, setIsPaymentplanModalOpen] = useState(false)
  const [extraPayPlanFields, setExtraPayPlanFields] = useState({})
  const [updatePaymentPlan, { loading: planLoading }] = useMutation<UpdateAssetAccount, UpdateAssetAccountVariables>(
    updateAssetAccountMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        if (item?.assetAccountPayPlan === null) {
        }
        if (refetchItem) {
          refetchItem();
        }
        notify({
          status: "success",
          text: "Payment plan assigned successfully",
        });
      },
    }
  );


  useEffect(() => {
    // Consolidate Payplan Name and Description plus the Fields
    if(item?.paymentPlan) {
    const arr = [
      { __typename: 'PlanDetail', pName: "planName", pValue: item?.paymentPlan?.planName }, 
      { __typename: 'PlanDetail', pName: "planDescription", pValue: item?.paymentPlan?.planDescription },
      { __typename: 'PlanDetail', pName: "useUpfront", pValue: item?.paymentPlan?.useUpfront || false },
    ]
    const payPlanDetails = item.paymentPlan?.planDetails || []
    const res = arr?.concat(payPlanDetails)
    setCurrentPayPlan(res)
    }
    // Create an Object for the Extra Fields For the Payment Plan.
    const extraFields = item?.meta
    const mappedExtraFields = []
    const assetAccountPaymentPlan = {}
    if (extraFields) {
      extraFields.forEach(element => {
        mappedExtraFields.push({pName: element.name, pValue: element.value})
      });
    }
    assetAccountPaymentPlan['planDetails'] = mappedExtraFields
    setExtraPayPlanFields(assetAccountPaymentPlan)

    getItems()
    // eslint-disable-next-line
  }, [])

// Wont Change...
  useEffect(() => {
    if (payPlanId) {
      const selectedOption = payPlanOptions.find((option: any) => option._id === payPlanId);
      if (selectedOption) {
        // Create a new array with copies of the objects to ensure new references
        const extraDetails = [{pName: "planName", pValue: selectedOption.name}, {pName: "planDescription", pValue: selectedOption.description}, {pName: "useUpfront", pValue: selectedOption.useUpfront}]
        const newPayPlan = selectedOption.payPlanDetails.map((detail: any) => ({ ...detail }));
        const resultPlan = extraDetails?.concat(newPayPlan)
        setDefaultP(resultPlan);
      }
    }
  }, [payPlanId, payPlanOptions]);



  useEffect(() => {
    if (items.length > 0) {
      const extraDetails = [{pName: "planName", pValue: items[0].node?.planName},{pName: "planDescription", pValue: items[0].node?.planDescription}, {pName: "useUpfront", pValue: items[0].node?.useUpfront || false}]
      const planDetails = items[0].node?.planDetails
      const resultDetails = extraDetails?.concat(planDetails)
      setDefaultP(resultDetails)
      setPayPlanOptions(
        items.map((item) => ({
          _id: item?.node?._id || '',
          name: item?.node?.planName || '',
          description: item?.node?.planDescription || '',
          useUpfront: item?.node?.useUpfront || false,
          payPlanDetails: item?.node?.planDetails
        })) || []
      );
    }
  }, [items])


  const handleSubmit = (values: any) => {
    const modifiedArray = values.payPlan.map(({ __typename, ...rest }) => rest);
    let planDetails = []
    let planName = ""
    let planDescription = ""
    let useUpfront = false 
      for (const key in modifiedArray) {
      if (
        modifiedArray[key].pName !== "planName" && 
        modifiedArray[key].pName !== "planDescription" && 
        modifiedArray[key].pName !== "useUpfront") {
        planDetails.push({ pName: modifiedArray[key].pName, pValue: String(modifiedArray[key].pValue) })
      }
      if (modifiedArray[key].pName === "planName") {
        planName = modifiedArray[key].pValue
      }
      if (modifiedArray[key].pName === "planDescription") {
        planDescription = modifiedArray[key].pValue
      }
      if (modifiedArray[key].pName === "useUpfront") {
        useUpfront = modifiedArray[key].pValue
      }
    }

    updatePaymentPlan({
      variables: {
        updateAssetAccountInput: {
          paymentPlan: {
            planDetails: planDetails,
            planName: planName,
            planDescription: planDescription,
            useUpfront: useUpfront
          },

          assetAccountId: assetAccountId
        }
      }
    })
  }

  const handlePaymentPlanModal = () => {
    setIsPaymentplanModalOpen(true)
  }
  if (!items.length) return <CardGridLoader />;
  return (
    <>
      <GridItem xs={12} sm={12} md={12}>
        <div style={{display: "flex"}}>
        <GridHalfWrapper>
          <CustomSelect
            selectOptions={payPlanOptions}
            onChange={(e: any) => {
              setPayPlanId(e.target.value);
            }}
            label={'Payment Plan'}
            value={payPlanId}
            name={'payPlanId'}
          />
        </GridHalfWrapper>
        <GridHalfWrapper>
        <Button
              color='primary'
              onClick={() => {
                handlePaymentPlanModal()
              }}
              style={{width: "100%", height: "30px", marginTop: "2rem"}}
              disabled={!item?.meta}
            >
              View Extra PayPlan Fields
            </Button>
        </GridHalfWrapper>
        </div>
        <AddPaymentPlan
          handleSubmit={handleSubmit}
          isLoading={planLoading}
          item={{ payPlan: currentPayPlan.length >  0 ? currentPayPlan : defaultP }}
          inActive={getAccountStageValue(item?.accountStage || '') > 1 || !item?._id}
        />

      </GridItem>
      <DrawerSidebar
        isModalOpen={isPaymentplanModalOpen}
        toggleModal={setIsPaymentplanModalOpen}
        title="Payment Plan"
        styles={window.innerWidth <= thresholdWidth ? drawerWidthMobile : drawerWidthPaymentPlanDesktop}
      >
        <AssetAccountPaymentPlan assetAccountPaymentPlan={extraPayPlanFields} />
      </DrawerSidebar>
    </>

  );
};

export default SetUpPaymentSchedule;
