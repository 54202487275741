import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import thingMakeClientSimulator from 'utils/thingMakeSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import { createItemBatchMutation } from '../mutations';
import {
  CreateItemBatch,
  CreateItemBatchVariables,
} from '../types/CreateItemBatch';

const useCreateBatch = (onCompleted: (data: any) => void) => {
  const notify = useNotifier();
  const [create, options] = useMutation<
    CreateItemBatch,
    CreateItemBatchVariables
  >(createItemBatchMutation, {
    client: thingMakeClientSimulator,
    onCompleted: async (data) => {
      onCompleted && onCompleted(data);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    create,
    options,
  };
};

export default useCreateBatch;
