import { useMutation } from '@apollo/client';
import { adminRegisterDistributorMutation } from 'apps/clientService/app/distributor/mutations';
import { AdminRegisterDistributor, AdminRegisterDistributorVariables } from 'apps/clientService/app/distributor/types/AdminRegisterDistributor';
import { AdminRegisterDistributorInput } from 'apps/clientService/app/types/clientServiceGlobalTypes';
import clientClientService from 'apps/clientService/app/utils/clientClientService';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { handleGQLErrors } from 'utils/gqlErrors';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';
import { LoginUserContext } from '../context/LoginUserContext';

const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const { getItems } = React.useContext(LoginUserContext);

  // create mutations
  const [createItem, createItemOpts] = useMutation<
    AdminRegisterDistributor,
    AdminRegisterDistributorVariables
  >(adminRegisterDistributorMutation, {
    client: clientClientService,
    onCompleted: async (data) => {

      notify({
        status: 'success',
        text: 'Token sent successfully.',
      });

      getItems();
      history(itemRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleSubmit = (
    adminRegisterDistributor: AdminRegisterDistributorInput
  ) => {
    createItem({
      variables: {
        adminRegisterDistributor,
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
