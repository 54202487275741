import useCreateThingBroker from 'apps/events/app/broker/hooks/useCreateThingBroker';
import { UpdateBrokerInput } from 'apps/events/app/types/eventsGlobalTypes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';

import { BrokerContext } from '../context/ThingBrokerProvider';

const AddItemContainer = () => {
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(BrokerContext);

  // update when client Id is provided
  const {
    mutation: createItem,
    mutationOpts: createItemOpts,
  } = useCreateThingBroker({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateBrokerInput) => {
    createItem({
      variables: {
        createBrokerInput: {
          login: input.login || '',
          name: input.name || '',
          password: input.password || '',
          url: input.url || '',
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
