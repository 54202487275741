import { gql } from '@apollo/client';

/**
 * logEvent(
createEventInput: CreateEventInput!
): Event!
 */
export const logEventMutation = gql`
  mutation LogEvent($createEventInput: CreateEventInput!) {
    logEvent(createEventInput: $createEventInput) {
      _id
    }
  }
`;

/**
 * Mutation.deleteEvent(
EventId: String!
): String!
 */
export const deleteEventMutation = gql`
  mutation DeleteEvent($EventId: String!) {
    deleteEvent(EventId: $EventId)
  }
`;

/**
 * updateEvent(
updateEventInput: UpdateEventInput!
): Event!
 */
export const updateEventMutation = gql`
  mutation UpdateEvent($updateEventInput: UpdateEventInput!) {
    updateEvent(updateEventInput: $updateEventInput) {
      _id
    }
  }
`;
