import { Checkbox, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import Check from '@material-ui/icons/Check';
import { ProductContext } from 'admin/product/context/ProductContext';

interface IProps {
  id?: string;
}

// @ts-ignore noqa:
const useStyles = makeStyles(styles);

const TableCheckbox: React.FC<IProps> = ({ id }) => {
  const { checkedProductIds, setCheckedProductIds, products } = useContext(
    ProductContext
  );
  const classes = useStyles();

  const [checked, setChecked] = useState(
    id ? checkedProductIds.includes(id as string) : false
  );
  React.useEffect(() => {
    const exists = checkedProductIds.includes(id as string);
    if (id) setChecked(exists);
  }, [checkedProductIds, checked, id]);

  const handleChecked = (checked: boolean) => {
    setChecked(checked);
    const checkedIds = [...checkedProductIds];
    if (checked && id) {
      // add it to the context
      setCheckedProductIds([id, ...checkedIds]);
    } else if (id) {
      // remove it from the context
      setCheckedProductIds(checkedIds.filter((item) => item !== id));
    }

    if (!id) {
      // check all
      if (checked) {
        const checkedIds = products.map((item) => item.OEM_SN);
        setCheckedProductIds(checkedIds);
      } else {
        //reset
        setCheckedProductIds([]);
      }
    }
  };
  return (
    <Checkbox
      key='key'
      tabIndex={-1}
      onClick={() => handleChecked(!checked)}
      checked={checked}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<Check className={classes.uncheckedIcon} />}
      classes={{
        checked: classes.checked,
        root: classes.checkRoot,
      }}
    />
  );
};

export default TableCheckbox;
