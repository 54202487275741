import CardGridLoader from '@components/Loaders/CardGridLoader';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { UpdateAvatarByItemIDInput } from '../../types/thingsGlobalTypes';
import AddItem from '../components/AddItem';
import { itemRoutes } from '../constants';
import { AvatarContext as Context } from '../context/AvatarProvider';
import useUpdateSignal from '../hooks/useUpdateAvatar';

import { useLazyGetSpecificAvatarForItemQuery } from '../queries';

const EditItemContainer = () => {
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(Context);

  const [item, setItem] = React.useState({} as UpdateAvatarByItemIDInput);

  const { data, loading } = useLazyGetSpecificAvatarForItemQuery({ itemId });

  const getAttribute = (attributes: { meta: string, prop: string, value: string }[] | null | undefined) => {
    return attributes?.map(it => ({
      meta: it?.meta,
      value: it?.value,
      prop: it?.prop
    })) || []
  }

  useEffect(() => {
    setItem({
      att: getAttribute(data?.getSpecificAvatarforItem?.att),
      cmd: getAttribute(data?.getSpecificAvatarforItem?.cmd),
      dia: getAttribute(data?.getSpecificAvatarforItem?.dia),
      dta: getAttribute(data?.getSpecificAvatarforItem?.dta),
      itemAlive: data?.getSpecificAvatarforItem?.itemAlive,
      itemId: data?.getSpecificAvatarforItem?.item?._id || '',
      lastPub: data?.getSpecificAvatarforItem?.lastPub,
      lastSub: data?.getSpecificAvatarforItem?.lastSub,
      lastSync: data?.getSpecificAvatarforItem?.lastSync,
      shadow: data?.getSpecificAvatarforItem?.item?._id,
      sts: getAttribute(data?.getSpecificAvatarforItem?.sts)
    }); // eslint-disable-next-line
  }, [data]);

  const { mutation, mutationOpts } = useUpdateSignal({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateAvatarByItemIDInput) => {
    mutation({
      variables: {
        updateAvatarByItemID: {
          att: input.att,
          cmd: input.cmd,
          dia: input.dia,
          dta: input.dta,
          itemAlive: input.itemAlive,
          itemId: input.shadow || '',
          lastPub: input.lastPub,
          lastSub: input.lastSub,
          lastSync: input.lastSync,
          shadow: input.shadow,
          sts: input.sts
        },
      },
    });
  };

  if (!item?.shadow || loading) return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleSubmit}
      isLoading={mutationOpts.loading}
      item={item}
    />
  );
};

export default EditItemContainer;
