import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-table';

import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
import { useNavigate } from 'react-router-dom';
import { itemsRoutes } from 'apps/things/app/item/constants';
import { itemRoutes as customerRoutes } from '../../customer/constants'
import { itemRoutes as assetRoutes } from '../../../../accounts/app/assetAccount/constants'
import { useAuth } from 'admin/auth/context/AuthProvider';
import { setUserPref } from 'admin/auth/utils';
import { ItemContext } from 'apps/things/app/item/context/ItemProvider';
import { CustomerContext } from '../../customer/context/CustomerProvider';
import { FleetContext } from 'apps/things/app/fleet/context/FleetProvider';
import { AssetAccountContext } from 'apps/accounts/app/assetAccount/context/AssetAccountProvider'
import { ClientExclusiveServiceTypes } from '../../types/clientServiceGlobalTypes';
interface IProps {
  row: Row;
}

const TableActions: React.FC<IProps> = ({ row, ...rest }) => {
  const history = useNavigate()
  const { userPref } = useAuth()
  const [loading, setLoading] = useState(false)
  const { setClientItems, setItems } = useContext(ItemContext)
  const { setItems: resetCustomers } = useContext(CustomerContext)
  const { setDistributorItems } = useContext(FleetContext)
  const { setItems: resetAssets } = useContext(AssetAccountContext)
  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  const updateLocalStorage = () => {
    if (row.values['node.distributor._id']) {
      setUserPref({ ...userPref, distributorId: row.values['node.distributor._id'] })
    }
  }

  const servicetype = useMemo(() => row.values['node.servicetype'], [row.values])

  const goto = (route: string) => {
    setTimeout(() => {
      history(route)
    }, 1000);
  }

  return (
    <div className='actions-right' style={{ display: 'flex' }}>


      <HoverDropdown
        menus={[
          {
            name: loading ? '    ...    ' : 'Items',
            action: () => {
              updateLocalStorage()
              setLoading(true)
              setClientItems([])
              setItems([])
              goto(itemsRoutes.listClient)
            }
          },
          {
            name: loading ? '    ...    ' : 'Fleets',
            action: () => {
              updateLocalStorage()
              setLoading(true)
              setDistributorItems([])
              goto('/admin/client-fleet')
            },
            hidden: servicetype === ClientExclusiveServiceTypes.PAYGO
          },
          {
            name: loading ? '    ...    ' : 'Customers',
            action: () => {
              updateLocalStorage()
              setLoading(true)
              resetCustomers([])
              goto(customerRoutes.list)
            },
            hidden: servicetype === ClientExclusiveServiceTypes.PAYGO
          },
          {
            name: loading ? '    ...    ' : 'AssetAccount',
            action: () => {
              updateLocalStorage()
              setLoading(true)
              resetAssets([])
              goto(assetRoutes.list)
            },
            hidden: servicetype === ClientExclusiveServiceTypes.PAYGO
          }
        ]}
      />


    </div>
  );
};

export default TableActions;
