import { QueryLazyOptions } from '@apollo/client';
import { IProps } from '@components/messages/types';
import { useCloudinaryListImagesQuery } from 'apps/ecommerce/app/cloudinaryImages/query';
import { CloudinaryListImagesVariables } from 'apps/ecommerce/app/cloudinaryImages/types/CloudinaryListImages';
import React, { createContext, useState, useEffect } from 'react';

export interface IMediaContext {
  next_cursor: string | undefined;
  images: string[];
  tags: string[];
  setTags: (tags: string[]) => void;
  fetchMore: () => void;
  filterImages: (tags: string) => void;
  loading: boolean;
  getImages: (
    options?: QueryLazyOptions<CloudinaryListImagesVariables> | undefined
  ) => void;
}

export const MediaContext = createContext<IMediaContext>({} as IMediaContext);

const MediaProvider: React.FC<IProps> = ({ children }) => {
  const [images, setImages] = useState<string[]>([]);
  const [next_cursor, setNextCursor] = useState<string | undefined>(undefined);
  const [tags, setTags] = useState<string[]>([]);

  const [getImages, { data, loading, refetch }] = useCloudinaryListImagesQuery({
    nextCursor: '',
    tag: 'none',
  });

  useEffect(() => {
    if (data && !loading && next_cursor === undefined) {
      setImages(
        data?.cloudinaryListImages.resources
          ?.filter((item) => !item.format.includes('mp4'))
          ?.map((item) => `${item.public_id}.${item.format}`) || []
      );
      setNextCursor(data?.cloudinaryListImages?.next_cursor || '');
    } // eslint-disable-next-line
  }, [data]);

  const fetchMore = async () => {
    if (loading) return;
    if (!refetch) return;
    const { data: refetchData } = await refetch({
      nextCursor: next_cursor,
      tag: 'none',
    });
    setImages([
      ...images,
      ...(refetchData?.cloudinaryListImages?.resources
        ?.filter((item) => !item.format.includes('mp4'))
        ?.map((item) => `${item.public_id}.${item.format}`) || []),
    ]);
    setNextCursor(refetchData?.cloudinaryListImages?.next_cursor || '');
  };

  const filterImages = async (tag: string) => {
    if (loading) return;
    if (!refetch) return;
    const { data: refetchData } = await refetch({
      nextCursor: '',
      tag: tag || 'none',
    });
    setImages([
      ...(refetchData?.cloudinaryListImages?.resources
        ?.filter((item) => !item.format.includes('mp4'))
        ?.map((item) => `${item.public_id}.${item.format}`) || []),
    ]);
    setNextCursor(refetchData?.cloudinaryListImages?.next_cursor || '');
  };

  const value = React.useMemo(
    () => ({
      fetchMore,
      images,
      loading,
      next_cursor,
      getImages,
      filterImages,
      tags,
      setTags,
    }), // eslint-disable-next-line
    [images, next_cursor, loading, tags]
  );

  return (
    <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
  );
};
export default MediaProvider;
