import Button from '@components/CustomButtons/Button';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { ButtonGroup } from '@material-ui/core';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { getAccountStageValue } from '../../constants';
import { AssetAccountContext } from '../../context/AssetAccountProvider';
import useActivateAssetAccount from '../../hooks/useActivateAssectAccount';

interface IProps {
  assetAccountId?: string;
  active: boolean
}

const ActivateAccount: React.FC<IProps> = ({active}) => {
  const { item, refetchItem } = useContext(AssetAccountContext);

  const notify = useNotifier();
  const { mutation, mutationOpts } = useActivateAssetAccount({
    onComplete: () => {
      notify({ status: 'success', text: 'Account activated successfully' });
      if (refetchItem) {
        refetchItem();
      }
    },
  });

  return (
    <GridContainer>
      <GridItem md={12}>
        <ButtonGroup disableElevation color='primary' style={{ marginTop: 30 }}>
          <Button
            color='primary'
            onClick={() => {
              mutation({
                variables: {
                  assetAccountId: item?._id || '',
                },
              });
            }}
            disabled={
              mutationOpts.loading ||
              getAccountStageValue(item?.accountStage || '') > 2 || !active
            }
          >
            Activate
          </Button>
        </ButtonGroup>
      </GridItem>
    </GridContainer>
  );
};

export default ActivateAccount;
