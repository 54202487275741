import React from 'react';

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import CardWrapper from '@components/Card/CardWrapper';
import './Dashboard.css'

const DashboardPage: React.FC = () => {
  // const Kibana = process.env.REACT_APP_KIBANA_URL
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <div className='iframe-container'>
            <iframe
              className='iframe-component'
              title='Kibana Dashboards'
              src="https://kibana-prod.omnivoltaic.com/goto/4e34d890-fa2a-11ed-8e35-a766243662dd"
              frameBorder='0'
            >
            </iframe>
          </div>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default DashboardPage;