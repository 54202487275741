import React from 'react';
// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';

import { addLegacyItemValidationSchema } from 'apps/things/app/validations';
import ItemForm from './LegacyForm';
import CardWrapper from '@components/Card/CardWrapper';
import { ProductItemInput } from '../types';

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: ProductItemInput, totalItems: number) => void;
  isLoading: boolean;
  initialValues: ProductItemInput;
  isUpdate?: boolean
}

const LegacyAddItem: React.FC<IRTable> = ({
  handleSubmit,
  isLoading,
  initialValues,
  isUpdate=false
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleSubmit(
                {
                  productModelID: values.productModelID,
                  productItemOEM_SN: values.productItemOEM_SN,
                  productBatchID: values.productBatchID,
                  productItemPAYG_SN: values.productItemPAYG_SN,
                  lifeCycleStatus: values.lifeCycleStatus,
                  firmwareVersion: values.firmwareVersion,
                  timerScheme: values.timerScheme,
                  itemBatchNumber: values.itemBatchNumber
                },
                values.totalItems
              )
            }
            initialValues={{...initialValues}}
            validationSchema={addLegacyItemValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemForm
                formBag={(formBag as unknown) as FormikProps<ProductItemInput>}
                disableLoginButton={isLoading}
                isUpdate={isUpdate}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default LegacyAddItem;
