import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminRegisterServicerInput } from '../../types/clientServiceGlobalTypes';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';

import { ServicerContext as Context } from '../context/ServicerProvider';
import { useAdminRegisterServicerMutation } from '../mutation';

const AddItemContainer = () => {
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(Context);

  // update when client Id is provided
  const {
    mutation: createItem,
    loading,
  } = useAdminRegisterServicerMutation({
    callbackFn: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: AdminRegisterServicerInput) => {
    createItem({
      variables: {
        adminRegisterServicer: {
          contactRole: input.contactRole,
          email: input.email,
          roleId: input.roleId,
          servicerDescription: input.servicerDescription,
          servicerName: input.servicerName,
          servicerType: input.servicerType,
          servicerAddress: {
            locationAddressDto: {
              inputAddressLatitude: input.servicerAddress?.locationAddressDto?.inputAddressLatitude,
              inputAddressLongitude: input.servicerAddress?.locationAddressDto?.inputAddressLongitude
            },
            city: input.servicerAddress?.city,
            country: input.servicerAddress?.country,
            postcode: input.servicerAddress?.postcode,
            srpc: input.servicerAddress?.srpc,
            street: input.servicerAddress?.street,
            unit: input.servicerAddress?.unit
          },


        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={loading}
    />
  );
};

export default AddItemContainer;
