import { gql, useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { handleGQLErrors } from 'utils/gqlErrors';
import { successFragment } from '../common/queries';
import { applicationSiteFragment } from './queries';
import {
  CreateApplicationSite,
  CreateApplicationSiteVariables,
} from './types/CreateApplicationSite';
import {
  DeleteApplicationSite,
  DeleteApplicationSiteVariables,
} from './types/DeleteApplicationSite';
import {
  UpdateApplicationSite,
  UpdateApplicationSiteVariables,
} from './types/UpdateApplicationSite';

/**
 * deleteApplicationSite(
applicationSiteId: String!
): Success!
 */
const deleteApplicationSiteMutation = gql`
  ${successFragment}
  mutation DeleteApplicationSite($applicationSiteId: String!) {
    deleteApplicationSite(applicationSiteId: $applicationSiteId) {
      ...Success
    }
  }
`;

/**
 updateApplicationSite(
updateApplicationSiteInput: UpdateApplicationSiteInput!
): ApplicationSite!!
 */
const updateApplicationSiteMutation = gql`
  ${applicationSiteFragment}
  mutation UpdateApplicationSite(
    $updateApplicationSiteInput: UpdateApplicationSiteInput!
  ) {
    updateApplicationSite(
      updateApplicationSiteInput: $updateApplicationSiteInput
    ) {
      ...ApplicationSite
    }
  }
`;

/**
 *createApplicationSite(
createApplicationSiteInput: CreateApplicationSiteInput!
): ApplicationSite!
 */
const createApplicationSiteMutation = gql`
  ${applicationSiteFragment}
  mutation CreateApplicationSite(
    $createApplicationSiteInput: CreateApplicationSiteInput!
  ) {
    createApplicationSite(
      createApplicationSiteInput: $createApplicationSiteInput
    ) {
      ...ApplicationSite
    }
  }
`;

export const useDeleteApplicationSite = (callBack: () => void) => {
  const [deleteApplicationSite, deleteApplicationSiteOpts] = useMutation<
    DeleteApplicationSite,
    DeleteApplicationSiteVariables
  >(deleteApplicationSiteMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    deleteApplicationSite,
    deleteApplicationSiteOpts,
  };
};

export const useUpdateApplicationSite = (callBack: () => void) => {
  const notify = useNotifier();
  const [updateApplicationSite, updateApplicationSiteOpts] = useMutation<
    UpdateApplicationSite,
    UpdateApplicationSiteVariables
  >(updateApplicationSiteMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
      err && handleGQLErrors(notify, err);
    },
  });

  return {
    updateApplicationSite,
    updateApplicationSiteOpts,
  };
};

export const useCreateApplicationSite = (
  callBack: (success?: boolean) => void
) => {
  const [createApplicationSite, createApplicationSiteOpts] = useMutation<
    CreateApplicationSite,
    CreateApplicationSiteVariables
  >(createApplicationSiteMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack(true);
    },
    onError: (err) => {
      console.error(err);
      callBack && callBack(false);
    },
  });

  return {
    createApplicationSite,
    createApplicationSiteOpts,
  };
};
