import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import {
  GetAllPublishers,
  GetAllPublishersVariables,
} from './types/GetAllPublishers';
import { ViewPublisher, ViewPublisherVariables } from './types/ViewPublisher';

/**
 * type Publisher {
_id: ID!
broker: Broker
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
topic: String!
updatedAt: DateTime
}
 */
const publisherFragment = gql`
  fragment Publisher on Publisher {
    topic
    broker {
      name
      password
      port
      _id
      createdAt
      deleteAt
      deleteStatus
      login
      updatedAt
      url
    }
    createdAt
    deleteAt
    deleteStatus

    updatedAt
    _id
  }
`;
/**
 * type PublisherConnection {
edges: [PublisherEdge!]
pageInfo: PublisherPageInfo
}
 */
const publisherConnectionFragment = gql`
  ${publisherFragment}
  fragment PublisherConnection on PublisherConnection {
    edges {
      cursor
      node {
        ...Publisher
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
`;
/**
 * type GetAllPublishersResponse {
page: PublisherConnection!
pageData: PageData
}
 */
const getAllPublishersResponseFragment = gql`
  ${publisherConnectionFragment}
  fragment GetAllPublishersResponse on GetAllPublishersResponse {
    page {
      ...PublisherConnection
    }
    pageData {
      count
      limit
      offset
    }
  }
`;
/**
 * getAllPublishers(
after: String
before: String
first: Int
last: Int
): GetAllPublishersResponse!
 */
const getAllPublishersQuery = gql`
  ${getAllPublishersResponseFragment}
  query GetAllPublishers(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllPublishers(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...GetAllPublishersResponse
    }
  }
`;

/**
 * viewPublisher(
id: ID!
): Publisher
 */
const viewPublisherQuery = gql`
  ${publisherFragment}
  query ViewPublisher($id: ID!) {
    viewPublisher(id: $id) {
      ...Publisher
    }
  }
`;

export const useViewPublisherQuery = (variables: ViewPublisherVariables) =>
  useQuery<ViewPublisher, ViewPublisherVariables>(viewPublisherQuery, {
    client: clientAccount,
    variables,
  });

export const useGetAllPublishersQuery = (
  variables: GetAllPublishersVariables
) =>
  useQuery<GetAllPublishers, GetAllPublishersVariables>(getAllPublishersQuery, {
    client: clientAccount,
    variables,
  });

export const useLazyGetAllPublishersQuery = (
  variables: GetAllPublishersVariables
) =>
  useLazyQuery<GetAllPublishers, GetAllPublishersVariables>(
    getAllPublishersQuery,
    {
      client: clientAccount,
      variables,
    }
  );
