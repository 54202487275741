import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import AllItemsPage from './components/AllItemsPage';
import { itemsRoutes } from './constants';
import Add from "./containers/AddItemContainer";
import Edit from "./containers/EditItemContainer";
import List from "./containers/ItemContainer";

interface IProps { }
export const routes = {
         path: itemsRoutes.list,
         element: <DefaultOutlet />,
         children: [
           { index: true, element: <List /> },
           { path: itemsRoutes.add, element: <Add /> },
           { path: itemsRoutes.edit, element: <Edit /> },
           { path: itemsRoutes.listBatch, element: <AllItemsPage /> },
           { path: itemsRoutes.listClient, element: <List /> },
         ],
       };
const ItemIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default ItemIndex;
