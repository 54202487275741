import { CodeSystemType } from 'apps/things/app/types/thingsGlobalTypes';

export const codeGeneratorFields = {
  codeGenId: 'codeGenId',
  codeSystemId: 'codeSystemId',
  hashRoot: 'hashRoot',
  hashTop: 'hashTop',
  codeCount: 'codeCount',
  hashIndex: 'hashIndex',
  codeReversalCount: 'codeReversalCount',
  itemId: 'itemId',
  oemItemID: 'oemItemID',
};

export const codeGeneratorRoutes = {
  list: '/admin/codegen',
  add: '/admin/codegen/add-codegen',
  edit: '/admin/codegen/:itemId',
  getEditLink: (itemId: string) => `/admin/codegen/${itemId}`,
};

export const codeSystemTypeOptions = () => {
  let options = [];
  for (let item in CodeSystemType) {
    options.push({
      _id: item, // @ts-ignore
      name: CodeSystemType[item],
    });
  }
  return options;
};

export const PAYG = 'PAYG';
export const FREE = 'FREE';
export const ENABLED = 'ENABLED';
export const DISABLED = 'DISABLED';
