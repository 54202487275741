import { legacyTypes } from './constants';

let INITIAL_STATE = {
  isProgress: false,
  items: { data: [] },
};
export function legacyFamilyReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case legacyTypes.LIST_LEGACY:
      return {
        ...state,
        isProgress: true,
      };

    case legacyTypes.LIST_LEGACY_SUCCESS:
      return {
        ...state,
        isProgress: false,
        items: { data: action.payload.data },
      };

    case legacyTypes.LIST_LEGACY_FAIL:
      return {
        ...state,
        isProgress: false,
      };

    default:
      return state;
  }
}
