import { authFormFields } from 'admin/auth/constants/formFields';
import { distributorFields } from 'admin/distributor/constants';
import { agentFields } from 'admin/agents/constants';
import * as yup from 'yup';
import YupPassword from 'yup-password'
const { login, register, updatePassword, registerStaffAgent } = authFormFields;
const { addDistributor } = distributorFields;
const { addagent } = agentFields;
YupPassword(yup);
export const emailValidation = yup
  .string()
  .email('Please enter a valid email.')
  .required('Email field is required.');

const passwordValidation = yup
  .string()
  .min(
      6,
      'password must contain 6 or more characters with at least one of each: uppercase, lowercase, number and special'
    )
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minNumbers(1, 'password must contain at least 1 number')
    .minSymbols(1, 'password must contain at least 1 special character')
  .required('Password is Required.');
export const stringRequired = yup.string().required('Field is required');

export const loginValidationSchema = () =>
  yup.object().shape({
    [login.email]: emailValidation,
    [login.password]: stringRequired,
  });

  
export const staffAgentRegisterValidationSchema = () =>
  yup.object().shape({
    [registerStaffAgent.email]: emailValidation,
    [registerStaffAgent.password]: passwordValidation,
    [registerStaffAgent.confirmPassword]: passwordConfirmationValidation,
  });

export const resetPasswordSchema = () =>
  yup.object().shape({
    [login.email]: emailValidation,
  });

export const passwordConfirmationValidation = yup
  .string()
  .oneOf(
    [yup.ref(register.password)],
    'Password and confirm password should match.'
  )
  .required('Confirm password is required.');

export const newPasswordConfirmationValidation = yup
  .string()
  .oneOf(
    [yup.ref(updatePassword.newPassword)],
    'New password and confirm password should match.'
  )
  .required('Confirm password is required.');
export const registerValidationSchema = () =>
  yup.object().shape({
    [register.email]: emailValidation,
    [register.password]: passwordValidation,
    [register.confirmPassword]: passwordConfirmationValidation,
    [register.firstName]: stringRequired,
    [register.lastName]: stringRequired,
  });

export const resetDistributorValidationSchema = () =>
  yup.object().shape({
    password: passwordValidation,
    confirmPassword: passwordConfirmationValidation,
  });

export const registerDistributorValidationSchema = () =>
  yup.object().shape({
    [register.email]: emailValidation,
    [register.password]: passwordValidation,
    [register.confirmPassword]: passwordConfirmationValidation,
    [register.personName]: stringRequired,
    [register.phone]: stringRequired,
    [register.social]: stringRequired,
    [register.address]: yup.object().shape({
      city: stringRequired,
      country: stringRequired,
      postcode: stringRequired,
      street: stringRequired,
      unit: stringRequired,
      srpc: stringRequired,
      locationAddressDto: yup.object().shape({
        inputAddressLatitude: stringRequired,
        inputAddressLongitude: stringRequired,
      }),
    }),
  });
export const updateUserDetailsValidationSchema = () =>
  yup.object().shape({
    [register.email]: emailValidation,
    [register.firstName]: stringRequired,
    [register.lastName]: stringRequired,
  });

export const addRoleValidationScheam = () =>
  yup.object().shape({
    [addDistributor.email]: emailValidation,
    [addDistributor.roleId]: stringRequired,
  });

export const addSubRoleValidationScheam = () =>
  yup.object().shape({
    [addagent.email]: emailValidation,
    [addagent.subRoleId]: stringRequired,
  });

export const updatePasswordValidationschema = () =>
  yup.object().shape({
    [updatePassword.currentPassword]: passwordValidation,
    [updatePassword.newPassword]: passwordValidation,
    [updatePassword.confirmNewPassword]: newPasswordConfirmationValidation,
  });
