/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionTypes {
  CLIENT = "CLIENT",
  DEVELOPMENT = "DEVELOPMENT",
  GLOBAL = "GLOBAL",
  MANAGEMENT = "MANAGEMENT",
  SYSTEM = "SYSTEM",
}

export enum ActorTypes {
  ACCOUNT = "ACCOUNT",
  DEVICE = "DEVICE",
  EVENT = "EVENT",
  ORGANIZATION = "ORGANIZATION",
  PERSON = "PERSON",
  PLACE = "PLACE",
  SOFTWARE = "SOFTWARE",
}

export enum ItemEventTypes {
  BATCH_CODE = "BATCH_CODE",
  BATCH_FACTORY_EXPORT = "BATCH_FACTORY_EXPORT",
  BATCH_ITEM_CREATION = "BATCH_ITEM_CREATION",
  FLEET_ASSIGNMENT = "FLEET_ASSIGNMENT",
  INITIALIZATION = "INITIALIZATION",
  ITEMS_REASSIGNMENT = "ITEMS_REASSIGNMENT",
  MESSAGE_FROM_MESSAGE_TEMPLATE = "MESSAGE_FROM_MESSAGE_TEMPLATE",
  PROVISION = "PROVISION",
}

export enum ProcessStatusTypes {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  ONGOING = "ONGOING",
  STARTED = "STARTED",
}

export interface ActionDto {
  type: ActionTypes;
  label: string;
  description: string;
}

export interface ActionFilterInput {
  type?: StringFilterInput | null;
  name?: StringFilterInput | null;
  description?: StringFilterInput | null;
}

export interface ActorDto {
  type: ActorTypes;
  name: string;
  description: string;
}

export interface ActorFilterInput {
  type?: StringFilterInput | null;
  name?: StringFilterInput | null;
  description?: StringFilterInput | null;
}

export interface CreateBrokerInput {
  name?: string | null;
  url?: string | null;
  port?: number | null;
  login?: string | null;
  password?: string | null;
}

export interface CreateEventInput {
  actor: ActorDto;
  action: ActionDto;
  time: any;
  location: LocationInputDto;
  signal: boolean;
}

export interface CreateMQTTBrokerInput {
  name: string;
  url: string;
  port: number;
  login?: string | null;
  password?: string | null;
}

export interface CreatePublisherInput {
  topic: string;
  broker: string;
}

export interface CreateSignalInput {
  jmp: any;
  publisher?: string | null;
  time: any;
}

export interface DateTimeFilterInput {
  and?: any[] | null;
  or?: any[] | null;
  not?: DateTimeFilterInput | null;
  eq?: any | null;
  ne?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: any[] | null;
  notIn?: any[] | null;
  between?: any[] | null;
}

export interface FloatFilterInput {
  and?: number[] | null;
  or?: number[] | null;
  not?: FloatFilterInput | null;
  eq?: number | null;
  ne?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: number | null;
  notNull?: boolean | null;
  in?: number[] | null;
  notIn?: number[] | null;
  between?: number[] | null;
}

export interface GetAllEventsInput {
  actor?: ActorFilterInput | null;
  action?: ActionFilterInput | null;
  time?: DateTimeFilterInput | null;
  location?: LocationFilterInput | null;
  signal?: IdFilterInput | null;
}

export interface IdFilterInput {
  and?: string[] | null;
  or?: string[] | null;
  not?: IdFilterInput | null;
  eq?: string | null;
  ne?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: string[] | null;
  notIn?: string[] | null;
  between?: string[] | null;
}

export interface LocationFilterInput {
  placeName?: string | null;
  latitude?: FloatFilterInput | null;
  longitude?: FloatFilterInput | null;
}

export interface LocationInputDto {
  placeName: string;
  latitude: number;
  longitude: number;
}

export interface StringFilterInput {
  and?: string[] | null;
  or?: string[] | null;
  not?: StringFilterInput | null;
  eq?: string | null;
  ne?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: string[] | null;
  notIn?: string[] | null;
  between?: string[] | null;
}

export interface UpdateActionDto {
  type?: ActionTypes | null;
  label?: string | null;
  description?: string | null;
}

export interface UpdateActorDto {
  type?: ActorTypes | null;
  name?: string | null;
  description?: string | null;
}

export interface UpdateBrokerInput {
  brokerId: string;
  name?: string | null;
  url?: string | null;
  port?: number | null;
  login?: string | null;
  password?: string | null;
}

export interface UpdateEventInput {
  eventId: string;
  actor?: UpdateActorDto | null;
  action?: UpdateActionDto | null;
  time?: any | null;
  location?: UpdateLocationInputDto | null;
  signal?: boolean | null;
}

export interface UpdateLocationInputDto {
  placeName?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

export interface UpdatePublisherInput {
  publisherId: string;
  topic?: string | null;
  broker?: string | null;
}

export interface UpdateSignalInput {
  signalId: string;
  jmp?: any | null;
  publisher?: string | null;
  time?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
