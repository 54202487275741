import { ApolloQueryResult, useMutation } from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import { QueryOrder } from 'apps/things/app/types/thingsGlobalTypes';
import clientSimulator from 'utils/clientSimulator';
import { deleteItemBatchMutation } from '../mutations';
import { useLazyGetAllItemBatches } from '../queries';
import {
  DeleteItemBatch,
  DeleteItemBatchVariables,
} from '../types/DeleteItemBatch';
import {
  GetAllItemBatches,
  GetAllItemBatchesVariables,
  GetAllItemBatches_getAllItemBatches_page_edges,
} from '../types/GetAllItemBatches';

import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';

export interface IBatchContext {
  itemBatches: GetAllItemBatches_getAllItemBatches_page_edges[];
  setItemBatches: (
    itemSKUs: GetAllItemBatches_getAllItemBatches_page_edges[]
  ) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  getItemBatches: () => void;
  tableActions: ITableActions[];
  refetchItemBatches:
  | ((
    variables?: Partial<GetAllItemBatchesVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllItemBatches>>)
  | undefined;

  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
}

export const ItemBatchContext = React.createContext<IBatchContext>(
  {} as IBatchContext
);

const ItemBatchProvider: React.FC<IProps> = ({ children }) => {
  const [itemBatches, setItemBatches] = React.useState<
    GetAllItemBatches_getAllItemBatches_page_edges[]
  >([] as GetAllItemBatches_getAllItemBatches_page_edges[]);
  const [loading, setLoading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  /// hooks
  const notify = useNotifier();
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, setPageListLimit, pageListLimit
  } = useOVSPagination();
  const [
    getItemBatches,
    { refetch: refetchItemBatches, data, fetchMore, loading: itemsLoading },
  ] = useLazyGetAllItemBatches({
    first: pageListLimit,
    queryorder: QueryOrder.DESC,
  });

 

  useEffect(() => {
    if (data) {
      setItemBatches(data?.getAllItemBatches?.page?.edges || itemBatches);
      setPaging({
        ...data?.getAllItemBatches?.pageData,
        ...data?.getAllItemBatches?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [data]);

  const [deleteItem] = useMutation<DeleteItemBatch, DeleteItemBatchVariables>(
    deleteItemBatchMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => { },
    }
  );

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllItemBatchesVariables = {
      first: pageListLimit,
      queryorder: QueryOrder.DESC,
      ...initPagAction(next),
    };

    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItemBatches(_data?.data?.getAllItemBatches?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemBatches?.pageData,
        ..._data?.data?.getAllItemBatches?.page?.pageInfo,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItemBatches && refetchItemBatches()
    }, 100);

  }


  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    if (fetchMore) {
      const variables: GetAllItemBatchesVariables = {
        first: pageListLimit,
        queryorder: QueryOrder.DESC,
      };
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItemBatches(_data?.data?.getAllItemBatches?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemBatches?.pageData,
        ..._data?.data?.getAllItemBatches?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            itemBatchId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Items batch deleted successfully',
        });
        if (refetchItemBatches) {
          const itemsFetched = await refetchItemBatches({
            first: 100,
            queryorder: QueryOrder.DESC,
          });
          setItemBatches(
            itemsFetched?.data?.getAllItemBatches?.page?.edges || itemBatches
          );
          setTableActionLoading(false);
        } else {
          await getItemBatches();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItemBatches) {
          const itemsFetched = await refetchItemBatches({
            first: 100,
            queryorder: QueryOrder.DESC,
          });
          setItemBatches(
            itemsFetched?.data?.getAllItemBatches?.page?.edges || itemBatches
          );
          setTableActionLoading(false);
        } else {
          await getItemBatches();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      itemBatches,
      setItemBatches,
      loading: loading || itemsLoading,
      setLoading,
      refetchItemBatches,
      getItemBatches,
      goTo,
      paging,
      searchQuery,
      setSearchQuery, setPageLimit,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} item(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [itemBatches, loading, searchQuery, paging, itemsLoading]
  );

  return (
    <ItemBatchContext.Provider value={value}>
      {children}
    </ItemBatchContext.Provider>
  );
};

export default ItemBatchProvider;
