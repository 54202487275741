import { ApolloError, gql, useMutation } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { productFragment } from './queries';
import { CreateProduct, CreateProductVariables } from './types/CreateProduct';

/**
 * Mutation.createProduct(
createProductInput: CreateProductInput!
): Product!
 */
export const createProductMutation = gql`
  ${productFragment}
  mutation CreateProduct($createProductInput: CreateProductInput!) {
    createEcommerceProduct(createProductInput: $createProductInput) {
      ...Product
    }
  }
`;

/**
 * updateEcommerceProduct(
updateProductInput: UpdateProductInput!
): Product!
 */
export const updateEcommerceProductMutation = gql`
  ${productFragment}
  mutation UpdateEcommerceProduct($updateProductInput: UpdateProductInput!) {
    updateEcommerceProduct(updateProductInput: $updateProductInput) {
      ...Product
    }
  }
`;

/**
 * type Success {
message: String!
status: Float!
}
 */
const successFragment = gql`
  fragment Success on Success {
    message
    status
  }
`;

/**
 * deleteEcommerceProduct(
productId: String!
): Success!
 */
export const deleteEcommerceProductMutation = gql`
  ${successFragment}
  mutation DeleteEcommerceProduct($productId: String!) {
    deleteEcommerceProduct(productId: $productId) {
      ...Success
    }
  }
`;

export const useCreateProduct = (
  callBack: (
    success?: boolean,
    data?: CreateProduct,
    error?: ApolloError
  ) => void
) => {
  const [createProduct, createItemOpts] = useMutation<
    CreateProduct,
    CreateProductVariables
  >(createProductMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack(true, data);
    },
    onError: (err) => {
      console.error(err);
      callBack && callBack(false, undefined, err);
    },
  });

  return {
    createProduct,
    createItemOpts,
  };
};
