import { gql } from '@apollo/client';

/**
 * deletePublisher(
publisherId: String!
): String!
 */
export const deletePublisherMutation = gql`
  mutation DeletePublisher($publisherId: String!) {
    deletePublisher(publisherId: $publisherId)
  }
`;

/**
 * logPublisher(
createPublisherInput: CreatePublisherInput!
): Publisher!
 */
export const logPublisherMutation = gql`
  mutation LogPublisher($createPublisherInput: CreatePublisherInput!) {
    logPublisher(createPublisherInput: $createPublisherInput) {
      _id
    }
  }
`;

/**
 * updatePublisher(
updatePublisherInput: UpdatePublisherInput!
): Publisher!
 */
export const updatePublisherMutation = gql`
  mutation UpdatePublisher($updatePublisherInput: UpdatePublisherInput!) {
    updatePublisher(updatePublisherInput: $updatePublisherInput) {
      _id
    }
  }
`;

/**
 * Mutation.assignPublisherToSignal(
publisherId: ID!
signalId: ID!
): Signal!
 */
export const assignPublisherToSignalMutation = gql`
  mutation AssignPublisherToSignal($publisherId: ID!, $signalId: ID!) {
    assignPublisherToSignal(publisherId: $publisherId, signalId: $signalId) {
      _id
    }
  }
`;
