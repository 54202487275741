import {
  AccountActions,
  ActivityDto,
  CreditAccountDTO,
} from '../../types/accountsGlobalTypes';

export const accountStatuses = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
};

export interface PaymentPlanInput {
  upFrontPrice: number,
  uFrontDaysIncluded: number,
  freecodePrice: number,
  hourPrice: number,
  daysToCutOff: number,
  expectedPaid: number,
  minimumPaymentAmount: number,
  totalPaid? :number,
  useUpfront: boolean
}

export const paymentPlanFields = {
  upFrontPrice: "upFrontPrice",
  uFrontDaysIncluded: "uFrontDaysIncluded",
  freecodePrice: "freecodePrice",
  hourPrice: "hourPrice",
  daysToCutOff: "daysToCutOff",
  expectedPaid: "expectedPaid",
  minimumPaymentAmount: "minimumPaymentAmount",
  totalPaid: "totalPaid",
  planName: "planName",
  planDescription: "planDescription",
  useUpfront: "useUpfront"
}

export const itemFields = {
  c2uRatio: 'c2uRatio',
  clientId: 'clientId',
  userId: 'userId',
  credit: 'credit',
  action: 'credit.activities.action',
  datetimeActivities: 'credit.activities.datetime',
  postState: 'credit.activities.postState',
  preState: 'credit.activities.preState',
  balance: 'credit.balance',
  currency: 'credit.currency',
  customerId: 'credit.customerId',

  itemId: 'itemId',

  payHistory: 'payHistory',
  amountPayHistory: 'payHistory.amount',
  datetimePayHistory: 'payHistory.datetime',
  instructionPayHistory: 'payHistory.instruction',

  paySchedule: 'paySchedule',
  amountPaySchedule: 'paySchedule.amount',
  datetimePaySchedule: 'paySchedule.datetime',
  instructionPaySchedule: 'paySchedule.instruction',
};

const creditAccount = {
  activities: [
    {
      action: '',
      amount: 0,
      datetime: new Date(),
      notes: '',
    },
  ] as ActivityDto[],
  balance: 0,
  currency: 'BOB', // default to Bolivaino currency https://en.wikipedia.org/wiki/ISO_4217
  customerId: '',
};

const payment = {
  amount: 0,
  datetime: new Date(),
  instruction: '',
};

export const defaultItem = {
  c2uRatio: 0,
  clientId: '',
  credit: creditAccount as CreditAccountDTO,
  itemId: '',
  payHistory: payment,
  paySchedule: payment,
  assetAccountId: '',
  userId: '',
};

export const getAccountStageValue = (stage: string | undefined) => {
  if (stage === 'ASSET_ACCOUNT_CREATED' || stage === 'ASSET_USER_PAIRED') {
    return 1;
  }
  if (stage === 'PAYPLAN_LOCKED') {
    return 2;
  }
  if (stage === 'ACCOUNT_ACTIVATED') {
    return 3;
  }
  if (stage === 'PAYPLAN_COMPLETED') {
    return 4;
  }
  if (stage === 'ACCOUNT_CLOSED') {
    return 5;
  }
  return 0;
};

export const accountActionsOptions = [
  {
    _id: AccountActions.ACTIVATE,
    name: AccountActions.ACTIVATE,
  },
  {
    _id: AccountActions.CREATE,
    name: AccountActions.CREATE,
  },
  {
    _id: AccountActions.CREDIT,
    name: AccountActions.CREDIT,
  },
  {
    _id: AccountActions.DEBIT,
    name: AccountActions.DEBIT,
  },
  {
    _id: AccountActions.FREEZE,
    name: AccountActions.FREEZE,
  },
  {
    _id: AccountActions.RESOLVE,
    name: AccountActions.RESOLVE,
  },
  {
    _id: AccountActions.SUSPEND,
    name: AccountActions.SUSPEND,
  },
];

const url = 'asset-accounts';

export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
