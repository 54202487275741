import React, { Dispatch, SetStateAction, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@components/CustomButtons/Button';
import TextInput from '@components/CustomInput/TextInput';
import { InputAdornment } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      flex: 1,
    },
    iconButton: {},
    divider: {},
  })
);

interface IProps {
  placeholder?: string;
  onChange: Dispatch<SetStateAction<any>>;
  onBtnClick: () => void;
  btnText?: string;
  type?: string;
  success?: boolean | undefined;
  loading?: boolean;
  errors?: string;
}
const DaysGenInput: React.FC<IProps> = ({
  type = 'number',
  placeholder = 'OTP days',
  onChange,
  onBtnClick,
  btnText = 'DAYCODE',
  success = undefined,
  loading,
  errors,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  return (
    <div className={classes.root}>
      <TextInput
        className={classes.input}
        placeholder={placeholder}
        autoComplete='off'
        name='days'
        type={type}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
          setValue(e.target.value);
        }}
        disabled={loading}
        error={!!errors}
        helperText={errors?.toString()}
        value={value}
        endAdornment={
          <InputAdornment position='end'>
            {success === true ? (
              <IconButton aria-label='toggle password visibility'>
                <CheckCircleOutlineIcon color='primary' fontSize='small' />
              </IconButton>
            ) : null}
            {success === false ? (
              <IconButton aria-label='toggle password visibility'>
                <CancelIcon color='error' fontSize='small' />
              </IconButton>
            ) : null}
          </InputAdornment>
        }
      />
      <Divider className={classes.divider} orientation='vertical' />
      <Button
        color='primary'
        className={classes.iconButton}
        aria-label='directions'
        onClick={() => {
          onBtnClick()
          setValue('')
        }}
        disabled={loading || !!errors}
        size='sm'
      >
        {loading ? 'loading...' : btnText}
      </Button>
    </div>
  );
};

export default DaysGenInput;
