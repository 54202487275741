import { IProps } from '@components/messages/types';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/epics/userPrefEpics';
import { LegacyActions } from '../redux/actions';

export interface ILegacyCategory {
  productCategoryID: string;
  productCategoryName: string;
  productCategoryDescription: string;
  image: string;
}
export interface ILegacyCategoryContext {
  items: ILegacyCategory[];
  setItems: (item: any) => void;
  loading: boolean;
  getItems: () => void;
}

export const LegacyCategoryContext = createContext<ILegacyCategoryContext>(
  {} as ILegacyCategoryContext
);

const LegacyCategoryProvider: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const itemsSelector = useSelector(
    (state: RootState) => state.legacyCategory.items
  );
  const isProgress = useSelector(
    (state: RootState) => state.legacyCategory.isProgress
  );
  const [items, setItems] = useState<ILegacyCategory[]>([]);

  useEffect(() => {
    if (itemsSelector.data.length) {
      setItems(itemsSelector.data);
    }
  }, [itemsSelector]);
  // context

  const getItems = () => {
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacyBatch()
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  const value = useMemo(
    () => ({
      items,
      setItems,
      loading: isProgress,
      getItems,
    }), // eslint-disable-next-line
    [items, isProgress]
  );

  return (
    <LegacyCategoryContext.Provider value={value}>
      {children}
    </LegacyCategoryContext.Provider>
  );
};

export default LegacyCategoryProvider;
