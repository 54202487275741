import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { skuRoutes as itemRoutes } from './constants';
import Add from "./containers/AddItemSKUContainer";
import Edit from "./containers/EditItemSKUContainer";
import List from "./containers/ItemSKU";

interface IProps { }

export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: itemRoutes.add, element: <Add /> },
    { path: itemRoutes.edit, element: <Edit /> },
  ],
};
const ItemSKUIndex: React.FC<IProps> = () => {

    return <DefaultOutlet />;
}

export default ItemSKUIndex