import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import { GetAllItemEvents, GetAllItemEventsVariables } from './types/GetAllItemEvents';


const itemEventFragment = gql`
fragment ItemEvent on ItemEvent {
  time
  type
  status
  total
  processed
  lastProcessedId
  description
  signal {
      _id
      publisher {
        _id
        deleteStatus
        deleteAt
        createdAt
        updatedAt
        topic
        broker {
          _id
          deleteStatus
          deleteAt
          createdAt
          updatedAt
          name
          url
          port
          login
          password
        }
      }
      jmp
      time
    }
  eventLocation {
    placeName
    latitude
    longitude
    }

  action {
    type
    label
    description
    }
  actor {
    type
    name
    description
  }
  createdAt
  updatedAt
  deleteStatus
  deleteAt
  _id
}
`
/**
 * type ItemEventEdge {
cursor: String
node: ItemEvent
}
 */
const itemEventEdgeFragment = gql`
  ${itemEventFragment}
  fragment ItemEventEdge on ItemEventEdge {
    cursor
    node {
      ...ItemEvent
    }
  }
`;
/**
 * type ItemEventPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const itemEventPageInfoFragment = gql`
  fragment ItemEventPageInfo on ItemEventPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type ItemEventConnection {
edges: [ItemEventEdge!]
pageInfo: ItemEventPageInfo
}
 */
const itemEventConnectionFragment = gql`
  ${itemEventEdgeFragment}
  ${itemEventPageInfoFragment}
  fragment ItemEventConnection on ItemEventConnection {
    edges {
      ...ItemEventEdge
    }
    pageInfo {
      ...ItemEventPageInfo
    }
  }
`;
/**
 * type PageData {
count: Int!
limit: Int!
offset: Int!
}
 */
const pageDataFragment = gql`
  fragment PageData on PageData {
    count
    limit
    offset
  }
`;
/**
 * type GetallItemEventsResponse {
page: ItemEventConnection!
pageData: PageData
}
 */
const getAllItemEventsResponseFragment = gql`
  ${pageDataFragment}
  ${itemEventConnectionFragment}
  fragment GetallItemEventsResponse on GetallItemEventsResponse {
    page {
      ...ItemEventConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * Query.getAllItemEvents(
before: String
after: String
first: Int
last: Int
search: String
): GetallItemEventsResponse!
 */
const getAllEventsQuery = gql`
  ${getAllItemEventsResponseFragment}
  query GetAllItemEvents(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $search: String
  ) {
    getAllItemEvents(
      after: $after
      before: $before
      first: $first
      last: $last
      search: $search
    ) {
      ...GetallItemEventsResponse
    }
  }
`;

export const useGetAllItemEventsQuery = (variables: GetAllItemEventsVariables) =>
  useQuery<GetAllItemEvents, GetAllItemEventsVariables>(getAllEventsQuery, {
    client: clientAccount,
    variables,
  });

export const useLazyGetAllItemEventsQuery = (variables: GetAllItemEventsVariables) =>
  useLazyQuery<GetAllItemEvents, GetAllItemEventsVariables>(getAllEventsQuery, {
    client: clientAccount,
    variables,
  });
