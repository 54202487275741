import * as React from 'react';
import { Row } from 'react-table';
import makeData from '../PreviewTable/makeData';

export interface IData {
  customerName: string;
  phone: string;
  email: string;
  position: string;
}
export interface IProvisionDataContext {
  data: Row<{}>[];
  setData: (data: Row<{}>[]) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  failed: number[];
  setFailed: (failed: number[]) => void;
  batchNumberItems: Row<{}>[];
  setBatchNumberItems: (data: Row<{}>[]) => void;
  fileName: string;
  setFileName: (name: string) => void;
}

export const ProvisionDataContext = React.createContext(
  {} as IProvisionDataContext
);

interface IProps {
  children: React.ReactNode;
}

const ProvisionDataProvider: React.FC<IProps> = ({ children }) => {
  const [data, setData] = React.useState<Row<{}>[]>(makeData(20));
  const [batchNumberItems, setBatchNumberItems] = React.useState<Row<{}>[]>(
    makeData(20)
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [failed, setFailed] = React.useState<number[]>([]);
  const [fileName, setFileName] = React.useState('Provision-csv');
  const value = React.useMemo(
    () => ({
      data,
      setData,
      isLoading,
      setIsLoading,
      failed,
      setFailed,
      batchNumberItems,
      setBatchNumberItems,
      fileName,
      setFileName,
    }), // eslint-disable-next-line
    [data, failed, batchNumberItems, fileName]
  );
  return (
    <ProvisionDataContext.Provider value={value}>
      {children}
    </ProvisionDataContext.Provider>
  );
};

export const useProvisionDataProvider = () => {
  const { setFailed, failed } = React.useContext(ProvisionDataContext);
  return {
    setFailed,
    failed,
  };
};

export default ProvisionDataProvider;
