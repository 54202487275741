import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LegacyItemContext } from '../context/LegacyItemContext';
import { legacyRoutes } from '../constants';
import LegacyAddItem from '../components/LegacyAddItem';
import { ProductItemInput } from '../types';
import * as actions from '../redux/actions';

const EditItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();

  const [loadingOne, setLoading] = useState(true);
  const { itemId = '' } = useParams();

  const { getItems, loading } = React.useContext(LegacyItemContext);
  const [item, setItem] = useState({} as ProductItemInput);

  useEffect(() => {
    actions.LegacyActions.getOneItem(itemId)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false);
        setItem(res);
      })
      .catch((error) => {
        setLoading(false);
      }); // eslint-disable-next-line
  }, []);

  const handleSubmit = (
    createItemInput: ProductItemInput,
    totalItems: number
  ) => {
    actions.LegacyActions.updateLegacyItem(itemId, {
      ...createItemInput,
      productItemID: itemId,
    }).then(async (res) => {
      notify({
        status: 'success',
        text: `Updated successfully.`,
      });
      getItems();
      history(legacyRoutes.list);
    });
  };

  if (loadingOne || !item.productItemID) return <GridLoader />;
  function removeByIndex(str: string, index: number) {
    return str.slice(0, index) + str.slice(index + 1);
  }
  let firm = item.firmwareVersion || '';
  if (firm[0] === '"') {
    firm = removeByIndex(firm, 0);
    firm = removeByIndex(firm, firm.length - 1);
  }
  const firmwareJson = JSON.parse(firm || '{}') || {};
  return (
    <LegacyAddItem
      initialValues={{
        ...item,
        firmwareVersion: JSON.stringify({
          protocol: firmwareJson.protocol,
          firmware: firmwareJson.firmware,
        }),
        totalItems: 0,
      }}
      handleSubmit={handleSubmit}
      isLoading={loading}
      isUpdate
    />
  );
};

export default EditItemContainer;
