import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import { GetAllEvents, GetAllEventsVariables } from './types/GetAllEvents';
import { ViewEvent, ViewEventVariables } from './types/ViewEvent';

/**
 * type Event {
_id: ID!
action: Action!
actor: Actor!
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
eventLocation: Location!
signal: Signal
time: DateTime!
updatedAt: DateTime
}
 */
const eventFragment = gql`
  fragment Event on Event {

    action {
      description
      label
      type
    }
    actor {
      description
      name
      type
    }
    createdAt
    deleteAt
    deleteStatus
    eventLocation {
      latitude
      longitude
      placeName
    }
    signal {
      _id
      jmp
      time
      publisher {
        broker {
          _id
          createdAt
          deleteAt
          deleteStatus
          login
          name
          password
          port
          updatedAt
          url
        }
      }
    }
    time
    updatedAt
    _id
  }
`;
/**
 * type EventEdge {
cursor: String
node: Event
}
 */
const eventEdgeFragment = gql`
  ${eventFragment}
  fragment EventEdge on EventEdge {
    cursor
    node {
      ...Event
    }
  }
`;
/**
 * type EventPageInfo {
endCursor: String
hasNextPage: Boolean!
hasPreviousPage: Boolean!
startCursor: String
}
 */
const eventPageInfoFragment = gql`
  fragment EventPageInfo on EventPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type EventConnection {
edges: [EventEdge!]
pageInfo: EventPageInfo
}
 */
const eventConnectionFragment = gql`
  ${eventEdgeFragment}
  ${eventPageInfoFragment}
  fragment EventConnection on EventConnection {
    edges {
      ...EventEdge
    }
    pageInfo {
      ...EventPageInfo
    }
  }
`;
/**
 * type PageData {
count: Float!
limit: Float!
offset: Float!
}
 */
const pageDataFragment = gql`
  fragment PageData on PageData {
    count
    limit
    offset
  }
`;
/**
 * type GetAllEventsResponse {
page: EventConnection!
pageData: PageData
}
 */
const getAllEventsResponseFragment = gql`
  ${pageDataFragment}
  ${eventConnectionFragment}
  fragment GetAllEventsResponse on GetAllEventsResponse {
    page {
      ...EventConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * Query.getAllEvents(
after: String
before: String
filters: GetAllEventsInput!
first: Int
last: Int
): GetAllEventsResponse!
 */
const getAllEventsQuery = gql`
  ${getAllEventsResponseFragment}
  query GetAllEvents(
    $after: String
    $before: String
    $filters: GetAllEventsInput!
    $first: Int
    $last: Int
  ) {
    getAllEvents(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
    ) {
      ...GetAllEventsResponse
    }
  }
`;

/**
 * 
 Query.viewEvent(
id: ID!
): Event
 */
const viewEventQuery = gql`
  ${eventFragment}
  query ViewEvent($id: ID!) {
    viewEvent(id: $id) {
      ...Event
    }
  }
`;

export const useViewEventQuery = (variables: ViewEventVariables) =>
  useQuery<ViewEvent, ViewEventVariables>(viewEventQuery, {
    client: clientAccount,
    variables,
  });

export const useGetAllEventsQuery = (variables: GetAllEventsVariables) =>
  useQuery<GetAllEvents, GetAllEventsVariables>(getAllEventsQuery, {
    client: clientAccount,
    variables,
  });

export const useLazyGetAllEventsQuery = (variables: GetAllEventsVariables) =>
  useLazyQuery<GetAllEvents, GetAllEventsVariables>(getAllEventsQuery, {
    client: clientAccount,
    variables,
  });
