import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import AdvancedSearch from './AdvancedSearch';
import { HeaderGroup } from 'react-table';
import { IAdvancedQuery } from './AdvancedSearch/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
      marginTop: 18,
    },
  })
);
interface IProps {
  setGlobalFilter: any;
  setSearchQuery?: (searchQuery: string) => void;
  headers: HeaderGroup;
  advancedSearch?: (queries: IAdvancedQuery[]) => void;
  queries?: IAdvancedQuery[];
  setQueries?: (queries: IAdvancedQuery[]) => void;
  refetchData?: () => void;
  searchQuery?: string;
}
const TableSearchBox: React.FC<IProps> = ({
  setGlobalFilter,
  setSearchQuery,
  headers,
  advancedSearch,
  queries,
  setQueries,
  refetchData,
  searchQuery
}) => {
  const classes = useStyles();

  return (
    <>
      <InputBase
        className={classes.input}
        placeholder='Search...'
        inputProps={{ 'aria-label': 'search ' }}
        value={searchQuery || ''}
        onChange={(e) => {
          if (setSearchQuery) {
            setSearchQuery(e.target.value);
          } else {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }
        }}
      />
      {advancedSearch && (
        <AdvancedSearch
          advancedSearch={advancedSearch}
          queries={queries}
          setQueries={setQueries}
          refetchData={refetchData}
          columns={[
            { value: '', label: '' },
            ...headers.headers
              .filter(
                (item) =>
                  !['', 'Actions'].includes(item.Header as string) &&
                  typeof item.Header === 'string'
              )
              .map((prop, key) => ({
                value: prop.Header as string,
                label: prop.Header as string,
              })),
          ]}
        />
      )}
    </>
  );
};

export default TableSearchBox;
