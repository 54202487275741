import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import clientSimulator from 'utils/clientSimulator';
import { GetAllSuppliersResponse_page_edges } from '../types/GetAllSuppliersResponse';
import { GetAllSuppliers, GetAllSuppliersVariables } from '../types/GetAllSuppliers';
import {
  DeleteSupplier,
  DeleteSupplierVariables,
} from '../types/DeleteSupplier';
import { deleteSupplierMutation } from '../mutations';
import { useGetAllSuppliersQuery } from '../queries';
import useOVSPagination from 'hooks/useOVSPagination';
import { funNumberAgr } from '@types';
export interface ISupplierContext {
  items: GetAllSuppliersResponse_page_edges[];
  setItems: (itemSKUs: GetAllSuppliersResponse_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllSuppliers>>)
    | undefined;
      // pagination
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setPageLimit: funNumberAgr
}

export const SupplierContext = React.createContext<ISupplierContext>(
  {} as ISupplierContext
);

const SupplierProvider: React.FC<IProps> = ({ children }) => {
  const [items, setItems] = React.useState<
    GetAllSuppliersResponse_page_edges[]
  >([] as GetAllSuppliersResponse_page_edges[]);

  const { setTableActionLoading } = useContext(GlobalSimulatorContext);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,
    setPageListLimit,
    pageListLimit
  } = useOVSPagination();
  /// hooks
  const notify = useNotifier();
  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useGetAllSuppliersQuery({
    first: pageListLimit,
  });

  useEffect(() => {
    setItems(data?.getAllSuppliers?.page?.edges || []);
    setPaging({
      ...data?.getAllSuppliers?.pageData,
      ...data?.getAllSuppliers?.page?.pageInfo,
    }); // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllSuppliersVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {      
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllSuppliers?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllSuppliers?.pageData,
        ..._data?.data?.getAllSuppliers?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const [deleteItem] = useMutation<DeleteSupplier, DeleteSupplierVariables>(
    deleteSupplierMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {},
    }
  );

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            supplierId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllSuppliers?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllSuppliers?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      getItems,
      refetchItems,
      goTo, paging,
      setPageLimit,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} supplier(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading, paging]
  );

  return (
    <SupplierContext.Provider value={value}>
      {children}
    </SupplierContext.Provider>
  );
};

export default SupplierProvider;
