import { routes as clientServiceRoutes } from "./app/clientExclusiveServicer"
import { routes as serviceRoutes } from "./app/clientService"
import { routes as servicePermRoutes } from "./app/clientServicePermissions"
import { routes as customerRoutes } from "./app/customer"
import { routes as distributorRoutes } from "./app/distributor"
import { routes as organisationRoutes } from "./app/organization"
import { routes as servicerRoutes } from "./app/servicer"
import { routes as supplierRoutes } from "./app/supplier"
import {routes as agentRoutes} from "./app/agents"
import {routes as distributorStaffRoutes} from "./app/distributorStaff"

export const clientServicRoutes = [
 clientServiceRoutes,
 serviceRoutes,
 servicePermRoutes,
 customerRoutes,
 distributorRoutes,
 organisationRoutes,
 servicerRoutes,
 supplierRoutes,
 agentRoutes,
 distributorStaffRoutes
]