import { CodeTypes } from 'apps/things/app/types/thingsGlobalTypes';

export const itemFirmwareFields = {
  codeSystem: 'codeSystem',
  description: 'description',
  version: 'version',
};

export const itemFirmwareRoutes = {
  list: '/admin/firmware',
  add: '/admin/firmware/add',
  edit: '/admin/firmware/:itemId',
  getEditLink: (itemId: string) => `/admin/firmware/${itemId}`,
};

export const codeTypeOptions = () => {
  let options = [];
  for (let item in CodeTypes) {
    options.push({
      _id: item, // @ts-ignore
      name: CodeTypes[item],
    });
  }
  return options;
};
