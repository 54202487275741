import { LegacyItemContext } from 'apps/migration/app/LegacyItem/context/LegacyItemContext';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  value: string;
}

const BatchLegacyLinkCell: React.FC<IProps> = ({ value }) => {
  const { setQueries, advancedSearch } = useContext(LegacyItemContext);

  const onClick = () => {
    setQueries([{ column: 'productBatchNumber', value: value }]);
    advancedSearch([{ column: 'productBatchNumber', value: value }]);
  };
  return (
    <Link to={`/admin/legacy-item?productBatchID=${value}`} onClick={onClick}>
      {value}
    </Link>
  );
};

export default BatchLegacyLinkCell;
