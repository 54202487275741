import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import { DeleteSignal, DeleteSignalVariables } from 'apps/events/app/signal/types/DeleteSignal';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { deleteSignalMutation } from '../mutations';

const useDeleteSignal = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    DeleteSignal,
    DeleteSignalVariables
  >(deleteSignalMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Deleted successfully', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useDeleteSignal;
