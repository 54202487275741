import { PAGE_LIST_LIMIT } from '@constants';
import useNotifier from 'hooks/useNotifier';
import { useEffect, useMemo, useState } from 'react';
import { useLazyGetAllServicersQuery } from '../queries';

const useServicer = () => {
  const [options, setOptions] = useState<{ _id: string; name: string }[]>([]);

  const [
    getItems,
    { data, loading, error, refetch },
  ] = useLazyGetAllServicersQuery({
    first: PAGE_LIST_LIMIT,
  });
  const notify = useNotifier();

  useEffect(() => {
    if (!options?.length) {
      getItems();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOptions(
      data?.getAllServicers?.page?.edges?.map((item) => ({
        _id: item?.node?._id || '',
        name: item?.node?.orgContactPerson?.contact?.email || '',
      })) || []
    ); // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error) {
      notify({ text: 'Error getting servicers', status: 'error' });
    } // eslint-disable-next-line
  }, [error]);

  const search = async (search: string) => {
    if (!refetch) {
      return Promise.resolve(
        data?.getAllServicers?.page?.edges
          ?.filter((it) =>
            it?.node?.orgContactPerson?.contact?.email?.includes(search)
          )
          ?.map((it) => ({
            label: it?.node?.orgContactPerson?.contact?.email || '',
            value: it?.node?._id || '',
          })) || []
      );
    }
    const refetchData = await refetch({
      first: PAGE_LIST_LIMIT,
    });

    const items =
      refetchData?.data?.getAllServicers?.page?.edges
        ?.filter((it) =>
          it?.node?.orgContactPerson?.contact?.email?.includes(search)
        )
        ?.map((item) => {
          return {
            label: item?.node?.orgContactPerson?.contact?.email || '',
            value: item.node?._id || '',
          };
        }) || [];
    return Promise.resolve(items as { value: string; label: string }[]);
  };

  return useMemo(
    () => ({
      options,
      loading,
      search,
      getItems,
    }), // eslint-disable-next-line
    [loading, options]
  );
};

export default useServicer;
