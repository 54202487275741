import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import { Row } from 'react-table';
import TableActions from '../components/TableActions';


export const itemTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <TableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },

    {
      Header: 'Name',
      accessor: 'node.orgContactPerson.name',
    },
    {
      Header: 'Email',
      accessor: 'node.orgContactPerson.contact.email',
    },
    {
      Header: 'Phone',
      accessor: 'node.orgContactPerson.contact.phone',
    },
    {
      Header: 'Social',
      accessor: 'node.orgContactPerson.contact.social',
      Cell: DescriptionCell
    },
    {
      Header: 'City',
      accessor: 'node.orgContactPerson.address.city',
    },
    {
      Header: 'Country',
      accessor: 'node.orgContactPerson.address.country',
    },
    {
      Header: 'Postcode',
      accessor: 'node.orgContactPerson.address.postcode',
    },
    {
      Header: 'srpc',
      accessor: 'node.orgContactPerson.address.srpc',
    },
    {
      Header: 'Street',
      accessor: 'node.orgContactPerson.address.street',
      Cell: DescriptionCell
    },
    {
      Header: 'Unit',
      accessor: 'node.orgContactPerson.address.unit',
    },
    {
      Header: 'Latitude',
      accessor: 'node.orgContactPerson.address.addressLocation.addressLatitude',
    },
    {
      Header: 'Latitude',
      accessor: 'node.orgContactPerson.address.addressLocation.addressLongitude',
    },
    {
      Header: 'Type',
      accessor: 'node.type',
    },

    {
      Header: 'Description',
      accessor: 'node.description',
      Cell: DescriptionCell,
    },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
    { Header: 'ID', accessor: 'node._id' },
  ],
});
