/**
 * mutation {
  createItemSKU(createItemSKUInput: {actorTypes})
}
 */

import { gql } from '@apollo/client';
import { itemSkuFragment } from './queries';

/**
 * mutation {
  updateItemSKU(updateItemSKUInput:)
}
 */

export const updateItemSKUMutation = gql`
  ${itemSkuFragment}
  mutation UpdateItemSKU($updateItemSKUInput: UpdateItemSKUInput!) {
    updateItemSKU(updateItemSKUInput: $updateItemSKUInput) {
      ...ItemSKU
    }
  }
`;

export const createItemSkuMutation = gql`
  ${itemSkuFragment}
  mutation CreateItemSKU($createItemSKUInput: CreateItemSKUInput!) {
    createItemSKU(createItemSKUInput: $createItemSKUInput) {
      ...ItemSKU
    }
  }
`;

/**
 * Mutation.deleteItemSKU(
itemSKUId: String!
): String!
 */
export const deleteItemSKUMutation = gql`
  mutation DeleteItemSKU($itemSKUId: String!) {
    deleteItemSKU(itemSKUId: $itemSKUId)
  }
`;

/**
 * Mutation.deleteGATTPropertiesFromItemSKU(
propValue: String!
itemSkuId: ID!
): ItemSKU!
 */
export const deleteGATTPropertiesFromItemSKUMutation = gql`
  mutation DeleteGATTPropertiesFromItemSKU(
    $propValue: String!
    $itemSkuId: ID!
  ) {
    deleteGATTPropertiesFromItemSKU(
      propValue: $propValue
      itemSkuId: $itemSkuId
    ) {
     _id 
    }
  }
`;
