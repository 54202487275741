import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { itemRoutes } from './constants';
import List from "./containers/Item";

interface IProps { }
export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
  ]

};
const ItemEventIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet
     
    />
  );
};

export default ItemEventIndex;
