
export const productTableData = () => ({
  header: [
    {
      accessor: 'checkbox',
      sticky: 'left',
    },
    {
      Header: 'OEM SN',
      accessor: 'node.OEM_SN',
    },

    {
      Header: 'Date added',
      accessor: 'dateAdded',
    },
    {
      Header: 'Note',
      accessor: 'note',
    },
    {
      Header: 'Value Per Day',
      accessor: 'valuePerDay',
    },
    {
      Header: 'Value Days',
      accessor: 'valueDays',
    },
    {
      Header: 'Longevity Days',
      accessor: 'longevityDays',
    },
    {
      Header: 'Activate Date',
      accessor: 'activateDate',
    },
    {
      Header: 'Deploy Notes',
      accessor: 'deployNotes',
    },
    {
      Header: 'PAYG SN',
      accessor: 'PAYG_SN',
    },
    {
      Header: 'Life Cycle Status',
      accessor: 'lifeCycleStatus',
    },
    {
      Header: 'Distributor ID',
      accessor: 'distributorID',
    },
    {
      Header: 'Distributor Name',
      accessor: 'distributorName',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      sticky: 'right',
    },
  ],
  dataRows: Array.from(
    Array(1000)
      .fill(2)
      .map((_, index: number) => ({
        node : {OEM_SN:'03AH1900000' + index,},
        OEM_SN:'03AH1900000' + index,
        dateAdded: Date.now(),
        note: '',
        valuePerDay: '      00.0        ',
        valueDays: Math.floor(Math.random() * 10),
        longevityDays: 0,
        activateDate: '',
        deployNotes: '',
        PAYG_SN: '03AH1900000' + Math.floor(Math.random() * 1000),
        lifeCycleStatus: 'Provisioned',
        distributorID: 68,
        distributorName: 'Pengd Wiilingd',
      }))
  ),
});
