import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';


import useOVSPagination from 'hooks/useOVSPagination';
import { funNumberAgr } from '@types';
import { GetAllClientExclusiveServicePermissions, GetAllClientExclusiveServicePermissionsVariables } from '../types/GetAllClientExclusiveServicePermissions';
import { GetAllClientExclusiveServicePermissionsResponse_page_edges } from '../types/GetAllClientExclusiveServicePermissionsResponse';
import { PAGE_LIST_LIMIT } from '@constants';
import { useLazyGetAllClientServicePermissionsQuery } from '../queries';

export interface IContext {
	items: GetAllClientExclusiveServicePermissionsResponse_page_edges[];
	setItems: (item: GetAllClientExclusiveServicePermissionsResponse_page_edges[]) => void;
	loading: boolean;
	getItems: () => void;
	activeStage: number;
	setActiveStage: (stage: number) => void;
	tableActions: ITableActions[];
	refetchItems:
	| ((
		variables?: Partial<GetAllClientExclusiveServicePermissionsVariables> | undefined
	) => Promise<ApolloQueryResult<GetAllClientExclusiveServicePermissions>>)
	| undefined;
	goTo: (nextPrev: boolean) => void;
	paging: any;
	setPageLimit: funNumberAgr
}

export const ClientServicePermissionContext = React.createContext(
	{} as IContext
);

const ClientServicePermissionProvider: React.FC<IProps> = ({ children }) => {
	const [activeStage, setActiveStage] = useState(0);
	const [item, setItem] = React.useState(
		{} as GetAllClientExclusiveServicePermissionsResponse_page_edges
	);
	const [items, setItems] = React.useState<
		GetAllClientExclusiveServicePermissionsResponse_page_edges[]
	>([]);
	const [isLoadingMore, setIsLoadingMore] = useState(false);

	/// hooks
	const {
		endCursorStack,
		initPagAction,
		setPaging,
		paging, pageListLimit, setPageListLimit
	} = useOVSPagination();

	const [
		getItems,
		{ refetch: refetchItems, data, loading, fetchMore },
	] = useLazyGetAllClientServicePermissionsQuery({
		first: PAGE_LIST_LIMIT,
	});

	useEffect(() => {
		setItems(data?.getAllClientExclusiveServicePermissions?.page?.edges || []);
		setPaging({
			...data?.getAllClientExclusiveServicePermissions?.pageData,
			...data?.getAllClientExclusiveServicePermissions?.page?.pageInfo,
		});
		// eslint-disable-next-line
	}, [data]);

	const goTo = async (next = true) => {
		if (isLoadingMore) return;

		let variables: GetAllClientExclusiveServicePermissionsVariables = {
			first: pageListLimit,
			...initPagAction(next),
		};

		if (fetchMore) {
			setIsLoadingMore(true);
			const _data = await fetchMore({
				variables,
				updateQuery: (previousResult, { fetchMoreResult }) => {
					setIsLoadingMore(false);
					if (!fetchMoreResult) {
						return previousResult;
					}
					return {
						...fetchMoreResult,
					};
				},
			});

			setItems(_data?.data?.getAllClientExclusiveServicePermissions?.page?.edges || []);

			setPaging({
				..._data?.data?.getAllClientExclusiveServicePermissions?.pageData,
				..._data?.data?.getAllClientExclusiveServicePermissions?.page?.pageInfo,
				hasPreviousPage: endCursorStack.length > 0,
			});
		}
	};

	const setPageLimit = (limit: number) => {
		setPageListLimit(limit)
		setTimeout(() => {
			refetchItems && refetchItems()
		}, 100);

	}

	const value = React.useMemo(
		() => ({
			item,
			setItem,
			items,
			setItems,
			loading: loading,
			getItems: getItems,
			refetchItems,
			tableActions: [],
			activeStage,
			setActiveStage,
			paging,
			goTo, setPageLimit
		}), // eslint-disable-next-line
		[items, loading, paging]
	);

	return (
		<ClientServicePermissionContext.Provider value={value}>{children}</ClientServicePermissionContext.Provider>
	);
};

export default ClientServicePermissionProvider;
