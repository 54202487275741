import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { resetDistributorPasswordMutation } from '../mutations';
import { ResetDistributorPassword, ResetDistributorPasswordVariables } from '../types/ResetDistributorPassword';

const useResetPasswordToken = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
  ResetDistributorPassword,
  ResetDistributorPasswordVariables
  >(resetDistributorPasswordMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Success', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useResetPasswordToken;
