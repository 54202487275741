import { routes as assignedItems } from "./LegacyAssignedItems";
import { routes as batch } from "./LegacyBatch";
import { routes as category } from "./LegacyCategory";
import { routes as codeHistory } from "./LegacyCodeHistory";
import { routes as distributor } from "./LegacyDistributor";
import { routes as family } from "./LegacyFamily";
import { routes as item } from "./LegacyItem";
import { routes as job } from "./LegacyJob";

import { routes as model } from "./LegacyModel";
import { routes as protocol } from "./LegacyProtocolVersion";
import { routes as provision } from "./provision";
import { routes as ERMJob } from "./ScanningICCJob";

export const migrationRoutes = [
  codeHistory,
  assignedItems,
  batch,
  category,
  distributor,
  family,
  item,
  job,
  model,
  protocol,
  provision,
  ERMJob,
];
