import { Row } from 'react-table';
import RoleTableAction from '../components/RoleTableAction';


export const roleTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <RoleTableAction {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: ' Name',
      accessor: 'node.name',
    },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
    },
    {
      Header: 'ID',
      accessor: 'node._id',
    },
  ],
});
