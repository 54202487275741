import React from 'react';
import { titleCase } from 'utils';

interface IProps {
	value: string;
}

const TitleCaseCell: React.FC<IProps> = ({ value }) => {

	return <div>{titleCase(value || '')}</div>;
};

export default TitleCaseCell;
