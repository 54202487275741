import _ from 'lodash';
import { Column } from 'react-table';
import DateCell from './DateCell';
import DescriptionCell from './DescriptionCell';
import SafeCell from './SafeCell';
import StatusCell from './StatusCell';

export interface ITableHeaderRow {
  Header: string;
  id?: string;
  accessor: string;
  Cell?: any;
}

const generateColumns = (
  row: any,
  prefix = 'node',
  name = '',
  count = 0
): { parent: Column[]; children: { name: string; data: string[] }[] } => {
  let arr: { name: string; data: string[] }[] = [];
  let parentColumns: Column[] = [];
  const columns = Object.keys(_.get(row, `${prefix}`) || {}) || [];

  for (const it of columns) {
    const rowData = _.get(row, `${prefix}.${it}`);

    let _row: Column = {
      Header: name + ' ' + it,
      accessor: `${prefix}.${it}`,
      Cell: SafeCell
    };

    // process description and array cell
    if (['description', 'planDescription', 'lastSub', 'lastPub', 'type'].includes(it) || Array.isArray(rowData)) {
      _row.Cell = DescriptionCell;
    }
    // Status Cell Update
    if(['status'].includes(it)) {
      _row.Cell = StatusCell
    }
    // process root level child
    let add = true;
    if (
      typeof rowData === 'object' &&
      !Array.isArray(rowData) &&
      rowData !== null &&
      count <= 2
    ) {
      add = false;
      _row.Cell = DescriptionCell;
      const _data = generateColumns(row, `${prefix}.${it}`, it, 1);
      parentColumns = [...parentColumns, ..._data.parent];

      arr.push({ name: `${prefix}.${it}`, data: rowData });
    }

    if (count === 1) {
      _row.Cell = DescriptionCell;
    }

    if (!rowData) {
      _row.Cell = DescriptionCell; 
    }

    if (['updatedAt', 'createdAt', 'deleteAt', 'time'].includes(it)) {
      _row.Cell = DateCell;
    }
    if (add) {
      if (!JSON.stringify(_row?.accessor).includes('_typename')) {
        parentColumns.push(_row);
      }
     
    }

  }

  return { parent: parentColumns, children: arr };
};

export const tableHeaderGen = (row: any) => {
  const data = generateColumns(row);

  return data.parent;
};
