import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LegacyProtocolVersionContext as Context } from '../context/LegacyProtocolVersionContext';
import { legacyRoutes } from '../constants';
import LegacyAddItem from '../components/LegacyAddItem';
import { IProductModel as ItemType } from '../types';
import * as actions from '../redux/actions';

const EditItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();

  const [loadingOne, setLoading] = useState(true);
  const { itemId = '' } = useParams();

  const { getItems, loading } = React.useContext(Context);
  const [item, setItem] = useState({} as ItemType);

  useEffect(() => {
    actions.LegacyActions.getOneItem(itemId)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false);
        setItem(res);
      })
      .catch((error) => {
        setLoading(false);
      }); // eslint-disable-next-line
  }, []);

  const handleSubmit = (createItemInput: ItemType) => {
    actions.LegacyActions.updateLegacyItem(itemId, {
      ...createItemInput,
      productCategoryID: itemId,
    }).then(async (res) => {
      notify({
        status: 'success',
        text: `Updated successfully.`,
      });
      getItems();
      history(legacyRoutes.list);
    });
  };

  if (loadingOne || !item.productModelID) return <GridLoader />;

  return (
    <>
      <LegacyAddItem
        initialValues={{ ...item, productModelCost: item.productModelCost || 0.00, quantityInStock: item.quantityInStock || 0 }}
        handleSubmit={handleSubmit}
        isLoading={loading}
      />
    </>
  );
};

export default EditItemContainer;
