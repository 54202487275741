import { AttributeDto } from 'admin/types/globalTypes';

export const itemSKUFields = {
  addItemSKU: {
    skuName: 'skuName',
    mainMediaURL: 'mainMediaURL',
    oemDescription: 'oemDescription',
    baseCosts: 'baseCosts',
    productBase: 'productBase',
    gatt: 'gatt',
    gattName: 'gatt.name',
    gattAttribute: 'gatt.attributes',
  },
  updateItemSKU: {
    itemSKUId: 'itemSKUId',
    actorTypes: 'actorTypes',
    actionScope: 'actionScope',
    actorName: 'actorName',
    productBase: 'productBase',
  },
};

export const defaultItemSKU = {
  skuName: '',
  productBase: '',
  oemDescription: '',
  gatt: {
    name: '',
    attributes: [{prop: '', value: '', meta: ''}] as AttributeDto[],
  },
};

export const skuRoutes = {
  list: '/admin/sku',
  add: '/admin/sku/add',
  edit: '/admin/sku/:itemId',
  getEditLink: (itemId: string) => `/admin/sku/${itemId}`,
};
