import React from 'react';

// @material-ui/core components

// core components

import { Formik, FormikProps } from 'formik';
import { assignDistributorToServicer } from 'apps/things/app/validations';
import { defaultItem } from '../constants';
import AssignDistributorForm from '../../servicer/components/AssignDistributorForm';
import { AssignDistributorClientExclusiveServiceTypeInput } from '../../types/clientServiceGlobalTypes';

interface IRTable {
  handleSubmit: (payload: typeof defaultItem) => void;
  isLoading: boolean;
  item: typeof defaultItem;
}

const AddItem: React.FC<IRTable> = ({ handleSubmit, isLoading, item }) => {
  return (
    <>
      <Formik
        onSubmit={async (values: any) => handleSubmit(values)}
        initialValues={{
          ...item,
        }}
        validationSchema={assignDistributorToServicer}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formBag) => (
          <AssignDistributorForm
            formBag={(formBag as unknown) as FormikProps<AssignDistributorClientExclusiveServiceTypeInput>}
            loading={isLoading}
          />
        )}
      </Formik>
    </>

  );
};

export default AddItem;
