import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";
import CardWrapper from "@components/Card/CardWrapper";
import { itemTableData } from "../constants/peopleViewData";
import GoBackHeader from "@components/GoBackHeader/GoBackHeader";
import { PeopleViewContext } from "../context/PeopleViewProvider";
import TablePreview from "@components/Loaders/TablePreview";
interface IRTable {
  bulkDeleteProducts?: () => void;
  isLoading?: boolean;
  itemId?: string;
}

const CodeEventPage: React.FC<IRTable> = ({
  bulkDeleteProducts,
  isLoading,
  itemId,
}) => {

  const { itemIdd } = useParams();
  const [headers, setHeaders] = useState<Column[]>([]);
  const { setItemId, loading, items, paging, setPageLimit, goTo } = useContext(PeopleViewContext)

  useEffect(() => {
    if (itemIdd) {
      setItemId(itemIdd)
    }
  })

  useEffect(() => {
    if (items.length > 0) {
      setHeaders([...itemTableData]);
    }
  },[items])

  if (headers.length === 0) {
    return <TablePreview items={items} loading={loading} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <GoBackHeader title="Go Back" />
          <OvsTable
            columns={headers as Column[]}
            data={items}
            checkedIds={[]}
            bulkDelete={bulkDeleteProducts}
            tableTitle="Code Event List"
            isLoading={isLoading || loading}
            hideRightToolbar
            paging={paging}
            goTo={goTo}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default CodeEventPage;
