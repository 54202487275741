import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import React, { useContext, useState, useMemo } from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { Row } from "react-table";
import ActionModal from "@components/Modal/ActionModal";
import { useMutation } from "@apollo/client";
import clientSimulator from "utils/clientSimulator";
import useNotifier from "hooks/useNotifier";
import { useNavigate } from "react-router-dom";
import { itemRoutes } from "../constants";
import {
  DeleteItemFleet,
  DeleteItemFleetVariables,
} from "../types/DeleteItemFleet";
import { reassignFleetItemsFromItemFleet } from "../mutations";
import { ReassignFleetItemsFromItemFleetVariables, ReassignFleetItemsFromItemFleet } from "../types/reassignFleetItemsFromItemFleet";
import { UpdateItemFleetCodeGenVariables, UpdateItemFleetCodeGen } from "../types/UpdateItemFleetCodeGen";
import { UpdateItemFleetCodeGenMutation } from "../mutations";
import { deleteItemFleetMutation } from "../mutations";
import { useAuth } from "admin/auth/context/AuthProvider";
import { FleetContext } from "../context/FleetProvider";
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import GridContainer from "@components/Grid/GridContainer";
import { Formik } from "formik";
import AssignItems from "./AssignItems";
import GridItem from "@components/Grid/GridItem";
import { useGetTotalItemsInFleet } from "../hooks/useFleet";
import { numberWithCommas } from "utils";
import Button from "@components/CustomButtons/Button";
import OVSForm from "@components/Form/OVSForm";
import { handleGQLErrors } from "utils/gqlErrors";
import TextInput from "@components/CustomInput/TextInput";
import { itemFields } from '../constants';
import { UpdateItemFleetCodeGenInput } from "apps/things/app/types/thingsGlobalTypes";
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();
  const { isDistributor } = useAuth();
  const { refetchItems: refetch } = useContext(FleetContext);

  const { loading, total, getItems } = useGetTotalItemsInFleet({
    clientId: row.values["node.distributor._id"],
    itemFleetId: row.values["node._id"],
  });

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [assignItemsOpen, setAssignItemsOpen] = useState(false);
  const [totalItemsOpen, setTotalItemsOpen] = useState(false);
  const [reAssignFleetModalOpen, setReAssignFleetModalOpen] = useState(false)
  const [codeGenSettingsModalOpen, setcodeGenSettingsModalOpen] = useState(false)
  const [fleetId, setFleetId] = useState("");
  const {
    getItems: getFleets,
    items: fleetItems,
    refetchItems: refetchFleets,
    loading: loadingFleet,
    refetchDistributorItems: refetchDistributorFleet,
  } = useContext(FleetContext);
  const searchFleet = async (search: string) => {
    if (!refetchFleets) {
      return Promise.resolve(
        fleetItems?.map((it) => ({
          name: it?.node?.fleetName,
          value: it?.node?._id,
        }))
      );
    }
  }
  const formFieldsData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "select-async",
            name: "fleetId",
            label: "Fleet",
            options: fleetItems?.map((it) => ({
              _id: it?.node?._id || "",
              name: it?.node?.fleetName || "",
            })),
            onChange: (e: any) => {
              setFleetId(e);
            },
            value: fleetId || "",
            // @ts-ignore
            searchPromise: searchFleet as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [fleetItems, fleetId]
  );

  const [UpdateItemFleetCodeGen, { loading: updateItemFleetCodeGenLoading }] = useMutation<UpdateItemFleetCodeGen, UpdateItemFleetCodeGenVariables>(
    UpdateItemFleetCodeGenMutation, {
    client: clientSimulator,
    onCompleted: () => {
      notify({
        status: "success",
        text: "Fleet Settings Updated Successfully",
      });
      refetchDistributorFleet && refetchDistributorFleet()
      setcodeGenSettingsModalOpen(false);
    },
    onError: (err) => {
      handleGQLErrors(notify, err)
    }
  });

  const [reassignBatchItems, { loading: reassingLoading }] = useMutation<ReassignFleetItemsFromItemFleet, ReassignFleetItemsFromItemFleetVariables>(
    reassignFleetItemsFromItemFleet, {
    client: clientSimulator,
    onCompleted: () => {
      notify({
        status: "success",
        text: "Items in fleet Re-Assigned successfully",
      });
      setFleetId("")
      setReAssignFleetModalOpen(false);
    },
    onError: (err) => {
      handleGQLErrors(notify, err)
    }
  });
  const [deleteItem] = useMutation<DeleteItemFleet, DeleteItemFleetVariables>(
    deleteItemFleetMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: "success",
          text: "Deleted successfully",
        });
        refetch && refetch();
        setIsModalOpen(false);
      },
    }
  );

  const handleReAssignFleet = () => {
    reassignBatchItems({ variables: { oldItemFleetId: row.values["node._id"], newItemFleetId: fleetId } })
  }

  const handleSetCodeGenSettings = (values: UpdateItemFleetCodeGenInput) => {
    UpdateItemFleetCodeGen({
      variables: {
        updateItemFleetCodeGenInput: {
          itemFleetId: row.values["node._id"],
          maxCreditStackCodeEvents: row.values["node.maxCreditStackCodeEvents"],
          maxCreditStackDays: row.values["node.maxCreditStackDays"],
          resetCodeCount: values?.resetCodeCount,
          freeCodeCount: values?.freeCodeCount
        }
      }
    })
  }
  return (
    <div className="actions-right" style={{ display: "flex" }}>
      <>
        <div className={`${classes.iconWrapper} ${isDistributor ? classes.iconWrapperHover : ''}`}>
          <Tooltip
            title="Delete"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              id="expander"
              aria-label="Delete"
              className={classes.tableActionButton}
              onClick={() => setIsModalOpen(true)}
              style={isDistributor ? { opacity: 0.3, pointerEvents: "none" } : {}}
            >
              <Delete
                className={classes.tableActionButtonIcon + " " + classes.delete}
              />
            </IconButton>
          </Tooltip>
          {isDistributor && (
            <span className={classes.stopSign} role="img" aria-label="Stop">
              🚫
            </span>
          )}
        </div>
        <div className={`${classes.iconWrapper} ${isDistributor ? classes.iconWrapperHover : ''}`}>
          <Tooltip
            id="expander"
            onClick={() => {
              history(itemRoutes.getEditLink(row.values["node._id"]));
            }}
            title="Edit"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              id="expander"
              aria-label="Edit"
              className={classes.tableActionButton}
              style={isDistributor ? { opacity: 0.3, pointerEvents: "none" } : {}}
            >
              <Edit
                className={classes.tableActionButtonIcon + " " + classes.edit}
              />
            </IconButton>
          </Tooltip>
          {isDistributor && (
            <span className={classes.stopSign} role="img" aria-label="Stop">
              🚫
            </span>
          )}
        </div>

      </>
      {isDistributor ?
        <HoverDropdown
          menus={[
            {
              name: "Code Gen Settings",
              action: () => {
                setcodeGenSettingsModalOpen(true);
              },
            },
          ]}
        /> :
        <HoverDropdown
          menus={[
            {
              name: "Assign items",
              action: () => {
                setAssignItemsOpen(true);
              },
            },

            {
              name: "Re assign items",
              action: () => {
                setReAssignFleetModalOpen(true);
                getFleets()
              },
            },
            {
              name: "Total Items",
              action: () => {
                setTotalItemsOpen(true);
                getItems();
              },
            },

          ]}
        />
      }



      {assignItemsOpen ? (
        <DrawerSidebar
          isModalOpen={assignItemsOpen}
          toggleModal={setAssignItemsOpen}
          styles={{ minWidth: "400px" }}
          title={`Assign Items to ${row.values["node.fleetName"]}`}
        >
          <GridContainer>
            <Formik
              onSubmit={async (values) => { }}
              initialValues={{
                oemItemIds: [
                  {
                    oemItemId: "",
                    itemId: "",
                  },
                  {
                    oemItemId: "",
                    itemId: "",
                  },
                  {
                    oemItemId: "",
                    itemId: "",
                  },
                  {
                    oemItemId: "",
                    itemId: "",
                  },
                  {
                    oemItemId: "",
                    itemId: "",
                  },
                ],
              }}
            >
              {(formBag) => (
                <>
                  <GridContainer>
                    <GridItem>
                      <div>Add OemItemIDs</div>
                    </GridItem>
                    <GridItem>
                      <div
                        onClick={async () => {
                          const text = await navigator.clipboard.readText();
                          let ids = [] as {
                            oemItemId: string;
                            itemId: string;
                          }[];
                          text?.split("\n").map((item) => {
                            ids.push({
                              oemItemId: item
                                ?.replaceAll(" ", "")
                                ?.replace(/(\r\n|\n|\r)/gm, "")
                                ?.replace("\t", ""),
                              itemId: "",
                            });
                            return item;
                          });
                          formBag.setFieldValue("oemItemIds", ids);
                        }}
                      >
                        <FileCopyIcon color={"primary"} fontSize={"small"} />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <AssignItems
                    formBag={formBag}
                    setAssignItemsOpen={setAssignItemsOpen}
                    itemFleetId={row.values["node._id"]}
                  />
                </>
              )}
            </Formik>
          </GridContainer>
        </DrawerSidebar>
      ) : null}
      {reAssignFleetModalOpen ? (
        <DrawerSidebar
          isModalOpen={reAssignFleetModalOpen}
          toggleModal={setReAssignFleetModalOpen}
          title={`Re Assign Items in Fleet`}
          styles={{ maxWidth: "30vw", minWidth: "400px" }}
        >
          <GridContainer>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <OVSForm formFieldsData={formFieldsData} />
                  <GridItem sm={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleReAssignFleet();
                      }}
                      style={{ marginLeft: 12 }}
                      disabled={reassingLoading || loadingFleet}
                    >
                      {reassingLoading ? "Loading..." : "Save"}
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
          </GridContainer>
        </DrawerSidebar>
      ) : null}
      {codeGenSettingsModalOpen ? (
        <DrawerSidebar
          isModalOpen={codeGenSettingsModalOpen}
          toggleModal={setcodeGenSettingsModalOpen}
          title={`Code Generation Settings`}
          styles={{ maxWidth: "30vw", minWidth: "400px" }}
        >
          <GridContainer>
            <Formik onSubmit={(values: any) => { handleSetCodeGenSettings(values) }} initialValues={{
              freeCodeCount: row.values["node.freeCodeCount"],
              resetCodeCount: row.values["node.resetCodeCount"],
            }}>
              {(formBag) => (
                <>
                  <GridItem sm={12}>
                    <TextInput
                      label='Reset Code Count'
                      name={itemFields.resetCodeCount}
                      type="number"
                    />
                  </GridItem>

                  <GridItem sm={12}>
                    <TextInput
                      label={`Free Code Count ${row.values["node.disableFreeCode"] ? "(disabled)" : ""}`}
                      name={itemFields.freeCodeCount}
                      type="number"
                      disabled={row.values["node.disableFreeCode"]}
                    />
                  </GridItem>
                  <GridItem sm={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        formBag.submitForm()
                      }}
                      disabled={updateItemFleetCodeGenLoading}
                    >
                      {updateItemFleetCodeGenLoading ? "Loading..." : "Edit Settings"}
                    </Button>
                  </GridItem>

                </>
              )}
            </Formik>
          </GridContainer>
        </DrawerSidebar>
      ) : null}
      {isModalOpen ? (
        <ActionModal
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          handleOnClickOkey={() =>
            deleteItem({
              variables: { itemFleetId: row.values["node._id"] },
            })
          }
          handleOnClickCancel={() => setIsModalOpen(false)}
          okText="Delete"
        >
          <div>Are you sure you want to delete this fleet?</div>
        </ActionModal>
      ) : null}
      {totalItemsOpen ? (
        <ActionModal
          isModalOpen={totalItemsOpen}
          toggleModal={setTotalItemsOpen}
          handleOnClickOkey={() => setIsModalOpen(false)}
          handleOnClickCancel={() => setIsModalOpen(false)}
          okText="Ok"
        >
          <div>
            <b>Total: </b>
            <span>
              {loading ? "loading..." : `${numberWithCommas(total || 0)}`}
            </span>
          </div>
        </ActionModal>
      ) : null}
    </div>
  );
};

export default TableActions;
