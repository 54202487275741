import { OrgTypes } from 'apps/clientService/app/types/clientServiceGlobalTypes';

export const itemFields = {
  contactRole: 'contactRole',
  city: 'distributorAddress.city',
  country: 'distributorAddress.country',
  postcode: 'distributorAddress.postcode',
  srpc: 'distributorAddress.srpc',
  street: 'distributorAddress.street',
  unit: 'distributorAddress.unit',
  inputAddressLongitude:
    'distributorAddress.locationAddressDto.inputAddressLongitude',
  inputAddressLatitude:
    'distributorAddress.locationAddressDto.inputAddressLatitude',
  distributorDescription: 'distributorDescription',
  distributorName: 'distributorName',
  distributorType: 'distributorType',
  email: 'email',
  roleId: 'roleId',
};

export const defaultAddress = {
  city: '',
  country: '',
  locationAddressDto: {
    inputAddressLatitude: 0,
    inputAddressLongitude: 0,
  },
  postcode: '',
  srpc: '',
  street: '',
  unit: '',
};

export const defaultItem = {
  contactRole: '',
  distributorAddress: defaultAddress,
  distributorDescription: 'Standard PAYG Distribution Established in 2021',
  distributorName: 'OVES Distributor',
  distributorType: OrgTypes.DISTRIBUTOR,
  email: '',
  roleId: '',
};

export const itemRoutes = {
  list: '/admin/users',
  add: '/admin/users/add',
  edit: '/admin/users/:itemId',
  getEditLink: (itemId: string) => `/admin/users/${itemId}`,
};
