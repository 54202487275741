import { useMutation } from '@apollo/client';
import CardGridLoader from '@components/Loaders/CardGridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { UpdateItemInput } from 'apps/things/app/types/thingsGlobalTypes';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import EditItem from '../components/EditItem';
import { defaultQueryVariables, itemsRoutes } from '../constants';
import { updateItemMutation } from '../mutations';
import { useGetAllItemsQuery, useGetSpecificItemQuery } from '../queries';
import { GetAllItemsResponse_page_edges_node } from '../types/GetAllItemsResponse';
import { GetSpecificItem_getSpecificItem } from '../types/GetSpecificItem';
import { UpdateItem, UpdateItemVariables } from '../types/UpdateItem';

const EditItemContainer = () => {
  const notify = useNotifier();
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const [getItem, { data, loading }] = useGetSpecificItemQuery({
    itemId,
  });
  const { refetch } = useGetAllItemsQuery(defaultQueryVariables);
  const [item, setItem] = React.useState(
    {} as GetAllItemsResponse_page_edges_node
  );

  useEffect(() => {
    getItem({ variables: { itemId } });
    // eslint-disable-next-line
  }, [itemId]);

  React.useEffect(() => {
    setItem(data?.getSpecificItem || ({} as GetSpecificItem_getSpecificItem));
    // eslint-disable-next-line
  }, [data]);

  const [updateItem, updateItemOpts] = useMutation<
    UpdateItem,
    UpdateItemVariables
  >(updateItemMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: 'success',
        text: 'Item updated successfully',
      });
      refetch();
      history(itemsRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleUpdate = (updateItemInput: UpdateItemInput) => {
    updateItem({
      variables: { updateItemInput },
    });
  };

  if (loading || !item._id) return <CardGridLoader />;

  return (
    <EditItem
      handleUpdate={handleUpdate}
      isLoading={updateItemOpts.loading}
      item={item}
    />
  );
};

export default EditItemContainer;
