import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import TextInput from '@components/CustomInput/TextInput';
import { paymentPlanFields } from '../../assetAccount/constants';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { PaymentPlanInput } from '../../assetAccount/constants';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleColumnCheckbox from '@components/ReactTable/ToggleColumnCheckbox';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    formControl: {marginTop: "1.7rem", marginLeft: "-2rem"},

    label: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "14px",
        marginTop: "0.5rem"
      },
})
const PaymentPlanForm: React.FC<{
    formBag: FormikProps<PaymentPlanInput>;
    disableLoginButton: boolean;
    actionButtonName?: string;
    inActive?: boolean;
}> = (props) => {
    const { formBag, disableLoginButton, actionButtonName = "" } = props;
    const classes = useStyles()
    return (
        <GridContainer>

            <GridItem xs={12} sm={12} md={12}>
                <GoBackHeader title={actionButtonName} />
                <Form>
                    <GridContainer>
                    <GridHalfWrapper>
                            <TextInput
                                label='Plan Name'
                                name={paymentPlanFields.planName}
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Plan Description'
                                name={paymentPlanFields.planDescription}
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Upfront Price'
                                name={paymentPlanFields.upFrontPrice}
                                type="number"
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Upfront Included Days'
                                name={paymentPlanFields.uFrontDaysIncluded}
                                type="number"
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Free Code Price'
                                name={paymentPlanFields.freecodePrice}
                                type="number"
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Hour Price'
                                name={paymentPlanFields.hourPrice}
                                type="number"
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Days to Cut Off'
                                name={paymentPlanFields.daysToCutOff}
                                type="number"
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Expected Paid'
                                name={paymentPlanFields.expectedPaid}
                                type="number"
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Minimum Payment Amount'
                                name={paymentPlanFields.minimumPaymentAmount}
                                type="number"
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                        <FormControlLabel 
                        className={classes.formControl} 
                        control={<ToggleColumnCheckbox name={paymentPlanFields.useUpfront}  />} 
                        label={<Typography className={classes.label}><p>Use Upfront</p></Typography>}/>
                        </GridHalfWrapper>
                    </GridContainer>

                    <Grid>
                        <Button
                            color='primary'
                            onClick={() => {
                                formBag.submitForm();
                            }}
                            disabled={disableLoginButton}
                        >
                            Save
                        </Button>
                    </Grid>
                </Form>
            </GridItem>
        </GridContainer>
    );
};

export default PaymentPlanForm;
