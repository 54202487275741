import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import clientClientService from '../utils/clientClientService';
import {
  GetAllSuppliers,
  GetAllSuppliersVariables,
} from './types/GetAllSuppliers';
import {
  GetSpecificSupplier,
  GetSpecificSupplierVariables,
} from './types/GetSpecificSupplier';
/**
 * type Supplier {
_id: ID!
activeSubRolePermission: PermissionInput!
authenticationInstance: AuthenticationInstance!
authenticationSubInstance: AuthenticationSubInstance!
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
description: String
itemSKU: [ItemSKU!]!
name: String!
orgAddress: Address!
orgContactPerson: Person
role: Roles!
subRolePermissions: SubRolePermissions!
type: OrgTypes!
updatedAt: DateTime
}
 */
const supplierFragment = gql`
  fragment Supplier on Supplier {
    _id
    createdAt
    deleteAt
    deleteStatus
    description

    name
    orgAddress {
      city
      country
      addressLocation {
        addressLatitude
        addressLongitude
      }
      postcode
      srpc
      street
      unit
    }

    orgContactPerson {
      _id
      createdAt
      deleteAt
      deleteStatus
      description
      name
    }
    type
    updatedAt
  }
`;
/**
 * type SupplierEdge {
cursor: String
node: Supplier
}
 */
const supplierEdgeFragment = gql`
  ${supplierFragment}
  fragment SupplierEdge on SupplierEdge {
    cursor
    node {
      ...Supplier
    }
  }
`;
/**
 * type SupplierConnection {
edges: [SupplierEdge!]
pageInfo: SupplierPageInfo
}
 */
const supplierConnectionFragment = gql`
  ${supplierEdgeFragment}
  fragment SupplierConnection on SupplierConnection {
    edges {
      ...SupplierEdge
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
`;
/**
 * type GetAllSuppliersResponse {
page: SupplierConnection!
pageData: PageData
}
 */

const getAllSuppliersResponseFragment = gql`
  ${supplierConnectionFragment}
  fragment GetAllSuppliersResponse on GetAllSuppliersResponse {
    page {
      ...SupplierConnection
    }
    pageData {
      count
      limit
      offset
    }
  }
`;
/**
 * getAllSuppliers(
after: String
before: String
first: Int
last: Int
): GetAllSuppliersResponse!
 */
const getAllSuppliersQuery = gql`
  ${getAllSuppliersResponseFragment}
  query GetAllSuppliers(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllSuppliers(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...GetAllSuppliersResponse
    }
  }
`;

/**
 * 
 *Query.getSpecificSupplier(
id: ID!
): Supplier
 */
const getSpecificSupplierQuery = gql`
  ${supplierFragment}
  query GetSpecificSupplier($id: ID!) {
    getSpecificSupplier(id: $id) {
      ...Supplier
    }
  }
`;
export const useGetSpecificSupplierQuery = (
  variables: GetSpecificSupplierVariables
) =>
  useQuery<GetSpecificSupplier, GetSpecificSupplierVariables>(
    getSpecificSupplierQuery,
    {
      variables,
      client: clientClientService,
    }
  );
export const useGetAllSuppliersQuery = (variables: GetAllSuppliersVariables) =>
  useLazyQuery<GetAllSuppliers, GetAllSuppliersVariables>(
    getAllSuppliersQuery,
    {
      client: clientClientService,
      variables,
    }
  );
