import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import { itemRoutes } from "./constants";
import Add from "./containers/AddItemContainer";
import Edit from "./containers/EditItemContainer";
import List from "./containers/Item";

interface IProps {}
export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: itemRoutes.add, element: <Add /> },
    { path: itemRoutes.edit, element: <Edit /> },
  ],
};
const AssetAccountIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default AssetAccountIndex;
