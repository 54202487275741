import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import { GetAllBrokers, GetAllBrokersVariables } from './types/GetAllBrokers';
import {
  GetAllThingBrokers,
  GetAllThingBrokersVariables,
} from './types/GetAllThingBrokers';
import {
  GetSpecificBroker,
  GetSpecificBrokerVariables,
} from './types/GetSpecificBroker';
import {
  GetSpecificThingBroker,
  GetSpecificThingBrokerVariables,
} from './types/GetSpecificThingBroker';

/**
 * type Broker {
_id: ID!
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
login: String
name: String!
password: String
port: String
updatedAt: DateTime
url: String!
}
 */
export const brokerFragment = gql`
  fragment Broker on Broker {
    _id
    createdAt
    deleteAt
    deleteStatus
    login
    name
    password
    port
    updatedAt
    url
  }
`;
/**
 * type BrokerEdge {
cursor: String
node: Broker
}
 */
const brokerEdgeFragment = gql`
  ${brokerFragment}
  fragment BrokerEdge on BrokerEdge {
    cursor
    node {
      ...Broker
    }
  }
`;
/**
 * type BrokerPageInfo {
endCursor: String
hasNextPage: Boolean!
hasPreviousPage: Boolean!
startCursor: String
}
 */
const brokerPageInfoFragment = gql`
  fragment BrokerPageInfo on BrokerPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type BrokerConnection {
edges: [BrokerEdge!]
pageInfo: BrokerPageInfo
}
 */
const brokerConnectionFragment = gql`
  ${brokerPageInfoFragment}
  ${brokerEdgeFragment}
  fragment BrokerConnection on BrokerConnection {
    edges {
      ...BrokerEdge
    }
    pageInfo {
      ...BrokerPageInfo
    }
  }
`;
/**
 * type PageData {
count: Float!
limit: Float!
offset: Float!
}
 */
const pageDataFragment = gql`
  ${brokerConnectionFragment}
  fragment PageData on PageData {
    count
    limit
    offset
  }
`;
/**
 * type GetAllBrokersResponse {
page: BrokerConnection!
pageData: PageData
}
 */
const getAllBrokersResponseFragment = gql`
  ${pageDataFragment}
  fragment GetAllBrokersResponse on GetAllBrokersResponse {
    page {
      ...BrokerConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * getAllBrokers(
after: String
before: String
first: Int
last: Int
): GetAllBrokersResponse!
 */
const getAllBrokerQuery = gql`
  ${getAllBrokersResponseFragment}
  query GetAllBrokers(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllBrokers(after: $after, before: $before, first: $first, last: $last) {
      ...GetAllBrokersResponse
    }
  }
`;

/**
 *Query.getAllThingBrokers(
after: String
before: String
first: Int
last: Int
): GetAllBrokersResponse!
 */
const getAllThingBrokerQuery = gql`
  ${getAllBrokersResponseFragment}
  query GetAllThingBrokers(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllThingBrokers(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...GetAllBrokersResponse
    }
  }
`;

/**
 * getSpecificBroker(
id: ID!
): Broker
 */
const getSpecificBrokerQuery = gql`
  ${brokerFragment}
  query GetSpecificBroker($id: ID!) {
    getSpecificBroker(id: $id) {
      ...Broker
    }
  }
`;

/**
Query.getSpecificThingBroker(
id: String!
): Broker
 */
const getSpecificThingBrokerQuery = gql`
  ${brokerFragment}
  query GetSpecificThingBroker($id: String!) {
    getSpecificThingBroker(id: $id) {
      ...Broker
    }
  }
`;

export const useGetAllBrokerQuery = (variables: GetAllBrokersVariables) =>
  useQuery<GetAllBrokers, GetAllBrokersVariables>(getAllBrokerQuery, {
    client: clientAccount,
    variables,
  });

export const useLazyGetAllBrokerQuery = (variables: GetAllBrokersVariables) =>
  useLazyQuery<GetAllBrokers, GetAllBrokersVariables>(getAllBrokerQuery, {
    client: clientAccount,
    variables,
  });

// thing
export const useGetAllThingBrokerQuery = (
  variables: GetAllThingBrokersVariables
) =>
  useQuery<GetAllThingBrokers, GetAllThingBrokersVariables>(
    getAllThingBrokerQuery,
    {
      client: clientAccount,
      variables,
    }
  );

export const useLazyGetAllThingBrokerQuery = (
  variables: GetAllThingBrokersVariables
) =>
  useLazyQuery<GetAllThingBrokers, GetAllThingBrokersVariables>(
    getAllThingBrokerQuery,
    {
      client: clientAccount,
      variables,
    }
  );

export const useGetSpecificThingBrokerQuery = (
  variables: GetSpecificThingBrokerVariables
) =>
  useQuery<GetSpecificThingBroker, GetSpecificThingBrokerVariables>(
    getSpecificThingBrokerQuery,
    {
      client: clientAccount,
      variables,
    }
  );

// .thing

export const useGetSpecificBrokerQuery = (
  variables: GetSpecificBrokerVariables
) =>
  useQuery<GetSpecificBroker, GetSpecificBrokerVariables>(
    getSpecificBrokerQuery,
    {
      client: clientAccount,
      variables,
    }
  );
