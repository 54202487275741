import React from 'react';
import Card from './Card';
import CardBody from './CardBody';

// @ts-ignore

interface IProp {
  children: React.ReactNode;
  title?: string;
  className?: string;
}

const CardWrapper: React.FC<IProp> = ({ children }) => {
  return (
    <Card>     
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default CardWrapper;
