import DateCell from '@components/ReactTable/DateCell';
import { Row } from 'react-table';
import SkuTableActions from '../components/TableActions';


export const itemTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <SkuTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: 'firstName',
      accessor: 'node.firstName',
    },
    {
      Header: 'lastName',
      accessor: 'node.lastName',
    },
    {
      Header: 'email',
      accessor: 'node.email',
    },

    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
    { Header: 'ID', accessor: 'node._id' },
  ],
});
