import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';
import { itemFirmwareTableData } from '../constants/itemFirmwareTableData';
import { ItemFirmwareContext } from '../context/FirmwareProvider';
import CardWrapper from '@components/Card/CardWrapper';
import { itemFirmwareRoutes } from '../constants';
interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
}

const ItemFirmwarePage: React.FC<IRTable> = () => {
  const {
    itemFirmwares: items,
    tableActions,
    getItemFirmwares,
    loading,
    goTo,
    setSearchQuery,
    searchQuery,
    paging, setPageLimit
  } = useContext(ItemFirmwareContext);

  useEffect(() => {
    getItemFirmwares();
    // eslint-disable-next-line
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              itemFirmwareTableData()
                .header as Column[]
            }
            data={items}
            checkedIds={[]}
            tableActions={tableActions}
            isLoading={loading}
            addItemPageLink={itemFirmwareRoutes.add}
            paging={paging}
            goTo={goTo}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemFirmwarePage;
