import React, { useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/itemTableData';
import { useOpenTokenSimulatorState } from '../context/ItemProvider';
import CardWrapper from '@components/Card/CardWrapper';
import { useAuth } from 'admin/auth/context/AuthProvider';
import useServicerPermission from 'hooks/useServicerPermission';
 import clientSimulator from 'utils/clientSimulator';
 import { useMutation } from '@apollo/client';
 import { CreateSingleItem, CreateSingleItemVariables } from '../../item/types/CreateSingleItem';
 import { createSingleItemMutation } from '../../item/mutations';
interface IRTable {
}

const OpenTokenSimulatorComponent: React.FC<IRTable> = () => {
  const { isDistributor, isServicer, isSuperAdmin } = useAuth();
  const {
    items,
    tableActions,
    clientItems,
    getClientItems,
    loading,
    getItems,
    goTo,
    paging,
    setSearchQuery,
    reload,
    setReload,
    setPageLimit,
    clientError,
    searchQuery,
    refetchClientItems
  } = useOpenTokenSimulatorState();

  const [createSingleItem, createItemOpts] = useMutation<
  CreateSingleItem,
  CreateSingleItemVariables
>(createSingleItemMutation, {
  client: clientSimulator,
  onCompleted: (data:any) => {
    refetchClientItems && refetchClientItems();
  },
  onError: (err) => {
    // handleGQLErrors(notify, err);
  },
})
  useServicerPermission({ loading, clientError })

  useEffect(() => {
    if (isDistributor && getClientItems) {
      if (!loading) {
        getClientItems();
      }
    } else {
      if (!isDistributor && !getItems) {
        return
      }
      if (reload) {
        if (!isDistributor) getItems();
      } else if (items?.length === 0 || !loading) {
        if (!isDistributor) getItems();
      }
    }

    return () => { setReload(true) }

    // eslint-disable-next-line
  }, []);

  const columnsToRemove = [
    'hashRoot',
    'codeGenerator',
    'Profile',
    'actionScope',
    'skuID',
    'BatchID',
    'idType'
  ];
  
  const columns = itemTableData({}).header?.filter(item => {
    if ((isDistributor || isServicer) && columnsToRemove.includes(item.Header)) {
      return false;
    }
    return true;
  }).map((item: any) => {
    if (isSuperAdmin && item.Header === "AccountNumber") {
      return {
        Header: 'SellerItemId',
        accessor: item.accessor 
      };
    }
    return item; 
  });

  const handleCreateSimulatorItem = async () => {
    await createSingleItem({
      variables: {
        createItemInput: {
          idString: "",
          oemItemID: "TEST2407xxxxxx",
          oemID: "OVES",
          itembatchId: "669a7d3ea4df8dce99ec3c30",
          itemfirmwareId: "6177f7dd5f868905bb1f5e76",
          codegeneratorId: "",
          totalProducts: 1,
          itemFleetId: "669a7d8ba4df8dce99ec3c31"
        },
      },
    });
    
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              columns as Column[]
            }
            paging={paging}
            goTo={goTo}
            data={(isDistributor || isServicer) ? clientItems || [] : items || []}
            checkedIds={[]}
            tableActions={tableActions}
            isLoading={loading || createItemOpts.loading as boolean}
            addItemBtnText='Add Items'
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
            handleCreateSimulatorItem={handleCreateSimulatorItem}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
const OpenTokenSimulator = React.memo(OpenTokenSimulatorComponent);
export default OpenTokenSimulator;


