import { ApolloQueryResult, useMutation } from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { Row } from 'react-table';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import clientSimulator from 'utils/clientSimulator';
import { useLazyGetAllProductsQuery } from '../queries';
import { deleteEcommerceProductMutation } from '../mutations';
import {
  DeleteEcommerceProduct,
  DeleteEcommerceProductVariables,
} from '../types/DeleteEcommerceProduct';
import {
  GetAllProducts,
  GetAllProductsVariables,
  GetAllProducts_getAllEcommerceProducts_page_edges,
} from '../types/GetAllProducts';
import { useEffect } from 'react';

export interface ITableActions {
  action: (items: Row[]) => void;
  title: string;
  description: (arg: string) => string;
  showConfirmationModal: boolean;
}
export interface IProductContext {
  items: GetAllProducts_getAllEcommerceProducts_page_edges[];
  setItems: (
    items: GetAllProducts_getAllEcommerceProducts_page_edges[]
  ) => void;
  loading: boolean;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<GetAllProductsVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllProducts>>)
    | undefined;
  getItems: () => void;
}

export const ProductEcommerceContext = React.createContext<IProductContext>(
  {} as IProductContext
);

const ItemProvider: React.FC<IProps> = ({ children }) => {
  // state
  const [items, setItems] = React.useState<
    GetAllProducts_getAllEcommerceProducts_page_edges[]
  >([] as GetAllProducts_getAllEcommerceProducts_page_edges[]);

  // context
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  // hooks
  const notify = useNotifier();
  const [
    getItems,
    { data, refetch: refetchItems, loading },
  ] = useLazyGetAllProductsQuery({
    first: 100,
  });

  useEffect(() => {
    if (data) setItems(data?.getAllEcommerceProducts?.page?.edges || []);
  }, [data]);

  const [deleteItemSKU] = useMutation<
    DeleteEcommerceProduct,
    DeleteEcommerceProductVariables
  >(deleteEcommerceProductMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {},
  });

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItemSKU({
          variables: {
            productId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Items deleted successfully',
        });
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllEcommerceProducts?.page?.edges || items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllEcommerceProducts?.page?.edges || items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      refetchItems,
      getItems,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} item(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading]
  );

  return (
    <ProductEcommerceContext.Provider value={value}>
      {children}
    </ProductEcommerceContext.Provider>
  );
};

export default ItemProvider;
