import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  QueryLazyOptions,
  useMutation
} from "@apollo/client";
import clientSimulator from "utils/clientSimulator";
import { IProps } from "@components/messages/types";
import React, { useEffect, useState, useMemo } from "react";
import { ITableActions } from "apps/things/app/item/context/ItemProvider";
import {
  GetAllAssetAccounts,
  GetAllAssetAccountsVariables,
  GetAllAssetAccounts_getAllAssetAccounts_page_edges,
} from "../types/GetAllAssetAccounts";
import {
  useLazygetAllAssetAccountsForClientQuery,
  useLazyGetAllAssetAccountsQuery,
  useLazyGetSpecificAssetAccountQuery,
} from "../queries";
import { useAuth } from "admin/auth/context/AuthProvider";
import {
  GetSpecificAssetAccount,
  GetSpecificAssetAccountVariables,
  GetSpecificAssetAccount_getSpecificAssetAccount,
} from "../types/GetSpecificAssetAccount";
import { getAccountStageValue } from "../constants";
import useOVSPagination from "hooks/useOVSPagination";
import { useDebouncedEffect } from "utils/useDebouncedEffect";
import { funNumberAgr } from "@types";
import {
  GetAllAssetAccountsForClient,
  GetAllAssetAccountsForClientVariables,
} from "../types/GetAllAssetAccountsForClient";
import GridContainer from '@components/Grid/GridContainer';
import { Formik } from 'formik';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import GridItem from '@components/Grid/GridItem';
import Button from '@components/CustomButtons/Button';
import { useContext } from "react";
import { GlobalSimulatorContext } from "apps/things/app/provider/SimulatorAppProvider";
import { CustomAgentContext } from "apps/clientService/app/agents/context/CustomAgentProvider";
// import { AssignItemToAgent, AssignItemToAgentVariables } from '../../agents/types/AssignItemToAgent';
import { 
  AssignAssetAccountToAgentByDistributorStaff, 
  AssignAssetAccountToAgentByDistributorStaffVariables } from "apps/clientService/app/agents/types/AssignAssetAccountToAgentByDistributorStaff";
import { assignAssetToAgentMutation } from "apps/clientService/app/agents/mutation"
import OVSForm from '@components/Form/OVSForm';
import { Row } from "react-table";
import useNotifier from "hooks/useNotifier";
export interface IAssetAccountContext {
  items: GetAllAssetAccounts_getAllAssetAccounts_page_edges[];
  setItems: (
    item: GetAllAssetAccounts_getAllAssetAccounts_page_edges[]
  ) => void;
  item: GetSpecificAssetAccount_getSpecificAssetAccount;
  _setItem: (item: GetSpecificAssetAccount_getSpecificAssetAccount) => void;
  loading: boolean;
  itemLoading: boolean;
  getItems: () => void;
  activeStage: number;
  setActiveStage: (stage: number) => void;
  tableActions: ITableActions[];
  refetchItems:
  | ((
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllAssetAccounts>>)
  | undefined;
  getItem: (
    options?: QueryLazyOptions<GetSpecificAssetAccountVariables> | undefined
  ) => void;
  refetchItem:
  | ((
    variables?: Partial<GetSpecificAssetAccountVariables> | undefined
  ) => Promise<ApolloQueryResult<GetSpecificAssetAccount>>)
  | undefined;
  refetchClientItems: (
    variables?: Partial<GetAllAssetAccountsForClientVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllAssetAccountsForClient>>;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr;
  clientError: ApolloError | undefined;
  resetItemContext: () => void;
}

export const AssetAccountContext = React.createContext<IAssetAccountContext>(
  {} as IAssetAccountContext
);

const AssetAccountProvider: React.FC<IProps> = ({ children }) => {
  const [activeStage, setActiveStage] = useState(0);
  const [item, _setItem] = React.useState(
    {} as GetSpecificAssetAccount_getSpecificAssetAccount
  );
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [items, setItems] = React.useState<
    GetAllAssetAccounts_getAllAssetAccounts_page_edges[]
  >([] as GetAllAssetAccounts_getAllAssetAccounts_page_edges[]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [assignToAgentModalOpen, setAssignToAgentModalOpen] = useState(false);
  const [agentId, setAgentId] = useState<any>();
  const [selectedItems, _setSelectedItems] = useState([] as Row[]);
  const { tableActionLoading, setTableActionLoading } = useContext(GlobalSimulatorContext);
  const setSelectedItemsForAgent = (itemsToAssign: Row[]) => {
    _setSelectedItems(itemsToAssign)
    setAssignToAgentModalOpen(true);
    getCustomAgents()
  }
  /// hooks
  const { initPagAction, setPaging, paging, pageListLimit, setPageListLimit } =
    useOVSPagination();
  const { loggedInUserId, isDistributor } = useAuth();
  const notify = useNotifier();
  const [
    getItems,
    {
      refetch: refetchItems,
      data,
      loading,
      fetchMore: fetchMoreAssetAccount,
      error: clientError,
    },
  ] = useLazyGetAllAssetAccountsQuery({
    first: pageListLimit,
  });

  const [
    getClientItems,
    {
      data: dataClientItems,
      loading: clientItemLoading,
      fetchMore: fetchMoreClientAccount,
      refetch: refetchClientItems,
    },
  ] = useLazygetAllAssetAccountsForClientQuery({
    first: pageListLimit,
    clientId: loggedInUserId,
  });

  useEffect(() => {
    if (isDistributor && dataClientItems) {
      setItems(
        dataClientItems?.getAllAssetAccountsForClient?.page?.edges || []
      );
      setPaging({
        ...dataClientItems?.getAllAssetAccountsForClient?.pageData,
        ...dataClientItems?.getAllAssetAccountsForClient?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [dataClientItems]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setItems(data?.getAllAssetAccounts?.page?.edges || []);
    setPaging({
      ...data?.getAllAssetAccounts?.pageData,
      ...data?.getAllAssetAccounts?.page?.pageInfo,
    });
    // eslint-disable-next-line
  }, [data]);
  const [
    getItem,
    { data: itemData, loading: itemLoading, refetch: refetchItem },
  ] = useLazyGetSpecificAssetAccountQuery({
    id: "",
  });

  useEffect(() => {
    if (itemData) {
      _setItem(itemData?.getSpecificAssetAccount);
      setActiveStage(
        getAccountStageValue(itemData?.getSpecificAssetAccount?.accountStage)
      );
    }
  }, [itemData]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllAssetAccountsVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };
    if (searchQuery) {
      variables["search"] = searchQuery;
    }

    if (fetchMoreClientAccount && isDistributor) {
      setIsLoadingMore(true);
      const _data = await fetchMoreClientAccount({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAssetAccountsForClient?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccountsForClient?.pageData,
        ..._data?.data?.getAllAssetAccountsForClient?.page?.pageInfo,
      });
    } else if (!isDistributor && fetchMoreAssetAccount) {
      setIsLoadingMore(true);
      const _data = await fetchMoreAssetAccount({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAssetAccounts?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccounts?.pageData,
        ..._data?.data?.getAllAssetAccounts?.page?.pageInfo,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit);
    setTimeout(() => {
      if (isDistributor) {
        refetchClientItems && refetchClientItems()
      }
      else {
        refetchItems && refetchItems();
      }
    }, 100);
  };

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    let variables: GetAllAssetAccountsVariables = {
      first: pageListLimit,
    };
    if (fetchMoreClientAccount && isDistributor) {
      if (searchQuery) {
        variables["search"] = searchQuery;
      }
      // variables['clientId'] = loggedInUserId

      setIsLoadingMore(true);
      const _data = await fetchMoreClientAccount({
        variables: {
          ...variables,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAssetAccountsForClient?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccountsForClient?.pageData,
        ..._data?.data?.getAllAssetAccountsForClient?.page?.pageInfo,
      });
    }

    if (fetchMoreAssetAccount && !isDistributor) {
      setIsLoadingMore(true);
      const _data = await fetchMoreAssetAccount({
        variables: searchQuery ? { search: searchQuery } : {},
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAssetAccounts?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccounts?.pageData,
        ..._data?.data?.getAllAssetAccounts?.page?.pageInfo,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);
  const resetItemContext = () => {
    _setItem({} as GetSpecificAssetAccount_getSpecificAssetAccount);
  };


  const {
    getItems: getCustomAgents,
    items: agentItems,
    refetchItems: refetchAgent
  } = useContext(CustomAgentContext);



  const [assignToAgent, { loading: agentLoading }] = useMutation<
  AssignAssetAccountToAgentByDistributorStaff, 
  AssignAssetAccountToAgentByDistributorStaffVariables
>(assignAssetToAgentMutation, {
  client: clientSimulator,
  onCompleted: async (data) => { 
    setAssignToAgentModalOpen(false)
    setAgentId("")
    setTableActionLoading(false)
    refetchClientItems && refetchClientItems()
    notify({
      text: "Asset Accounts Assigned to Agent Succesfully",
      status: "success"
    })
  },
  onError: async (err) => {
    notify({
      text: "There was a Problem assigning Asset Accounts",
      status: "error"
    })
  }
});
const assignItemsToAgent = (selectedItems: Row[]) => {
  if(!agentId) {
    notify({
      text: "Please select an Agent",
      title: "",
      status: "error",
    });
    return;
  }
  const assetAccountIds = selectedItems.map(item => item.values["node._id"]);

  setTableActionLoading(true)
  assignToAgent({
    variables: {
      assignAssetAccountToAgentByDistributorStaffInput: {
        agentId: agentId,
        assetAccountIds: assetAccountIds
      }
    }
  })
}

  const searchAgent = async (search: string) => {
    if (!refetchAgent) {
      return Promise.resolve(
        agentItems?.map((it) => ({
          name: it?.node?.contact.email,
          value: it?.node?._id,
        }))
      );
    }
    const refetchData = await refetchAgent({
      first: pageListLimit,
      search,
    });
  
    const items = refetchData?.data?.getAllAgentsForSpecificDistributorStaff?.page?.edges?.map(
      (item) => {
        return {
          label: item?.node?.contact.email || "",
          value: item.node?._id || "",
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  };

  const agentFieldsData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "select-async",
            name: "agentId",
            label: "Agent",
            options: agentItems?.map((it) => ({
              _id: it?.node?._id || "",
              name: it?.node?.contact.email || "",
            })),
            onChange: (e: any) => {
              setAgentId(e);
            },
            value: agentId || "",
            searchPromise: searchAgent as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [agentItems, agentId]
  );

  const value = React.useMemo(
    () => ({
      item,
      _setItem,
      items,
      setItems,
      loading: loading || clientItemLoading || isLoadingMore,
      getItems: isDistributor ? getClientItems : getItems,
      refetchItems,
      getItem,
      refetchItem,
      itemLoading,
      activeStage,
      setActiveStage,
      goTo,
      paging,
      searchQuery,
      setSearchQuery,
      setPageLimit,
      clientError,
      refetchClientItems,
      resetItemContext,
      tableActions: [
        {
          action: setSelectedItemsForAgent,
          title: "Assign Items to Agent",
          description: (arg: string) => "",
          showConfirmationModal: false,
        },
      ],
    }), // eslint-disable-next-line
    [
      items,
      loading,
      clientItemLoading,
      item,
      itemLoading,
      activeStage,
      paging,
      searchQuery,
      isLoadingMore,
      clientError,
      itemData,
    ]
  );

  return (
    <AssetAccountContext.Provider value={value}>
      <>
        {children}

        {assignToAgentModalOpen ? (
          <DrawerSidebar
            isModalOpen={assignToAgentModalOpen}
            toggleModal={setAssignToAgentModalOpen}
            title="Assign Selected Items to Agent"
            styles={{ maxWidth: "30vw", minWidth: "400px" }}
          >
            <GridContainer>
              <Formik onSubmit={(e) => { }} initialValues={{}}>
                {(formBag) => (
                  <>
                    <OVSForm formFieldsData={agentFieldsData} />
                    <GridItem sm={12}>
                      <Button
                        color="primary"
                        onClick={() => {
                          assignItemsToAgent(selectedItems);
                        }}
                        style={{ marginLeft: 12 }}
                        disabled={tableActionLoading}
                      >
                        {agentLoading ? "Loading..." : "Save"}
                      </Button>
                    </GridItem>
                  </>
                )}
              </Formik>
            </GridContainer>
          </DrawerSidebar>
        ) : null}
      </>
    </AssetAccountContext.Provider>
  );
};

export default AssetAccountProvider;
