import { gql } from '@apollo/client';
import { amazonProductCategoryFragment } from './queries';

/**
 * mutation {
  createAmazonProducCategory(
    createAmazonProductCategoryInput: {
      categoryName: ""
      categoryTree: "String"
      categoryDescription: ""
    }
  ) {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    categoryName
    categoryTree
    categoryDescription
  }
}
 */
export const createAmazonProducCategoryMutation = gql`
  mutation CreateAmazonProducCategory(
    $createAmazonProductCategoryInput: CreateAmazonProductCategoryInput!
  ) {
    createAmazonProducCategory(
      createAmazonProductCategoryInput: $createAmazonProductCategoryInput
    ) {
      _id
      deleteStatus
      deleteAt
      createdAt
      updatedAt
      categoryName
      categoryTree
      categoryDescription
    }
  }
`;

/**
 * deleteAmazonProducCategory(
amazonProductCategoryId: String!
): String!
 */
export const deleteAmazonProducCategoryMutation = gql`
  mutation DeleteAmazonProducCategory($amazonProductCategoryId: String!) {
    deleteAmazonProducCategory(
      amazonProductCategoryId: $amazonProductCategoryId
    )
  }
`;

/**
 * updateAmazonProducCategory(
updateAmazonProductCategoryInput: UpdateAmazonProductCategoryInput!
): AmazonProductCategory!
 */
export const updateAmazonProducCategoryMutation = gql`
  ${amazonProductCategoryFragment}
  mutation UpdateAmazonProducCategory(
    $updateAmazonProductCategoryInput: UpdateAmazonProductCategoryInput!
  ) {
    updateAmazonProducCategory(
      updateAmazonProductCategoryInput: $updateAmazonProductCategoryInput
    ) {
      ...AmazonProductCategory
    }
  }
`;
