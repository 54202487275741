import CardGridLoader from '@components/Loaders/CardGridLoader';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';
import { ClientServiceContext as Context } from '../context/ClientServiceProvider';
import { useUpdateClientExclusiveServiceMutation } from '../mutations';

import { useGetSpecificClientExclusiveServiceQuery } from '../queries';

const EditItemContainer = () => {
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(Context);

  const [item, setItem] = React.useState({} as typeof defaultItem);

  const { data, loading } = useGetSpecificClientExclusiveServiceQuery({ id: itemId });



  useEffect(() => {
    setItem({
      clientExclusiveServiceId: data?.getSpecificClientExclusiveService?._id || '',
      distributorId: data?.getSpecificClientExclusiveService?.distributor?.orgContactPerson?.contact?.email || '',
      servicerId: data?.getSpecificClientExclusiveService?.servicer?._id || '',
      serviceType: data?.getSpecificClientExclusiveService?.servicetype || defaultItem.serviceType
    }); // eslint-disable-next-line
  }, [data]);

  const { mutation, loading: updating } = useUpdateClientExclusiveServiceMutation({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const getDistributorID = (id: string) => {
    return data?.getSpecificClientExclusiveService?.distributor?.orgContactPerson?.contact?.email === id ? data?.getSpecificClientExclusiveService?.distributor?._id : id
  }

  const handleSubmit = (input: typeof defaultItem) => {
    mutation({
      variables: {
        updateClientExclusiveServiceInput: {
          clientExclusiveServiceId: input.clientExclusiveServiceId,
          distributorId: getDistributorID(input.distributorId),
          serviceType: input.serviceType,
          servicerId: input.servicerId
        }

      },
    });
  };

  if (!item?.clientExclusiveServiceId || loading) return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleSubmit}
      isLoading={loading || updating}
      item={item}
    />
  );
};

export default EditItemContainer;
