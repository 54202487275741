import React from 'react';
import { itemRoutes } from './constants';
import List from "./components/ItemPage";
import EditCodeGeneratorContainer from './containers/EditCodeGeneratorSettingsContainer';
import DefaultOutlet from 'routes/DefaultOutlet';

interface IProps { }
export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: itemRoutes.settings, element: <EditCodeGeneratorContainer /> },
  ],
};

const DistributorIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet />
  );
};

export default DistributorIndex;
