import React from 'react';
import './styles.scss';

interface IProps {}

const Loader: React.FC<IProps> = () => {
  return (
    <div className='lds-ellipsis' style={{textAlign: 'center'}}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
