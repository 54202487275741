import {
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext, useEffect } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useMutation } from '@apollo/client';
import { DeleteItemSKU, DeleteItemSKUVariables } from '../types/DeleteItemSKU';
import clientSimulator from 'utils/clientSimulator';
import { deleteItemSKUMutation } from '../mutations';
import useNotifier from 'hooks/useNotifier';
import { useLazyGetSpecificItemSKUQuery } from '../queries';
import { useNavigate } from 'react-router-dom';
import { defaultItemSKU, skuRoutes } from '../constants';
import { ItemSKUContext } from '../context/SKUProvider';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const SkuTableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();
  const { refetchItemSKU: refetch, setDefaultItem } = useContext(
    ItemSKUContext
  );

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [deleteItemSKU] = useMutation<DeleteItemSKU, DeleteItemSKUVariables>(
    deleteItemSKUMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        refetch && refetch();
        setIsModalOpen(false);
      },
    }
  );

  const [getItem, { data }] = useLazyGetSpecificItemSKUQuery({
    id: row.values['node._id'],
  });

  useEffect(() => {
    if (data) {
      setDefaultItem({
        skuName: data?.getSpecificItemSKU?.skuName + ' ( copy )' || '',
        oemDescription: data?.getSpecificItemSKU?.oemDescription || '',
        gatt: data?.getSpecificItemSKU?.properties || defaultItemSKU.gatt,
        productBase: data?.getSpecificItemSKU?.productBase || '',
      });
      history(skuRoutes.add);
    } // eslint-disable-next-line
  }, [data]);

  const handleDuplicate = async () => {
    getItem();
  };

  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id='expander'
        onClick={() => {
          history(skuRoutes.getEditLink(row.values['node._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>

      <HoverDropdown
        menus={[
          {
            name: 'Duplicate',
            action: () => {
              handleDuplicate();
            },
          },
        ]}
      />

      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deleteItemSKU({ variables: { itemSKUId: row.values['node._id'] } })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this item SKU?</div>
      </ActionModal>
    </div>
  );
};

export default SkuTableActions;
