import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

const BackDrop: React.FC<{ loading: boolean; children: React.ReactNode }> = ({
  loading,
  children,
}) => {
  const classes = useStyles();

  const handleClose = () => {};
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleClose}
      >
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress color='inherit' />
          {children}
        </div>
      </Backdrop>
    </div>
  );
};

export default BackDrop;
