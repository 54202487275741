import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { logEventMutation } from '../mutations';
import { LogEvent, LogEventVariables } from '../types/LogEvent';

const useLogEvent = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<LogEvent, LogEventVariables>(
    logEventMutation,
    {
      client: clientAccount,
      onCompleted: (data) => {
        if (!data?.logEvent?._id) {
          return notify({ text: 'Some error occured!', status: 'error' });
        }
        if (onComplete) {
          onComplete();
        }
        notify({ text: 'Event created successfully', status: 'success' });
      },
      onError: (err) => {
        handleGQLErrors(notify, err);
      },
    }
  );

  return {
    mutation,
    mutationOpts,
  };
};

export default useLogEvent;
