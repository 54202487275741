import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { logPublisherMutation } from '../mutations';
import { LogPublisher, LogPublisherVariables } from '../types/LogPublisher';

const useLogItem = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    LogPublisher,
    LogPublisherVariables
  >(logPublisherMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (!data?.logPublisher?._id) {
        return notify({ text: 'Some error occured!', status: 'error' });
      }
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Created successfully', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useLogItem;
