import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import React, { ChangeEvent, useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@components/CustomButtons/Button';

interface IProps {
	title: string
	onSubmit: (file: File) => void
	loading?: boolean
}

const UploadFileComponent: React.FC<IProps> = ({ title, loading, onSubmit }) => {
	const [file, setFile] = useState<File>()
	const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const input = event.target as HTMLInputElement;
		if (!input.files?.length) {
			return;
		}
		setFileName(input.files[0].name)


		setFile(input.files[0]);

	};

	const [fileName, setFileName] = useState('')
	const getId = () => {
		return title.replaceAll(' ', '-').toLowerCase()
	}
	return (
		<GridItem md={6}>
			<h5 style={{marginTop: 10}}>{title}</h5>
			<section className='upload-notice'>
				<GridContainer className="align-baseline ">

					<GridItem md={6}>

						<label
							htmlFor={getId()}
							style={{fontSize: 14}}
							className='py-2 px-3 upload-checkin-file MuiButtonBase-root MuiIconButton-root border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-indigo-500 hover:border-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-800 transition duration-150 ease-in-out'
						>

							<>
								<CloudUploadIcon color='inherit' />
								&nbsp; {fileName || 'Select file'}
							</>

						</label>

						<input onChange={onFileChange} type="file" className='hidden' id={getId()} />

					</GridItem>
					<GridItem className={'float-right'} sm={6}>
						<Button
							color='primary'
							onClick={() => {
								if (file) {
									onSubmit(file)
								}
							}}
							disabled={!file || loading}
						>
							Upload
						</Button>
					</GridItem>

				</GridContainer>
			</section>
		</GridItem>
	)
}

export default UploadFileComponent