import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { itemRoutes } from './constants';
import AddItemContainer from './containers/AddItemContainer';
import EditItemContainer from './containers/EditItemContainer';
import Item from './containers/Item';

interface IProps { }


export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <Item /> },
    { path: itemRoutes.add, element: <AddItemContainer /> },
    { path: itemRoutes.edit, element: <EditItemContainer /> },
  ],
}

const AvatarIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet />

  );
};

export default AvatarIndex;
