import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import MessageTemplateForm from './MessageTemplateForm';
import CardWrapper from '@components/Card/CardWrapper';
import { UpdateMessageTemplateInput } from '../../types/accountsGlobalTypes';

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: UpdateMessageTemplateInput) => void;
  isLoading: boolean;
  item: UpdateMessageTemplateInput;
}

const EditMessageTemplate: React.FC<IRTable> = ({ handleSubmit, isLoading, item }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
            <Formik
              onSubmit={async (values: any) => handleSubmit(values)}
              initialValues={item}
              validateOnChange={true}
            >
              {(formBag) => (
                <MessageTemplateForm
                  formBag={
                    (formBag as unknown) as FormikProps<UpdateMessageTemplateInput>
                  }
                  disableLoginButton={isLoading}
                />
              )}
            </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default EditMessageTemplate;
