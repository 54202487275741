import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { pageDataFragment } from '../common/queries';
import {
  GetAllCategories,
  GetAllCategoriesVariables,
} from './types/GetAllCategories';
import {
  GetSpecificCategory,
  GetSpecificCategoryVariables,
} from './types/GetSpecificCategory';
/**type Category {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
name: String!
thumb: String!
products: [Product!]
} */
export const categoryFragment = gql`
  fragment Category on Category {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    name
    thumb
    description
    slug
  }
`;

/**
 * type CategoryEdge {
cursor: String
node: Category
}
 */
const categoryEdgeFragment = gql`
  ${categoryFragment}
  fragment CategoryEdge on CategoryEdge {
    cursor
    node {
      ...Category
    }
  }
`;

/**
 * type CategoryPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const categoryPageInfoFragment = gql`
  fragment CategoryPageInfo on CategoryPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type CategoryConnection {
edges: [CategoryEdge!]
pageInfo: CategoryPageInfo
}
 */
const categoryConnectionFragment = gql`
  ${categoryEdgeFragment}
  ${categoryPageInfoFragment}
  fragment CategoryConnection on CategoryConnection {
    edges {
      ...CategoryEdge
    }
    pageInfo {
      ...CategoryPageInfo
    }
  }
`;

/**
 * type GetAllCategoriesResponse {
page: CategoryConnection!
pageData: PageData
}
 */
const getAllCategoriesResponseFragment = gql`
  ${categoryConnectionFragment}
  ${pageDataFragment}
  fragment GetAllCategoriesResponse on GetAllCategoriesResponse {
    page {
      ...CategoryConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * Query.getAllCategories(
before: String
after: String
first: Int
last: Int
): GetAllCategoriesResponse!
 */

const getAllCategoriesQuery = gql`
  ${getAllCategoriesResponseFragment}
  query GetAllCategories(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllCategories(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllCategoriesResponse
    }
  }
`;

/**
 * 
getSpecificCategory(
id: String!
): Category!
 */
const getSpecificCategoryQuery = gql`
  ${categoryFragment}
  query GetSpecificCategory($id: String!) {
    getSpecificCategory(id: $id) {
      ...Category
    }
  }
`;

export const useGetSpecificCategoryQuery = (id: string) =>
  useQuery<GetSpecificCategory, GetSpecificCategoryVariables>(
    getSpecificCategoryQuery,
    {
      variables: { id },
      client: clientEcommerce,
    }
  );

export const useGetAllCategoriesQuery = (
  variables: GetAllCategoriesVariables
) =>
  useQuery<GetAllCategories, GetAllCategoriesVariables>(getAllCategoriesQuery, {
    client: clientEcommerce,
    variables,
  });

export const useLazyGetAllCategoriesQuery = (
  variables: GetAllCategoriesVariables
) =>
  useLazyQuery<GetAllCategories, GetAllCategoriesVariables>(
    getAllCategoriesQuery,
    {
      client: clientEcommerce,
      variables,
    }
  );
