import { routes as assetAccRoutes } from "./assetAccount"
import {routes as assetAccounttempData} from "./angazaReview"
import {routes as payPlanTemplate} from "./payPlanTemplate"
import {routes as messageGroup} from "./messageGroup"
import {routes as messageTemplate} from "./mesageTemplate"
import {routes as DashboardReports} from "./dashboardReports"
import {routes as peopleViewRoutes} from "./peopleView"
import {routes as paymentRoutes} from "./payments"
export const accountsRoutes = [
 assetAccRoutes,
 assetAccounttempData,
 payPlanTemplate,
 messageGroup,
 messageTemplate,
 DashboardReports,
 peopleViewRoutes,
 paymentRoutes
]