import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import { paymentRoutes as itemRoutes } from "./constants";
import List from "./containers/PaymentsContainer";
interface IProps {}

export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
  ],
};

const ItemFirmwareIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default ItemFirmwareIndex;
