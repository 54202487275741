import React from 'react';
// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';

import { addLegacyBatchValidationSchema } from 'apps/things/app/validations';
import ItemForm from './LegacyForm';
import CardWrapper from '@components/Card/CardWrapper';
import { IProductBatch } from '../types';

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: IProductBatch, totalItems: number) => void;
  isLoading: boolean;
  initialValues: IProductBatch;
}

const LegacyAddItem: React.FC<IRTable> = ({
  handleSubmit,
  isLoading,
  initialValues,
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleSubmit(
                {
                  productBatchNumber: values.productBatchNumber,
                  productBatchDate: values.productBatchDate,
                  productBatchNotes: values.productBatchNotes,
                  productBatchState: values.productBatchState,
                },
                values.totalItems
              )
            }
            initialValues={initialValues}
            validationSchema={addLegacyBatchValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemForm
                formBag={(formBag as unknown) as FormikProps<IProductBatch>}
                disableLoginButton={isLoading}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default LegacyAddItem;
