import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { itemBatchRoutes as itemRoutes } from './constants';
import Add from './containers/AddItemBatchContainer';
import Edit from './containers/EditItemBatchContainer';
import List from './containers/ItemBatchContainer';

interface IProps { }
export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: itemRoutes.add, element: <Add /> },
    { path: itemRoutes.edit, element: <Edit /> },
  ],
}
const ItemBatchIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet />

  );
};

export default ItemBatchIndex;
