import { useMutation } from '@apollo/client';
import CardGridLoader from '@components/Loaders/CardGridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { handleGQLErrors } from 'utils/gqlErrors';
import { UpdateOrganizationInput } from '../../types/clientServiceGlobalTypes';
import clientClientService from '../../utils/clientClientService';
import AddItem from '../components/AddItem';
import { itemRoutes } from '../constants';
import { OrganizationContext } from '../context/OrganisationProvider';
import { updateOrganizationMutation } from '../mutations';
import { useGetSpecificOrganizationQuery } from '../queries';
import {
  UpdateOrganization,
  UpdateOrganizationVariables,
} from '../types/UpdateOrganization';

const EditItemSKUContainer = () => {
  const notify = useNotifier();
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { data, loading } = useGetSpecificOrganizationQuery({
    orgId: itemId,
  });
  const { refetchItems: refetch } = useContext(OrganizationContext);
  const [item, setItem] = React.useState({} as UpdateOrganizationInput);

  React.useEffect(() => {
    if (!data) return;
    const input = data?.getSpecificOrganization;
    const address = data?.getSpecificOrganization?.orgAddress;

    setItem({
      orgId: data?.getSpecificOrganization?._id || '',
      orgAddress: {
        // ...address,
        city: address?.city || '',
        country: address?.country || '',
        postcode: address?.postcode || '',
        srpc: address?.srpc || '',
        street: address?.street || '',
        unit: address?.unit || '',
        UpdateLocationAddressDto: {
          lat: address?.addressLocation?.addressLatitude || 0,
          lng: address?.addressLocation?.addressLongitude || 0,
        },
      },
      orgDescription: input?.description || '',
      orgName: input?.name,
      orgProfile: input?.orgProfile || '',
    }); // eslint-disable-next-line
  }, [data]);

  const [updateItem, updateItemOpts] = useMutation<
    UpdateOrganization,
    UpdateOrganizationVariables
  >(updateOrganizationMutation, {
    client: clientClientService,
    onCompleted: (data) => {
      notify({
        status: 'success',
        text: 'Updated successfully',
      });
      refetch && refetch();
      history(itemRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleUpdate = (updateOrganizationInput: UpdateOrganizationInput) => {
    updateItem({
      variables: { updateOrganizationInput },
    });
  };

  if (!data?.getSpecificOrganization?._id || !item.orgId)
    return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleUpdate}
      isLoading={updateItemOpts.loading || loading}
      item={item}
    />
  );
};

export default EditItemSKUContainer;
