import React, { useContext, useState } from 'react';
import { Row } from 'react-table';
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { useNavigate } from 'react-router-dom';
import { itemFirmwareRoutes } from '../constants';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import Delete from "@material-ui/icons/Delete";
import ActionModal from '@components/Modal/ActionModal';
import { useMutation } from '@apollo/client';
import { deleteMessageGroupMutation } from '../mutations';
import {DeleteMessageGroup, DeleteMessageGroupVariables} from "../types/DeleteMessageGroup";
import clientSimulator from 'utils/clientSimulator';
import useNotifier from 'hooks/useNotifier';
import { MessageGroupContext } from '../context/MessageGroupProvider';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const notify = useNotifier();
  const classes = useStyles();
  const history = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {refetchItems: refetch} = useContext(MessageGroupContext)
  const [deleteMessageGroup] = useMutation<DeleteMessageGroup, DeleteMessageGroupVariables>(
    deleteMessageGroupMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: "success",
          text: "Item deleted successfully",
        });
        refetch && refetch();
        setIsModalOpen(false);
      },
    }
  );
  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        id='expander'
        onClick={() => {
          history(itemFirmwareRoutes.getEditLink(row.values['node._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Delete"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id="expander"
          aria-label="Delete"
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + " " + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <HoverDropdown
        menus={[
          {
            name: "People View",
            action: () => {
              history(itemFirmwareRoutes.getCodeEventsLink(row.values["node._id"]));
            },
          },
        ]}
      />
      {isModalOpen ? (
        <ActionModal
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          handleOnClickOkey={() =>
            deleteMessageGroup({ variables: { id: row.values["node._id"] } })
          }
          handleOnClickCancel={() => setIsModalOpen(false)}
          okText="Delete"
        >
          <div>Are you sure you want to delete this Message Group?</div>
        </ActionModal>
      ) : null}
    </div>
  );
};

export default TableActions;
