import React from 'react';
import { Navigate, Route, RouteObject, Routes, useRoutes } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import AuthLayout from 'layouts/Auth';
import AdminLayout from 'layouts/Admin';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { thingRoutes } from 'apps/things/app/routes';
import {routes as supportRoutes } from 'apps/support'
import { accountsRoutes} from 'apps/accounts/app/routes'
import { clientServicRoutes } from 'apps/clientService/routes';
import { eventsRoutes } from 'apps/events/app/routes';
import { migrationRoutes } from 'apps/migration/app/routes';
import {routes as loginRoutes} from 'admin/loginUser'
import RolePage from 'admin/Role/components/RolePage';
import ErrorPage from '@components/ErrorBoundary/ErrorPage';
import CustomerIndex from 'apps/clientService/app/customer';
export const history = createBrowserHistory();

interface IProps { }

const AppRoutes: React.FC<IProps> = () => {
  const { isAuthenticated, isSuperAdmin, isDistributor, isServicer
  } = useAuth();
  const getHomeElement = () => {
    if (isSuperAdmin) {
      return '/admin/item'
    }
    if (isServicer) {
      return "/admin/client-exclusive-servicer";
    }
    if (isDistributor) {
      return '/admin/item/distributor-items'
    }
    return ''
  }
  let __routes: RouteObject[] = [
    {
      path: "/",
      element: <AdminLayout />,
      children: [
        { index: true, element: <Navigate replace to={getHomeElement()} /> },
        ...(thingRoutes as RouteObject[]),
        ...accountsRoutes,
        ...clientServicRoutes,
        ...eventsRoutes,
        ...migrationRoutes,
        supportRoutes,
        loginRoutes,
        { path: "/admin/roles", element: <RolePage /> },
        { path: "/admin/admin-customers", element: <CustomerIndex /> },
        { path: "/admin/error-page", element: <ErrorPage /> },
        { path: "*", element: <Navigate replace to={getHomeElement()} /> },
      ],
    },
  ];
  let element = useRoutes(__routes);
  if (isAuthenticated) {
    return (
 
      <>{element}</>
    );
  }
  return (
    <Routes>
      <Route path='/auth/*' element={<AuthLayout />} />
      <Route  path="/admin/error-page" element={<ErrorPage /> } />
      <Route path="*" element={<Navigate replace to="/auth/login-page" />} />
    </Routes>
  );
};

export default AppRoutes;
