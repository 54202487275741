import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect } from 'react';
import { Row } from 'react-table';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';

import {
  GetAllCategories,
  GetAllCategoriesVariables,
  GetAllCategories_getAllCategories_page_edges,
} from '../types/GetAllCategories';
import { useLazyGetAllCategoriesQuery } from '../queries';
import { useDeleteCategory } from '../mutations';

export interface ITableActions {
  action: (items: Row[]) => void;
  title: string;
  description: (arg: string) => string;
  showConfirmationModal: boolean;
}
export interface ICategoryContext {
  items: GetAllCategories_getAllCategories_page_edges[];
  setItems: (items: GetAllCategories_getAllCategories_page_edges[]) => void;
  loading: boolean;
  tableActions: ITableActions[];
  refetch:
    | ((
        variables?: Partial<GetAllCategoriesVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllCategories>>)
    | undefined;
  getItems: () => void;
}

export const CategoryContext = React.createContext<ICategoryContext>(
  {} as ICategoryContext
);

const CategoryProvider: React.FC<IProps> = ({ children }) => {
  // state
  const [items, setItems] = React.useState<
    GetAllCategories_getAllCategories_page_edges[]
  >([] as GetAllCategories_getAllCategories_page_edges[]);

  // context
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  // hooks
  const notify = useNotifier();
  const [getItems, { data, refetch, loading }] = useLazyGetAllCategoriesQuery({
    first: 100,
  });

  useEffect(() => {
    if (data) setItems(data?.getAllCategories?.page?.edges || []);
  }, [data]);

  const { deleteCategory } = useDeleteCategory(() => {});

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteCategory({
          variables: {
            categoryId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Items deleted successfully',
        });
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllCategories?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllCategories?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      refetch,
      getItems,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} categories(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading]
  );

  return (
    <CategoryContext.Provider value={value}>
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
