import { useMutation } from "@apollo/client";
import useNotifier from "hooks/useNotifier";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleGQLErrors } from "utils/gqlErrors";
import AddItem from "../components/AddItem";
import { defaultItem, itemRoutes } from "../constants";

  import { 
    DistributorStaffRegisterAgent,
    DistributorStaffRegisterAgentVariables
   } from "../types/DistributorStaffRegisterAgent";
import { distributorStaffRegisterAgentMutation } from "../mutation";
import { CustomAgentContext } from "../context/CustomAgentProvider";

import clientClientService from "../../utils/clientClientService";
import { DistributorStaffRegisterAgentInput } from "../../types/clientServiceGlobalTypes";
import { useAuth } from "admin/auth/context/AuthProvider";
import {  useLazyGetAllSubRolesQuery } from "admin/Role/queries";
const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const [subRoleId, setSubRoleId] = React.useState<any>("")
  const { refetchItems: refetch } = React.useContext(CustomAgentContext);
  const {loggedInUserId} = useAuth()

  const [getItems, { data }] = useLazyGetAllSubRolesQuery();
  // create mutations

  const [createItem, createItemOpts] = useMutation<
  DistributorStaffRegisterAgent,
  DistributorStaffRegisterAgentVariables
  >(distributorStaffRegisterAgentMutation, {
    client: clientClientService,
    onCompleted: async (data) => {
      if (!data) {
        return notify({
          status: "error",
          text: "Something went wrong!",
        });
      }
      notify({
        status: "success",
        text: "Created successfully.",
      });

      if (refetch) await refetch();
      history(itemRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  useEffect(() => {
    getItems()
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    const distributorStaff = data?.getAllSubRoles?.page?.edges?.find(edge => 
      edge?.node?.name === "GENERAL AGENT"
    );
  
    setSubRoleId(distributorStaff?.node?._id)
  },[data])
  const handleSubmit = (input: DistributorStaffRegisterAgentInput) => {
    
    createItem({
      variables: {
        distributorStaffRegisterAgentInput: {
          ...input,
          subRoleId: subRoleId,
          agentID: loggedInUserId,
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item = {defaultItem}
      isLoading = {createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
