import Button from "@components/CustomButtons/Button";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import GridContainer from "@components/Grid/GridContainer";
import GridItem from "@components/Grid/GridItem";
import { ButtonGroup } from "@material-ui/core";
import { PairAssetAccountInput } from "apps/accounts/app/types/accountsGlobalTypes";
import CodeEventContainer from "apps/things/app/codeEvent/containers/CodeEventContainer";
import AddCodeGeneratorContainer from "apps/things/app/codeGenerator/containers/AddCodeGeneratorContainer";
import { FormikProps } from "formik";
import React, { useCallback, useContext } from "react";
import ReactToPrint from "react-to-print";
import { AssetAccountContext } from "../../context/AssetAccountProvider";
import QRCode from "qrcode.react";
import { format2DP, titleCase } from "utils";
import useGetDistributorCodeEvents from "../../hooks/useGetAllCodeEventsByDistributorQueries";
import { useAuth } from "admin/auth/context/AuthProvider";
import AssetAccountPaymentPlan from "../PaymentPlanComponent";
import * as _ from "lodash";
interface IProps {
  formBag: FormikProps<PairAssetAccountInput>;
  disableLoginButton: boolean;
  codeGenModalOpen: boolean;
  setCodeGenModalOpen: (open: boolean) => void;
  hideGenerate?: boolean;
  active: boolean;
}



const CodeActions: React.FC<IProps> = ({
  disableLoginButton,
  setCodeGenModalOpen,
  codeGenModalOpen,
  hideGenerate,
  active,
}) => {
  const { item, refetchItem } = useContext(AssetAccountContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isPaymentplanModalOpen, setIsPaymentPlanModalOpen] = React.useState(false)
  const componentRef = React.useRef(null);
  const onBeforeGetContent = () => { };
  const { loggedInUserId, isDistributorStaff } = useAuth()
  const printTrigger = useCallback(() => {
    return (
      <ButtonGroup
        disableElevation
        orientation="vertical"
        color="primary"
        style={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <Button color="primary">Print Receipt</Button>
      </ButtonGroup>
    ); // eslint-disable-next-line
  }, []);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current; // eslint-disable-next-line
  }, [componentRef.current]);

  const { items, loading } = useGetDistributorCodeEvents({
    itemId: item?.asset?._id,
    distributorId: loggedInUserId,
  });

  const today = new Date();
  function capitalizeFirstLetter(str: String) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const thresholdWidth = 600;
  const drawerWidthDesktop = { minWidth: "80vw", maxWidth: "80vw" }
  const drawerWidthPaymentPlanDesktop = { minWidth: "30vw", maxWidth: "30vw" }
  const drawerWidthMobile = { minWidth: "100vw", maxWidth: "100vw" }
  
  return (
    <>
      <div
        ref={componentRef}
        className="printable"
        id="printable"
        style={{ margin: 10 }}
      >
        <div
          style={{
            borderBottomStyle: "dotted",
            borderWidth: 2,
            height: 20,
          }}
        ></div>
        <div>
          <span
            style={{
              fontSize: 12,
              textAlign: "center",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
              fontFamily: "'Times New Roman', serif",
              fontWeight: 900,
            }}
          >
            RECEIPT
          </span>
        </div>
        <div
          style={{
            borderTopStyle: "dotted",
            borderWidth: 2,
            height: 20,
            marginTop: 20,
          }}
        ></div>
        <div
          style={{ fontFamily: "'Times New Roman', serif", fontWeight: 600 }}
        >
          <div>
            <b> Issue Date: </b>{" "}
            <span style={{ float: "right" }}>{today.toLocaleString()}</span>
          </div>
          <div>
            <b> User Name: </b>{" "}
            <span style={{ float: "right" }}>
              {titleCase(item?.credit?.owner?.name || "")}
            </span>
          </div>
          <div>
            <b> Product ID: </b>{" "}
            <span style={{ float: "right" }}>{item?.asset?.oemItemID}</span>
          </div>
          <div>
            <b>Balance: </b>{" "}
            <span style={{ float: "right" }}>
              {format2DP(item?.credit?.balance || 0)} {item?.credit?.currency}
            </span>
          </div>
          <div>
            <b>{(item?.credit?.activities?.length &&
              capitalizeFirstLetter(item?.credit?.activities[item?.credit?.activities?.length - 1]
                .action)) || "Credit Amount"}{" "} Amount: </b>{" "}
            <span style={{ float: "right" }}>
              {(item?.credit?.activities?.length &&
                item?.credit?.activities[item?.credit?.activities?.length - 1]
                  .amount) ||
                0}{" "}
              {item?.credit?.currency}
            </span>
          </div>
          <div>
            <b> Credit Days Added: </b>{" "}
            <span style={{ float: "right" }}>
              {item?.asset?.codeGenerator &&
                item?.asset?.codeGenerator?.codeHistory?.length &&
                item?.asset?.codeGenerator?.codeHistory[0]?.codeDays}
              {_.get(items, "[0].codeDays", "")} Days
            </span>
          </div>
          <div>
            <b> Token: </b>{" "}
            <span style={{ float: "right" }}>
              {_.get(items, "[0].codeDecString", "")}
            </span>
          </div>
          {item?.asset?.codeGenerator?.codeHistory?.length && (
            <>
              <div>
                <b> </b>{" "}
                <span style={{}}>
                  <QRCode
                    value={
                      (item?.asset?.codeGenerator?.codeHistory?.length &&
                        item?.asset?.codeGenerator?.codeHistory[0]
                          ?.codeDecString) ||
                      ""
                    }
                  />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      {item?._id ? (
        <GridContainer>
          {loggedInUserId !== "662901b8082c0b1a656894d2" && !isDistributorStaff && (
            <GridItem sm={6}>
              <ButtonGroup
                disableElevation
                orientation="vertical"
                color="primary"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    setCodeGenModalOpen(true);
                  }}
                  disabled={disableLoginButton || !active}
                >
                  Generate Code
                </Button>
              </ButtonGroup>
            </GridItem>
          )}
          <GridItem sm={6}>
            <ButtonGroup
              disableElevation
              orientation="vertical"
              color="primary"
              style={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setIsModalOpen(true);
                }}
                disabled={disableLoginButton}
              >
                Code History
              </Button>
            </ButtonGroup>
          </GridItem>
          <GridItem sm={6}>
            {!active ? (
              <ButtonGroup
                disableElevation
                orientation="vertical"
                color="primary"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Button
                  color="primary"
                  disabled={disableLoginButton || !active}
                >
                  Print Receipt
                </Button>
              </ButtonGroup>
            ) : (
              <ReactToPrint
                content={reactToPrintContent}
                documentTitle="receipt"
                removeAfterPrint
                trigger={printTrigger}
                onBeforeGetContent={onBeforeGetContent}
              />
            )}
          </GridItem>
          <GridItem sm={6}>
            <ButtonGroup
              disableElevation
              orientation="vertical"
              color="primary"
              style={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setIsPaymentPlanModalOpen(true);
                }}
                disabled={disableLoginButton}
              >
                Payment Plan
              </Button>
            </ButtonGroup>
          </GridItem>
        </GridContainer>
      ) : null}
      <DrawerSidebar
        isModalOpen={codeGenModalOpen}
        toggleModal={setCodeGenModalOpen}
        title="Generate Code"
        styles={window.innerWidth <= thresholdWidth ? drawerWidthMobile : drawerWidthDesktop}
      >
        {codeGenModalOpen && item?.asset?._id && (
          <AddCodeGeneratorContainer
            generateDayCodeCallback={() => {
              if (refetchItem) {
                refetchItem();
              }
            }}
            itemID={item?.asset?._id || ""}
          />
        )}
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={isPaymentplanModalOpen}
        toggleModal={setIsPaymentPlanModalOpen}
        title="Payment Plan"
        styles={window.innerWidth <= thresholdWidth ? drawerWidthMobile : drawerWidthPaymentPlanDesktop}
      >
        <AssetAccountPaymentPlan assetAccountPaymentPlan={item?.paymentPlan} />
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        title="Code History"
        styles={window.innerWidth <= thresholdWidth ? drawerWidthMobile : drawerWidthDesktop}
      >
        {isModalOpen && (
          <CodeEventContainer itemId={item?.asset?._id} isLoading={loading} />
        )}
      </DrawerSidebar>
    </>
  );
};

export default CodeActions;
