import React, { useContext, useEffect, useState } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import CardWrapper from '@components/Card/CardWrapper';
import { itemRoutes } from '../constants';
import { EventContext } from '../context/EventProvider';
import { tableHeaderGen } from '@components/ReactTable/tableHeaderGen';
import TablePreview from '@components/Loaders/TablePreview';
import { itemTableData } from '../constants/itemTableData';

interface IRTable {}

const ItemPage: React.FC<IRTable> = () => {
  const { items, tableActions, getItems, loading, goTo, paging } = useContext(
    EventContext
  );

  const [headers, setHeaders] = useState<Column[]>([]);

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      setHeaders([...itemTableData, ...tableHeaderGen(items[0])]);
    }
  }, [items]);
  if (headers.length === 0) {
    return <TablePreview items={items} loading={loading} />;
  }

 
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={headers as Column[]}
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={itemRoutes.add}
            tableActions={tableActions}
            goTo={goTo}
            paging={paging}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemPage;
