import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';

export const useAnyMutation = (anyMutation: DocumentNode) => {
  const [mutation, mutationOps] = useMutation(anyMutation);
  return {
    mutation,
    loading: mutationOps.loading,
    error: mutationOps.error,
    called: mutationOps.called
  };
};
