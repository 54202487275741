export const legacyRoutes = { // todo enable edit and add
  list: '/admin/legacy/protocol-version',
  add: '',// '/admin/legacy/protocol-version/add',
  edit: '', // '/admin/legacy/protocol-version/:itemId',
  getEditLink: (itemId: string) => `` // `/admin/legacy/protocol-version/${itemId}`,
};


export const itemFields = {
  productModelID: 'productModelID',
  productModelName: 'productModelName',
  productFamilyID: 'productFamilyID',
  productVendor: 'productVendor',
  productModelDescription: 'productModelDescription',
  quantityInStock: 'quantityInStock',
  productModelCost: 'productModelCost',
  image: 'image',
};
