import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { categorySelectorFragment } from '../categorySelector/queries';
import { pageDataFragment } from '../common/queries';
import { productSelectorFragment } from '../productSelector/queries';
import {
  GetAllApplicationSite,
  GetAllApplicationSiteVariables,
} from './types/GetAllApplicationSite';
import {
  GetSpecificApplicationSite,
  GetSpecificApplicationSiteVariables,
} from './types/GetSpecificApplicationSite';

/**
 * type ApplicationSite {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
websiteUrl: String!
applicationBrandSelector: [BrandSelector!]!
applicationProductSelector: [ProductSelector!]!
applicationCategorySelector: [CategorySelector!]!
}
 */
export const applicationSiteFragment = gql`
  ${productSelectorFragment}
  ${categorySelectorFragment}
  fragment ApplicationSite on ApplicationSite {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    websiteUrl
    description
    applicationProductSelector {
      ...ProductSelector
    }
    applicationCategorySelector {
      ...CategorySelector
    }
  }
`;

/**
 * getSpecificApplicationSite(
id: String!
): ApplicationSite!
 */
const getSpecificApplicationSiteQuery = gql`
  ${applicationSiteFragment}
  query GetSpecificApplicationSite($id: String!) {
    getSpecificApplicationSite(id: $id) {
      ...ApplicationSite
    }
  }
`;
/**
 * type ApplicationSiteEdge {
cursor: String
node: ApplicationSite
}
 */
const applicationSiteEdgeFragment = gql`
  ${applicationSiteFragment}
  fragment ApplicationSiteEdge on ApplicationSiteEdge {
    cursor
    node {
      ...ApplicationSite
    }
  }
`;
/**
 * type ApplicationSitePageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const applicationSitePageInfoFragment = gql`
  fragment ApplicationSitePageInfo on ApplicationSitePageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type ApplicationSiteConnection {
edges: [ApplicationSiteEdge!]
pageInfo: ApplicationSitePageInfo
}
 */
const applicationSiteConnectionFragment = gql`
  ${applicationSiteEdgeFragment}
  ${applicationSitePageInfoFragment}
  fragment ApplicationSiteConnection on ApplicationSiteConnection {
    edges {
      ...ApplicationSiteEdge
    }
    pageInfo {
      ...ApplicationSitePageInfo
    }
  }
`;

/**
 * type GetAllApplicationSitesResponse {
page: ApplicationSiteConnection!
pageData: PageData
}
 */
const getAllApplicationSitesResponseFragment = gql`
  ${pageDataFragment}
  ${applicationSiteConnectionFragment}
  fragment GetAllApplicationSitesResponse on GetAllApplicationSitesResponse {
    page {
      ...ApplicationSiteConnection
    }
    pageData {
      ...PageData
    }
  }
`;

/**
 * getAllApplicationSite(
before: String
after: String
first: Int
last: Int
): GetAllApplicationSitesResponse!
 */
const getAllApplicationSiteQuery = gql`
  ${getAllApplicationSitesResponseFragment}
  query GetAllApplicationSite(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllApplicationSite(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllApplicationSitesResponse
    }
  }
`;

export const useLazyGetAllGetAllApplicationSiteQuery = (
  variables: GetAllApplicationSiteVariables
) =>
  useLazyQuery<GetAllApplicationSite, GetAllApplicationSiteVariables>(
    getAllApplicationSiteQuery,
    {
      client: clientEcommerce,
      variables,
    }
  );

export const useGetAllApplicationSiteQuery = (
  variables: GetAllApplicationSiteVariables
) =>
  useQuery<GetAllApplicationSite, GetAllApplicationSiteVariables>(
    getAllApplicationSiteQuery,
    {
      client: clientEcommerce,
      variables,
    }
  );

export const useGetSpecificApplicationSiteQuery = (id: string) =>
  useQuery<GetSpecificApplicationSite, GetSpecificApplicationSiteVariables>(
    getSpecificApplicationSiteQuery,
    {
      variables: { id },
      client: clientEcommerce,
    }
  );

export const useLazyGetSpecificApplicationSiteQuery = (id: string) =>
  useLazyQuery<GetSpecificApplicationSite, GetSpecificApplicationSiteVariables>(
    getSpecificApplicationSiteQuery,
    {
      variables: { id },
      client: clientEcommerce,
    }
  );
