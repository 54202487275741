import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { deleteRolePermissionMutation } from '../mutations';
import {
  DeleteRolePermission,
  DeleteRolePermissionVariables,
} from '../types/DeleteRolePermission';

const useDeleteRolePerm = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    DeleteRolePermission,
    DeleteRolePermissionVariables
  >(deleteRolePermissionMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      } else {
        notify({ text: 'Success', status: 'success' });
      }
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useDeleteRolePerm;
