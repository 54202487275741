import Button from '@components/CustomButtons/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js';
// @ts-ignore noqa
const useStyle = makeStyles(styles);

interface IProps {
  isModalOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  okText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  handleOnClickOkey?: () => void;
  handleOnClickCancel?: () => void;
  loading?: boolean;
  modalSmall?: boolean;
  styles?: any;
}

const DefaultModalContent = () => <h5>Are you sure you want to do this?</h5>;

const ActionModal: React.FC<IProps> = ({
  isModalOpen,
  toggleModal,
  okText = 'Ok',
  cancelText = 'Cancel',
  children,
  handleOnClickCancel,
  handleOnClickOkey,
  loading = false,
  modalSmall = false,
  styles = {},
}) => {
  const classes = useStyle();
  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal + ' ' + modalSmall ? classes.modalSmall : '',
        }}
        open={isModalOpen}
        keepMounted
        onClose={() => toggleModal(false)}
        aria-labelledby='small-modal-slide-title'
        aria-describedby='small-modal-slide-description'
        style={styles}
      >
        <DialogTitle
          id='small-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={() => toggleModal(!isModalOpen)}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id='small-modal-slide-description'
          className={classes.modalBody + ' ' + classes.modalSmallBody}
        >
          {!children && <DefaultModalContent />}
          {children}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + ' ' + classes.modalFooterCenter}
        >
          {handleOnClickCancel ? (<Button
            onClick={handleOnClickCancel}
            color='transparent'
            className={classes.modalSmallFooterFirstButton}
            disabled={loading}
          >
            {cancelText}
          </Button>) : null}
          {handleOnClickOkey ? (<Button
            onClick={handleOnClickOkey}
            color='success'
            simple
            disabled={loading}
            className={
              classes.modalSmallFooterFirstButton +
              ' ' +
              classes.modalSmallFooterSecondButton
            }
          >
          {okText}
          </Button>) : null}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ActionModal;
