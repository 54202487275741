import React from 'react';
import { FormikProps } from 'formik';
// @material-ui/core components
import { WithStyles } from '@material-ui/core/styles';

// core components
import Button from '@components/CustomButtons/Button.js';
import CustomInput from '@components/CustomInput/CustomInput.js';
import Card from '@components/Card/Card.js';
import CardBody from '@components/Card/CardBody.js';
import CardAvatar from '@components/Card/CardAvatar.js';
import CardFooter from '@components/Card/CardFooter.js';

import ovsLogo from 'assets/img/ovs-logo.png';
import { authFormFields } from 'admin/auth/constants/formFields';
import { Link } from 'react-router-dom';

export interface IResetPasswordFormValues {
  [key: string]: string | undefined;
  email?: string;
}

const ResetPasswordForm: React.FC<
  WithStyles & { formBag: FormikProps<IResetPasswordFormValues> }
> = ({ classes, formBag }) => {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('');
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  return (
    <div className={classes.container}>
      <form>
        <Card
          profile
          className={classes.customCardClass + ' ' + classes[cardAnimaton]}
        >
          <CardAvatar className={classes.cardAvatar} >
            <Link to='login-page'>
              <img src={ovsLogo} alt='Ovs Logo' />
            </Link>
          </CardAvatar>
          <CardBody profile>
            <h4 className={classes.cardTitle}>Reset Password</h4>
            <CustomInput
              labelText='Email'
              id='email'
              name="email"
              onChange={(e) =>
                formBag.setFieldValue(
                  authFormFields.login.email,
                  e.target.value
                )
              }
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'type',
                autoComplete: 'on',
              }}
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color='primary' onClick={() => {
              formBag.submitForm()
            }}>
              Reset
            </Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
