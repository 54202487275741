import React from 'react';

interface IProps {
  value: string;
}

const MigratedCell: React.FC<IProps> = ({ value }) => {
  return <div>{value ? <div>Migrated</div> : <div>---</div>}</div>;
};

export default MigratedCell;
