import { gql } from "@apollo/client";

/**
 * Mutation.reverseCodeEvent(
reverseCodeEventInput: ReverseCodeEventInput!
): CodeResponse!
 */
export const reverseCodeEventMutation = gql`
  mutation ReverseCodeEvent($reverseCodeEventInput: ReverseCodeEventInput!) {
    reverseCodeEvent(reverseCodeEventInput: $reverseCodeEventInput)
  }
`;

