import { OVS_PRODUCTS_ENDPOINT } from 'config';
import { ajax } from 'rxjs/ajax';
import { StorageService } from './StorageService';
export class HttpService {
  static get(url: string, headers = { 'Content-Type': 'application/json' }) {
    const auth = StorageService.getToken()
      ? { Authorization: 'Token ' + StorageService.getToken() }
      : {};
    return ajax({
      url: `${OVS_PRODUCTS_ENDPOINT}${url}`,
      headers: { ...headers, ...auth },
      method: 'GET',
      async: true,
      crossDomain: true,
      responseType: 'json',
      createXHR: () => new XMLHttpRequest(),
    });
  } // get

  static post(
    url: string,
    body: any,
    headers = { 'Content-Type': 'application/json' }
  ) {
    const auth = StorageService.getToken()
      ? { Authorization: 'Token ' + StorageService.getToken() }
      : {};
    return ajax({
      url: `${OVS_PRODUCTS_ENDPOINT}${url}`,
      method: 'POST',
      body,
      headers: { ...headers, ...auth },
      async: true,
      crossDomain: true,
      responseType: 'json',
      // createXHR: () => new XMLHttpRequest()
    });
  } // post
  static put(
    url: string,
    body: any,
    headers = { 'Content-Type': 'application/json' }
  ) {
    const auth = StorageService.getToken()
      ? { Authorization: 'Token ' + StorageService.getToken() }
      : {};
    return ajax({
      url: `${OVS_PRODUCTS_ENDPOINT}${url}`,
      method: 'PUT',
      body,
      headers: { ...headers, ...auth },
      async: true,
      crossDomain: true,
      responseType: 'json',
      createXHR: () => new XMLHttpRequest(),
    });
  } // put

  static delete(
    url: string,
    body: any,
    headers = { 'Content-Type': 'application/json' }
  ) {
    const auth = StorageService.getToken()
      ? { Authorization: 'Token ' + StorageService.getToken() }
      : {};
    return ajax({
      url: `${OVS_PRODUCTS_ENDPOINT}${url}`,
      method: 'DELETE',
      body,
      headers: { ...headers, ...auth },
      async: true,
      crossDomain: true,
      responseType: 'json',
      createXHR: () => new XMLHttpRequest(),
    });
  } // delete
}
