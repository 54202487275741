import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LegacyCategoryContext as Context } from '../context/LegacyCategoryContext';
import { legacyRoutes, itemFields } from '../constants';
import LegacyAddItem from '../components/LegacyAddItem';
import { IProductCategory } from '../types';
import * as actions from '../redux/actions';

const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();

  const { getItems, loading } = React.useContext(Context);

  const handleSubmit = (createItemInput: IProductCategory) => {
    actions.LegacyActions.createLegacyRequest({
      ...createItemInput,
    }).then(async (res) => {
      notify({
        status: 'success',
        text: `Created successfully.`,
      });
      getItems();
      history(legacyRoutes.list);
    });
  };

  if (loading) return <GridLoader />;

  return (
    <LegacyAddItem
      initialValues={{
        productCategoryName: '',
        [itemFields.productCategoryID]: '',
        [itemFields.productCategoryName]: '',
        [itemFields.productCategoryDescription]: '',
        [itemFields.image]: '',
      }}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
};

export default AddItemContainer;
