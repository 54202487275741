import { Grid } from '@material-ui/core';
import { Form, FormikProps, FieldArray } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleColumnCheckbox from '@components/ReactTable/ToggleColumnCheckbox';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    formControl: {marginTop: "1rem", marginLeft: "-2rem"},

    label: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "14px",
        marginTop: "0.5rem"
      },
})
const PaymentPlanForm: React.FC<{
  formBag: FormikProps<any>;
  disableLoginButton: boolean;
  actionButtonName?: string;
  inActive?: boolean;
}> = (props) => {
  const { formBag, disableLoginButton} = props;
  let { values } = formBag
  const classes = useStyles()
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Form>
          <GridContainer>
            <FieldArray
              name="payPlan"
              render={arrayHelpers => (
                <>
                  {values.payPlan.map((test: any, index: any) => {
                    const key = `${test.pName}-${index}`;
                    return (
                      <GridHalfWrapper key={key}>
                      {typeof(test.pValue) === "number" ? (
                        <TextInput
                          label={test.pName}
                          name={`payPlan.${index}.pValue`}
                          type="number"
                        />
                      ) : typeof(test.pValue) === "boolean" ? (
                        <FormControlLabel
                          className={classes.formControl}
                          control={<ToggleColumnCheckbox name={`payPlan.${index}.pValue`} />}
                          label={<Typography className={classes.label}><p>{test.pName}</p></Typography>}
                        />
                      ) : (
                        <TextInput
                          label={test.pName}
                          name={`payPlan.${index}.pValue`}
                        />
                      )}
                    </GridHalfWrapper>)
                  })}
                </>
              )}
            />
          </GridContainer>

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default PaymentPlanForm;
