import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';


import useOVSPagination from 'hooks/useOVSPagination';
import { useLazyGetAllClientExclusiveServicesQuery } from '../queries';
import { funNumberAgr } from '@types';
import { GetAllClientExclusiveServiceResponse_page_edges } from '../types/GetAllClientExclusiveServiceResponse';
import { PAGE_LIST_LIMIT } from '@constants';
import { GetAllClientExclusiveServices, GetAllClientExclusiveServicesVariables } from '../types/GetAllClientExclusiveServices';
import { handleGQLErrors } from 'utils/gqlErrors';
import useNotifier from 'hooks/useNotifier';

export interface IClientServiceContext {
  items: GetAllClientExclusiveServiceResponse_page_edges[];
  setItems: (item: GetAllClientExclusiveServiceResponse_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  activeStage: number;
  setActiveStage: (stage: number) => void;
  tableActions: ITableActions[];
  refetchItems:
  | ((
    variables?: Partial<GetAllClientExclusiveServicesVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllClientExclusiveServices>>)
  | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setPageLimit: funNumberAgr
}

export const ClientServiceContext = React.createContext(
  {} as IClientServiceContext
);

const ClientProvider: React.FC<IProps> = ({ children }) => {
  const [activeStage, setActiveStage] = useState(0);
  const [item, setItem] = React.useState(
    {} as GetAllClientExclusiveServiceResponse_page_edges
  );
  const [items, setItems] = React.useState<
    GetAllClientExclusiveServiceResponse_page_edges[]
  >([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  /// hooks
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();

  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore, error },
  ] = useLazyGetAllClientExclusiveServicesQuery({
    first: PAGE_LIST_LIMIT,
  });
  const notify = useNotifier()
  useEffect(() => {
    if (error) {
      handleGQLErrors(notify, error)
    } // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    setItems(data?.getAllClientExclusiveServices?.page?.edges || []);
    setPaging({
      ...data?.getAllClientExclusiveServices?.pageData,
      ...data?.getAllClientExclusiveServices?.page?.pageInfo,
    });
    // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllClientExclusiveServicesVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllClientExclusiveServices?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllClientExclusiveServices?.pageData,
        ..._data?.data?.getAllClientExclusiveServices?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const value = React.useMemo(
    () => ({
      item,
      setItem,
      items,
      setItems,
      loading: loading,
      getItems: getItems,
      refetchItems,
      tableActions: [],
      activeStage,
      setActiveStage,
      paging,
      goTo, setPageLimit
    }), // eslint-disable-next-line
    [items, loading, paging]
  );

  return (
    <ClientServiceContext.Provider value={value}>{children}</ClientServiceContext.Provider>
  );
};

export default ClientProvider;
