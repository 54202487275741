import { PAGE_LIST_LIMIT } from '@constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserPrefActions } from 'store/actions/userPrefActions';
import { RootState } from 'store/epics/userPrefEpics';

interface IPaging {
  __typename?: any;
  startCursor?: string | null;
  endCursor?: string | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  [key: string]: any;
}

const useOVSPagination = () => {
  // stores page info
  const [paging, setPaging] = useState({} as IPaging);
  const [pageListLimit, _setPageListLimit] = useState(PAGE_LIST_LIMIT);

  const rowsPerPage = useSelector(
    (store: RootState) => store.userPref.rowsPerPage || PAGE_LIST_LIMIT
  );
  const dispatch = useDispatch();

  const setPageListLimit = (limit: number) => {
    dispatch(UserPrefActions.setRowsPerPage(limit));
  };

  useEffect(() => {
    _setPageListLimit(rowsPerPage);
  }, [rowsPerPage]);
  // stack of end cursor
  const [endCursorStack, setEndCursorStack] = useState<string[]>([]);

  const initPagAction = (next: boolean) => {
    if (!next && endCursorStack.length === 0) {
      return {};
    }
    // update cursor
    if (next) {
      if (paging?.endCursor) {
        setEndCursorStack([...endCursorStack, paging?.endCursor]);
      }
    } else {
      // update endCursorStack. Remove last item in the stack
      const _endCursorStack = [...endCursorStack];
      _endCursorStack.pop();
      setEndCursorStack([..._endCursorStack]);
    }

    if (next) {
      return { after: paging?.endCursor };
    } else {
      if (endCursorStack.length > 1) {
        return { after: endCursorStack[endCursorStack.length - 2] };
      }
    }
  };

  return {
    endCursorStack,
    setEndCursorStack,
    initPagAction,
    setPaging,
    paging,
    setPageListLimit,
    pageListLimit,
  };
};

export default useOVSPagination;
