import React from 'react';
import { Row } from 'react-table';
import TableActions from '../components/TableActions';

export const itemTableData = [
  {
    Header: 'Actions',
    id: 'expander', // It needs an ID
    Cell: ({ row }: { row: Row }) => (
      <TableActions {...row.getToggleRowExpandedProps()} row={row} />
    ),
  },
  {
    Header: 'Servicetype',
    accessor: 'node.servicetype'
  },
  {
    Header: 'Distributor Email',
    accessor: 'node.distributor.orgContactPerson.contact.email'
  }
];
