import { IProps } from '@components/messages/types';
import { GetAllSubRoleLoginUsersForRoleLoginUser_getAllSubRoleLoginUsersForRoleLoginUser } from 'admin/agents/types/GetAllSubRoleLoginUsersForRoleLoginUser';
import { GetAllSubRoleLoginUsersForRoleLoginUserResponse } from 'admin/agents/types/GetAllSubRoleLoginUsersForRoleLoginUserResponse';
import React, { useState } from 'react';

export interface IAgentContext {
  users: GetAllSubRoleLoginUsersForRoleLoginUserResponse;
  setUsers: (
    users: GetAllSubRoleLoginUsersForRoleLoginUser_getAllSubRoleLoginUsersForRoleLoginUser
  ) => void;
  loading: boolean;
}

export const AgentContext = React.createContext<IAgentContext>(
  {} as IAgentContext
);

const AgentProvider: React.FC<IProps> = ({ children }) => {
  const [users, setUsers] = React.useState<
    GetAllSubRoleLoginUsersForRoleLoginUser_getAllSubRoleLoginUsersForRoleLoginUser
  >(
    {} as GetAllSubRoleLoginUsersForRoleLoginUser_getAllSubRoleLoginUsersForRoleLoginUser
  );
  const [loading] = useState(false);

  const value = React.useMemo(
    () => ({
      users,
      setUsers,
      loading,
    }),
    [users, loading]
  );

  return (
    <AgentContext.Provider value={value as IAgentContext}>
      {children}
    </AgentContext.Provider>
  );
};

export default AgentProvider;
