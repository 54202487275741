import { Theme, Tooltip, withStyles } from '@material-ui/core';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
interface IProps {
  value: string;
}
const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const SafeCell: React.FC<IProps> = ({ value }) => {
  const length = 24;
  let _value = typeof value === 'string' ? value : JSON.stringify(value, null, 2);
  _value = _value === 'null' ? '' : _value;

  const notify = useNotifier()
  return (
    <CopyToClipboard text={_value}
										onCopy={() => notify({ text: 'Text Copied.', status: 'success' })}>
    <HtmlTooltip title={<React.Fragment>{_value}</React.Fragment>}>
      <div>
        {_value?.length > length
          ? _value?.substring(0, length) + '...'
          : _value}
      </div>
    </HtmlTooltip>
    </CopyToClipboard>
  );
};

export default SafeCell;
