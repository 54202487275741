import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { itemsRoutes } from './constants';
import List from "./containers/ItemContainer";
import EncoderDecoderContainer from './containers/EncoderDecoderContainer';
import OpenTokenCodeEvent from './components/codeEvents';
interface IProps { }
export const routes = {
         path: itemsRoutes.list,
         element: <DefaultOutlet />,
         children: [
           { index: true, element: <List /> },
           { path: itemsRoutes.simulator, element: <EncoderDecoderContainer /> },
           { path: itemsRoutes.codeEvents, element: <OpenTokenCodeEvent /> },
         ],
       };
const ItemIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default ItemIndex;
