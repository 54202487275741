import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { itemRoutes } from '../constants';
import { AvatarContext as Context } from '../context/AvatarProvider';
import ActionModal from '@components/Modal/ActionModal';
import useDeleteAvatar from '../hooks/useDeleteAvatar';
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import axios from 'axios'
import { GRAFANA_API, GRAFANA_API_KEY } from 'config'
import * as _ from 'lodash'

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const history = useNavigate();
  const { refetchItems: refetch } = useContext(Context);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { mutation: deleteItem } = useDeleteAvatar({
    onComplete: () => {
      refetch && refetch();
      setIsModalOpen(false);
    },
  });

  // This is the topic we capture from erm
  let topic = _.get(row.values["node.att"],"[0].value", "test")
  // let itemId = row.values["node._id"]
  // console.log("item id",itemId)

  /**
   * This function creates a dashboard on Grafana.
   * It get's topic from the avatar section under the att.value.
   * It uses the value and maps it as the topic on grafana and populates dashboard.
   * When you click on create dashboard on erm, you get redirected to grafana to that specific topic's dashboard.
   */
  const createDashboard = async () => {

    const url = `${GRAFANA_API}/api/dashboards/db`;

    let dashboard
    // The dashboard object that we use to create our dashboard. We predefine upfront

    if (topic.startsWith("42AH")) {
      // Use dashboard template for 48AH topic
      dashboard = {
        "annotations": {
          "list": [
            {
              "builtIn": 1,
              "datasource": {
                "type": "datasource",
                "uid": "grafana"
              },
              "enable": true,
              "hide": true,
              "iconColor": "rgba(0, 211, 255, 1)",
              "name": "Annotations & Alerts",
              "target": {
                "limit": 100,
                "matchAny": false,
                "tags": [],
                "type": "dashboard"
              },
              "type": "dashboard"
            }
          ]
        },
        "editable": true,
        "fiscalYearStartMonth": 0,
        "graphTooltip": 0,
        "links": [],
        "liveNow": false,
        "uid": `${topic}`,
        "id": null,
        "title": `Dashboard of the topic ${topic}`,
        "panels": [
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 0
            },
            "id": 12,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-GrYlRd"
                    },
                    "displayName": "GPRS Sleep time window",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 0,
                  "y": 1
                },
                "id": 67,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_gstw"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "GPRS Connect time window",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 3,
                  "y": 1
                },
                "id": 68,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_gctw"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Generic Device Switch",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "bool_on_off"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 6,
                  "y": 1
                },
                "id": 69,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_swch"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Heart Beat Interval Minutes",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 9,
                  "y": 1
                },
                "id": 70,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_hbfq"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "CMD",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 1
            },
            "id": 6,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "custom": {
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      }
                    },
                    "decimals": 6,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "rgba(245, 54, 54, 0.9)",
                          "value": null
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 22,
                  "w": 14,
                  "x": 0,
                  "y": 2
                },
                "id": 20,
                "maxDataPoints": 1,
                "options": {
                  "basemap": {
                    "config": {},
                    "name": "Basemap",
                    "opacity": 0.9,
                    "tooltip": true,
                    "type": "osm-standard"
                  },
                  "controls": {
                    "mouseWheelZoom": true,
                    "showAttribution": true,
                    "showDebug": false,
                    "showMeasure": false,
                    "showScale": false,
                    "showZoom": true
                  },
                  "layers": [
                    {
                      "config": {
                        "blur": 14,
                        "radius": 4,
                        "weight": {
                          "field": "Time",
                          "fixed": 1,
                          "max": 1,
                          "min": 0
                        }
                      },
                      "location": {
                        "latitude": "sts_plat",
                        "longitude": "sts_plon",
                        "mode": "coords"
                      },
                      "name": "Layer 1",
                      "tooltip": true,
                      "type": "heatmap"
                    }
                  ],
                  "tooltip": {
                    "mode": "details"
                  },
                  "view": {
                    "allLayers": true,
                    "id": "africa",
                    "lat": 0,
                    "lon": 30,
                    "zoom": 5
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "table",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_plat"
                          ],
                          "type": "field"
                        }
                      ],
                      [
                        {
                          "params": [
                            "sts_plon"
                          ],
                          "type": "field"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "geomap"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "PayG State",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 14,
                  "y": 2
                },
                "id": 63,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_pgst"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Total Running History Days",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 16,
                  "y": 2
                },
                "id": 28,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_trhd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 18,
                  "y": 2
                },
                "id": 26,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_rcrd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Last Token Entry Response",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 20,
                  "y": 2
                },
                "id": 61,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_tkre"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "PayG Running Days",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 22,
                  "y": 2
                },
                "id": 30,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_tpgd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Device Output State",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 14,
                  "y": 5
                },
                "id": 62,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_ocst"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "SSTC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 16,
                  "y": 5
                },
                "id": 18,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_sstc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "GPS Signal Strength",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 18,
                  "y": 5
                },
                "id": 22,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_gpss"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "GPFC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 20,
                  "y": 5
                },
                "id": 24,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_gpfc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "displayName": "SoC%",
                    "mappings": [],
                    "thresholds": {
                      "mode": "percentage",
                      "steps": [
                        {
                          "color": "red",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 10
                        },
                        {
                          "color": "green",
                          "value": 20
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 8
                },
                "id": 72,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "always",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Relative SoC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 8
                },
                "id": 73,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "displayName": "Pack Voltage",
                    "mappings": [],
                    "thresholds": {
                      "mode": "percentage",
                      "steps": [
                        {
                          "color": "red",
                          "value": null
                        },
                        {
                          "color": "orange",
                          "value": 10
                        },
                        {
                          "color": "green",
                          "value": 20
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 12
                },
                "id": 65,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckv"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Pack Voltage",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 12
                },
                "id": 40,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckv"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "max": 4000,
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 2000
                        },
                        {
                          "color": "red",
                          "value": 3000
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 16
                },
                "id": 71,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_outp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 16
                },
                "id": 36,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_outp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Pack Current",
                    "mappings": [],
                    "max": 4000,
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 2000
                        },
                        {
                          "color": "red",
                          "value": 3000
                        }
                      ]
                    },
                    "unit": "mamp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 20
                },
                "id": 81,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true,
                  "text": {}
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "amp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 20
                },
                "id": 82,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "STS",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 2
            },
            "id": 10,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Accumulated Energy Output",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 0,
                  "y": 3
                },
                "id": 38,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_aeng"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Full Charge Capacity",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 2,
                  "y": 3
                },
                "id": 48,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_fccp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlPu"
                    },
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 4,
                  "y": 3
                },
                "id": 52,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_acyc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-blues"
                    },
                    "displayName": "Pack Temperature",
                    "mappings": [],
                    "noValue": "-",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "celsius"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 7,
                  "y": 3
                },
                "id": 80,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckt"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-blues"
                    },
                    "displayName": "SSPE",
                    "mappings": [],
                    "noValue": "-",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "celsius"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 10,
                  "y": 3
                },
                "id": 50,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_sspe"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-GrYlRd"
                    },
                    "displayName": "Relative SoC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 4,
                  "x": 13,
                  "y": 3
                },
                "id": 44,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-YlBl"
                    },
                    "displayName": "Pack Current",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mamp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 4,
                  "x": 17,
                  "y": 3
                },
                "id": 42,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-RdYlGr"
                    },
                    "displayName": "Remaining Capacity",
                    "mappings": [],
                    "noValue": "0Wh",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 21,
                  "y": 3
                },
                "id": 46,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rcap"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Total Input Power (W)",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 0,
                  "y": 6
                },
                "id": 58,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_inpp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": false,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Total Input Power (W)",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 2,
                  "y": 6
                },
                "id": 34,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_inpp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Battery Charging Power",
                    "mappings": [],
                    "min": 0,
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 10,
                  "y": 6
                },
                "id": 59,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_batp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Battery Charging Power",
                    "mappings": [],
                    "min": 0,
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 12,
                  "y": 6
                },
                "id": 32,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_batp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "DTA",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 3
            },
            "id": 8,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineStyle": {
                        "fill": "solid"
                      },
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "decimals": 0,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 6,
                  "w": 24,
                  "x": 0,
                  "y": 4
                },
                "id": 54,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "alias": "CV01",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv01"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  },
                  {
                    "alias": "CV02",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "B",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv02"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV03",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "C",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv03"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV04",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "D",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv04"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV05",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "E",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv05"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV06",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "F",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv06"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV07",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "G",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv07"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV08",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "H",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv08"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Eboat$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV09",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "I",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv09"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV10",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "J",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv10"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV11",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "K",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv11"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV12",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "L",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv12"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV13",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "M",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv13"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV14",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "N",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv14"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV15",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "O",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv15"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV16",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "P",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv16"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV17",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "Q",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv17"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV18",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "R",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv18"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV19",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "S",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv19"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV20",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "T",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv20"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  }
                ],
                "title": "Cell Voltages",
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "decimals": 3,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 11,
                  "w": 24,
                  "x": 0,
                  "y": 10
                },
                "id": 64,
                "options": {
                  "displayMode": "lcd",
                  "minVizHeight": 10,
                  "minVizWidth": 0,
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showUnfilled": true,
                  "text": {}
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "alias": "CV01",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv01"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  },
                  {
                    "alias": "CV02",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "B",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv02"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV03",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "C",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv03"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV04",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "D",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv04"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV05",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "E",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv05"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV06",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "F",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv06"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV07",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "G",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv07"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV08",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "H",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv08"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Eboat$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV09",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "I",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv09"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV10",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "J",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv10"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV11",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "K",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv11"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV12",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "L",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv12"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV13",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "M",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv13"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV14",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "N",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv14"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV15",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "O",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv15"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV16",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "P",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv16"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV17",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "Q",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv17"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV18",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "R",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv18"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV19",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "S",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv19"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV20",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "T",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv20"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  }
                ],
                "title": "Cell Voltages",
                "type": "bargauge"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "DIA",
            "type": "row"
          }
        ],
        "refresh": "",
        "revision": 1,
        "schemaVersion": 38,
        "style": "dark",
        "tags": [],
        "templating": {
          "list": []
        },
        "time": {
          "from": "now-6h",
          "to": "now"
        }
      };
    } else if (topic.startsWith("48AH")) {
      // Use dashboard template for 42AH topic
      dashboard = {
        "annotations": {
          "list": [
            {
              "builtIn": 1,
              "datasource": {
                "type": "datasource",
                "uid": "grafana"
              },
              "enable": true,
              "hide": true,
              "iconColor": "rgba(0, 211, 255, 1)",
              "name": "Annotations & Alerts",
              "target": {
                "limit": 100,
                "matchAny": false,
                "tags": [],
                "type": "dashboard"
              },
              "type": "dashboard"
            }
          ]
        },
        "editable": true,
        "fiscalYearStartMonth": 0,
        "graphTooltip": 0,
        "links": [],
        "liveNow": false,
        "uid": `${topic}`,
        "id": null,
        "title": `Dashboard of the topic ${topic}`,
        "panels": [
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 0
            },
            "id": 12,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-GrYlRd"
                    },
                    "displayName": "GPRS Sleep time window",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 0,
                  "y": 1
                },
                "id": 67,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_gstw"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "GPRS Connect time window",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 3,
                  "y": 1
                },
                "id": 68,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_gctw"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Generic Device Switch",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "bool_on_off"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 6,
                  "y": 1
                },
                "id": 69,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_swch"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Heart Beat Interval Minutes",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 9,
                  "y": 1
                },
                "id": 70,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_hbfq"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "CMD",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 1
            },
            "id": 6,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "custom": {
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      }
                    },
                    "decimals": 6,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "rgba(245, 54, 54, 0.9)",
                          "value": null
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 22,
                  "w": 14,
                  "x": 0,
                  "y": 2
                },
                "id": 20,
                "maxDataPoints": 1,
                "options": {
                  "basemap": {
                    "config": {},
                    "name": "Basemap",
                    "opacity": 0.9,
                    "tooltip": true,
                    "type": "osm-standard"
                  },
                  "controls": {
                    "mouseWheelZoom": true,
                    "showAttribution": true,
                    "showDebug": false,
                    "showMeasure": false,
                    "showScale": false,
                    "showZoom": true
                  },
                  "layers": [
                    {
                      "config": {
                        "blur": 14,
                        "radius": 4,
                        "weight": {
                          "field": "Time",
                          "fixed": 1,
                          "max": 1,
                          "min": 0
                        }
                      },
                      "location": {
                        "latitude": "sts_plat",
                        "longitude": "sts_plon",
                        "mode": "coords"
                      },
                      "name": "Layer 1",
                      "tooltip": true,
                      "type": "heatmap"
                    }
                  ],
                  "tooltip": {
                    "mode": "details"
                  },
                  "view": {
                    "allLayers": true,
                    "id": "africa",
                    "lat": 0,
                    "lon": 30,
                    "zoom": 5
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "table",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_plat"
                          ],
                          "type": "field"
                        }
                      ],
                      [
                        {
                          "params": [
                            "sts_plon"
                          ],
                          "type": "field"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "geomap"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "PayG State",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 14,
                  "y": 2
                },
                "id": 63,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_pgst"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Total Running History Days",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 16,
                  "y": 2
                },
                "id": 28,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_trhd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 18,
                  "y": 2
                },
                "id": 26,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_rcrd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Last Token Entry Response",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 20,
                  "y": 2
                },
                "id": 61,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_tkre"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "PayG Running Days",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 22,
                  "y": 2
                },
                "id": 30,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_tpgd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Device Output State",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 14,
                  "y": 5
                },
                "id": 62,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_ocst"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "SSTC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 16,
                  "y": 5
                },
                "id": 18,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_sstc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "GPS Signal Strength",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 18,
                  "y": 5
                },
                "id": 22,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_gpss"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "GPFC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 20,
                  "y": 5
                },
                "id": 24,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_gpfc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "displayName": "SoC%",
                    "mappings": [],
                    "thresholds": {
                      "mode": "percentage",
                      "steps": [
                        {
                          "color": "red",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 10
                        },
                        {
                          "color": "green",
                          "value": 20
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 8
                },
                "id": 72,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "always",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Relative SoC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 8
                },
                "id": 73,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "displayName": "Pack Voltage",
                    "mappings": [],
                    "thresholds": {
                      "mode": "percentage",
                      "steps": [
                        {
                          "color": "red",
                          "value": null
                        },
                        {
                          "color": "orange",
                          "value": 10
                        },
                        {
                          "color": "green",
                          "value": 20
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 12
                },
                "id": 65,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckv"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Pack Voltage",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 12
                },
                "id": 40,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckv"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "max": 4000,
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 2000
                        },
                        {
                          "color": "red",
                          "value": 3000
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 16
                },
                "id": 71,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_outp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 16
                },
                "id": 36,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_outp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Pack Current",
                    "mappings": [],
                    "max": 4000,
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 2000
                        },
                        {
                          "color": "red",
                          "value": 3000
                        }
                      ]
                    },
                    "unit": "mamp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 20
                },
                "id": 81,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true,
                  "text": {}
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "amp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 20
                },
                "id": 82,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "STS",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 2
            },
            "id": 10,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Accumulated Energy Output",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 0,
                  "y": 3
                },
                "id": 38,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_aeng"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Full Charge Capacity",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 2,
                  "y": 3
                },
                "id": 48,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_fccp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlPu"
                    },
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 4,
                  "y": 3
                },
                "id": 52,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_acyc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-blues"
                    },
                    "displayName": "Pack Temperature",
                    "mappings": [],
                    "noValue": "-",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "celsius"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 7,
                  "y": 3
                },
                "id": 80,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckt"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-blues"
                    },
                    "displayName": "SSPE",
                    "mappings": [],
                    "noValue": "-",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "celsius"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 10,
                  "y": 3
                },
                "id": 50,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_sspe"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-GrYlRd"
                    },
                    "displayName": "Relative SoC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 4,
                  "x": 13,
                  "y": 3
                },
                "id": 44,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-YlBl"
                    },
                    "displayName": "Pack Current",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mamp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 4,
                  "x": 17,
                  "y": 3
                },
                "id": 42,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-RdYlGr"
                    },
                    "displayName": "Remaining Capacity",
                    "mappings": [],
                    "noValue": "0Wh",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 21,
                  "y": 3
                },
                "id": 46,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rcap"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Total Input Power (W)",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 0,
                  "y": 6
                },
                "id": 58,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_inpp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": false,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Total Input Power (W)",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 2,
                  "y": 6
                },
                "id": 34,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_inpp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Battery Charging Power",
                    "mappings": [],
                    "min": 0,
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 10,
                  "y": 6
                },
                "id": 59,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_batp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Battery Charging Power",
                    "mappings": [],
                    "min": 0,
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 12,
                  "y": 6
                },
                "id": 32,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_batp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "DTA",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 3
            },
            "id": 8,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineStyle": {
                        "fill": "solid"
                      },
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "decimals": 0,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 6,
                  "w": 24,
                  "x": 0,
                  "y": 4
                },
                "id": 54,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "alias": "CV01",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv01"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  },
                  {
                    "alias": "CV02",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "B",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv02"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV03",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "C",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv03"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV04",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "D",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv04"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV05",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "E",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv05"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV06",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "F",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv06"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV07",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "G",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv07"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV08",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "H",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv08"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Eboat$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV09",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "I",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv09"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV10",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "J",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv10"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV11",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "K",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv11"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV12",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "L",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv12"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV13",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "M",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv13"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV14",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "N",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv14"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV15",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "O",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv15"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV16",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "P",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv16"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV17",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "Q",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv17"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV18",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "R",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv18"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV19",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "S",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv19"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV20",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "T",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv20"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  }
                ],
                "title": "Cell Voltages",
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "decimals": 3,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 11,
                  "w": 24,
                  "x": 0,
                  "y": 10
                },
                "id": 64,
                "options": {
                  "displayMode": "lcd",
                  "minVizHeight": 10,
                  "minVizWidth": 0,
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showUnfilled": true,
                  "text": {}
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "alias": "CV01",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv01"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  },
                  {
                    "alias": "CV02",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "B",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv02"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV03",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "C",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv03"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV04",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "D",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv04"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV05",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "E",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv05"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV06",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "F",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv06"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV07",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "G",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv07"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV08",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "H",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv08"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Eboat$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV09",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "I",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv09"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV10",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "J",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv10"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV11",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "K",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv11"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV12",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "L",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv12"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV13",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "M",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv13"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV14",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "N",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv14"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV15",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "O",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv15"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV16",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "P",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv16"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV17",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "Q",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv17"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV18",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "R",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv18"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV19",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "S",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv19"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV20",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "T",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv20"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  }
                ],
                "title": "Cell Voltages",
                "type": "bargauge"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "DIA",
            "type": "row"
          }
        ],
        "refresh": "",
        "revision": 1,
        "schemaVersion": 38,
        "style": "dark",
        "tags": [],
        "templating": {
          "list": []
        },
        "time": {
          "from": "now-6h",
          "to": "now"
        }
      };
    } else if (topic.startsWith("06AH")) {
      // Use dashboard template for 06AH****** topic
      dashboard = {
        "annotations": {
          "list": [
            {
              "builtIn": 1,
              "datasource": {
                "type": "datasource",
                "uid": "grafana"
              },
              "enable": true,
              "hide": true,
              "iconColor": "rgba(0, 211, 255, 1)",
              "name": "Annotations & Alerts",
              "target": {
                "limit": 100,
                "matchAny": false,
                "tags": [],
                "type": "dashboard"
              },
              "type": "dashboard"
            }
          ]
        },
        "editable": true,
        "fiscalYearStartMonth": 0,
        "graphTooltip": 0,
        "links": [],
        "liveNow": false,
        "uid": `${topic}`,
        "id": null,
        "title": `Dashboard of the topic ${topic}`,
        "panels": [
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 0
            },
            "id": 12,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-GrYlRd"
                    },
                    "displayName": "GPRS Sleep time window",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 0,
                  "y": 1
                },
                "id": 67,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_gstw"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "GPRS Connect time window",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 3,
                  "y": 1
                },
                "id": 68,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_gctw"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Generic Device Switch",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "bool_on_off"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 6,
                  "y": 1
                },
                "id": 69,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_swch"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Heart Beat Interval Minutes",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 9,
                  "y": 1
                },
                "id": 70,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_hbfq"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "CMD",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 1
            },
            "id": 6,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "custom": {
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      }
                    },
                    "decimals": 6,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "rgba(245, 54, 54, 0.9)",
                          "value": null
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 22,
                  "w": 14,
                  "x": 0,
                  "y": 2
                },
                "id": 20,
                "maxDataPoints": 1,
                "options": {
                  "basemap": {
                    "config": {},
                    "name": "Basemap",
                    "opacity": 0.9,
                    "tooltip": true,
                    "type": "osm-standard"
                  },
                  "controls": {
                    "mouseWheelZoom": true,
                    "showAttribution": true,
                    "showDebug": false,
                    "showMeasure": false,
                    "showScale": false,
                    "showZoom": true
                  },
                  "layers": [
                    {
                      "config": {
                        "blur": 14,
                        "radius": 4,
                        "weight": {
                          "field": "Time",
                          "fixed": 1,
                          "max": 1,
                          "min": 0
                        }
                      },
                      "location": {
                        "latitude": "sts_plat",
                        "longitude": "sts_plon",
                        "mode": "coords"
                      },
                      "name": "Layer 1",
                      "tooltip": true,
                      "type": "heatmap"
                    }
                  ],
                  "tooltip": {
                    "mode": "details"
                  },
                  "view": {
                    "allLayers": true,
                    "id": "africa",
                    "lat": 0,
                    "lon": 30,
                    "zoom": 5
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "table",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_plat"
                          ],
                          "type": "field"
                        }
                      ],
                      [
                        {
                          "params": [
                            "sts_plon"
                          ],
                          "type": "field"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "geomap"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "PayG State",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 14,
                  "y": 2
                },
                "id": 63,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_pgst"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Total Running History Days",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 16,
                  "y": 2
                },
                "id": 28,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_trhd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 18,
                  "y": 2
                },
                "id": 26,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_rcrd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Last Token Entry Response",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 20,
                  "y": 2
                },
                "id": 61,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_tkre"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "PayG Running Days",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 22,
                  "y": 2
                },
                "id": 30,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_tpgd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Device Output State",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 14,
                  "y": 5
                },
                "id": 62,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_ocst"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "SSTC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 16,
                  "y": 5
                },
                "id": 18,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_sstc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "GPS Signal Strength",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 18,
                  "y": 5
                },
                "id": 22,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_gpss"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "GPFC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 20,
                  "y": 5
                },
                "id": 24,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_gpfc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "displayName": "SoC%",
                    "mappings": [],
                    "thresholds": {
                      "mode": "percentage",
                      "steps": [
                        {
                          "color": "red",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 10
                        },
                        {
                          "color": "green",
                          "value": 20
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 8
                },
                "id": 72,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "always",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Relative SoC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 8
                },
                "id": 73,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "displayName": "Pack Voltage",
                    "mappings": [],
                    "thresholds": {
                      "mode": "percentage",
                      "steps": [
                        {
                          "color": "red",
                          "value": null
                        },
                        {
                          "color": "orange",
                          "value": 10
                        },
                        {
                          "color": "green",
                          "value": 20
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 12
                },
                "id": 65,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckv"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Pack Voltage",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 12
                },
                "id": 40,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckv"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "max": 4000,
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 2000
                        },
                        {
                          "color": "red",
                          "value": 3000
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 16
                },
                "id": 71,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_outp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 16
                },
                "id": 36,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_outp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Pack Current",
                    "mappings": [],
                    "max": 4000,
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 2000
                        },
                        {
                          "color": "red",
                          "value": 3000
                        }
                      ]
                    },
                    "unit": "mamp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 20
                },
                "id": 81,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true,
                  "text": {}
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "amp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 20
                },
                "id": 82,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "STS",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 2
            },
            "id": 10,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Accumulated Energy Output",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 0,
                  "y": 3
                },
                "id": 38,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_aeng"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Full Charge Capacity",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 2,
                  "y": 3
                },
                "id": 48,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_fccp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlPu"
                    },
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 4,
                  "y": 3
                },
                "id": 52,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_acyc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-blues"
                    },
                    "displayName": "Pack Temperature",
                    "mappings": [],
                    "noValue": "-",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "celsius"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 7,
                  "y": 3
                },
                "id": 80,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckt"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-blues"
                    },
                    "displayName": "SSPE",
                    "mappings": [],
                    "noValue": "-",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "celsius"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 10,
                  "y": 3
                },
                "id": 50,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_sspe"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-GrYlRd"
                    },
                    "displayName": "Relative SoC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 4,
                  "x": 13,
                  "y": 3
                },
                "id": 44,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-YlBl"
                    },
                    "displayName": "Pack Current",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mamp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 4,
                  "x": 17,
                  "y": 3
                },
                "id": 42,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-RdYlGr"
                    },
                    "displayName": "Remaining Capacity",
                    "mappings": [],
                    "noValue": "0Wh",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 21,
                  "y": 3
                },
                "id": 46,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rcap"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Total Input Power (W)",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 0,
                  "y": 6
                },
                "id": 58,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_inpp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": false,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Total Input Power (W)",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 2,
                  "y": 6
                },
                "id": 34,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_inpp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Battery Charging Power",
                    "mappings": [],
                    "min": 0,
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 10,
                  "y": 6
                },
                "id": 59,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_batp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Battery Charging Power",
                    "mappings": [],
                    "min": 0,
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 12,
                  "y": 6
                },
                "id": 32,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_batp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "DTA",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 3
            },
            "id": 8,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineStyle": {
                        "fill": "solid"
                      },
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "decimals": 0,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 6,
                  "w": 24,
                  "x": 0,
                  "y": 4
                },
                "id": 54,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "alias": "CV01",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv01"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  },
                  {
                    "alias": "CV02",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "B",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv02"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV03",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "C",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv03"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV04",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "D",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv04"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV05",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "E",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv05"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV06",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "F",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv06"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV07",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "G",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv07"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV08",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "H",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv08"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Eboat$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV09",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "I",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv09"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV10",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "J",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv10"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV11",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "K",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv11"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV12",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "L",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv12"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV13",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "M",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv13"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV14",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "N",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv14"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV15",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "O",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv15"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV16",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "P",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv16"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV17",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "Q",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv17"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV18",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "R",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv18"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV19",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "S",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv19"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV20",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "T",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv20"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  }
                ],
                "title": "Cell Voltages",
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "decimals": 3,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 11,
                  "w": 24,
                  "x": 0,
                  "y": 10
                },
                "id": 64,
                "options": {
                  "displayMode": "lcd",
                  "minVizHeight": 10,
                  "minVizWidth": 0,
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showUnfilled": true,
                  "text": {}
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "alias": "CV01",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv01"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  },
                  {
                    "alias": "CV02",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "B",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv02"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV03",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "C",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv03"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV04",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "D",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv04"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV05",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "E",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv05"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV06",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "F",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv06"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV07",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "G",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv07"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV08",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "H",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv08"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Eboat$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV09",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "I",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv09"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV10",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "J",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv10"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV11",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "K",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv11"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV12",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "L",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv12"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV13",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "M",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv13"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV14",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "N",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv14"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV15",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "O",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv15"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV16",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "P",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv16"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV17",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "Q",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv17"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV18",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "R",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv18"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV19",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "S",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv19"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV20",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "T",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv20"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  }
                ],
                "title": "Cell Voltages",
                "type": "bargauge"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "DIA",
            "type": "row"
          }
        ],
        "refresh": "",
        "revision": 1,
        "schemaVersion": 38,
        "style": "dark",
        "tags": [],
        "templating": {
          "list": []
        },
        "time": {
          "from": "now-6h",
          "to": "now"
        }
      };
    } else {
      // Use default dashboard template if topic does not match any of the conditions
      dashboard = {
        "annotations": {
          "list": [
            {
              "builtIn": 1,
              "datasource": {
                "type": "datasource",
                "uid": "grafana"
              },
              "enable": true,
              "hide": true,
              "iconColor": "rgba(0, 211, 255, 1)",
              "name": "Annotations & Alerts",
              "target": {
                "limit": 100,
                "matchAny": false,
                "tags": [],
                "type": "dashboard"
              },
              "type": "dashboard"
            }
          ]
        },
        "editable": true,
        "fiscalYearStartMonth": 0,
        "graphTooltip": 0,
        "links": [],
        "liveNow": false,
        "uid": `${topic}`,
        "id": null,
        "title": `Dashboard of the topic ${topic}`,
        "panels": [
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 0
            },
            "id": 12,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-GrYlRd"
                    },
                    "displayName": "GPRS Sleep time window",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 0,
                  "y": 1
                },
                "id": 67,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_gstw"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "GPRS Connect time window",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 3,
                  "y": 1
                },
                "id": 68,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_gctw"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Generic Device Switch",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "bool_on_off"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 6,
                  "y": 1
                },
                "id": 69,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_swch"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Heart Beat Interval Minutes",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "m"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 3,
                  "x": 9,
                  "y": 1
                },
                "id": 70,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "cmd_hbfq"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "CMD",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 1
            },
            "id": 6,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "custom": {
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      }
                    },
                    "decimals": 6,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "rgba(245, 54, 54, 0.9)",
                          "value": null
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 22,
                  "w": 14,
                  "x": 0,
                  "y": 2
                },
                "id": 20,
                "maxDataPoints": 1,
                "options": {
                  "basemap": {
                    "config": {},
                    "name": "Basemap",
                    "opacity": 0.9,
                    "tooltip": true,
                    "type": "osm-standard"
                  },
                  "controls": {
                    "mouseWheelZoom": true,
                    "showAttribution": true,
                    "showDebug": false,
                    "showMeasure": false,
                    "showScale": false,
                    "showZoom": true
                  },
                  "layers": [
                    {
                      "config": {
                        "blur": 14,
                        "radius": 4,
                        "weight": {
                          "field": "Time",
                          "fixed": 1,
                          "max": 1,
                          "min": 0
                        }
                      },
                      "location": {
                        "latitude": "sts_plat",
                        "longitude": "sts_plon",
                        "mode": "coords"
                      },
                      "name": "Layer 1",
                      "tooltip": true,
                      "type": "heatmap"
                    }
                  ],
                  "tooltip": {
                    "mode": "details"
                  },
                  "view": {
                    "allLayers": true,
                    "id": "africa",
                    "lat": 0,
                    "lon": 30,
                    "zoom": 5
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "table",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_plat"
                          ],
                          "type": "field"
                        }
                      ],
                      [
                        {
                          "params": [
                            "sts_plon"
                          ],
                          "type": "field"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "geomap"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "PayG State",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 14,
                  "y": 2
                },
                "id": 63,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_pgst"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Total Running History Days",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 16,
                  "y": 2
                },
                "id": 28,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_trhd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 18,
                  "y": 2
                },
                "id": 26,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_rcrd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlYlRd"
                    },
                    "displayName": "Last Token Entry Response",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 20,
                  "y": 2
                },
                "id": 61,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_tkre"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "PayG Running Days",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        }
                      ]
                    },
                    "unit": "d"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 22,
                  "y": 2
                },
                "id": 30,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_tpgd"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Device Output State",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 14,
                  "y": 5
                },
                "id": 62,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_ocst"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "SSTC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 16,
                  "y": 5
                },
                "id": 18,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_sstc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "GPS Signal Strength",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 18,
                  "y": 5
                },
                "id": 22,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "vertical",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_gpss"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "GPFC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 20,
                  "y": 5
                },
                "id": 24,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "sts_gpfc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "displayName": "SoC%",
                    "mappings": [],
                    "thresholds": {
                      "mode": "percentage",
                      "steps": [
                        {
                          "color": "red",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 10
                        },
                        {
                          "color": "green",
                          "value": 20
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 8
                },
                "id": 72,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "always",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Relative SoC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 8
                },
                "id": 73,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "displayName": "Pack Voltage",
                    "mappings": [],
                    "thresholds": {
                      "mode": "percentage",
                      "steps": [
                        {
                          "color": "red",
                          "value": null
                        },
                        {
                          "color": "orange",
                          "value": 10
                        },
                        {
                          "color": "green",
                          "value": 20
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 12
                },
                "id": 65,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckv"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Pack Voltage",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 12
                },
                "id": 40,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckv"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "max": 4000,
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 2000
                        },
                        {
                          "color": "red",
                          "value": 3000
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 16
                },
                "id": 71,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_outp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 16
                },
                "id": 36,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_outp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Pack Current",
                    "mappings": [],
                    "max": 4000,
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "yellow",
                          "value": 2000
                        },
                        {
                          "color": "red",
                          "value": 3000
                        }
                      ]
                    },
                    "unit": "mamp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 14,
                  "y": 20
                },
                "id": 81,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true,
                  "text": {}
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Output Power",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "amp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 16,
                  "y": 20
                },
                "id": 82,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "STS",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 2
            },
            "id": 10,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Accumulated Energy Output",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 0,
                  "y": 3
                },
                "id": 38,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_aeng"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Full Charge Capacity",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 2,
                  "x": 2,
                  "y": 3
                },
                "id": 48,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_fccp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-BlPu"
                    },
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    }
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 4,
                  "y": 3
                },
                "id": 52,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_acyc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-blues"
                    },
                    "displayName": "Pack Temperature",
                    "mappings": [],
                    "noValue": "-",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "celsius"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 7,
                  "y": 3
                },
                "id": 80,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckt"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-blues"
                    },
                    "displayName": "SSPE",
                    "mappings": [],
                    "noValue": "-",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "celsius"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 10,
                  "y": 3
                },
                "id": 50,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_sspe"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-GrYlRd"
                    },
                    "displayName": "Relative SoC",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "percent"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 4,
                  "x": 13,
                  "y": 3
                },
                "id": 44,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rsoc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-YlBl"
                    },
                    "displayName": "Pack Current",
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mamp"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 4,
                  "x": 17,
                  "y": 3
                },
                "id": 42,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "text": {},
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_pckc"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "continuous-RdYlGr"
                    },
                    "displayName": "Remaining Capacity",
                    "mappings": [],
                    "noValue": "0Wh",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watth"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 3,
                  "w": 3,
                  "x": 21,
                  "y": 3
                },
                "id": 46,
                "options": {
                  "colorMode": "background",
                  "graphMode": "area",
                  "justifyMode": "auto",
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "textMode": "auto"
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_rcap"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "stat"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Total Input Power (W)",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 0,
                  "y": 6
                },
                "id": 58,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_inpp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "description": "",
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": false,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Total Input Power (W)",
                    "mappings": [],
                    "noValue": "0",
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 2,
                  "y": 6
                },
                "id": 34,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_inpp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "displayName": "Battery Charging Power",
                    "mappings": [],
                    "min": 0,
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 2,
                  "x": 10,
                  "y": 6
                },
                "id": 59,
                "options": {
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showThresholdLabels": false,
                  "showThresholdMarkers": true
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_batp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "gauge"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "displayName": "Battery Charging Power",
                    "mappings": [],
                    "min": 0,
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "watt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 4,
                  "w": 8,
                  "x": 12,
                  "y": 6
                },
                "id": 32,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dta_batp"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  }
                ],
                "type": "timeseries"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "DTA",
            "type": "row"
          },
          {
            "collapsed": true,
            "datasource": {
              "type": "influxdb",
              "uid": "rKl9C1EVz"
            },
            "gridPos": {
              "h": 1,
              "w": 24,
              "x": 0,
              "y": 3
            },
            "id": 8,
            "panels": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "palette-classic"
                    },
                    "custom": {
                      "axisCenteredZero": false,
                      "axisColorMode": "text",
                      "axisLabel": "",
                      "axisPlacement": "auto",
                      "barAlignment": 0,
                      "drawStyle": "line",
                      "fillOpacity": 0,
                      "gradientMode": "none",
                      "hideFrom": {
                        "legend": false,
                        "tooltip": false,
                        "viz": false
                      },
                      "lineInterpolation": "linear",
                      "lineStyle": {
                        "fill": "solid"
                      },
                      "lineWidth": 1,
                      "pointSize": 5,
                      "scaleDistribution": {
                        "type": "linear"
                      },
                      "showPoints": "auto",
                      "spanNulls": true,
                      "stacking": {
                        "group": "A",
                        "mode": "none"
                      },
                      "thresholdsStyle": {
                        "mode": "off"
                      }
                    },
                    "decimals": 0,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 6,
                  "w": 24,
                  "x": 0,
                  "y": 4
                },
                "id": 54,
                "options": {
                  "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                  },
                  "tooltip": {
                    "mode": "single",
                    "sort": "none"
                  }
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "alias": "CV01",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv01"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  },
                  {
                    "alias": "CV02",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "B",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv02"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV03",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "C",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv03"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV04",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "D",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv04"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV05",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "E",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv05"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV06",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "F",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv06"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV07",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "G",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv07"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV08",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "H",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv08"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Eboat$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV09",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "I",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv09"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV10",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "J",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv10"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV11",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "K",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv11"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV12",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "L",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv12"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV13",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "M",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv13"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV14",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "N",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv14"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV15",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "O",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv15"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV16",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "P",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv16"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV17",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "Q",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv17"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV18",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "R",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv18"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV19",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "S",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv19"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV20",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "T",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv20"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  }
                ],
                "title": "Cell Voltages",
                "type": "timeseries"
              },
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "fieldConfig": {
                  "defaults": {
                    "color": {
                      "mode": "thresholds"
                    },
                    "decimals": 3,
                    "mappings": [],
                    "thresholds": {
                      "mode": "absolute",
                      "steps": [
                        {
                          "color": "green",
                          "value": null
                        },
                        {
                          "color": "red",
                          "value": 80
                        }
                      ]
                    },
                    "unit": "mvolt"
                  },
                  "overrides": []
                },
                "gridPos": {
                  "h": 11,
                  "w": 24,
                  "x": 0,
                  "y": 10
                },
                "id": 64,
                "options": {
                  "displayMode": "lcd",
                  "minVizHeight": 10,
                  "minVizWidth": 0,
                  "orientation": "auto",
                  "reduceOptions": {
                    "calcs": [
                      "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                  },
                  "showUnfilled": true,
                  "text": {}
                },
                "pluginVersion": "9.4.7",
                "targets": [
                  {
                    "alias": "CV01",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "A",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv01"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=",
                        "value": `dt/V01/GPRSV2/${topic}`
                      }
                    ]
                  },
                  {
                    "alias": "CV02",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "B",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv02"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV03",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "C",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv03"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV04",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "D",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv04"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV05",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "E",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv05"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV06",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "F",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv06"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV07",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "G",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv07"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV08",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "H",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv08"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Eboat$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV09",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "I",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv09"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV10",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "J",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv10"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV11",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "K",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv11"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV12",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "L",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv12"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV13",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "M",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv13"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV14",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "N",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv14"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV15",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "O",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv15"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV16",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "P",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv16"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV17",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "Q",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv17"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV18",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "R",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv18"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV19",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "S",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv19"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  },
                  {
                    "alias": "CV20",
                    "datasource": {
                      "type": "influxdb",
                      "uid": "rKl9C1EVz"
                    },
                    "groupBy": [
                      {
                        "params": [
                          "$__interval"
                        ],
                        "type": "time"
                      },
                      {
                        "params": [
                          "null"
                        ],
                        "type": "fill"
                      }
                    ],
                    "hide": false,
                    "measurement": "mqtt_consumer",
                    "orderByTime": "ASC",
                    "policy": "default",
                    "refId": "T",
                    "resultFormat": "time_series",
                    "select": [
                      [
                        {
                          "params": [
                            "dia_cv20"
                          ],
                          "type": "field"
                        },
                        {
                          "params": [],
                          "type": "mean"
                        }
                      ]
                    ],
                    "tags": [
                      {
                        "key": "topic",
                        "operator": "=~",
                        "value": "/^$Ebike$/"
                      }
                    ]
                  }
                ],
                "title": "Cell Voltages",
                "type": "bargauge"
              }
            ],
            "repeat": "Ovcamp",
            "targets": [
              {
                "datasource": {
                  "type": "influxdb",
                  "uid": "rKl9C1EVz"
                },
                "refId": "A"
              }
            ],
            "title": "DIA",
            "type": "row"
          }
        ],
        "refresh": "",
        "revision": 1,
        "schemaVersion": 38,
        "style": "dark",
        "tags": [],
        "templating": {
          "list": []
        },
        "time": {
          "from": "now-6h",
          "to": "now"
        }
      };
    }

    const config = {
      headers: { Authorization: `Bearer ${GRAFANA_API_KEY}` },
    };

    try {
      // the request we make to our grafana api which then makes a post request and creates a dashboard
      const response = await axios.post(url, {dashboard}, config);
      // we initialize the newURL for our dashboard which grafana returns to us after creating the dashboard
      let newUrl = response.data.url
      // We then get to redirected to the url of the new dashboard created
      window.open(`https://client-grafana.omnivoltaic.com${newUrl}`);
    } catch (error) {
      console.error(error);
    }
  };

  const viewDashboard = async () => {
    const config = {
      headers: { Authorization: `Bearer ${GRAFANA_API_KEY}` },
    };
    
    try {
      const topic = _.get(row.values["node.att"], "[0].value", "test");
      const response = await axios.get(`${GRAFANA_API}/api/dashboards/uid/${topic}`, config);
      
      const newUrl = response.data.meta.url;
      // Open the dashboard in a new tab
      window.open(`https://client-grafana.omnivoltaic.com${newUrl}`, '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='actions-right' style={{ display: 'flex' }}>
            <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id='expander'
        onClick={() => {
          history(itemRoutes.getEditLink(row.values['node.item._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>

      <HoverDropdown
        menus={[
          {
            name: localStorage.getItem(`itemClicked_${row.values["node.att"]?.[0]?.value || "test"}`) === "true" ? "View Dashboard" : "Create Dashboard",
            action: () => {
              const topic = _.get(row.values["node.att"], "[0].value", "test");
              console.log('this is topic', topic)
              const isClicked = localStorage.getItem(`itemClicked_${topic}`);
              if (isClicked === "true") {
                viewDashboard();
              } else {
                createDashboard();
                localStorage.setItem(`itemClicked_${topic}`, "true");
              }
            },
          },
        ]}
      />

      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deleteItem({
            variables: { avatarId: row.values['node._id'] },
          })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this avatar?</div>
      </ActionModal>
    </div>
  );
};

export default TableActions;
