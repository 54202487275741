import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import clientSimulator from 'utils/clientSimulator';
import { deleteAmazonProducCategoryMutation } from '../mutations';
import { useLazyGetAllAmazonProductCategoriesQuery } from '../queries';
import {
  DeleteAmazonProducCategory,
  DeleteAmazonProducCategoryVariables,
} from '../types/DeleteAmazonProducCategory';
import { GetAllAmazonProductCategories, GetAllAmazonProductCategories_getAllAmazonProductCategories_page_edges } from '../types/GetAllAmazonProductCategories';
import { PAGE_LIST_LIMIT } from '@constants';

export interface IAmazonCategoryContext {
  categories: GetAllAmazonProductCategories_getAllAmazonProductCategories_page_edges[];
  setCategories: (
    categories: GetAllAmazonProductCategories_getAllAmazonProductCategories_page_edges[]
  ) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  tableActions: ITableActions[];
  refetchItems: ((variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetAllAmazonProductCategories>>) | undefined;
  getItems: () => void;
}

export const AmazonCategoryContext = React.createContext<
  IAmazonCategoryContext
>({} as IAmazonCategoryContext);

const AmazonCategoryProvider: React.FC<IProps> = ({ children }) => {
  const [categories, setCategories] = React.useState<
    GetAllAmazonProductCategories_getAllAmazonProductCategories_page_edges[]
  >(
    [] as GetAllAmazonProductCategories_getAllAmazonProductCategories_page_edges[]
  );
  const [loading, setLoading] = React.useState(false);

  // context
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  // hooks
  const notify = useNotifier();
  const [
    getItems,
    { refetch: refetchItems },
  ] = useLazyGetAllAmazonProductCategoriesQuery({
    first: 100,
  });

  const [deleteItem] = useMutation<
    DeleteAmazonProducCategory,
    DeleteAmazonProducCategoryVariables
  >(deleteAmazonProducCategoryMutation, {
    client: clientSimulator,
    onCompleted: (data) => {},
  });

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            amazonProductCategoryId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Items deleted successfully',
        });
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: PAGE_LIST_LIMIT,
          });
          setCategories(
            itemsFetched?.data?.getAllAmazonProductCategories?.page?.edges ||
              categories
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: PAGE_LIST_LIMIT,
          });
          setCategories(
            itemsFetched?.data?.getAllAmazonProductCategories?.page?.edges ||
              categories
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      categories,
      setCategories,
      loading,refetchItems,getItems,
      setLoading,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} item(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [categories, loading]
  );

  return (
    <AmazonCategoryContext.Provider value={value}>
      {children}
    </AmazonCategoryContext.Provider>
  );
};

export default AmazonCategoryProvider;
