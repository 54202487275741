import { gql, useLazyQuery, useQuery } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import {
  GetAllClientExclusiveServices,
  GetAllClientExclusiveServicesVariables,
} from './types/GetAllClientExclusiveServices';
import {
  GetAllClientExclusiveServicesForDistributor,
  GetAllClientExclusiveServicesForDistributorVariables,
} from './types/GetAllClientExclusiveServicesForDistributor';
import {
  GetSpecificClientExclusiveService,
  GetSpecificClientExclusiveServiceVariables,
} from './types/GetSpecificClientExclusiveService';
/**
 * type ClientExclusiveService {
_id: ID!
clientExclusiveServicePermissions: [ClientExclusiveServicePermissions!]!
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
distributor: Distributor!
servicer: Servicer!
servicetype: ClientExclusiveServiceTypes!
updatedAt: DateTime
}
 */
const clientExclusiveServiceFragment = gql`
  fragment ClientExclusiveService on ClientExclusiveService {
    _id
    clientExclusiveServicePermissions {
      _id
      name
      permission {
        _id
        name
      }
    }
    createdAt
    deleteAt
    deleteStatus
    distributor {
      _id
      name
      orgContactPerson {
        _id
        contact {
          email
          phone
          social
        }
      }
      role {
        _id
      }
    }
    servicer {
      _id
      name
      description
      services
      type
      orgContactPerson {
        _id
        name
        contact {
          email
          phone
          social
        }
      }
    }
    servicetype
    updatedAt
  }
`;
/**
 * type ClientExclusiveServiceEdge {
cursor: String
node: ClientExclusiveService
}
 */
const clientExclusiveServiceEdgeFragment = gql`
  ${clientExclusiveServiceFragment}
  fragment ClientExclusiveServiceEdge on ClientExclusiveServiceEdge {
    cursor
    node {
      ...ClientExclusiveService
    }
  }
`;
/**
 * type ClientExclusiveServiceConnection {
edges: [ClientExclusiveServiceEdge!]
pageInfo: ClientExclusiveServicePageInfo
}
 */
const clientExclusiveServiceConnectionFragment = gql`
  ${clientExclusiveServiceEdgeFragment}
  fragment ClientExclusiveServiceConnection on ClientExclusiveServiceConnection {
    edges {
      ...ClientExclusiveServiceEdge
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
`;
/**
 * type GetAllClientExclusiveServiceResponse {
page: ClientExclusiveServiceConnection!
pageData: PageData
}
 */
export const getAllClientExclusiveServiceResponseFragment = gql`
  ${clientExclusiveServiceConnectionFragment}
  fragment GetAllClientExclusiveServiceResponse on GetAllClientExclusiveServiceResponse {
    page {
      ...ClientExclusiveServiceConnection
    }
    pageData {
      count
      limit
      offset
    }
  }
`;
/**
 * getAllClientExclusiveServices(
after: String
before: String
first: Int
last: Int
): GetAllClientExclusiveServiceResponse!
 */
const getAllClientExclusiveServicesQuery = gql`
  ${getAllClientExclusiveServiceResponseFragment}
  query GetAllClientExclusiveServices(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllClientExclusiveServices(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...GetAllClientExclusiveServiceResponse
    }
  }
`;

/**
 * 
 getAllClientExclusiveServicesForDistributor(
before: String
after: String
first: Int
last: Int
distributorId: String!
): GetAllClientExclusiveServiceResponse!
 */
const getAllClientExclusiveServicesForDistributor = gql`
  ${getAllClientExclusiveServiceResponseFragment}
  query GetAllClientExclusiveServicesForDistributor(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $distributorId: String!
  ) {
    getAllClientExclusiveServicesForDistributor(
      before: $before
      after: $after
      first: $first
      last: $last
      distributorId: $distributorId
    ) {
      ...GetAllClientExclusiveServiceResponse
    }
  }
`;
export const useLazyGetAllClientExclusiveServicesForDistributor = (
  variables: GetAllClientExclusiveServicesForDistributorVariables
) =>
  useLazyQuery<
    GetAllClientExclusiveServicesForDistributor,
    GetAllClientExclusiveServicesForDistributorVariables
  >(getAllClientExclusiveServicesForDistributor, {
    client: clientSimulator,
    variables,
  });

export const useLazyGetAllClientExclusiveServicesQuery = (
  variables: GetAllClientExclusiveServicesVariables
) =>
  useLazyQuery<
    GetAllClientExclusiveServices,
    GetAllClientExclusiveServicesVariables
  >(getAllClientExclusiveServicesQuery, {
    variables,
    client: clientSimulator,
  });
/**
 * getSpecificClientExclusiveService(
id: ID!
): ClientExclusiveService
 */
const getSpecificClientExclusiveServiceQuery = gql`
  ${clientExclusiveServiceFragment}
  query GetSpecificClientExclusiveService($id: ID!) {
    getSpecificClientExclusiveService(id: $id) {
      ...ClientExclusiveService
    }
  }
`;

export const useGetSpecificClientExclusiveServiceQuery = (
  variables: GetSpecificClientExclusiveServiceVariables
) => {
  const notify = useNotifier();
  return useQuery<
    GetSpecificClientExclusiveService,
    GetSpecificClientExclusiveServiceVariables
  >(getSpecificClientExclusiveServiceQuery, {
    variables,
    client: clientSimulator,
    onError: (error) => {
      handleGQLErrors(notify, error);
    },
  });
};
