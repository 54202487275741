import Button from '@components/CustomButtons/Button';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { ButtonGroup } from '@material-ui/core';
import React, { useContext } from 'react';
import { AssetAccountContext } from '../../context/AssetAccountProvider';
import { useNavigate } from 'react-router-dom';

interface IProps {
  assetAccountId?: string;
  active: boolean;
}

const CloseAccount: React.FC<IProps> = ({ active }) => {
  const {  resetItemContext } = useContext(AssetAccountContext);
  const navigate = useNavigate()


  return (
    <GridContainer>
      <GridItem md={12}>
        <ButtonGroup disableElevation color='primary' style={{ marginTop: 30 }}>
          <Button
            color='primary'
            onClick={() => {
              resetItemContext()
              navigate('/admin/asset-accounts')
            }}
          >
            Close Account
          </Button>
        </ButtonGroup>
      </GridItem>
    </GridContainer>
  );
};

export default CloseAccount;
