import { gql } from '@apollo/client';

/**
 * Mutation.createBroker(
createBrokerInput: CreateBrokerInput!
): Broker!
 */
export const createBrokerMutation = gql`
  mutation CreateBroker($createBrokerInput: CreateMQTTBrokerInput!) {
    createBroker(createBrokerInput: $createBrokerInput) {
      _id
    }
  }
`;

/**
 * deleteBroker(
BrokerId: String!
): String!
 */
export const deleteBrokerMutation = gql`
  mutation DeleteBroker($BrokerId: String!) {
    deleteBroker(BrokerId: $BrokerId)
  }
`;

/**
 * Mutation.updateBroker(
updateBrokerInput: UpdateBrokerInput!
): Broker!
 */
export const updateBrokerMutation = gql`
  mutation UpdateBroker($updateBrokerInput: UpdateBrokerInput!) {
    updateBroker(updateBrokerInput: $updateBrokerInput) {
      _id
    }
  }
`;

// thing broker
/**
 * Mutation.createThingBroker(
createBrokerInput: CreateBrokerInput!
): Broker!
 */
export const createThingBrokerMutation = gql`
  mutation CreateThingBroker($createBrokerInput: CreateBrokerInput!) {
    createThingBroker(createBrokerInput: $createBrokerInput) {
      _id
    }
  }
`;

/**
 * Mutation.deleteThingBroker(
brokerId: String!
): String!
 */
export const deleteThingBrokerMutation = gql`
  mutation DeleteThingBroker($brokerId: String!) {
    deleteThingBroker(brokerId: $brokerId)
  }
`;

/**
 * updateThingBroker(
updateBrokerInput: UpdateBrokerInput!
): Broker!
*/
export const updateThingBrokerMutation = gql`
  mutation UpdateThingBroker($updateBrokerInput: UpdateBrokerInput!) {
    updateThingBroker(updateBrokerInput: $updateBrokerInput) {
      _id
    }
  }
`;