import CardWrapper from '@components/Card/CardWrapper';
import Button from '@components/CustomButtons/Button';
import OVSForm from '@components/Form/OVSForm';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Grid } from '@material-ui/core';
import { FormikProps } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useLazyDistributor } from '../../distributor/hooks/useDistributor';
import { AssignDistributorClientExclusiveServiceTypeInput } from '../../types/clientServiceGlobalTypes';
import { clientExclusiveServiceTypesOptions } from '../constants';
import useServicer from '../hooks/useServicer';

interface IProps {
	formBag: FormikProps<AssignDistributorClientExclusiveServiceTypeInput>;
	loading: boolean
}

const AssignDistributorForm: React.FC<IProps> = ({ formBag, loading }) => {

	const {
		options: distributorOptions,
		data: distributors,
		search: searchDistributor,
	} = useLazyDistributor()



	const { errors, setFieldValue, values } = formBag

	const { options: serviceOptions, getItems, search: searchServicer } = useServicer()

	useEffect(() => {
		getItems()
		// eslint-disable-next-line
	}, [])


	const formFieldsData = useMemo(
		() => [
			{
				name: '',
				fields: [
					{
						md: 6,
						type: 'select-async',
						name: 'Servicer',
						label: 'servicerId',
						error: errors.servicerId,
						options: serviceOptions?.map(it => ({_id: it._id || '', name: it.name || ''})) || [],
						onChange: (e: any) => {
							setFieldValue('servicerId', e);
						},
						value: values.servicerId || '',
						searchPromise: searchServicer
					},
					{
						md: 6,
						type: 'select-async',
						name: 'distributorId',
						label: 'Distributor',
						options: distributorOptions,
						onChange: (e: any) => {
							setFieldValue('distributorId', e);
						},
						value: values.distributorId || '',
						error: errors.distributorId,
						searchPromise: searchDistributor as (
							arg: string
						) => Promise<{ value: string; label: string }[]>,
					},

					{
						md: 6,
						type: 'select',
						name: 'Service Type',
						label: 'serviceType',
						error: errors.serviceType,
						options: clientExclusiveServiceTypesOptions,
						onChange: (e: any) => {
							setFieldValue('serviceType', e.target.value);
						},
						value: values.serviceType || '',
					},
				],
			},
		], // eslint-disable-next-line
		[distributorOptions, values, distributors, serviceOptions])
	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<CardWrapper>
					<GoBackHeader />
					<GridContainer>
						<OVSForm formFieldsData={formFieldsData} />
					</GridContainer>
					<Grid>
						<Button
							color='primary'
							onClick={() => {
								formBag.submitForm();
							}}
							disabled={loading}
						>
							Save
						</Button>
					</Grid>

				</CardWrapper>
			</GridItem>
		</GridContainer>
	)
}

export default AssignDistributorForm