import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import { Row } from 'react-table';
import ItemTableActions from '../components/CodeGeneratorTableActions';

export const itemTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <ItemTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: 'OEM Item ID',
      accessor: 'node.oemItemID',
    },
    {
      Header: 'Seller Item ID',
      accessor: 'node.sellerItemID',
    },
    {
      Header: ' Description',
      accessor: 'node.description',
      Cell: DescriptionCell,
    },
    {
      Header: 'Actor Types',
      accessor: 'node.type',
    },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt', Cell: DateCell,
    },
    {
      Header: 'Profile',
      accessor: 'node.profile',
    },
    {
      Header: 'ID Type',
      accessor: 'node.idType',
    },

    {
      Header: 'Seller ID',
      accessor: 'node.sellerID',
    },


    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt', Cell: DateCell,
    },
    { Header: 'ID', accessor: 'node._id' },
  ],
});
