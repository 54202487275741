import React, { useContext, useEffect, useState } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData, addLastCreditAmountColumn } from '../constants/itemTableData';
import CardWrapper from '@components/Card/CardWrapper';
import { itemRoutes } from '../constants';
import { AssetAccountContext } from '../context/AssetAccountProvider';
import useServicerPermission from 'hooks/useServicerPermission';
import { useAuth } from 'admin/auth/context/AuthProvider';

interface IRTable { }

const ItemPage: React.FC<IRTable> = () => {
  const {
    items,
    tableActions,
    getItems,
    loading,
    paging,
    goTo,
    setSearchQuery,
    setPageLimit,
    clientError,
    searchQuery
  } = useContext(
    AssetAccountContext
  );
  const [updatedItem, setUpdatedItem] = useState<any>([])
  useServicerPermission({ loading, clientError })
  const { isSuperAdmin, isDistributorStaff } = useAuth()
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    let updatedItem = addLastCreditAmountColumn(items)
    setUpdatedItem(updatedItem)
  }, [items]);

  const columnsToChange = [
    'AccountNumber'
  ]

  const columns = itemTableData().header?.map((item: any) => {
    if (isSuperAdmin && columnsToChange.includes(item.Header)) {
      return {
        ...item,  // Keep all other properties of the item
        Header: 'SellerItemId',  // Change the Header property
      };
    }
    return item; 
  });


  if (isSuperAdmin) {
    // Find the index of the element with Header "ItemOemId"
    const currentIndex = itemTableData().header?.findIndex((item: any) => item.Header === 'ItemOemItemID');
    let temp = columns[currentIndex]
    columns.splice(currentIndex, 1);
    columns.splice(4, 0, temp);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              columns as Column[]
            }
            data={updatedItem}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={itemRoutes.add}
            addItemBtnText='Add'
            tableActions={tableActions}
            hiddenColumns={['node.payHistory', 'node.asset.codeGenerator.codeHistory']}
            goTo={goTo}
            paging={paging}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
            isDistributorStaff={isDistributorStaff}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemPage;
