import { IAdvancedQuery } from '@components/ReactTable/TableToolBar/AdvancedSearch/types';
import { AxiosResponse } from 'axios';

export const generateQueryParams = (queries: IAdvancedQuery[]) => {
  let str = '';
  [...queries]
    .filter((item) => item.column !== '')
    .filter((item) => item.value !== '')
    .filter((item) => item.hidden !== true)
    .forEach((item) => (str += `${item.column}=${item.value}&`));
  return str;
};
export function getParameterByName(name: string) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export enum LegacyProductClassBases {
  AH03 = '03AH',
  AH06 = '06AH',
  AH12 = '12AH',
  AH18 = '18AH',
  AH24 = '24AH',
  AH30 = '30AH',
  AH36 = '36AH',
  AH42 = '42AH',
  AH48 = '48AH',
  BS1K = 'BS1K',
  BS2K = 'BS2K',
  LA1K = 'LA1K',
  LA2K = 'LA2K',
  TV24 = 'TV24',
  TV32 = 'TV32',
  C300 = 'C300',
  MAT0 = 'MAT0',
  MAT1 = 'MAT1',
  PART = 'PART',
  PACK = 'PACK',
  MISC = 'MISC',
  TEST = 'TEST',
}

export const productBaseOptions = Object.keys(LegacyProductClassBases).map(
  (key) => ({
    name: key, // @ts-ignore
    _id: LegacyProductClassBases[key],
  })
);

export const resolveQID = (oemSN: string) => {
  if (
    oemSN.includes('03AH') ||
    oemSN.includes('06AH') ||
    oemSN.includes('09AH') ||
    oemSN.includes('12AH') ||
    oemSN.includes('32AH') ||
    oemSN.includes('36AH')
  ) {
    return 'PT000235';
  }
  if (oemSN.includes('M400')) {
    return 'PT000293';
  }
  return '';
};

export const delayMyPromise = (myPromise: any, myDelay: number) => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      return resolve(myPromise);
    }, myDelay);
  });
};

export function sleep(milliseconds: number) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export const BATCH_CODES = {
  batch_code: 'batch_code',
  initialization: 'initialization',
  assign: 'assign',
  provision: 'provision',
};

export const makeQuery = (
  params: string,
  actions: {
    getLegacy: (params: string) => Promise<AxiosResponse<any>>;
    listLegacy: any;
    listLegacySuccess: any;
    listLegacyFail: any;
  },
  dispatch: any
) => {
  dispatch(actions.listLegacy({}));
  actions
    .getLegacy(params)
    .then((res) => {
      dispatch(actions.listLegacySuccess(res.data));
    })
    .catch((err) => {
      dispatch(actions.listLegacyFail(err));
    });
};

export const productQIDOptions = [
  {
    "label": "Omnivoltaic ovCamp 24Ah",
    "value": "PT004627"
  }, 
  {
    "label": "Omnivoltaic LUMN M300 Series",
    "value": "PT004053"
  },
  {
    "label": "Omnivoltaic ovCatch C500",
    "value": "PT003996"
  },
  {
    "label": "LUMN HOME HS20 Series",
    "value": "PT003997"
  },
  {
    "label": "LUMN HOME HS40 Series",
    "value": "PT003998"
  },
  {
    value: 'PT001730',
    label: 'OvCamp Ov115',
  },
  {
    value: 'PT001731',
    label: 'OvCamp Ov40',
  },
  {
    value: 'PT001727',
    label: 'OvCamp SW460',
  },
  {
    value: 'PT001729',
    label: 'OvCamp SW115',
  },
  {
    value: 'PT001626',
    label: 'OvCamp SW155',
  },
  {
    value: 'PT001624',
    label: 'OvCamp SW40',
  },
  {
    value: 'PT001625',
    label: 'OvCamp SW80',
  },
  {
    value: 'PT001732',
    label: 'OvCamp SW80L',
  },
  {
    value: 'PT001628',
    label: 'OvLumn SW20',
  },
  {
    value: 'PT001619',
    label: 'Omnivoltaic Camp 3Ah',
  },
  {
    value: 'PT001620',
    label: 'Omnivoltaic Camp 6Ah',
  },
  {
    value: 'PT001728',
    label: 'Omnivoltaic Camp 9Ah',
  },
  {
    value: 'PT001621',
    label: 'Omnivoltaic Camp 12Ah',
  },
  {
    value: 'PT001879',
    label: 'Omnivoltaic Camp 18Ah',
  },
  {
    value: 'PT001880',
    label: 'Omnivoltaic Camp 42Ah',
  },
  {
    value: 'PT001881',
    label: 'Omnivoltaic Camp 48Ah24V',
  },
  {
    value: 'PT001622',
    label: 'Omnivoltaic Camp 36Ah',
  },
  {
    value: 'PT001623',
    label: 'Omnivoltaic Lumn M400',
  },
  {
    value: 'PT001882',
    label: 'Omnivoltaic Lumn M600',
  },
  {
    value: 'PT001883',
    label: 'Omnivoltaic Lumn L500',
  },
  {
    value: 'PT001884',
    label: 'Omnivoltaic Lumn L300',
  },
  {
    value: 'PT001885',
    label: 'Omnivoltaic Lumn L190',
  },
  {
    value: 'PT001886',
    label: 'Omnivoltaic Catch C300',
  },
  {
    value: 'PT005546',
    label: 'Inverter 24V',
  },
  {
    value: 'PT005547',
    label: 'Inverter 48V',
  },
  {
    value: 'PT005548',
    label: 'EBike VCU',
  }, 
  {
    value: 'PT005557',
    label: 'HSBat24'
  },
  {
    value: 'PT005558',
    label: 'HSBat48'
  },
  {
    value: "PT005615",
    label: "OASIS Series"
  }
];
