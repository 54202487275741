import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/itemTableData';
import CardWrapper from '@components/Card/CardWrapper';
import { itemRoutes } from '../constants';
import { LoginUserContext } from '../context/LoginUserContext';

interface IRTable { }

const ItemPage: React.FC<IRTable> = () => {
  const { items, tableActions, getItems, loading } = useContext(LoginUserContext);

  useEffect(() => {
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              itemTableData().header as Column[]
            }
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={itemRoutes.add}
            addItemBtnText='Add'
            tableActions={tableActions}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemPage;
