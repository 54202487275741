import { OrgTypes } from '../../types/clientServiceGlobalTypes';

export const itemFields = {
  contactRole: 'contactRole',
  orgAddress: 'orgAddress',
  orgDescription: 'orgDescription',
  orgName: 'orgName',
  orgProfile: 'orgProfile',
  orgType: 'orgType',
  city: 'orgAddress.city',
  country: 'orgAddress.country',
  postcode: 'orgAddress.postcode',
  srpc: 'orgAddress.srpc',
  street: 'orgAddress.street',
  unit: 'orgAddress.unit',
  inputAddressLongitude: 'orgAddress.locationAddressDto.inputAddressLongitude',
  inputAddressLatitude: 'orgAddress.locationAddressDto.inputAddressLatitude',
};

export const defaultAddress = {
  city: '',
  country: '',
  locationAddressDto: {
    inputAddressLatitude: 0,
    inputAddressLongitude: 0,
  },
  postcode: '',
  srpc: '',
  street: '',
  unit: '',
};

export const defaultOrganization = {
  orgId: '',
  contactRole: '',
  orgAddress: defaultAddress,
  orgDescription: '',
  orgName: '',
  orgProfile: '',
  orgType: OrgTypes.DISTRIBUTOR,
};

const url = 'organizations';
export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
