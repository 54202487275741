import { gql } from "@apollo/client";
import { itemBatchFragment } from "./queries";

/**
 * mutation {
  createItemBatch(createItemBatchInput: {
    actorTypes: "",
    actionScope: "",
    batchNumber: ""
  })
}
 */
export const createItemBatchMutation = gql`
  ${itemBatchFragment}
  mutation CreateItemBatch($createItemBatchInput: CreateItemBatchInput!) {
    createItemBatch(createItemBatchInput: $createItemBatchInput) {
      ...ItemBatch
    }
  }
`;

/**
 * deleteItemBatch(
itemBatchId: String!
): String!
 */
export const deleteItemBatchMutation = gql`
  mutation DeleteItemBatch($itemBatchId: String!) {
    deleteItemBatch(itemBatchId: $itemBatchId)
  }
`;

/**
 * updateItemBatch(
updateItemBatchInput: UpdateItemBatchInput!
): ItemBatch!
 */
export const updateItemBatchMutation = gql`
  ${itemBatchFragment}
  mutation UpdateItemBatch($updateItemBatchInput: UpdateItemBatchInput!) {
    updateItemBatch(updateItemBatchInput: $updateItemBatchInput) {
      ...ItemBatch
    }
  }
`;

export const provisionBatchMutation = gql`
  mutation ProvisionBatch($qid: String!, $batchId: String!) {
    provisionBatch(provisionInput: { qid: $qid, batchId: $batchId }) {
      error
      itemsProvisioned {
        qid
        batchId
      }
    }
  }
`;

/**
 * Mutation.batchCode(
batchCodeInput: BatchCodeInput!
): BatchCodeResponse!
 */
export const batchCodeMutation = gql`
  # Write your query or mutation here
  mutation BatchCode($batchId: ID!, $codeDays: Int!) {
    batchCode(batchCodeInput: { batchId: $batchId, codeDays: $codeDays }) {
      batchCodes {
        codeGenerator
        itemId
      }
    }
  }
`;

/**
batchInitialize(
batchInitializeInput: BatchInitializeInput!
):
 */
export const batchInitializeMutation = gql`
  mutation BatchInitialize($batchInitializeInput: BatchInitializeInput!) {
    batchInitialize(batchInitializeInput: $batchInitializeInput) {
      _id
    }
  }
`;

/**
 reassignBatchItemsFromItemFleet(
reassignBatchItemsFromItemFleetInput: ReassignBatchItemsFromItemFleetInput!
): Success!
 */

export const reassignBatchItemsFromItemFleetMutation = gql`
mutation reassignBatchItemsFromItemFleet($itemFleetId: ID!, $itemBatchId: ID!, $isHolding: Boolean) {
  reassignBatchItemsFromItemFleet(reassignBatchItemsFromItemFleetInput: { itemFleetId: $itemFleetId, itemBatchId: $itemBatchId, isHolding: $isHolding })
   {
    status
    message
  }
}
`;


export const assignItemBatchToItemFleetMutation =gql` 
mutation assignItemBatchToItemFleet($itemFleetId: ID!, $itemBatchId: ID!) {
  assignItemBatchToItemFleet(assignItemBatchToItemFleetInput: {itemFleetId: $itemFleetId, itemBatchId: $itemBatchId}) 
  {
    _id
    fleetName
  }
}
`
