import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { itemRoutes } from '../constants';
import useDeleteEvent from '../hooks/useDeleteEvent';
import { EventContext } from '../context/EventProvider';
import ActionModal from '@components/Modal/ActionModal';

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const history = useNavigate();
  const { refetchItems: refetch } = useContext(EventContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { mutation: deleteItem } = useDeleteEvent({
    onComplete: () => {
      refetch && refetch();
      setIsModalOpen(false);
    },
  });
  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        id='expander'
        onClick={() => {
          history(itemRoutes.getEditLink(row.values['node._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deleteItem({
            variables: { EventId: row.values['node._id'] },
          })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this event?</div>
      </ActionModal>
    </div>
  );
};

export default TableActions;
