import { IProps } from "@components/messages/types";
import React, { createContext, FC, useMemo, useState } from "react";
import { GetAllClientItems_getAllClientItems_page_edges } from "../../item/types/GetAllClientItems";

export interface ICheckInOutContext {
	items: GetAllClientItems_getAllClientItems_page_edges[]
	setItems: (items: GetAllClientItems_getAllClientItems_page_edges[]) => void
}

export const CheckInOutContext = createContext<ICheckInOutContext>({} as ICheckInOutContext)

const CheckInOutProvider: FC<IProps> = ({ children }) => {
	const [items, setItems] = useState([] as GetAllClientItems_getAllClientItems_page_edges[])

	const value = useMemo(() => ({ items, setItems }), [items])

	return (
		<CheckInOutContext.Provider value={value} >
			{children}
		</CheckInOutContext.Provider>
	)

}

export default CheckInOutProvider