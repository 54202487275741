import { IProps } from '@components/messages/types';
import React from 'react';
import BrokerProvider from '../broker/context/BrokerProvider';
import EventProvider from '../events/context/EventProvider';
import PublisherProvider from '../publisher/context/PublisherProvider';
import SignalProvider from '../signal/context/SignalProvider';
import ItemEventProvider from '../itemEvents/context/EventProvider';
const EventAppProvider: React.FC<IProps> = ({ children }) => (
  <SignalProvider>
    <BrokerProvider>
      <PublisherProvider>
        <ItemEventProvider>
        <EventProvider>{children}</EventProvider>
        </ItemEventProvider>
      </PublisherProvider>
    </BrokerProvider>
  </SignalProvider>
);

export default EventAppProvider;
