import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import _ from 'lodash';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useContext, useEffect } from 'react';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';

import { IProductModel as ItemType } from '../types';
import OVSForm from '@components/Form/OVSForm';
import {LegacyFamilyContext } from '../../LegacyFamily/context/LegacyFamilyContext';
import { IProductFamily } from '../../LegacyFamily/types';

const LegacyForm: React.FC<{
  formBag: FormikProps<ItemType>;
  disableLoginButton: boolean;
  actionButtonName?: string;
}> = (props) => {
  const { formBag, disableLoginButton, actionButtonName = 'Add Items' } = props;
  const { getItems, items } = useContext(LegacyFamilyContext);

  useEffect(() => {
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);

  const formFieldsData = React.useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            type: 'text',
            name: itemFields.productModelName,
            label: 'Product Model Name',
          },
          {
            type: 'textarea',
            name: itemFields.productModelDescription,
            label: 'Description',
          },
          {
            type: 'select',
            name: itemFields.productFamilyID,
            label: 'Product Family',
            options: items.map((it: IProductFamily) => ({
              _id: it.productFamilyID || '',
              name: it.productFamilyName,
            })),
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.productFamilyID, e.target.value);
            },
            value: _.get(formBag.values, itemFields.productFamilyID) || '',
          },
          {
            type: 'number',
            name: itemFields.quantityInStock,
            label: 'Quantity In Stock',
          },
          {
            type: 'number',
            name: itemFields.productModelCost,
            label: 'Product Model Cost',
          },
          {
            type: 'text',
            name: itemFields.productVendor,
            label: 'Product Vendor',
          },
          {
            type: 'text',
            name: itemFields.image,
            label: 'Image URL',
          },
        ],
      },
    ], // eslint-disable-next-line
    [formBag.values, items]
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer>
                <OVSForm formFieldsData={formFieldsData} />
              </GridContainer>
            </GridItem>
          </GridContainer>

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
                formBag.validateForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default LegacyForm;
