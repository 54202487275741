import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import clientAccount from '../../utils/clientAccount';
import { pairAssetAccountMutation } from '../mutations';
import {
  PairAssetAccount,
  PairAssetAccountVariables,
  PairAssetAccount_pairAssetAccount,
} from '../types/PairAssetAccount';

const usePairAssetAccount = ({
  onComplete,
}: {
  onComplete?: (data: PairAssetAccount_pairAssetAccount) => void;
}) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    PairAssetAccount,
    PairAssetAccountVariables
  >(pairAssetAccountMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete(data?.pairAssetAccount);
      } else {
        notify({ text: 'Accounted paired successfully', status: 'success' });
      }
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default usePairAssetAccount;
