import { IProps } from '@components/messages/types';
import { getToken } from 'admin/auth/utils';
import { LoginUsersEdge_node } from 'admin/distributor/types/LoginUsersEdge';
import React from 'react';

interface ISettingsContext {
  user: LoginUsersEdge_node;
  setUser: (user: LoginUsersEdge_node) => void;
  preUserState: { email: string; firstName: string; lastName: string };
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const SettingsContext = React.createContext<ISettingsContext>(
  {} as ISettingsContext
);

export const SettingsProvider: React.FC<IProps> = ({ children }) => {
  const [user, setUser] = React.useState({} as LoginUsersEdge_node);
  const [isLoading, setIsLoading] = React.useState(false);
  const userPref = getToken().userPref;
  const [preUserState] = React.useState({
    email: userPref?.email,
    firstName: userPref?.firstName,
    lastName: userPref?.lastName,
  });

  const value = React.useMemo(
    () => ({
      user,
      setUser,
      isLoading,
      setIsLoading,
      preUserState,
    }),
    [user, isLoading, preUserState]
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};
