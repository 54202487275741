import * as React from 'react';
import _ from 'lodash';
import { api } from '../../services/api';
import { ProvisionDataContext } from '../context/ProvisionDataContext';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { CircularProgress, Tooltip } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CSVReader from '@components/CSVUploader/CSVReader';
import { useStateCallback } from 'utils';
import BackDrop from '@components/Loaders/BackDrop';
import CustomInput from '@components/CustomInput/CustomInput';
import Button from '@components/CustomButtons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/epics/userPrefEpics';
import { ProvisionActions } from 'store/actions/provisionActions';
import { Row } from 'react-table';
// import ItemProvider, {
//   ItemContext,
// } from 'apps/things/app/item/context/ItemProvider';
// import { useMutation } from '@apollo/client';
// import {
//   UpdateItem,
//   UpdateItemVariables,
// } from 'apps/things/app/item/types/UpdateItem';
// import { updateItemMutation } from 'apps/things/app/item/mutations';
// import clientSimulator from 'utils/clientSimulator';
// import { handleGQLErrors } from 'utils/gqlErrors';
// import useNotifier from 'hooks/useNotifier';

const BatchCSVUploader = () => {
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: string) =>
      header.toLowerCase().replace(/\W/g, '_'),
  };

  const { setData } = React.useContext(ProvisionDataContext);

  const dispatch = useDispatch();

  const [loadingCSV, setLoadingCSV] = useStateCallback(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const [resolvedItems] = useStateCallback(0);
  const [batchNumbers, setBatchNumbers] = React.useState<string>('');

  const progress = useSelector((store: RootState) => store.provision.progress);
  const [percentageProgress, setPercentageProgress] = React.useState(0);

  // const { _fetchMore } = React.useContext(ItemContext);

  React.useEffect(() => {
    if (progress?.data?.loopIndex && progress?.data?.totalLoops)
      setPercentageProgress(
        parseInt(
          (
            (progress.data.loopIndex * 100) /
            progress.data.totalLoops
          ).toString()
        )
      ); // eslint-disable-next-line
  }, [progress]);

  const onStartLoad = () => { };

  const RenderLoadingProgress = () => {
    return (
      <div>
        <span>
          Resolving product item IDS. {parseInt(percentageProgress.toString())}%
          complete, {resolvedItems} of {totalItems}
        </span>
      </div>
    );
  };

  const getProducts = async (batchNumber: string) => {
    return api.post('products/batches', [batchNumber]);
  };



  const handleForce = async (csvData: any, fileInfo: any) => {
    const batch_numbers: { batchnumber: string; qid: string }[] = csvData;
    if (batch_numbers.length === 0) return;
    setData([]);
    setTotalItems(batch_numbers.length);
    let counter = 0;
    let newData: Row<{}>[] = [];
    for (const element of batch_numbers) {
      const { data } = await getProducts(element.batchnumber);

      newData.push(
        ..._.uniqBy(
          [
            ...data.map((item: any, index: number) => ({
              ...item,
              productitemid: item.id,
              hash_top: item.hash_top,
              hash_root: item.hash_root,
              otp_count: item.otp_count,
              hash_index: item.hash_index,
              productitemoem_sn: item.OEM_SN,
              productitempayg_sn: item.PAYG_SN,
              firmwareversion: item.firmwareVersion?.replace(',', ''),
              lifecyclestatus: item.lifeCycleStatus?.replace(',', ''),
              productbatchid: item.productBatchID,
              productbatchnumber: item.productBatchNumber?.replace(',', ''),
              productmodelname: item.productModelName?.replace(',', ''),
              codevalue: item.codeValue,
              index: index + 1,
              product_qid: element.qid,
            })),
          ],
          function (e: any) {
            return e.productitemoem_sn;
          }
        )
      );
      counter += 1;
      dispatch(
        ProvisionActions.setProgress({
          totalLoops: batch_numbers.length,
          loopIndex: counter,
        })
      );
    }

    setData(newData.map((item, index) => ({ ...item, index: index + 1 })));

    dispatch(ProvisionActions.setRawItems(newData));
    dispatch(
      ProvisionActions.setProgress({
        totalLoops: 0,
        loopIndex: 0,
      })
    );

    setLoadingCSV((prev: any) => false);
  };

  const handleDarkSideForce = (err: any) => { };

  return (
    <div style={{ paddingLeft: 44, paddingRight: 44 }}>
      <GridContainer className='container notice'>
        <BackDrop loading={loadingCSV}>
          <RenderLoadingProgress />
        </BackDrop>
        <GridItem xs={12} sm={6}>
          <div className='left-content'>
            <Tooltip id='Upload CSV' title='Migrate' placement='top'>
              <label
                htmlFor='csv-file'
                className='py-2 px-3 MuiButtonBase-root MuiIconButton-root border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-indigo-500 hover:border-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-800 transition duration-150 ease-in-out'
              >
                {loadingCSV ? (
                  <CircularProgress size={18} />
                ) : (
                  <>
                    <CloudUploadIcon color='inherit' />
                    &nbsp; Migrate
                  </>
                )}
              </label>
            </Tooltip>
            <CSVReader
              cssClass='csv-reader-input'
              label=''
              onFileLoaded={handleForce}
              onError={handleDarkSideForce}
              parserOptions={papaparseOptions}
              inputId='csv-file'
              inputStyle={{ color: 'black' }}
              onStartLoad={onStartLoad}
            />
          </div>
          <div>
            <p>.csv, .tsv spreadsheet accepted</p>
          </div>
          <div>OR</div>
          <p>Add a list of comma separated batch numbers</p>
          <div>
            <div>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: batchNumbers,
                  onChange: (e: React.FormEvent<HTMLInputElement>) => {
                    setBatchNumbers(e.currentTarget.value); // Set undefined to remove the filter entirely
                  },
                  placeholder: `Enter batch numbers e.g 03AH1809001, 03AH1811001`,
                  multiline: true,
                }}
              />
            </div>
            <Button
              size='sm'
              onClick={() =>
                handleForce(
                  batchNumbers
                    .split(',')
                    .map((item) => ({ batch_number: item.trim() })),
                  null
                )
              }
            >
              Migrate
            </Button>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <div className='content description'>
            You can upload any .csv, .tsv file with any set of columns as long
            as it has 1 record per row. The next step will allow you to preview
            your spreadsheet columns to the right data points. You'll be able to
            confirm the information before submitting.
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default BatchCSVUploader;
