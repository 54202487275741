import React from 'react';

interface IProps {
  value: string;
}

const CodeDaysCell: React.FC<IProps> = ({ value }) => {
  if (value?.toString() === '2192') {
    return <div>Reset code</div>;
  }
  if (value?.toString() === '1096') {
    return <div>Free code</div>;
  }
  return <div>{value}</div>;
};

export default CodeDaysCell;
