import { useMutation } from "@apollo/client";
import useNotifier from "hooks/useNotifier";
import {
  BatchInitialize,
  BatchInitializeVariables,
} from "../types/BatchInitialize";
import { batchInitializeMutation } from "../mutations";
import thingMakeClientSimulator from "utils/thingMakeSimulator";
import { handleGQLErrors } from "utils/gqlErrors";

const useBatchInitialization = (onComplete: (data: any) => void) => {
  const notify = useNotifier();
  const [create, options] = useMutation<
    BatchInitialize,
    BatchInitializeVariables
  >(batchInitializeMutation, {
    client: thingMakeClientSimulator,
    onCompleted: async (data: any) => {
      onComplete && onComplete(data);
    },
    onError: (error) => {
      handleGQLErrors(notify, error);
    },
  });
  return {
    create,
    options,
  };
};

export default useBatchInitialization;
