import * as _ from 'lodash';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { FieldArray, Form, FormikProps } from 'formik';
// @material-ui/icons

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import MUIButton from '@material-ui/core/Button';
import React, { useState } from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemSKUFields } from '../constants';
import { CreateItemSKUInput } from 'apps/things/app/types/thingsGlobalTypes';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import GattForm from './Gatt/GattForm';
import AddIcon from '@material-ui/icons/Add';
import Button from '@components/CustomButtons/Button';
import ActionModal from '@components/Modal/ActionModal';
import useItemSKU from '../hooks/useItemSKU';
import AsyncSeachableSelect from '@components/CustomSelect/AsyncSeachableSelect';
import useNotifier from 'hooks/useNotifier';

const ItemSKUForm: React.FC<{
  formBag: FormikProps<CreateItemSKUInput>;
  disableLoginButton: boolean;
  actionButtonName?: string;
  productBase?: string;
  isEdit?: boolean;
  itemSkuId?: string;
}> = (props) => {
  const {
    formBag,
    disableLoginButton,
    actionButtonName = 'Add Item SKU',
    isEdit = false,
    itemSkuId,
  } = props;

  const { addItemSKU } = itemSKUFields;
  const { values } = formBag;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { options, refetch, data } = useItemSKU();
  const [sku, setSKU] = useState('');
  const notify = useNotifier();

  const search = async (search: string) => {
    if (!refetch) {
      return options;
    }
    const refetchData = await refetch({ first: 20, skuName: search });
    const items = refetchData?.data?.getAllItemSKUs?.page?.edges?.map(
      (item) => {
        return {
          label: item.node?.skuName || '',
          value: item.node?._id || '',
        };
      }
    );
    return items as { value: string; label: string }[];
  };

  const setAttributesFromSKU = () => {
    const _itemSKU = _.find(data, (item) => {
      return item.node?._id === sku;
    });
    if (_itemSKU) {
      if (!formBag.values.gatt?.name) {
        formBag.setFieldValue(
          addItemSKU.gattName,
          _itemSKU?.node?.properties?.name
        );
      }
      formBag.setFieldValue(
        addItemSKU.gattAttribute,
        _itemSKU?.node?.properties?.attributes
      );
    }
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridHalfWrapper>
              <TextInput label='SKU Name' name={addItemSKU.skuName} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Product Base' name={addItemSKU.productBase} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='OEM Description'
                name={addItemSKU.oemDescription}
                multiline
              />
            </GridHalfWrapper>
          </GridContainer>

          <GridContainer>
            <GridItem xs={6}>
              <TextInput label='GATT Name' name={addItemSKU.gattName} />
            </GridItem>
            <GridItem sm={12}>
              <h6>
                Attributes &nbsp;
                <Button
                  size='sm'
                  color='transparent'
                  onClick={() => setIsModalOpen(true)}
                >
                  Copy from{' '}
                </Button>
              </h6>
            </GridItem>
          </GridContainer>
          <FieldArray
            name={addItemSKU.gattAttribute}
            render={(arrayHelpers) => {
              const remove = (index: number) => {
                arrayHelpers.remove(index);
              };
              const add = () => {
                arrayHelpers.push({
                  value: '',
                  prop: '',
                  meta: '',
                });
              };
              return (
                <>
                  {values.gatt?.attributes?.map((item, index) => {
                    return (
                      <GattForm
                        itemSkuId={itemSkuId}
                        index={index}
                        propValue={item.prop || ''}
                        remove={remove}
                        isEdit={isEdit}
                        key={index + '-gatt-form'}
                      />
                    );
                  })}
                  <Typography>
                    <Tooltip
                      id='tooltip-top'
                      title='Add an attribute'
                      placement='top'
                    >
                      <MUIButton
                        onClick={() => {
                          add();
                        }}
                      >
                        <AddIcon />
                      </MUIButton>
                    </Tooltip>
                  </Typography>
                </>
              );
            }}
          />

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
        <ActionModal
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          handleOnClickOkey={() => {
            if (!sku) {
              notify({ text: 'Please select an item SKU.', status: 'error' });
              return;
            }
            setIsModalOpen(false);
            setAttributesFromSKU();
          }}
          handleOnClickCancel={() => setIsModalOpen(false)}
          okText={'Select'}
          styles={{ textAlign: 'start' }}
        >
          <div>Select item SKU</div>
          <AsyncSeachableSelect
            onChange={(e: any) => {
              setSKU(e);
            }}
            placeholder='Select SKU'
            options={options}
            searchPromise={search}
            initialValue={{ value: '', label: sku }}
          />
        </ActionModal>
      </GridItem>
    </GridContainer>
  );
};

export default ItemSKUForm;
