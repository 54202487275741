import Papa from "papaparse";

export const useConvertJsonToCSV = () => {
  const handleDownload = ({
    jsonData,
    downloadFileName = "data.csv",
  }: {
    jsonData: any;
    downloadFileName: string;
  }) => {
    const convertToCSV = () => {
      const csv = Papa.unparse(jsonData);
      return csv;
    };
    const csv = convertToCSV();
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = downloadFileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return {
    handleDownload,
  };
};
