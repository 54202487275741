import { Checkbox, makeStyles } from '@material-ui/core';
import React from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import Check from '@material-ui/icons/Check';
import { Field, useFormikContext} from 'formik';
interface IProps {
  name: any
}

// @ts-ignore noqa:
const useStyles = makeStyles(styles);

const ToggleColumnCheckbox: React.FC<IProps> = ({
  name
}) => {
  const classes = useStyles();

  const { setFieldValue } = useFormikContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setFieldValue(name, newValue);
  };

  return (
    <Field name={name}>
      {({ field }:any) => (
        <Checkbox
          key={name}
          tabIndex={-1}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={field.value} // Bind to Formik field value
          onChange={handleChange}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      )}
    </Field>
  );
};

export default ToggleColumnCheckbox;


