import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.1';
import AppContext from './context';
import storeZ from './store';

const { store, persister } = storeZ();

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <BrowserRouter>
          <AppContext />          
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
  
);
