import CardWrapper from "@components/Card/CardWrapper";
import GridContainer from "@components/Grid/GridContainer";
import GridItem from "@components/Grid/GridItem";
import { Box, Typography } from "@material-ui/core";
import 'react-quill/dist/quill.snow.css';
import React from 'react'

function ErrorPage() {

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CardWrapper>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh",
                flexDirection: "column",
              }}
            >
              <Typography variant="h2" style={{ color: "black" }}>
                504
              </Typography>
              <Typography variant="h6" style={{ color: "black" }}>
                This page is taking way to long to load
              </Typography>
              <Typography variant="body1" style={{ color: "black" }}>
                Sorry about that. Please try refreshing and contact us if the
                problem persists.
              </Typography>
            </Box>
          </CardWrapper>
        </GridItem>
      </GridContainer>
    );
}

export default ErrorPage