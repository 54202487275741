import { legacyApi } from 'apps/migration/app/services/legacyApi';
import { ProductItemInput } from '../types';
import { legacyTypes } from './constants';
export class LegacyActions {
  static listLegacy(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY,
      payload: { data },
    };
  }

  static listLegacySuccess(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY_SUCCESS,
      payload: { data },
    };
  }
  static listLegacyFail(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY_FAIL,
      payload: { data },
    };
  }

  // create item
  static createLegacy(data: any) {
    return {
      type: legacyTypes.CREATE_LEGACY,
      payload: { data },
    };
  }

  static createLegacySuccess(data: any) {
    return {
      type: legacyTypes.CREATE_LEGACY_SUCCESS,
      payload: { data },
    };
  }

  static createLegacyFail(data: any) {
    return {
      type: legacyTypes.CREATE_LEGACY_FAIL,
      payload: { data },
    };
  }

  /// api requests
  static getLegacy(params: string) {
    return legacyApi.get(`products?${params}`);
  }

  static getOneItem(id: string) {
    return legacyApi.get(`products/items/${id}`);
  }

  static deleteItem(id: string) {
    return legacyApi.delete(`products/items/${id}`);
  }

  static updateLegacyItem(id: string, payload: ProductItemInput) {
    return legacyApi.put(`products/items/${id}`, payload);
  }

  static createLegacyRequest(payload: any) {
    return legacyApi.post(`products/items`, payload);
  }

  static getProtocolVersions() {
    return legacyApi.get(`protocol-versions`);
  }

  static getUnassignedItem(params: string) {
    return legacyApi.get(`products-unassigned?${params}`);
  }
}
