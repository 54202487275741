import gql from 'graphql-tag';
import { itemFragment } from '../item/queries';

import { coderDecoderFragment } from './queries';

export const initializeOpenTokenCodeGenMutation = gql`
  ${itemFragment}
  mutation InitializeOpenTokenCodeGen($initializeOpenTokenCodeGenInput: InitializeOpenTokenCodeGenInput!) {
    initializeOpenTokenCodeGen(initializeOpenTokenCodeGenInput: $initializeOpenTokenCodeGenInput) {
      ...Item
    }
  }`

export const updateOpenTokenCodeGenStateMutation = gql`
${coderDecoderFragment}
  mutation UpdateOpenTokenCodeGenState($updateTokenDataInput: UpdateTokenDataInput!) {
    updateTokenData(updateTokenDataInput: $updateTokenDataInput){
      ...CodeDecoder
    }
  }
  `
  export const updateOpenTokenCodeDecoderStateMutation = gql`
${coderDecoderFragment}
  mutation UpdateOpenTokenCodeDecoderState($updateOpenTokenDecoderInput: UpdateOpenTokenDecoderInput!) {
    updateOpenTokenDecoder(updateOpenTokenDecoderInput: $updateOpenTokenDecoderInput){
      ...CodeDecoder
    }
  }
  `