import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useContext, useEffect, useMemo } from 'react';

import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../../constants';
import OVSForm, { IFormFields } from '@components/Form/OVSForm';
import { QueryOrder, UpdateAvatarByItemIDInput } from '../../../types/thingsGlobalTypes';
import { ItemContext as Context } from '../../../item/context/ItemProvider';
import { PAGE_LIST_LIMIT } from '@constants';
import AttributeForm from './AttributeForm';
const ItemForm: React.FC<{
  formBag: FormikProps<UpdateAvatarByItemIDInput>;
  disableLoginButton: boolean;
}> = (props) => {
  const { formBag, disableLoginButton } = props;
  const { items, getItems, refetchItems: refetch } = useContext(Context);

  useEffect(() => {
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);

  const searchPromise = async (search: string) => {
    if (!refetch) {
      return Promise.resolve(
        [] as { label: string; value: string }[]
      );
    }
    const refetchData = await refetch({
      first: PAGE_LIST_LIMIT,
      queryorder: QueryOrder.DESC,
      search,
    });

    const items = refetchData?.data?.getAllItems?.page?.edges?.map(
      (item) => {
        return {
          label: item.node?.oemItemID || '',
          value: item.node?._id || '',
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  }

  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            type: 'select-async',
            name: 'shadow',
            label: 'Shadow',
            options: items.map((it) => ({
              _id: it?.node?._id,
              name: it?.node?.oemItemID,
            })),
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.shadow, e);
            },
            value: formBag.values.shadow || '',
            error: formBag.errors.shadow as string,
            searchPromise: searchPromise as (
              search: string
            ) => Promise<{ value: string; label: string }[]>
          },

          {
            type: 'text',
            name: itemFields.lastSub,
            label: 'lastSub',
            error: formBag.errors.lastSub as string,
          },
          {
            type: 'text',
            name: itemFields.lastPub,
            label: 'lastPub',
            error: formBag.errors.lastPub as string,
          },
          {
            type: 'date',
            name: itemFields.lastSync,
            label: 'lastSync',
            dateFormat: 'YYYY-MM-DD',
            value: formBag.values.lastSync,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.lastSync, e);
            },
            error: formBag.errors.lastSync as string,
          },
          {
            type: 'switch',
            name: itemFields.itemAlive,
            label: 'Generate a signal?',
            value: formBag.values.itemAlive,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.itemAlive, e.target.checked);
            },
            error: formBag.errors.itemAlive as string,
          },
        ],
      },
    ], // eslint-disable-next-line
    [formBag.values, items]
  );
  const { values } = formBag
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader />
        <Form>
          <GridContainer>
            <OVSForm formFieldsData={formFieldsData as IFormFields[]} />
            <GridItem xs={12}>
              <h6>Att</h6>
              <AttributeForm
                field={itemFields.att}
                values={values.att}
              />
            </GridItem>
            <GridItem xs={12}>
              <h6>CMD</h6>
              <AttributeForm
                field={itemFields.cmd}
                values={values.cmd}
              />
            </GridItem>
            <GridItem xs={12}>
              <h6>DIA</h6>
              <AttributeForm
                field={itemFields.dia}
                values={values.dia}
              />
            </GridItem>
            <GridItem xs={12}>
              <h6>DTA</h6>
              <AttributeForm
                field={itemFields.dta}
                values={values.dta}
              />
            </GridItem>
            <GridItem xs={12}>
              <h6>STS</h6>
              <AttributeForm
                field={itemFields.sts}
                values={values.sts}
              />
            </GridItem>
          </GridContainer>
          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
                console.warn(values);
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer >
  );
};

export default ItemForm;
