import { Grid, InputLabel } from "@material-ui/core";
import { Form, FormikProps } from "formik";
import moment from "moment";
// @material-ui/icons
import { DatePicker } from "@material-ui/pickers";
// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
import Button from "@components/CustomButtons/Button";
import React, { useState } from "react";
import TextInput from "@components/CustomInput/TextInput";
import GoBackHeader from "@components/GoBackHeader/GoBackHeader";
import { itemFields } from "../constants";
import GridHalfWrapper from "@components/Grid/GridHalfWrapper";

import { IProductBatch } from "../types";

const LegacyForm: React.FC<{
  formBag: FormikProps<IProductBatch>;
  disableLoginButton: boolean;
  actionButtonName?: string;
}> = (props) => {
  const { formBag, disableLoginButton, actionButtonName = "Add Items" } = props;

  const [_date, changeDate] = useState(new Date());

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer>
                <GridHalfWrapper>
                  <TextInput
                    label="productBatchNumber"
                    name="productBatchNumber"
                    value={formBag.values.productBatchNumber}
                  />
                </GridHalfWrapper>
                <GridHalfWrapper>
                  <TextInput
                    label="productBatchNotes"
                    name="productBatchNotes"
                    value={formBag.values.productBatchNotes}
                  />
                </GridHalfWrapper>
                <GridHalfWrapper>
                  <InputLabel htmlFor="simple-select" style={{ marginTop: 10 }}>
                    productBatchDate
                  </InputLabel>
                  <DatePicker
                    style={{ width: "100%" }}
                    autoOk
                    format="dd-mm-yy"
                    value={_date}
                    // @ts-ignore
                    onChange={(date) => {
                      const newDate = moment(date || "")?.format();
                      formBag.setFieldValue(
                        itemFields.addItem.productBatchDate,
                        newDate
                      );
                      changeDate(date || new Date());
                    }}
                  />
                </GridHalfWrapper>
                <GridHalfWrapper>
                  <TextInput
                    label="productBatchState"
                    name="productBatchState"
                    value={formBag.values.productBatchState}
                  />
                </GridHalfWrapper>
              </GridContainer>
            </GridItem>
          </GridContainer>

          <Grid>
            <Button
              color="primary"
              onClick={() => {
                formBag.submitForm();
                formBag.validateForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default LegacyForm;
