import React, { useEffect } from 'react';

import { useAuth } from 'admin/auth/context/AuthProvider';
import { useLazyGetAllAssetAccountsForCustomerQuery } from 'apps/accounts/app/assetAccount/queries';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import CardWrapper from '@components/Card/CardWrapper';
import OvsTable from '@components/ReactTable/OvsTable';
import { itemTableData } from 'apps/accounts/app/assetAccount/constants/itemTableData';
import { Column } from 'react-table';

interface IProp {
  customerId: string;
  customerName: string;
}
const AssetAccountTable: React.FC<IProp> = ({ customerId }) => {
  const { loggedInUserId } = useAuth();
  const [
    getItems,
    { loading, data },
  ] = useLazyGetAllAssetAccountsForCustomerQuery({
    customerId,
    clientId: loggedInUserId,
    first: 100,
  });
  useEffect(() => {
    getItems(); // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={itemTableData().header as Column[]}
            data={data?.getAllAssetAccountsForCustomer?.page?.edges || []}
            checkedIds={[]}
            tableActions={[]}
            isLoading={loading}
            addItemPageLink={'/'}
            addItemBtnText='Add'
            hideRightToolbar
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AssetAccountTable;
