import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import { GetAllSignals, GetAllSignalsVariables } from './types/GetAllSignals';
import {
  GetSpecificSignal,
  GetSpecificSignalVariables,
} from './types/GetSpecificSignal';

/**
 * type Signal {
_id: ID!
broker: Broker
jmp: JSON!
time: DateTime!
topic: String!
}
 */
const signalFragment = gql`
  fragment Signal on Signal {
    _id
    jmp
    time
    publisher {
      _id
      broker {
        _id
        createdAt
        deleteAt
        deleteStatus
        login
        name
        password
        port
        updatedAt
        url
      }
      createdAt
      deleteAt
      deleteStatus
      topic
      updatedAt
    }
  }
`;
/**
 * type SignalEdge {
cursor: String
node: Signal
}
 */
const signalEdgeFragment = gql`
  ${signalFragment}
  fragment SignalEdge on SignalEdge {
    cursor
    node {
      ...Signal
    }
  }
`;
/**
 * type SignalPageInfo {
endCursor: String
hasNextPage: Boolean!
hasPreviousPage: Boolean!
startCursor: String
}
 */
const signalPageInfoFragment = gql`
  fragment SignalPageInfo on SignalPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type SignalConnection {
edges: [SignalEdge!]
pageInfo: SignalPageInfo
}
 */
const signalConnectionFragment = gql`
  ${signalPageInfoFragment}
  ${signalEdgeFragment}
  fragment SignalConnection on SignalConnection {
    edges {
      ...SignalEdge
    }
    pageInfo {
      ...SignalPageInfo
    }
  }
`;
/**
 * type PageData {
count: Float!
limit: Float!
offset: Float!
}
 */
const pageDataFragment = gql`
  fragment PageData on PageData {
    count
    limit
    offset
  }
`;
/**
 * type GetAllSignalsResponse {
page: SignalConnection!
pageData: PageData
}
 */
const getAllSignalsResponseFragment = gql`
  ${pageDataFragment}
  ${signalConnectionFragment}
  fragment GetAllSignalsResponse on GetAllSignalsResponse {
    page {
      ...SignalConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * getAllSignals(
after: String
before: String
first: Int
last: Int
): GetAllSignalsResponse!
 */
const getAllSignalsQuery = gql`
  ${getAllSignalsResponseFragment}
  query GetAllSignals(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllSignals(after: $after, before: $before, first: $first, last: $last) {
      ...GetAllSignalsResponse
    }
  }
`;

/**
 * getSpecificSignal(
id: ID!
): Signal
 */
const getSpecificSignalQuery = gql`
  ${signalFragment}
  query GetSpecificSignal($id: ID!) {
    getSpecificSignal(id: $id) {
      ...Signal
    }
  }
`;

export const useGetSpecificSignalQuery = (
  variables: GetSpecificSignalVariables
) =>
  useQuery<GetSpecificSignal, GetSpecificSignalVariables>(
    getSpecificSignalQuery,
    {
      client: clientAccount,
      variables,
    }
  );

export const useGetAllSignalsQuery = (variables: GetAllSignalsVariables) =>
  useQuery<GetAllSignals, GetAllSignalsVariables>(getAllSignalsQuery, {
    client: clientAccount,
    variables,
  });

export const useLazyGetAllSignalsQuery = (variables: GetAllSignalsVariables) =>
  useLazyQuery<GetAllSignals, GetAllSignalsVariables>(getAllSignalsQuery, {
    client: clientAccount,
    variables,
  });
