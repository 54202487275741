import { ApolloQueryResult, QueryResult } from "@apollo/client";
import { IProps } from "@components/messages/types";
import useGetDistributorCodeEvents from "apps/accounts/app/assetAccount/hooks/useGetAllCodeEventsByDistributorQueries";
import useOVSPagination from "hooks/useOVSPagination";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CodeGenerator_codeHistory } from "../../codeGenerator/types/CodeGenerator";
import {
  GetAllCodeEventsForSpecificItemByDistributor,
  GetAllCodeEventsForSpecificItemByDistributorVariables,
} from "../types/GetAllCodeEventsForSpecificItemByDistributor";
import * as _ from "lodash";

export interface ICodeEventContext {
  codeEvents: CodeGenerator_codeHistory[];
  setCodeEvents: (codeEvents: CodeGenerator_codeHistory[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  resetContext: () => void;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  getItems: ({
    variables,
  }: {
    variables: GetAllCodeEventsForSpecificItemByDistributorVariables;
  }) =>
    | Promise<
      QueryResult<
        GetAllCodeEventsForSpecificItemByDistributor,
        GetAllCodeEventsForSpecificItemByDistributorVariables
      >
    >
    | undefined;
  refetch?: (
    variables?:
      | Partial<GetAllCodeEventsForSpecificItemByDistributorVariables>
      | undefined
  ) => Promise<ApolloQueryResult<GetAllCodeEventsForSpecificItemByDistributor>>;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  itemId: string;
  setItemId: (itemID: string) => void;
}

export const CodeEventContext = React.createContext<ICodeEventContext>(
  {} as ICodeEventContext
);

const CodeEventProvider: React.FC<IProps> = ({ children }) => {
  const [codeEvents, setCodeEvents] = useState(
    [] as CodeGenerator_codeHistory[]
  );
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [itemId, setItemId] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { initPagAction, setPaging, paging, pageListLimit } =
    useOVSPagination();


  const {
    getItems,
    loading: loadingCodeEvents,
    refetch,
    data,
    includeDistributorID,
    loggedInUserId,
    fetchMore,
  } = useGetDistributorCodeEvents({
    itemId,
    autoload: false,
  });

  const resetContext = () => {
    setLoading(false);
    setCodeEvents([]);
  };


  const getVariables = () => {
    let variables: GetAllCodeEventsForSpecificItemByDistributorVariables = {
      itemId,
      first: pageListLimit,
    };

    if (includeDistributorID) {
      variables.distributorId = loggedInUserId;
    }
    return variables;
  };

  useEffect(() => {
    if (!itemId) return;
    const variables = getVariables();
    getItems({
      variables,
    });
    // eslint-disable-next-line
  }, [itemId]);



  useEffect(() => {
    if (data) {
      setCodeEvents(
        _.map(
          _.get(
            data,
            "getAllCodeEventsForSpecificItemByDistributor.page.edges",
            []
          ),
          (it) => it.node
        ) as CodeGenerator_codeHistory[]
      );

      setPaging({
        ...data?.getAllCodeEventsForSpecificItemByDistributor?.pageData,
        ...data?.getAllCodeEventsForSpecificItemByDistributor?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore || !itemId) return;

    let variables: GetAllCodeEventsForSpecificItemByDistributorVariables = {
      first: pageListLimit,
      itemId: itemId || "",
      ...initPagAction(next),
    };
    if (includeDistributorID) {
      variables.distributorId = loggedInUserId;
    }

    if (!refetch) return;
    setIsLoadingMore(true);
    const { data: moreData } = await fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setIsLoadingMore(false);
        if (!fetchMoreResult) {
          return previousResult;
        }
        return fetchMoreResult;
      },
    });
    setCodeEvents(
      _.map(
        _.get(
          moreData,
          "getAllCodeEventsForSpecificItemByDistributor.page.edges",
          []
        ),
        (it) => it.node
      ) as CodeGenerator_codeHistory[]
    );

    setPaging({
      ...moreData?.getAllCodeEventsForSpecificItemByDistributor?.pageData,
      ...moreData?.getAllCodeEventsForSpecificItemByDistributor?.page?.pageInfo,
    });
  };

  const value = useMemo(
    () => ({
      codeEvents,
      setCodeEvents,
      loading: loading || loadingCodeEvents || isLoadingMore,
      setLoading,
      resetContext,
      itemId,
      setItemId,
      setSearchQuery,
      searchQuery,
      getItems,
      goTo,
      paging,
      refetch
    }), // eslint-disable-next-line
    [loading, codeEvents, itemId, searchQuery, loadingCodeEvents, isLoadingMore]
  );

  return (
    <CodeEventContext.Provider value={value}>
      {children}
    </CodeEventContext.Provider>
  );
};

export function useCodeEventProvider() {
  const context = useContext(CodeEventContext);

  if (!context) {
    throw new Error(
      "useCodeEventProvider should be used inside the CodeEventContext."
    );
  }

  return context;
}
export default CodeEventProvider;
