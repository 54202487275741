import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import { codeGeneratorRoutes as itemRoutes } from "./constants";
import Add from "./containers/AddCodeGeneratorContainer";
import List from "./containers/CodeGeneratorContainer";

interface IProps {}

export const routes = {
         path: itemRoutes.list,
         element: <DefaultOutlet />,
         children: [
           { index: true, element: <List /> },
           { path: itemRoutes.add, element: <Add /> },
           { path: itemRoutes.edit, element: <Add /> },
         ],
       };
const CodeGeneratorIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default CodeGeneratorIndex;
