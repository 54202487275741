
const url = 'events-publisher';

export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
export const itemFields = {
  broker: 'broker',
  topic: 'topic',
  publisherId: 'publisherId'
};


export const defaultItem = {
 broker: '',
 topic: '',
 publisherId: ''
};
