import {
  defaultFont,
  dangerColor,
  grayColor,
  tooltip,
  primaryColor,
  successColor
} from "assets/jss/material-dashboard-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const tasksStyle = {
  ...customCheckboxRadioSwitch,
  table: {
    marginBottom: "0"
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid " + grayColor[5]
  },
  tableActions: {
    border: "none",
    padding: "12px 8px !important",
    verticalAlign: "middle",
  },
  formControl: { marginLeft: "-2rem"},

  label: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "14px",
      marginTop: "0.5rem",
      fontWeight: "400",
      color: "black !important"
    },
  tableCell: {
    ...defaultFont,
    padding: "0",
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0",
    color: successColor[0]
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor[0],
    boxShadow: "none",
  },
  delete: {
    backgroundColor: "transparent",
    color: dangerColor[0],
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor[0],
    boxShadow: "none"
  },
  iconWrapper: {
    position: "relative",
    display: "inline-block"
  },
  stopSign: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "none",
    color: "red", /* Set color for the stop sign */
    fontSize: "1.5em", /* Adjust size as needed */
    pointerEvents: "none" /* Ensure it doesn't interfere with hover events */
  },
  iconWrapperHover: {
    '&:hover $stopSign': {
      display: "block"
    }
  },
  tooltip
};

export default tasksStyle;
