import { legacyBatchTypes } from './constants';

let INITIAL_STATE = {
  isProgress: false,
  items: { data: [], paging: [] },
};

export function legacyBatchReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case legacyBatchTypes.LIST_LEGACY_BATCH:
      return {
        ...state,
        isProgress: true,
      };

    case legacyBatchTypes.LIST_LEGACY_BATCH_SUCCESS:
      return {
        ...state,
        isProgress: false,
        items: { data: action.payload.data?.results, paging: action.payload.data?.paging },
      };

    case legacyBatchTypes.LIST_LEGACY_BATCH_FAIL:
      return {
        ...state,
        isProgress: false,
      };

    default:
      return state;
  }
}
