import React, { ChangeEvent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      margin: 8
      // width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

interface IProps {
  placeholder?: string;
  // searchName: string;
  onChange: (e: any) => void;
}

const CustomSearchInput: React.FC<IProps> = ({
  placeholder = 'Search',
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Paper component='div' className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          onChange(e.target.value);
        }}
        inputProps={{
          placeholder,
        }}
      />
      <IconButton
        type='submit'
        className={classes.iconButton}
        aria-label='search'
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default CustomSearchInput;
