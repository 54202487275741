import { gql, useQuery } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import { codeEventFragment } from '../codeEvent/queries';
import { pageDataFragment } from '../sku/queries';
import {
  GetAllCodeGenerators,
  GetAllCodeGeneratorsVariables,
} from './types/GetAllCodeGenerators';
import {
  GetSpecificCodeGenerator,
  GetSpecificCodeGeneratorVariables,
} from './types/GetSpecificCodeGenerator';

/**
 * type CodeSystem {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
system: CodeSystemType!
hashChainLength: Int!
hashMaxJump: Int!
freeCodeJump: Int!
resetCodeJump: Int!
codeRange: [CodeTypes!]!
}
 */
export const codeSystemFragment = gql`
  fragment CodeSystem on CodeSystem {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    system
    hashChainLength
    hashMaxJump
    freeCodeJump
    resetCodeJump
    codeRange
  }
`;
/**
/**
 * type CodeGenerator {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
type: ActorTypes!
actionScope: ActionScope!
actorName: String!
profile: String!
codeSystem: ACP1!
hashRoot: String!
hashTop: String!
codeCount: Float!
hashIndex: Float!
codeReversalCount: Float!
codeHistory: [CodeEvent!]!
}
 */
export const codeGeneratorFragment = gql`
  ${codeEventFragment}
  ${codeSystemFragment}
  fragment CodeGenerator on CodeGenerator {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    type
    actionScope
    actorName
    profile
    codeSystem {
      ...CodeSystem
    }
    hashRoot
    hashTop
    codeCount
    hashIndex
    codeReversalCount
    codeHistory {
      ...CodeEvent
    }
  }
`;

/**
 * type CodeGeneratorEdge {
cursor: String
node: CodeGenerator
}
 */
const codeGeneratorEdgeFragment = gql`
  ${codeGeneratorFragment}
  fragment CodeGeneratorEdge on CodeGeneratorEdge {
    cursor
    node {
      ...CodeGenerator
    }
  }
`;

/**
 * type CodeGeneratorPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const codeGeneratorPageInfoFragment = gql`
  fragment CodeGeneratorPageInfo on CodeGeneratorPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type CodeGeneratorConnection {
edges: [CodeGeneratorEdge!]
pageInfo: CodeGeneratorPageInfo
}
 */
const codeGeneratorConnectionFragment = gql`
  ${codeGeneratorEdgeFragment}
  ${codeGeneratorPageInfoFragment}
  fragment CodeGeneratorConnection on CodeGeneratorConnection {
    edges {
      ...CodeGeneratorEdge
    }
    pageInfo {
      ...CodeGeneratorPageInfo
    }
  }
`;

/**
 * type GetAllCodeGeneratorsResponse {
page: CodeGeneratorConnection!
pageData: PageData
}
 */
const getAllCodeGeneratorsResponseFragment = gql`
  ${codeGeneratorConnectionFragment}
  ${pageDataFragment}
  fragment GetAllCodeGeneratorsResponse on GetAllCodeGeneratorsResponse {
    page {
      ...CodeGeneratorConnection
    }
    pageData {
      ...PageData
    }
  }
`;

/**
 * getAllCodeGenerators(
before: String
after: String
first: Int
last: Int
): GetAllCodeGeneratorsResponse!
 */
const getAllCodeGeneratorsQuery = gql`
  ${getAllCodeGeneratorsResponseFragment}
  query GetAllCodeGenerators(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllCodeGenerators(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllCodeGeneratorsResponse
    }
  }
`;

/**
 getSpecificCodeGenerator(
id: String!
): CodeGenerator!
 */
const getSpecificCodeGeneratorQuery = gql`
  ${codeGeneratorFragment}
  query GetSpecificCodeGenerator($id: String!) {
    getSpecificCodeGenerator(id: $id) {
      ...CodeGenerator
    }
  }
`;

export const useGetSpecificCodeGeneratorQuery = (
  variables: GetSpecificCodeGeneratorVariables
) =>
  useQuery<GetSpecificCodeGenerator, GetSpecificCodeGeneratorVariables>(
    getSpecificCodeGeneratorQuery,
    {
      client: clientSimulator,
      variables,
    }
  );

export const useGetAllCodeGeneratorsQuery = (
  variables: GetAllCodeGeneratorsVariables
) =>
  useQuery<GetAllCodeGenerators, GetAllCodeGeneratorsVariables>(
    getAllCodeGeneratorsQuery,
    {
      client: clientSimulator,
      variables,
    }
  );
