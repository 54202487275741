import { gql, useLazyQuery } from '@apollo/client';
import clientSimulator from 'apps/clientService/app/utils/clientClientService';
import {
  DownloadClientCreds,
  DownloadClientCredsVariables,
} from './types/DownloadClientCreds';
import {
  DownloadDistributorCodeGens,
  DownloadDistributorCodeGensVariables,
} from './types/DownloadDistributorCodeGens';
import {
  DownloadDistributorDetails,
  DownloadDistributorDetailsVariables,
} from './types/DownloadDistributorDetails';
import {
  DownloadDistributorFleet,
  DownloadDistributorFleetVariables,
} from './types/DownloadDistributorFleet';
import {
  DownloadDistributorItems,
  DownloadDistributorItemsVariables,
} from './types/DownloadDistributorItems';

/**
 * downloadClientCreds(
roleId: String!
):
 */
const downloadClientCredsQuery = gql`
  query DownloadClientCreds($roleId: String!) {
    downloadClientCreds(roleId: $roleId) {
      name
      url
    }
  }
`;

export const useLazyDownloadClientCreds = (
  variables: DownloadClientCredsVariables
) =>
  useLazyQuery<DownloadClientCreds, DownloadClientCredsVariables>(
    downloadClientCredsQuery,
    {
      client: clientSimulator,
      variables,
    }
  );

/**
 * downloadDistributorCodeGens(
fleetId: String!
):
 */
const downloadDistributorCodeGensQuery = gql`
  query DownloadDistributorCodeGens($fleetId: String!) {
    downloadDistributorCodeGens(fleetId: $fleetId) {
      name
      url
    }
  }
`;

export const useLazyDownloadDistributorCodeGens = (
  variables: DownloadDistributorCodeGensVariables
) =>
  useLazyQuery<
    DownloadDistributorCodeGens,
    DownloadDistributorCodeGensVariables
  >(downloadDistributorCodeGensQuery, {
    client: clientSimulator,
    variables,
  });

/**
 * downloadDistributorDetails(
clientId: String!
):
 */
const downloadDistributorDetailsQuery = gql`
  query DownloadDistributorDetails($clientId: String!) {
    downloadDistributorDetails(clientId: $clientId) {
      name
      url
    }
  }
`;

export const useLazyDownloadDistributorDetails = (
  variables: DownloadDistributorDetailsVariables
) =>
  useLazyQuery<DownloadDistributorDetails, DownloadDistributorDetailsVariables>(
    downloadDistributorDetailsQuery,
    {
      client: clientSimulator,
      variables,
    }
  );

/**
 * 
downloadDistributorFleet(
clientId: String!
):
 */
const downloadDistributorFleetQuery = gql`
  query DownloadDistributorFleet($clientId: String!) {
    downloadDistributorFleet(clientId: $clientId) {
      name
      url
    }
  }
`;

export const useLazyDownloadDistributorFleet = (
  variables: DownloadDistributorFleetVariables
) =>
  useLazyQuery<DownloadDistributorFleet, DownloadDistributorFleetVariables>(
    downloadDistributorFleetQuery,
    {
      client: clientSimulator,
      variables,
    }
  );
/**
 * downloadDistributorItems(
fleetId: String!
): String!
 */
const downloadDistributorItemsQuery = gql`
  query DownloadDistributorItems($fleetId: String!) {
    downloadDistributorItems(fleetId: $fleetId)
  }
`;

export const useLazyDownloadDistributorItems = (
  variables: DownloadDistributorItemsVariables
) =>
  useLazyQuery<DownloadDistributorItems, DownloadDistributorItemsVariables>(
    downloadDistributorItemsQuery,
    {
      client: clientSimulator,
      variables,
    }
  );
