import { useEffect, useState } from 'react';
import { useGetAllItemSKUsQuery } from '../queries';

const useItemSKU = () => {
  const [options, setOptions] = useState<{ value: string; label: string; }[]>([]);

  const { data, loading, refetch } = useGetAllItemSKUsQuery({
    first: 20,
  });

  useEffect(() => {
    setOptions(
      data?.getAllItemSKUs?.page?.edges?.map((item) => ({
        value: item?.node?._id || '',
        label: item?.node?.skuName || '',
      })) || []
    ); // eslint-disable-next-line
  }, [data]);

  return {
    options,
    refetch,
    loading,
    data: data?.getAllItemSKUs?.page?.edges
  };
};

export default useItemSKU;
