import { IProps } from '@components/messages/types';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import useNotifier from 'hooks/useNotifier';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-table';
import { RootState } from 'store/epics/userPrefEpics';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { makeQuery } from '../../utils';
import { LegacyActions } from '../redux/actions';

export interface ILegacyDistributor {
  distributorID: string;
  customerName: string;
  contactEmail: string;
  distributorAccountNo: string;
  distributorProfileURL: string;
  contactLastName: string;
  contactFirstName: string;
}
export interface ILegacyDistributorContext {
  items: ILegacyDistributor[];
  setItems: (item: any) => void;
  loading: boolean;
  getItems: () => void;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setOffset: (offset: string | number) => void;
  tableActions: ITableActions[];
}

export const LegacyDistributorContext = createContext<
  ILegacyDistributorContext
>({} as ILegacyDistributorContext);

const LegacyDistributorProvider: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const notify = useNotifier()
  const itemsSelector = useSelector(
    (state: RootState) => state.legacyDistributor.items
  );
  const isProgress = useSelector(
    (state: RootState) => state.legacyDistributor.isProgress
  );
  const [items, setItems] = useState<ILegacyDistributor[]>([]);
  const [paging, setPaging] = useState({});
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  useEffect(() => {
    setItems(itemsSelector.data);
    setPaging(itemsSelector.paging);
  }, [itemsSelector]);
  // context

  const getItems = () => {
    makeQuery(
      "",
      {
        getLegacy: LegacyActions.getDistributorsLegacy,
        listLegacy: LegacyActions.listLegacy,
        listLegacyFail: LegacyActions.listLegacyFail,
        listLegacySuccess: LegacyActions.listLegacySuccess
      },
      dispatch
    );
    return [];
  };
  const goTo = (next = true) => {
    let params = '';
    if (searchQuery !== undefined) {
      params += 'search=' + searchQuery;
    }
    if (next) {
      params += '&' + itemsSelector.paging?.next;
    } else {
      params += '&' + itemsSelector.paging?.prev;
    }
    makeQuery(params, {
        getLegacy: LegacyActions.getDistributorsLegacy,
        listLegacy: LegacyActions.listLegacy,
        listLegacyFail: LegacyActions.listLegacyFail,
        listLegacySuccess: LegacyActions.listLegacySuccess
      }, dispatch);
    return [];
  };

  const search = () => {
    if (searchQuery === undefined) return;
    makeQuery('search=' + searchQuery, {
        getLegacy: LegacyActions.getDistributorsLegacy,
        listLegacy: LegacyActions.listLegacy,
        listLegacyFail: LegacyActions.listLegacyFail,
        listLegacySuccess: LegacyActions.listLegacySuccess
      }, dispatch);

    return [];
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const setOffset = (offset: number | string) => {
    let params = '';
    params += `offset=${offset}`;
    if (searchQuery !== undefined) {
      params += '&search=' + searchQuery;
    }
    makeQuery(
      params,
      {
        getLegacy: LegacyActions.getDistributorsLegacy,
        listLegacy: LegacyActions.listLegacy,
        listLegacyFail: LegacyActions.listLegacyFail,
        listLegacySuccess: LegacyActions.listLegacySuccess,
      },
      dispatch
    );
  };
  const value = useMemo(
    () => ({
      items,
      setItems,
      loading: isProgress,
      getItems,
      setSearchQuery,
      searchQuery,
      goTo,
      paging,
      setOffset,
      tableActions: [
        {
          action: (args: Row[]) => {
            LegacyActions.bulkMigrateDistributor();
            notify({ text: 'Migration initiated.', status: 'success' })
          },
          description: (arg: string) => 'This action will initiate bulk migrations for all distributors.',
          title: 'Bulk Migrate Distributors',
          showConfirmationModal: true,
          bulkAction: true
        },
        {
          action: (args: Row[]) => {
            LegacyActions.bulkMigrateAssignedItems();
            notify({ text: 'Migration initiated.', status: 'success' })
          },
          description: (arg: string) => 'This action will initiate bulk migrations for all distributors assigned items.',
          title: 'Bulk Migrate Assigned items',
          showConfirmationModal: true,
          bulkAction: true
        }
      ]
    }), // eslint-disable-next-line
    [items, isProgress, paging, searchQuery]
  );

  return (
    <LegacyDistributorContext.Provider value={value}>
      {children}
    </LegacyDistributorContext.Provider>
  );
};

export default LegacyDistributorProvider;
