import { productTableData } from 'admin/product/constants/table';
import React, { createContext, useMemo, useState } from 'react';
import { IProduct } from '../types';

export interface IProductContext {
  checkedProductIds: string[];
  setCheckedProductIds: (ids: string[]) => void;
  products: IProduct[];
  setProducts: (products: IProduct[]) => void;
}
export const ProductContext = createContext({} as IProductContext);

interface IProps {
  children: React.ReactNode;
}

const ProductProvider: React.FC<IProps> = ({ children }) => {
  const [checkedProductIds, setCheckedProductIds] = useState<string[]>([]);
  const [products, setProducts] = useState(productTableData().dataRows);

  const value = useMemo(
    () => ({
      checkedProductIds,
      setCheckedProductIds,
      products,
      setProducts,
    }),
    [checkedProductIds, setCheckedProductIds, products]
  );
  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};

export default ProductProvider;
