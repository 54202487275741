import DescriptionCell from '@components/ReactTable/DescriptionCell';
import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyTableActions';
import MigratedCell from '../components/MigratedCell';

export const legacyTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <Actions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    { Header: 'productItemID', accessor: 'productItemID' },
    {
      Header: 'productItemOEM_SN',
      accessor: 'productItemOEM_SN',
    },
    {
      Header: 'productItemPAYG_SN',
      accessor: 'productItemPAYG_SN',
    },
    {
      Header: 'lifeCycleStatus',
      accessor: 'lifeCycleStatus',
    },
    {
      Header: 'Status',
      accessor: 'timerScheme',
      Cell: MigratedCell,
    },
    {
      Header: 'firmwareVersion',
      accessor: 'firmwareVersion',
    },
    {
      Header: 'productModelName',
      accessor: 'productModelName',
    },
    {
      Header: 'productBatchID',
      accessor: 'productBatchID',
    },
    {
      Header: 'productBatchNumber',
      accessor: 'productBatchNumber',
    },
    {
      Header: 'otpGeneratorID',
      accessor: 'otpGeneratorID',
    },
    {
      Header: 'otpSystemID',
      accessor: 'otpSystemID',
    },
    {
      Header: 'otpGeneratorHash_Top',
      accessor: 'otpGeneratorHash_Top',
    },
    {
      Header: 'otpGeneratorHash_Root',
      accessor: 'otpGeneratorHash_Root',
    },
    {
      Header: 'otpGeneratorCurrent_Count',
      accessor: 'otpGeneratorCurrent_Count',
    },
    {
      Header: 'otpGeneratorCurrent_Hash_Index',
      accessor: 'otpGeneratorCurrent_Hash_Index',
    },
    {
      Header: 'otpGeneratorStatus',
      accessor: 'otpGeneratorStatus',
    },
    {
      Header: 'otpSystemName',
      accessor: 'otpSystemName',
    },
    {
      Header: 'otpOperatorDescription',
      accessor: 'otpOperatorDescription',
      Cell: DescriptionCell,
    },
    {
      Header: 'otpHashChainLength',
      accessor: 'otpHashChainLength',
    },
    {
      Header: 'otpMaxHCJ',
      accessor: 'otpMaxHCJ',
    },   
    {
      Header: 'codeIndex',
      accessor: 'codeIndex'
    }, 
    {
      Header: 'last_Generated_Code',
      accessor: 'last_Generated_Code',
    },
    {
      Header: 'contactEmail',
      accessor: 'contactEmail',
    },
    {
      Header: 'customerName',
      accessor: 'customerName',
    },
    {
      Header: 'contactLastName',
      accessor: 'contactLastName',
    },
    {
      Header: 'contactFirstName',
      accessor: 'contactFirstName',
    },
    {
      Header: 'ermid',
      accessor: 'ermid',
    },
    {
      Header: 'codegenid',
      accessor: 'codegenid',
    },
  ],
};
