import CardGridLoader from '@components/Loaders/CardGridLoader';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  AttributeDto,
  QueryOrder,
  UpdateItemSKUInput,
} from 'apps/things/app/types/thingsGlobalTypes';
import EditItemSKU from '../components/EditItemSKU';
import { skuRoutes } from '../constants';
import { useGetAllItemSKUsQuery, useGetSpecificItemSKUQuery } from '../queries';
import { GetSpecificItemSKUs_getSpecificItemSKU } from '../types/GetSpecificItemSKUs';
import { UpdateItemSKU } from '../types/UpdateItemSKU';
import useUpdateItemSKU from '../hooks/useUpdateItemSKU';

const EditItemSKUContainer = () => {
  const notify = useNotifier();
  const { itemId } = useParams();
  const history = useNavigate();
  const { data, loading } = useGetSpecificItemSKUQuery({
    id: itemId || '',
  });
  const { refetch } = useGetAllItemSKUsQuery({
    first: 100,
    queryorder: QueryOrder.DESC,
  });
  const [itemSKU, setItemSKU] = React.useState(
    {} as GetSpecificItemSKUs_getSpecificItemSKU
  );

  React.useEffect(() => {
    setItemSKU(
      data?.getSpecificItemSKU || ({} as GetSpecificItemSKUs_getSpecificItemSKU)
    );
  }, [data]);

  const onComplete = (data: UpdateItemSKU) => {
    notify({
      status: 'success',
      text: 'Item SKU updated successfully',
    });

    refetch();
    history(skuRoutes.list);
  };
  const {
    mutation: updateItemSKU,
    mutationOpts: updateItemSKUOpts,
  } = useUpdateItemSKU({
    onComplete: (data) => {
      onComplete(data);
    },
  });

  const handleUpdate = (updateItemSKUInput: UpdateItemSKUInput) => {
    // @ts-ignore
    const _attr = updateItemSKUInput?.gatt?.attributes as AttributeDto[];

    Promise.all(
      _attr
        ?.map((item) => ({
          meta: item?.meta,
          prop: item?.prop,
          value: item?.value,
        }))
        .map((item) =>
          updateItemSKU({
            variables: {
              updateItemSKUInput: {
                itemSKUId: itemId || '',
                gatt: {
                  attributes: {
                    prop: item?.prop || '',
                    value: item?.value || '',
                    meta: item?.meta || '',
                  },
                },
              },
            },
          })
        )
    )
      .then((res) => { })
      .catch((err) => { });
    delete updateItemSKUInput.gatt;
    updateItemSKU({
      variables: { updateItemSKUInput },
    });
  };

  if (loading || !itemSKU._id) return <CardGridLoader />;

  return (
    <EditItemSKU
      handleUpdate={handleUpdate}
      isLoading={updateItemSKUOpts.loading}
      itemSKU={itemSKU}
    />
  );
};

export default EditItemSKUContainer;
