export const legacyBatchRoutes = {
  list: '/admin/legacy-batch',
  add: '/admin/legacy-batch/add',
  edit: '/admin/legacy-batch/:itemId',
  getEditLink: (itemId: string) => `/admin/legacy-batch/${itemId}`,
};

export const itemFields = {
  addItem: {
    productBatchID: 'productBatchID',
    productBatchNumber: 'productBatchNumber',
    productBatchDate: 'productBatchDate',
    productBatchNotes: 'productBatchNotes',
    productBatchState: 'productBatchState',
  },
};
