import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import {
  CreateItemSKUInput,
  QueryOrder,
} from 'apps/things/app/types/thingsGlobalTypes';
import clientSimulator from 'utils/clientSimulator';
import { deleteItemSKUMutation } from '../mutations';
import { useLazyGetAllItemSKUsQuery } from '../queries';
import { DeleteItemSKU, DeleteItemSKUVariables } from '../types/DeleteItemSKU';
import {
  GetAllItemSKUs,
  GetAllItemSKUsVariables,
  GetAllItemSKUs_getAllItemSKUs_page_edges,
} from '../types/GetAllItemSKUs';
import { defaultItemSKU } from '../constants';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';

export interface IItemSKUContext {
  defaultItem: CreateItemSKUInput;
  setDefaultItem: (item: CreateItemSKUInput) => void;
  itemSKUs: GetAllItemSKUs_getAllItemSKUs_page_edges[];
  setItemSKUs: (itemSKUs: GetAllItemSKUs_getAllItemSKUs_page_edges[]) => void;
  loading: boolean;
  getItemSKU: () => void;
  tableActions: ITableActions[];
  refetchItemSKU:
  | ((
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllItemSKUs>>)
  | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
}

export const ItemSKUContext = React.createContext<IItemSKUContext>(
  {} as IItemSKUContext
);

const ItemSKUProvider: React.FC<IProps> = ({ children }) => {
  const [defaultItem, setDefaultItem] = useState(
    defaultItemSKU as CreateItemSKUInput
  );
  const [itemSKUs, setItemSKUs] = React.useState<
    GetAllItemSKUs_getAllItemSKUs_page_edges[]
  >([] as GetAllItemSKUs_getAllItemSKUs_page_edges[]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  /// hooks
  const notify = useNotifier();
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();
  const [
    getItemSKU,
    { refetch: refetchItemSKU, loading, fetchMore, data },
  ] = useLazyGetAllItemSKUsQuery({
    first: pageListLimit,
    queryorder: QueryOrder.DESC,
  });

  useEffect(() => {
    if (data) {
      setItemSKUs(data?.getAllItemSKUs?.page?.edges || itemSKUs);
      setPaging({
        ...data?.getAllItemSKUs?.pageData,
        ...data?.getAllItemSKUs?.page?.pageInfo,
      });
    }  // eslint-disable-next-line
  }, [data]);

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItemSKU && refetchItemSKU()
    }, 100);

  }

  

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllItemSKUsVariables = {
      first: pageListLimit,
      queryorder: QueryOrder.DESC,
      ...initPagAction(next),
    };

    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllItemSKUs } = await fetchMore({
        variables: {
          ...variables,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return fetchMoreResult as GetAllItemSKUs;
        },
      });

      setItemSKUs(_data?.data?.getAllItemSKUs?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemSKUs?.pageData,
        ..._data?.data?.getAllItemSKUs?.page?.pageInfo,
      });
    }
  };

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    if (fetchMore) {
      const variables: GetAllItemSKUsVariables = {
        first: pageListLimit,
        queryorder: QueryOrder.DESC,
      };
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllItemSKUs } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItemSKUs(_data?.data?.getAllItemSKUs?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemSKUs?.pageData,
        ..._data?.data?.getAllItemSKUs?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const [deleteItemSKU] = useMutation<DeleteItemSKU, DeleteItemSKUVariables>(
    deleteItemSKUMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => { },
    }
  );

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItemSKU({
          variables: {
            itemSKUId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Items SKU deleted successfully',
        });
        if (refetchItemSKU) {
          const itemsFetched = await refetchItemSKU({
            first: 100,
            queryorder: QueryOrder.DESC,
          });
          setItemSKUs(
            itemsFetched?.data?.getAllItemSKUs?.page?.edges || itemSKUs
          );
          setTableActionLoading(false);
        } else {
          await getItemSKU();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItemSKU) {
          const itemsFetched = await refetchItemSKU({
            first: 100,
            queryorder: QueryOrder.DESC,
          });
          setItemSKUs(
            itemsFetched?.data?.getAllItemSKUs?.page?.edges || itemSKUs
          );
          setTableActionLoading(false);
        } else {
          await getItemSKU();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      itemSKUs,
      setItemSKUs,
      loading,
      getItemSKU,
      refetchItemSKU,
      defaultItem,
      setDefaultItem,
      goTo,
      paging,
      searchQuery,
      setSearchQuery, setPageLimit,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} item(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [itemSKUs, defaultItem, loading, searchQuery, paging]
  );

  return (
    <ItemSKUContext.Provider value={value}>{children}</ItemSKUContext.Provider>
  );
};

export default ItemSKUProvider;
