import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { itemRoutes } from './constants';
import Add from "./containers/AddItemContainer";
import List from "./containers/Item";

interface IProps { }
export const routes = {
         path: itemRoutes.list,
         element: <DefaultOutlet />,
         children: [
           { index: true, element: <List /> },
           { path: itemRoutes.add, element: <Add /> },
         ],
       };
const LoginUserIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet
     
    />
  );
};

export default LoginUserIndex;
