import gql from 'graphql-tag';

/**
 * Mutation.deleteDistributor(
distributorId: String!
): String!
 */
export const deleteDistributorMutation = gql`
  mutation DeleteDistributor($distributorId: String!) {
    deleteDistributor(distributorId: $distributorId)
  }
`;
export const accessTokenFragement = gql`
  fragment AccessToken on AccessToken {
    _id
    accessToken
    agentId
    agentType
    authenticationInstance
    createdAt
    deleteAt
    deleteStatus
    email
    firstName
    hireDate
    idString
    lastName
    name
    officeAddress
    profile
    role
    roleName
    subrole
    type
    updatedAt
  }
`;

/**
 * type User {
_id: ID!
accessToken: String
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
email: String
firstName: String
lastName: String
name: String
roleName: String
updatedAt: DateTime
}
 */
export const userFragment = gql`
  fragment User on User {
    _id
    accessToken
    createdAt
    deleteAt
    deleteStatus
    email
    firstName
    lastName
    name
    roleName
    updatedAt
  }
`;

/**
 *signUpClient(
signUpClientInput: SignUpClientInput!
): User!
 */
export const signUpClient = gql`
  ${userFragment}
  mutation SignUpClient($signUpClientInput: SignUpClientInput!) {
    signUpClient(signUpClientInput: $signUpClientInput) {
      ...User
    }
  }
`;

/**
 *signUpClient(
signUpClientInput: SignUpClientInput!
): User!
 */
export const signUpStaffAgentMutation = gql`
  ${userFragment}
  mutation SignUpDistributorStaffAgent($signUpDistributorStaffInput: SignUpDistributorStaffOrAgentInput!) {
    signUpDistributorStaff(signUpDistributorStaffInput: $signUpDistributorStaffInput) {
      ...User
    }
  }
`;

/**
 * Mutation.adminRegisterDistributor(
adminRegisterDistributor: AdminRegisterDistributorInput!
): Distributor!
 */
export const adminRegisterDistributorMutation = gql`
  mutation AdminRegisterDistributor(
    $adminRegisterDistributor: AdminRegisterDistributorInput!
  ) {
    adminRegisterDistributor(
      adminRegisterDistributor: $adminRegisterDistributor
    ) {
      _id
      name
    }
  }
`;

/**
 * checkDistributorEmailForResetPassword(
email: String!
): Success!
 */
export const resetPasswordMutation = gql`
  mutation CheckDistributorEmailForResetPassword($email: String!) {
    checkDistributorEmailForResetPassword(email: $email) {
      message
      status
    }
  }
`;

/**
 * resetDistributorPassword(
resetDistributorPasswordInput: ResetDistributorPasswordInput!
): Success!
 */
export const resetDistributorPasswordMutation = gql`
  mutation ResetDistributorPassword(
    $resetDistributorPasswordInput: ResetDistributorPasswordInput!
  ) {
    resetDistributorPassword(
      resetDistributorPasswordInput: $resetDistributorPasswordInput
    ) {
      status
    }
  }
`;


/**
 * updateDistributorSetting(
updateDistributorSettingInput: UpdateDistributorSettingInput!
): DistributorSetting!
 */

export const updateDistributorSettingMutation = gql `
mutation UpdateDistributorSetting($updateDistributorSettingInput: UpdateDistributorSettingInput!) {
    updateDistributorSetting(updateDistributorSettingInput: $updateDistributorSettingInput) {
      _id
    resetCodeCount
    freeCodeCount
    totalCodeCountLimit
    codeGenInterval
    dayCodeCountLimit
    maxCreditStackDays
    maxCreditStackCodeEvents
    }
  }
`