import Button from '@components/CustomButtons/Button';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import TransferComponent from '@components/Transfer/TransferComponent';
import { PAGE_LIST_LIMIT } from '@constants';
import CustomSearchInput from 'apps/ecommerce/app/productSelector/components/CustomSearchInput';
import React, { useEffect, useState } from 'react';
import { LegacyActions } from '../../LegacyItem/redux/actions';
interface IProps {
  onSubmit: (args: string[]) => void;
  loading: boolean;
}

const AssignItemsForm: React.FC<IProps> = ({ onSubmit, loading: _loading }) => {
  const [checked, setChecked] = React.useState<string[]>([]);
  const [options, setOptions] = useState<
    {
      productItemOEM_SN: string;
      productItemID: string;
      productBatchNumber: string;
    }[]
  >([]);
  const [cache, setCache] = useState<
    {
      productItemOEM_SN: string;
      productItemID: string;
      productBatchNumber: string;
    }[]
  >([]);
  const [left, setLeft] = React.useState<string[]>(
    options.map((item) => item.productItemID)
  );
  const [right, setRight] = React.useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setLoading(true);
    LegacyActions.getUnassignedItem('search=')
      .then((res) => {
        setOptions(res.data || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    return () => {
      setOffset(0);
    };
  }, []);

  useEffect(() => {
    setLeft(
      options
        .filter((i) => !right.includes(i?.productItemID || ''))
        .map((item) => item.productItemID)
    ); // eslint-disable-next-line
  }, [options]);

  const getName = (id: string) => {
    let item = options?.find((i) => i.productItemID === id);
    if (!item) {
      item = cache?.find((i) => i.productItemID === id);
    }

    if (item) return `${item.productItemOEM_SN} | ${item.productBatchNumber}`;
    return '';
  };

  const onSearch = (e: string) => {
    setSearchQuery(e);
    setLoading(true);
    LegacyActions.getUnassignedItem('search=' + e)
      .then((res) => {
        setLoading(false);
        setCache([
          ...cache,
          ...options.filter((i) => right.includes(i?.productItemID)),
        ]);
        setOptions([...res.data] || []);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleCheckedRight = () => {
    setLoading(true);

    LegacyActions.getUnassignedItem(
      `search=${searchQuery}&offset=${offset + PAGE_LIST_LIMIT}`
    )
      .then((res) => {
        setLoading(false);
        setCache([
          ...cache,
          ...options.filter((i) => right.includes(i?.productItemID)),
        ]);
        setOptions([...res.data, ...options] || []);
      })
      .catch((err) => {
        setLoading(false);
      });
    setOffset(offset + PAGE_LIST_LIMIT);
  };

  return (
    <div>
      <GridContainer>
        <GridItem md={12}>
          <CustomSearchInput
            onChange={onSearch}
            placeholder='Search products'
          />
        </GridItem>
      </GridContainer>
      <TransferComponent
        left={left}
        setLeft={setLeft}
        right={right}
        setRight={setRight}
        checked={checked}
        setChecked={setChecked}
        getName={getName}
        loading={loading}
        handleCheckedRight={handleCheckedRight}
      />
      <GridContainer>
        <GridItem md={12}>
          <Button
            color='primary'
            onClick={() => {
              onSubmit(right);
            }}
            disabled={loading || _loading}
            style={{ marginLeft: 7 }}
          >
            Save
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AssignItemsForm;
