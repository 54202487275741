
const url = 'events-signal';

export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
export const itemFields = {
  signalId: 'signalId',
  publisher: 'publisher',
  jmp: 'jmp',
  time: 'time',
};

export const defaultItem = {
  signalId: '',
  broker: '',
  jmp: '{}',
  topic: '',
  time: new Date(),
};
