import {  useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { IFileInfo } from 'react-csv-reader';

import ItemSKUPage from '../components/ItemSKUPage';
import { ItemSKUContext } from '../context/SKUProvider';
import { CreateItemSKU, CreateItemSKUVariables } from '../types/CreateItemSKU';
import { createItemSkuMutation } from '../mutations';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import useNotifier from 'hooks/useNotifier';
import ActionModal from '@components/Modal/ActionModal';
import { LinearProgress } from '@material-ui/core';
import { CreateItemSKUInput } from 'apps/things/app/types/thingsGlobalTypes';

const ItemSKU = (props: any) => {
  const { refetchItemSKU } = React.useContext(ItemSKUContext);
  const notify = useNotifier();
  const [uploadedSkus, setUploadedSkus] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [progress, setProgress] = useState(0);
  const [totalUpload, setTotalUpload] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [itemSKUInputData, setItemSKUInputData] = useState<
    CreateItemSKUInput[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [createItemSKU] = useMutation<CreateItemSKU, CreateItemSKUVariables>(
    createItemSkuMutation,
    {
      client: clientSimulator,
      onCompleted: async (data) => {
        setProgress(((uploadedSkus + totalFailed + 1) * 100) / totalUpload);
        setUploadedSkus(uploadedSkus + 1);
      },
      onError: (err) => {
        setProgress(((uploadedSkus + totalFailed + 1) * 100) / totalUpload);
        setTotalFailed(totalFailed + 1);
        const messages = err?.graphQLErrors || [];

        const msg = JSON.stringify( // @ts-ignore
          messages?.map((item) => item?.extensions?.response?.message)
        );
        notify({ text: msg, status: 'error' });
        handleGQLErrors(notify, err);
      },
    }
  );

  const uploadCSV = (csvData: any[], fileInfo: IFileInfo) => {
    // setProgress(0);
    // setTotalFailed(0);
    // setTotalUpload(0);

    const fields = { skuName: -1, productBase: -1, oemDescription: -1 };

    const data = [...csvData];
    const csvHeader = data.shift();

    for (var key in fields) {
      if (fields.hasOwnProperty(key)) {
        // @ts-ignore
        // eslint-disable-next-line
        fields[key] = csvHeader.findIndex((element) => element === key);
      }
    }

    let inputData: CreateItemSKUInput[] = [];

    data.forEach((item: string[], i: number) => {
      inputData.push({
        skuName: item[fields['skuName']],
        oemDescription: item[fields['oemDescription']],

       
        productBase: // @ts-ignore
          ProductClassBases[item[fields.productBase]] || ProductClassBases.MISC,
      });
    });
    setTotalUpload(inputData.length);

    setItemSKUInputData(inputData);
    setIsModalOpen(true);
  };

  const createDataFromCSV = () => {
    setUploading(true);
    Promise.all(
      Array.from(Array(itemSKUInputData.length).keys()).map((item, index) =>
        createItemSKU({
          variables: { createItemSKUInput: itemSKUInputData[index] },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: `Process completed.`,
        });

        setUploading(false);
        if (refetchItemSKU) {
          await refetchItemSKU();
        }

        setIsModalOpen(false);
      })
      .then((res) => {
        refetchItemSKU && refetchItemSKU();
      });
  };

  return (
    <div className='main-content'>
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={createDataFromCSV}
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Upload'
        loading={uploading}
      >
        <div className='progress-bar'>
          <LinearProgress
            variant='determinate'
            value={progress}
            color='primary'
            // @ts-ignore
            thickness={4}
          />
        </div>

        <div>Are you sure you want to create {totalUpload} item SKU?</div>
      </ActionModal>
      <ItemSKUPage
        uploadCsv={uploadCSV}
        progress={progress}
        uploading={uploading}
      />
    </div>
  );
};

export default ItemSKU;
