export const legacyRoutes = {
  list: '/admin/legacy-item',
  add: '/admin/legacy-item/add',
  edit: '/admin/legacy-item/:itemId',
  getEditLink: (itemId: string) => `/admin/legacy-item/${itemId}`,
};

export const itemFields = {
  addItem: {
    idString: 'idString',
    creationDate: 'creationDate',
    oemID: 'oemID',
    oemItemID: 'oemItemID',
    itembatchId: 'itembatchId',
    itemBatchNumber: 'itemBatchNumber', // for storing select option label
    itemfirmwareId: 'itemfirmwareId',
    productModelID: 'productModelID',
    codegeneratorId: 'codegeneratorId',
    itemStockId: 'itemStockId',
    itemFleetId: 'itemFleetId',
    totalItems: 'totalItems',
    lifeCycleStatus: 'lifeCycleStatus',
    productItemPAYG_SN: 'productItemPAYG_SN',
    productItemOEM_SN: 'productItemOEM_SN',
    productBatchID: 'productBatchID',
    firmwareVersion: 'firmwareVersion',
    timerScheme: 'timerScheme',
    productType: 'productType'

  },
};