import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { IconButton } from '@material-ui/core';
import { useAuth } from 'admin/auth/context/AuthProvider';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import { Payment } from 'apps/accounts/app/assetAccount/types/Payment';
import AssetHistoryTable from 'apps/accounts/app/assetAccount/components/AssetHistoryTable';
import useNotifier from "hooks/useNotifier";
import { itemCodeEventsRoutes } from '../../codeEvent/constants';
const viewPortWidth = window.innerWidth
const thresholdWidth = 600
const useStyles = makeStyles({
  container: {
    marginBottom: 30,
  },
  table: {
    minWidth: viewPortWidth <= thresholdWidth? 280: 650,
  },
  td: { fontSize: viewPortWidth <= thresholdWidth? "10px": "16px", padding: viewPortWidth <= thresholdWidth ? "16px 5px" : "16px" },
  th: { width: viewPortWidth <= thresholdWidth? 100: 180, fontWeight: 'bold', fontSize: viewPortWidth <= thresholdWidth? "10px": "16px", padding: viewPortWidth <= thresholdWidth ? "16px 5px" : "16px" },
  btn: { padding: 0, marginLeft: 15 },
  thWithButton: { paddingTop: 7, paddingBottom: 7 },
});


const placeholder = '---';


interface IProps {
  otpHex: string;
  otpDec?: string;
  hashIndex?: number;
  otpCount?: number;
  hashTop?: string;
  hashRoot?: string;
  hashChainLength?: number;
  maxHashJump: number;
  oemItemID: string;
  itemID: string;
  paymentHistory?: Payment[];
  customerName?: string;
}

const CodeGeneratorPreview: React.FC<IProps> = ({
  otpHex = placeholder,
  otpDec = placeholder,
  hashIndex = placeholder,
  otpCount = placeholder,
  hashTop = placeholder,
  hashRoot = placeholder,
  hashChainLength = placeholder,
  maxHashJump = placeholder,
  oemItemID = placeholder,
  itemID = placeholder,
  paymentHistory = [],
  customerName = '',
}) => {
  const classes = useStyles();
  const [payHistoryModalOpen, setPayHistoryModalOpen] = React.useState(false);
  const { isDistributor } = useAuth();
  const notify = useNotifier()
  const history = useNavigate()
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.th}>
              OTP (64bit Hex)
            </TableCell>
            <TableCell className={classes.td}>{otpHex}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" className={classes.th} scope="row">
              OTP (Padded Dec)
            </TableCell>
            <TableCell className={classes.td} component="td">
              {otpDec}

              {otpDec && (       
                <span>
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(otpDec);
                      notify({text: "Otp Copied Successfully", status: "success"})
                    }}
                    className={classes.btn}
                  >
                    <FileCopyOutlinedIcon
                      style={{ fontSize: 16 }}
                      fontSize="inherit"
                    />
                  </IconButton>
                </span>
              )}
            </TableCell>
          </TableRow>
          {!isDistributor ? (
            <>
              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  Hash Index (C)
                </TableCell>
                <TableCell>{hashIndex}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  OTP Count (I)
                </TableCell>
                <TableCell>{otpCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  Hash Top
                </TableCell>
                <TableCell>{hashTop}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  Hash Root
                </TableCell>
                <TableCell>{hashRoot}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  Hash Chain Length(N)
                </TableCell>
                <TableCell>{hashChainLength}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  Maximum HCJ (MAXN)
                </TableCell>
                <TableCell>{maxHashJump}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  OEM Product ID
                </TableCell>
                <TableCell>{oemItemID}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  Product ID
                </TableCell>
                <TableCell>{itemID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.th} scope="row">
                  Code History
                </TableCell>
                <TableCell className={classes.thWithButton}>
                  <Button size="sm" onClick={() => history(itemCodeEventsRoutes.getCodeEventsLink(itemID))}>
                    View Code History
                  </Button>
                </TableCell>
              </TableRow>
            </>
          ) : null}
        </TableBody>
      </Table>

      <DrawerSidebar
        isModalOpen={payHistoryModalOpen}
        toggleModal={setPayHistoryModalOpen}
        title={customerName + " pay history"}
        styles={{ maxWidth: "80vw" }}
      >
        <AssetHistoryTable paymentHistory={paymentHistory} />
      </DrawerSidebar>
    </TableContainer>
  );
};

export default CodeGeneratorPreview;
