import React from 'react';
import ContentLoader from 'react-content-loader';
import './styles.scss';

interface IProps {
  colSpan: number;
}

const TableLoader: React.FC<IProps> = (props) => {
  return (
    <>
      {Array.from(Array(props.colSpan).keys()).map((item) => {
        return (
          <td key={item}>
            <ContentLoader
              speed={2}
              width={200}
              height={50}
              viewBox='0 0 976 50'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
              {...props}
            >
              <rect x='25' y='15' rx='5' ry='5' width='320' height='150' />
            </ContentLoader>
          </td>
        );
      })}
    </>
  );
};

export default TableLoader;
