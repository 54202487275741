
import TableActions from "../components/tableActions";
import { Row } from "react-table";

export const itemTableData = [
  {
    Header: 'Actions',
    id: 'expander', // It needs an ID
    Cell: ({ row }: { row: Row }) => (
      <TableActions {...row.getToggleRowExpandedProps()} row={row} />
    ),
  },
];
