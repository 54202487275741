import { LoginUsers } from 'admin/auth/types/LoginUsers';
import { SignUpClientInput } from 'apps/clientService/app/types/clientServiceGlobalTypes';
import { SignUpDistributorStaffOrAgentInput } from 'apps/clientService/app/types/clientServiceGlobalTypes';
import React from 'react';
import { SignUpCredentialsDto } from '../types/authGlobalTypes';

interface IUserContext {
  login: (email: string, password: string) => void;
  loginClient: (email: string, password: string) => void;
  signup: (credentials: SignUpCredentialsDto) => void;
  signUpDistributor: (signUpClientInput: SignUpClientInput) => void;
  signUpStaffAgent: (signUpDistributorStafforAgentInput: SignUpDistributorStaffOrAgentInput ) => void;
  user?: LoginUsers | null | undefined;
  isLoading?: boolean;
}

export const UserContext = React.createContext<IUserContext>(
  {} as IUserContext
);
