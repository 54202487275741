import { gql, useLazyQuery } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import {
  CloudinaryListImages,
  CloudinaryListImagesVariables,
} from './types/CloudinaryListImages';

/**
 * type Format {
png: Float!
jpg: Float!
mp4: Float!
doc: Float!
}
 */
const formatFragment = gql`
  fragment Format on Format {
    png
    jpg
    mp4
    doc
  }
`;
/**
 * type Agregations {
format: Format!
}
 */
export const agregationsFragment = gql`
  ${formatFragment}
  fragment Agregations on Agregations {
    format {
      ...Format
    }
  }
`;

/**
 * ype Resources {
asset_id: String!
public_id: String!
folder: String!
filename: String!
format: String!
version: Float!
resource_type: String!
type: String!
created_at: String!
uploaded_at: String!
bytes: Float!
backup_bytes: Float!
width: Float!
height: Float!
aspect_ratio: Float!
pixels: Float!
image_analysis: ImageAnalysis!
url: String!
secure_url: String!
status: String!
access_mode: String!
access_control: [String!]
}
 */
const resourcesFragment = gql`
  fragment Resources on Resources {
    asset_id
    public_id
    folder
    filename
    format
    version
    resource_type
    type
    created_at
    uploaded_at
    bytes
    backup_bytes
    url
    secure_url
    status
    access_mode
    access_control
    __typename
  }
`;
/**
 * type ListImagesCloudinary {
total_count: Float!
time: Float!
aggregations: Agregations!
next_cursor: String!
resources: [Resources!]!
}
 */
const listImagesCloudinaryFragment = gql`
  ${resourcesFragment}
  fragment ListImagesCloudinary on ListImagesCloudinary {
    total_count
    time
    next_cursor
    resources {
      ...Resources
    }
  }
`;
/**
 * cloudinaryListImages(
nextCursor: String!
): ListImagesCloudinary!
 */
const cloudinaryListImagesQuery = gql`
  ${listImagesCloudinaryFragment}
  query CloudinaryListImages($nextCursor: String!, $tag: String) {
    cloudinaryListImages(nextCursor: $nextCursor, tag: $tag) {
      ...ListImagesCloudinary
    }
  }
`;

export const useCloudinaryListImagesQuery = (
  variables: CloudinaryListImagesVariables
) =>
  useLazyQuery<CloudinaryListImages, CloudinaryListImagesVariables>(
    cloudinaryListImagesQuery,
    {
      variables,
      client: clientEcommerce,
    }
  );
