import { OrgTypes } from "../../types/clientServiceGlobalTypes";

export const itemFields = {
  contactRole: 'contactRole',
  supplierAddress: 'supplierAddress',
  supplierDescription: 'supplierDescription',
  supplierName: 'supplierName',
  orgProfile: 'orgProfile',
  orgType: 'orgType',
  city: 'supplierAddress.city',
  country: 'supplierAddress.country',
  postcode: 'supplierAddress.postcode',
  srpc: 'supplierAddress.srpc',
  street: 'supplierAddress.street',
  unit: 'supplierAddress.unit',
  inputAddressLongitude:
    'supplierAddress.locationAddressDto.inputAddressLongitude',
  inputAddressLatitude:
    'supplierAddress.locationAddressDto.inputAddressLatitude',
};

export const defaultAddress = {
  city: '',
  country: '',
  locationAddressDto: {
    inputAddressLatitude: 0,
    inputAddressLongitude: 0,
  },
  postcode: '',
  srpc: '',
  street: '',
  unit: '',
};

export const defaultItem = {
  supplierId: '_',
  contactRole: 'Supplier',
  supplierAddress: defaultAddress,
  supplierDescription: '',
  supplierName: '',
  orgType: OrgTypes.SUPPLIER,
};

const url = 'suppliers';
export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
