import { DocumentNode, gql, useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import { useEffect } from 'react';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import {
  AdminRegisterServicer,
  AdminRegisterServicerVariables,
} from './types/AdminRegisterServicer';
import {
  AssignDistributorClientExclusiveServiceType,
  AssignDistributorClientExclusiveServiceTypeVariables,
} from './types/AssignDistributorClientExclusiveServiceType';
import {
  UpdateServicer,
  UpdateServicerVariables,
} from './types/UpdateServicer';

/**
 * Mutation.adminRegisterServicer(
adminRegisterServicer: AdminRegisterServicerInput!
): Servicer!
 */
const adminRegisterServicerMutation = gql`
  mutation AdminRegisterServicer(
    $adminRegisterServicer: AdminRegisterServicerInput!
  ) {
    adminRegisterServicer(adminRegisterServicer: $adminRegisterServicer) {
      _id
    }
  }
`;
export interface OnCompleteFn {
  callbackFn: () => void;
}

export const useAnyMutation = (
  anyMutation: DocumentNode,
  { callbackFn }: OnCompleteFn
) => {
  const [mutation, mutationOps] = useMutation(anyMutation, {
    onCompleted: (data) => {
      callbackFn();
    },
  });
  const notify = useNotifier();
  useEffect(() => {
    if (mutationOps.error) {
      handleGQLErrors(notify, mutationOps.error);
    } // eslint-disable-next-line
  }, [mutationOps.error]);

  return {
    mutation,
    loading: mutationOps.loading,
    error: mutationOps.error,
  };
};

export const useAdminRegisterServicerMutation = ({
  callbackFn,
}: OnCompleteFn) => {
  const notify = useNotifier();
  const [mutation, mutationOps] = useMutation<
    AdminRegisterServicer,
    AdminRegisterServicerVariables
  >(adminRegisterServicerMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      callbackFn();
      notify({ text: 'Created successfully', status: 'success' });
    },
    onError: (error) => {
      handleGQLErrors(notify, error);
    },
  });
  return {
    mutation,
    loading: mutationOps.loading,
    error: mutationOps.error,
  };
};

/**
 * Mutation.deleteServicer(
servicerId: String!
): String!
 */
export const deleteServicerMutation = gql`
  mutation DeleteServicer($servicerId: String!) {
    deleteServicer(servicerId: $servicerId)
  }
`;

/**
 * Mutation.updateServicer(
updateServicerInput: UpdateServicerInput!
): Servicer!
 */
export const updateServicerMutation = gql`
  mutation UpdateServicer($updateServicerInput: UpdateServicerInput!) {
    updateServicer(updateServicerInput: $updateServicerInput) {
      _id
    }
  }
`;

export const useUpdateServicerMutation = ({ callbackFn }: OnCompleteFn) => {
  const notify = useNotifier();
  const [mutation, mutationOps] = useMutation<
    UpdateServicer,
    UpdateServicerVariables
  >(updateServicerMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      callbackFn();
      notify({ text: 'Created successfully', status: 'success' });
    },
  });
  return {
    mutation,
    loading: mutationOps.loading,
    error: mutationOps.error,
  };
};

/**
 * assignDistributorClientExclusiveServiceType(
assignDistributorClientExclusiveServiceTypeInput: AssignDistributorClientExclusiveServiceTypeInput!
): ClientExclusiveService!
 */

const assignDistributorClientExclusiveServiceTypeMutation = gql`
  mutation AssignDistributorClientExclusiveServiceType(
    $assignDistributorClientExclusiveServiceTypeInput: AssignDistributorClientExclusiveServiceTypeInput!
  ) {
    assignDistributorClientExclusiveServiceType(
      assignDistributorClientExclusiveServiceTypeInput: $assignDistributorClientExclusiveServiceTypeInput
    ) {
      _id
    }
  }
`;

export const useAssignDistributorClientExclusiveServiceTypeMutation = ({
  callbackFn,
}: OnCompleteFn) => {
  const notify = useNotifier();
  const [mutation, mutationOps] = useMutation<
    AssignDistributorClientExclusiveServiceType,
    AssignDistributorClientExclusiveServiceTypeVariables
  >(assignDistributorClientExclusiveServiceTypeMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      callbackFn();
      notify({ text: 'Created successfully', status: 'success' });
    },
    onError: (error) => {
      handleGQLErrors(notify, error)
    }
  });
  return {
    mutation,
    loading: mutationOps.loading,
    error: mutationOps.error,
  };
};
