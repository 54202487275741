import { gql } from '@apollo/client';

/**
 * mutation {
  assignPermissionToRole(
    
    assignPermissionToRoleInput: {
      roleId: "60078d32df008825b634b7bf"
      authenticationInstanceId: "6027515e631bc08b8424be2b"
      permissions: [{permissionId: "", permissionName: NOT_APPLICABLE}]
      
    }
  ) {
  status
    message
  }
}
 */
export const assignPermissionToRoleMutation = gql`
  mutation AssignPermissionToRole(
    $assignPermissionToRoleInput: AssignPermissionToRoleInput!
  ) {
    assignPermissionToRole(
      assignPermissionToRoleInput: $assignPermissionToRoleInput
    ) {
      status
      message
    }
  }
`;

/**
 * Mutation.deleteRolePermission(
rolePermissionId: String!
): Success!
 */
export const deleteRolePermissionMutation = gql`
  mutation DeleteRolePermission($rolePermissionId: String!) {
    deleteRolePermission(rolePermissionId: $rolePermissionId) {
      message
    }
  }
`;


/**
 * Mutation.assignPermissionsToAgent(
assignPermissionToAgentInput: AssignPermissionToSubRoleInput!
): Success!
 */

export const assignPermissionsToAgentMutation = gql`
  mutation AssignPermissionToAgent(
  $assignPermissionToAgentInput: AssignPermissionToSubRoleInput!
  ) {
  assignPermissionsToAgent(assignPermissionToAgentInput: $assignPermissionToAgentInput) 
    {
  status
  message
    }
  }
`