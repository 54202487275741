export const legacyRoutes = {
  list: '/admin/legacy/model',
  add: '/admin/legacy/model/add',
  edit: '/admin/legacy/model/:itemId',
  getEditLink: (itemId: string) => `/admin/legacy/model/${itemId}`,
};


export const itemFields = {
  productModelID: 'productModelID',
  productModelName: 'productModelName',
  productFamilyID: 'productFamilyID',
  productVendor: 'productVendor',
  productModelDescription: 'productModelDescription',
  quantityInStock: 'quantityInStock',
  productModelCost: 'productModelCost',
  image: 'image',
};
