import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import { Row } from 'react-table';
import SkuTableActions from '../components/TableActions';


export const itemSKUTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <SkuTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },

    {
      Header: 'Name',
      accessor: 'node.name',
    },
    {
      Header: 'Country',
      accessor: 'node.orgAddress.country',
    },
    {
      Header: 'City',
      accessor: 'node.orgAddress.city',
    },
    {
      Header: 'postcode',
      accessor: 'node.orgAddress.postcode',
    },
    {
      Header: 'srpc',
      accessor: 'node.orgAddress.srpc',
    },
    {
      Header: 'street',
      accessor: 'node.orgAddress.street',
    },
    {
      Header: 'unit',
      accessor: 'node.orgAddress.unit',
    }, {
      Header: 'Latitude',
      accessor: 'node.orgAddress.addressLatitude',
    }, {
      Header: 'Longitude',
      accessor: 'node.orgAddress.addressLongitude',
    },
    {
      Header: 'Description',
      accessor: 'node.description',
      Cell: DescriptionCell,
    },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
    { Header: 'ID', accessor: 'node._id' },
  ],
});
