import React from 'react';
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SettingsIcon from '@material-ui/icons/Settings';
import styles from 'assets/jss/material-dashboard-pro-react/components/paginationStyle.js';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import { ButtonGroup, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import Button from '@material-ui/core/Button';
import { funNumberAgr } from '@types';
// @ts-ignore noqa
const useStyles = makeStyles(styles);

interface IProps {
  visiblePages: number[];
  pageIndex: number;
  onClick: (page: number) => void;
  previousPage: () => void;
  canNextPage: boolean;
  nextPage: () => void;
  pageCount: number;
  canPreviousPage: boolean;
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger';
  loading?: boolean | undefined;
  setOffset?: (offset: number | string) => void;
  setPageLimit?: funNumberAgr
  setNumberOfRows?: funNumberAgr
}
const Pagination: React.FC<IProps> = ({
  visiblePages,
  color = 'primary',
  onClick,
  pageIndex,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  loading,
  setOffset,
  setPageLimit,
  setNumberOfRows
}) => {
  const classes = useStyles();
  const paginationLink = cx({
    [classes.paginationLink]: true,
    [classes.disabled]: !canPreviousPage && loading,
  });

  const settingLink = cx({
    [classes.paginationLink]: true,
    [classes.zeroMargin]: true,
  });

  const paginationPrevLink = cx({
    [classes.paginationLink]: true,
    [classes.disabled]: !canNextPage && loading,
  });
  const [value, setValue] = React.useState('20');

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };


  return (
    <ul className={classes.pagination}>
      <li className={classes.paginationItem}>
        <div
          onClick={
            loading || !canPreviousPage
              ? () => {
                previousPage();
              }
              : previousPage
          }
          className={paginationLink}
        >
          <NavigateBeforeIcon />
        </div>
      </li>
      {setOffset ? (
        <li className={classes.paginationItem}>
          <PopupState variant='popover' popupId='demo-popup-popover'>
            {(popupState) => (
              <>
                <div className={settingLink} {...bindTrigger(popupState)}>
                  <SettingsIcon fontSize='small' />
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box p={10} style={{ minWidth: 100 }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label={'Offset'}
                          name={'offset'}
                          size='small'
                          value={value}
                          type='number'
                          onChange={handleValueChange}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ height: 14 }}>&nbsp;</div>
                        <ButtonGroup
                          aria-label=' primary button group'
                          style={{ float: 'right' }}
                        >
                          <Button
                            size='small'
                            onClick={() => {
                              setOffset && setOffset(value);
                            }}
                          >
                            Apply
                          </Button>
                        </ButtonGroup>
                      </GridItem>
                    </GridContainer>
                  </Box>
                </Popover>
              </>
            )}
          </PopupState>
        </li>
      ) : null}
      {!setOffset && setPageLimit ? (
        <li className={classes.paginationItem}>
          <PopupState variant='popover' popupId='demo-popup-popover'>
            {(popupState) => (
              <>
                <div className={settingLink} {...bindTrigger(popupState)}>
                  <SettingsIcon fontSize='small' />
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box p={10} style={{ minWidth: 100, padding: 10 }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          onChange={(e: any) => {
                            setValue(e.target.value || '')
                           
                          }}
                        >
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>

                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ height: 14 }}>&nbsp;</div>
                        <ButtonGroup
                          aria-label=' primary button group'
                          style={{ float: 'right' }}
                        >
                          <Button
                            size='small'
                            onClick={() => {
                              setPageLimit(parseInt(value));
                              setNumberOfRows && setNumberOfRows(parseInt(value))
                              popupState.close()
                            }}
                          >
                            Apply
                          </Button>
                        </ButtonGroup>
                      </GridItem>
                    </GridContainer>
                  </Box>
                </Popover>
              </>
            )}
          </PopupState>
        </li>
      ) : null}
      <li className={classes.paginationItem}>
        <div
          onClick={loading || !canNextPage ? () => ({}) : nextPage}
          className={paginationPrevLink}
        >
          <NavigateNextIcon />
        </div>
      </li>
    </ul>
  );
};

export default Pagination;
