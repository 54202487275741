import { IProps } from "@components/messages/types";
import React, { FC } from "react";
import LegacyAssignedItemsContext from "../LegacyAssignedItems/context/LegacyAssignedItemsContext";
import LegacyBatchProvider from "../LegacyBatch/context/LegacyBatchContext";
import LegacyCategoryProvider from "../LegacyCategory/context/LegacyCategoryContext";
import LegacyCodeHistoryContext from "../LegacyCodeHistory/context/LegacyCodeHistoryContext";
import LegacyFamilyProvider from "../LegacyFamily/context/LegacyFamilyContext";
import LegacyItemProvider from "../LegacyItem/context/LegacyItemContext";
import LegacyModelProvider from "../LegacyModel/context/LegacyModelContext";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LegacyDistributorContext from "../LegacyDistributor/context/LegacyDistributorContext";
import LegacyJobProvider from "../LegacyJob/context/LegacyJobContext";
import LegacyProtocolVersionProvider from "../LegacyProtocolVersion/context/LegacyProtocolVersionContext";
import ERMJobProvider from "../ScanningICCJob/context/ERMJobContext";

const LegacyProvider: FC<IProps> = ({ children }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <LegacyJobProvider>
      <LegacyModelProvider>
        <LegacyCategoryProvider>
          <LegacyFamilyProvider>
            <LegacyDistributorContext>
              <LegacyItemProvider>
                <LegacyAssignedItemsContext>
                  <LegacyCodeHistoryContext>
                    <LegacyProtocolVersionProvider>
                      <ERMJobProvider>
                        <LegacyBatchProvider>{children}</LegacyBatchProvider>
                      </ERMJobProvider>
                    </LegacyProtocolVersionProvider>
                  </LegacyCodeHistoryContext>
                </LegacyAssignedItemsContext>
              </LegacyItemProvider>{" "}
            </LegacyDistributorContext>
          </LegacyFamilyProvider>
        </LegacyCategoryProvider>
      </LegacyModelProvider>
    </LegacyJobProvider>
  </MuiPickersUtilsProvider>
);

export default LegacyProvider;
