import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import { itemFirmwareRoutes as itemRoutes } from "./constants";
import List from "./containers/MessageGroupContainer";
import CreateMessageForm from "./components/itemForm";
import EditMessageGroupContainer from "./containers/EditMessageGroupContainer";
interface IProps {}

export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: itemRoutes.add, element: <CreateMessageForm /> },
    { path: itemRoutes.edit, element: <EditMessageGroupContainer /> },
  ],
};

const ItemFirmwareIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default ItemFirmwareIndex;
