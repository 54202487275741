import React, { useCallback, useState } from "react";
import { nanoid } from "nanoid";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { MenuItem, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import * as _ from "lodash";
// @ts-ignore
const useStyles = makeStyles(styles);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0,
    margin: 4,
  },
}))(Tooltip);

interface IProps {
  name?: string;
  isDistributor?:boolean
  menus: {
    action: () => void;
    name: string;
    menuItem?: React.ReactNode;
    hidden?: boolean;
    props?: any;
  }[];
}
const HoverDropdown: React.FC<IProps> = ({ name, menus, isDistributor }) => {
  const classes = useStyles();
  const getKey = useCallback(
    (str: any, idx: any) => encodeURI(`${str},${idx}`),
    []
  );
  const [showTooltip, setShowTooltip] = useState(false);
  const activeMenus = menus.filter((menu) => menu.hidden !== false);
  return (
    <LightTooltip
      interactive
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      title={
        <React.Fragment>
          {menus.map((item, index) => {
            if (item.hidden) {
              return null;
            }
            const props = item.props
              ? { ...item.props }
              : { onClick: item.action };
            return !item.menuItem ? (
              <MenuItem
                key={nanoid() + getKey(index, item.name) + name}
                {...props}
              >
                {item.name}
              </MenuItem>
            ) : (
              <div key={nanoid() + getKey(index, item.name)}>
                {item.menuItem}
              </div>
            );
          })}
        </React.Fragment>
      }
    >
      {!_.isEmpty(activeMenus) ? (
        <div className={`${classes.iconWrapper} ${isDistributor ? classes.iconWrapperHover : ''}`}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={classes.tableActionButton}
          onClick={() => setShowTooltip(!showTooltip)}
          style={isDistributor ? { opacity: 0.3, pointerEvents: "none" } : {}}
        >
          <MoreVertIcon />
        </IconButton>
        {isDistributor && (
          <span className={classes.stopSign} role="img" aria-label="Stop">
            🚫
          </span>
        )}
      </div>
      ) : (
        <div></div>
      )}
    </LightTooltip>
  );
};

export default HoverDropdown;
