import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { legacyRoutes } from './constants';
import List from "./containers/LegacyContainer";
interface IProps { }

export const routes = {
         path: legacyRoutes.list,
         element: <DefaultOutlet />,
         children: [
           { index: true, element: <List /> },
         ],
       };

const LegacyCodeHistoryIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet
    />
  );
};

export default LegacyCodeHistoryIndex;
