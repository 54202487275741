import useNotifier from "hooks/useNotifier";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddItem from "../components/AddItem";
import { defaultItem, itemRoutes } from "../constants";
import { AssetAccountContext } from "../context/AssetAccountProvider";

import { useAuth } from "admin/auth/context/AuthProvider";
import useDistributor from "apps/clientService/app/distributor/hooks/useDistributor";
import {
  PairAssetAccountInput,
  UpdateAssetAccountInput,
} from "../../types/accountsGlobalTypes";
import usePairAssetAccount from "../hooks/usePairAssetAccount";
import { GetSpecificAssetAccount_getSpecificAssetAccount } from "../types/GetSpecificAssetAccount";

const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const {
    refetchItems: refetch,
    getItem,
    _setItem,
    refetchClientItems,
  } = useContext(AssetAccountContext);
  const { isDistributor, distributorId } = useAuth();
  const { personId } = useDistributor();

  useEffect(() => {
    _setItem({} as GetSpecificAssetAccount_getSpecificAssetAccount); // eslint-disable-next-line
  }, []);

  // create mutations
  const { mutation: createItem, mutationOpts: createItemOpts } =
    usePairAssetAccount({
      onComplete: async (data) => {
        notify({
          status: "success",
          text: "Created successfully.",
        });

        try {
          if (getItem) {
            getItem({ variables: { id: data?._id } });
          }
          if (!isDistributor && refetch) await refetch();
          if (isDistributor && refetchClientItems) await refetchClientItems();
          history(itemRoutes.getEditLink(data?._id));
        } catch (error) {
          notify({
            status: "error",
            text: `${error}`,
          });
          history(itemRoutes.list);
        }
      },
    });

  const handleSubmit = (input: PairAssetAccountInput) => {
    try {
      const itemId = input.itemId === "_" ? "" : input.itemId;
      createItem({
        variables: {
          pairAssetAccountInput: {
            clientId: distributorId,
            credit: {
              balance: input.credit?.balance || 0,
              currency: input.credit?.currency || defaultItem.credit.currency,
              customerId:
                input.credit?.customerId || defaultItem.credit.customerId,
            },
            itemId: itemId || "",
            paySchedule: input.paySchedule || defaultItem.paySchedule,
            userId: personId,
          },
        },
      });
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem as UpdateAssetAccountInput}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
