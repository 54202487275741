import {
  Checkbox,
  ClickAwayListener,
  Fade,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { HeaderGroup } from 'react-table';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { useDispatch } from 'react-redux';
import { UserPrefActions } from 'store/actions/userPrefActions';
import { useLocation } from 'react-router';

interface IProps {
  headerGroups: HeaderGroup;
  setRemovedHeaders: Dispatch<SetStateAction<string[]>>;
  handleUpdateHeader: (removedHeaders: string[]) => void;
  removedHeaders: string[];
}

const ToggleColumnList: React.FC<IProps> = ({
  headerGroups,
  setRemovedHeaders,
  handleUpdateHeader,
  removedHeaders,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [columnHeaders] = useState(headerGroups);
  const [multipleSelect, setMultipleSelect] = React.useState<string[]>(
    removedHeaders
  );

  useEffect(() => {
    if (multipleSelect.length !== removedHeaders.length) {
      setMultipleSelect(removedHeaders);
    }
    // eslint-disable-next-line
  }, [removedHeaders]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleColumn = (item: string) => {
    let items = [...multipleSelect];
    if (items.indexOf(item) === -1) {
      items.push(item);
    } else {
      items = items.filter((current) => item !== current);
    }
    dispatch(
      UserPrefActions.toggleColumn({
        [location.pathname]: items,
      })
    );
    setMultipleSelect(items);
    setRemovedHeaders(items);
    handleUpdateHeader(items);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <Tooltip
          id='tooltip-top'
          title='Hide/Show table columns'
          placement='top'
        >
          <IconButton
            aria-controls='fade-menu'
            aria-haspopup='true'
            onClick={handleClick}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{ padding: 0 }}
          >
            <ViewColumnIcon className="toggle-btn" />
          </IconButton>
        </Tooltip>
        <Menu
          id='fade-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {columnHeaders.headers
            .filter(
              (item) =>
                !['', 'Actions'].includes(item.Header as string) &&
                typeof item.Header === 'string'
            )
            .map((prop, key) => (
              <MenuItem
                onClick={() => handleToggleColumn(prop.Header as string)}
                key={key}
              >
                <Checkbox
                  checked={multipleSelect.indexOf(prop.Header as string) === -1}
                />
                <ListItemText primary={prop.Header as ReactNode} />
              </MenuItem>
            ))}
        </Menu>
      </>
    </ClickAwayListener>
  );
};

export default ToggleColumnList;
