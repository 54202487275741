import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/itemTableData';
import { ItemContext } from '../context/ItemProvider';
import CardWrapper from '@components/Card/CardWrapper';
import { itemsRoutes } from '../constants';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { QueryOrder } from 'admin/types/globalTypes';
import { useLazyGetAllItemsQuery } from '../queries';
import { useParams } from 'react-router-dom';
import { PAGE_LIST_LIMIT } from '@constants';

interface IRTable {
  batchID?: string
}

const AllItemsPage: React.FC<IRTable> = ({ batchID }) => {
  const { isDistributor } = useAuth();
  const { itemId = '' } = useParams();
  const {
    tableActions,
  } = useContext(ItemContext);
  const [
    getItems,
    { data: itemData, loading },
  ] = useLazyGetAllItemsQuery({
    first: PAGE_LIST_LIMIT ,
    queryorder: QueryOrder.DESC,
    search: itemId || batchID
  });
  useEffect(() => {
    if (loading) return
    getItems();
    // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              itemTableData({}).header as Column[]
            }
            data={itemData?.getAllItems?.page?.edges || []}
            checkedIds={[]}
            tableActions={tableActions}
            isLoading={loading}
            addItemPageLink={itemsRoutes.add}
            addItemBtnText='Add Items'
            hideRightToolbar={isDistributor}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
export default AllItemsPage;
