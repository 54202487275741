import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();

  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <>
              <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
          <Tooltip
            title="Delete"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              id="expander"
              aria-label="Delete"
              className={classes.tableActionButton}
              style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
            >
              <Delete
                className={classes.tableActionButtonIcon + " " + classes.delete}
              />
            </IconButton>
          </Tooltip>
          {(true) && (
            <span className={classes.stopSign} role="img" aria-label="Stop">
              🚫
            </span>
          )}
        </div>
        <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
      <Tooltip
        id='expander'
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}

      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
          style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      { (true) && (
            <span className={classes.stopSign} role="img" aria-label="Stop">
              🚫
            </span>
          )}
      </div>
      <HoverDropdown
      isDistributor={true}
      menus={[
        {
          name: "",
          action: () => {
          },
        },
      ]}
    /></>
    </div>
  );
};

export default TableActions;
