import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import { useNavigate } from 'react-router-dom';
import { itemRoutes } from '../constants';
import { deletePayPlanMutation } from '../mutations';
import { DeletePayPlan, DeletePayPlanVariables } from '../types/DeletePayPlan';
import { PayPlanTemplateContext } from '../context/EventProvider';
import clientSimulator from 'utils/clientSimulator';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {refetchItems } = useContext(PayPlanTemplateContext)

  const [deletePayPlanTemplate] = useMutation<DeletePayPlan, DeletePayPlanVariables>(
    deletePayPlanMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: "success",
          text: "Payment plan Template Deleted successfully",
        });
        refetchItems && refetchItems();
        setIsModalOpen(false)
      },
    }
  );
  return (
    <div className='actions-right' style={{ display: 'flex' }}>
            <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id='expander'
        onClick={() => {
          history(itemRoutes.getEditLink(row.values['node._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <HoverDropdown
      isDistributor={true}
      menus={[
        {
          name: "",
          action: () => {
          },
        },
      ]}
    />

      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deletePayPlanTemplate({
            variables: { payPlanTemplateId: row.values['node._id'] },
          })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this Pay Plan Template?</div>
      </ActionModal>
    </div>
  );
};

export default TableActions;
