import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyTableActions';
import LinkCell from '../components/LinkCell';

export const legacyTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <Actions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    { Header: 'productItemID', accessor: 'productItemID', Cell: LinkCell },
    { Header: 'productItemOEM_SN', accessor: 'productItemOEM_SN' },
    { Header: 'productBatchNumber', accessor: 'productBatchNumber' },
    { Header: 'assignedItemsID', accessor: 'assignedItemsID' },
    { Header: 'distributorID', accessor: 'distributorID' },
    { Header: 'ermid', accessor: 'ermid' },
    { Header: 'codegenid', accessor: 'codegenid' },
    {
      Header: 'lifeCycleStatus',
      accessor: 'lifeCycleStatus',
    },
    {
      Header: 'customerName',
      accessor: 'customerName',
    },
    {
      Header: 'contactEmail',
      accessor: 'contactEmail',
    },
    {
      Header: 'contactLastName',
      accessor: 'contactLastName',
    },
    {
      Header: 'contactFirstName',
      accessor: 'contactFirstName',
    },
    {
      Header: 'itemAssignDate',
      accessor: 'itemAssignDate',
      Cell: DateCell,
    },

    {
      Header: 'itemAssignNote',
      accessor: 'itemAssignNote',
      Cell: DescriptionCell,
    },
    {
      Header: 'valuePerDay',
      accessor: 'valuePerDay',
    },
    {
      Header: 'valueDays',
      accessor: 'valueDays',
    },
    {
      Header: 'longevityDays',
      accessor: 'longevityDays',
    },
    {
      Header: 'activateDate',
      accessor: 'activateDate',
    },
    {
      Header: 'deployNotes',
      accessor: 'deployNotes',
    },
    {
      Header: 'assignedCodes',
      accessor: 'assignedCodes',
    },
  ],
};
