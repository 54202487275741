import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';

import {
  UpdateBrokerInput,
} from '../../types/eventsGlobalTypes';
import { BrokerContext } from '../context/BrokerProvider';
import useCreateBroker from '../hooks/useCreateBroker';

const AddItemContainer = () => {
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(BrokerContext);

  // update when client Id is provided
  const {
    mutation: createItem,
    mutationOpts: createItemOpts,
  } = useCreateBroker({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateBrokerInput) => {
    createItem({
      variables: {
        createBrokerInput: {
          login: input.login || '',
          name: input.name || '',
          password: input.password || '',
          url: input.url || '',
          port: input.port || 8000,
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
