import TextInput from '@components/CustomInput/TextInput';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Button, createStyles, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray } from 'formik';
import { AttributeDto } from 'admin/types/globalTypes';


interface IProps {
  index: number;
  remove: (index: number) => void;
  isEdit?: boolean;
  propValue?: string;
  field: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(3),
      width: 50,
    },
  })
);

const AttributeFormInput: React.FC<IProps> = ({
  index,
  remove,
  field
}) => {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem sm={1}>
        <Button
          title='remove'
          className={classes.selectEmpty}
          onClick={() => {
            remove(index);
          }}
        >
          <RemoveIcon />
        </Button>
      </GridItem>
      <GridItem sm={3}>
        <TextInput
          label='Prop'
          name={`${field}[${index}].prop`}
        />
      </GridItem>
      <GridItem sm={3}>
        <TextInput
          label='Value'
          name={`${field}[${index}].value`}
        />
      </GridItem>
      <GridItem sm={3}>
        <TextInput
          label='Meta'
          name={`${field}[${index}].meta`}
        />
      </GridItem>
    </GridContainer>
  );
};

interface IAttributeForm {
  field: string;
  values: AttributeDto[] | null | undefined
}
const AttributeForm: React.FC<IAttributeForm> = ({ field, values }) => {
  return (<FieldArray
    name={field}
    render={(arrayHelpers) => {
      const remove = (index: number) => {
        arrayHelpers.remove(index);
      };
      const add = () => {
        arrayHelpers.push({
          value: '',
          prop: '',
          meta: '',
        });
      };
      return (
        <>
          {values && values.map((item, index) => {
            return (
              <AttributeFormInput
                index={index}
                field={field}
                propValue={item.prop || ''}
                remove={remove}
                key={index + '-att-form'}
              />
            );
          })}
          <Typography>
            <Tooltip
              id='tooltip-top'
              title='Add an attribute'
              placement='top'
            >
              <Button
                onClick={() => {
                  add();
                }}
              >
                <AddIcon />
              </Button>
            </Tooltip>
          </Typography>
        </>
      );
    }}
  />)
}
export default AttributeForm;
