import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';


import useOVSPagination from 'hooks/useOVSPagination';
import { GetAllAvatars, GetAllAvatarsVariables, GetAllAvatars_getAllAvatars_page_edges } from '../types/GetAllAvatars';
import { useLazyGetAllAvatarsQuery } from '../queries';
import { funNumberAgr } from '@types';

export interface IAvatarContext {
  items: GetAllAvatars_getAllAvatars_page_edges[];
  setItems: (item: GetAllAvatars_getAllAvatars_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  activeStage: number;
  setActiveStage: (stage: number) => void;
  tableActions: ITableActions[];
  refetchItems:
  | ((
    variables?: Partial<GetAllAvatarsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllAvatars>>)
  | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setPageLimit: funNumberAgr
}

export const AvatarContext = React.createContext<IAvatarContext>(
  {} as IAvatarContext
);

const SignalProvider: React.FC<IProps> = ({ children }) => {
  const [activeStage, setActiveStage] = useState(0);
  const [item, setItem] = React.useState(
    {} as GetAllAvatars_getAllAvatars_page_edges
  );
  const [items, setItems] = React.useState<
    GetAllAvatars_getAllAvatars_page_edges[]
  >([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  /// hooks
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,pageListLimit, setPageListLimit
  } = useOVSPagination();

  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllAvatarsQuery({
    first: pageListLimit,
  });

  useEffect(() => {
    setItems(data?.getAllAvatars?.page?.edges || []);
    setPaging({
      ...data?.getAllAvatars?.pageData,
      ...data?.getAllAvatars?.page?.pageInfo,
    });
    // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllAvatarsVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAvatars?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAvatars?.pageData,
        ..._data?.data?.getAllAvatars?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const value = React.useMemo(
    () => ({
      item,
      setItem,
      items,
      setItems,
      loading: loading,
      getItems: getItems,
      refetchItems,
      tableActions: [],
      activeStage,
      setActiveStage,
      paging,
      goTo, setPageLimit
    }), // eslint-disable-next-line
    [items, loading, paging]
  );

  return (
    <AvatarContext.Provider value={value}>{children}</AvatarContext.Provider>
  );
};

export default SignalProvider;
