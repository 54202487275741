import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { updateBrokerMutation } from '../mutations';
import { UpdateBroker, UpdateBrokerVariables } from '../types/UpdateBroker';

const useUpdateBroker = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UpdateBroker,
    UpdateBrokerVariables
  >(updateBrokerMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      } else {
        notify({ text: 'Updated successfully', status: 'success' });
      }
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useUpdateBroker;
