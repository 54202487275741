import { PAGE_LIST_LIMIT } from '@constants';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { isEmptyArray } from 'formik';
import * as _ from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react';
import { DistributorContext } from '../context/DistributorProvider';
import {
  useGetAllDistributorsQuery,
  useGetSpecificDistributorQuery,
} from '../queries';

const useDistributor = () => {
  const { distributorId } = useAuth();
  const [options, setOptions] = useState<{ _id: string; name: string }[]>([]);
  const [personId, setPersonId] = useState('');

  const { data, loading } = useGetAllDistributorsQuery({ first: 100 });
  const {
    data: distributorData,
    loading: isLoading,
  } = useGetSpecificDistributorQuery({ id: distributorId });

  useEffect(() => {
    setPersonId(
      distributorData?.getSpecificDistributor?.orgContactPerson?._id || ''
    );
  }, [distributorData]);

  useEffect(() => {
    setOptions(
      _.map(_.get(data, 'getAllDistributors.page.edges',[]), ((item) => ({
        _id: _.get(item, 'node._id', ''),
        name: _.get(item, 'node.orgContactPerson.name', ''),
      }))) || []
    ); // eslint-disable-next-line
  }, [data]);

  return {
    options,
    loading: loading || isLoading,
    personId,
  };
};

export const useLazyDistributor = () => {
  const { items: data, getItems, loading, refetchItems } = useContext(
    DistributorContext
  );

  
  useEffect(() => {
    if (isEmptyArray(data)) {
      getItems();
    } // eslint-disable-next-line
  }, [])
  const search = async (search: string) => {
   
    if (!refetchItems) {
      return Promise.resolve(
        data?.map((it) => ({
          name: it?.node?.orgContactPerson?.contact?.email,
          value: it?.node?._id,
        }))
      );
    }
    const refetchData = await refetchItems({
      first: PAGE_LIST_LIMIT,
      search,
    });

    const items = refetchData?.data?.getAllDistributors?.page?.edges?.map(
      (item) => {
        return {
          label: item?.node?.orgContactPerson?.contact?.email || '',
          value: item.node?._id || '',
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  };

  return useMemo(
    () => ({
      data,
      getItems,
      loading,
      search,
      options:
      data?.map((item) => ({
          _id: item?.node?._id || '',
          name: item?.node?.orgContactPerson?.contact?.email || '',
        })) || [],
    }), // eslint-disable-next-line
    [data, loading]
  );
};
export default useDistributor;
