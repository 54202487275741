import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { codeGenSettingsField } from '../constants';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { DistributorSetting } from '../types/DistributorSetting';
const CodeGenSettingsForm: React.FC<{
    formBag: FormikProps<DistributorSetting>;
    disableLoginButton: boolean;
    actionButtonName?: string;
}> = (props) => {
    const { formBag, disableLoginButton, actionButtonName = 'Add' } = props;
    const { isSuperAdmin } = useAuth()
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
               {isSuperAdmin && <GoBackHeader title={actionButtonName} />} 
                <Form>
                    <GridContainer>
                        <GridHalfWrapper>
                            <TextInput
                                label='Free Code Count'
                                name={codeGenSettingsField.freeCodeCount}
                                type='number'
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Reset Code Count'
                                name={codeGenSettingsField.resetCodeCount}
                                type='number'
                            />
                        </GridHalfWrapper>
                        {isSuperAdmin &&
                            <>
                                <GridHalfWrapper>
                                    <TextInput
                                        label='Code Generation Interval(Milliseconds)'
                                        name={codeGenSettingsField.codeGenInterval}
                                        type='number'
                                    />
                                </GridHalfWrapper>
                                <GridHalfWrapper>
                                    <TextInput
                                        label='Day Code Count Limit'
                                        name={codeGenSettingsField.dayCodeCountLimit}
                                        type='number'
                                    />
                                </GridHalfWrapper>
                                <GridHalfWrapper>
                                    <TextInput
                                        label='Total Code Count Limit'
                                        name={codeGenSettingsField.totalCodeCountLimit}
                                        type='number'

                                    />
                                </GridHalfWrapper>
                                <GridHalfWrapper>
                                    <TextInput
                                        label='Max Credit Stack Days'
                                        name={codeGenSettingsField.maxCreditStackDays}
                                        type='number'
                                    />
                                </GridHalfWrapper>
                                <GridHalfWrapper>
                                    <TextInput
                                        label='Max Credit Stack Code Events'
                                        name={codeGenSettingsField.maxCreditStackCodeEvents}
                                        type='number'
                                    />
                                </GridHalfWrapper>
                            </>
                        }
                    </GridContainer>
                    <Grid>
                        <Button
                            color='primary'
                            onClick={() => {
                                formBag.submitForm();
                            }}
                            disabled={disableLoginButton}
                        >
                            Save
                        </Button>
                    </Grid>
                </Form>
            </GridItem>
        </GridContainer>
    );
};

export default CodeGenSettingsForm;
