import {
  IconButton,
  MenuItem,
  Tooltip,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import React, { useContext } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import ActionModal from '@components/Modal/ActionModal';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import { CSVLink } from 'react-csv';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
import { Data } from './TableToolBar';

// @ts-ignore
const useStyles = makeStyles(styles);

interface IProps {
  selectedFlatRows: Row[];
  tableActions?: ITableActions[];
  csvData: string | Data;
}

const MoreActions: React.FC<IProps> = ({
  selectedFlatRows,
  tableActions,
  csvData,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [actionIndex, setActionIndex] = React.useState(0);

  // context
  const { tableActionLoading } = useContext(GlobalSimulatorContext);

  const menuItems = tableActions?.map((actionItem, index) => ({
    name: actionItem.title,
    action: () => {
      if (actionItem.showConfirmationModal) {
        // open user confirmation modal
        setActionIndex(index);
        setIsModalOpen(true);
      } else {
        // execute the function without confirmation
        actionItem.action(selectedFlatRows);
      }
    },
  }));

  return (
    <>
      <Tooltip
        title='More actions'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        {!tableActionLoading ? (
          <HoverDropdown
            name="moreActions"
            menus={[
              {
                name: 'Download',
                action: () => {},
                menuItem: (
                  <MenuItem onClick={() => {}}>
                    <CSVLink
                      filename='data.csv'
                      color='inherit'
                      data={csvData}
                      target='_blank'
                      style={{ color: '#212121' }}
                    >
                      Download
                    </CSVLink>
                  </MenuItem>
                ),
              },
              ...(menuItems || []),
            ]}
          />
        ) : (
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={() => {}}
            className={classes.tableActionButton}
          >
            <CircularProgress size={18} />
          </IconButton>
        )}
      </Tooltip>

      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() => {
          tableActions && tableActions[actionIndex].action(selectedFlatRows);
          setIsModalOpen(false);
        }}
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText={tableActions && tableActions[actionIndex]?.title}
      >
        <div>
          {tableActions &&
            tableActions[actionIndex]?.description(
              selectedFlatRows.length.toString()
            )}
        </div>
      </ActionModal>
    </>
  );
};

export default MoreActions;
