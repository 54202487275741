export const itemBatchFields = {
  batchNumber: 'batchNumber',
  batchDate: 'batchDate',
  description: 'description',
  itemSKUId: 'itemSKUId',
  algorithmType: 'algorithmType',
  secretKey: 'secretKey',
  startingCode: 'startingCode'
};

export const itemBatchRoutes = {
  list: '/admin/batch',
  add: '/admin/batch/add',
  edit: '/admin/batch/:itemId',
  getEditLink: (itemId: string) => `/admin/batch/${itemId}`,
};
