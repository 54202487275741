import React from 'react';
interface IProps {
  value: string[];
}

const TotalItemsCell: React.FC<IProps> = ({ value }) => {
  return <div>{value?.length || '--'}</div>;
};

export default TotalItemsCell;
