import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';

import {
  GetAllSignals,
  GetAllSignalsVariables,
  GetAllSignals_getAllSignals_page_edges,
} from '../types/GetAllSignals';
import { useLazyGetAllSignalsQuery } from '../queries';
import useOVSPagination from 'hooks/useOVSPagination';
import { PAGE_LIST_LIMIT } from '@constants';

export interface ISignalContext {
  items: GetAllSignals_getAllSignals_page_edges[];
  setItems: (item: GetAllSignals_getAllSignals_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  activeStage: number;
  setActiveStage: (stage: number) => void;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<GetAllSignalsVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllSignals>>)
    | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
}

export const SignalContext = React.createContext<ISignalContext>(
  {} as ISignalContext
);

const SignalProvider: React.FC<IProps> = ({ children }) => {
  const [activeStage, setActiveStage] = useState(0);
  const [item, setItem] = React.useState(
    {} as GetAllSignals_getAllSignals_page_edges
  );
  const [items, setItems] = React.useState<
    GetAllSignals_getAllSignals_page_edges[]
  >([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  /// hooks
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,
  } = useOVSPagination();

  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllSignalsQuery({
    first: PAGE_LIST_LIMIT,
  });

  useEffect(() => {
    setItems(data?.getAllSignals?.page?.edges || []);
    setPaging({
      ...data?.getAllSignals?.pageData,
      ...data?.getAllSignals?.page?.pageInfo,
    });
    // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllSignalsVariables = {
      first: PAGE_LIST_LIMIT,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllSignals?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllSignals?.pageData,
        ..._data?.data?.getAllSignals?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const value = React.useMemo(
    () => ({
      item,
      setItem,
      items,
      setItems,
      loading: loading,
      getItems: getItems,
      refetchItems,
      tableActions: [],
      activeStage,
      setActiveStage,
      paging,
      goTo,
    }), // eslint-disable-next-line
    [items, loading, paging]
  );

  return (
    <SignalContext.Provider value={value}>{children}</SignalContext.Provider>
  );
};

export default SignalProvider;
