import { useMutation } from "@apollo/client";
import _ from "lodash";
import GridLoader from "@components/Loaders/GridLoader";
import useNotifier from "hooks/useNotifier";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSpecificItemQuery } from "apps/things/app/item/queries";
import {
  GetSpecificItem_getSpecificItem,
  GetSpecificItem_getSpecificItem_codeGenerator,
} from "apps/things/app/item/types/GetSpecificItem";
import clientSimulator from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import { CodeGeneratorContext } from "../context/CodeGeneratorProvider";
import {
  generateDaysCodeMutation,
  initializeCodeGeneratorMutation,
} from "../mutations";

import {
  InitializeCodeGenerator,
  InitializeCodeGeneratorVariables,
} from "../types/InitializeCodeGenerator";
import {
  GenerateDaysCode,
  GenerateDaysCodeVariables,
} from "../types/GenerateDaysCode";
import { DISABLED } from "../constants";
import { CodeSystemType } from "admin/types/globalTypes";
import { useGetAllCodeSystemsQuery } from "../../codeSystem/queries";
import { GetAllCodeSystems_getAllCodeSystems_page_edges } from "../../codeSystem/types/GetAllCodeSystems";
import { useCodeEventProvider } from "../../codeEvent/context/CodeEventProvider";
import AddCodeGenerationRequest from "../components/AddCodeGenerationRequest";
import {SendSms, SendSmsVariables} from "../types/SendSms";
import { sendSmsMutation } from "../mutations";
interface IProps {
  itemID?: string;
  generateDayCodeCallback?: () => void;
}
const AddCodeGenerationRequestContainer: React.FC<IProps> = ({
  itemID = "",
  generateDayCodeCallback,
}) => {
  const notify = useNotifier();
  const { itemId: _itemId = "" } = useParams();
  const [itemId] = useState(itemID || _itemId);
  const [codeSystems, setCodeSystems] = useState<
    GetAllCodeSystems_getAllCodeSystems_page_edges[]
  >([]);

  const handleSetCodeSystems = useCallback(
    (value: GetAllCodeSystems_getAllCodeSystems_page_edges[]) =>
      setCodeSystems(value),
    []
  );
  const { data: codeSystemsData } = useGetAllCodeSystemsQuery();
  useEffect(() => {
    if (codeSystemsData) {
      handleSetCodeSystems(
        _.get(codeSystemsData, "getAllCodeSystems.page.edges", [])
      );
    }
  }, [codeSystemsData, handleSetCodeSystems]);

  const getCodeSystemID = (codeSystemType: CodeSystemType): string => {
    const system = codeSystems?.filter(
      (it) => it?.node?.system === codeSystemType
    );
    return _.get(system, "[0].node._id") || "";
  };
  const {
    item,
    setItem,
    setItemID,
    setOemItemID,
    setHashRoot,
    setHashTop,
    setHashChainLength,
    setHashIndex,
    setOtpCount,
    setDeviceState,
    resetContext,
    setOtpDec,
    setOtpHex
  } = React.useContext(CodeGeneratorContext);

  const [getItem, { data: itemNode, loading: getItemLoading }] =
    useGetSpecificItemQuery({
      itemId,
    });


  useEffect(() => {
    resetContext();
    getItem({ variables: { itemId } });
    // eslint-disable-next-line
  }, [itemId]);

  useEffect(() => {
    if (!getItemLoading && itemNode) {
      setItem(
        itemNode?.getSpecificItem || ({} as GetSpecificItem_getSpecificItem)
      );
      setItemID(itemNode?.getSpecificItem?._id || "");
      setOemItemID(itemNode?.getSpecificItem?.oemItemID || "");
      handleSetGenerator();
    }
    // eslint-disable-next-line
  }, [itemNode]);

  const [sendSms] = useMutation<SendSms, SendSmsVariables>(sendSmsMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
        if(data) {
        notify({text: data.sendSms, status:"success"})
        }
    },
    onError: (error) => {
      handleGQLErrors(notify, error);
     }
  })

  const [createDayCode, createDayCodeOpts] = useMutation<
    GenerateDaysCode,
    GenerateDaysCodeVariables
  >(generateDaysCodeMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
      try {
        notify({ text: "Day code generated successfully", status: "success" });
        setOtpDec(data.generateDaysCode.codeDec)
        setOtpHex(data.generateDaysCode.codeHex)
        await handleRefetchData();
        generateDayCodeCallback && generateDayCodeCallback();
      } catch (error) {
        console.warn("Error handling create daycode callback");
      }
    },
    onError: (error) => {
      try {
        handleGQLErrors(notify, error);
      } catch (error) {
        notify({ text: "Error", status: "error" });
      }
    },
  });


  const [initializeCodeGen] = useMutation<
    InitializeCodeGenerator,
    InitializeCodeGeneratorVariables
  >(initializeCodeGeneratorMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: "success",
        text: "Code Generator initialized successfully.",
      });
      setHashRoot(data?.initializeCodeGen?.hashRoot);
      setHashTop(data?.initializeCodeGen?.hashTop);
      setOtpCount(data?.initializeCodeGen?.codeCount);
      setHashIndex(data?.initializeCodeGen?.hashIndex);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const { refetch } = useCodeEventProvider();

  const handleRefetchData = async () => {
    // TODO: refetch code events
    if (refetch) refetch();
    // await refetchCodeEvents();
  };

 
  const handleDaysCode = async (codeDays: number) => {
    await createDayCode({
      variables: {
        generateDaysCodeInput: {
          itemId,
          codeDays: parseInt(codeDays.toString()),
        },
      },
    });
  };

const handleSendSms = async (telephone: string, message:string) => {
  await sendSms({
    variables: {
      telephone: telephone,
      message: message
    }
  })
}

  const handleSetGenerator = async () => {
    const itemCodeGenerator: GetSpecificItem_getSpecificItem_codeGenerator =
      itemNode?.getSpecificItem
        ?.codeGenerator as GetSpecificItem_getSpecificItem_codeGenerator;

    if (itemCodeGenerator) {
      // assign the item to an initialized code gen
      setHashRoot(itemCodeGenerator?.hashRoot || "");
      setHashTop(itemCodeGenerator?.hashTop || "");
      setOtpCount(itemCodeGenerator?.codeCount || 0);
      setHashChainLength(itemCodeGenerator?.hashIndex);
      setHashIndex(itemCodeGenerator.hashIndex);

      // TODO: set code history
    } else {
      // initialize code generator
      setDeviceState(DISABLED); // device state should be disabled
      await initializeCodeGen({
        variables: {
          initializeCodeGenInput: {
            codeSystemId:
              item?.itemFirmware?.codeSystem === CodeSystemType.ACP2
                ? getCodeSystemID(CodeSystemType.ACP2)
                : getCodeSystemID(CodeSystemType.ACP1), // '6092568a40b418900023e25d': '60925d07e894e83a1c66c309',
            itemId,
          },
        },
      });
    }
  };

  if (getItemLoading) return <GridLoader />;

  return (
    <AddCodeGenerationRequest
      handleDaysCode={handleDaysCode}
      handleSendSms={handleSendSms}
      itemId={itemId}
      isLoading={
        createDayCodeOpts.loading
      }
    />
  );
};

export default AddCodeGenerationRequestContainer;
