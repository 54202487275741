import moment from 'moment';
import _ from 'lodash';
import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  defaultProductType,
  getDefaultDate,
  getDefaultSequence,
} from 'apps/things/app/utils';
import { LegacyItemContext } from '../context/LegacyItemContext';
import { legacyRoutes, itemFields } from '../constants';
import LegacyAddItem from '../components/LegacyAddItem';
import { ProductItemInput } from '../types';
import * as actions from '../redux/actions';
import { LegacyModelContext } from '../../LegacyModel/context/LegacyModelContext';
import { getAllItemSKUsIDQuery } from 'apps/things/app/sku/queries';
import clientSimulator from 'utils/clientSimulator';
import { getAllItemFirmwaresSimpleQuery } from 'apps/things/app/firmware/queries';

const AddItemContainer = () => {
  const { addItem } = itemFields;
  const notify = useNotifier();
  const history = useNavigate();

  const [lastItem, setLastItem] = useState(0);

  const { getItems, loading } = React.useContext(LegacyItemContext);
  const { items: dataProductModel, loading: productModelLoading } = useContext(
    LegacyModelContext
  );

  useEffect(() => {
    actions.LegacyActions.getLegacy('offset=0&limit=1')
      .then((res) => res.data)
      .then((res) => res?.results || [])
      .then((res) => {
        if (res.length > 0) {
          setLastItem(res[0].productItemID);
        }
      });
  }, []);

  const getItemFirmwareDetails = (itemFirmware: string, firmwareEdges: any) => {
    const defaultValue = {
      version: '',
      protocol: '',
      itemfirmwareId: '',
    };
    if (!itemFirmware) {
      return defaultValue;
    }

    const firmware = JSON.parse(itemFirmware) || {};
    const _itemFirmware = _.find(firmwareEdges, (o) => {
      return (
        o.version?.replace(/\s/g, '') === firmware.firmware?.replace(/\s/g, '')
      );
    });
    return {
      version: firmware.firmware,
      protocol: firmware.protocol,
      itemfirmwareId: _itemFirmware?._id,
    };
  };

  const handleSubmit = async (
    createItemInput: ProductItemInput,
    totalItems: number
  ) => {
    let firstItem = getDefaultSequence(
      '000000' + lastItem.toString(),
      6,
      999900
    );
    firstItem = firstItem !== '000NaN' ? firstItem : '000000';
    let sequences = [firstItem];

    for (var i = 1; i < totalItems; i++) {
      sequences.push(getDefaultSequence(sequences[i - 1], 6, 999900));
    }

    const sku = _.find(dataProductModel, function (o) {
      return o?.productModelID === createItemInput.productModelID;
    });

    const _data = await clientSimulator.query({
      query: getAllItemSKUsIDQuery,
      variables: { search: sku?.productModelName || '', first: 100 },
    });
    const skuEdges = _data?.data?.getAllItemSKUs?.page?.edges[0];
    const skuID = skuEdges?.node?._id;

    // fetch all firmwares
    const firmwareData = await clientSimulator.query({
      query: getAllItemFirmwaresSimpleQuery,
      variables: { first: 100 },
    });

    const firmwareEdges = firmwareData?.data?.getAllItemFirmwares?.page?.edges?.map(
      (it: any) => ({ ...it?.node })
    );

    const version = getItemFirmwareDetails(
      createItemInput.firmwareVersion || '',
      firmwareEdges
    );

    console.warn(version, ' found ');

    Promise.all(
      Array.from(Array(totalItems).keys()).map((item, index) => {
        // setTotalItems(totalItems + 1);
        return actions.LegacyActions.createLegacyRequest({
          ...createItemInput,
          skuID,
          timerScheme: 1, // indicate the item has been duplicated
          productItemOEM_SN: createItemInput?.productItemOEM_SN?.replace(
            'xxxxxx',
            sequences[index]
          ),
        });
      })
    ).then(async (res) => {
      notify({
        status: 'success',
        text: `${totalItems} items created successfully.`,
      });
      getItems();
      history(legacyRoutes.list);
    });
  };

  if (loading) return <GridLoader />;

  return (
    <LegacyAddItem
      initialValues={{
        [addItem.productModelID]: '',
        [addItem.creationDate]: moment().format(),
        [addItem.productItemOEM_SN]: `${defaultProductType}${getDefaultDate()}${'xxxxxx'}`,
        [addItem.lifeCycleStatus]: 'Created',
        [addItem.productBatchID]: '',
        [addItem.firmwareVersion]: '',
        [addItem.timerScheme]: '',
        [addItem.totalItems]: '',
        itemBatchNumber: '',
      }}
      handleSubmit={handleSubmit}
      isLoading={loading || productModelLoading}
    />
  );
};

export default AddItemContainer;
