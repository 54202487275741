export const itemFirmwareRoutes = {
  list: '/admin/message-group',
  add: '/admin/message-group/add',
  edit:'/admin/message-group/:itemId',
  getCodeEventsLink: (itemId: string) => `/admin/people-views/${itemId}`,
  getEditLink: (itemId: string) => `/admin/message-group/${itemId}`
};

export const itemFields = {
  messageGroupId: 'messageGroupId',
  messageTemplateId: 'messageTemplateId',
  
};

export const messageGroupFields = {
  name: "name",
  description: "description"
}

