/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountActions {
  ACTIVATE = "ACTIVATE",
  CREATE = "CREATE",
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
  FREEZE = "FREEZE",
  RESOLVE = "RESOLVE",
  SUSPEND = "SUSPEND",
}

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}

export enum ActionScope {
  CLIENT = "CLIENT",
  DEVELOPMENT = "DEVELOPMENT",
  GLOBAL = "GLOBAL",
  MANAGEMENT = "MANAGEMENT",
  SYSTEM = "SYSTEM",
}

export enum ActionTypes {
  CLIENT = "CLIENT",
  DEVELOPMENT = "DEVELOPMENT",
  GLOBAL = "GLOBAL",
  MANAGEMENT = "MANAGEMENT",
  SYSTEM = "SYSTEM",
}

export enum ActorTypes {
  ACCOUNT = "ACCOUNT",
  DEVICE = "DEVICE",
  EVENT = "EVENT",
  ORGANIZATION = "ORGANIZATION",
  PERSON = "PERSON",
  PLACE = "PLACE",
  SOFTWARE = "SOFTWARE",
}

export enum AgentTypes {
  COLLECTION = "COLLECTION",
  SALES = "SALES",
  SERVICE = "SERVICE",
}

export enum AssetAccountStages {
  ACCOUNT_ACTIVATED = "ACCOUNT_ACTIVATED",
  ACCOUNT_CLOSED = "ACCOUNT_CLOSED",
  ASSET_ACCOUNT_CREATED = "ASSET_ACCOUNT_CREATED",
  ASSET_UNLOCKED = "ASSET_UNLOCKED",
  ASSET_USER_PAIRED = "ASSET_USER_PAIRED",
  PAYPLAN_COMPLETED = "PAYPLAN_COMPLETED",
  PAYPLAN_LOCKED = "PAYPLAN_LOCKED",
}

export enum BatchState {
  Completed = "Completed",
  Scheduled = "Scheduled",
  Submitted = "Submitted",
}

export enum CodeSystemType {
  ACP1 = "ACP1",
  ACP2 = "ACP2",
}

export enum CodeTypes {
  DAYSCODE = "DAYSCODE",
  FREECODE = "FREECODE",
  RESETCODE = "RESETCODE",
  SYNCCODE = "SYNCCODE",
}

export enum LifeCycle {
  ASSIGNED = "ASSIGNED",
  INITIATED = "INITIATED",
  INSTALLED = "INSTALLED",
  PRODUCED = "PRODUCED",
  RECYCLED = "RECYCLED",
  RETURNED = "RETURNED",
  SHIPPED = "SHIPPED",
}

export enum OrgTypes {
  DISTRIBUTOR = "DISTRIBUTOR",
  FINANCIAL = "FINANCIAL",
  GOVERNMENT = "GOVERNMENT",
  INSTITUTION = "INSTITUTION",
  INVESTOR = "INVESTOR",
  SERVICE = "SERVICE",
  SUPPLIER = "SUPPLIER",
}

export enum PermissionInput {
  ACCOUNTS_SERVICE_CUSTOMERS = "ACCOUNTS_SERVICE_CUSTOMERS",
  ACTIVATE_ASSETACCOUNT = "ACTIVATE_ASSETACCOUNT",
  ACTIVATE_AVATAR = "ACTIVATE_AVATAR",
  ACTIVATE_CREDIT_ACCOUNT = "ACTIVATE_CREDIT_ACCOUNT",
  ADMIN_ASSIGN_PERMISSION_TO_SUBROLE = "ADMIN_ASSIGN_PERMISSION_TO_SUBROLE",
  ADMIN_GET_ALL_AGENTS_FOR_DISTRIBUTOR = "ADMIN_GET_ALL_AGENTS_FOR_DISTRIBUTOR",
  ADMIN_REGISTER_DISTRIBUTOR = "ADMIN_REGISTER_DISTRIBUTOR",
  ARCHIVE_SIGNAL_CLASS_BY_TIMESTAMP = "ARCHIVE_SIGNAL_CLASS_BY_TIMESTAMP",
  ASSIGIN_PUBLISHER_TO_SIGNAL = "ASSIGIN_PUBLISHER_TO_SIGNAL",
  ASSIGN_CODEGENERATOR_TO_ITEM = "ASSIGN_CODEGENERATOR_TO_ITEM",
  ASSIGN_CREDIT_ACCOUNT_TO_CUSTOMER = "ASSIGN_CREDIT_ACCOUNT_TO_CUSTOMER",
  ASSIGN_DEFAULT_LOGINUSER_PERMISSIONS_TO_SUBROLE = "ASSIGN_DEFAULT_LOGINUSER_PERMISSIONS_TO_SUBROLE",
  ASSIGN_ITEMBATCH_TO_ITEMFLEET = "ASSIGN_ITEMBATCH_TO_ITEMFLEET",
  ASSIGN_ITEMFLEET_TO_DISTRIBUTOR = "ASSIGN_ITEMFLEET_TO_DISTRIBUTOR",
  ASSIGN_ITEM_TO_ITEMFLEET = "ASSIGN_ITEM_TO_ITEMFLEET",
  ASSIGN_PAYMENT_PLAN_TO_ASSETACCOUNT = "ASSIGN_PAYMENT_PLAN_TO_ASSETACCOUNT",
  ASSIGN_PERMISSION_TO_ROLE = "ASSIGN_PERMISSION_TO_ROLE",
  ASSIGN_ROLE_PERMISSIONS_TO_SUBROLE = "ASSIGN_ROLE_PERMISSIONS_TO_SUBROLE",
  ASSIGN_ROLE_TO_USER = "ASSIGN_ROLE_TO_USER",
  ASSIGN_SUBROLE_TO_ROLE = "ASSIGN_SUBROLE_TO_ROLE",
  CHANGE_AGENT_SUB_ROLE = "CHANGE_AGENT_SUB_ROLE",
  CHANGE_LOGIN_USER_AUTHENTICATION_INSTANCE = "CHANGE_LOGIN_USER_AUTHENTICATION_INSTANCE",
  CHANGE_ROLE_LOGIN_USER_ASSIGN_PERMISSION_STATUS = "CHANGE_ROLE_LOGIN_USER_ASSIGN_PERMISSION_STATUS",
  CHANGE_SUBROLE_LOGIN_USER_SUBROLE = "CHANGE_SUBROLE_LOGIN_USER_SUBROLE",
  CHECK_DISTRIBUTOR_EMAIL_FOR_RESET_PASSWORD = "CHECK_DISTRIBUTOR_EMAIL_FOR_RESET_PASSWORD",
  CHECK_OVES_PERSONEL_EMAIL_FOR_RESET_PASSWORD = "CHECK_OVES_PERSONEL_EMAIL_FOR_RESET_PASSWORD",
  CLIENT_REGISTER_CUSTOMER = "CLIENT_REGISTER_CUSTOMER",
  CLOSE_ASSETACCOUNT = "CLOSE_ASSETACCOUNT",
  COPY_LEGACY_CODEEVENT_PARAMETERS = "COPY_LEGACY_CODEEVENT_PARAMETERS",
  COPY_LEGACY_CODEGEN_PARAMETERS = "COPY_LEGACY_CODEGEN_PARAMETERS",
  COPY_LEGACY_ITEMS_AND_PROVISION_ITEMS = "COPY_LEGACY_ITEMS_AND_PROVISION_ITEMS",
  CREATE_ADDRESS = "CREATE_ADDRESS",
  CREATE_AMAZON_PRODUCT_CATEGORY = "CREATE_AMAZON_PRODUCT_CATEGORY",
  CREATE_APPLICATION_SITE = "CREATE_APPLICATION_SITE",
  CREATE_ARTICLE = "CREATE_ARTICLE",
  CREATE_ASSET_ACCOUNT = "CREATE_ASSET_ACCOUNT",
  CREATE_AUTHENTICATION_INSTANCE = "CREATE_AUTHENTICATION_INSTANCE",
  CREATE_AUTHENTICATION_SUBINSTANCE = "CREATE_AUTHENTICATION_SUBINSTANCE",
  CREATE_AVATAR = "CREATE_AVATAR",
  CREATE_BRAND = "CREATE_BRAND",
  CREATE_BRAND_SELECTOR = "CREATE_BRAND_SELECTOR",
  CREATE_BROKER = "CREATE_BROKER",
  CREATE_CATEGORY = "CREATE_CATEGORY",
  CREATE_CATEGORY_SELECTOR = "CREATE_CATEGORY_SELECTOR",
  CREATE_DISPATCHER = "CREATE_DISPATCHER",
  CREATE_ECOMMERCE_PRODUCT = "CREATE_ECOMMERCE_PRODUCT",
  CREATE_EVENT = "CREATE_EVENT",
  CREATE_ITEM = "CREATE_ITEM",
  CREATE_ITEMBATCH = "CREATE_ITEMBATCH",
  CREATE_ITEMFIRMWARE = "CREATE_ITEMFIRMWARE",
  CREATE_ITEMFLEET = "CREATE_ITEMFLEET",
  CREATE_ITEMSKU = "CREATE_ITEMSKU",
  CREATE_ITEMSKUS_IN_BULK = "CREATE_ITEMSKUS_IN_BULK",
  CREATE_ITEMSTOCK = "CREATE_ITEMSTOCK",
  CREATE_LOGIN_USER = "CREATE_LOGIN_USER",
  CREATE_MATERIAL = "CREATE_MATERIAL",
  CREATE_MEDIA_META = "CREATE_MEDIA_META",
  CREATE_MESSAGE = "CREATE_MESSAGE",
  CREATE_MULTIPLE_SUBINSTANCES_UNDER_INSTANCE = "CREATE_MULTIPLE_SUBINSTANCES_UNDER_INSTANCE",
  CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
  CREATE_PACK = "CREATE_PACK",
  CREATE_PART = "CREATE_PART",
  CREATE_PAYPLAN = "CREATE_PAYPLAN",
  CREATE_PERMISSION = "CREATE_PERMISSION",
  CREATE_PLACE = "CREATE_PLACE",
  CREATE_PRODUCT = "CREATE_PRODUCT",
  CREATE_PRODUCT_SELECTOR = "CREATE_PRODUCT_SELECTOR",
  CREATE_ROLE = "CREATE_ROLE",
  CREATE_ROUTE = "CREATE_ROUTE",
  CREATE_SERVICER = "CREATE_SERVICER",
  CREATE_SHIPMENT = "CREATE_SHIPMENT",
  CREATE_SIGNAL = "CREATE_SIGNAL",
  CREATE_SUBROLE = "CREATE_SUBROLE",
  CREATE_SUBSCRIBER = "CREATE_SUBSCRIBER",
  CREATE_SUPPLIER = "CREATE_SUPPLIER",
  CREATE_THING_BROKER = "CREATE_THING_BROKER",
  CREATE_VARIATION = "CREATE_VARIATION",
  CREDIT_CREDIT_ACCOUNT = "CREDIT_CREDIT_ACCOUNT",
  DEACTIVATE_AVATAR = "DEACTIVATE_AVATAR",
  DEBIT_CREDIT_ACCOUNT = "DEBIT_CREDIT_ACCOUNT",
  DEFAULT_PERMISSIONS = "DEFAULT_PERMISSIONS",
  DELETE_ADDRESS = "DELETE_ADDRESS",
  DELETE_AGENT = "DELETE_AGENT",
  DELETE_AMAZON_PRODUCT_CATEGORY = "DELETE_AMAZON_PRODUCT_CATEGORY",
  DELETE_APPLICATION_SITE = "DELETE_APPLICATION_SITE",
  DELETE_ARTICLE = "DELETE_ARTICLE",
  DELETE_AUTHENTICATION_INSTANCE = "DELETE_AUTHENTICATION_INSTANCE",
  DELETE_AUTHENTICATION_SUBINSTANCE = "DELETE_AUTHENTICATION_SUBINSTANCE",
  DELETE_AVATAR = "DELETE_AVATAR",
  DELETE_BRAND = "DELETE_BRAND",
  DELETE_BRAND_SELECTOR = "DELETE_BRAND_SELECTOR",
  DELETE_BROKER = "DELETE_BROKER",
  DELETE_BUYER = "DELETE_BUYER",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  DELETE_CATEGORY_SELECTOR = "DELETE_CATEGORY_SELECTOR",
  DELETE_CODEEVENT = "DELETE_CODEEVENT",
  DELETE_CODEGENERATOR = "DELETE_CODEGENERATOR",
  DELETE_DISPATCHER = "DELETE_DISPATCHER",
  DELETE_DISTRIBUTOR = "DELETE_DISTRIBUTOR",
  DELETE_ECOMMERCE_PRODUCT = "DELETE_ECOMMERCE_PRODUCT",
  DELETE_EVENT = "DELETE_EVENT",
  DELETE_GATT_PROPERTIES_FROM_ITEMSKU = "DELETE_GATT_PROPERTIES_FROM_ITEMSKU",
  DELETE_ITEM = "DELETE_ITEM",
  DELETE_ITEMBATCH = "DELETE_ITEMBATCH",
  DELETE_ITEMFIRMWARE = "DELETE_ITEMFIRMWARE",
  DELETE_ITEMFLEET = "DELETE_ITEMFLEET",
  DELETE_ITEMSKU = "DELETE_ITEMSKU",
  DELETE_ITEMSTOCK = "DELETE_ITEMSTOCK",
  DELETE_LOGIN_USER = "DELETE_LOGIN_USER",
  DELETE_MATERIAL = "DELETE_MATERIAL",
  DELETE_MEDIA_META = "DELETE_MEDIA_META",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  DELETE_ORGANIZATION = "DELETE_ORGANIZATION",
  DELETE_OVES_ADMIN = "DELETE_OVES_ADMIN",
  DELETE_PACK = "DELETE_PACK",
  DELETE_PART = "DELETE_PART",
  DELETE_PAYPLAN = "DELETE_PAYPLAN",
  DELETE_PERMISSION = "DELETE_PERMISSION",
  DELETE_PERSON = "DELETE_PERSON",
  DELETE_PLACE = "DELETE_PLACE",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  DELETE_PRODUCT_SELECTOR = "DELETE_PRODUCT_SELECTOR",
  DELETE_PUBLISHER = "DELETE_PUBLISHER",
  DELETE_ROLE = "DELETE_ROLE",
  DELETE_ROLE_PERMISSION = "DELETE_ROLE_PERMISSION",
  DELETE_ROUTE = "DELETE_ROUTE",
  DELETE_SERVICER = "DELETE_SERVICER",
  DELETE_SHIPMENT = "DELETE_SHIPMENT",
  DELETE_SIGNAL = "DELETE_SIGNAL",
  DELETE_SUBROLE = "DELETE_SUBROLE",
  DELETE_SUBROLE_PERMISSIONS = "DELETE_SUBROLE_PERMISSIONS",
  DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER",
  DELETE_SUPPLIER = "DELETE_SUPPLIER",
  DELETE_THING_BROKER = "DELETE_THING_BROKER",
  DELETE_VARIATION = "DELETE_VARIATION",
  EMIT_SIGNAL = "EMIT_SIGNAL",
  FREEZE_CREDIT_ACCOUNT = "FREEZE_CREDIT_ACCOUNT",
  GENERATE_AGENT_SIGNUP_TOKEN = "GENERATE_AGENT_SIGNUP_TOKEN",
  GENERATE_CODEGENERATOR = "GENERATE_CODEGENERATOR",
  GENERATE_DAYSCODE = "GENERATE_DAYSCODE",
  GENERATE_FREESCODE = "GENERATE_FREESCODE",
  GENERATE_LOGIN_USER_SUBROLE_SIGNUP_TOKEN = "GENERATE_LOGIN_USER_SUBROLE_SIGNUP_TOKEN",
  GENERATE_RESETCODE = "GENERATE_RESETCODE",
  GENERATE_ROLE_USER_SIGN_UP_TOKEN = "GENERATE_ROLE_USER_SIGN_UP_TOKEN",
  GENERATE_USER_ROLE_SIGNUP_TOKEN = "GENERATE_USER_ROLE_SIGNUP_TOKEN",
  GET_ALL_ADDRESSES = "GET_ALL_ADDRESSES",
  GET_ALL_AGENTS = "GET_ALL_AGENTS",
  GET_ALL_AGENTS_FOR_DISTRIBUTOR = "GET_ALL_AGENTS_FOR_DISTRIBUTOR",
  GET_ALL_AMAZON_PRODUCT_CATEGORIES = "GET_ALL_AMAZON_PRODUCT_CATEGORIES",
  GET_ALL_APPLICATION_SITES = "GET_ALL_APPLICATION_SITES",
  GET_ALL_ARTICLES = "GET_ALL_ARTICLES",
  GET_ALL_ASSET_ACCOUNTS = "GET_ALL_ASSET_ACCOUNTS",
  GET_ALL_ASSET_ACCOUNTS_FOR_CLIENT = "GET_ALL_ASSET_ACCOUNTS_FOR_CLIENT",
  GET_ALL_ASSET_ACCOUNTS_FOR_CUSTOMER = "GET_ALL_ASSET_ACCOUNTS_FOR_CUSTOMER",
  GET_ALL_AUTHENTICATION_INSTANCE = "GET_ALL_AUTHENTICATION_INSTANCE",
  GET_ALL_AUTHENTICATION_SUBINSTANCE = "GET_ALL_AUTHENTICATION_SUBINSTANCE",
  GET_ALL_AVATARS = "GET_ALL_AVATARS",
  GET_ALL_BRANDS = "GET_ALL_BRANDS",
  GET_ALL_BRAND_SELECTOR_SELECTORS = "GET_ALL_BRAND_SELECTOR_SELECTORS",
  GET_ALL_BROKERS = "GET_ALL_BROKERS",
  GET_ALL_BUYERS = "GET_ALL_BUYERS",
  GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES",
  GET_ALL_CATEGORY_SELECTOR_SELECTORS = "GET_ALL_CATEGORY_SELECTOR_SELECTORS",
  GET_ALL_CLIENT_CUSTOMERS = "GET_ALL_CLIENT_CUSTOMERS",
  GET_ALL_CLIENT_ITEMS = "GET_ALL_CLIENT_ITEMS",
  GET_ALL_CLIENT_ITEMS_IN_ITEMFLEET = "GET_ALL_CLIENT_ITEMS_IN_ITEMFLEET",
  GET_ALL_CODEGENERATORS = "GET_ALL_CODEGENERATORS",
  GET_ALL_CODE_EVENTS = "GET_ALL_CODE_EVENTS",
  GET_ALL_CODE_SYSTEMS = "GET_ALL_CODE_SYSTEMS",
  GET_ALL_DISPATCHERS = "GET_ALL_DISPATCHERS",
  GET_ALL_DISTRIBUTORS = "GET_ALL_DISTRIBUTORS",
  GET_ALL_ECOMMERCE_PRODUCTS = "GET_ALL_ECOMMERCE_PRODUCTS",
  GET_ALL_EVENTS = "GET_ALL_EVENTS",
  GET_ALL_ITEMBATCHES = "GET_ALL_ITEMBATCHES",
  GET_ALL_ITEMFIRMWARES = "GET_ALL_ITEMFIRMWARES",
  GET_ALL_ITEMFLEETS = "GET_ALL_ITEMFLEETS",
  GET_ALL_ITEMS = "GET_ALL_ITEMS",
  GET_ALL_ITEMSKUS = "GET_ALL_ITEMSKUS",
  GET_ALL_ITEMSTOCKS = "GET_ALL_ITEMSTOCKS",
  GET_ALL_ITEMS_FOR_ITEMBATCH_IN_DESCENDING_ORDER = "GET_ALL_ITEMS_FOR_ITEMBATCH_IN_DESCENDING_ORDER",
  GET_ALL_LOGIN_USERS = "GET_ALL_LOGIN_USERS",
  GET_ALL_MATERIALS = "GET_ALL_MATERIALS",
  GET_ALL_MEDIA_METAS = "GET_ALL_MEDIA_METAS",
  GET_ALL_MESSAGES = "GET_ALL_MESSAGES",
  GET_ALL_ORGANIZATIONS = "GET_ALL_ORGANIZATIONS",
  GET_ALL_OVESADMINS = "GET_ALL_OVESADMINS",
  GET_ALL_OVES_ADMINS = "GET_ALL_OVES_ADMINS",
  GET_ALL_PACKS = "GET_ALL_PACKS",
  GET_ALL_PARTS = "GET_ALL_PARTS",
  GET_ALL_PAYPLANS = "GET_ALL_PAYPLANS",
  GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS",
  GET_ALL_PERSONS = "GET_ALL_PERSONS",
  GET_ALL_PLACES = "GET_ALL_PLACES",
  GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS",
  GET_ALL_PRODUCT_SELECTOR_SELECTORS = "GET_ALL_PRODUCT_SELECTOR_SELECTORS",
  GET_ALL_PUBLISHERS = "GET_ALL_PUBLISHERS",
  GET_ALL_ROLES = "GET_ALL_ROLES",
  GET_ALL_ROUTES = "GET_ALL_ROUTES",
  GET_ALL_SERVICERS = "GET_ALL_SERVICERS",
  GET_ALL_SHIPMENT = "GET_ALL_SHIPMENT",
  GET_ALL_SIGNALS = "GET_ALL_SIGNALS",
  GET_ALL_SPECIFIC_ARTICLE = "GET_ALL_SPECIFIC_ARTICLE",
  GET_ALL_SUBROLES = "GET_ALL_SUBROLES",
  GET_ALL_SUBROLE_LOGINUSERS_FOR_ROLE_LOGINUSER = "GET_ALL_SUBROLE_LOGINUSERS_FOR_ROLE_LOGINUSER",
  GET_ALL_SUBSCRIBERS = "GET_ALL_SUBSCRIBERS",
  GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS",
  GET_ALL_THING_BROKERS = "GET_ALL_THING_BROKERS",
  GET_ALL_VARIATIONS = "GET_ALL_VARIATIONS",
  GET_DISTRIBUTOR_TOKEN = "GET_DISTRIBUTOR_TOKEN",
  GET_ECOMMERCE_PRODUCTS_BY_CATEGORY = "GET_ECOMMERCE_PRODUCTS_BY_CATEGORY",
  GET_ITEMFLEETS_FOR_CLIENT = "GET_ITEMFLEETS_FOR_CLIENT",
  GET_PERMISSIONS_FOR_SUBROLE = "GET_PERMISSIONS_FOR_SUBROLE",
  GET_SPECIFIC_ADDRESS = "GET_SPECIFIC_ADDRESS",
  GET_SPECIFIC_AGENT = "GET_SPECIFIC_AGENT",
  GET_SPECIFIC_AMAZON_PRODUCT_CATEGORY = "GET_SPECIFIC_AMAZON_PRODUCT_CATEGORY",
  GET_SPECIFIC_APPLICATION_SITE = "GET_SPECIFIC_APPLICATION_SITE",
  GET_SPECIFIC_ASSET_ACCOUNTS = "GET_SPECIFIC_ASSET_ACCOUNTS",
  GET_SPECIFIC_ASSET_ACCOUNT_CREDIT_BALANCE = "GET_SPECIFIC_ASSET_ACCOUNT_CREDIT_BALANCE",
  GET_SPECIFIC_AUTHENTICATION_INSTANCE = "GET_SPECIFIC_AUTHENTICATION_INSTANCE",
  GET_SPECIFIC_AUTHENTICATION_SUBINSTANCE = "GET_SPECIFIC_AUTHENTICATION_SUBINSTANCE",
  GET_SPECIFIC_AVATAR = "GET_SPECIFIC_AVATAR",
  GET_SPECIFIC_AVATAR_FOR_ITEM = "GET_SPECIFIC_AVATAR_FOR_ITEM",
  GET_SPECIFIC_BRAND = "GET_SPECIFIC_BRAND",
  GET_SPECIFIC_BRAND_SELECTOR = "GET_SPECIFIC_BRAND_SELECTOR",
  GET_SPECIFIC_BROKER = "GET_SPECIFIC_BROKER",
  GET_SPECIFIC_BUYER = "GET_SPECIFIC_BUYER",
  GET_SPECIFIC_CATEGORY = "GET_SPECIFIC_CATEGORY",
  GET_SPECIFIC_CATEGORY_SELECTOR = "GET_SPECIFIC_CATEGORY_SELECTOR",
  GET_SPECIFIC_CLIENT_ITEM_IN_ITEMFLEET = "GET_SPECIFIC_CLIENT_ITEM_IN_ITEMFLEET",
  GET_SPECIFIC_CODEGENERATOR = "GET_SPECIFIC_CODEGENERATOR",
  GET_SPECIFIC_CODE_EVENT = "GET_SPECIFIC_CODE_EVENT",
  GET_SPECIFIC_CODE_SYSTEM = "GET_SPECIFIC_CODE_SYSTEM",
  GET_SPECIFIC_CUSTOMER_OR_PERSON = "GET_SPECIFIC_CUSTOMER_OR_PERSON",
  GET_SPECIFIC_DISPATCHER = "GET_SPECIFIC_DISPATCHER",
  GET_SPECIFIC_DISTRIBUTOR = "GET_SPECIFIC_DISTRIBUTOR",
  GET_SPECIFIC_ECOMMERCE_PRODUCT = "GET_SPECIFIC_ECOMMERCE_PRODUCT",
  GET_SPECIFIC_EVENT = "GET_SPECIFIC_EVENT",
  GET_SPECIFIC_ITEM = "GET_SPECIFIC_ITEM",
  GET_SPECIFIC_ITEMBATCH = "GET_SPECIFIC_ITEMBATCH",
  GET_SPECIFIC_ITEMFIRMWARE = "GET_SPECIFIC_ITEMFIRMWARE",
  GET_SPECIFIC_ITEMFLEET = "GET_SPECIFIC_ITEMFLEET",
  GET_SPECIFIC_ITEMSKU = "GET_SPECIFIC_ITEMSKU",
  GET_SPECIFIC_ITEMSTOCK = "GET_SPECIFIC_ITEMSTOCK",
  GET_SPECIFIC_LOGIN_USER = "GET_SPECIFIC_LOGIN_USER",
  GET_SPECIFIC_MATERIAL = "GET_SPECIFIC_MATERIAL",
  GET_SPECIFIC_MEDIA_META = "GET_SPECIFIC_MEDIA_META",
  GET_SPECIFIC_MESSAGE = "GET_SPECIFIC_MESSAGE",
  GET_SPECIFIC_ORGANIZATION = "GET_SPECIFIC_ORGANIZATION",
  GET_SPECIFIC_OVESADMIN = "GET_SPECIFIC_OVESADMIN",
  GET_SPECIFIC_OVES_ADMIN = "GET_SPECIFIC_OVES_ADMIN",
  GET_SPECIFIC_PACK = "GET_SPECIFIC_PACK",
  GET_SPECIFIC_PART = "GET_SPECIFIC_PART",
  GET_SPECIFIC_PAYPLAN = "GET_SPECIFIC_PAYPLAN",
  GET_SPECIFIC_PERMISSION = "GET_SPECIFIC_PERMISSION",
  GET_SPECIFIC_PLACE = "GET_SPECIFIC_PLACE",
  GET_SPECIFIC_PRODUCT = "GET_SPECIFIC_PRODUCT",
  GET_SPECIFIC_PRODUCT_SELECTOR = "GET_SPECIFIC_PRODUCT_SELECTOR",
  GET_SPECIFIC_ROLE = "GET_SPECIFIC_ROLE",
  GET_SPECIFIC_ROLE_PERMISSIONS = "GET_SPECIFIC_ROLE_PERMISSIONS",
  GET_SPECIFIC_ROUTE = "GET_SPECIFIC_ROUTE",
  GET_SPECIFIC_SERVICER = "GET_SPECIFIC_SERVICER",
  GET_SPECIFIC_SHIPMENT = "GET_SPECIFIC_SHIPMENT",
  GET_SPECIFIC_SIGNAL = "GET_SPECIFIC_SIGNAL",
  GET_SPECIFIC_SUBROLE = "GET_SPECIFIC_SUBROLE",
  GET_SPECIFIC_SUBSCRIBER = "GET_SPECIFIC_SUBSCRIBER",
  GET_SPECIFIC_SUPPLIER = "GET_SPECIFIC_SUPPLIER",
  GET_SPECIFIC_THING_BROKER = "GET_SPECIFIC_THING_BROKER",
  GET_SPECIFIC_VARIATION = "GET_SPECIFIC_VARIATION",
  GET_SUBROLES_FOR_LOGINUSER_ROLE = "GET_SUBROLES_FOR_LOGINUSER_ROLE",
  INITIALIZE_CODEGENERATOR = "INITIALIZE_CODEGENERATOR",
  ITEMSKU_GATT_MANIPULATION = "ITEMSKU_GATT_MANIPULATION",
  LOG_EVENT = "LOG_EVENT",
  LOG_PUBLISHER = "LOG_PUBLISHER",
  MAKE_AUTHENTICATION_INSTANCE_ACTIVE = "MAKE_AUTHENTICATION_INSTANCE_ACTIVE",
  MAKE_AUTHENTICATION_SUBINSTANCE_ACTIVE = "MAKE_AUTHENTICATION_SUBINSTANCE_ACTIVE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PAIR_ASSETACCOUNT = "PAIR_ASSETACCOUNT",
  RESET_DISTRIBUTOR_PASSWORD = "RESET_DISTRIBUTOR_PASSWORD",
  RESET_OVES_PERSONEL_PASSWORD = "RESET_OVES_PERSONEL_PASSWORD",
  RESOLVE_CREDIT_ACCOUNT = "RESOLVE_CREDIT_ACCOUNT",
  RESTORE_SIGNAL_CLASS_WITH_TIMESTAMP = "RESTORE_SIGNAL_CLASS_WITH_TIMESTAMP",
  SET_ASSETACCOUNT_STATUS_TO_PAYPLAN_COMPLETED = "SET_ASSETACCOUNT_STATUS_TO_PAYPLAN_COMPLETED",
  SET_CREDIT_ACCOUNT_BALANCE_FOR_ASSET_ACCOUNT = "SET_CREDIT_ACCOUNT_BALANCE_FOR_ASSET_ACCOUNT",
  SUSPEND_CREDIT_ACCOUNT = "SUSPEND_CREDIT_ACCOUNT",
  THING_SERVICE_ITEMS_BY_OEMITEMID = "THING_SERVICE_ITEMS_BY_OEMITEMID",
  UPDATE_ADDRESS = "UPDATE_ADDRESS",
  UPDATE_AGENT = "UPDATE_AGENT",
  UPDATE_AGENT_PASSWORD = "UPDATE_AGENT_PASSWORD",
  UPDATE_AMAZON_PRODUCT_CATEGORY = "UPDATE_AMAZON_PRODUCT_CATEGORY",
  UPDATE_APPLICATION_SITE = "UPDATE_APPLICATION_SITE",
  UPDATE_ARTICLE = "UPDATE_ARTICLE",
  UPDATE_ASSET_ACCOUNT = "UPDATE_ASSET_ACCOUNT",
  UPDATE_ASSIGNED_PERMISSION_TO_ROLE = "UPDATE_ASSIGNED_PERMISSION_TO_ROLE",
  UPDATE_ASSIGNED_ROLE_PERMISSIONS_TO_SUBROLE = "UPDATE_ASSIGNED_ROLE_PERMISSIONS_TO_SUBROLE",
  UPDATE_AUTHENTICATION_INSTANCE = "UPDATE_AUTHENTICATION_INSTANCE",
  UPDATE_AUTHENTICATION_SUBINSTANCE = "UPDATE_AUTHENTICATION_SUBINSTANCE",
  UPDATE_AVATAR = "UPDATE_AVATAR",
  UPDATE_AVATAR_BY_ITEM_ID = "UPDATE_AVATAR_BY_ITEM_ID",
  UPDATE_BRAND = "UPDATE_BRAND",
  UPDATE_BRAND_SELECTOR = "UPDATE_BRAND_SELECTOR",
  UPDATE_BROKER = "UPDATE_BROKER",
  UPDATE_BUYER = "UPDATE_BUYER",
  UPDATE_BUYER_PASSWORD = "UPDATE_BUYER_PASSWORD",
  UPDATE_CATEGORY = "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SELECTOR = "UPDATE_CATEGORY_SELECTOR",
  UPDATE_DISPATCHER = "UPDATE_DISPATCHER",
  UPDATE_DISTRIBUTOR = "UPDATE_DISTRIBUTOR",
  UPDATE_DISTRIBUTOR_PASSWORD = "UPDATE_DISTRIBUTOR_PASSWORD",
  UPDATE_ECOMMERCE_PRODUCT = "UPDATE_ECOMMERCE_PRODUCT",
  UPDATE_EVENT = "UPDATE_EVENT",
  UPDATE_ITEM = "UPDATE_ITEM",
  UPDATE_ITEMBATCH = "UPDATE_ITEMBATCH",
  UPDATE_ITEMFIRMWARE = "UPDATE_ITEMFIRMWARE",
  UPDATE_ITEMFLEET = "UPDATE_ITEMFLEET",
  UPDATE_ITEMSKU = "UPDATE_ITEMSKU",
  UPDATE_ITEMSTOCK = "UPDATE_ITEMSTOCK",
  UPDATE_LOGINUSER_PASSWORD = "UPDATE_LOGINUSER_PASSWORD",
  UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER",
  UPDATE_MATERIAL = "UPDATE_MATERIAL",
  UPDATE_MEDIA_META = "UPDATE_MEDIA_META",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
  UPDATE_OVES_ADMIN = "UPDATE_OVES_ADMIN",
  UPDATE_OVES_ADMIN_PASSWORD = "UPDATE_OVES_ADMIN_PASSWORD",
  UPDATE_PACK = "UPDATE_PACK",
  UPDATE_PART = "UPDATE_PART",
  UPDATE_PAYPLAN = "UPDATE_PAYPLAN",
  UPDATE_PERMISSION = "UPDATE_PERMISSION",
  UPDATE_PERSON = "UPDATE_PERSON",
  UPDATE_PLACE = "UPDATE_PLACE",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SELECTOR = "UPDATE_PRODUCT_SELECTOR",
  UPDATE_PUBLISHER = "UPDATE_PUBLISHER",
  UPDATE_ROLE = "UPDATE_ROLE",
  UPDATE_ROUTE = "UPDATE_ROUTE",
  UPDATE_SERVICER = "UPDATE_SERVICER",
  UPDATE_SHIPMENT = "UPDATE_SHIPMENT",
  UPDATE_SIGNAL = "UPDATE_SIGNAL",
  UPDATE_SUBROLE = "UPDATE_SUBROLE",
  UPDATE_SUBSCRIBER = "UPDATE_SUBSCRIBER",
  UPDATE_SUPPLIER = "UPDATE_SUPPLIER",
  UPDATE_THING_BROKER = "UPDATE_THING_BROKER",
  UPDATE_VARIATION = "UPDATE_VARIATION",
  UPLOAD_FILE = "UPLOAD_FILE",
  VIEW_ACTIVITIES_OF_ASSETACCOUNT = "VIEW_ACTIVITIES_OF_ASSETACCOUNT",
  VIEW_EVENT = "VIEW_EVENT",
  VIEW_PUBLISHER = "VIEW_PUBLISHER",
}

export enum PersonTypes {
  AGENT = "AGENT",
  CONTACT = "CONTACT",
  CUSTOMER = "CUSTOMER",
  DEVELOPER = "DEVELOPER",
  LOGIN = "LOGIN",
}

export enum QueryOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ThingIDTypes {
  DOI = "DOI",
  MAC = "MAC",
  UUID = "UUID",
}

export interface ActionDto {
  description?: string | null;
  label?: string | null;
  type?: ActionTypes | null;
}

export interface ActionFilterInput {
  description?: StringFilterInput | null;
  name?: StringFilterInput | null;
  type?: StringFilterInput | null;
}

export interface ActivityDto {
  action: string;
  amount: number;
  datetime: any;
  notes: string;
}

export interface ActorDto {
  description?: string | null;
  name?: string | null;
  type?: ActorTypes | null;
}

export interface ActorFilterInput {
  description?: StringFilterInput | null;
  name?: StringFilterInput | null;
  type?: StringFilterInput | null;
}

export interface AddressDto {
  city: string;
  country: string;
  locationAddressDto: AddressLocationDto;
  postcode: string;
  srpc: string;
  street: string;
  unit: string;
}

export interface AddressLocationDto {
  inputAddressLatitude: number;
  inputAddressLongitude: number;
}

export interface AdminRegisterDistributorInput {
  contactRole: string;
  distributorAddress: AddressDto;
  distributorDescription?: string | null;
  distributorName?: string | null;
  distributorType?: OrgTypes | null;
  email: string;
  roleId: string;
}

export interface AssignCodeGeneratorToItemInput {
  codeGeneratorId: string;
  itemId: string;
}

export interface AssignItemToItemFleetInput {
  itemFleetId: string;
  itemId: string;
}

export interface AssignPermissionToRoleInput {
  authenticationInstanceId: string;
  permissions: PermissionInput[];
  roleId: string;
}

export interface AttributeDto {
  meta: string;
  prop: string;
  value: string;
}

export interface ClientRegisterPersonInput {
  clientContactPerson: PersonContactDto;
  clientPersonAddress: AddressDto;
  clientPersonDescription?: string | null;
  clientPersonName: string;
  clientPersonType?: PersonTypes | null;
}

export interface CreateAmazonProductCategoryInput {
  categoryDescription?: string | null;
  categoryName?: string | null;
  categoryTree?: string | null;
}

export interface CreateAvatarInput {
  att?: AttributeDto[] | null;
  cmd?: AttributeDto[] | null;
  dia?: AttributeDto[] | null;
  dta?: AttributeDto[] | null;
  itemAlive?: boolean | null;
  lastPub: string;
  lastSub: string;
  lastSync: any;
  shadow: string;
  sts?: AttributeDto[] | null;
}

export interface CreateBrokerInput {
  login?: string | null;
  name?: string | null;
  password?: string | null;
  port?: number | null;
  url?: string | null;
}

export interface CreateEventInput {
  action: ActionDto;
  actor: ActorDto;
  location: LocationInputDto;
  signal: boolean;
  time: any;
}

export interface CreateItemBatchInput {
  batchDate: any;
  batchNumber: string;
  description?: string | null;
  itemSKUId: string;
}

export interface CreateItemFirmwareInput {
  codeSystem: CodeSystemType;
  description?: string | null;
  version: string;
}

export interface CreateItemFleetInput {
  assignDate: any;
  description: string;
  fleetName: string;
}

export interface CreateItemInput {
  codegeneratorId?: string | null;
  idString?: string | null;
  itemFleetId?: string | null;
  itemStockId?: string | null;
  itembatchId: string;
  itemfirmwareId: string;
  oemID?: string | null;
  oemItemID: string;
}

export interface CreateItemSKUInput {
  gatt?: GattDto | null;
  mainMediaURL?: string | null;
  oemDescription?: string | null;
  productBase: string;
  skuName: string;
}

export interface CreateOrganizationInput {
  contactRole: string;
  orgAddress: AddressDto;
  orgDescription?: string | null;
  orgName?: string | null;
  orgProfile: string;
  orgType?: OrgTypes | null;
}

export interface CreatePayPlanInput {
  parameters?: PlanParameterDto[] | null;
  type?: string | null;
}

export interface CreatePublisherInput {
  broker: string;
  topic: string;
}

export interface CreateSignalInput {
  jmp: any;
  publisher?: string | null;
  time: any;
}

export interface CreateSupplierInput {
  contactRole: string;
  supplierAddress: AddressDto;
  supplierDescription?: string | null;
  supplierName?: string | null;
  supplierType?: OrgTypes | null;
}

export interface CreditAccountDTO {
  activities?: ActivityDto[] | null;
  balance: number;
  currency: string;
  customerId: string;
}

export interface DateTimeFilterInput {
  and?: any[] | null;
  between?: any[] | null;
  contains?: any | null;
  containsi?: any | null;
  endsWith?: any | null;
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  not?: DateTimeFilterInput | null;
  notContains?: any | null;
  notContainsi?: any | null;
  notIn?: any[] | null;
  notNull?: boolean | null;
  null?: boolean | null;
  or?: any[] | null;
  startsWith?: any | null;
}

export interface FloatFilterInput {
  and?: number[] | null;
  between?: number[] | null;
  contains?: number | null;
  containsi?: number | null;
  endsWith?: number | null;
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
  not?: FloatFilterInput | null;
  notContains?: number | null;
  notContainsi?: number | null;
  notIn?: number[] | null;
  notNull?: boolean | null;
  null?: number | null;
  or?: number[] | null;
  startsWith?: number | null;
}

export interface GattDto {
  attributes?: AttributeDto[] | null;
  name: string;
}

export interface GenerateCodeGenInput {
  codeDays: number;
  codeDecString: string;
  codeHexString: string;
  codeType: CodeTypes;
  description?: string | null;
  itemId: string;
}

export interface GenerateCodeInput {
  description?: string | null;
  itemId: string;
}

export interface GenerateDaysCodeInput {
  codeDays: number;
  description?: string | null;
  itemId: string;
}

export interface GenerateUserRoleSignUpTokenInput {
  email: string;
  roleId: string;
}

export interface GetAllClientItemsInItemFleet {
  clientId: string;
  itemFleetId: string;
}

export interface GetAllEventsInput {
  action?: ActionFilterInput | null;
  actor?: ActorFilterInput | null;
  location?: LocationFilterInput | null;
  signal?: IdFilterInput | null;
  time?: DateTimeFilterInput | null;
}

export interface IdFilterInput {
  and?: string[] | null;
  between?: string[] | null;
  contains?: string | null;
  containsi?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  ne?: string | null;
  not?: IdFilterInput | null;
  notContains?: string | null;
  notContainsi?: string | null;
  notIn?: string[] | null;
  notNull?: boolean | null;
  null?: boolean | null;
  or?: string[] | null;
  startsWith?: string | null;
}

export interface InitializeCodeGenInput {
  codeSystemId: string;
  freeCodeCount?: number | null;
  itemId: string;
  resetCodeCount?: number | null;
}

export interface LocationFilterInput {
  latitude?: FloatFilterInput | null;
  longitude?: FloatFilterInput | null;
  placeName?: string | null;
}

export interface LocationInputDto {
  latitude: number;
  longitude: number;
  placeName?: string | null;
}

export interface PairAssetAccountInput {
  clientId: string;
  credit: CreditAccountDTO;
  itemId: string;
  paySchedule?: PaymentDTO | null;
  userId: string;
}

export interface PaymentDTO {
  amount: number;
  datetime: any;
  instruction: string;
}

export interface PaymentInput {
  action: AccountActions;
  amount: number;
  datetime: any;
  notes: string;
}

export interface PersonContactDto {
  email: string;
  phone: string;
  social: string;
}

export interface PlanParameterDto {
  description: string;
  parameter: string;
  value: number;
}

export interface SignUpClientInput {
  address: AddressDto;
  authenticationToken?: string | null;
  email: string;
  password: string;
  personDescription?: string | null;
  personName: string;
  personType?: PersonTypes | null;
  phone: string;
  social: string;
}

export interface StringFilterInput {
  and?: string[] | null;
  between?: string[] | null;
  contains?: string | null;
  containsi?: string | null;
  endsWith?: string | null;
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  ne?: string | null;
  not?: StringFilterInput | null;
  notContains?: string | null;
  notContainsi?: string | null;
  notIn?: string[] | null;
  notNull?: boolean | null;
  null?: boolean | null;
  or?: string[] | null;
  startsWith?: string | null;
}

export interface UpdateActionDto {
  description?: string | null;
  label?: string | null;
  type?: ActionTypes | null;
}

export interface UpdateActorDto {
  description?: string | null;
  name?: string | null;
  type?: ActorTypes | null;
}

export interface UpdateAddressDto {
  UpdateLocationAddressDto?: UpdateLocationDto | null;
  city?: string | null;
  country?: string | null;
  postcode?: string | null;
  srpc?: string | null;
  street?: string | null;
  unit?: string | null;
}

export interface UpdateAmazonProductCategoryInput {
  amazonProductCategoryId: string;
  categoryDescription?: string | null;
  categoryName?: string | null;
  categoryTree?: string | null;
}

export interface UpdateAssetAccountInput {
  assetAccountId: string;
  credit?: CreditAccountDTO | null;
  payPlanId?: string | null;
  paySchedule?: PaymentDTO | null;
}

export interface UpdateAvatarByItemIDInput {
  att?: AttributeDto[] | null;
  cmd?: AttributeDto[] | null;
  dia?: AttributeDto[] | null;
  dta?: AttributeDto[] | null;
  itemAlive?: boolean | null;
  itemId: string;
  lastPub?: string | null;
  lastSub?: string | null;
  lastSync?: any | null;
  shadow?: string | null;
  sts?: AttributeDto[] | null;
}

export interface UpdateBrokerInput {
  brokerId: string;
  login?: string | null;
  name?: string | null;
  password?: string | null;
  port?: number | null;
  url?: string | null;
}

export interface UpdateClientPersonInput {
  clientContactPerson?: PersonContactDto | null;
  clientPersonAddress?: AddressDto | null;
  clientPersonDescription?: string | null;
  clientPersonName?: string | null;
  clientPersonType?: PersonTypes | null;
  personId: string;
}

export interface UpdateContactDto {
  email?: string | null;
  phone?: string | null;
  social?: string | null;
}

export interface UpdateEventInput {
  action?: UpdateActionDto | null;
  actor?: UpdateActorDto | null;
  eventId: string;
  location?: UpdateLocationInputDto | null;
  signal?: boolean | null;
  time?: any | null;
}

export interface UpdateGattDto {
  attributes: AttributeDto;
}

export interface UpdateItemBatchInput {
  actionScope?: ActionScope | null;
  actorName?: string | null;
  actorTypes?: ActorTypes | null;
  batchDate?: any | null;
  batchNumber?: string | null;
  batchState?: BatchState | null;
  description?: string | null;
  itemBatchId: string;
  itemSKUId?: string | null;
  profile?: string | null;
}

export interface UpdateItemFirmwareInput {
  actionScope?: ActionScope | null;
  actorName?: string | null;
  actorTypes?: ActorTypes | null;
  codeSystem?: CodeSystemType | null;
  description?: string | null;
  itemFirmwareId: string;
  profile?: string | null;
  version?: string | null;
}

export interface UpdateItemFleetInput {
  actionScope?: ActionScope | null;
  actorName?: string | null;
  actorTypes?: ActorTypes | null;
  assignDate?: any | null;
  clientId?: string | null;
  description?: string | null;
  fleetName?: string | null;
  itemFleetId: string;
  profile?: string | null;
}

export interface UpdateItemInput {
  actionScope?: ActionScope | null;
  actorName?: string | null;
  actorTypes?: ActorTypes | null;
  codegeneratorId?: string | null;
  creationDate?: any | null;
  description?: string | null;
  idString?: string | null;
  idType?: ThingIDTypes | null;
  itemFleetId?: string | null;
  itemId: string;
  itemStockId?: string | null;
  itembatchId?: string | null;
  itemfirmwareId?: string | null;
  lifeCycle?: LifeCycle | null;
  oemID?: string | null;
  oemItemID?: string | null;
  profile?: string | null;
  sellerID?: string | null;
  sellerItemID?: string | null;
}

export interface UpdateItemSKUInput {
  actionScope?: ActionScope | null;
  actorName?: string | null;
  actorTypes?: ActorTypes | null;
  gatt?: UpdateGattDto | null;
  gattName?: string | null;
  itemSKUId: string;
  mainMediaURL?: string | null;
  oemDescription?: string | null;
  productBase?: string | null;
  profile?: string | null;
  skuName?: string | null;
}

export interface UpdateLocationDto {
  lat?: number | null;
  lng?: number | null;
}

export interface UpdateLocationInputDto {
  latitude?: number | null;
  longitude?: number | null;
  placeName?: string | null;
}

export interface UpdateLoginUserInput {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  loginUserId: string;
  password: string;
}

export interface UpdateOrganizationInput {
  contactRole?: string | null;
  orgAddress?: UpdateAddressDto | null;
  orgContactPerson?: UpdatePersonDto | null;
  orgDescription?: string | null;
  orgId: string;
  orgName?: string | null;
  orgProfile?: string | null;
  orgType?: OrgTypes | null;
}

export interface UpdatePayPlanInput {
  parameters?: PlanParameterDto[] | null;
  payPlanId: string;
  type?: string | null;
}

export interface UpdatePersonDto {
  address?: UpdateAddressDto | null;
  contact?: UpdateContactDto | null;
  personDescription?: string | null;
  personName?: string | null;
  personType?: PersonTypes | null;
}

export interface UpdatePublisherInput {
  broker?: string | null;
  publisherId: string;
  topic?: string | null;
}

export interface UpdateSignalInput {
  jmp?: any | null;
  publisher?: string | null;
  signalId: string;
  time?: any | null;
}

export interface UpdateSupplierInput {
  contactRole?: string | null;
  supplierAddress?: UpdateAddressDto | null;
  supplierContactPerson?: UpdatePersonDto | null;
  supplierDescription?: string | null;
  supplierId: string;
  supplierName?: string | null;
  supplierType?: OrgTypes | null;
}

export interface UpdateUserPasswordInput {
  newPassword: string;
  oldPassword: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
