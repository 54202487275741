import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  GetAllAuthenticationInstances,
  GetAllAuthenticationInstancesVariables,
} from './types/GetAllAuthenticationInstances';
import {
  GetAllPermissions,
  GetAllPermissionsVariables,
} from './types/getAllPermissions';
import { GetAllRoles, GetAllRolesVariables } from './types/GetAllRoles';
import {
  GetSpecificRolePermissions,
  GetSpecificRolePermissionsVariables,
} from './types/GetSpecificRolePermissions';
import { GetAllSubRoles, GetAllSubRolesVariables } from './types/GetAllSubRoles';


export const pageDataFragment = gql`
  fragment PageDataFragment on PageData {
    count
    limit
    offset
  }
`;
export const pageInfoFragment = gql`
  fragment PageInfoFragement on RolesPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type SubRolesPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */

export const subRolePageInfoFragment = gql`
  fragment PageInfoFragement on SubRolesPageInfo  {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`

/**
 * type SubRoles {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
name: String
role: Roles
agent: [Agent!]!
}
 */
 export const subRolesFragment = gql`
 fragment SubRoles on SubRoles {
  _id
  deleteStatus
  deleteAt
  createdAt
  updatedAt
  name
  role {
     _id
    name
    }
 }
 `

/**
 * type SubRolesEdge {
cursor: String
node: SubRoles
}
 */

const subRolesEdgeFragment = gql`
  ${subRolesFragment}
fragment SubRolesEdge on SubRolesEdge {
  cursor
  node {
    ...SubRoles
  }
}
`

/**
 * type SubRolesConnection {
edges: [SubRolesEdge!]
pageInfo: SubRolesPageInfo
}
 */

const subRolesConnectionFragment = gql`
${subRolesEdgeFragment}
${subRolePageInfoFragment}
fragment SubRolesConnection on SubRolesConnection {
edges {
  ...SubRolesEdge
  }
pageInfo {
  ...PageInfoFragement
  }
}
`
/**
 * type GetAllSubRolesResponse {
page: SubRolesConnection!
pageData: PageData
}
 */

const getAllSubRolesResponseFragment = gql`
${subRolesConnectionFragment}
${pageDataFragment}
fragment GetAllSubRolesResponse on GetAllSubRolesResponse {
page {
  ...SubRolesConnection
  }
pageData {
  ...PageDataFragment
  }
}
`
/**
 * getAllSubRoles(
before: String
after: String
first: Int
last: Int
): GetAllSubRolesResponse!
 */

export const getAllSubRolesQuery = gql`
  ${getAllSubRolesResponseFragment}
  query GetAllSubRoles(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllSubRoles(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllSubRolesResponse
    }
  }
`

/**
 * type Permissions {
_id: ID!
deleteStatus: DeleteStatusInput!
deleteTime: DateTime
createdAt: DateTime
updatedAt: DateTime
name: PermissionInput!
}
 */
const permissionsFragment = gql`
  fragment Permissions on Permissions {
    _id
    createdAt
    updatedAt
    name
  }
`;
/**
 * type PermissionsEdge {
cursor: String
node: Permissions
}
 */
const permissionsEdgeFragment = gql`
  ${permissionsFragment}
  fragment PermissionsEdge on PermissionsEdge {
    cursor
    node {
      ...Permissions
    }
  }
`;
/**
 * type PermissionsPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const permissionsPageInfoFragment = gql`
  fragment PermissionsPageInfo on PermissionsPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type PermissionsConnection {
edges: [PermissionsEdge!]
pageInfo: PermissionsPageInfo
}
 */
const permissionsConnectionFragment = gql`
  ${permissionsEdgeFragment}
  ${permissionsPageInfoFragment}
  fragment PermissionsConnection on PermissionsConnection {
    edges {
      ...PermissionsEdge
    }
    pageInfo {
      ...PermissionsPageInfo
    }
  }
`;
/**
 * type GetAllPermissionsResponse {
page: PermissionsConnection!
pageData: PageData
}
 */
const getAllPermissionsResponseFragment = gql`
  ${permissionsConnectionFragment}
  ${pageDataFragment}
  fragment GetAllPermissionsResponse on GetAllPermissionsResponse {
    page {
      ...PermissionsConnection
    }
    pageData {
      ...PageDataFragment
    }
  }
`;

/**
 * query {
  getAllPermissions {
    page {
      edges {
        node {
          _id
          name
          
        }
      }
    }
  }
}
 */
export const getAllPermissionsQuery = gql`
  ${getAllPermissionsResponseFragment}
  query GetAllPermissions(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllPermissions(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllPermissionsResponse
    }
  }
`;



/**
 * type AuthenticationInstance {
_id: ID!
deleteStatus: DeleteStatusInput
deleteTime: DateTime
createdAt: DateTime
updatedAt: DateTime
name: String!
}
 */

export const authenticationInstanceFragment = gql`
  fragment AuthenticationInstance on AuthenticationInstance {
    _id
    deleteStatus
    createdAt
    updatedAt
    name
  }
`;

/**
 * type AuthenticationInstanceEdge {
cursor: String
node: AuthenticationInstance
}
 */
const authenticationInstanceEdgeFragment = gql`
  ${authenticationInstanceFragment}
  fragment AuthenticationInstanceEdge on AuthenticationInstanceEdge {
    cursor
    node {
      ...AuthenticationInstance
    }
  }
`;

/**
 * type AuthenticationInstancePageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const authenticationInstancePageInfoFragment = gql`
  fragment AuthenticationInstancePageInfo on AuthenticationInstancePageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type AuthenticationInstanceConnection {
edges: [AuthenticationInstanceEdge!]
pageInfo: AuthenticationInstancePageInfo
}
 */
const authenticationInstanceConnectionFragment = gql`
  ${authenticationInstancePageInfoFragment}
  ${authenticationInstanceEdgeFragment}
  fragment AuthenticationInstanceConnection on AuthenticationInstanceConnection {
    edges {
      ...AuthenticationInstanceEdge
    }
    pageInfo {
      ...AuthenticationInstancePageInfo
    }
  }
`;

/**
 * type GetAllAuthenticationInstancesResponse {
page: AuthenticationInstanceConnection!
pageData: PageData
}
 */
const getAllAuthenticationInstancesResponseFragment = gql`
  ${authenticationInstanceConnectionFragment}
  ${pageDataFragment}
  fragment GetAllAuthenticationInstancesResponse on GetAllAuthenticationInstancesResponse {
    page {
      ...AuthenticationInstanceConnection
    }
    pageData {
      ...PageDataFragment
    }
  }
`;

/**
 * 
 * Query.getAllAuthenticationInstances(
before: String
after: String
first: Int
last: Int
): GetAllAuthenticationInstancesResponse
 */
export const getAllAuthenticationInstancesQuery = gql`
  ${getAllAuthenticationInstancesResponseFragment}
  query GetAllAuthenticationInstances(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllAuthenticationInstances(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllAuthenticationInstancesResponse
    }
  }
`;
export const useGetAllAuthenticationInstancesQuery = (
  variables: GetAllAuthenticationInstancesVariables
) =>
  useQuery<
    GetAllAuthenticationInstances,
    GetAllAuthenticationInstancesVariables
  >(getAllAuthenticationInstancesQuery, { variables });
export const useLazyGetAllAuthenticationInstancesQuery = (
  variables: GetAllAuthenticationInstancesVariables
) =>
  useLazyQuery<
    GetAllAuthenticationInstances,
    GetAllAuthenticationInstancesVariables
  >(getAllAuthenticationInstancesQuery, { variables });

export const useGetAllPermissionsQuery = (
  variables: GetAllPermissionsVariables
) =>
  useQuery<GetAllPermissions, GetAllPermissionsVariables>(
    getAllPermissionsQuery,
    { variables }
  );

export const useLazyGetAllPermissionsQuery = (
  variables: GetAllPermissionsVariables
) =>
  useLazyQuery<GetAllPermissions, GetAllPermissionsVariables>(
    getAllPermissionsQuery,
    { variables }
  );

// roles
/**
   * type SubRoles {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
name: String
role: Roles!
}
   */
// export const subRolesFragment = gql`
//   fragment SubRoles on SubRoles {
//     _id
//     deleteAt
//     deleteStatus
//     name
//     role {
//       _id
//       name
//     }
//   }
// `;
/**
 * type Roles {
_id: ID!
deleteTime: DateTime
createdAt: DateTime
updatedAt: DateTime
name: String
}
 */
export const rolesFragment = gql`
  fragment Roles on Roles {
    _id
    createdAt
    updatedAt
    name
    permissions {
      _id
      name
      permission {
        _id
        name
      }
    }
  }
`;

/**
 * type RolesEdge {
cursor: String
node: Roles
}
 */
const rolesEdgeFragment = gql`
  ${rolesFragment}
  fragment RolesEdge on RolesEdge {
    cursor
    node {
      ...Roles
    }
  }
`;

/**
 * type RolesConnection {
edges: [RolesEdge!]
pageInfo: RolesPageInfo
}
 */
const roleConnectionFragment = gql`
  ${rolesEdgeFragment}
  ${pageInfoFragment}
  fragment RolesConnection on RolesConnection {
    edges {
      ...RolesEdge
    }
    pageInfo {
      ...PageInfoFragement
    }
  }
`;

/**
 * type GetAllRolesResponse {
page: RolesConnection!
pageData: PageData
}
 */
export const getAllRolesResponseFragment = gql`
  ${pageDataFragment}
  ${roleConnectionFragment}
  fragment GetAllRolesResponse on GetAllRolesResponse {
    page {
      ...RolesConnection
    }
    pageData {
      ...PageDataFragment
    }
  }
`;

/**
 * Get roles query
 * Query.getAllRoles(
before: String
after: String
first: Int
last: Int
): GetAllRolesResponse!
 */
export const getAllRolesQuery = gql`
  ${getAllRolesResponseFragment}
  query GetAllRoles($before: String, $after: String, $first: Int, $last: Int) {
    getAllRoles(before: $before, after: $after, first: $first, last: $last) {
      ...GetAllRolesResponse
    }
  }
`;

// TODO: update this onces build types works
export const useGetAllRolesQuery = () =>
  useQuery<GetAllRoles, GetAllRolesVariables>(getAllRolesQuery, {
    variables: { first: 100 },
  });

export const useLazyGetAllRolesQuery = () =>
  useLazyQuery<GetAllRoles, GetAllRolesVariables>(getAllRolesQuery, {
    variables: { first: 100 },
  });

export const useLazyGetAllSubRolesQuery = () => 
  useLazyQuery<GetAllSubRoles, GetAllSubRolesVariables>(getAllSubRolesQuery, {
    variables: {first: 100}
  })


/**
   * type RolesPermissions {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
name: String
role: Roles
permission: Permissions 
authenticationInstance: AuthenticationInstance
}
   */
const rolesPermissionsFragment = gql`
  fragment RolesPermissions on RolesPermissions {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    name
    role {
      _id
      name
    }
    permission {
      _id
      name
    }
    authenticationInstance {
      _id
      name
    }
  }
`;

/**
   * type RolesPermissionsConnection {
edges: [RolesPermissionsEdge!]
pageInfo: RolesPermissionsPageInfo
}
   */
const rolesPermissionsConnectionFragment = gql`
  ${rolesPermissionsFragment}
  fragment RolesPermissionsConnection on RolesPermissionsConnection {
    edges {
      cursor
      node {
        ...RolesPermissions
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
`;
/**
 * type GetSpecificRolePermissionsResponse {
page: RolesPermissionsConnection!
pageData: PageData
}
 */
const getSpecificRolePermissionsResponseFragment = gql`
  ${rolesPermissionsConnectionFragment}
  fragment GetSpecificRolePermissionsResponse on GetSpecificRolePermissionsResponse {
    page {
      ...RolesPermissionsConnection
    }
    pageData {
      count
      limit
      offset
    }
  }
`;
/**]
   * getSpecificRolePermissions(
getSpecificRolePermissionsInput: GetSpecificRolePermissionsInput!
before: String
after: String
first: Int
last: Int
): GetSpecificRolePermissionsResponse!
   */
const getSpecificRolePermissionsQuery = gql`
  ${getSpecificRolePermissionsResponseFragment}
  query GetSpecificRolePermissions(
    $getSpecificRolePermissionsInput: GetSpecificRolePermissionsInput!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getSpecificRolePermissions(
      getSpecificRolePermissionsInput: $getSpecificRolePermissionsInput
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetSpecificRolePermissionsResponse
    }
  }
`;

export const useLazyGetSpecificRolePermissionsQuery = (
  variables: GetSpecificRolePermissionsVariables
) =>
  useLazyQuery<GetSpecificRolePermissions, GetSpecificRolePermissionsVariables>(
    getSpecificRolePermissionsQuery,
    {
      variables,
    }
  );
