import CardGridLoader from '@components/Loaders/CardGridLoader';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { UpdateEventInput } from '../../types/eventsGlobalTypes';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';
import { EventContext } from '../context/EventProvider';
import useUpdateEvent from '../hooks/useUpdateEvent';

import { useViewEventQuery } from '../queries';
import { Event } from '../types/Event';

const EditItemContainer = () => {
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(EventContext);

  const [item, setItem] = React.useState({} as Event);

  const { data, loading } = useViewEventQuery({ id: itemId });

  useEffect(() => {
    setItem({
      ...(data?.viewEvent || ({} as Event)),
    }); // eslint-disable-next-line
  }, [data]);

  const { mutation, mutationOpts } = useUpdateEvent({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateEventInput) => {
    mutation({
      variables: {
        updateEventInput: {
          eventId: itemId,
          action: input.action || defaultItem.action,
          actor:
            {
              name: input?.actor?.name || '',
              description: input?.actor?.description || '',
              type: input?.actor?.type,
            } || defaultItem.actor,
          location:
            {
              latitude: input.location?.latitude,
              longitude: input.location?.longitude,
              placeName: input.location?.placeName,
            } || defaultItem.location,
          signal: input.signal || false,
          time: input.time,
        },
      },
    });
  };

  if (!item?._id || loading) return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleSubmit}
      isLoading={mutationOpts.loading}
      item={{
        eventId: item?._id,
        action: {
          description: item?.action.description || '',
          label: item?.action.label || '',
          type: item?.action.type || '',
        },
        actor: {
          ...item.actor,
        },
        location: {
          ...item.eventLocation,
        },
        signal: !!item?.signal?._id,
        time: item?.time,
      }}
    />
  );
};

export default EditItemContainer;
