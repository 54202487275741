import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik } from 'formik';
import { itemFirmwareFields } from '../constants';
import { UpdateItemFirmwareInput } from 'apps/things/app/types/thingsGlobalTypes';
import { itemFirmwareFieldsValidationSchema } from 'apps/things/app/validations';
import ItemFirmwareForm from './ItemFirmwareForm';
import CardWrapper from '@components/Card/CardWrapper';
import { GetSpecificItemFirmware_getSpecificItemFirmware } from '../types/GetSpecificItemFirmware';

interface IRTable {
  handleUpdate: (payload: UpdateItemFirmwareInput) => void;
  isLoading: boolean;
  itemFirmware: GetSpecificItemFirmware_getSpecificItemFirmware;
}

const EditItemFirmware: React.FC<IRTable> = ({
  handleUpdate,
  isLoading,
  itemFirmware,
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleUpdate({
                itemFirmwareId: itemFirmware._id,
                actorTypes: values.actorTypes,
                actionScope: values.actionScope,
                actorName: values.actorName,
                description: values.description,
                profile: values.profile,
                version: values.version,
                codeSystem: values.codeSystem,
              })
            }
            initialValues={{
              [itemFirmwareFields.codeSystem]: itemFirmware.codeSystem,
              [itemFirmwareFields.description]: itemFirmware.description,
              [itemFirmwareFields.version]: itemFirmware.version,
            }}
            validationSchema={itemFirmwareFieldsValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemFirmwareForm
                formBag={formBag}
                disableLoginButton={isLoading}
                actionButtonName='Update Item Firmware'
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default EditItemFirmware;
