import { ClientExclusiveServiceTypes } from "../../types/clientServiceGlobalTypes";

const url = 'client-service';

export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
export const itemFields = {
  clientExclusiveServiceId: 'clientExclusiveServiceId',
  distributorId: 'distributorId',
  serviceType: 'serviceType',
  servicerId: 'servicerId' 
};

export const defaultItem = {
  clientExclusiveServiceId: '',
  distributorId: '',
  serviceType: ClientExclusiveServiceTypes.PAYGO,
  servicerId: '' 
};
