export const itemFields = {
  assignDate: 'assignDate',
  description: 'description',
  fleetName: 'fleetName',
  itemList: 'itemList',
  clientId: 'clientId',
};

export const codeGenSettingsField = {
  freeCodeCount: 'freeCodeCount',
  resetCodeCount: 'resetCodeCount',
  dayCodeCountLimit: 'dayCodeCountLimit',
  totalCodeCountLimit: 'totalCodeCountLimit',
  codeGenInterval: 'codeGenInterval',
  maxCreditStackDays: 'maxCreditStackDays',
  maxCreditStackCodeEvents: 'maxCreditStackCodeEvents',
}

export const defaultItem = {
  assignDate: new Date(),
  description: '',
  fleetName: '',
  itemList: [],
  clientId: '',
  itemFleetId: '',
};
const url = 'distributors';
export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  settings: `/admin/${url}/settings`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
