import { gql, useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { handleGQLErrors } from 'utils/gqlErrors';
import { successFragment } from '../common/queries';
import { brandFragment } from './queries';
import { CreateBrand, CreateBrandVariables } from './types/CreateBrand';
import { DeleteBrand, DeleteBrandVariables } from './types/DeleteBrand';
import { UpdateBrand, UpdateBrandVariables } from './types/UpdateBrand';

/**
 * deleteBrand(
brandId: String!
): Success!
 */
const deleteBrandMutation = gql`
  ${successFragment}
  mutation DeleteBrand($brandId: String!) {
    deleteBrand(brandId: $brandId) {
      ...Success
    }
  }
`;

/**
 * updateBrand(
updateBrandInput: UpdateBrandInput!
): Brand!
 */
const updateBrandMutation = gql`
  ${brandFragment}
  mutation UpdateBrand($updateBrandInput: UpdateBrandInput!) {
    updateBrand(updateBrandInput: $updateBrandInput) {
      ...Brand
    }
  }
`;

/**
 * createBrand(
createBrandInput: CreateBrandInput!
): Brand!
 */
const createBrandMutation = gql`
  ${brandFragment}
  mutation CreateBrand($createBrandInput: CreateBrandInput!) {
    createBrand(createBrandInput: $createBrandInput) {
      ...Brand
    }
  }
`;

export const useDeleteBrand = (callBack: () => void) => {
  const [deleteBrand, deleteBrandOpts] = useMutation<
    DeleteBrand,
    DeleteBrandVariables
  >(deleteBrandMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    deleteBrand,
    deleteBrandOpts,
  };
};

export const useUpdateBrand = (callBack: () => void) => {
  const notify = useNotifier();
  const [updateBrand, updateBrandOpts] = useMutation<
    UpdateBrand,
    UpdateBrandVariables
  >(updateBrandMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
      err && handleGQLErrors(notify, err);
    },
  });

  return {
    updateBrand,
    updateBrandOpts,
  };
};

export const useCreateBrand = (callBack: (success?: boolean) => void) => {
  const [createBrand, createBrandOpts] = useMutation<
    CreateBrand,
    CreateBrandVariables
  >(createBrandMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack(true);
    },
    onError: (err) => {
      console.error(err);
      callBack && callBack(false);
    },
  });

  return {
    createBrand,
    createBrandOpts,
  };
};
