import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/codGeneratorTableData';
import CardWrapper from '@components/Card/CardWrapper';
import { CodeGeneratorContext } from '../context/CodeGeneratorProvider';
import { codeGeneratorRoutes } from '../constants';

interface IRTable {
}

const CodeGeneratorPage: React.FC<IRTable> = () => {
  const { items, getItems, loading, paging, goTo, setSearchQuery, setPageLimit, searchQuery } = useContext(CodeGeneratorContext);
  useEffect(() => {
    if (!loading && items.length === 0) {
      getItems()
    }
    // eslint-disable-next-line
  }, [])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              itemTableData().header as Column[]
            }
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={codeGeneratorRoutes.add}
            addItemBtnText="Add Code Generator"
            hideRightToolbar
            paging={paging}
            goTo={goTo}
            setSearchQuery={setSearchQuery}
            setPageLimit={setPageLimit}
            searchQuery={searchQuery}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default CodeGeneratorPage;
