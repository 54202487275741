import React from 'react';
import LegacyPage from '../components/LegacyPage';

interface IProps {}

const LegacyModel: React.FC<IProps> = () => {
  return <LegacyPage isLoading={false} />;
};

export default LegacyModel;
