import CardWrapper from '@components/Card/CardWrapper';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import NoDataFound from '@components/NoDataFound/NoDataFound';
import React from 'react';
import ContentLoader from 'react-content-loader';
import TableLoader from './TableLoader';

interface IProps {
  loading: boolean;
  items: any[];
  addItemPageLink?: string;
}

const TablePreview: React.FC<IProps> = ({ loading, items, addItemPageLink }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <ContentLoader
            speed={2}
            width={200}
            height={80}
            viewBox='0 0 976 150'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='5' y='25' rx='5' ry='5' width='520' height='350' />
          </ContentLoader>
          <table className='rttable table sticky' style={{ width: '100%' }}>
            <tbody>
              {loading && (
                <tr>
                  <TableLoader colSpan={2} />
                  <TableLoader colSpan={10} />
                </tr>
              )}
              {!loading && items?.length === 0 && (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={10}>
                    <NoDataFound addItemPageLink={addItemPageLink} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default TablePreview;
