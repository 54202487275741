import React, { useEffect, useState } from "react";

// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";
import { itemProcessData } from "../constants/itemBatchTableData";
import CardWrapper from "@components/Card/CardWrapper";
interface IRTable {
    data: any
}

const BatchProgressPage: React.FC<IRTable> = ({data}) => {
    const [transformedData, setTransaformedData] = useState<any>([])
    console.log(data, "Data")

    useEffect(() => {
        const transformedData = Object.entries(data).map(([actionName, details]) => ({
            actionName,
            state: (details as any).state,           // Use 'any' assertion here
            pendingItems: (details as any).totalItems - (details as any).processedItems,
            updatedOn: (details as any).updatedOn,
          }));
          setTransaformedData(transformedData)
        //   console.log(transformedData)
    },[data])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={itemProcessData().header as Column[]}
            data={transformedData}
            checkedIds={[]}
            tableTitle="Batch Progress"
            hideRightToolbar
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default BatchProgressPage;
