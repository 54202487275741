import { useMutation } from "@apollo/client";
import useNotifier from "hooks/useNotifier";
import { clientUpload } from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import {
  uploadAuthenticationInstanceDockerStandaloneMutation,
  uploadCodeEventsDockerStandaloneMutation,
  uploadCodeSystemsDockerStandaloneMutation,
  uploadCustomerDetailsDockerStandaloneMutation,
  uploadDistributorDetailsDockerStandaloneMutation,
  uploadItemBatchesDockerStandaloneMutation,
  uploadItemFirmwaresDockerStandaloneMutation,
  uploadItemFleetsDockerStandaloneMutation,
  uploadItemsDockerStandaloneMutation,
  uploadItemSKUsDockerStandaloneMutation,
  uploadItemStocksDockerStandaloneMutation,
  uploadRolesDockerStandaloneMutation,
  uploadRolesPermissionsDockerStandaloneMutation,
} from "../mutations";
import {
  UploadAuthenticationInstanceDockerStandalone,
  UploadAuthenticationInstanceDockerStandaloneVariables,
} from "../types/UploadAuthenticationInstanceDockerStandalone";
import {
  UploadCodeEventsDockerStandalone,
  UploadCodeEventsDockerStandaloneVariables,
} from "../types/UploadCodeEventsDockerStandalone";
import {
  UploadCodeSystemsDockerStandalone,
  UploadCodeSystemsDockerStandaloneVariables,
} from "../types/UploadCodeSystemsDockerStandalone";
import {
  UploadCustomerDetailsDockerStandalone,
  UploadCustomerDetailsDockerStandaloneVariables,
} from "../types/UploadCustomerDetailsDockerStandalone";
import {
  UploadDistributorDetailsDockerStandalone,
  UploadDistributorDetailsDockerStandaloneVariables,
} from "../types/UploadDistributorDetailsDockerStandalone";
import {
  UploadItemBatchesDockerStandalone,
  UploadItemBatchesDockerStandaloneVariables,
} from "../types/UploadItemBatchesDockerStandalone";
import {
  UploadItemFirmwaresDockerStandalone,
  UploadItemFirmwaresDockerStandaloneVariables,
} from "../types/UploadItemFirmwaresDockerStandalone";
import {
  UploadItemFleetsDockerStandalone,
  UploadItemFleetsDockerStandaloneVariables,
} from "../types/UploadItemFleetsDockerStandalone";
import {
  UploadItemsDockerStandalone,
  UploadItemsDockerStandaloneVariables,
} from "../types/UploadItemsDockerStandalone";
import {
  UploadItemSKUsDockerStandalone,
  UploadItemSKUsDockerStandaloneVariables,
} from "../types/UploadItemSKUsDockerStandalone";
import {
  UploadItemStocksDockerStandalone,
  UploadItemStocksDockerStandaloneVariables,
} from "../types/UploadItemStocksDockerStandalone";
import {
  UploadRolesDockerStandalone,
  UploadRolesDockerStandaloneVariables,
} from "../types/UploadRolesDockerStandalone";
import {
  UploadRolesPermissionsDockerStandalone,
  UploadRolesPermissionsDockerStandaloneVariables,
} from "../types/UploadRolesPermissionsDockerStandalone";

export const useUploadAuthentication = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadAuthenticationInstanceDockerStandalone,
    UploadAuthenticationInstanceDockerStandaloneVariables
  >(uploadAuthenticationInstanceDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadCodeEventsDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadCodeEventsDockerStandalone,
    UploadCodeEventsDockerStandaloneVariables
  >(uploadCodeEventsDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadCodeSystemsDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadCodeSystemsDockerStandalone,
    UploadCodeSystemsDockerStandaloneVariables
  >(uploadCodeSystemsDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadCustomerDetailsDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadCustomerDetailsDockerStandalone,
    UploadCustomerDetailsDockerStandaloneVariables
  >(uploadCustomerDetailsDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadDistributorDetailsDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadDistributorDetailsDockerStandalone,
    UploadDistributorDetailsDockerStandaloneVariables
  >(uploadDistributorDetailsDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadItemBatchesDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadItemBatchesDockerStandalone,
    UploadItemBatchesDockerStandaloneVariables
  >(uploadItemBatchesDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadItemFirmwaresDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadItemFirmwaresDockerStandalone,
    UploadItemFirmwaresDockerStandaloneVariables
  >(uploadItemFirmwaresDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadItemFleetsDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadItemFleetsDockerStandalone,
    UploadItemFleetsDockerStandaloneVariables
  >(uploadItemFleetsDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadItemSKUsDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadItemSKUsDockerStandalone,
    UploadItemSKUsDockerStandaloneVariables
  >(uploadItemSKUsDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadItemStocksDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadItemStocksDockerStandalone,
    UploadItemStocksDockerStandaloneVariables
  >(uploadItemStocksDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadItemsDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadItemsDockerStandalone,
    UploadItemsDockerStandaloneVariables
  >(uploadItemsDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};

export const useUploadRolesDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadRolesDockerStandalone,
    UploadRolesDockerStandaloneVariables
  >(uploadRolesDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};
export const useUploadRolesPermissionsDockerStandalone = () => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UploadRolesPermissionsDockerStandalone,
    UploadRolesPermissionsDockerStandaloneVariables
  >(uploadRolesPermissionsDockerStandaloneMutation, {
    client: clientUpload,
    onCompleted: (data) => {
      notify({ text: "Success", status: "success" });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
  };
};
