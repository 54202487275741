import { useMutation } from "@apollo/client";
import useNotifier from "hooks/useNotifier";
import thingMakeClientSimulator from "utils/thingMakeSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import { batchCodeMutation } from "../mutations";
import { BatchCode, BatchCodeVariables } from "../types/BatchCode";

const useBatchCode = (onCompleted: (data: any) => void) => {
  const notify = useNotifier();
  const [create, options] = useMutation<BatchCode, BatchCodeVariables>(
    batchCodeMutation,
    {
      client: thingMakeClientSimulator,
      onCompleted: async (data) => {
        onCompleted && onCompleted(data);
      },
      onError: (err) => {
        handleGQLErrors(notify, err);
      },
    }
  );

  return {
    create,
    options,
  };
};

export default useBatchCode;
