import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { categoryFragment } from '../category/queries';
import { pageDataFragment } from '../common/queries';

import {
  GetAllCategorySelectorSelectors,
  GetAllCategorySelectorSelectorsVariables,
} from './types/GetAllCategorySelectorSelectors';
import {
  GetSpecificCategorySelector,
  GetSpecificCategorySelectorVariables,
} from './types/GetSpecificCategorySelector';

/**
 * type CategorySelector {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
selection: String!
targetKey: String!
selectedCategories: [Category!]!
}
 */
export const categorySelectorFragment = gql`
  ${categoryFragment}
  fragment CategorySelector on CategorySelector {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    selection
    selectedCategories {
      ...Category
    }
  }
`;

/**
 * type CategorySelectorConnection {
edges: [CategorySelectorEdge!]
pageInfo: CategorySelectorPageInfo
}
 */

/**
 * getSpecificCategorySelector(
id: String!
): CategorySelector!
 */
const getSpecificCategorySelectorQuery = gql`
  ${categorySelectorFragment}
  query GetSpecificCategorySelector($id: String!) {
    getSpecificCategorySelector(id: $id) {
      ...CategorySelector
    }
  }
`;
/**
 * type CategorySelectorEdge {
cursor: String
node: CategorySelector
}
 */
const categorySelectorEdgeFragment = gql`
  ${categorySelectorFragment}
  fragment CategorySelectorEdge on CategorySelectorEdge {
    cursor
    node {
      ...CategorySelector
    }
  }
`;

/**
 * type CategorySelectorPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const categorySelectorPageInfoFragment = gql`
  fragment CategorySelectorPageInfo on CategorySelectorPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;

/**
 * type CategorySelectorConnection {
edges: [CategorySelectorEdge!]
pageInfo: CategorySelectorPageInfo
}
 */
const categorySelectorConnectionFragment = gql`
  ${categorySelectorEdgeFragment}
  ${categorySelectorPageInfoFragment}
  fragment CategorySelectorConnection on CategorySelectorConnection {
    edges {
      ...CategorySelectorEdge
    }
    pageInfo {
      ...CategorySelectorPageInfo
    }
  }
`;

/**
 * type GetAllCategoriesSelectorResponse {
page: CategorySelectorConnection!
pageData: PageData
}
 */
const getAllCategoriesSelectorResponseFragment = gql`
  ${categorySelectorConnectionFragment}
  ${pageDataFragment}
  fragment GetAllCategoriesSelectorResponse on GetAllCategoriesSelectorResponse {
    page {
      ...CategorySelectorConnection
    }
    pageData {
      ...PageData
    }
  }
`;

/**
 * getAllCategorySelectorSelectors(
before: String
after: String
first: Int
last: Int
): GetAllCategoriesSelectorResponse!
 */
const getAllCategorySelectorSelectorsQuery = gql`
  ${getAllCategoriesSelectorResponseFragment}
  query GetAllCategorySelectorSelectors(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllCategorySelectorSelectors(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllCategoriesSelectorResponse
    }
  }
`;

export const useLazyGetAllCategoriesSelectorQuery = (
  variables: GetAllCategorySelectorSelectorsVariables
) =>
  useLazyQuery<
    GetAllCategorySelectorSelectors,
    GetAllCategorySelectorSelectorsVariables
  >(getAllCategorySelectorSelectorsQuery, {
    client: clientEcommerce,
    variables,
  });

export const useGetAllCategoriesSelectorQuery = (
  variables: GetAllCategorySelectorSelectorsVariables
) =>
  useQuery<
    GetAllCategorySelectorSelectors,
    GetAllCategorySelectorSelectorsVariables
  >(getAllCategorySelectorSelectorsQuery, {
    client: clientEcommerce,
    variables,
  });

export const useGetSpecificCategorySelectorQuery = (id: string) =>
  useQuery<GetSpecificCategorySelector, GetSpecificCategorySelectorVariables>(
    getSpecificCategorySelectorQuery,
    {
      variables: { id },
      client: clientEcommerce,
    }
  );
