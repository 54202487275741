import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import { fleetValidationSchema } from 'apps/things/app/validations';
import ItemForm from './ItemForm';
import CardWrapper from '@components/Card/CardWrapper';
import { UpdateItemFleetInput } from 'apps/things/app/types/thingsGlobalTypes';

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: UpdateItemFleetInput) => void;
  isLoading: boolean;
  item: UpdateItemFleetInput;
  itemList: string[];
  setItemList: (args: string[]) => void;
  editForm?: boolean;
}

const AddItem: React.FC<IRTable> = ({
  handleSubmit,
  isLoading,
  item,
  itemList,
  setItemList,
  editForm
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          {item.assignDate && (
            <Formik
              onSubmit={async (values: any) => handleSubmit(values)}
              initialValues={{ ...item, fleetName: item.fleetName }}
              validationSchema={fleetValidationSchema}
              validateOnChange={true}
            >
              {(formBag) => (
                <ItemForm
                  formBag={
                    (formBag as unknown) as FormikProps<UpdateItemFleetInput>
                  }
                  itemList={itemList}
                  setItemList={setItemList}
                  disableLoginButton={isLoading}
                  editForm = {editForm}
                />
              )}
            </Formik>
          )}
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItem;
