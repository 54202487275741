import React, { Suspense } from "react";
import cx from "classnames";
import { Outlet } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components

// core components
import AdminNavbar from "@components/Navbars/AdminNavbar.js";
import Sidebar from "@components/Sidebar/Sidebar.js";

import routes from "routes/routes";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { IRoute } from "routes/types";
import { useAuth } from "admin/auth/context/AuthProvider";
import { USER_TYPES } from "admin/auth/constants/auth";
import { IS_STANDALONE } from "config";
import ErrorBoundary from "@components/ErrorBoundary";

var ps: any;

const Dashboard = (props: any) => {
  const { classes, ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [color] = React.useState("blue");
  const [bgColor] = React.useState("black");
  const { userPref, loggedInUserId } = useAuth();
  
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef<HTMLDivElement>();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      if (mainPanel && mainPanel.current)
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes: IRoute[]): string => {
    let activeRoute = "Omnivoltaic  Energy Solutions";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute: string = getActiveRoute(
          routes[i].views as IRoute[]
        );
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        let layout = (routes && routes[i] && routes[i].layout) || "";
        let path = (routes && routes[i] && routes[i].path) || "";

        if (window.location.href.indexOf(layout + path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
 
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  
  const _routes = routes
    .filter((item) => item.layout !== "/auth")
    .filter((item) => {
      if (!item.permissions?.includes(userPref?.roleName) && !item.permissions?.includes(userPref?.subrole?.name)) {
        return null;
      }

      let _item = 
      item.permissions?.includes(userPref?.roleName) || 
      item.permissions?.includes(userPref?.subrole?.name);

  
      if (IS_STANDALONE?.toString()?.toLowerCase() === "true") {
        if (item.permissions?.includes(USER_TYPES.EXCLUDE_STANDALONE)) {
          return null;
        }
      }
      if(item.path === "/accounts" && loggedInUserId !== "662901b8082c0b1a656894d2"){
        
        for(let i = 0; i<item.views.length; i++) {
          if (item.views[i].path === "/angaza-review") {
            item.views.splice(i, 1)
          }
        }
      }

      return _item;
    });
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={_routes}
        logoText={"Omnivoltaic"}
        logo={
          "https://res.cloudinary.com/oves/image/upload/t_banner-bg2/v1656757962/promo/Woman_Before_Dashboard.jpg)"
        }
        image={
          "https://res.cloudinary.com/oves/image/upload/t_banner-bg2/v1656757962/promo/Woman_Before_Dashboard.jpg)"
        }
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        sidebarMinimize={sidebarMinimize.bind(this)}
        // rtlActive
        {...rest}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(routes as IRoute[])}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <ErrorBoundary>
            <div className={classes.content}>
              <div className={classes.container}>
              
                <Outlet />

              </div>
            </div>
          </ErrorBoundary>
        </div>
      </Suspense>
    </div>
  );
};

export default withStyles(styles as any)(Dashboard as any) as any;
