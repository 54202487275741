export const authFormFields = {
  login: {
    email: 'email',
    password: 'password',
  },

  registerStaffAgent: {
    email: 'email',
    password: 'password',
    confirmPassword: 'confirmPassword',
  },
  register: {
    email: 'email',
    password: 'password',
    confirmPassword: 'confirmPassword',
    newPassword: 'newPassword',
    firstName: 'firstName',
    lastName: 'lastName',
    authenticationToken: 'authenticationToken',
    birthDate: 'birthDate',
    idString: 'idString',
    idType: 'idType',
    personName: 'personName',
    personDescription: 'personDescription',
    phone: 'phone',
    social: 'social',
    address: 'address',
    unit: 'address.unit',
    street: 'address.street',
    city: 'address.city',
    srpc: 'address.srpc',
    country: 'address.country',
    postcode: 'address.postcode',
    locationAddressDto: 'address.locationAddressDto',
    inputAddressLatitude: 'address.locationAddressDto.inputAddressLatitude',
    inputAddressLongitude: 'address.locationAddressDto.inputAddressLongitude',

  },
  updatePassword: {
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    confirmNewPassword: 'confirmNewPassword',
  },
};
