import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { distributorIncreaseResetCodeCountForItemMutation } from '../mutations';
import { DistributorIncreaseResetCodeCountForItem, DistributorIncreaseResetCodeCountForItemVariables } from '../types/DistributorIncreaseResetCodeCountForItem';

const useIncreaseResetCodeCount = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    DistributorIncreaseResetCodeCountForItem,
    DistributorIncreaseResetCodeCountForItemVariables
  >(distributorIncreaseResetCodeCountForItemMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Updated successfully', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useIncreaseResetCodeCount;
