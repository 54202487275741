import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import ProductBatchContainer from './ProductBatchContainer';
import ProvisionContainer from './ProvisionContainer';
import './styles.scss';

interface IProps { }
export const routes = {
         path: "/admin/provision",
         element: <DefaultOutlet />,
         children: [
           { index: true, element: <ProvisionContainer /> },
           {
             path: "/admin/provision/product-item-batch",
             element: <ProductBatchContainer />,
           },
         ],
       };
const ProvisionIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};


export default ProvisionIndex;
