import gql from 'graphql-tag';
import { loginUsersFragment } from './queries';

/**
 * Mutation.deleteLoginUser(
userId: String!
): Success!
 */
export const deleteLoginUserMutation = gql`
  mutation DeleteLoginUser($userId: String!) {
    deleteLoginUser(userId: $userId) {
      message
    }
  }
`;

/**
 * Mutation.updateLoginUser(
updateLoginUserInput: UpdateLoginUserInput!
): LoginUsers!
 */
export const updateLoginUserMutation = gql`
  ${loginUsersFragment}
  mutation UpdateLoginUser($updateLoginUserInput: UpdateLoginUserInput!) {
    updateLoginUser(updateLoginUserInput: $updateLoginUserInput) {
      ...LoginUsers
    }
  }
`;

/**
 * Mutation.updateLoginUserPassword(
updateLoginUserPasswordInput: UpdateUserPasswordInput!
): Success!
 */
export const updateLoginUserPasswordMutation = gql`
  mutation updateLoginUserPassword(
    $updateLoginUserPasswordInput: UpdateUserPasswordInput!
  ) {
    updateLoginUserPassword(
      updateLoginUserPasswordInput: $updateLoginUserPasswordInput
    ) {
      message
    }
  }
`;

/**
 * Mutation.generateUserRoleSignUpToken(
generateUserRoleSignUpTokenInput: GenerateUserRoleSignUpTokenInput!
): Success!
 */
export const generateUserRoleSignUpTokenMutation = gql`
  mutation GenerateUserRoleSignUpToken(
    $generateUserRoleSignUpTokenInput: GenerateUserRoleSignUpTokenInput!
  ) {
    generateUserRoleSignUpToken(
      generateUserRoleSignUpTokenInput: $generateUserRoleSignUpTokenInput
    ) {
      message
    }
  }
`;


