import Button from '@components/CustomButtons/Button';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Formik } from 'formik';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect, useState } from 'react';
import { createMessageFromTemplateMutation } from '../mutations';
import { useMutation } from '@apollo/client';
import clientAccount from '../../utils/clientAccount';
import { handleGQLErrors } from 'utils/gqlErrors';
import { CreateMessageFromTemplateVariables, CreateMessageFromTemplate } from '../types/CreateMessageFromTemplate';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { MessageGroupContext } from '../context/MessageGroupProvider';
import { MessageTemplateContext } from '../../mesageTemplate/context/MessageTemplateProvider';
import { useContext } from 'react';
import AsyncSeachableSelect from '@components/CustomSelect/AsyncSeachableSelect';
import { itemFields } from '../constants';
import CardWrapper from '@components/Card/CardWrapper';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
interface IProps {

}

const CreateMessageForm: React.FC<IProps> = () => {
  const [messageGroupOptions, setMessageGroupOptions] = useState<{ label: string; value: string }[]>([]);
  const [messageTemplateOptions, setMessageTemplateOptions] = useState<{ label: string; value: string }[]>([]);
  const notify = useNotifier();
  const {
    items: MessageGroupItems,
    refetchItems: refetchGroupItems,
    getItems: getGroupItems
  } = useContext(MessageGroupContext);
  const {
    items: MessageTemplateItems,
    refetchItems: refetchTemplateItems,
    getItems: getTemplateItems
  } = useContext(MessageTemplateContext);

  const [mutation, mutationOpts] = useMutation<
    CreateMessageFromTemplate,
    CreateMessageFromTemplateVariables
  >(createMessageFromTemplateMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      notify({ text: 'Accounted activated successfully', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  useEffect(() => { 
    if (MessageGroupItems.length > 0) {
      setMessageGroupOptions(
        MessageGroupItems.map((item) => ({
          value: item?.node?._id || '',
          label: item?.node?.name || '',
        })) || []
      );

    }

  }, [MessageGroupItems])

  useEffect(() => { 
    if (MessageTemplateItems.length > 0) {
      setMessageTemplateOptions(
        MessageTemplateItems.map((item) => ({
          value: item?.node?._id || '',
          label: item?.node?.name || '',
        })) || []
      )
    }
  }, [MessageTemplateItems])

  useEffect(() => {
    getGroupItems();
    getTemplateItems();
    // eslint-disable-next-line
  },[])
  
  const searchMessageGroup = async (search: string) => {
    if (!refetchGroupItems) {
      return messageGroupOptions;
    }

    const refetchData = await refetchGroupItems({
      first: 20,
      search,
    });
    const items = refetchData?.data?.getAllMessageGroups?.page?.edges?.map(
      (item) => {
        return {
          label: item.node?.name || "",
          value: item.node?._id || "",
        };
      }
    );
    return items as { value: string; label: string }[];
  };

  const searchMessageTemplate = async (search: string) => {
    if (!refetchTemplateItems) {
      return messageTemplateOptions;
    }

    const refetchData = await refetchTemplateItems({
      first: 20,
      search,
    });
    const items = refetchData?.data?.getAllMessageTemplates?.page?.edges?.map(
      (item) => {
        return {
          label: item.node?.name || "",
          value: item.node?._id || "",
        };
      }
    );
    return items as { value: string; label: string }[];
  };
  return (
    <>
    <CardWrapper>
    <GoBackHeader />
      <Formik
        onSubmit={async (values: any, { resetForm }) => {
          await mutation({
            variables: {
              createMessageFromTemplateInput: {
                messageGroupId: values.messageGroupId,
                messageTemplateId: values.messageTemplateId
              }
            },
          });
          resetForm();
        }}
        initialValues={{}}
      >
        {(formBag) => (
          <GridContainer>
            <GridHalfWrapper>
              <AsyncSeachableSelect
                onChange={(e: any) => {
                  formBag.setFieldValue(itemFields.messageGroupId, e);
                }}
                placeholder={"Select Message Group"}
                options={messageGroupOptions}
                searchPromise={searchMessageGroup}
                initialValue={
                     {}
                }
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <AsyncSeachableSelect
                onChange={(e: any) => {
                  formBag.setFieldValue(itemFields.messageTemplateId, e);
                }}
                placeholder={"Select Message Template"}
                options={messageTemplateOptions}
                searchPromise={searchMessageTemplate}
                initialValue={
                {}
                }
              />
            </GridHalfWrapper>
            <GridItem sm={4}>
              <Button
                color='primary'
                disabled={mutationOpts.loading}
                onClick={ () => formBag.submitForm()}
              >
                Save
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </Formik>
      </CardWrapper>
    </>
  );
};

export default CreateMessageForm;
