import { ermAPI as legacyApi } from 'apps/migration/app/services/ermAPI';
import { legacyTypes } from './constants';
export class LegacyActions {
  static listLegacy(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY,
      payload: { data },
    };
  }

  static listLegacySuccess(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY_SUCCESS,
      payload: { data },
    };
  }
  static listLegacyFail(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY_FAIL,
      payload: { data },
    };
  }

  static getLegacy(params: string) {
    return legacyApi.get(`scanning-iccs?${params}`)
  }

  static getMigrationEstimationLegacy(search: string) {
    return legacyApi.get(`migration-time?search=${search}`)
  }
}
