import { useEffect, useState } from 'react';
import { useGetAllClientCustomersQuery } from '../queries';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { ISelectOption } from 'utils';

const useCustomer = () => {
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const { userPref } = useAuth();

  const { data, loading, refetch } = useGetAllClientCustomersQuery({
    first: 20,
    clientId: userPref?._id,
  });

  useEffect(() => {
    setOptions(
      data?.getAllClientCustomers?.page?.edges?.map((item) => ({
        _id: item?.node?._id || '',
        name: item?.node?.name || '',
      })) || []
    ); // eslint-disable-next-line
  }, [data]);

  return {
    options,
    refetch,
    loading,
  };
};

export default useCustomer;
