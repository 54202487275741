import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { pageDataFragment } from '../common/queries';
import { productFragment } from '../products/queries';
import {
  GetAllProductSelectorSelectors,
  GetAllProductSelectorSelectorsVariables,
} from './types/GetAllProductSelectorSelectors';
import {
  GetSpecificProductSelector,
  GetSpecificProductSelectorVariables,
} from './types/GetSpecificProductSelector';

/**
 * type ProductSelector {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
selection: String!
targetKey: String!
selectedProducts: [Product!]!
}
 */
export const productSelectorFragment = gql`
  ${productFragment}
  fragment ProductSelector on ProductSelector {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    selection
    description
    selectedProducts {
      ...Product
    }
  }
`;

/**
 * getSpecificProductSelector(
id: String!
): ProductSelector!
 */
const getSpecificProductSelectorQuery = gql`
  ${productSelectorFragment}
  query GetSpecificProductSelector($id: String!) {
    getSpecificProductSelector(id: $id) {
      ...ProductSelector
    }
  }
`;

/**
 * type ProductSelectorEdge {
cursor: String
node: ProductSelector
}
 */
const productSelectorEdgeFragment = gql`
  ${productSelectorFragment}
  fragment ProductSelectorEdge on ProductSelectorEdge {
    cursor
    node {
      ...ProductSelector
    }
  }
`;
/**
 * type ProductSelectorPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const productSelectorPageInfoFragment = gql`
  fragment ProductSelectorPageInfo on ProductSelectorPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type ProductSelectorConnection {
edges: [ProductSelectorEdge!]
pageInfo: ProductSelectorPageInfo
}
 */
const productSelectorConnectionFragment = gql`
  ${productSelectorEdgeFragment}
  ${productSelectorPageInfoFragment}
  fragment ProductSelectorConnection on ProductSelectorConnection {
    edges {
      ...ProductSelectorEdge
    }
    pageInfo {
      ...ProductSelectorPageInfo
    }
  }
`;
/**
 * type GetAllProductsSelectorResponse {
page: ProductSelectorConnection!
pageData: PageData
}
 */
const getAllProductsSelectorResponseFragment = gql`
  ${pageDataFragment}
  ${productSelectorConnectionFragment}
  fragment GetAllProductsSelectorResponse on GetAllProductsSelectorResponse {
    page {
      ...ProductSelectorConnection
    }
    pageData {
      ...PageData
    }
  }
`;

/**
 * getAllProductSelectorSelectors(
before: String
after: String
first: Int
last: Int
): GetAllProductsSelectorResponse!
 */
const getAllProductSelectorSelectorsQuery = gql`
  ${getAllProductsSelectorResponseFragment}
  query GetAllProductSelectorSelectors(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllProductSelectorSelectors(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllProductsSelectorResponse
    }
  }
`;

export const useGetSpecificProductSelectorQuery = (id: string) =>
  useQuery<GetSpecificProductSelector, GetSpecificProductSelectorVariables>(
    getSpecificProductSelectorQuery,
    {
      variables: { id },
      client: clientEcommerce,
    }
  );
export const useLazyGetSpecificProductSelectorQuery = (id: string) =>
  useLazyQuery<GetSpecificProductSelector, GetSpecificProductSelectorVariables>(
    getSpecificProductSelectorQuery,
    {
      variables: { id },
      client: clientEcommerce,
    }
  );

export const useLazyGetAllProductSelectorQuery = (
  variables: GetAllProductSelectorSelectorsVariables
) =>
  useLazyQuery<
    GetAllProductSelectorSelectors,
    GetAllProductSelectorSelectorsVariables
  >(getAllProductSelectorSelectorsQuery, {
    client: clientEcommerce,
    variables,
  });

export const useGetAllProductSelectorQuery = (
  variables: GetAllProductSelectorSelectorsVariables
) =>
  useQuery<
    GetAllProductSelectorSelectors,
    GetAllProductSelectorSelectorsVariables
  >(getAllProductSelectorSelectorsQuery, {
    client: clientEcommerce,
    variables,
  });
