import React from "react";
import { Row } from "react-table";

import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import FleetsPage from "./FleetsPage";
import { useNavigate, createSearchParams } from "react-router-dom";
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const SkuTableActions: React.FC<IProps> = ({ row }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const thresholdWidth = 600;
  const drawerWidthDesktop = { minWidth: "70vw", maxWidth: "70vw" }
  const drawerWidthMobile = { minWidth: "100vw", maxWidth: "100vw" }
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <div className="actions-right" style={{ display: "flex" }}>
      <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
        <Tooltip
          title="Delete"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            id="expander"
            aria-label="Delete"
            className={classes.tableActionButton}
            style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
          >
            <Delete
              className={classes.tableActionButtonIcon + " " + classes.delete}
            />
          </IconButton>
        </Tooltip>
        {(true) && (
          <span className={classes.stopSign} role="img" aria-label="Stop">
            🚫
          </span>
        )}
      </div>
      <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
        <Tooltip
          id='expander'
          title='Edit'
          placement='top'
          classes={{ tooltip: classes.tooltip }}

        >
          <IconButton
            id='expander'
            aria-label='Edit'
            className={classes.tableActionButton}
            style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
          >
            <Edit
              className={classes.tableActionButtonIcon + ' ' + classes.edit}
            />
          </IconButton>
        </Tooltip>
        {(true) && (
          <span className={classes.stopSign} role="img" aria-label="Stop">
            🚫
          </span>
        )}
      </div>
      <HoverDropdown
        menus={[
          {
            name: "Fleets",
            action: () => {
              setIsModalOpen(true);
            },
          },

          {
            name: "Code Generation Settings",
            action: () => {
              const queryParams = createSearchParams({ distributorId: row.values["node._id"] });
              navigate(`/admin/distributors/settings?${queryParams}`)
            }
          }
        ]}
      />

      {isModalOpen ? (
        <DrawerSidebar
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          title={`${row.values["node.orgContactPerson.contact.email"]} fleets`}
          styles={window.innerWidth <= thresholdWidth ? drawerWidthMobile : drawerWidthDesktop}
        >
          <FleetsPage clientId={row.values["node._id"]} />
        </DrawerSidebar>
      ) : null}
    </div>
  );
};

export default SkuTableActions;
