import { gql } from '@apollo/client';
import { itemFirmwareFragment } from './queries';

/**
 * mutation {
  createItemFirmware(createItemFirmwareInput: {})
}
 */
export const createItemFirmwareMutation = gql`
  ${itemFirmwareFragment}
  mutation CreateItemFirmware(
    $createItemFirmwareInput: CreateItemFirmwareInput!
  ) {
    createItemFirmware(createItemFirmwareInput: $createItemFirmwareInput) {
      ...ItemFirmware
    }
  }
`;

/**
 * updateItemFirmware(
updateItemFirmwareInput: UpdateItemFirmwareInput!
): ItemFirmware!
 */
export const updateItemFirmwareMutation = gql`
  ${itemFirmwareFragment}
  mutation UpdateItemFirmware(
    $updateItemFirmwareInput: UpdateItemFirmwareInput!
  ) {
    updateItemFirmware(updateItemFirmwareInput: $updateItemFirmwareInput) {
      ...ItemFirmware
    }
  }
`;

/**
 * deleteItemFirmware(
itemFirmwareId: String!
): String!
 */
export const deleteItemFirmwareMutation = gql`
  mutation DeleteItemFirmware($itemFirmwareId: String!) {
    deleteItemFirmware(itemFirmwareId: $itemFirmwareId)
  }
`;
