import { Epic } from 'redux-observable';
import { tap, ignoreElements, filter } from 'rxjs/operators';
import { userPrefTypes } from 'store/actionTypes/userPrefTypes';
import { ActionType, isOfType, StateType } from 'typesafe-actions';

export type RootState = StateType<
  ReturnType<typeof import('../reducers').default>
>;
export type RootAction = ActionType<typeof import('../actions').default>;

export class UserPrefEpics {
  static toggleColumn: Epic<RootAction, RootAction, RootState> = (
    action$,
    state$
  ) => {
    return action$.pipe(
      filter(isOfType(userPrefTypes.TOGGLE_COLUMN)),
      tap((action) => {
        console.warn(
          `action type must be equal: ${userPrefTypes.TOGGLE_COLUMN} === ${action.type}`
        );
      }),
      ignoreElements()
    );
  };
}
