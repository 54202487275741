import DateCell from '@components/ReactTable/DateCell';
import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyTableActions';

export const legacyTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <Actions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    { Header: 'codeHistoryID', accessor: 'codeHistoryID' },
    {
      Header: 'productItemID',
      accessor: 'productItemID',
    },
    {
      Header: 'productItemOEM_SN',
      accessor: 'productItemOEM_SN',
    },
    {
      Header: 'otpGeneratorID',
      accessor: 'otpGeneratorID',
    },
    {
      Header: 'codeIndex',
      accessor: 'codeIndex',
    },
    {
      Header: 'codeValue',
      accessor: 'codeValue',
    },
    {
      Header: 'codeDate',
      accessor: 'codeDate',
      Cell: DateCell,
    },
    {
      Header: 'codeDays',
      accessor: 'codeDays',
    },
  ],
};
