import OVSForm, { IFormFields } from '@components/Form/OVSForm';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Button, createStyles, FormControl, makeStyles, Theme } from '@material-ui/core';
import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
interface IProps {
	oemItemIds: { oemItemId: string; itemId: string }[]
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			minWidth: 120,
			width: '100%',
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
			width: 50,
		},
	})
);
const AssignItemForm: React.FC<{ formBag: FormikProps<IProps>, index: number, value: string, add: () => void, remove: (index: number) => void }> = ({ formBag, index, value, add, remove }) => {
	const classes = useStyles();
	const formFieldsData = useMemo(
		() => [
			{
				name: '',
				fields: [
					{
						type: 'text',
						name: `oemItemIds[${index}].oemItemId`,
						label: 'oem ItemId',
						value: formBag.values.oemItemIds[index].oemItemId || '',
						md: 5
					},
					{
						type: 'text',
						name: `oemItemIds[${index}].itemId`,
						label: 'ItemId',
						value: formBag.values.oemItemIds[index].itemId || '',
						md: 5,
						disabled: true
					},
				]
			}], [formBag.values, index])

	return (
		<FormControl className={classes.formControl}>
			<GridContainer>
				<GridItem xs={12} sm={2} md={2}>
					<Button
						title='remove'
						className={classes.selectEmpty}
						onClick={() => remove(index)}
					>
						<RemoveIcon />
					</Button>
				</GridItem>
				<GridItem xs={12} sm={10} md={10}>
				<GridContainer>
					<OVSForm formFieldsData={formFieldsData as IFormFields[]} />
					</GridContainer>
				</GridItem>

			</GridContainer>
		</FormControl>
	)
}

export default AssignItemForm