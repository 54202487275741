import { useMutation } from '@apollo/client';
import CardGridLoader from '@components/Loaders/CardGridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import AddItem from '../components/AddItem';
import { itemRoutes } from '../constants';
import { useGetSpecificItemFleetQuery } from '../queries';
import { FleetContext } from '../context/FleetProvider';

import {
  UpdateItemFleet,
  UpdateItemFleetVariables,
} from '../types/UpdateItemFleet';
import { UpdateItemFleetInput } from 'apps/things/app/types/thingsGlobalTypes';
import { updateItemFleetMutation } from '../mutations';

const EditItemSKUContainer = () => {
  const notify = useNotifier();
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { data, loading } = useGetSpecificItemFleetQuery({
    id: itemId,
  });
  const { refetchItems: refetch, getItems } = useContext(FleetContext);
  const [item, setItem] = React.useState({} as UpdateItemFleetInput);
  const [itemList, setItemList] = useState([] as string[]);

  React.useEffect(() => {

    setItemList(
      data?.getSpecificItemFleet?.itemList?.map((item) => item._id) || []
    );
    setItem({
      assignDate: data?.getSpecificItemFleet?.assignDate,
      description: data?.getSpecificItemFleet?.description,
      fleetName: data?.getSpecificItemFleet?.fleetName,

      clientId: data?.getSpecificItemFleet?.distributor?._id,
      itemFleetId: itemId,
      resetCodeCount: data?.getSpecificItemFleet?.resetCodeCount,
      dayCodeCountLimit: data?.getSpecificItemFleet?.dayCodeCountLimit,
      totalCodeCountLimit: data?.getSpecificItemFleet?.totalCodeCountLimit,
      codeGenInterval: data?.getSpecificItemFleet?.codeGenInterval,
      maxCreditStackDays: data?.getSpecificItemFleet?.maxCreditStackDays,
      maxCreditStackCodeEvents: data?.getSpecificItemFleet?.maxCreditStackCodeEvents,
      freeCodeCount: data?.getSpecificItemFleet?.freeCodeCount,
      daysToCheckDuration: data?.getSpecificItemFleet?.daysToCheckDuration,
      minimumDayCodesGenerated: data?.getSpecificItemFleet?.minimumDayCodesGenerated,
      disableResetCodeCount: data?.getSpecificItemFleet?.disableResetCodeCount === null? false: !data?.getSpecificItemFleet?.disableResetCodeCount,
      disableDayCodeCountLimit: data?.getSpecificItemFleet?.disableDayCodeCountLimit === null? false : !data?.getSpecificItemFleet?.disableDayCodeCountLimit,
      disableTotalCodeCountLimit: data?.getSpecificItemFleet?.disableTotalCodeCountLimit === null ? false : !data?.getSpecificItemFleet?.disableTotalCodeCountLimit,
      disableCodeGenInterval: data?.getSpecificItemFleet?.disableCodeGenInterval ===null? false : !data?.getSpecificItemFleet?.disableCodeGenInterval,
      disableMaxCreditStackDays: data?.getSpecificItemFleet?.disableMaxCreditStackDays === null ? false : !data?.getSpecificItemFleet?.disableMaxCreditStackDays,
      disableMaxCreditStackCodeEvents: data?.getSpecificItemFleet?.disableMaxCreditStackCodeEvents === null? false : !data?.getSpecificItemFleet?.disableMaxCreditStackCodeEvents,
      disableDaysToCheckDuration: data?.getSpecificItemFleet?.disableDaysToCheckDuration === null? false: !data?.getSpecificItemFleet?.disableDaysToCheckDuration,
      disableMinimumDayCodesGenerated: data?.getSpecificItemFleet?.disableMinimumDayCodesGenerated === null ? false : !data?.getSpecificItemFleet?.disableMinimumDayCodesGenerated,
      disableFreeCode: data?.getSpecificItemFleet?.disableFreeCode === null ? false: !data?.getSpecificItemFleet?.disableFreeCode,
      
    }); // eslint-disable-next-line
  }, [data]);

  const [updateItem, updateItemOpts] = useMutation<
    UpdateItemFleet,
    UpdateItemFleetVariables
  >(updateItemFleetMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: 'success',
        text: 'Updated successfully',
      });
      getItems();
      refetch && refetch();
      history(itemRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleUpdate = (updateItemFleetInput: UpdateItemFleetInput) => {


    updateItemFleetInput.disableCodeGenInterval = !updateItemFleetInput.disableCodeGenInterval
    updateItemFleetInput.disableDayCodeCountLimit = !updateItemFleetInput.disableDayCodeCountLimit
    updateItemFleetInput.disableDaysToCheckDuration = !updateItemFleetInput.disableDaysToCheckDuration
    updateItemFleetInput.disableFreeCode = !updateItemFleetInput.disableFreeCode
    updateItemFleetInput.disableMaxCreditStackCodeEvents = !updateItemFleetInput.disableMaxCreditStackCodeEvents
    updateItemFleetInput.disableMinimumDayCodesGenerated = !updateItemFleetInput.disableMinimumDayCodesGenerated
    updateItemFleetInput.disableResetCodeCount = !updateItemFleetInput.disableResetCodeCount
    updateItemFleetInput.disableTotalCodeCountLimit = !updateItemFleetInput.disableTotalCodeCountLimit
    updateItemFleetInput.disableMaxCreditStackDays = !updateItemFleetInput.disableMaxCreditStackDays

    updateItem({
      variables: { updateItemFleetInput },
    });
  };

  if (!data?.getSpecificItemFleet?._id) return <CardGridLoader />;
  return (
    <AddItem
      handleSubmit={handleUpdate}
      isLoading={updateItemOpts.loading || loading}
      item={item}
      itemList={itemList}
      setItemList={setItemList}
      editForm = {true}
    />
  );
};

export default EditItemSKUContainer;
