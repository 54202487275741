import { gql, useLazyQuery, useQuery } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import {
  GetAllItemSKUs,
  GetAllItemSKUsVariables,
} from './types/GetAllItemSKUs';
import {
  GetSpecificItemSKUs,
  GetSpecificItemSKUsVariables,
} from './types/GetSpecificItemSKUs';

/**
 * type MediaMeta {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
curator: String!
description: String!
mediaURL: String!
}
 */
// export const mediaMetaFragment = gql`
//   fragment MediaMeta on MediaMeta {
//     _id
//   }
// `;
/**
 * type ItemSKU {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
type: ActorTypes!
actionScope: ActionScope!
actorName: String!
profile: String!
skuName: String!
family: ProductFamilies!
model: ProductModels!
mainImageURL: String
oemDescription: String
quantityProduced: Float!
quantityInStock: Float!
quantityInProduction: Float!
quantityInstalled: Float!
}
 */
export const itemSkuFragment = gql`
  fragment ItemSKU on ItemSKU {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    type
    actionScope
    actorName
    profile
    skuName
    productBase
    oemDescription
    properties {
      name
      attributes {
        prop
        value
        meta
      }
    }
  }
`;
/**
 * type ItemSKUEdge {
cursor: String
node: ItemSKU
}
 */
export const itemSKUEdgeFragment = gql`
  ${itemSkuFragment}
  fragment ItemSKUEdge on ItemSKUEdge {
    cursor
    node {
      ...ItemSKU
    }
  }
`;

/**
 * type PageData {
count: Float!
limit: Float!
offset: Float!
}
 */
export const pageDataFragment = gql`
  fragment PageData on PageData {
    count
    limit
    offset
  }
`;
/**
 * type ItemSKUPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
export const itemSKUPageInfoFragment = gql`
  fragment ItemSKUPageInfo on ItemSKUPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type ItemSKUConnection {
edges: [ItemSKUEdge!]
pageInfo: ItemSKUPageInfo
}
 */
export const itemSKUConnectionFragment = gql`
  ${itemSKUEdgeFragment}
  ${itemSKUPageInfoFragment}
  fragment ItemSKUConnection on ItemSKUConnection {
    edges {
      ...ItemSKUEdge
    }
    pageInfo {
      ...ItemSKUPageInfo
    }
  }
`;
/**
 * type GetAllItemSKUsResponse {
page: ItemSKUConnection!
pageData: PageData
}
 */
export const getAllItemSKUsResponseFragment = gql`
  ${itemSKUConnectionFragment}
  ${pageDataFragment}
  fragment GetAllItemSKUsResponse on GetAllItemSKUsResponse {
    page {
      ...ItemSKUConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * query {
  getAllItemSKUs {
    
  }
}
 */
export const getAllItemSKUsQuery = gql`
  ${getAllItemSKUsResponseFragment}
  query GetAllItemSKUs(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $queryorder: QueryOrder
    $search: String
  ) {
    getAllItemSKUs(
      before: $before
      after: $after
      first: $first
      last: $last
      queryorder: $queryorder
      search: $search
    ) {
      ...GetAllItemSKUsResponse
    }
  }
`;
export const getAllItemSKUsIDQuery = gql`
  query GetAllItemSKUIds(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $search: String
  ) {
    getAllItemSKUs(
      before: $before
      after: $after
      first: $first
      last: $last
      search: $search
    ) {
      page {
        edges {
          node {
            _id
          }
        }
      }
      __typename
    }
  }
`;
/**
 getSpecificItemSKU(
id: String!
): ItemSKU!
 */
export const getSpecificItemSKUQuery = gql`
  ${itemSkuFragment}
  query GetSpecificItemSKUs($id: String!) {
    getSpecificItemSKU(id: $id) {
      ...ItemSKU
    }
  }
`;

export const useGetAllItemSKUsQuery = (variables: GetAllItemSKUsVariables) =>
  useQuery<GetAllItemSKUs>(getAllItemSKUsQuery, {
    variables,
    client: clientSimulator,
  });

export const useLazyGetAllItemSKUsQuery = (
  variables: GetAllItemSKUsVariables
) => {
  const notify = useNotifier();
  return useLazyQuery<GetAllItemSKUs>(getAllItemSKUsQuery, {
    variables,
    client: clientSimulator,
    onError: (error) => {
      handleGQLErrors(notify, error);
    },
  });
};

export const useGetSpecificItemSKUQuery = (
  variables: GetSpecificItemSKUsVariables
) =>
  useQuery<GetSpecificItemSKUs, GetSpecificItemSKUsVariables>(
    getSpecificItemSKUQuery,
    {
      variables,
      client: clientSimulator,
    }
  );

export const useLazyGetSpecificItemSKUQuery = (
  variables: GetSpecificItemSKUsVariables
) =>
  useLazyQuery<GetSpecificItemSKUs, GetSpecificItemSKUsVariables>(
    getSpecificItemSKUQuery,
    {
      variables,
      client: clientSimulator,
    }
  );
