import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import TigStack from "./Apps";

interface IProps {}
export const routes = {
  path: "/admin/apps",
  element: <DefaultOutlet />,
  children: [{ index: true, element: <TigStack /> }],
};
const AppsRoutes: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default AppsRoutes;
