import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import clientSimulator from "utils/clientSimulator";
import {
    GetItembyOemItemId,
    GetItembyOemItemIdVariables
} from "./types/GetItembyOemItemId";
import { handleGQLErrors } from "utils/gqlErrors";


export const openTokeCodeHistoryFragment = gql`
fragment OpenTokenCodeHistory on OpenTokenCodeEvent {
        _id
        token
        token_type
        token_value
        max_count
	    code_history_type
        createdAt
        updatedAt
}
`

export const coderDecoderFragment = gql`
  fragment CodeDecoder on Item {
     _id
    openTokencodeGen {
        _id
        deleteStatus
        deleteAt
        createdAt
        updatedAt
        secret_key
        token
        token_type
        token_value
        max_count
        starting_code
    }
    openTokenCodeDecoder {
        _id
        deleteStatus
        deleteAt
        createdAt
        updatedAt
        secret_key
        token
        token_type
        token_value
        max_count
        used_count
        starting_code
    }
}
`;
export const expandedCoderDecoderFragment = gql`
${openTokeCodeHistoryFragment}
  fragment CodeDecoder on Item {
     _id
    openTokencodeGen {
        _id
        deleteStatus
        deleteAt
        createdAt
        updatedAt
        secret_key
        token
        token_type
        token_value
        max_count
        starting_code
        openTokenCodeHistory {
        ...OpenTokenCodeHistory
        }
    }
    openTokenCodeDecoder {
        _id
        deleteStatus
        deleteAt
        createdAt
        updatedAt
        secret_key
        token
        token_type
        token_value
        max_count
        used_count
        starting_code
        openTokenCodeHistory {
        ...OpenTokenCodeHistory
        }
    }
}
`;


export const getItembyOemItemIdQuery = gql`
  ${expandedCoderDecoderFragment}
  query GetItembyOemItemId($oemItemId: ID!) {
    getItembyOemItemId(oemItemId: $oemItemId) {
      ...CodeDecoder
    }
  }
`;

export const useLazyGetItembyOemItemIdQuery = (variables: GetItembyOemItemIdVariables) =>
    useLazyQuery<GetItembyOemItemId, GetItembyOemItemIdVariables>(
        getItembyOemItemIdQuery,
        {
            client: clientSimulator,
            variables,
            onError: (error) => {
                handleGQLErrors(() => { }, error);
            },
        }
    );



