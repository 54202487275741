import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { messageTemplateFields } from '../constants';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { UpdateMessageTemplateInput } from '../../types/accountsGlobalTypes';
import CustomSelect from '@components/CustomSelect/CustomSelect';

const MessageTemplateForm: React.FC<{
    formBag: FormikProps<UpdateMessageTemplateInput>;
    disableLoginButton: boolean;
    actionButtonName?: string;
}> = (props) => {
    const { formBag, disableLoginButton, actionButtonName = 'Add' } = props;

    const intentOptions = [
        {
            _id: "KEYCODE",
            name: "KEYCODE"
        },
        {
            _id: "PAYMENT",
            name: "PAYMENT"
        },
        {
            _id: "REFUND",
            name: "REFUND"
        },
        {
            _id: "TWOXDAYS",
            name: "TWOXDAYS"
        },
        {
            _id: "THREEXDAYS",
            name: "THREEXDAYS"
        },
        {
            _id: "TENPLUSDAYS",
            name: "TENPLUSDAYS"
        },
        {
            _id: "UNLOCK",
            name: "UNLOCK"
        },
        {
            _id: "RESET",
            name: "RESET"
        }
    ]

    const courierOptions = [
        {
            _id: "TELERIVET",
            name: "TELERIVET"
        },
        {
            _id: "LIPAY",
            name: "LIPAY"
        }
    ]
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <GoBackHeader title={actionButtonName} />
                <Form>
                    <GridContainer>
                        <GridHalfWrapper>
                            <TextInput
                                label='Name'
                                name={messageTemplateFields.name}
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Description'
                                name={messageTemplateFields.description}
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <CustomSelect
                                selectOptions={intentOptions}
                                onChange={(e: any) => {
                                    formBag.setFieldValue(messageTemplateFields.intent, e.target.value);
                                }}
                                label={'Select Intent'}
                                value={formBag.values.intent || ''}
                                name={messageTemplateFields.intent}
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <CustomSelect
                                selectOptions={courierOptions}
                                onChange={(e: any) => {
                                    formBag.setFieldValue(messageTemplateFields.messageCourier, e.target.value);
                                }}
                                label={'Message Courier'}
                                value={formBag.values.messageCourier || ''}
                                name={messageTemplateFields.messageCourier}
                            />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                            <TextInput
                                label='Message Body'
                                name={messageTemplateFields.messageBody}
                                multiline
                            />
                        </GridHalfWrapper>
                    </GridContainer>

                    <Grid>
                        <Button
                            color='primary'
                            onClick={() => {
                                formBag.submitForm();
                            }}
                            disabled={disableLoginButton}
                        >
                            Save
                        </Button>
                    </Grid>
                </Form>
            </GridItem>
        </GridContainer>
    );
};

export default MessageTemplateForm;
