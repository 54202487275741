import React from 'react';
import GridItem from './GridItem';

interface IProps {
  children: React.ReactNode;
  md?: number
}

const GridHalfWrapper: React.FC<IProps> = ({ children, md=6 }) => {
  return (
    <GridItem xs={12} sm={6} md={md}>
      {children}
    </GridItem>
  );
};

export default GridHalfWrapper;
