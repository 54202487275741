import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import clientAccount from '../../utils/clientAccount';
import { activateAssetAccountMutation } from '../mutations';
import {
  ActivateAssetAccount,
  ActivateAssetAccountVariables,
} from '../types/ActivateAssetAccount';

const useActivateAssetAccount = ({
  onComplete,
}: {
  onComplete?: () => void;
}) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    ActivateAssetAccount,
    ActivateAssetAccountVariables
  >(activateAssetAccountMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      } else {
        notify({ text: 'Accounted activated successfully', status: 'success' });
      }
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useActivateAssetAccount;
