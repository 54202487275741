import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import clientAccount from '../../utils/clientAccount';
import { debitCreditAccountMutation } from '../mutations';

import {
  DebitCreditAccount,
  DebitCreditAccountVariables,
} from '../types/DebitCreditAccount';

const useDebitAccount = ({
  onComplete,
}: {
  onComplete?: (balance: number) => void;
}) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    DebitCreditAccount,
    DebitCreditAccountVariables
  >(debitCreditAccountMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete(data?.debitCreditAccount?.credit?.balance || 0);
      } else {
        notify({ text: 'Accounted debited successfully', status: 'success' });
      }
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useDebitAccount;
