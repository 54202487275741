import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useMutation } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import useNotifier from 'hooks/useNotifier';
import { useNavigate } from 'react-router-dom';
import {
  DeleteItem,
  DeleteItemVariables,
} from 'apps/things/app/item/types/DeleteItem';
import { deleteItemMutation } from 'apps/things/app/item/mutations';
import { codeGeneratorRoutes } from '../constants';
import { CodeGeneratorContext } from '../context/CodeGeneratorProvider';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const SkuTableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();
  const { refetchItems: refetch } = useContext(CodeGeneratorContext)
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [deleteItemSKU] = useMutation<DeleteItem, DeleteItemVariables>(
    deleteItemMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        refetch && refetch();
        setIsModalOpen(false);
      },
    }
  );
  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id='expander'
        onClick={() => {
          history(codeGeneratorRoutes.getEditLink(row.values['node._id']));
        }}
        title='Simulate item'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <HoverDropdown
        isDistributor={true}
        menus={[
          {
            name: "",
            action: () => {
            },
          },
        ]}
      />
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deleteItemSKU({ variables: { itemId: row.values['node._id'] } })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this item?</div>
      </ActionModal>
    </div>
  );
};

export default SkuTableActions;
