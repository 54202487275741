import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { Row } from "react-table";
import ActionModal from "@components/Modal/ActionModal";
import { useNavigate } from "react-router-dom";
import { legacyBatchRoutes } from "../constants";
import { LegacyBatchContext } from "../context/LegacyBatchContext";
import { LegacyBatchActions } from "../redux/actions";
import useNotifier from "hooks/useNotifier";
import { LegacyItemContext } from "apps/migration/app/LegacyItem/context/LegacyItemContext";
import { SocketContext } from "apps/migration/app/Sockets/context/SocketContext";
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import GridContainer from "@components/Grid/GridContainer";
import GridItem from "@components/Grid/GridItem";
import { tryParseJSONObject } from "utils";
import { BATCH_CODES, productQIDOptions } from "../../utils";
import OVSForm from "@components/Form/OVSForm";
import { LegacyDistributorContext } from "../../LegacyDistributor/context/LegacyDistributorContext";
import { Formik } from "formik";
import { LegacyActions } from "../../LegacyDistributor/redux/actions";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import Button from "@components/CustomButtons/Button";

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const LegacyBatchTableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const history = useNavigate();
  const notify = useNotifier();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [tokenizeModalOpen, setTokenizeModalOpen] = React.useState(false);
  const [migrateModalOpen, setMigrateModalOpen] = useState(false);
  const [initializeLoading, setInitializeLoading] = useState(false);
  const [batchCoding, setBatchCoding] = useState(false);
  const [provisionModalOpen, setProvisionModalOpen] = useState(false);
  const [batchCodeModalOpen, setBatchCodeModalOpen] = useState(false);
  const [batchAssignModalOpen, setBatchAssignModalOpen] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [distributorID, setDistributorId] = useState("");
  const getNotes = () => {
    try {
      return tryParseJSONObject(row.values["productBatchNotes"] || "{}")
        ? JSON.parse(row.values["productBatchNotes"])?.angazaQID
        : "";
    } catch (error) {
      return "";
    }
  };
  const [angazaQID, setQID] = useState(getNotes());

  const [product_qid, setProductQID] = useState(
    // @ts-ignore
    angazaQID || ""
  );
  const [disableQIDInput, setInputDisabled] = useState(
    // @ts-ignore
    !!angazaQID || false
  );

  useEffect(() => {
    try {
      if (tryParseJSONObject(row.values["productBatchNotes"])) {
        const qid = JSON.parse(row.values["productBatchNotes"])?.angazaQID;
        setQID(qid);
        setInputDisabled(!!qid);
        setProductQID(qid);
      } else {
        setQID("");
        setInputDisabled(false);
        setProductQID("");
      }
    } catch (error) {
      setQID("");
      setInputDisabled(false);
      setProductQID("");
    }
    // eslint-disable-next-line
  }, [tryParseJSONObject(row.values["productBatchNotes"])]);
  const [provisionLoading, setProvisionLoading] = useState(false);
  const { items, getItems: getDistributors } = useContext(
    LegacyDistributorContext
  );
  const { getItems } = useContext(LegacyBatchContext);
  const { getItems: refetchProductItems } = useContext(LegacyItemContext);
  const { socket } = useContext(SocketContext);
  useEffect(() => {
    if (items.length === 0) {
      getDistributors && getDistributors();
    } // eslint-disable-next-line
  }, []);
  const deleteItem = () => {
    LegacyBatchActions.deleteItem(row.values["productBatchID"])
      .then((res) => {
        getItems();
        setIsModalOpen(false);
        notify({
          text: "Deleted successfully",
          status: "success",
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.error;
        notify({
          text: message || JSON.stringify(err),
          status: "error",
        });
        setTimeout(() => {
          setIsModalOpen(false);
        }, 2000);
      });
  };

  const batchAssign = () => {
    if (!distributorID) {
      return notify({
        text: "Please select distributor",
        status: "error",
      });
    }
    socket?.emit(
      "room",
      `${BATCH_CODES.assign}-${row.values["productBatchID"]}`
    );

    socket?.on("message", function (data) {
      console.log("Incoming message:", data);
    });

    setAssignLoading(true);
    LegacyBatchActions.batchAssign({
      productBatchNumber: row.values["productBatchNumber"],
      productBatchID: row.values["productBatchID"],
      distributorID,
    })
      .then((res) => {
        setBatchAssignModalOpen(false);
        setAssignLoading(false);
        refetchProductItems();
        notify({
          text: "Batch assign in progress.",
          status: "success",
        });
      })
      .catch((error) => {
        setBatchAssignModalOpen(false);
        setAssignLoading(false);
        refetchProductItems();
      });
  };

  const initializeProductByBatch = () => {
    socket?.emit(
      "room",
      `${BATCH_CODES.initialization}-${row.values["productBatchID"]}`
    );

    socket?.on("message", function (data) {
      console.log("Incoming message:", data);
    });

    setInitializeLoading(true);
    LegacyBatchActions.initializeBatchItems({
      productBatchNumber: row.values["productBatchNumber"],
      productBatchID: row.values["productBatchID"],
    })
      .then((res) => {
        setInitializeLoading(false);
        setTokenizeModalOpen(false);
        refetchProductItems();
        notify({
          text: "Successfully initialized batch product items",
          status: "success",
        });
      })
      .catch((error) => {
        setInitializeLoading(false);
        refetchProductItems();
      });
  };

  const updateOtpState = () => {
    LegacyBatchActions.updateOtpState(row.values["productBatchID"])
      .then((res) => {
        setInitializeLoading(false);
        setTokenizeModalOpen(false);
        notify({
          text: "Successfully initialized batch product items",
          status: "success",
        });
      })
      .catch((error) => {
        setInitializeLoading(false);
      });
  };

  const updateOtpStateFrom = () => {
    LegacyBatchActions.updateOtpStateFrom(row.values["productBatchID"])
      .then((res) => {
        setInitializeLoading(false);
        setTokenizeModalOpen(false);
        notify({
          text: "Successfully initialized batch product items",
          status: "success",
        });
      })
      .catch((error) => {
        console.log("error :>> ", error);
        setInitializeLoading(false);
      });
  };

  const provisionProductBatch = () => {
    if (!product_qid) {
      return notify({ status: "error", text: "Product QID is required" });
    }

    socket?.emit(
      "room",
      `${BATCH_CODES.provision}-${row.values["productBatchID"]}`
    );
    setProvisionLoading(true);
    LegacyBatchActions.provisionBatchRequest({
      product_qid,
      productBatchID: row.values["productBatchID"],
      productBatchNumber: row.values["productBatchNumber"],
    })
      .then((res) => {
        setProvisionLoading(false);
        setProvisionModalOpen(false);
        refetchProductItems();
        notify({
          text: `ProductItem with batch id ${row.values["productBatchID"]} are being provisioned. Please go to jobs page to track provision progress.`,
          status: "info",
        });
      })
      .catch((error) => {
        setProvisionLoading(false);
        refetchProductItems();
      });
  };

  const handleMigrateItemsInBatch = () => {
    setInitializeLoading(true);
    LegacyBatchActions.migrateItemsInBatch({
      productBatchID: row.values["productBatchID"],
      productBatchNumber: row.values["productBatchNumber"],
    })
      .then((res) => {
        notify({
          text: "Product Items migrated successfully",
          status: "success",
        });
        setInitializeLoading(false);
        setMigrateModalOpen(false);
      })
      .catch((error) => {
        console.warn(error?.response?.data);
        notify({
          text: "A problem occurred when migrating items",
          status: "error",
        });
        setInitializeLoading(false);
        setMigrateModalOpen(false);
      });
  };

  const searchProductQID = async (search: string) => {
    const res = productQIDOptions?.filter((it) => {
      return it?.label?.toLocaleLowerCase().includes(search);
    });
    return res;
  };

  const formFieldsData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "select-async",
            name: "distributor",
            label: "Distributor",
            options: items.map((it) => ({
              _id: it.distributorID,
              name: it.contactEmail,
            })),
            onChange: (e: any) => {
              setDistributorId(e);
            },
            value: distributorID || "",
            searchPromise: async (search: string) => {
              const _res = await LegacyActions.getDistributorsLegacy(
                `search=${search}`
              ).then((res) => {
                return res;
              });
              const _data = _res?.data?.results?.map((it: any) => ({
                value: it.distributorID,
                label: it.contactEmail,
              }));
              return _data;
            },
          },
        ],
      },
    ],
    [items, distributorID]
  );

  return (
    <div className="actions-right" style={{ display: "flex" }}>
      <Tooltip
        id="expander"
        onClick={() => {
          history(legacyBatchRoutes.getEditLink(row.values["productBatchID"]));
        }}
        title="Edit "
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id="expander"
          aria-label="Edit"
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + " " + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Delete "
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id="expander"
          aria-label="Delete"
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + " " + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <HoverDropdown
        menus={[
          {
            name: "Initialize Batch",
            action: () => {
              setTokenizeModalOpen(true);
            },
          },
          {
            name: "Migrate Items",
            action: () => {
              setMigrateModalOpen(true);
            },
          },
          {
            name: disableQIDInput ? "Angaza Re-provision" : "Angaza Provision",
            action: () => {
              setProvisionModalOpen(true);
            },
          },
          {
            name: "Download Products",
            action: () => {
              notify({
                status: "success",
                text: "CSV file downloading...",
              });
              LegacyBatchActions.downloadProductItemsCSV(
                row.values["productBatchID"]
              )
                .then((response) => {
                  const downloadUrl = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = downloadUrl;
                  link.setAttribute(
                    "download",
                    `${row.values["productBatchNumber"]}-batch-productItems.csv`
                  );
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                })
                .catch((error) => {
                  console.warn(error);
                });
            },
          },
          {
            name: "Batch Code",
            action: () => {
              setBatchCodeModalOpen(true);
            },
          },
          {
            name: "Batch Assign",
            action: () => {
              setBatchAssignModalOpen(true);
            },
          },
          {
            name: "Download factory products",
            action: () => {
              notify({
                status: "success",
                text: "CSV file downloading...",
              });
              LegacyBatchActions.downloadProductsForFactoryCSV(
                row.values["productBatchID"]
              )
                .then((response) => {
                  const downloadUrl = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = downloadUrl;
                  link.setAttribute(
                    "download",
                    `${row.values["productBatchNumber"]}-batch-factory.csv`
                  );
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                })
                .catch((error) => {
                  console.warn(error);
                });
            },
          },
          {
            name: "Update codegen state",
            action: () => {
              updateOtpState();
            },
          },
          {
            name: "Update codegen from",
            action: () => {
              updateOtpStateFrom();
            },
          },
          {
            name: "Batch Free code",
            action: () => {
              LegacyBatchActions.batchFreeCode({
                productBatchNumber: row.values["productBatchNumber"],
                productBatchID: row.values["productBatchID"],
              });
            },
          },
        ]}
      />

      {/* assign batch */}
      <DrawerSidebar
        isModalOpen={batchAssignModalOpen}
        toggleModal={setBatchAssignModalOpen}
        title="Assign Batch"
        styles={{ maxWidth: "30vw", minWidth: "400px" }}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h6>
              This will assign the products in batchNumber{" "}
              {row.values["productBatchNumber"]} to a distributor
            </h6>
          </GridItem>
          <Formik onSubmit={(e) => {}} initialValues={{}}>
            {(formBag) => (
              <>
                <OVSForm formFieldsData={formFieldsData} />
                <Grid sm={12}>
                  <Button
                    color="primary"
                    onClick={() => {
                      batchAssign();
                    }}
                    style={{ marginLeft: 12 }}
                    disabled={assignLoading}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Formik>
          {/* </GridItem> */}
        </GridContainer>
      </DrawerSidebar>

      {/* migrate modal */}
      <ActionModal
        isModalOpen={migrateModalOpen}
        toggleModal={setMigrateModalOpen}
        handleOnClickOkey={handleMigrateItemsInBatch}
        handleOnClickCancel={() => setMigrateModalOpen(false)}
        loading={initializeLoading}
        okText="Migrate"
      >
        <div>Are you sure you want to migrate product items in this batch?</div>
      </ActionModal>
      {/* end migrate modal */}
      <ActionModal
        isModalOpen={tokenizeModalOpen}
        toggleModal={setTokenizeModalOpen}
        handleOnClickOkey={initializeProductByBatch}
        handleOnClickCancel={() => setTokenizeModalOpen(false)}
        loading={initializeLoading}
        okText="Ok"
      >
        <div>
          Are you sure you want to initialize otpGenerator for the products in
          this batch?
        </div>
      </ActionModal>
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={deleteItem}
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ActionModal>

      {/* provision */}
      <DrawerSidebar
        isModalOpen={provisionModalOpen}
        toggleModal={setProvisionModalOpen}
        title={`Provision to ${product_qid}`}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h6>
              This will provision the products <br /> in batchNumber{" "}
              {row.values["productBatchNumber"]}{" "}
            </h6>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <OVSForm
              formFieldsData={[
                {
                  name: "",
                  fields: [
                    {
                      md: 12,
                      disabled: disableQIDInput,
                      type: "select-async",
                      name: "product_qid",
                      label: "Product QID",
                      options: productQIDOptions.map((it) => ({
                        _id: it.value,
                        name: it.label,
                      })),
                      onChange: (e: any) => {
                        setProductQID(e);
                      },
                      value: product_qid || "",
                      searchPromise: searchProductQID,
                    },
                  ],
                },
              ]}
            />
            <Grid>
              <Button
                color="primary"
                onClick={() => {
                  provisionProductBatch();
                }}
                disabled={provisionLoading}
                style={{ marginLeft: 12 }}
              >
                Save
              </Button>
            </Grid>
          </GridItem>
        </GridContainer>
      </DrawerSidebar>

      {/* batch code  */}
      <ActionModal
        isModalOpen={batchCodeModalOpen}
        toggleModal={setBatchCodeModalOpen}
        handleOnClickOkey={() => {
          setBatchCodeModalOpen(false);
          setBatchCoding(true);
          LegacyBatchActions.batchCode({
            productBatchNumber: row.values["productBatchNumber"],
            productBatchID: row.values["productBatchID"],
          })
            .then((res) => {
              setBatchCodeModalOpen(false);
              setBatchCoding(false);
              refetchProductItems();
              notify({
                text: "Successfully initialized batch code generation.",
                status: "success",
              });
            })
            .catch((error) => {
              setBatchCodeModalOpen(false);
              setBatchCoding(false);
              refetchProductItems();
            });
        }}
        handleOnClickCancel={() => setTokenizeModalOpen(false)}
        loading={batchCoding}
        okText="Ok"
      >
        <div>
          Are you sure you want to initialize one day code for the productItems
          in this batch?
        </div>
      </ActionModal>
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={deleteItem}
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText="Delete"
      >
        <div>Are you sure you want to delete?</div>
      </ActionModal>
    </div>
  );
};

export default LegacyBatchTableActions;
