import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@components/CustomButtons/Button';
import CodeEventContainer from 'apps/things/app/codeEvent/containers/CodeEventContainer';
import DefaultModal from '@components/Modal/DefaultModal';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  th: { width: 180, fontWeight: 'bold' },
  btn: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
    paddingRight: 2,
  },
  thWithButton: { paddingTop: 7, paddingBottom: 7 },
});

const placeholder = '---';

interface IProps {
  hashTop?: string;
  hashRoot?: string;
  hashChainLength?: number;
  maxHashJump: number;
  oemItemID: string;
  itemID: string;
  daysPassed: number;
  payGoState: string;
  deviceState: string;
  daysRemaining: number;
  daysPassedPlusOne: () => void;
  loading: boolean;
}

const DeviceDetailsPreview: React.FC<IProps> = ({
  hashTop = placeholder,
  hashRoot = placeholder,
  maxHashJump = placeholder,
  oemItemID = placeholder,
  itemID = placeholder,
  daysPassed,
  daysRemaining,
  payGoState,
  deviceState,
  daysPassedPlusOne,
  loading,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableBody>
          <TableRow>
            <TableCell component='th' scope='row' className={classes.th}>
              Days Passed
            </TableCell>
            <TableCell className={classes.thWithButton}>
              {daysPassed}&nbsp;&nbsp;&nbsp;&nbsp;
              <Button size='sm' onClick={daysPassedPlusOne} disabled={loading || daysRemaining===0}>
                Day + 1
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Days Remaining
            </TableCell>
            <TableCell component='td'>
              {loading ? 'loading...' : daysRemaining > 1095 ? 0: daysRemaining}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              PAYG State
            </TableCell>
            <TableCell>{payGoState}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Device State
            </TableCell>
            <TableCell>{deviceState}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              OTP (64bit Hex)
            </TableCell>
            <TableCell>{hashTop}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Hash Root
            </TableCell>
            <TableCell>{hashRoot}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Maximum HCJ (MAXN)
            </TableCell>
            <TableCell>{maxHashJump}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              OEM Product ID
            </TableCell>
            <TableCell>{oemItemID}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Product ID
            </TableCell>
            <TableCell>{itemID}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <DefaultModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() => {}}
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <CodeEventContainer />
      </DefaultModal>
    </TableContainer>
  );
};

export default DeviceDetailsPreview;
