export const legacyRoutes = {
  list: '/admin/legacy/category',
  add: '/admin/legacy/category/add',
  edit: '/admin/legacy/category/:itemId',
  getEditLink: (itemId: string) => `/admin/legacy/category/${itemId}`,
};

export const itemFields = {
  productCategoryID: 'productCategoryID',
  productCategoryName: 'productCategoryName',
  productCategoryDescription: 'productCategoryDescription',
  image: 'image',
};
