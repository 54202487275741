import Button from '@components/CustomButtons/Button';
import TextInput from '@components/CustomInput/TextInput';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { creditAccountValidationSchema } from 'apps/things/app/validations';
import { Formik } from 'formik';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect, useState } from 'react';
import useActivateAssetAccount from '../hooks/useActivateCreditccount';
import { accountStatuses } from '../constants';
import useDebitAccount from '../hooks/useDebitAccount';
import { AccountActions } from '../../types/accountsGlobalTypes';
import { InputLabel } from '@material-ui/core';
import Datetime from 'react-datetime';
import ActionModal from "@components/Modal/ActionModal";


interface IProps {
  assetAccountId: string;
  onComplete?: (balance: number) => void;
  status: string;
  openCodeGenModal: () => void;
  onGenerateCodeButton: () => void
}

const DebitAccountForm: React.FC<IProps> = ({
  assetAccountId,
  onComplete = (balance: number) => { },
  status,
  openCodeGenModal = () => { },
  onGenerateCodeButton
}) => {
  const notify = useNotifier();
  const { mutation, mutationOpts } = useDebitAccount({
    onComplete: (balance) => {
      notify({ text: 'Accounted credited successfully', status: 'success' });
      onComplete && onComplete(balance);
    },
  });
  const [date, setDate] = useState(new Date());
  const [isConfirmModal, setConfirmModal] = React.useState(false)
  const [isSaveButtonOpen, setSaveButtonOpen] = React.useState(true)
  const [isGenerateCodeButtonOpen, setGenerateCodeButtonOpen] = React.useState(false)
  const {
    mutation: activateMutation,
    mutationOpts: activateMutationOpts,
  } = useActivateAssetAccount({
    onComplete: () => {
      notify({ text: 'Accounted activated successfully', status: 'success' });
    },
  });

  useEffect(() => {
    if (status === accountStatuses.inactive) {
      activateMutation({
        variables: {
          assetAccountId,
        },
      });
    } // eslint-disable-next-line
  }, [status]);

  return (
    <>
      <Formik
        onSubmit={async (values: any, { resetForm }) => {
          await mutation({
            variables: {
              assetAccountId,
              paymentInput: {
                amount: values.amount || 0,
                datetime: values.datetime,
                notes: values.notes,
                action: values.action,
              },
            },
          });
          resetForm();
          setGenerateCodeButtonOpen(true)
          setSaveButtonOpen(false)
        }}
        initialValues={{
          amount: '',
          datetime: new Date(),
          notes: '',
          action: AccountActions.DEBIT,
        }}
        validateOnChange={true}
        validationSchema={creditAccountValidationSchema}
      >
        {(formBag) => (
          <GridContainer>
            <GridItem sm={12}>
              <TextInput name='amount' type='number' label='Amount' />
              <InputLabel htmlFor='simple-select' id='label-left'>
                Date
              </InputLabel>
              <Datetime
                timeFormat={false}
                value={date}
                onChange={(moment) => {
                  if (typeof moment === 'object') {
                    // @ts-ignore
                    setDate(moment);
                    formBag.setFieldValue('datetime', moment);
                  }
                }}
                inputProps={{ placeholder: 'Date Picker Here' }}
              />

              <TextInput name='notes' type='number' label='Notes' multiline />
            </GridItem>
            <GridItem sm={4}>
              <Button
                color='primary'
                onClick={() => {
                  setConfirmModal(true)
                }}
                disabled={mutationOpts.loading || activateMutationOpts.loading || !isSaveButtonOpen}
              >
                Save
              </Button>
            </GridItem>
            <GridItem sm={8}>
              <Button color='primary' onClick={() => { }} disabled={true}>
                Apply Credit For Code
              </Button>
            </GridItem>
            <GridItem sm={4}>
              <Button
                color='primary'
                onClick={() => {
                  onGenerateCodeButton()
                  openCodeGenModal && openCodeGenModal();
                  

                }}
                disabled={mutationOpts.loading || activateMutationOpts.loading || !isGenerateCodeButtonOpen}
              >
                Generate code
              </Button>
            </GridItem>
            <ActionModal
              isModalOpen={isConfirmModal}
              toggleModal={setConfirmModal}
              handleOnClickOkey={() => {
                formBag.submitForm();
                formBag.validateForm();
                setConfirmModal(false);
              }}
              handleOnClickCancel={() => setConfirmModal(false)}
              okText={"Confirm"}
            >
              <div>
                Are you sure you want to Debit the account
              </div>
            </ActionModal>
          </GridContainer>
        )}
      </Formik>

    </>
  );
};

export default DebitAccountForm;
