import Button from '@components/CustomButtons/Button';
import TextInput from '@components/CustomInput/TextInput';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { creditAccountValidationSchema } from 'apps/things/app/validations';
import { Formik } from 'formik';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect, useState } from 'react';
import useCreditAccount from '../hooks/useCreditAccount';
import Datetime from 'react-datetime';
import { InputLabel } from '@material-ui/core';
import useActivateAssetAccount from '../hooks/useActivateCreditccount';
import { accountStatuses } from '../constants';

interface IProps {
  customerName: string;
  assetAccountId: string;
  onComplete?: (balance: number) => void;
  status: string;
}

const CreditAccountForm: React.FC<IProps> = ({
  assetAccountId,
  onComplete = (balance: number) => {},
  status,
}) => {
  const notify = useNotifier();
  const [date, setDate] = useState(new Date());
  const { mutation, mutationOpts } = useCreditAccount({
    onComplete: (balance) => {
      notify({ text: 'Accounted credited successfully', status: 'success' });
      onComplete && onComplete(balance);
    },
  });

  const {
    mutation: activateMutation,
    mutationOpts: activateMutationOpts,
  } = useActivateAssetAccount({
    onComplete: () => {
      notify({ text: 'Accounted activated successfully', status: 'success' });
    },
  });
  useEffect(() => {
    if (status === accountStatuses.inactive) {
      activateMutation({
        variables: {
          assetAccountId,
        },
      });
    } // eslint-disable-next-line
  }, [status]);

  return (
    <Formik
      onSubmit={async (values: any, { resetForm }) => {
        await mutation({
          variables: {
            assetAccountId,
            paymentInput: {
              amount: values.amount || 0,
              datetime: values.datetime,
              notes: values.notes,
              action: values.action,
            },
          },
        });
        resetForm();
      }}
      initialValues={{
        amount: '',
        datetime: new Date(),
        notes: '',
        action: 'CREDIT',
      }}
      validateOnChange={true}
      validationSchema={creditAccountValidationSchema}
    >
      {(formBag) => (
        <GridContainer>
          <GridItem sm={12}>
            <TextInput name='amount' type='number' label='Amount' />
            <InputLabel htmlFor='simple-select' id='label-left'>
              Date
            </InputLabel>
            <Datetime
              timeFormat={false}
              value={date}
              onChange={(moment) => {
                if (typeof moment === 'object') {
                  // @ts-ignore
                  setDate(moment);
                  formBag.setFieldValue('datetime', moment);
                }
              }}
              inputProps={{ placeholder: 'Date Picker Here' }}
            />

            <TextInput name='notes' type='number' label='Notes' multiline />
          </GridItem>
          <GridItem sm={12}>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
                formBag.validateForm();
              }}
              disabled={mutationOpts.loading || activateMutationOpts.loading}
            >
              Save
            </Button>
          </GridItem>
        </GridContainer>
      )}
    </Formik>
  );
};

export default CreditAccountForm;
