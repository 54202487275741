import React from 'react';
import moment from 'moment';
// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import { itemFields } from '../constants';
import { CreateItemInput } from 'apps/things/app/types/thingsGlobalTypes';
import { addItemValidationSchema } from 'apps/things/app/validations';
import ItemForm from './ItemForm';
import CardWrapper from '@components/Card/CardWrapper';
import { defaultProductType, getDefaultDate } from 'apps/things/app/utils';

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: CreateItemInput, totalItems: number) => void;
  isLoading: boolean;
}

const AddItem: React.FC<IRTable> = ({ handleSubmit, isLoading }) => {
  const { addItem } = itemFields;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any, { resetForm }) => {
              handleSubmit(
                {
                  idString: values.idString,
                  oemItemID: values.oemItemID,
                  oemID: values.oemID,
                  itembatchId: values.itembatchId,
                  itemfirmwareId: values.itemfirmwareId,
                  codegeneratorId: values.codegeneratorId,
                  itemStockId: values.itemStockId,
                  itemFleetId: values.itemFleetId,
                },
                values.totalItems
              )
              resetForm()
            }
            }
            initialValues={{
              [addItem.idString]: '',
              [addItem.creationDate]: moment().format(),
              [addItem.oemItemID]: `${defaultProductType}${getDefaultDate()}${'xxxxxx'}`,
              [addItem.oemID]: 'OVES',
              [addItem.itembatchId]: '',
              [addItem.itemfirmwareId]: '',
              [addItem.codegeneratorId]: '',
              [addItem.totalItems]: '',
            }}
            validationSchema={addItemValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemForm
                formBag={(formBag as unknown) as FormikProps<CreateItemInput>}
                disableLoginButton={isLoading}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItem;
