import React, { useContext, useEffect, useState } from "react";

// @material-ui/core components
// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";

import CardWrapper from "@components/Card/CardWrapper";
import { LegacyJobContext } from "../context/LegacyJobContext";
import { legacyTableData } from "../constants/data";
import SocketProvider from "../../Sockets/context/SocketContext";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Snackbar } from "@material-ui/core";
interface IRTable {
  bulkDeleteProducts?: () => void;
  isLoading: boolean;
}
let timer: any;
const LegacyPage: React.FC<IRTable> = () => {
  const {
    items,
    getItems,
    goTo,
    paging,
    setSearchQuery,
    loading,
    setOffset,
    searchQuery,
  } = useContext(LegacyJobContext);
  const [data] = useState(
    {} as {
      daysRemaining: string;
      hoursRemaining: string;
      migratedItems: string;
      remainingItems: string;
    }
  );
  const [search, setSearch] = useState(
    "migrateAssignedItemCodeHistoryOffsetnine9"
  );

  const resetSearch = () => {
    if (timer) {
      clearInterval(timer);
    }
    timer = setInterval(() => {
      getItems();
    }, 20000);
  };
  const _search = (q: string) => {
    setSearchQuery(q);
    if (timer) {
      clearInterval(timer);
    }
  };

  React.useEffect(() => {
    timer = setInterval(() => {
      getItems();
    }, 20000);
    return () => {
      clearInterval(timer);
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  return (
    <SocketProvider>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CardWrapper>
            {data?.daysRemaining ? (
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={true}
                autoHideDuration={6000}
                onClose={() => {}}
              >
                <Alert severity="success">
                  <AlertTitle>Code History Migration</AlertTitle>
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <button onClick={resetSearch}>reset</button>
                  <br />
                  Time estimation: {data?.daysRemaining} or{" "}
                  {data?.hoursRemaining}
                  <br />
                  Items {data?.migratedItems} of {data?.remainingItems}
                </Alert>
              </Snackbar>
            ) : null}
            <OvsTable
              columns={legacyTableData.header as Column[]}
              data={items}
              checkedIds={[]}
              isLoading={loading}
              addItemPageLink={"/"}
              goTo={goTo}
              paging={paging}
              refetchData={getItems}
              hideRightToolbar={true}
              setSearchQuery={_search}
              searchQuery={searchQuery}
              setOffset={setOffset}
            />
          </CardWrapper>
        </GridItem>
      </GridContainer>
    </SocketProvider>
  );
};
export default LegacyPage;
