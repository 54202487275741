const url = 'thing-broker';

export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
export const itemFields = {
  brokerId: 'brokerId',
  login: 'login',
  name: 'name',
  password: 'password',
  url: 'url',
};

export const defaultItem = {
  brokerId: '',
  login: '',
  name: 'Omnivoltaic Open MQTT',
  password: '',
  url: 'mqtt-2.omnivoltaic.com',
};
