import React from 'react';
import ResetPasswordTokenPage from '../components/ResetPassword/ResetPasswordTokenPage';

interface IProps {}

const ResetPasswordToken: React.FC<IProps> = () => {
  return <ResetPasswordTokenPage />;
};

export default ResetPasswordToken;
