import { ActionTypes, ActorTypes } from '../../types/eventsGlobalTypes';

const url = 'events-events';

export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
export const itemFields = {
  eventId: 'eventId',
  action: 'action',
  actionDescription: 'action.description',
  actionLabel: 'action.label',
  actionType: 'action.type',
  actor: 'actor',
  actorDescription: 'actor.description',
  actorName: 'actor.name',
  actorType: 'actor.type',
  location: 'location',
  latitude: 'location.latitude',
  longitude: 'location.longitude',
  placeName: 'location.placeName',
  signal: 'signal',
  signalTopic: 'signalTopic',
  time: 'time',
};

export const actionTypeOptions = [
  { _id: ActionTypes.CLIENT, name: ActionTypes.CLIENT },
  { _id: ActionTypes.DEVELOPMENT, name: ActionTypes.DEVELOPMENT },
  { _id: ActionTypes.GLOBAL, name: ActionTypes.GLOBAL },
  { _id: ActionTypes.MANAGEMENT, name: ActionTypes.MANAGEMENT },
  { _id: ActionTypes.SYSTEM, name: ActionTypes.SYSTEM },
];

export const actorTypeOptions = [
  { _id: ActorTypes.ACCOUNT, name: ActorTypes.ACCOUNT },
  { _id: ActorTypes.DEVICE, name: ActorTypes.DEVICE },
  { _id: ActorTypes.EVENT, name: ActorTypes.EVENT },
  { _id: ActorTypes.ORGANIZATION, name: ActorTypes.ORGANIZATION },
  { _id: ActorTypes.PERSON, name: ActorTypes.PERSON },
  { _id: ActorTypes.PLACE, name: ActorTypes.PLACE },
  { _id: ActorTypes.SOFTWARE, name: ActorTypes.SOFTWARE },
];
export const defaultItem = {
  eventId: '',
  action: {
    description: 'Action Generated by OVES IoT Device',
    label: 'eIOT',
    type: ActionTypes.CLIENT,
  },
  actor: {
    description: '',
    name: '',
    type: ActorTypes.ACCOUNT,
  },
  location: {
    latitude: 0,
    longitude: 0,
    placeName: '',
  },
  signal: true,
  signalTopic: '',
  time: new Date(),
};
