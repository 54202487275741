import React, { useContext, useEffect } from "react";

// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";

import { itemSKUTableData } from "../constants/itemSKUTableData";
import { ItemSKUContext } from "../context/SKUProvider";
import CardWrapper from "@components/Card/CardWrapper";
import { skuRoutes } from "../constants";
import { IFileInfo } from "react-csv-reader";

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  uploadCsv: (data: any[], fileInfo: IFileInfo) => any;
  progress: number;
  uploading: boolean;
}

const ItemSKUPage: React.FC<IRTable> = ({ uploadCsv }) => {
  const {
    itemSKUs,
    tableActions,
    loading,
    setSearchQuery,
    searchQuery,
    paging,
    getItemSKU,
    goTo,
    setPageLimit,
  } = useContext(ItemSKUContext);

  useEffect(() => {
    getItemSKU();
    // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={itemSKUTableData().header as Column[]}
            data={itemSKUs}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={skuRoutes.add}
            addItemBtnText="Add Item SKU"
            uploadCsv={uploadCsv}
            tableActions={tableActions}
            paging={paging}
            goTo={goTo}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemSKUPage;
