import TextInput from '@components/CustomInput/TextInput';
import AsyncSeachableSelect from '@components/CustomSelect/AsyncSeachableSelect';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import GridItem from '@components/Grid/GridItem';
import { FormControlLabel, InputLabel, Switch } from '@material-ui/core';
import React from 'react';
import Datetime from 'react-datetime';

export interface IFormFields {
  name: string;
  fields: {
    type: string;
    name: string;
    label: string;
    error?: string;
    md?: number;
    disabled?: boolean;
    dateFormat?: string;
    onChange?: (e: any) => void | undefined;
    value?: string | boolean;
    options?: { _id: string; name: string }[] | undefined;
    searchPromise?: (
      arg: string
    ) => Promise<{ value: string; label: string }[]>;
  }[];
}
interface IProps {
  formFieldsData: IFormFields[];
}

const defaultCallback = async (e: string) => {
  return new Promise<{ value: string; label: string }[]>((resolve, reject) => {
    resolve([]);
  });
};

const OVSForm: React.FC<IProps> = ({ formFieldsData }) => {
  return (
    <>
      {formFieldsData.map((section, i) => (
        <React.Fragment key={`section-${i}`}>
          <>
            {section.name ? (
              <GridItem md={12} sm={12}>
                <h5>{section.name}</h5>
              </GridItem>
            ) : null}
            {section.fields.map((field, idx) => {
              switch (field.type) {
                case 'text':
                  return (
                    <GridHalfWrapper md={field.md || 6} key={`field-text-${idx}`}>
                      <TextInput name={field.name}  label={field.label} disabled={field.disabled || false} />
                    </GridHalfWrapper>
                  );
                case 'number':
                  return (
                    <GridHalfWrapper  md={field.md || 6} key={`field-number-${idx}`}>
                      <TextInput
                        label={field.label}
                        name={field.name}
                        type='number'
                        onChange={field.onChange}
                      />
                    </GridHalfWrapper>
                  );
                case 'textarea':
                  return (
                    <GridHalfWrapper key={`field-textarea-${idx}`}>
                      <TextInput
                        label={field.label}
                        name={field.name}
                        multiline
                      />
                    </GridHalfWrapper>
                  );
                case 'select-async':
                  return (
                    <GridHalfWrapper
                      md={field.md || 6}
                      key={`field-textarea-${idx}`}
                    >
                      <AsyncSeachableSelect
                        onChange={(e: any) => {
                          if (field?.onChange) {
                            field?.onChange(e);
                          }
                        }}
                        isDisabled={field.disabled}
                        placeholder={field.label}
                        options={
                          field.options?.map((it) => ({
                            value: it._id,
                            label: it.name,
                          })) || []
                        }
                        searchPromise={
                          field.searchPromise || // @ts-ignore
                          (defaultCallback as (
                            arg: string
                          ) => PromiseLike<{ value: string; label: string }[]>)
                        }
                        initialValue={
                          field.value
                            ? {
                                value: '',
                                label: field.value,
                              }
                            : {}
                        }
                        error={field.error}
                      />
                    </GridHalfWrapper>
                  );
                case 'select':
                  return (
                    <GridHalfWrapper
                      md={field.md || 6}
                      key={`field-select-${idx}`}
                    >
                      <CustomSelect
                        selectOptions={field.options || []}
                        loading={false}
                        onChange={(e: any) => {
                          if (field?.onChange) {
                            field?.onChange(e);
                          }
                        }}
                        label={field.label}
                        value={field.value as string}
                        name={field.name}
                      />
                    </GridHalfWrapper>
                  );
                case 'date':
                  return (
                    <GridHalfWrapper
                      md={field.md || 6}
                      key={`field-date-${idx}`}
                    >
                      <InputLabel
                        htmlFor='simple-select'
                        // className={classes.selectLabel}
                        id='label-left'
                        style={{ marginBottom: 10, textTransform: 'uppercase' }}
                      >
                        {field.label || field.name}
                      </InputLabel>
                      <div style={{ marginBottom: 12 }}>
                        <>
                          <Datetime
                            timeFormat={false}
                            dateFormat={field.dateFormat || 'DD-MM-YY'}
                            value={(field.value as string) || ''}
                            onChange={(moment) => {
                              if (
                                typeof moment === 'object' &&
                                field.onChange
                              ) {
                                field.onChange(moment.format(field.dateFormat || 'DD-MM-YY'));
                              }
                            }}
                            inputProps={{ placeholder: '' }}
                          />
                          {field.error ? (
                            <div className='error-message'>{field.error}</div>
                          ) : null}
                        </>
                      </div>
                    </GridHalfWrapper>
                  );
                case 'switch':
                  return (
                    <GridHalfWrapper  md={field.md || 6} key={`field-switch-${idx}`}>
                      <FormControlLabel
                        label={field.label}
                        control={
                          <Switch
                            checked={(field.value as boolean) || false}
                            onChange={field.onChange}
                            name={field.name}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                      />
                    </GridHalfWrapper>
                  );
                default:
                  return null;
              }
            })}
          </>
        </React.Fragment>
      ))}
    </>
  );
};

export default OVSForm;
