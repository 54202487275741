import React from 'react';
import TigStackPage from '../components/TigStackPage';

const TigStack = (props: any) => {
  return (
      <TigStackPage />
  );
};

export default TigStack;
