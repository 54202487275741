import React from 'react';

interface IProps {
  value: string;
}

const StatusCell: React.FC<IProps> = ({ value }) => {
  if (value.toString() === '1') {
    return <div>Yes</div>;
  }
  return <div>Pending</div>;
};

export default StatusCell;
