import { useMutation } from '@apollo/client';
import CardGridLoader from '@components/Loaders/CardGridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { handleGQLErrors } from 'utils/gqlErrors';
import { UpdateSupplierInput } from '../../types/clientServiceGlobalTypes';
import clientClientService from '../../utils/clientClientService';
import AddItem from '../components/AddItem';
import { itemRoutes } from '../constants';
import { SupplierContext } from '../context/SupplierProvider';
import { updateSupplierMutation } from '../mutations';
import { useGetSpecificSupplierQuery } from '../queries';
import {
  UpdateSupplier,
  UpdateSupplierVariables,
} from '../types/UpdateSupplier';

const EditItemSKUContainer = () => {
  const notify = useNotifier();
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { data, loading } = useGetSpecificSupplierQuery({
    id: itemId,
  });
  const { refetchItems: refetch, getItems } = useContext(SupplierContext);
  const [item, setItem] = React.useState({} as UpdateSupplierInput);

  React.useEffect(() => {
    if (!data) return;
    const input = data?.getSpecificSupplier;
    const address = data?.getSpecificSupplier?.orgAddress;

    setItem({
      supplierId: data?.getSpecificSupplier?._id || '',
      supplierAddress: {
        city: address?.city || '',
        country: address?.country || '',
        postcode: address?.postcode || '',
        srpc: address?.srpc || '',
        street: address?.street || '',
        unit: address?.unit || '',
        UpdateLocationAddressDto: {
          lat: address?.addressLocation?.addressLatitude || 0,
          lng: address?.addressLocation?.addressLongitude || 0,
        },
      },

      supplierDescription: input?.description || '',
      supplierName: input?.name,
    }); // eslint-disable-next-line
  }, [data]);

  const [updateItem, updateItemOpts] = useMutation<
    UpdateSupplier,
    UpdateSupplierVariables
  >(updateSupplierMutation, {
    client: clientClientService,
    onCompleted: (data) => {
      notify({
        status: 'success',
        text: 'Updated successfully',
      });
      getItems();
      refetch && refetch();
      history(itemRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleUpdate = (updateSupplierInput: UpdateSupplierInput) => {
    updateItem({
      variables: { updateSupplierInput },
    });
  };

  if (!data?.getSpecificSupplier?._id || !item.supplierId)
    return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleUpdate}
      isLoading={updateItemOpts.loading || loading}
      item={item}
    />
  );
};

export default EditItemSKUContainer;
