import { FormControl, InputProps } from '@material-ui/core';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import CustomInput from './CustomInput';

interface IProps {
  label?: string;
  multiline?: boolean;
  rowsMax?: number;
  InputProps?: InputProps;
  endAdornment?: React.ReactNode
  error?: boolean;  
  helperText?: string
  size?: string
  onChange?: (e: any) => void | undefined
}

const TextInput:React.FC<IProps & FieldHookConfig<any>> = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <React.Fragment>
      <CustomInput
        labelText={label}
        formControlProps={{
          fullWidth: true,
        }}
        {...field}
        {...props}
      />
      <FormControl>
        {meta.touched && meta.error ? (
          <div className='error-message'>{meta.error}</div>
        ) : null}
      </FormControl>
    </React.Fragment>
  );
};

export default TextInput;
