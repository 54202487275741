import { routes as brokerRoutes } from "./broker"
import { routes as eventRoutes } from "./events"
import { routes as publisherRoutes } from "./publisher"
import { routes as signalRoutes } from "./publisher"
import {routes as itemEventRoutes} from "./itemEvents"


export const eventsRoutes = [
 brokerRoutes,
 eventRoutes,
 publisherRoutes,
 signalRoutes,
 itemEventRoutes
]