import { USER_PREF_STORAGE_KEY } from 'admin/auth/constants/auth';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { getFromStorage, setUserPref } from 'admin/auth/utils';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-table';
import _ from 'lodash'
import TableLoader from '@components/Loaders/TableLoader';
import ItemPage from 'apps/things/app/item/components/ItemPage';
interface IProps {
    row: Row
}

const DistributorItemsTable: React.FC<IProps> = ({ row }) => {
    const { userPref } = useAuth()
    const [user, setUser] = useState<{distributorId: string}>()
    useEffect(() => {
      setTimeout(() => {
        setUser(JSON.parse(_.get(getFromStorage(USER_PREF_STORAGE_KEY), 'key') || '{}'))        
      }, 100);
    }, [])
    
    useEffect(() => {
        if (row.values['node.distributor._id']) {
            setUserPref({ ...userPref, distributorId: row.values['node.distributor._id'] })
        } // eslint-disable-next-line
    }, [row])
    if (!user?.distributorId) {
        return <TableLoader colSpan={3}/>
    }
    return (<div>
        <ItemPage />
    </div>)
}

export default DistributorItemsTable