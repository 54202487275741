import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useMutation } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import useNotifier from 'hooks/useNotifier';
import {
  DeleteItemFirmware,
  DeleteItemFirmwareVariables,
} from '../types/DeleteItemFirmware';
import { deleteItemFirmwareMutation } from '../mutations';
import { useNavigate } from 'react-router';
import { itemFirmwareRoutes } from '../constants';
import { ItemFirmwareContext } from '../context/FirmwareProvider';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);

const ItemFirmwareActions: React.FC<IProps> = ({ row, ...rest }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();
  const { refetchItemFirmwares: refetch } = useContext(ItemFirmwareContext)
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [deleteItem] = useMutation<
    DeleteItemFirmware,
    DeleteItemFirmwareVariables
  >(deleteItemFirmwareMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: 'success',
        text: 'Item firmware deleted successfully',
      });
      refetch && refetch();
      setIsModalOpen(false);
    },
  });
  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id='expander'
        onClick={() => {
          history(itemFirmwareRoutes.getEditLink(row.values['node._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <HoverDropdown
        isDistributor={true}
        menus={[
          {
            name: "",
            action: () => {
            },
          },
        ]}
      />
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deleteItem({ variables: { itemFirmwareId: row.values['node._id'] } })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this item firmware?</div>
      </ActionModal>
    </div>
  );
};

export default ItemFirmwareActions;
