import React, { ReactNode, useContext, useEffect, useState } from 'react';
// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column, Row } from 'react-table';

import CardWrapper from '@components/Card/CardWrapper';
import { itemRoutes } from '../constants';
import { ClientExclusiveServiceContext as Context } from '../context/ClientExclusiveServicerProvider';
import { tableHeaderGen } from '@components/ReactTable/tableHeaderGen';
import TablePreview from '@components/Loaders/TablePreview';
import { itemTableData } from '../constants/itemTableData';
import DistributorItemsTable from './DistributorItemsTable';

interface IRTable {
  data?: any
  isLoading?: boolean
}

const ItemPage: React.FC<IRTable> = ({  isLoading }) => {
  const { items, tableActions, getItems, loading, goTo, paging, setPageLimit } = useContext(
    Context
  );

  const [headers, setHeaders] = useState<Column[]>([]);

  useEffect(() => {
    if (!items?.length) {
      getItems();
    }

    // eslint-disable-next-line
  }, []);
  const renderRowSubComponent = React.useCallback(({ row }: {row: Row}) => {
    return (
      <div className='sub-row-table' >
        <DistributorItemsTable row={row} />
      </div>
    ); 
  }, []);

  useEffect(() => {
    if (items?.length > 0 ) {
      const excludeColumns = ['node.distributor.orgContactPerson.contact.email', 'node.servicetype' ]
      const autogeneratedHeaders = tableHeaderGen(items[0])?.filter(it => !excludeColumns.includes(it?.accessor?.toString() || '')) || []
      setHeaders([...itemTableData, ...autogeneratedHeaders]);
    }  // eslint-disable-next-line
  }, [items]);
   
  if (headers.length === 0 || isLoading) {
    return <TablePreview items={items} loading={loading} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={headers as Column[]}
            data={items || []}
            checkedIds={[]}
            isLoading={loading || isLoading}
            addItemPageLink={itemRoutes.add}
            tableActions={tableActions}
            goTo={ goTo}
            paging={paging}
            hideRightToolbar={ false}
            setPageLimit={setPageLimit}
            renderRowSubComponent={renderRowSubComponent as ({ row }: { row?: Row<{}> | undefined; }) => ReactNode}
            hiddenColumns={[]}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemPage;
