import CardGridLoader from '@components/Loaders/CardGridLoader';
import useUpdateThingBroker from 'apps/events/app/broker/hooks/useUpdateThingBroker';
import { useGetSpecificThingBrokerQuery } from 'apps/events/app/broker/queries';
import { Broker } from 'apps/events/app/broker/types/Broker';
import { UpdateBrokerInput } from 'apps/events/app/types/eventsGlobalTypes';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import AddItem from '../components/AddItem';
import { itemRoutes } from '../constants';
import { BrokerContext } from '../context/ThingBrokerProvider';

const EditItemContainer = () => {
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(BrokerContext);

  const [item, setItem] = React.useState({} as Broker);

  const { data, loading } = useGetSpecificThingBrokerQuery({ id: itemId });

  useEffect(() => {
    setItem({
      ...(data?.getSpecificThingBroker || ({} as Broker)),
    }); // eslint-disable-next-line
  }, [data]);

  const { mutation, mutationOpts } = useUpdateThingBroker({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateBrokerInput) => {
    mutation({
      variables: {
        updateBrokerInput: {
          brokerId: itemId,
          login: input.login || '',
          name: input.name || '',
          password: input.password || '',
          url: input.url || '',
        },
      },
    });
  };

  if (!item?._id || loading) return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleSubmit}
      isLoading={mutationOpts.loading}
      item={{
        brokerId: item?._id,
        login: item?.login || '',
        password: item.password,
        url: item?.url,
        name: item?.name,
      }}
    />
  );
};

export default EditItemContainer;
