import CardWrapper from '@components/Card/CardWrapper';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react'
class ErrorBoundary extends React.Component<{children: React.ReactNode}, { error: string | null, errorInfo: { componentStack: string } | null }> {
	constructor(props: {children: React.ReactNode}) {
		super(props);
		this.state = { error: null, errorInfo: null, };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		})
		// You can also log error messages to an error reporting service here
	}
	
	render() {

		if (this.state.errorInfo) {
			// Error path
			return (
				<div className={'the-content'}>

				
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<CardWrapper>
							<Alert severity="error">
								<AlertTitle>Something went wrong...</AlertTitle>
								Errors happen, but they shouldn’t and we apologize for the inconvenience.
							</Alert>
							<div style={{ whiteSpace: 'pre-wrap' }}>
								{this.state.error && this.state.error.toString()}
								<br />
								{this.state.errorInfo.componentStack}
							</div>
						</CardWrapper></GridItem></GridContainer>
						</div>);
		}
		return this.props.children;
	}
}

export default ErrorBoundary