import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import { createSignalValidationSchema } from 'apps/things/app/validations';
import ItemForm from './ItemForm';
import { UpdateSignalInput } from '../../types/thingsGlobalTypes';

interface IRTable {
  handleSubmit: (payload: UpdateSignalInput) => void;
  isLoading: boolean;
  item: UpdateSignalInput;
}

const AddItem: React.FC<IRTable> = ({ handleSubmit, isLoading, item }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Formik
          onSubmit={async (values: any) => handleSubmit(values)}
          initialValues={{
            ...item,
          }}
          validationSchema={createSignalValidationSchema}
          validateOnChange={true}
        >
          {(formBag) => (
            <ItemForm
              formBag={(formBag as unknown) as FormikProps<UpdateSignalInput>}
              disableLoginButton={isLoading}
            />
          )}
        </Formik>
      </GridItem>
    </GridContainer>
  );
};

export default AddItem;
