import { userPrefTypes } from 'store/actionTypes/userPrefTypes';

export class UserPrefActions {
  static toggleColumn(body: any) {
    return {
      type: userPrefTypes.TOGGLE_COLUMN,
      payload: { body },
    };
  }
  
  static setRowsPerPage(rowsPerPage: number) {
    return {
      type: userPrefTypes.SET_ROWS_PER_PAGE,
      payload: rowsPerPage
    }
  }
}
