import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import AssignItemsForm from './AssignItemsForm';
import { LegacyBatchActions } from '../../LegacyBatch/redux/actions';
import { LegacyActions } from '../redux/actions';
import useNotifier from 'hooks/useNotifier';
import { LegacyAssignedItemContext } from '../../LegacyAssignedItems/context/LegacyAssignedItemsContext';
import LegacyContainer from '../../LegacyAssignedItems/containers/LegacyContainer';
import ActionModal from '@components/Modal/ActionModal';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);

const LegacyTableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [migrateDistributorModal, setMigrateDistributorModal] = useState(false);
  const [migrateDistributorLoading, setMigrateDistributorLoading] = useState(
    false
  );
  const [migrateAssignModalOpen, setMigrateAssignModalOpen] = useState(false)
  const [migrateCodeHistoryModalOpen, setMigrateCodeHistoryModalOpen] = useState(false)
  const [migrateCodeGenModalOpen, setMigrateCodeGenModalOpen] = useState(false)
  const [migrateAssignedItemsLoading, setMigrateAssignedItemsLoading] = useState(false)
  const notify = useNotifier();
  const { setQueries, advancedSearch } = React.useContext(
    LegacyAssignedItemContext
  );

  const migrateAssignedItems = () => {
    setMigrateAssignedItemsLoading(true);
    LegacyActions.migrateAssignedItems(row.values['distributorID'])
      .then(res => {
        setMigrateAssignedItemsLoading(false)
        setMigrateAssignModalOpen(false)
        notify({
          text: 'Migration job started successfully',
          status: 'success',
        });
      })
      .catch(err => {
        setMigrateAssignedItemsLoading(false)
        setMigrateAssignModalOpen(false)
        notify({
          text: 'Some error occurred',
          status: 'error',
        });
      })
  }

  const migrateCodeHistory = () => {
    setMigrateAssignedItemsLoading(true);
    LegacyActions.migrateDistributorCodeHistory(row.values['distributorID'])
      .then(res => {
        setMigrateAssignedItemsLoading(false)
        setMigrateCodeHistoryModalOpen(false)
        notify({
          text: 'Migration job started successfully',
          status: 'success',
        });
      })
      .catch(err => {
        setMigrateAssignedItemsLoading(false)
        setMigrateCodeHistoryModalOpen(false)
        notify({
          text: 'Some error occurred',
          status: 'error',
        });
      })
  }

  const migrateBulkCodeHistory = () => {
    setMigrateAssignedItemsLoading(true);
    LegacyActions.migrateDistributorBulkCodeHistory(row.values['distributorID'])
      .then(res => {
        setMigrateAssignedItemsLoading(false)
        setMigrateCodeHistoryModalOpen(false)
        notify({
          text: 'Migration job started successfully',
          status: 'success',
        });
      })
      .catch(err => {
        setMigrateAssignedItemsLoading(false)
        setMigrateCodeHistoryModalOpen(false)
        notify({
          text: 'Some error occurred',
          status: 'error',
        });
      })
  }

  const migrateCodeGen = () => {
    setMigrateAssignedItemsLoading(true);
    LegacyActions.migrateDistributorCodeGenerator(row.values['distributorID'])
      .then(res => {
        setMigrateAssignedItemsLoading(false)
        setMigrateCodeHistoryModalOpen(false)
        notify({
          text: 'Migration job started successfully',
          status: 'success',
        });
      })
      .catch(err => {
        setMigrateAssignedItemsLoading(false)
        setMigrateCodeHistoryModalOpen(false)
        notify({
          text: 'Some error occurred',
          status: 'error',
        });
      })
  }

  const bulkMigrateCodeGen = () => {
    setMigrateAssignedItemsLoading(true);
    LegacyActions.bulkMigrateDistributorCodeGenerator()
      .then(res => {
        setMigrateAssignedItemsLoading(false)
        setMigrateCodeHistoryModalOpen(false)
        notify({
          text: 'Migration job started successfully',
          status: 'success',
        });
      })
      .catch(err => {
        setMigrateAssignedItemsLoading(false)
        setMigrateCodeHistoryModalOpen(false)
        notify({
          text: 'Some error occurred',
          status: 'error',
        });
      })
  }

  const migrateDistributor = () => {
    setMigrateDistributorLoading(true);
    LegacyActions.migrateDistributor(row.values['distributorID'])
      .then((res) => {
        setMigrateDistributorLoading(false);
        setMigrateDistributorModal(false);
        notify({
          text: 'Migrated successfully',
          status: 'success',
        });
      })
      .catch((e) => {
        setMigrateDistributorLoading(false);
        setMigrateDistributorModal(false);
        notify({
          text: e?.response?.data?.error
            ? e?.response?.data?.error
            : JSON.stringify(e?.response?.data)?.substring(0, 100),
          status: 'error',
        });
      });
  };

  const onSubmit = (productItemIDs: string[]) => {
    setLoading(true);
    Promise.all(
      productItemIDs.map((item) =>
        LegacyBatchActions.assignItem({
          productItemID: item,
          distributorID: row.values['distributorID'],
        })
      )
    )
      .then((res) => {
        setLoading(false);
        notify({
          text: 'ProductItem assigned successfully',
          status: 'success',
        });
      })
      .catch((e) => {
        setLoading(false);
        notify({
          text: 'ProductItem assigned successfully',
          status: 'success',
        });
        setTimeout(() => {
          setIsAssignModalOpen(false);
        }, 2000);
      });
  };

  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        id='expander'
        onClick={() => { }}
        title='Edit '
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title='Delete '
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => ({})}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <HoverDropdown
        menus={[
          {
            name: 'Assigned Items',
            action: () => {
              setTimeout(() => {
                setQueries([
                  { column: 'distributorID', value: row.values['distributorID'] },
                ]);
                advancedSearch([
                  { column: 'distributorID', value: row.values['distributorID'] },
                ]);
              }, 2000);

              setHistoryModalOpen(true);
            },
          },
          {
            name: 'Migrate Distributor',
            action: () => {
              setMigrateDistributorModal(true);
            },
          },
          {
            name: 'Migrate Assigned Items',
            action: () => {
              setMigrateAssignModalOpen(true);
            },
          },
          {
            name: 'Migrate Code Generator',
            action: () => {
              setMigrateCodeGenModalOpen(true);
            },
          },
          {
            name: 'Migrate Code history',
            action: () => {
              setMigrateCodeHistoryModalOpen(true);
            },
          },
          {
            name: 'Bulk Migrate Code Generator',
            action: () => {
              bulkMigrateCodeGen()
            }
          },
          {
            name: 'Bulk Migrate Code History',
            action: () => {
              migrateBulkCodeHistory()
            }
          }
        ]}
      />
      {migrateAssignModalOpen ? (<ActionModal
        isModalOpen={migrateAssignModalOpen}
        toggleModal={setMigrateAssignModalOpen}
        handleOnClickOkey={migrateAssignedItems}
        loading={migrateAssignedItemsLoading}
        handleOnClickCancel={() => setMigrateAssignModalOpen(false)}
        okText='Migrate'
      >
        <div>Are you sure you want to distributor assigned items?</div>
      </ActionModal>) : null}
      {migrateCodeGenModalOpen ? (<ActionModal
        isModalOpen={migrateCodeGenModalOpen}
        toggleModal={setMigrateCodeGenModalOpen}
        handleOnClickOkey={migrateCodeGen}
        loading={migrateAssignedItemsLoading}
        handleOnClickCancel={() => setMigrateCodeGenModalOpen(false)}
        okText='Migrate'
      >
        <div>Are you sure you want to distributor code generators?</div>
      </ActionModal>) : null}
      {migrateCodeHistoryModalOpen ? (<ActionModal
        isModalOpen={migrateCodeHistoryModalOpen}
        toggleModal={setMigrateCodeHistoryModalOpen}
        handleOnClickOkey={migrateCodeHistory}
        loading={migrateAssignedItemsLoading}
        handleOnClickCancel={() => setMigrateAssignModalOpen(false)}
        okText='Migrate'
      >
        <div>Are you sure you want to distributor code history?</div>
      </ActionModal>) : null}
      <ActionModal
        isModalOpen={migrateDistributorModal}
        toggleModal={setMigrateDistributorModal}
        handleOnClickOkey={migrateDistributor}
        loading={migrateDistributorLoading}
        handleOnClickCancel={() => setMigrateDistributorModal(false)}
        okText='Migrate'
      >
        <div>Are you sure you want to migrate this distributor?</div>
      </ActionModal>
      <DrawerSidebar
        isModalOpen={historyModalOpen}
        toggleModal={setHistoryModalOpen}
        title={`Assigned Items ${row.values['customerName']}`}
        styles={{ minWidth: '800px', maxWidth: '940px' }}
      >
        {historyModalOpen ? <LegacyContainer /> : null}
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={isAssignModalOpen}
        toggleModal={setIsAssignModalOpen}
        title={`Assign to ${row.values['customerName']}`}
        styles={{ minWidth: '800px', maxWidth: '940px' }}
      >
        <AssignItemsForm onSubmit={onSubmit} loading={loading} />
      </DrawerSidebar>
    </div>
  );
};

export default LegacyTableActions;
