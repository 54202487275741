import { itemsTypes } from 'store/actionTypes/itemsTypes';

let INITIAL_STATE = {
  isProgress: false,
  items: {data:[]},
};

export function itemsReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case itemsTypes.SET_ITEMS:
      return {
        ...state,
        items: { ...state.items, ...action.payload },
      };

    default:
      return state;
  }
}
