import { useState } from "react";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { Row } from "react-table";
import { useAuth } from "admin/auth/context/AuthProvider";
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import GridContainer from "@components/Grid/GridContainer";
import GridItem from "@components/Grid/GridItem";
import { Formik, Form } from "formik";
import TextInput from "@components/CustomInput/TextInput";
import Button from "@components/CustomButtons/Button";
import {
  InitializeOpenTokenCodeGen,
  InitializeOpenTokenCodeGenVariables
} from "../types/InitializeOpenTokenCodeGen";
import { InitializeOpenTokenCodeGenInput } from "../../types/thingsGlobalTypes";
import { initializeOpenTokenCodeGenMutation } from "../mutation";
import { useMutation } from "@apollo/client";
import clientSimulator from "utils/clientSimulator";
import useNotifier from "hooks/useNotifier";
import { useOpenTokenSimulatorState } from "../context/ItemProvider";
import { handleGQLErrors } from "utils/gqlErrors";
import { useNavigate } from "react-router-dom";
import { itemsRoutes } from "../constants";
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const SkuTableActions: React.FC<IProps> = ({ row }) => {
  const notify = useNotifier()
  const history = useNavigate();
  const classes = useStyles();
  const { isDistributor } = useAuth();
  const [initializeItemModalOpen, setInitializeItemModalOpen] = useState(false)
  const { refetchClientItems } = useOpenTokenSimulatorState()
  const [intializeOpenTokenCodeGen] = useMutation<InitializeOpenTokenCodeGen, InitializeOpenTokenCodeGenVariables>(
    initializeOpenTokenCodeGenMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: "success",
          text: "Item Initialized successfully",
        });
        refetchClientItems && refetchClientItems();
        setInitializeItemModalOpen(false);
      },
      onError: (error) => {
        handleGQLErrors(notify, error)
      }
    }
  );
  const handleInitializeItem = (values: InitializeOpenTokenCodeGenInput) => {
    intializeOpenTokenCodeGen({
      variables: {
        initializeOpenTokenCodeGenInput: {
          oem_item_id: values.oem_item_id,
          max_count: values.max_count,
          secret_key: values.secret_key,
          starting_code: values.starting_code,
          used_count: values.used_count
        }
      }
    })
  }
  return (
    <div className="actions-right" style={{ display: "flex" }}>
      <>

        <div className={`${classes.iconWrapper} ${isDistributor ? classes.iconWrapperHover : ''}`}>
          <Tooltip
            title="Delete"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              id="expander"
              aria-label="Delete"
              className={classes.tableActionButton}
              style={isDistributor ? { opacity: 0.3, pointerEvents: "none" } : {}}
            >
              <Delete className={classes.tableActionButtonIcon + " " + classes.delete} />
            </IconButton>
          </Tooltip>
          {isDistributor && (
            <span className={classes.stopSign} role="img" aria-label="Stop">
              🚫
            </span>
          )}
        </div>
        <div className={`${classes.iconWrapper} ${isDistributor ? classes.iconWrapperHover : ''}`}>
          <Tooltip
            id="expander"
            onClick={() => {
            }}
            title="Edit"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              id="expander"
              aria-label="Edit"
              className={classes.tableActionButton}
              style={isDistributor ? { opacity: 0.3, pointerEvents: "none" } : {}}
            >
              <Edit className={classes.tableActionButtonIcon + " " + classes.edit} />
            </IconButton>
          </Tooltip>
          {isDistributor && (
            <span className={classes.stopSign} role="img" aria-label="Stop">
              🚫
            </span>
          )}
        </div>
        <HoverDropdown

          menus={[
            {
              name: "Simulator",
              action: () => {
                history(itemsRoutes.getSimulatorLink(row.values["node.oemItemID"]));
                console.log("Simulator Button Clicked, ------128-----")
              },
            },
            {
              name: "Initialize Item",
              action: () => {
                setInitializeItemModalOpen(true)
              },
            },
          ]}
        />
      </>
      {initializeItemModalOpen ? (
        <DrawerSidebar
          isModalOpen={initializeItemModalOpen}
          toggleModal={setInitializeItemModalOpen}
          title={`Initialize Item`}
          styles={{ maxWidth: "30vw", minWidth: "400px" }}
        >

          <Formik
            initialValues={{
              oem_item_id: row.values["node.oemItemID"] || '',
              starting_code: "123456789",
              secret_key: "0123456789abcdef0123456789abcdef",
              max_count: 0,
              used_count: 0
            }}
            onSubmit={(values) => {
              handleInitializeItem(values)
            }}
          >
            {(formBag) => (
              <Form>
                <GridContainer>
                  <GridItem md={12}>
                    <TextInput
                      label="Oem Item Id"
                      name="oem_item_id"
                      disabled
                      multiline
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <TextInput
                      label="Starting Code"
                      name="starting_code"
                      disabled
                      multiline
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <TextInput
                      label="Secret Key"
                      name="secret_key"
                      disabled
                      multiline
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <TextInput
                      label="Max Count"
                      name="max_count"
                      disabled
                      multiline
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <TextInput
                      label="Used Count"
                      name="used_count"
                      disabled
                      multiline
                    />
                  </GridItem>
                  <GridItem sm={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        formBag.submitForm();
                      }}
                    >
                      Initialize
                    </Button>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </DrawerSidebar>
      ) : null}

    </div>
  );
};

export default SkuTableActions;
