function createHash(a:any) {
return a
}

function randomBytes(b: any) {
  return b
}
const shasum = createHash('sha1');
class CodeGenerator {
  max_HCJ = 1096;
  hash_chain_length = 100000;
  PAYG_life_cycle_status = 'FREE';
  otp_count = 0;
  current_hash_index = this.hash_chain_length;
  OEM_SN = '0123456789';
  GAP_CHR = ' ';
  START_CHR = '*';
  END_CHR = '#';
  max_hit_tries = 2192;
  freeCodeJump = 1096;
  resetCodeJump = 2192;
  codeLimit = 3288; // freeCodeJump + resetCodeJump
  constructor() {
    this.max_HCJ = 1096;
  }

  /**
   * Generates a valid base64 nonce value of at least 128-bits.
   *
   * @param {number} size The random bytes to generate clamped between 16 and 64.
   * Defaults to 16 (128-bit).
   *
   * @see https://csp.withgoogle.com/docs/faq.html#generating-nonces
   *
   * @returns {string} The base64 nonce value.
   */
  nonce = (size = 16): string => {
    const clamped = Math.max(16, Math.min(size, 64));
    const rounded = Math.round(clamped);

    return randomBytes(rounded).toString('base64');
  };

  hash_str_to_Low_High_Bytes(hash_str: string) {
    let otp_hash_low_high: number[] = [];

    otp_hash_low_high[0] = parseInt(hash_str.substr(0, 8), 16);
    otp_hash_low_high[1] = parseInt(hash_str.substr(8, 8), 16);
    return otp_hash_low_high;
  }

  OTP_Gen_Low_High = (msg: string) => {
    let otp_hash = this.OTP_Hash_64(msg);
    let otp_hash_low_high = this.hash_str_to_Low_High_Bytes(otp_hash);
    return otp_hash_low_high;
  };

  dec_str_padding_low_high = (hash_str: string) => {
    let hash_low_high = this.hash_str_to_Low_High_Bytes(hash_str);

    const token =
      '0' +
      hash_low_high
        .map((item) => this.zeroFill(item.toString(), 10))
        .join()
        .replace(',', '');

    let hash_form = '';

    for (var i = 0; i <= 6; i++) {
      hash_form += token.substr(i * 3, 3) + this.GAP_CHR;
    }
    return this.START_CHR + hash_form.trim() + this.END_CHR;
  };

  get_hash = (msg: string) => {
    let otp_hash = shasum.update(msg);
    return otp_hash.digest('hex');
  };

  zeroFill(number: string, width: number) {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
    }
    return number.toString();
  }
  
  /**
   * 
   * @param msg 
   * @returns 16 digits
   */
  OTP_Hash_64 = (msg: string) => {
    var shasum = createHash('sha1');
    let otp_hash = shasum.update(msg);
    let hash_hex = otp_hash.digest('hex'); // 40 char string 

    // be92910a-e7bb6f58bb9b7c6f845bc30640119592

    let otp_hash_hex: number[] = [];
    
    // create an array of 5 items (number) each 8 char long
    for (var i = 0; i <= 4; i++) {
      otp_hash_hex[i] = parseInt(hash_hex.substr(i * 8, 8), 16);
    }

    otp_hash_hex[0] = (otp_hash_hex[0] ^ otp_hash_hex[2]) >>> 0;
    otp_hash_hex[0] = (otp_hash_hex[0] ^ otp_hash_hex[4]) >>> 0;
    otp_hash_hex[1] = (otp_hash_hex[1] ^ otp_hash_hex[3]) >>> 0;

    // arr[0] = 08DF88888

    return (
      this.zeroFill(otp_hash_hex[0].toString(16).toUpperCase(), 8) +
      '' +
      this.zeroFill(otp_hash_hex[1].toString(16).toUpperCase(), 8)
    );
  };

  OTP_hex = (
    hash_root: string,
    current_has_index: number,
    hash_jump: number
  ) => {
    let otp_hash_hex = hash_root?.toString() || '';
    
    const K = current_has_index - hash_jump;
    for (var i = 1; i <= K; i++) {
      otp_hash_hex = this.OTP_Hash_64(otp_hash_hex);
    }
    return otp_hash_hex;
  };

  get_hash_index = (hash_root: string, hash_top: string) => {
    let otp_hash_hex = hash_root.toString();
    let count = 0;

    for (let index = 1; index < this.hash_chain_length; index++) {
      otp_hash_hex = this.OTP_Hash_64(otp_hash_hex);

      if (otp_hash_hex === hash_top) {
        count = index;
        break;
      }
    }
    count = this.hash_chain_length - count;  // 100,0000 - 99,9999 (count) (max=2192)
    return count;
  };

  extract_hash_low_high(formatted_hash_str: string): number[] {
    // strip out spaces
    let hash_str = formatted_hash_str?.slice(2)?.slice(0, -1)?.replaceAll(' ', '') || '';

    return [
      parseInt(hash_str.substr(0, 10)),
      parseInt(hash_str.substr(10, 10)),
    ];
  }

  extract_hash_str_low_high(otp_hash_hex: number[]) {
    return (
      this.zeroFill(otp_hash_hex[0].toString(16).toUpperCase(), 8) +
      '' +
      this.zeroFill(otp_hash_hex[1].toString(16).toUpperCase(), 8)
    );
  }

  padded_decimal_to_hex = (padded: string) => {
    return this.extract_hash_str_low_high(this.extract_hash_low_high(padded));
  };
}

export const codeGenerator = new CodeGenerator();
export default codeGenerator;
