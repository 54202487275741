import React, { useContext, useEffect, useState } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import CardWrapper from '@components/Card/CardWrapper';
import { PayPlanTemplateContext } from '../context/EventProvider';
import { tableHeaderGen } from '@components/ReactTable/tableHeaderGen';
import TablePreview from '@components/Loaders/TablePreview';
import { itemTableData,displayPayPlanFields } from '../constants/itemTableData';
import { itemRoutes } from '../constants';
interface IRTable {}

const ItemPage: React.FC<IRTable> = () => {
  const { items, tableActions, getItems, searchQuery, setSearchQuery, loading, goTo, paging } = useContext(
    PayPlanTemplateContext
  );
  const [headers, setHeaders] = useState<Column[]>([]);
  const [itemsClone, setItemsClone] = useState([])
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      let itemsClone: any = JSON.parse(JSON.stringify(items))
      if(items[0].node?.planDetails) {
        for (let i = 0; i<items.length; i++) {
          // @ts-ignore
          for(let j = 0; j<items[i].node?.planDetails.length; j++) {
            // @ts-ignore
            let pName = items[i].node?.planDetails[j]['pName']
            // @ts-ignore
            let pValue = items[i].node?.planDetails[j]['pValue']
            if(pName) {
            itemsClone[i].node[String(pName)] = pValue
            }
          }
        }

      }
      let displayedFields = displayPayPlanFields(items[0].node?.planDetails);
      let tableHeaders = tableHeaderGen(items[0]);
      let finalHeaders = [
          ...tableHeaders.slice(0, 2),
          ...displayedFields,
          ...tableHeaders.slice(2, 4)
      ];
      
      setHeaders([...itemTableData, ...finalHeaders]);
      setItemsClone(itemsClone)
    }
  }, [items]);
  if (headers.length === 0) {
    return <TablePreview items={items} loading={loading} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={headers as Column[]}
            data={itemsClone}
            addItemPageLink={itemRoutes.add}
            checkedIds={[]}
            isLoading={loading}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            tableActions={tableActions}
            goTo={goTo}
            paging={paging}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemPage;
