import { SocketContext } from 'apps/migration/app/Sockets/context/SocketContext';
import React, { useContext, useEffect } from 'react';

interface IProps {
  productBatchID: string;
}

const HandleSocketIO: React.FC<IProps> = ({ productBatchID }) => {
  const { socket } = useContext(SocketContext);
  useEffect(() => {
    socket?.emit('requestBatchStatus', { productBatchID }); // eslint-disable-next-line
  }, []);

  socket?.on('batchInitializationStatus', (data) => {
    console.warn(data);
  });

  socket?.on('message', function (data) {
    console.log('Incoming message:', data);
  });
  return <></>;
};

export default HandleSocketIO;
