import { ApolloError } from '@apollo/client';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotifier from './useNotifier';
import { itemRoutes } from 'apps/clientService/app/clientExclusiveServicer/constants';

const useServicerPermission = ({
  loading,
  clientError,
}: {
  loading: boolean;
  clientError: ApolloError | undefined;
}) => {
  const history = useNavigate();
  const notify = useNotifier();
  const { userPref, isServicer } = useAuth();

  useEffect(() => {
    if (loading) return;
    if (isServicer && !userPref.distributorId) {
      notify({
        status: 'error',
        text: 'Please ensure that you have selected a distributor on client exclusive servicer table actions.',
      });
      setTimeout(() => {
        history(itemRoutes.list);
      }, 3000);
    } else if (isServicer && clientError) {
      notify({
        status: 'error',
        text: "Please contact admin for permissions to list this distributor's items.",
      });
      setTimeout(() => {
        history(itemRoutes.list);
      }, 3000);
    } // eslint-disable-next-line
  }, [loading, clientError]);
};

export default useServicerPermission;
