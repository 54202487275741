import { useMutation } from '@apollo/client';
import CardGridLoader from '@components/Loaders/CardGridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateItemFirmwareInput } from 'apps/things/app/types/thingsGlobalTypes';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import EditItemFirmware from '../components/EditItemFirmware';
import { itemFirmwareRoutes } from '../constants';
import { updateItemFirmwareMutation } from '../mutations';
import {
  useGetAllItemFirmwaresQuery,
  useGetSpecificItemFirmwareQuery,
} from '../queries';

import { GetSpecificItemFirmware_getSpecificItemFirmware } from '../types/GetSpecificItemFirmware';
import {
  UpdateItemFirmware,
  UpdateItemFirmwareVariables,
} from '../types/UpdateItemFirmware';

const EditItemFirmwareContainer = () => {
  const notify = useNotifier();
  const { refetch } = useGetAllItemFirmwaresQuery({
    first: 100,
  });

  const [itemFirmware, setItemFirmware] = useState(
    {} as GetSpecificItemFirmware_getSpecificItemFirmware
  );
  const { itemId = '' } = useParams();
  const history = useNavigate();

  const { data, loading } = useGetSpecificItemFirmwareQuery({ id: itemId });

  useEffect(() => {
    setItemFirmware(
      data?.getSpecificItemFirmware ||
      ({} as GetSpecificItemFirmware_getSpecificItemFirmware)
    );
  }, [data]);
  const [updateItemFirmware] = useMutation<
    UpdateItemFirmware,
    UpdateItemFirmwareVariables
  >(updateItemFirmwareMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: 'success',
        text: 'Item firmware update successfully.',
      });
      refetch();
      history(itemFirmwareRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleUpdate = (updateItemFirmwareInput: UpdateItemFirmwareInput) => {
    updateItemFirmware({
      variables: { updateItemFirmwareInput },
    });
  };
  if (loading || !itemFirmware._id) return <CardGridLoader />;

  return (
    <EditItemFirmware
      isLoading={loading}
      handleUpdate={handleUpdate}
      itemFirmware={itemFirmware}
    />
  );
};

export default EditItemFirmwareContainer;
