import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/itemTableData';
import CardWrapper from '@components/Card/CardWrapper';
import { itemRoutes } from '../constants';
import { CustomerContext } from '../context/CustomerProvider';
import useServicerPermission from 'hooks/useServicerPermission';
import { useAuth } from 'admin/auth/context/AuthProvider';

interface IRTable { }

const ItemPage: React.FC<IRTable> = () => {
  const { items, tableActions, getItems, loading, goTo, paging, setSearchQuery, setPageLimit, clientError, searchQuery } = useContext(CustomerContext);
  const { isDistributorStaff } = useAuth()
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  useServicerPermission({ loading, clientError })
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              itemTableData().header as Column[]
            }
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={itemRoutes.add}
            addItemBtnText='Add'
            tableActions={tableActions}
            paging={paging}
            goTo={goTo}
            isDistributorStaff={isDistributorStaff}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemPage;
