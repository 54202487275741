import {
  LifeCycle,
  QueryOrder,
  ThingIDTypes,
} from 'apps/things/app/types/thingsGlobalTypes';

export const itemFields = {
  addItem: {
    idString: 'idString',
    creationDate: 'creationDate',
    oemID: 'oemID',
    oemItemID: 'oemItemID',
    itembatchId: 'itembatchId',
    itemBatchNumber: 'itemBatchNumber', // for storing select option label
    itemfirmwareId: 'itemfirmwareId',
    codegeneratorId: 'codegeneratorId',
    itemStockId: 'itemStockId',
    itemFleetId: 'itemFleetId',
    totalItems: 'totalItems',
  },
};

export const itemsRoutes = {
  listClient: '/admin/item/distributor-items',
  list: '/admin/item',
  listBatch: '/admin/item/:batchNumber/:itemId',
  add: '/admin/item/add',
  edit: '/admin/item/:itemId',
  getEditLink: (itemId: string) => `/admin/item/${itemId}`,
  getListBatch: (itemId: string, batchNumber: string) => `/admin/item/${batchNumber}/${itemId}`
};

export const defaultQueryVariables = {
  first: 20,
  queryorder: QueryOrder.DESC,
};

export const thingIDTypeOptions = [
  {
    _id: ThingIDTypes.DOI,
    name: ThingIDTypes.DOI,
  },
  {
    _id: ThingIDTypes.MAC,
    name: ThingIDTypes.MAC,
  },
  {
    _id: ThingIDTypes.UUID,
    name: ThingIDTypes.UUID,
  },
];

export const LifeCycleOptions = [
  {
    _id: LifeCycle.ASSIGNED,
    name: LifeCycle.ASSIGNED,
  },
  {
    _id: LifeCycle.INITIATED,
    name: LifeCycle.INITIATED,
  },
  {
    _id: LifeCycle.INSTALLED,
    name: LifeCycle.INSTALLED,
  },
  {
    _id: LifeCycle.PRODUCED,
    name: LifeCycle.PRODUCED,
  },
  {
    _id: LifeCycle.RECYCLED,
    name: LifeCycle.RECYCLED,
  },
  {
    _id: LifeCycle.RETURNED,
    name: LifeCycle.RETURNED,
  },
  {
    _id: LifeCycle.SHIPPED,
    name: LifeCycle.SHIPPED,
  },
];
