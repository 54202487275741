import { useMutation } from '@apollo/client';
import { UpdateItemSKU, UpdateItemSKUVariables } from '../types/UpdateItemSKU';
import { updateItemSKUMutation } from '../mutations';
import clientSimulator from 'utils/clientSimulator';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';

const useUpdateItemSKU = ({
  onComplete,
}: {
  onComplete: (data: any) => void;
}) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UpdateItemSKU,
    UpdateItemSKUVariables
  >(updateItemSKUMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      onComplete(data);
    },
    onError: (err) => {
      const messages = err?.graphQLErrors || [];

      const msg = JSON.stringify( // @ts-ignore
        messages?.map((item) => item?.extensions?.response?.message)
      );
      notify({ text: msg, status: 'error' });
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useUpdateItemSKU;
