import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import PaymentPlanForm from './PaymentPlanForm';
import CardWrapper from '@components/Card/CardWrapper';
import { PaymentPlanInput } from '../../constants';
interface IRTable {
  handleSubmit: (payload: PaymentPlanInput) => void;
  isLoading: boolean;
  item: any;
  inActive?: boolean;
}

const AddPaymentPlan: React.FC<IRTable> = ({
  handleSubmit,
  isLoading,
  item,
  inActive,
}) => {

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>{ handleSubmit(values)}}
            initialValues={item}
            validateOnChange={true}
            enableReinitialize
          >
            {(formBag) => (
              <PaymentPlanForm
                formBag={
                  (formBag as unknown) as FormikProps<any>
                }
                disableLoginButton={isLoading}
                inActive={inActive}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddPaymentPlan;
