import { provisionTypes } from 'store/actionTypes/provisionTypes';

let INITIAL_STATE = {
  isProgress: false,
  items: { data: [] },
  unresolvedItems: { data: [] },
  rawItems: { data: [] },
  progress: {
    data: {
      totalLoops: 0,
      loopIndex: 0,
    },
  },
};

export function provisionReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case provisionTypes.SET_PROVISION_ITEMS:
      return {
        ...state,
        items: { data: action.payload },
      };
    case provisionTypes.SET_UNRESOLVED_ITEMS:
      return { ...state, unresolvedItems: { data: action.payload } };
    case provisionTypes.SET_PROGRESS:
      return { ...state, progress: { data: action.payload } };
    case provisionTypes.SET_RAW_ITEMS:
      return { ...state, rawItems: { data: action.payload } };
    default:
      return state;
  }
}
