import { Grid } from "@material-ui/core";
import { Form, FormikProps } from "formik";

// @material-ui/icons
// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
import Button from "@components/CustomButtons/Button";
import React, { useContext, useEffect, useState } from "react";
import TextInput from "@components/CustomInput/TextInput";
import GoBackHeader from "@components/GoBackHeader/GoBackHeader";
import { itemFields, getAccountStageValue } from "../../constants";
import GridHalfWrapper from "@components/Grid/GridHalfWrapper";
import useCustomer from "apps/clientService/app/customer/hooks/useCustomer";
import { PairAssetAccountInput } from "../../../types/accountsGlobalTypes";
import AsyncSeachableSelect from "@components/CustomSelect/AsyncSeachableSelect";
import { Payment } from "../../types/Payment";
import CodeActions from "./CodeActions";
import CreditActions from "./CreditActions";
import Accordion from "@components/Accordion/Accordion";
import SetUpPaymentSchedule from "./SetUpPaymentSchedule";
import { AssetAccountContext } from "../../context/AssetAccountProvider";
import ActivateAccount from "./ActivateAccount";
import CloseAccount from "./CloseAccount";
import AssetPayComplete from "./AssetPayComplete";
import { titleCase } from "utils";
import { ItemContext } from "apps/things/app/item/context/ItemProvider";
import cc from 'currency-codes';
import { useAuth } from "admin/auth/context/AuthProvider";
const ItemForm: React.FC<{
  formBag: FormikProps<PairAssetAccountInput>;
  disableLoginButton: boolean;
  actionButtonName?: string;
  customerName?: string;
  paymentHistory?: Payment[];
  refetchItem?: () => void;
  assetId?: string;
}> = (props) => {
  const {
    formBag,
    disableLoginButton,
    actionButtonName = "Add",
    paymentHistory = [],
    refetchItem
  } = props;

  const [customerOptions, setCustomerOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [codeGenModalOpen, setCodeGenModalOpen] = useState(false);
  const { item } = useContext(AssetAccountContext);
  const {loggedInUserId} = useAuth();

  const {
    refetchClientItems,
    clientItems: items,
    loading: iLoading,
  } = useContext(ItemContext);

  const {
    options: _customerOptions,
    loading: cLoading,
    refetch: refetchCustomers,
  } = useCustomer();


  
  useEffect(() => {
    if (_customerOptions.length > 0 && !cLoading)
      setCustomerOptions(
        _customerOptions?.map((item) => ({
          value: item?._id || "",
          label: item?.name || "",
        }))
      ); // eslint-disable-next-line
  }, [_customerOptions]);

  const [itemOptions, setItemOptions] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    if (items.length > 0 && !iLoading)
      setItemOptions(
        items?.map((item) => ({
          value: item?.node?._id || "",
          label: item?.node?.sellerItemID || "",
        }))
      ); // eslint-disable-next-line
  }, [items]);

  const searchItem = async (search: string) => {
    if (!refetchClientItems) {
      return itemOptions;
    }
    const refetchData = await refetchClientItems({ first: 20, search });
    const items = refetchData?.data?.getAllClientItems?.page?.edges?.map(
      (item) => {
        return {
          label: item.node?.sellerItemID || "",
          value: item.node?._id || "",
        };
      }
    );
    return items as { value: string; label: string }[];
  };

  const searchCustomer = async (search: string) => {
    if (!refetchCustomers) {
      return itemOptions;
    }

    const refetchData = await refetchCustomers({
      first: 20,
      search,
    });
    const items = refetchData?.data?.getAllClientCustomers?.page?.edges?.map(
      (item) => {
        return {
          label: item.node?.name || "",
          value: item.node?._id || "",
        };
      }
    );
    return items as { value: string; label: string }[];
  };
  const currency_code_data = cc.data.map((item) => {
    return {
      label: item?.code, 
      value: item?.code
    }
  })
  const searchCurrency = async(search:string) => {
    const items = currency_code_data.filter(item => item.label.includes(search));
    return items as { value: string; label: string }[];
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <GridContainer>
          <GridHalfWrapper>
            <h5>
              <b>Accout Number: </b>
              {item?.asset?.sellerItemID}
            </h5>
          </GridHalfWrapper>
          <GridHalfWrapper>
            <h5>
              <b>User Name: </b>
              {titleCase(item?.credit?.owner?.name || "")}
            </h5>
          </GridHalfWrapper>
        </GridContainer>
        <Accordion
          active={getAccountStageValue(item?.accountStage || "")}
          collapses={[
            {
              title: "Stage 1: Pair User With Asset",
              complete: getAccountStageValue(item?.accountStage) > 0,
              content: (
                <Form style={{ width: "100%" }}>
                  <GridContainer>
                    <GridHalfWrapper>
                      <h6>Item Id</h6>
                      <AsyncSeachableSelect
                        onChange={(e: any) => {
                          formBag.setFieldValue(itemFields.itemId, e);
                        }}
                        placeholder={"Select an item"}
                        options={itemOptions}
                        searchPromise={searchItem}
                        isDisabled={!!item?.credit?.owner?.name}
                        initialValue={
                          item?.asset?.oemItemID
                            ? {
                                value: "",
                                label: item?.asset?.oemItemID,
                              }
                            : {}
                        }
                        error={formBag.errors.itemId}
                      />
                    </GridHalfWrapper>
                    <GridHalfWrapper>
                      <h6>User Credit Account</h6>
                      <AsyncSeachableSelect
                        onChange={(e: any) => {
                          formBag.setFieldValue(itemFields.customerId, e);
                        }}
                        placeholder={"Select customer"}
                        options={customerOptions}
                        searchPromise={searchCustomer}
                        isDisabled={!!item?.credit?.owner?.name}
                        initialValue={
                          item?.credit?.owner?.name
                            ? {
                                value: "",
                                label: item?.credit?.owner?.name,
                              }
                            : {}
                        }
                        error={formBag.errors.credit?.customerId}
                      />
                      <GridContainer>
                        <GridHalfWrapper>
                          <TextInput
                            label="Balance"
                            name={itemFields?.balance}
                            type="number"
                            disabled={!!item?._id || ["660a8433ee0960d169dc8bfd", 
                              "662901b8082c0b1a656894d2"].includes(loggedInUserId)} 
                            value={
                              formBag?.values?.credit?.balance ||
                              item?.credit?.balance
                            }
                          />
                        </GridHalfWrapper>
                        <GridHalfWrapper>
                      <AsyncSeachableSelect
                        onChange={(e: any) => {
                          formBag.setFieldValue(itemFields.currency, e);
                        }}
                        placeholder={"Select Currency"}
                        options={currency_code_data}
                        searchPromise={searchCurrency}
                        isDisabled={!!item?._id}
                        initialValue={
                          item?.credit?.currency
                            ? {
                                value: "",
                                label: item?.credit?.currency,
                              }
                            : {}
                        }
                        error={formBag.errors.credit?.currency}
                      />
                        </GridHalfWrapper>
                      </GridContainer>
                    </GridHalfWrapper>
                  </GridContainer>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Button
                        color="primary"
                        onClick={() => {
                          formBag.submitForm();
                        }}
                        disabled={
                          disableLoginButton || !!item?.credit?.owner?.name
                        }
                      >
                        Pair
                      </Button>
                    </Grid>
                  </div>
                </Form>
              ),
            },
            {
              title: "Stage 2: Setup Payment Schedule",
              complete: getAccountStageValue(item?.accountStage) > 1,
              content: (
                <>
                  <SetUpPaymentSchedule assetAccountId={item?._id} />
                </>
              ),
            },
            {
              title: "Stage 3: Activate Account",
              complete: getAccountStageValue(item?.accountStage) > 2,
              content: (
                <>
                  <ActivateAccount
                    active={getAccountStageValue(item?.accountStage) === 2}
                  />
                </>
              ),
            },
            {
              title: "Stage 4: Payments And Codes",
              complete: getAccountStageValue(item?.accountStage) > 3,
              content: (
                <>
                  <GridContainer>
                    <GridHalfWrapper>
                      <CodeActions
                        disableLoginButton={disableLoginButton}
                        formBag={formBag}
                        codeGenModalOpen={codeGenModalOpen}
                        setCodeGenModalOpen={setCodeGenModalOpen}
                        active={getAccountStageValue(item?.accountStage) > 2}
                      />
                    </GridHalfWrapper>
                    <GridHalfWrapper>
                      <CreditActions
                        assetAccountId={item?._id}
                        setCodeGenModalOpen={setCodeGenModalOpen}
                        disableLoginButton={disableLoginButton}
                        formBag={formBag}
                        paymentHistory={paymentHistory || []}
                        refetchItem={refetchItem}
                        active={getAccountStageValue(item?.accountStage) > 2}
                      />
                    </GridHalfWrapper>
                  </GridContainer>
                </>
              ),
            },
            {
              title: "Stage 5: Review Completion Plan",
              complete: getAccountStageValue(item?.accountStage) > 3,
              content: (
                <>
                  <GridContainer>
                    <GridHalfWrapper>
                      <CodeActions
                        disableLoginButton={disableLoginButton}
                        formBag={formBag}
                        codeGenModalOpen={codeGenModalOpen}
                        setCodeGenModalOpen={setCodeGenModalOpen}
                        hideGenerate
                        active={getAccountStageValue(item?.accountStage) > 2}
                      />
                    </GridHalfWrapper>
                    <GridHalfWrapper>
                      <CreditActions
                        assetAccountId={item?._id}
                        setCodeGenModalOpen={setCodeGenModalOpen}
                        disableLoginButton={disableLoginButton}
                        formBag={formBag}
                        paymentHistory={paymentHistory || []}
                        refetchItem={refetchItem}
                        showActivities
                        active={getAccountStageValue(item?.accountStage) > 2}
                      />
                    </GridHalfWrapper>
                    <GridHalfWrapper>
                      <AssetPayComplete
                        active={
                          getAccountStageValue(item?.accountStage) === 3 ||
                          getAccountStageValue(item?.accountStage) === 4
                        }
                      />
                    </GridHalfWrapper>
                  </GridContainer>
                </>
              ),
            },
            {
              title: "Stage 6: Close Account",
              complete: getAccountStageValue(item?.accountStage) >= 5,
              content: (
                <>
                  <CloseAccount
                    active={getAccountStageValue(item?.accountStage) >= 4}
                  />
                </>
              ),
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
