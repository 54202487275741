import DateCell from '@components/ReactTable/DateCell';
import React from 'react';
import { Row } from 'react-table';
import ItemTableActions from '../components/ItemTableActions';

interface ITableData {
  CheckboxComponent?: React.ReactNode;
  custom?: boolean
}

const thresholdWidth = 600;
let header:any = []

if (window.innerWidth <= thresholdWidth) {
  header = [
    {
      Header: 'oemItemID',
      accessor: 'node.oemItemID',
    },
    {
      Header: 'sellerItemID',
      accessor: 'node.sellerItemID',
    },
    { Header: 'ID', accessor: 'node._id' },
    {
      Header: 'oemID',
      accessor: 'node.oemID',
    },
    {
      Header: 'sellerID',
      accessor: 'node.sellerID',
    },

    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
  ]
} else {
  header = [
    {
      Header: 'oemItemID',
      accessor: 'node.oemItemID',
    },
    {
      Header: 'AccountNumber',
      accessor: 'node.sellerItemID',
    },

    {
      Header: 'Fleet',
      accessor: 'node.itemFleet.fleetName',
    },
    {
      Header: 'sellerID',
      accessor: 'node.sellerID',
    },
    {
      Header: 'oemID',
      accessor: 'node.oemID',
    },
  ]
}

export const itemTableData = ({ custom = false }: ITableData) => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <>
          {<ItemTableActions {...row.getToggleRowExpandedProps()} row={row} />}
        </>

      ),
    },
    ...header
  ],
});


let headerCodeEvents:any = []

if (window.innerWidth <= thresholdWidth) {
  headerCodeEvents = [
    {
      Header: 'Token',
      accessor: 'token',
    },
    {
      Header: 'Token Type',
      accessor: 'token_type',
    },
    {
      Header: 'Token Value',
      accessor: 'token_value',
    },
    {
      Header: 'Max Count',
      accessor: 'max_count',
    },
    {
      Header: 'Date Created',
      accessor: 'createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      Cell: DateCell,
    },
  ]
} else {
  headerCodeEvents = [
    {
      Header: 'Token',
      accessor: 'token',
    },
    {
      Header: 'Token Type',
      accessor: 'token_type',
    },
    {
      Header: 'Token Value',
      accessor: 'token_value',
    },
    {
      Header: 'Max Count',
      accessor: 'max_count',
    },

    {
      Header: 'Date Created',
      accessor: 'createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      Cell: DateCell,
    },
  ]
}

export const itemTableDataCodeEvents = ({ custom = false }: ITableData) => ({
  headerCodeEvents: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
    },
    ...headerCodeEvents
  ],
});