import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "apollo-link-error";
import { getToken } from "admin/auth/utils";
import { FEDERATION_API } from "config";

// Instantiate required constructor fields
const cache = new InMemoryCache();

const link = createHttpLink({
  uri: FEDERATION_API,
});

export const tokenLink = setContext((_, context) => {
  const authToken = getToken();
  return {
    ...context,
    headers: {
      ...context.headers,
      Authorization: authToken ? `Bearer ${authToken.auth}` : null,
      universe: "ECOMMERCE",
    },
  };
});

interface ResponseError extends ErrorResponse {
  networkError?: Error & {
    statusCode?: number;
    bodyText?: string;
  };
}

export const errorLink = onError((error: ResponseError) => {
  if (error.networkError && error.networkError.statusCode === 524) {
  }
  if (error.networkError && error.networkError.statusCode === 401) {
  }
});

const client = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  link: tokenLink.concat(link),

  // Provide some optional constructor fields
  name: "react-web-client",
  version: "1.3",
  queryDeduplication: false,
  defaultOptions: {
    mutate: {},
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

export default client;
