import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateAvatarByItemIDInput } from '../../types/thingsGlobalTypes';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';

import { AvatarContext as Context } from '../context/AvatarProvider';
import useCreateSignal from '../hooks/useCreateAvatar';

const AddItemContainer = () => {
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(Context);

  // update when client Id is provided
  const {
    mutation: createItem,
    mutationOpts: createItemOpts,
  } = useCreateSignal({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateAvatarByItemIDInput) => {
    createItem({
      variables: {
        createAvatarInput: {
          att: input.att,
          cmd: input.cmd,
          dia: input.dia,
          dta: input.dta,
          sts: input.sts,
          itemAlive: input.itemAlive,
          lastPub: input.lastPub || '',
          lastSub: input.lastSub || '',
          lastSync: input.lastSync,
          shadow: input.shadow || '',
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
