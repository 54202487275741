import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import CardWrapper from '@components/Card/CardWrapper';
 import { itemTableDataCodeEvents } from '../constants/itemTableData';

interface IRTable {
    codeEvents?: any
    data?:any
}

const OpenTokenCodeEventComponent: React.FC<IRTable> = ({ data}) => {
    const columns = itemTableDataCodeEvents({}).headerCodeEvents

    
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
          columns={columns}
            data={data?.openTokenCodeHistory}
            checkedIds={[]}
            isLoading={false}
            hideRightToolbar
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
const OpenTokenCodeEvent = React.memo(OpenTokenCodeEventComponent);
export default OpenTokenCodeEvent;


