import React from 'react';
import LegacyBatchPage from '../components/LegacyBatchPage';

interface IProps {}

const LegacyBatch: React.FC<IProps> = () => {
  
  return <LegacyBatchPage isLoading={false} />;
};

export default LegacyBatch;
