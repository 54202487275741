import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import CardWrapper from '@components/Card/CardWrapper';
import { LegacyModelContext } from '../context/LegacyModelContext';
import { legacyModelTableData } from '../constants/data';
import { legacyRoutes } from '../constants';

interface IRTable {
  bulkDeleteProducts?: () => void;
  isLoading: boolean;
}

const LegacyModelPage: React.FC<IRTable> = () => {
  const { items, getItems, loading, tableActions } = useContext(LegacyModelContext);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={legacyModelTableData.header as Column[]}
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={legacyRoutes.add}
            addItemBtnText='Add'
            tableActions={tableActions}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
export default LegacyModelPage;
