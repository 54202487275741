import DescriptionCell from '@components/ReactTable/DescriptionCell';
import React from 'react';
import { Row } from 'react-table';
import BatchLegacyLinkCell from '../components/BatchLegacyLinkCell';
import LegacyBatchTableActions from '../components/LegacyBatchTableActions';

export const legacyBatchTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <LegacyBatchTableActions
          {...row.getToggleRowExpandedProps()}
          row={row}
        />
      ),
    },
    { Header: 'productBatchID', accessor: 'productBatchID' },
    {
      Header: 'productBatchNumber',
      accessor: 'productBatchNumber',
      Cell: BatchLegacyLinkCell
    },
    {
      Header: 'productBatchState',
      accessor: 'productBatchState',
    },
    {
      Header: 'productBatchNotes',
      accessor: 'productBatchNotes',
      Cell: DescriptionCell,
    },
    {
      Header: 'productBatchDate',
      accessor: 'productBatchDate',
    },
  ],
};
