import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";

// core components
import Footer from "@components/Footer/Footer.js";

import routes from "routes/routes";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import { IRoute } from "routes/types";

const AuthLayout: React.FC = (props: any) => {
  const { classes } = props;
  // ref for the wrapper div
  const wrapper = React.createRef<HTMLDivElement>();
  // styles
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });
  const getRoutes = (routes: IRoute[]): any => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        const views = props && prop.views;
        return getRoutes(views);
      }
      if (prop.layout === '/auth') {
        const Component = prop.component
        return (
          <Route
            path={prop.path} // @ts-ignore
            element={<Component />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>{getRoutes(routes as IRoute[])}</Routes>
          </Suspense>
          <Footer white />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles as any)(AuthLayout as any) as any;
