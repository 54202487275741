import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import { assetAccountValidationSchema } from 'apps/things/app/validations';
import ItemForm from './ItemForm/ItemForm';
import CardWrapper from '@components/Card/CardWrapper';
import { PairAssetAccountInput, UpdateAssetAccountInput } from '../../types/accountsGlobalTypes';
import { Payment } from '../types/Payment';

interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: PairAssetAccountInput) => void;
  isLoading: boolean;
  item: UpdateAssetAccountInput;
  paymentHistory?: Payment[];
  refetchItem?: () => void;
  assetId?: string
  assetAccountPaymentPlan?: any;
}

const AddItem: React.FC<IRTable> = ({
  handleSubmit,
  isLoading,
  item,
  paymentHistory = [],
  refetchItem = () => {},
  assetId=""
}) => {
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) => handleSubmit(values)}
            initialValues={item}
            validationSchema={assetAccountValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemForm
                formBag={
                  (formBag as unknown) as FormikProps<PairAssetAccountInput>
                }
                disableLoginButton={isLoading}
                paymentHistory={paymentHistory}
                refetchItem={refetchItem}
                assetId={assetId}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItem;
