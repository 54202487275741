import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import React, { useContext, useEffect, useMemo } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import { ServicerContext as Context } from '../context/ServicerProvider';
import ActionModal from '@components/Modal/ActionModal';
import { useAnyMutation } from 'hooks/useAnyMutation';
import { deleteServicerMutation } from '../mutation';
import { Edit } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { itemRoutes } from '../constants';
import useNotifier from 'hooks/useNotifier';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const history = useNavigate();
  const { refetchItems: refetch } = useContext(Context);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onComplete = () => {
    refetch && refetch();
    setIsModalOpen(false);
  }

  const { mutation: deleteItem, called } = useAnyMutation(deleteServicerMutation)

  useEffect(() => {
    if (called) {
      onComplete()
    } // eslint-disable-next-line
  }, [called])

  const assigned = useMemo(() => {
    return row.values['node.clientExclusiveService']?.length > 0
  }, [row.values])
  const notify = useNotifier()

  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id='expander'
        onClick={() => {
          history(itemRoutes.getEditLink(row.values['node._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <HoverDropdown
        isDistributor={true}
        menus={[
          {
            name: "",
            action: () => {
            },
          },
        ]}
      />
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() => {
          if (assigned) {
            return notify({ text: 'You cannot delete this servicer.', status: 'error' })
          }
          deleteItem({
            variables: { servicerId: row.values['node._id'] },

          })
        }
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        {!assigned ? <div>Are you sure you want to delete this servicer?</div> : null}
        {assigned ? <div>This servicer is assigned to a distributor. You are not allowed to delete it.</div> : null}
      </ActionModal>
    </div>
  );
};

export default TableActions;
