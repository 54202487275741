import styled from 'styled-components';

export const Styles = styled.div`
  overflow: scroll;

  .table {
    border-spacing: 0;
    .header {
      padding: 7px 5px;
      text-align: justify;
      .tr {        
        .th {
          border-top: 1px solid #ddd;
        }        
      } 
    }
    .sticky-column {
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;
    }
    .shadow-right {
      box-shadow: 3px 0 5px -2px rgba(0, 0, 0, 0.2);
    }

    .sticky-column:nth-child(2) {
      left: 30px;
    }

    .tr {  
      &.expanded {
        box-shadow: inset 0 0 10px #e8e0e0;
      }   
      &.-odd {
        background: rgba(0, 0, 0, 0.03);
       
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      .expanded-row {
        display: flex;
        margin-left: 5px;
      }
      .row-colon {
        padding-right: 5px;
      }
    }
    .th {
      background: #fff;
      padding: 7px 5px;
    }

    .th,
    .td {      
      overflow: hidden;
      border: 0;
      padding: 7px 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }
      .tr {
        .td {
          background: #fff;
          
        }
        &.-odd {
          .td {
            background: rgb(251 250 250 / 90%);
          }
        }
      }
      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        width: 40px;
      }

      [data-sticky-first-right-td] {
        box-shadow: -1.5px 0px 1.2px #ccc;
        text-align: center;
        width: 40px;
      }
    }
  }
  .sticky-footer {
    position: sticky;
    bottom: 0;
    width: 100%; /* Ensure it spans the full width */
    background: white;
    text-align: left;
    padding: 10px;
    border-top: 1px solid #ddd;
    z-index: 2; /* Ensure it appears above other content */
  }
`;