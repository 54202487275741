import { legacyApi } from 'apps/migration/app/services/legacyApi';
import { legacyTypes } from './constants';
export class LegacyActions {
  static listLegacy(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY,
      payload: { data },
    };
  }

  static listLegacySuccess(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY_SUCCESS,
      payload: { data },
    };
  }
  static listLegacyFail(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY_FAIL,
      payload: { data },
    };
  }

  static getLegacyBatch(params?: string) {
    return legacyApi.get(`product/model?${params}`);
  }
  static createLegacyRequest(payload: any) {
    return legacyApi.post(`product/model`, payload);
  }

  static getOneItem(id: string) {
    return legacyApi.get(`product/model/${id}`);
  }

  static updateLegacyItem(id: string, payload: any) {
    return legacyApi.put(`product/model/${id}`, payload);
  }
  static deleteItem(id: string) {
    return legacyApi.delete(`product/model/${id}`);
  }

  static migrateProductModel(id: string) {
    return legacyApi.post(`product-model/migrate/${id}`);
  }
}
