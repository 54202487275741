import React, { useContext, useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/itemTableData';
import CardWrapper from '@components/Card/CardWrapper';
import { DistributorContext } from '../context/DistributorProvider';
import { useAuth } from 'admin/auth/context/AuthProvider';

interface IRTable { }

const ItemPage: React.FC<IRTable> = () => {
  const { isDistributor } = useAuth();
  const { items, tableActions, getItems, loading, paging, setSearchQuery, goTo, setPageLimit, searchQuery } = useContext(
    DistributorContext
  );

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isDistributor) return;
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              itemTableData().header as Column[]
            }
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={'/admin/users/add'}
            addItemBtnText='Add'
            tableActions={tableActions}
            hideRightToolbar={isDistributor}
            paging={paging}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            goTo={goTo}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ItemPage;
