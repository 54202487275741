import { Grid } from '@material-ui/core';
import { Form, FormikProps, Field } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { UpdateClientPersonInput } from '../../types/clientServiceGlobalTypes';
import PhoneNumberInput from '@components/PhoneNumber/PhoneNumber';

const ItemForm: React.FC<{
  formBag: FormikProps<UpdateClientPersonInput>;
  disableLoginButton: boolean;
  actionButtonName?: string;
}> = (props) => {
  const { formBag, disableLoginButton, actionButtonName = 'Add' } = props;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridHalfWrapper>
              <TextInput
                label='Name'
                name={itemFields.clientPersonName}
                type='email'
                required
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Email'
                name={itemFields.email}
                type='email'
                required
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <Field name={itemFields.phone}>
                {({ field, form }: { field: { value: string }; form:any }) => (
                  <PhoneNumberInput
                    label="Phone"
                    value={field.value}
                    onChange={(phone) =>
                      formBag.setFieldValue(itemFields.phone, phone)
                    }
                    error={
                      form.errors?.clientContactPerson?.phone && form.touched?.clientContactPerson?.phone
                        ? form.errors?.clientContactPerson?.phone
                        : ''
                    } // Show error if touched and error exists
                  />
                  
                )}
              </Field>
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Social' name={itemFields.social} />
            </GridHalfWrapper>
          </GridContainer>
          <GridContainer>
            <GridHalfWrapper>
              <TextInput label='city' name={itemFields.city} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Country' name={itemFields.country} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Postcode' name={itemFields.postcode} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='srpc' name={itemFields.srpc} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='street' name={itemFields.street} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='unit' name={itemFields.unit} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Longitude'
                name={itemFields.inputAddressLongitude}
                type='number'
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Latitude'
                name={itemFields.inputAddressLatitude}
                type='number'
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Description'
                name={itemFields.clientPersonDescription}
                multiline
              />
            </GridHalfWrapper>
          </GridContainer>

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
