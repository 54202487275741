import CardWrapper from '@components/Card/CardWrapper';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import {
  ANGAZA_PROVISION_CREDENTIALS,
  ANGAZA_PROVISION_ENDPOINT,
  PROXY_URL,
} from 'config';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { ProvisionDataContext } from './context/ProvisionDataContext';
import PreviewTable from './PreviewTable/PreviewTable';
import CSVUploader from './Uploader/CSVUploader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/epics/userPrefEpics';
import { ProvisionActions } from 'store/actions/provisionActions';
import BackDrop from '@components/Loaders/BackDrop';
import { delayMyPromise } from '../utils';
import axios from 'axios';
const ProvisionContainer = () => {
  const { setData } = React.useContext(ProvisionDataContext);
  const { enqueueSnackbar } = useSnackbar();

  const first = 10;
  const dispatch = useDispatch();
  const data = useSelector((store: RootState) => store.provision.items.data);
  const progress = useSelector((store: RootState) => store.provision.progress);
  const [loading, setLoading] = useState(false);
  const [percentageProgress, setPercentageProgress] = useState(0);

  useEffect(() => {
    setData(data); // eslint-disable-next-line
  }, data);

  useEffect(() => {
    if (progress?.data?.loopIndex && progress?.data?.totalLoops)
      setPercentageProgress(
        parseInt(
          (
            (progress.data.loopIndex * 100) /
            progress.data.totalLoops
          ).toString()
        )
      ); // eslint-disable-next-line
  }, [progress]);

  const provision = ({ loopIndex = 0, pData = data }) => {
    setLoading(true);

    const tempData = [
      ...data?.filter((item: any) => {
        return item.productitemid && item.product_qid && !item.angaza_id;
      }),
    ];

    const totalLoops = parseInt((tempData.length / first).toString());

    Promise.all(
      tempData
        .slice(loopIndex * first, loopIndex * first + first)
        .map((item: any, index) => {
          const formData = JSON.stringify({
            product_qid: item.product_qid,
            productId: item.productitemid,
          });

          return delayMyPromise(
            axios
              .post(PROXY_URL + ANGAZA_PROVISION_ENDPOINT, formData, {
                auth: {
                  username: ANGAZA_PROVISION_CREDENTIALS.username,
                  password: ANGAZA_PROVISION_CREDENTIALS.password,
                },
                timeout: 10000,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Headers': '*',
                  'Access-Control-Allow-Credentials': 'true',
                  'Content-type': 'application/json',
                  withCredentials: true,
                },
              })
              .then((res) => {
                return {
                  angaza_id: res.data?.unit_number || '',
                  productitemoem_sn: item.productitemoem_sn,
                  index,
                };
              })
              .catch((error) => {
                const err = error?.response?.data?.context?.reason;

                if (err) {
                  return {
                    angaza_id: err.split(' ').slice(-1).pop() || '',
                    productitemoem_sn: item.productitemoem_sn,
                    index,
                  };
                }
              }),
            10000
          );
        })
    )
      .then((res) => {
        _.pullAllBy(
          res,
          [{ productitemoem_sn: null }, undefined],
          'productitemoem_sn'
        );
        let allData = [...pData];
        res?.map((instance: any) => {
          let itemIndex = _.findIndex(allData, {
            // @ts-ignore
            productitemoem_sn: instance.productitemoem_sn,
          });
          if (itemIndex !== -1) {
            allData.splice(itemIndex, 1, {
              ...allData[itemIndex], // @ts-ignore
              angaza_id: instance.angaza_id,
              loopIndex,
            });
          }
          return instance
        });
        dispatch(ProvisionActions.setProvisionItems(allData));
        dispatch(
          ProvisionActions.setProgress({
            totalLoops,
            loopIndex,
          })
        );

        if (loopIndex < totalLoops - 1) {
          provision({ loopIndex: loopIndex + 1, pData: allData });
        } else {
          // reset progress

          setLoading(false);
          enqueueSnackbar('Successfully provisioned products.', {
            variant: 'success',
          });
          dispatch(
            ProvisionActions.setProgress({
              totalLoops: 0,
              loopIndex: 0,
            })
          );
        }
      })
      .catch((error) => {
        console.warn(error);
        setLoading(false);
      });
  };

  const RenderLoadingProgress = () => {
    return (
      <div>
        <span>Angaza provisioning... {percentageProgress}% complete</span>
      </div>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <BackDrop loading={loading}>
          <RenderLoadingProgress />
        </BackDrop>
        <CardWrapper>
          <CSVUploader />
          <PreviewTable
            provision={() => provision({ loopIndex: 0, pData: data })}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ProvisionContainer;
