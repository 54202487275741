import DescriptionCell from '@components/ReactTable/DescriptionCell';
import ImageCell from 'apps/ecommerce/app/products/components/TableCells/ImageCell';
import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyTableActions';

export const legacyTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <Actions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    { Header: 'productFamilyID', accessor: 'productFamilyID' },
    {
      Header: 'productFamilyName',
      accessor: 'productFamilyName',
    },
    {
      Header: 'productFamilyDescription',
      accessor: 'productFamilyDescription',
      Cell: DescriptionCell,
    },
    {
      Header: 'Image',
      accessor: 'image',
      Cell: ImageCell,
    },
    {
      Header: 'productCategoryName',
      accessor: 'productCategoryName',
    },
  ],
};
