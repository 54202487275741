import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { legacyRoutes as itemRoutes } from './constants';
import List from "./containers/LegacyContainer";
interface IProps { }

export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
  ],
};
const LegacyAssignedItemIndex: React.FC<IProps> = () => (
  <DefaultOutlet
  />
);

export default LegacyAssignedItemIndex;
