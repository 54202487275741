import CardGridLoader from '@components/Loaders/CardGridLoader';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  PairAssetAccountInput,
  UpdateAssetAccountInput,
} from '../../types/accountsGlobalTypes';
import AddItem from '../components/AddItem';
import { defaultItem } from '../constants';
import { AssetAccountContext } from '../context/AssetAccountProvider';
const EditItemContainer = () => {
  const { itemId = '' } = useParams();

  const {
    getItem,
    item: _item,
    itemLoading: loading,
    refetchItem: refetchAssetAccount,
  } = useContext(AssetAccountContext);
  const [item, setItem] = React.useState({} as UpdateAssetAccountInput);

  const refetchItem = () => {
    if (refetchAssetAccount) {
      refetchAssetAccount();
    }
    
  };

 
  useEffect(() => {
  
    if (!_item?._id) {
      return
    }
    setItem({
      credit: {
        balance: _item?.credit?.balance || 0,
        currency: _item?.credit?.currency || defaultItem.credit.currency,
        customerId: _item?.credit?.owner?._id || defaultItem.credit.customerId,
      },
      assetAccountId: itemId,

    }); 
    // eslint-disable-next-line
  }, [_item]);

  React.useEffect(() => {

    if (!_item?._id && itemId) {

      getItem({ variables: { id: itemId } });
    } // eslint-disable-next-line
  }, []);

  const handleUpdate = (updateAssetAccountInput: PairAssetAccountInput) => { };

  if (!_item?._id) return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleUpdate}
      isLoading={loading}
      item={item}
      refetchItem={refetchItem}
    />
    
  );
};

export default EditItemContainer;
