import { IProps } from '@components/messages/types';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import useNotifier from 'hooks/useNotifier';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-table';
import { RootState } from 'store/epics/userPrefEpics';
import { LegacyActions } from '../redux/actions';
export interface ILegacyModel {
  productModelID: string;
  productModelName: string;
  productFamilyID: string;
  productVendor: string;
  productModelDescription: string;
  quantityInStock: string;
  productModelCost: string;
  productFamilyName: string;
  image: string;
  productcategoryname: string;
}
export interface ILegacyProtocolVersionContext {
  items: ILegacyModel[];
  setItems: (item: any) => void;
  loading: boolean;
  getItems: () => void;
  tableActions: ITableActions[];
}

export const LegacyProtocolVersionContext = createContext<
  ILegacyProtocolVersionContext
>({} as ILegacyProtocolVersionContext);

const LegacyProtocolVersionProvider: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const itemsSelector = useSelector(
    (state: RootState) => state.legacyProtocolVersion.items
  );
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);
  const notify = useNotifier();

  const isProgress = useSelector(
    (state: RootState) => state.legacyProtocolVersion.isProgress
  );
  const [items, setItems] = useState<ILegacyModel[]>([]);

  useEffect(() => {
    if (itemsSelector.data.length) {
      setItems(itemsSelector.data);
    }
  }, [itemsSelector]);
  // context

  const getItems = () => {
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacyBatch()
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  const multiMigrate = (protocols: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      protocols.map((it) =>
        LegacyActions.migrateProtocolVersions(it.values['versionID'])
      )
    )
      .then((res) => {
        setTableActionLoading(false);
        notify({
          status: 'success',
          text: 'Migrated successfully',
        });
      })
      .catch((err) => setTableActionLoading(false));
  };

  const value = useMemo(
    () => ({
      items,
      setItems,
      loading: isProgress,
      getItems,
      tableActions: [
        {
          action: multiMigrate,
          title: 'Migrate',
          description: (arg: string) =>
            `Are you sure you want to migrate ${arg} item(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, isProgress]
  );

  return (
    <LegacyProtocolVersionContext.Provider value={value}>
      {children}
    </LegacyProtocolVersionContext.Provider>
  );
};

export default LegacyProtocolVersionProvider;
