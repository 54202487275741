import { gql, useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import {
  DeleteClientExclusiveService,
  DeleteClientExclusiveServiceVariables,
} from './types/DeleteClientExclusiveService';
import {
  UpdateClientExclusiveService,
  UpdateClientExclusiveServiceVariables,
} from './types/UpdateClientExclusiveService';

/**
 * updateClientExclusiveService(
updateClientExclusiveServiceInput: UpdateClientExclusiveServiceInput!
): ClientExclusiveService!
 */
const updateClientExclusiveServiceMutation = gql`
  mutation UpdateClientExclusiveService(
    $updateClientExclusiveServiceInput: UpdateClientExclusiveServiceInput!
  ) {
    updateClientExclusiveService(
      updateClientExclusiveServiceInput: $updateClientExclusiveServiceInput
    ) {
      _id
    }
  }
`;

export const useUpdateClientExclusiveServiceMutation = ({
  onComplete,
}: {
  onComplete?: () => void;
}) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UpdateClientExclusiveService,
    UpdateClientExclusiveServiceVariables
  >(updateClientExclusiveServiceMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Deleted successfully', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
    error: mutationOpts.error,
  };
};

/**
 * deleteClientExclusiveService(
ClientExclusiveServiceId: String!
): String!
 */
const deleteClientExclusiveServiceMutation = gql`
  mutation DeleteClientExclusiveService($clientExclusiveServiceId: String!) {
    deleteClientExclusiveService(
      clientExclusiveServiceId: $clientExclusiveServiceId
    )
  }
`;

export const useDeleteClientExclusiveServiceMutation = ({
  onComplete,
}: {
  onComplete?: () => void;
}) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    DeleteClientExclusiveService,
    DeleteClientExclusiveServiceVariables
  >(deleteClientExclusiveServiceMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Deleted successfully', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    loading: mutationOpts.loading,
    error: mutationOpts.error,
  };
};
