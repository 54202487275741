import React from 'react';
import ResetPasswordPage from 'admin/auth/components/ResetPassword/ResetPasswordPage';

interface IProps {}

const ResetPassword: React.FC<IProps> = () => {
  return <ResetPasswordPage />;
};

export default ResetPassword;
