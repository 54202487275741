import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { updatePublisherMutation } from '../mutations';
import {
  UpdatePublisher,
  UpdatePublisherVariables,
} from '../types/UpdatePublisher';

const useUpdateItem = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    UpdatePublisher,
    UpdatePublisherVariables
  >(updatePublisherMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      } else {
        notify({ text: 'Updated successfully', status: 'success' });
      }
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useUpdateItem;
