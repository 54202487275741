import { gql, useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { handleGQLErrors } from 'utils/gqlErrors';
import { successFragment } from '../common/queries';
import { categorySelectorFragment } from './queries';
import { CreateCategorySelector, CreateCategorySelectorVariables } from './types/CreateCategorySelector';
import { DeleteCategorySelector, DeleteCategorySelectorVariables } from './types/DeleteCategorySelector';
import { UpdateCategorySelector, UpdateCategorySelectorVariables } from './types/UpdateCategorySelector';

/**
 * deleteCategorySelector(
categorySelectorId: String!
): Success!
 */
const deleteCategorySelectorMutation = gql`
  ${successFragment}
  mutation DeleteCategorySelector($categorySelectorId: String!) {
    deleteCategorySelector(categorySelectorId: $categorySelectorId) {
      ...Success
    }
  }
`;

/**
 * updateCategorySelector(
updateCategorySelectorInput: UpdateCategorySelectorInput!
): CategorySelector!
 */
const updateCategorySelectorMutation = gql`
  ${categorySelectorFragment}
  mutation UpdateCategorySelector(
    $updateCategorySelectorInput: UpdateCategorySelectorInput!
  ) {
    updateCategorySelector(
      updateCategorySelectorInput: $updateCategorySelectorInput
    ) {
      ...CategorySelector
    }
  }
`;

/**
 * createCategorySelector(
createCategorySelectorInput: CreateCategorySelectorInput!
): CategorySelector!
 */
const createCategorySelectorMutation = gql`
  ${categorySelectorFragment}
  mutation CreateCategorySelector(
    $createCategorySelectorInput: CreateCategorySelectorInput!
  ) {
    createCategorySelector(
      createCategorySelectorInput: $createCategorySelectorInput
    ) {
      ...CategorySelector
    }
  }
`;


export const useDeleteCategorySelector = (callBack: () => void) => {
  const [deleteCategory, deleteCategoryOpts] = useMutation<
    DeleteCategorySelector,
    DeleteCategorySelectorVariables
  >(deleteCategorySelectorMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    deleteCategory,
    deleteCategoryOpts,
  };
};


export const useUpdateCategorySelector = (callBack: () => void) => {
  const notify = useNotifier()
  const [updateCategory, updateCategoryOpts] = useMutation<
    UpdateCategorySelector,
    UpdateCategorySelectorVariables
  >(updateCategorySelectorMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
      err && handleGQLErrors(notify, err)
    },
  });

  return {
    updateCategory,
    updateCategoryOpts,
  };
};


export const useCreateCategorySelector = (callBack: (success?: boolean) => void) => {
  const [createCategory, createCategoryOpts] = useMutation<
    CreateCategorySelector,
    CreateCategorySelectorVariables
  >(createCategorySelectorMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack(true);
    },
    onError: (err) => {
      console.error(err);
      callBack && callBack(false);
    },
  });

  return {
    createCategory,
    createCategoryOpts,
  };
};