import React from 'react';
import { legacyRoutes } from './constants';
import List from "./containers/LegacyModel";

import Add from "./containers/AddItemContainer";
import Edit from "./containers/EditItemContainer";
import DefaultOutlet from "routes/DefaultOutlet";
interface IProps {}

export const routes = {
  path: legacyRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: legacyRoutes.add, element: <Add /> },
    { path: legacyRoutes.edit, element: <Edit /> },
  ],
};

const LegacyProtocolVersionIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};


export default LegacyProtocolVersionIndex;
