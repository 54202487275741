import React from 'react';
import { USER_TYPES } from 'admin/auth/constants/auth';
// @material-ui/icons
import DnsIcon from '@material-ui/icons/Dns';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SettingsIcon from '@material-ui/icons/Settings';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import LegacyItemIndex from 'apps/migration/app/LegacyItem';
import LegacyProtocolVersionIndex from 'apps/migration/app/LegacyProtocolVersion';
import LegacyDistributorIndex from 'apps/migration/app/LegacyDistributor';
import ResetPassword from 'admin/auth/containers/ResetPassword';
import ItemIndex from 'apps/things/app/item';
import LegacyModelIndex from 'apps/migration/app/LegacyModel';
import ResetPasswordToken from 'admin/auth/containers/ResetPasswordToken';
import ItemBatchIndex from 'apps/things/app/batch';
import DashboardIndex from 'apps/things/app/dashboard';
import TigStackIndex from 'apps/things/app/tigstack';
const CheckInIndex = React.lazy(() => import('apps/things/app/checkInOut'));
const ClientExclusiveServiceIndex = React.lazy(() => import('apps/clientService/app/clientExclusiveServicer'))
// const LegacyProtocolVersionIndex = React.lazy(() => import('apps/migration/app/LegacyProtocolVersion'));
const Register = React.lazy(() => import('admin/auth/containers/Register'));
const StaffAgentRegister = React.lazy(() => import('admin/auth/containers/StaffAgentRegister'))
const Login = React.lazy(() => import('admin/auth/containers/Login'));
const Agent = React.lazy(() => import('admin/agents/containers/Agent'));
const Role = React.lazy(() => import('admin/Role/containers/Role'));
const ServicerIndex = React.lazy(() => import('apps/clientService/app/servicer'))
const ClientServiceIndex = React.lazy(() => import('apps/clientService/app/clientService'))
const CodeGeneratorIndex = React.lazy(() =>
  import('apps/things/app/codeGenerator')
);
const ItemSKUIndex = React.lazy(() => import('apps/things/app/sku'));
// const ClientServicePermissionIndex = React.lazy(() => import('apps/clientService/app/clientServicePermissions'));

const ItemFirmwareIndex = React.lazy(() => import('apps/things/app/firmware'));

const AppsRoutes = React.lazy(() => import('apps/support'));

const LegacyBatchIndex = React.lazy(() =>
  import('apps/migration/app/LegacyBatch')
);

const LegacyCategoryIndex = React.lazy(() =>
  import('apps/migration/app/LegacyCategory')
);
const LegacyFamilyIndex = React.lazy(() =>
  import('apps/migration/app/LegacyFamily')
);
const LegacyAssignedItemIndex = React.lazy(() =>
  import('apps/migration/app/LegacyAssignedItems')
);
const LegacyCodeHistoryIndex = React.lazy(() =>
  import('apps/migration/app/LegacyCodeHistory')
);
const FleetIndex = React.lazy(() => import('apps/things/app/fleet'));
const LoginUserIndex = React.lazy(() => import('admin/loginUser'));

const CustomerIndex = React.lazy(() =>
  import('apps/clientService/app/customer')
);

const AgentsIndex = React.lazy(() => 
  import('apps/clientService/app/agents')
)

const DistributorStaffIndex = React.lazy(() => 
  import('apps/clientService/app/distributorStaff')
)
const AssetAccountIndex = React.lazy(() =>
  import('apps/accounts/app/assetAccount')
);
const PaymentsIndex = React.lazy(() =>
  import('apps/accounts/app/payments')
);
const PayPlanTemplateIndex = React.lazy(() =>
  import('apps/accounts/app/payPlanTemplate')
);
const AssetAccountTempDataIndex = React.lazy(() =>
  import('apps/accounts/app/angazaReview')
);
const MessageGroupIndex = React.lazy(() => 
import('apps/accounts/app/messageGroup')
);
const MessageTemplateIndex = React.lazy(() => 
  import('apps/accounts/app/mesageTemplate')
);

const DashboardReportIndex = React.lazy(() => 
  import('apps/accounts/app/dashboardReports')
);

const DistributorIndex = React.lazy(() =>
  import('apps/clientService/app/distributor')
);
const OrganizationIndex = React.lazy(() =>
  import('apps/clientService/app/organization')
);

const SupplierIndex = React.lazy(() =>
  import('apps/clientService/app/supplier')
);

const LegacyJobIndex = React.lazy(() => import('apps/migration/app/LegacyJob'));
const ERMJobIndex = React.lazy(() => import('apps/migration/app/ScanningICCJob'))
const EventIndex = React.lazy(() => import('apps/events/app/events'));
const ItemEventIndex = React.lazy(() => import('apps/events/app/itemEvents'));
const SignalIndex = React.lazy(() => import('apps/events/app/signal'));
const BrokerIndex = React.lazy(() => import('apps/events/app/broker'));
const PublisherIndex = React.lazy(() => import('apps/events/app/publisher'));
const ThingBrokerIndex = React.lazy(() => import('apps/things/app/broker'));
const AvatarIndex = React.lazy(() => import('apps/things/app/avatar'));
const OpenTokenSimulatorIndex = React.lazy(() => import('apps/things/app/openTokenSimulator'))
const dashRoutes = [
  {
    path: '/login-page',
    name: 'Login Page',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: Login,
    layout: '/auth',
  },
  {
    path: '/register-page/:token',
    name: 'Register Page',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: Register,
    layout: '/auth',
  },
  {
    path: '/register-page/distributor_staff/:token',
    name: 'Register Page',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: StaffAgentRegister,
    layout: '/auth',
  },
  {
    path: '/passwordreset/:token',
    name: 'Reset Password Page',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: ResetPasswordToken,
    layout: '/auth',
  },
  {
    path: '/reset-password-page',
    name: 'Reset Password Page',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: ResetPassword,
    layout: '/auth',
  },
  {
    path: '/check/auth/check-in',
    name: 'Check In',
    mini: 'C',
    component: CheckInIndex,
    layout: '/auth',
  },
  {
    collapse: true,
    path: '/settings',
    name: 'My Settings',
    rtlName: 'الحاجيات',
    icon: SettingsIcon,
    state: 'settings',
    permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR],
    views: [
      {
        path: '/distributors/settings',
        name: 'Code Generation',
        rtlName: 'عالتسعير',
        mini: 'C',
        rtlMini: 'ع',
        component: DistributorIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR],
      },
      {
        path: '/profile',
        name: 'Profile',
        rtlName: 'عالتسعير',
        mini: 'P',
        rtlMini: 'ع',
        component: ItemSKUIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/workspace',
        name: 'Workspace',
        rtlName: 'عالتسعير',
        mini: 'W',
        rtlMini: 'ع',
        component: ItemSKUIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/security',
        name: 'Security',
        rtlName: 'عالتسعير',
        mini: 'S',
        rtlMini: 'ع',
        component: ItemSKUIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },

    ],
  },
  {
    collapse: true,
    path: '/identity',
    name: 'Identity',
    rtlName: 'الحاجيات',
    icon: FingerprintIcon,
    state: 'identity',
    permissions: [USER_TYPES.SUPER_ADMIN],
    views: [
      {
        path: '/organizations',
        name: 'Organization',
        rtlName: 'عالتسعير',
        mini: 'O',
        rtlMini: 'ع',
        component: OrganizationIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/users',
        name: 'Users',
        rtlName: 'عالتسعير',
        mini: 'U',
        rtlMini: 'ع',
        component: LoginUserIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/roles',
        name: 'Roles and Permissions',
        rtlName: 'عالتسعير',
        mini: 'R',
        rtlMini: 'ع',
        component: Role,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
    ],
  },
  //
  {
    collapse: true,
    path: '/client',
    name: 'Client',
    rtlName: 'الحاجيات',
    icon: RecentActorsIcon,
    state: 'client',
    permissions: [USER_TYPES.SUPER_ADMIN],
    views: [
      {
        path: '/distributors',
        name: 'Distributors',
        rtlName: 'الحاجيات',
        mini: 'D',
        component: DistributorIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/suppliers',
        name: 'Suppliers',
        rtlName: 'الحاجيات',
        mini: 'S',
        component: SupplierIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/admin-customers',
        name: 'Customers',
        rtlName: 'الحاجيات',
        mini: 'C',
        component: Agent,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/servicer',
        name: 'Servicers',
        rtlName: '---',
        mini: 'S',
        component: ServicerIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {

        path: '/client-service',
        name: 'Servicer Client',
        rtlName: '---',
        mini: 'A',
        component: ClientServiceIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      }
    ],
  },
  {
    collapse: true,
    path: '/accounts',
    name: 'Accounts',
    rtlName: 'الحاجيات',
    icon: ApartmentIcon,
    state: 'accounts',
    permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF, USER_TYPES.GENERAL_AGENT],
    views: [
      {
        path: '/client-exclusive-servicer',
        name: 'Client Exclusive Servicer',
        rtlName: '---',
        mini: 'C',
        component: ClientExclusiveServiceIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SERVICER]
      },
      {
        path: '/thing-fleet/client-fleet',
        name: 'Fleets',
        rtlName: 'عالتسعير',
        mini: 'F',
        rtlMini: 'ع',
        component: FleetIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/item/distributor-items',
        name: 'Items',
        rtlName: 'عالتسعير',
        mini: 'I',
        rtlMini: 'ع',
        component: ItemIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF, USER_TYPES.GENERAL_AGENT],
      },
      {
        path: '/customers',
        name: 'Customer',
        rtlName: 'عالتسعير',
        mini: 'C',
        rtlMini: 'ع',
        component: CustomerIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.DISTRIBUTOR_STAFF, USER_TYPES.GENERAL_AGENT],
      },
      {
        path: '/distributor-staff',
        name: 'Distributor Staff',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: DistributorStaffIndex,
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR],
      },
      {
        path: '/agents',
        name: 'Agents',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: AgentsIndex,
        layout: '/admin',
        permissions: [ USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/asset-accounts',
        name: 'Asset Accounts',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: AssetAccountIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SERVICER, USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR_STAFF, USER_TYPES.GENERAL_AGENT],
      },
      {
        path: '/payments',
        name: 'Payments',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: PaymentsIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/payplan-template',
        name: 'PayPlan Template',
        rtlName: 'عالتسعير',
        mini: 'P',
        rtlMini: 'ع',
        component: PayPlanTemplateIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/message-group',
        name: 'Message Group',
        rtlName: 'عالتسعير',
        mini: 'M',
        rtlMini: 'ع',
        component: MessageGroupIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/message-template',
        name: 'Message Template',
        rtlName: 'عالتسعير',
        mini: 'T',
        rtlMini: 'ع',
        component: MessageTemplateIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR_STAFF],
      },

      {
        path: '/angaza-review',
        name: 'Angaza Review',
        rtlName: 'عالتسعير',
        mini: 'R',
        rtlMini: 'ع',
        component: AssetAccountTempDataIndex,
        layout: '/admin',
        permissions: [USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/dashboard-report',
        name: 'Dashboard Report',
        rtlName: 'عالتسعير',
        mini: 'D',
        rtlMini: 'ع',
        component: DashboardReportIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/opentoken-simulator',
        name: 'OpenToken Simulator ',
        mini: 'O',
        component: OpenTokenSimulatorIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },

    ],
  },
  {
    collapse: true,
    path: '/thing',
    name: 'Thing',
    rtlName: 'الحاجيات',
    icon: DnsIcon,
    state: 'thing',
    permissions: [USER_TYPES.SUPER_ADMIN],
    views: [
      {
        path: '/dashboard',
        name: 'Overview Dashboard',
        rtlName: 'عالتسعير',
        mini: 'D',
        rtlMini: 'ع',
        component: DashboardIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/sku',
        name: 'Item SKUs',
        rtlName: 'عالتسعير',
        mini: 'S',
        rtlMini: 'ع',
        component: ItemSKUIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },

      {
        path: '/batch',
        name: ' Item Batch',
        rtlName: 'عالتسعير',
        mini: 'B',
        rtlMini: 'ع',
        component: ItemBatchIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/thing-fleet',
        name: ' Item Fleet',
        rtlName: 'عالتسعير',
        mini: 'F',
        rtlMini: 'ع',
        component: FleetIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/firmware',
        name: ' Item Firmware',
        rtlName: 'عالتسعير',
        mini: 'F',
        rtlMini: 'ع',
        component: ItemFirmwareIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },

      {
        path: '/item',
        name: 'Items',
        rtlName: 'عالتسعير',
        mini: 'I',
        rtlMini: 'ع',
        component: ItemIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/codegen',
        name: 'Code Generator',
        mini: 'C',
        component: CodeGeneratorIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/thing-broker',
        name: 'Broker',
        mini: 'B',
        component: ThingBrokerIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/avatar',
        name: 'Avatar',
        mini: 'A',
        component: AvatarIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/check',
        name: 'Check Out',
        mini: 'C',
        component: CheckInIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/check/in',
        name: 'Check In',
        mini: 'C',
        component: CheckInIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SHOW_STANDALONE, USER_TYPES.SUPER_ADMIN],
      },
    ],
  },

  {
    collapse: true,
    path: '/events',
    name: 'Event',
    rtlName: 'الحاجيات',
    icon: EventNoteIcon,
    state: 'events',
    permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
    views: [
      {
        path: '/events-events',
        name: 'Events',
        rtlName: 'الحاجيات',
        component: EventIndex,
        mini: 'E',
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },

      {
        path: '/events-items',
        name: 'Item Events',
        rtlName: 'الحاجيات',
        component: ItemEventIndex,
        mini: 'I',
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/events-signal',
        name: 'Signal',
        rtlName: 'الحاجيات',
        component: SignalIndex,
        mini: 'S',
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/events-broker',
        name: 'Broker',
        rtlName: 'عالتسعير',
        mini: 'B',
        rtlMini: 'ع',
        component: BrokerIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
      {
        path: '/events-publisher',
        name: 'Publisher',
        rtlName: 'عالتسعير',
        mini: 'P',
        rtlMini: 'ع',
        component: PublisherIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.DISTRIBUTOR, USER_TYPES.DISTRIBUTOR_STAFF],
      },
    ],
  },
  //
  {
    collapse: true,
    path: '/migration',
    name: 'Migration',
    rtlName: 'الحاجيات',
    icon: ImportExportIcon,
    layout: '/admin',
    state: 'migrations',
    permissions: [USER_TYPES.SUPER_ADMIN, USER_TYPES.EXCLUDE_STANDALONE],
    views: [
      {
        path: '/legacy-batch',
        name: 'Legacy Batches',
        rtlName: 'عالتسعير',
        mini: 'B',
        rtlMini: 'ع',
        component: LegacyBatchIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy-item',
        name: 'Legacy Items',
        rtlName: 'عالتسعير',
        mini: 'I',
        rtlMini: 'ع',
        component: LegacyItemIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy-assigned-items',
        name: 'Legacy Assigned Items',
        rtlName: 'عالتسعير',
        mini: 'A',
        rtlMini: 'ع',
        component: LegacyAssignedItemIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy-code-history',
        name: 'Legacy Code History',
        rtlName: 'عالتسعير',
        mini: 'C',
        rtlMini: 'ع',
        component: LegacyCodeHistoryIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy-distributor',
        name: 'Legacy Distributor',
        rtlName: 'عالتسعير',
        mini: 'D',
        rtlMini: 'ع',
        component: LegacyDistributorIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy/model',
        name: 'Legacy Models',
        rtlName: 'عالتسعير',
        mini: 'M',
        rtlMini: 'ع',
        component: LegacyModelIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy/family',
        name: 'Legacy Families',
        rtlName: 'عالتسعير',
        mini: 'F',
        rtlMini: 'ع',
        component: LegacyFamilyIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy/category',
        name: 'Legacy Category',
        rtlName: 'عالتسعير',
        mini: 'C',
        rtlMini: 'ع',
        component: LegacyCategoryIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy/protocol-version',
        name: 'Legacy Protocol Version',
        rtlName: 'عالتسعير',
        mini: 'P',
        rtlMini: 'ع',
        component: LegacyProtocolVersionIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/legacy-job',
        name: 'Jobs',
        rtlName: 'عالتسعير',
        mini: 'J',
        rtlMini: 'ع',
        component: LegacyJobIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/erm-job',
        name: 'ERM Jobs',
        rtlName: 'عالتسعير',
        mini: 'J',
        rtlMini: 'ع',
        component: ERMJobIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
    ],
  },

  //
  {
    collapse: true,
    path: '/support',
    name: 'Support',
    rtlName: 'الحاجيات',
    icon: ContactSupportIcon,
    state: 'support',
    layout: '/admin',
    permissions: [USER_TYPES.SUPER_ADMIN],
    views: [
      {
        path: '/apps',
        name: 'Mobile App Download',
        rtlName: 'هعذاتسجيل الدخول',
        mini: 'A',
        rtlMini: 'هعذا',
        component: AppsRoutes,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      },
      {
        path: '/tigstack',
        name: 'Tig Stack Download',
        rtlName: 'اسم بالاتجاه العكسي لتيج ستاك التنزيل',
        mini: 'T',
        rtlMini: 'هعذا',
        component: TigStackIndex,
        layout: '/admin',
        permissions: [USER_TYPES.SUPER_ADMIN],
      }
    ],
  },
];
export default dashRoutes;
