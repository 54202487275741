import gql from 'graphql-tag';

/**
 * createOrganization(
createOrganizationInput: CreateOrganizationInput!
): Organization!
 */
export const createOrganizationMutation = gql`
  mutation CreateOrganization(
    $createOrganizationInput: CreateOrganizationInput!
  ) {
    createOrganization(createOrganizationInput: $createOrganizationInput) {
      _id
    }
  }
`;

/**
 * updateOrganization(
updateOrganizationInput: UpdateOrganizationInput!
): Organization!
 */
export const updateOrganizationMutation = gql`
  mutation UpdateOrganization(
    $updateOrganizationInput: UpdateOrganizationInput!
  ) {
    updateOrganization(updateOrganizationInput: $updateOrganizationInput) {
      _id
    }
  }
`;

/**
 * 
Mutation.deleteOrganization(
organizationId: String!
): String!
 */
export const deleteOrganizationMutation = gql`
  mutation DeleteOrganization($organizationId: String!) {
    deleteOrganization(organizationId: $organizationId)
  }
`;
