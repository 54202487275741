import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { Row } from 'react-table';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';

import { useEffect } from 'react';

import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import {
  GetAllProductSelectorSelectors,
  GetAllProductSelectorSelectorsVariables,
  GetAllProductSelectorSelectors_getAllProductSelectorSelectors_page_edges,
} from '../types/GetAllProductSelectorSelectors';
import { useLazyGetAllProductSelectorQuery } from '../queries';
import { useDeleteProductSelector } from '../mutations';

export interface IProductSelectorContext {
  items: GetAllProductSelectorSelectors_getAllProductSelectorSelectors_page_edges[];
  setItems: (
    items: GetAllProductSelectorSelectors_getAllProductSelectorSelectors_page_edges[]
  ) => void;
  loading: boolean;
  tableActions: ITableActions[];
  refetch:
    | ((
        variables?: Partial<GetAllProductSelectorSelectorsVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllProductSelectorSelectors>>)
    | undefined;
  getItems: () => void;
}

export const ProductSelectorContext = React.createContext<
  IProductSelectorContext
>({} as IProductSelectorContext);

const ProductSelectorProvider: React.FC<IProps> = ({ children }) => {
  // state
  const [items, setItems] = React.useState<
    GetAllProductSelectorSelectors_getAllProductSelectorSelectors_page_edges[]
  >(
    [] as GetAllProductSelectorSelectors_getAllProductSelectorSelectors_page_edges[]
  );

  // context
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  // hooks
  const notify = useNotifier();
  const [
    getItems,
    { data, refetch, loading },
  ] = useLazyGetAllProductSelectorQuery({
    first: 100,
  });

  useEffect(() => {
    if (data) setItems(data?.getAllProductSelectorSelectors?.page?.edges || []);
  }, [data]);

  const { deleteProductSelector } = useDeleteProductSelector(() => {});

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
      deleteProductSelector({
          variables: {
            productSelectorId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Category selector deleted successfully',
        });
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllProductSelectorSelectors?.page?.edges ||
              items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllProductSelectorSelectors?.page?.edges ||
              items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      refetch,
      getItems,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} product selector(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading]
  );

  return (
    <ProductSelectorContext.Provider value={value}>
      {children}
    </ProductSelectorContext.Provider>
  );
};

export default ProductSelectorProvider;
