import React from 'react';
import moment from 'moment';
// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik } from 'formik';
import { itemBatchFields } from '../constants';
import { CreateItemBatchInput } from 'apps/things/app/types/thingsGlobalTypes';
import { itemBatchFieldsValidationSchema } from 'apps/things/app/validations';
import ItemBatchForm from './ItemBatchForm';
import CardWrapper from '@components/Card/CardWrapper';
import { defaultProductType, getDefaultDate } from '../../utils';
interface IRTable {
  handleSubmit: (payload: CreateItemBatchInput) => void;
  isLoading: boolean;
  lastSequence: string;
}

const AddItemBatch: React.FC<IRTable> = ({
  handleSubmit,
  isLoading,
  lastSequence='001',
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleSubmit({
                batchNumber: values.batchNumber,
                batchDate: values.batchDate,
                description: values.description,
                itemSKUId: values.itemSKUId,
                code_gen_type: values.algorithmType,
                secret_key: values.secretKey || null,
                starting_code: values.startingCode || null,
              })
            }
            initialValues={{
              [itemBatchFields.batchNumber]: `${defaultProductType}${getDefaultDate()}${lastSequence}`,
              [itemBatchFields.batchDate]: moment().format(),
              [itemBatchFields.description]: '',
              [itemBatchFields.itemSKUId]: '',
            }}
            validationSchema={itemBatchFieldsValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemBatchForm
                formBag={formBag}
                disableLoginButton={isLoading}
                lastSequence={lastSequence}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItemBatch;
