import { AddressDto, PersonTypes } from '../../types/clientServiceGlobalTypes';

export const itemFields = {
  email: 'clientContactPerson.email',
  phone: 'clientContactPerson.phone',
  social: 'clientContactPerson.social',
  city: 'clientPersonAddress.city',
  country: 'clientPersonAddress.country',
  postcode: 'clientPersonAddress.postcode',
  srpc: 'clientPersonAddress.srpc',
  street: 'clientPersonAddress.street',
  unit: 'clientPersonAddress.unit',
  inputAddressLongitude:
    'clientPersonAddress.locationAddressDto.inputAddressLongitude',
  inputAddressLatitude:
    'clientPersonAddress.locationAddressDto.inputAddressLatitude',
  clientPersonDescription: 'clientPersonDescription',
  clientPersonName: 'clientPersonName',
  clientPersonType: 'clientPersonType',
};

export const defaultAddress = {
  city: '',
  country: '',
  locationAddressDto: {
    inputAddressLatitude: 0,
    inputAddressLongitude: 0,
  },
  postcode: '',
  srpc: '',
  street: '',
  unit: '',
};

export const defaultItem = {
  personId: '_',
  clientContactPerson: { email: '', phone: '', social: '' },
  clientPersonAddress: defaultAddress as AddressDto,
  clientPersonDescription: '',
  clientPersonName: '',
  clientPersonType: PersonTypes.CUSTOMER,
};

export const itemRoutes = {
  list: '/admin/customers',
  add: '/admin/customers/add',
  edit: '/admin/customers/:itemId',
  getEditLink: (itemId: string) => `/admin/customers/${itemId}`,
};
