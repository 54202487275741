import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import { GetAllAssetAccountsTempData, GetAllAssetAccountsTempDataVariables } from './types/GetAllAssetAccountsTempData';
/**
 * type AssetAccountTempData {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
status: AssetAccountProcessStatus!
data: JSONObject
csvData: JSONObject
sellerItemID: String!
itemID: String
assetAccountID: String
customerID: String
}
 */

const assetAccountTempDataFragment = gql`
fragment AssetAccountTempData on AssetAccountTempData {
  _id
  csvData
}
`
/**
 * type AssetAccountTempDataEdge {
cursor: String
node: AssetAccountTempData

 */

const assetAccountTempDataEdgeFragment = gql`
  ${assetAccountTempDataFragment}
  fragment AssetAccountTempDataEdge on AssetAccountTempDataEdge {
    cursor
    node {
      ...AssetAccountTempData
    }
  }
`;
/**
 * type AssetAccountTempDataPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const assetAccountTempDataPageInfoFragment = gql`
  fragment  AssetAccountTempDataPageInfo  on  AssetAccountTempDataPageInfo  {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type AssetAccountTempDataConnection {
edges: [AssetAccountTempDataEdge!]
pageInfo: AssetAccountTempDataPageInfo
}
 */
const assetAccountTempDataConnectionFragment = gql`
  ${assetAccountTempDataEdgeFragment}
  ${assetAccountTempDataPageInfoFragment}
  fragment AssetAccountTempDataConnection on AssetAccountTempDataConnection {
    edges {
      ...AssetAccountTempDataEdge
    }
    pageInfo {
      ...AssetAccountTempDataPageInfo
    }
  }
`;
/**
 * type PageData {
count: Int!
limit: Int!
offset: Int!
}
 */
const pageDataFragment = gql`
  fragment PageData on PageData {
    count
    limit
    offset
  }
`;
/**
 * type GetAllAssetAccountsTempDataResponse {
page: AssetAccountTempDataConnection!
pageData: PageData
}
 */
const getAllAssetAccountsTempDataResponse = gql`
  ${pageDataFragment}
  ${assetAccountTempDataConnectionFragment}
  fragment GetAllAssetAccountsTempDataResponse on GetAllAssetAccountsTempDataResponse {
    page {
      ...AssetAccountTempDataConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * Query.getAllItemEvents(
before: String
after: String
first: Int
last: Int
search: String
): GetallItemEventsResponse!
 */
const  getAllAssetAccountsTempDataQuery = gql`
  ${getAllAssetAccountsTempDataResponse}
  query GetAllAssetAccountsTempData(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $search: String
  ) {
    getAllAssetAccountsTempData(
      after: $after
      before: $before
      first: $first
      last: $last
      search: $search
    ) {
      ...GetAllAssetAccountsTempDataResponse
    }
  }
`;

export const useGetAllAssetAccountsTempDataQuery = (variables: GetAllAssetAccountsTempDataVariables) =>
  useQuery<GetAllAssetAccountsTempData, GetAllAssetAccountsTempDataVariables>(getAllAssetAccountsTempDataQuery, {
    client: clientAccount,
    variables,
  });

export const useLazyGetAllAssetAccountsTempDataQuery = (variables: GetAllAssetAccountsTempDataVariables) =>
  useLazyQuery<GetAllAssetAccountsTempData, GetAllAssetAccountsTempDataVariables>(getAllAssetAccountsTempDataQuery, {
    client: clientAccount,
    variables,
  });


