import CardWrapper from '@components/Card/CardWrapper';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import React from 'react';
import PreviewTable from './PreviewTable/PreviewTable';
import BatchCSVUploader from './Uploader/BatchCSVUploader';

const ProductBatchContainer = () => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <BatchCSVUploader />
          <PreviewTable />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default ProductBatchContainer;
