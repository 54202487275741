import CardWrapper from '@components/Card/CardWrapper';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import React, { useEffect, useMemo, useState } from 'react';
import { useLazyFleet } from '../../fleet/hooks/useFleet';
import { Formik, isEmptyArray } from 'formik';
import OVSForm from '@components/Form/OVSForm';
import Button from '@components/CustomButtons/Button';
import LinksTable, { Links } from './LinksTable';
import { useDownloadCredentials, useDownloadDistributorCodeGens, useDownloadDistributorDetails, useDownloadDistributorFleet, useDownloadDistributorItems } from '../hooks/useDownloadItems';
import EmptyState from './EmptyState';
import { useLazyDistributor } from 'apps/clientService/app/distributor/hooks/useDistributor';

const CheckInOutContainer = () => {
  const { query: getDistributorItems, data: distributorItems, loading: loadingDistributorItems } = useDownloadDistributorItems()
  const { query: getCredentialsLinks, data: credentialsLinks, loading: loadingCredentialsLinks } = useDownloadCredentials()
  const { query: getCodeGens, data: codeGens, loading: loadingCodeGens } = useDownloadDistributorCodeGens()
  const { query: getDistributorDetails, data: distributorDetailsLinks, loading: loadingDetails } = useDownloadDistributorDetails()
  const { query: getFleetsLinks, data: distributorFleetsLinks, loading: loadingFleetsLinks } = useDownloadDistributorFleet()
  const [fleetId, setFleetId] = useState('')
  const [fleetIdCodeGen, setFleetIdCodeGen] = useState('')
  const [distributorId, setDistributorId] = useState('')
  const [distributorIdItem, setDistributorIdItem] = useState('')
  const [distributorIdCred, setDistributorIdCred] = useState('')
  const { options: distributorOptions, data: distributors, search: searchDistributor, getItems: getDistributors } = useLazyDistributor()
  const { options, searchFleet, data: fleetItems, loading: loadingFleets, getItems: getFleets } = useLazyFleet()
  useEffect(() => {
    if (isEmptyArray(options)) {
      getFleets()
    }
    if (isEmptyArray(distributors)) {
      getDistributors()
    }  // eslint-disable-next-line
  }, [])

  const distributorCredentialFormFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            md: 6,
            type: 'select-async',
            name: 'distributorId',
            label: 'Distributor',
            options: distributorOptions,
            onChange: (e: any) => {

              setDistributorIdCred(e);
            },
            value: distributorIdCred || '',
            searchPromise: searchDistributor as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [distributorOptions, distributorIdCred, distributors])
  const distributorItemsFormFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            md: 6,
            type: 'select-async',
            name: 'distributorId',
            label: 'Distributor',
            options: distributorOptions,
            onChange: (e: any) => {

              setDistributorIdItem(e);
            },
            value: distributorIdItem || '',
            searchPromise: searchDistributor as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [distributorOptions, distributorIdItem, distributors])
  const distributorFormFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            md: 6,
            type: 'select-async',
            name: 'distributorId',
            label: 'Distributor',
            options: distributorOptions,
            onChange: (e: any) => {

              setDistributorId(e);
            },
            value: distributorId || '',
            searchPromise: searchDistributor as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [distributorOptions, distributorId, distributors])
  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            md: 6,
            type: 'select-async',
            name: 'fleetId',
            label: 'Distributor',
            options: distributorOptions,
            onChange: (e: any) => {

              setFleetId(e);
            },
            value: fleetId || '',
            searchPromise: searchDistributor as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [fleetItems, fleetId, options]
  );

  const formFieldsDataCodeGen = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            md: 6,
            type: 'select-async',
            name: 'fleetIdCodeGen',
            label: 'Fleet',
            options,
            onChange: (e: any) => {

              setFleetIdCodeGen(e);
            },
            value: fleetIdCodeGen || '',
            searchPromise: searchFleet as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [fleetItems, fleetIdCodeGen, options]
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>

        <CardWrapper>

          <GridContainer justify={'space-between'} className="align-baseline">
            <GridItem xs={12} sm={12} md={12}>
              <h5>Download Credentials</h5>
            </GridItem>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <OVSForm formFieldsData={distributorCredentialFormFieldsData} />
                  <GridItem sm={6}>
                    <Button
                      color='primary'
                      onClick={() => {
                        if (distributorIdCred) {
                          getCredentialsLinks({
                            variables: {
                              roleId: distributorIdCred
                            }
                          })
                        }
                      }}
                      disabled={loadingDetails || !distributorIdCred || loadingCredentialsLinks}
                    >
                      Get Links
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
            <GridItem md={12}>
              {!isEmptyArray(credentialsLinks?.downloadClientCreds || []) ? <LinksTable rows={credentialsLinks?.downloadClientCreds as Links[]} /> : (
                <EmptyState message='No links. Please select a distributor and click Get Links button.' />
              )}
            </GridItem>
          </GridContainer>
        </CardWrapper>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>

        <CardWrapper>

          <GridContainer justify={'space-between'} className="align-baseline">
            <GridItem xs={12} sm={12} md={12}>
              <h5>Download Details</h5>
            </GridItem>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <OVSForm formFieldsData={distributorFormFieldsData} />
                  <GridItem sm={6}>
                    <Button
                      color='primary'
                      onClick={() => {
                        if (distributorId) {
                          getDistributorDetails({
                            variables: {
                              clientId: distributorId
                            }
                          })
                        }
                      }}
                      disabled={loadingDetails || !distributorId}
                    >
                      Get Links
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
            <GridItem md={12}>
              {!isEmptyArray(distributorDetailsLinks?.downloadDistributorDetails || []) ? <LinksTable rows={distributorDetailsLinks?.downloadDistributorDetails as Links[]} /> : (
                <EmptyState message='No links. Please select a distributor and click Get Links button.' />
              )}
            </GridItem>
          </GridContainer>
        </CardWrapper>
      </GridItem>


      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>

          <GridContainer justify={'space-between'} className="align-baseline">
            <GridItem xs={12} sm={12} md={12}>
              <h5>Download Fleet Items</h5>
            </GridItem>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <OVSForm formFieldsData={formFieldsData} />
                  <GridItem sm={6}>
                    <Button
                      color='primary'
                      onClick={() => {
                        if (fleetId) {
                          getFleetsLinks({
                            variables: {
                              clientId: fleetId
                            }
                          })
                        }
                      }}
                      // style={{ marginLeft: 12 }}
                      disabled={loadingFleets || !fleetId || loadingFleetsLinks}
                    >
                      Get Links
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
            <GridItem md={12}>
              {!isEmptyArray(distributorFleetsLinks?.downloadDistributorFleet || []) ? <LinksTable rows={distributorFleetsLinks?.downloadDistributorFleet as Links[]} /> : (
                <EmptyState message='No links. Please select a fleet and click Get Links button.' />
              )}
            </GridItem>
          </GridContainer>
        </CardWrapper>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>

          <GridContainer justify={'space-between'} className="align-baseline">
            <GridItem xs={12} sm={12} md={12}>
              <h5>Download Distributor Items </h5>
            </GridItem>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <OVSForm formFieldsData={distributorItemsFormFieldsData} />
                  <GridItem sm={6}>
                    <Button
                      color='primary'
                      onClick={() => {
                        if (distributorIdItem) {
                          getDistributorItems({
                            variables: {
                              fleetId: distributorIdItem
                            }
                          })
                        }
                      }}
                      disabled={loadingDistributorItems || !distributorIdItem}
                    >
                      Get Links
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
            <GridItem md={12}>
              {distributorItems?.downloadDistributorItems ? <LinksTable rows={[{ name: 'Items.json', url: distributorItems?.downloadDistributorItems }] as Links[]} /> : (
                <EmptyState message='No links. Please select a distributor and click Get Links button.' />
              )}
            </GridItem>
          </GridContainer>
        </CardWrapper>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>

          <GridContainer justify={'space-between'} className="align-baseline">
            <GridItem xs={12} sm={12} md={12}>
              <h5>Download Code Generators</h5>
            </GridItem>
            <Formik onSubmit={(e) => { }} initialValues={{}}>
              {(formBag) => (
                <>
                  <OVSForm formFieldsData={formFieldsDataCodeGen} />
                  <GridItem sm={6}>
                    <Button
                      color='primary'
                      onClick={() => {
                        if (fleetIdCodeGen) {
                          getCodeGens({
                            variables: {
                              fleetId: fleetIdCodeGen
                            }
                          })
                        }
                      }}
                      disabled={loadingCodeGens || !fleetIdCodeGen || loadingFleetsLinks}
                    >
                      Get Links
                    </Button>
                  </GridItem>
                </>
              )}
            </Formik>
            <GridItem md={12}>
              {!isEmptyArray(codeGens?.downloadDistributorCodeGens || []) ? <LinksTable rows={codeGens?.downloadDistributorCodeGens as Links[]} /> : (
                <EmptyState message='No links. Please select a fleet and click Get Links button.' />
              )}
            </GridItem>
          </GridContainer>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default CheckInOutContainer;
