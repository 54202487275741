import { Row } from "react-table";
import SkuTableActions from "../components/TableActions"

export const itemTableData = [
  {
    Header: 'Actions',
    id: 'expander', // It needs an ID
    Cell: ({ row }: { row: Row }) => (
      <SkuTableActions {...row.getToggleRowExpandedProps()} row={row} />
    ),
  }
];


export const displayPayPlanFields = (payPlan:any) => {
  let arr = []
  for (let i = 0; i<payPlan.length; i++) {
    arr.push({Header: payPlan[i].pName, accessor: `node.${payPlan[i].pName}`})
  }
  return arr
}
