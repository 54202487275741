// @ts-nocheck
import { useParams } from 'react-router-dom';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import clientSimulator from "utils/clientSimulator";
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { PaymentPlanInput } from '../constants';
import AddItem from "../components/AddItem"
import { updatePayPlanMutation } from '../mutations';
import { UpdatePayPlan, UpdatePayPlanVariables } from '../types/UpdatePayPlan';
import { PayPlanTemplateContext } from '../context/EventProvider';
import { itemRoutes } from '../constants';
import { useGetSpecificPayPlanTemplates } from '../queries';
import CardGridLoader from '@components/Loaders/CardGridLoader';
const EditItemContainer: React.FC<IProps> = () => {
  const [initialPayPlan, setInitialPayPlan] = useState<PaymentPlanInput>({})
  const { itemId = "" } = useParams();
  const { refetchItems } = useContext(PayPlanTemplateContext)
  const { data, loading: payPlanLoading } = useGetSpecificPayPlanTemplates({
    id: itemId,
  });
  const notify = useNotifier();
  const history = useNavigate();

  useEffect(() => {
    let payPlanDetails = data?.getSpecificPayPlanTemplate?.planDetails
    let payPlanName = data?.getSpecificPayPlanTemplate?.planName
    let useUpfront =  data?.getSpecificPayPlanTemplate?.useUpfront
    let payPlanDescription = data?.getSpecificPayPlanTemplate?.planDescription

    // Create an empty object to store the result
    const resultObject = {};
    resultObject['planName'] = payPlanName
    resultObject['planDescription'] = payPlanDescription
    resultObject['useUpfront'] = useUpfront
    // Iterate through the array and populate the resultObject
    if (!payPlanLoading) {
      for (const item of payPlanDetails) {
        resultObject[item.pName] = Number(item.pValue);
      }
    }
    setInitialPayPlan(resultObject)
    // eslint-disable-next-line
  }, [data])


  const [updatePayPlanTemplate, { loading }] = useMutation<UpdatePayPlan, UpdatePayPlanVariables>(
    updatePayPlanMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: "success",
          text: "Payment plan Template Updated successfully",
        });
        refetchItems && refetchItems();
        history(itemRoutes.list)
      },
    }
  );

  const handleSubmit = (values: PaymentPlanInput) => {
    let planDetails = []
    for (const key in values) {
      if (key !== "planName" && key !== "planDescription" && key !== "useUpfront") {
        planDetails.push({ pName: key, pValue: String(values[key]) })
      }
    }

    updatePayPlanTemplate({
      variables: {
        updatePayPlanTemplateInput: {
          payPlanId: itemId,
          planName: values.planName,
          planDescription: values.planDescription,
          planDetails: planDetails,
          useUpfront: values.useUpfront
        }
      }
    })
  }
  if (!initialPayPlan.hourPrice || payPlanLoading) return <CardGridLoader />;
  return (
    <AddItem
      handleSubmit={handleSubmit}
      isLoading={loading}
      item={initialPayPlan}
    />
  );
};

export default EditItemContainer;
