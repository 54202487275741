import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext, useState } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useNavigate } from 'react-router-dom';
import { itemRoutes } from '../constants';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import CreditAccountForm from './CreditAccountForm';
import { AssetAccountContext } from '../context/AssetAccountProvider';
import AssetHistoryTable from './AssetHistoryTable';
import AddCodeGeneratorContainer from 'apps/things/app/codeGenerator/containers/AddCodeGeneratorContainer';
import AddCodeGenerationRequestContainer from 'apps/things/app/codeGenerator/containers/AddCodeGenerationRequestContainer';
import CodeEventContainer from 'apps/things/app/codeEvent/containers/CodeEventContainer';
import { useAuth } from 'admin/auth/context/AuthProvider';
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const history = useNavigate();
  const { isSuperAdmin, isDistributor } = useAuth()
  const { refetchItems } = useContext(AssetAccountContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [creditFormOpen, setCreditFormOpen] = React.useState(false);
  const [payHistoryModalOpen, setPayHistoryModalOpen] = React.useState(false);
  const [codeGenModalOpen, setCodeGenModalOpen] = useState(false);
  const [codeHistoryModalOpen, setCodeHistoryModalOpen] = useState(false);
  const [codeGenRequestModalOpen, setCodeGenRequestModalOpen] = useState(false)
  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <>
              <div className={`${classes.iconWrapper} ${(isSuperAdmin || isDistributor) ? classes.iconWrapperHover : ''}`}>
          <Tooltip
            title="Delete"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              id="expander"
              aria-label="Delete"
              className={classes.tableActionButton}
              style={(isSuperAdmin || isDistributor) ? { opacity: 0.3, pointerEvents: "none" } : {}}
            >
              <Delete
                className={classes.tableActionButtonIcon + " " + classes.delete}
              />
            </IconButton>
          </Tooltip>
          {(isSuperAdmin || isDistributor) && (
            <span className={classes.stopSign} role="img" aria-label="Stop">
              🚫
            </span>
          )}
        </div>
        <div className={`${classes.iconWrapper} ${isSuperAdmin ? classes.iconWrapperHover : ''}`}>
      <Tooltip
        id='expander'
        onClick={() => {
          history(itemRoutes.getEditLink(row.values['node._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}

      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
          style={isSuperAdmin ? { opacity: 0.3, pointerEvents: "none" } : {}}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      { isSuperAdmin && (
            <span className={classes.stopSign} role="img" aria-label="Stop">
              🚫
            </span>
          )}
      </div>
      <HoverDropdown
      menus={[
        {
          name: "Code Generation Request",
          action: () => {
            // setTokenizeModalOpen(true);
            setCodeGenRequestModalOpen(true)
          },
        },
      ]}
    /></>

      <DrawerSidebar
        isModalOpen={codeHistoryModalOpen}
        toggleModal={setCodeHistoryModalOpen}
        title='Code History'
        styles={{ minWidth: '80vw' }}
      >
        <CodeEventContainer itemId={row.values['node.asset._id']} />
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={payHistoryModalOpen}
        toggleModal={setPayHistoryModalOpen}
        title={'Payment history'}
        styles={{ maxWidth: '80vw' }}
      >
        <AssetHistoryTable paymentHistory={row.values['node.payHistory']} />
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={creditFormOpen}
        toggleModal={setCreditFormOpen}
        title='Credit Account'
        styles={{ maxWidth: '370px' }}
      >
        <CreditAccountForm
          customerName={row.values['node.credit.owner.name']}
          assetAccountId={row.values['node._id']}
          status={row.values['node.credit.accountStatus']}
          onComplete={(balance: number) => {
            setCreditFormOpen(false);
            refetchItems && refetchItems();
          }}
        />
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={codeGenRequestModalOpen}
        toggleModal={setCodeGenRequestModalOpen}
        title='Code Generation Request'
        styles={{ minWidth: '30vw', maxWidth: '30vw' }}
      >
        {codeGenRequestModalOpen && row.values['node.asset._id'] && (
          <AddCodeGenerationRequestContainer
            itemID={row.values['node.asset._id']}
          />
        )}
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={codeGenModalOpen}
        toggleModal={setCodeGenModalOpen}
        title='Generate Code'
        styles={{ minWidth: '70vw', maxWidth: '70vw' }}
      >
        {codeGenModalOpen && row.values['node.asset._id'] && (
          <AddCodeGeneratorContainer
            generateDayCodeCallback={() => {
              setCodeGenModalOpen(false);
            }}
            itemID={row.values['node.asset._id']}
          />
        )}
      </DrawerSidebar>
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() => { }}
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this customer?</div>
      </ActionModal>
    </div>
  );
};

export default TableActions;
