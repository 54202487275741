import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useContext, useEffect, useMemo } from 'react';

import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';
import OVSForm from '@components/Form/OVSForm';
import { UpdateSignalInput } from '../../types/thingsGlobalTypes';
import { PublisherContext } from 'apps/events/app/publisher/context/PublisherProvider';

const ItemForm: React.FC<{
  formBag: FormikProps<UpdateSignalInput>;
  disableLoginButton: boolean;
}> = (props) => {
  const { formBag, disableLoginButton } = props;
  const { items, getItems } = useContext(PublisherContext);

  useEffect(() => {
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);

  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            type: 'select',
            name: itemFields.publisher,
            label: 'Publisher',
            options: items?.map((it) => ({
              _id: it.node?._id || '',
              name: it?.node?.topic || '',
            })),
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.publisher, e.target.value);
            },
            value: formBag.values.publisher || '',
          },
          {
            type: 'textarea',
            name: itemFields.jmp,
            label: 'Jmp',
          },
          {
            type: 'date',
            name: itemFields.time,
            label: 'Time',
            value: formBag.values.time,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.time, e);
            },
            error: formBag.errors.time as string,
          },
        ],
      },
    ], // eslint-disable-next-line
    [formBag.values, items]
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader />
        <Form>
          <GridContainer>
            <OVSForm formFieldsData={formFieldsData} />
          </GridContainer>
          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
