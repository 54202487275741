import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';

import useLogEvent from '../hooks/useLogItem';
import {
  UpdatePublisherInput,
} from '../../types/eventsGlobalTypes';
import { PublisherContext as Context } from '../context/PublisherProvider';

const AddItemContainer = () => {
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(Context);

  // update when client Id is provided
  const { mutation: createItem, mutationOpts: createItemOpts } = useLogEvent({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdatePublisherInput) => {
    createItem({
      variables: {
        createPublisherInput: {
          broker: input.broker || '',
          topic: input.topic || ''
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
