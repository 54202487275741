import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import { Formik, FormikProps } from 'formik';
import { itemFields } from '../constants';
import {
  CreateItemInput,
  UpdateItemInput,
} from 'apps/things/app/types/thingsGlobalTypes';
import { addItemValidationSchema } from 'apps/things/app/validations';
import ItemSKUForm from './ItemForm';
import CardWrapper from '@components/Card/CardWrapper';
import { GetSpecificItem_getSpecificItem } from '../types/GetSpecificItem';

interface IRTable {
  title?: string;
  bulkDeleteProducts?: () => void;
  handleUpdate: (payload: UpdateItemInput) => void;
  isLoading: boolean;
  item: GetSpecificItem_getSpecificItem;
}

const EditItem: React.FC<IRTable> = ({ isLoading, handleUpdate, item }) => {
  const { addItem } = itemFields;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleUpdate({
                itemId: item._id,
                actorTypes: values.actorTypes,
                actionScope: values.actionScope,
                profile: values.profile,
                idType: values.idType,
                idString: values.idString,
                description: values.description,
                creationDate: values.creationDate,
                oemID: values.oemID,
                oemItemID: values.oemItemID,
                itembatchId: values.itembatchId,
                itemfirmwareId: values.itemfirmwareId,
                codegeneratorId: values.codegeneratorId,
              })
            }
            initialValues={{
              [addItem.idString]: item.idString,
              [addItem.oemID]: item.oemID,
              [addItem.itembatchId]: item.itemBatch._id,
              [addItem.itemBatchNumber]: item.itemBatch.batchNumber,
              [addItem.codegeneratorId]: item.codeGenerator?._id,
              [addItem.oemItemID]: item.oemItemID,
              [addItem.totalItems]: 0,
              [addItem.itemfirmwareId]: item.itemFirmware._id,
            }}
            validationSchema={addItemValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemSKUForm
                formBag={(formBag as unknown) as FormikProps<CreateItemInput>}
                disableLoginButton={isLoading}
                actionButtonName='Update Item'
                isUpdate
                sequence={item.oemItemID?.slice(-6)}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default EditItem;
