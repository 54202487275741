import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";
import CardWrapper from "@components/Card/CardWrapper";
import { CodeEventContext } from "../context/CodeEventProvider";
import { codeEventTableData } from "../constants/codeEventTableData";
import GoBackHeader from "@components/GoBackHeader/GoBackHeader";
import { useAuth } from "admin/auth/context/AuthProvider";;
interface IRTable {
  bulkDeleteProducts?: () => void;
  isLoading?: boolean;
  itemId?: string;
}

const CodeEventPage: React.FC<IRTable> = ({
  bulkDeleteProducts,
  isLoading,
  itemId,
}) => {
  const {
    codeEvents,
    loading,
    paging,
    goTo,
    setSearchQuery,
    searchQuery,
    setItemId,
  } = useContext(CodeEventContext);

  const [innerItemId, setInnerItemId] = useState("")
  const { itemIdd } = useParams();
  const {isDistributor, isServicer} = useAuth()
  useEffect(() => {
    if (itemId && !loading) {
      setInnerItemId(itemId)
    } 
    else if (itemIdd) {
      setInnerItemId(itemIdd)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setItemId(innerItemId);
    // eslint-disable-next-line
  }, [innerItemId])
  const columnsToRemove = [
    'userWhoCausedTheChange',
    'typeOfChangeChange',
    'Triggers',
    'hashRoot'
  ];
  
  const columns = codeEventTableData().header?.filter(item => {
    if ((isDistributor || isServicer) && columnsToRemove.includes(item.Header)) {
      return false;
    }
    return true;
  });

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
        <GoBackHeader title="Go Back" />
          <OvsTable
            columns={columns as Column[]}
            data={
              codeEvents?.map((item, id) => ({ ...item, id: id + 1 })) || []
            }
            checkedIds={[]}
            bulkDelete={bulkDeleteProducts}
            tableTitle="Code Event List"
            isLoading={isLoading || loading}
            hideRightToolbar
            goTo={goTo}
            paging={paging}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default CodeEventPage;
