import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { pageDataFragment } from '../common/queries';
import { GetAllBrands, GetAllBrandsVariables } from './types/GetAllBrands';
import {
  GetSpecificBrand,
  GetSpecificBrandVariables,
} from './types/GetSpecificBrand';

/**
 * type Brand {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
logo: String!
thumb: String!
link: String!
brandSelectors: [BrandSelector!]
}
 */
export const brandFragment = gql`
  fragment Brand on Brand {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    logo
    thumb
    link
  }
`;

/**
 * Query.getSpecificBrand(
id: String!
): Brand!
 */
const getSpecificBrandQuery = gql`
  ${brandFragment}
  query GetSpecificBrand($id: String!) {
    getSpecificBrand(id: $id) {
      ...Brand
    }
  }
`;
/**
 * type BrandEdge {
cursor: String
node: Brand
}
 */
const brandEdgeFragment = gql`
  ${brandFragment}
  fragment BrandEdge on BrandEdge {
    cursor
    node {
      ...Brand
    }
  }
`;
/**
 * type BrandPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const brandPageInfoFragment = gql`
  fragment BrandPageInfo on BrandPageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;
/**
 * type BrandConnection {
edges: [BrandEdge!]
pageInfo: BrandPageInfo
}
 */
const brandConnectionFragment = gql`
  ${brandPageInfoFragment}
  ${brandEdgeFragment}
  fragment BrandConnection on BrandConnection {
    edges {
      ...BrandEdge
    }
    pageInfo {
      ...BrandPageInfo
    }
  }
`;
/**
 * type GetAllBrandsResponse {
page: BrandConnection!
pageData: PageData
}
 */
const getAllBrandsResponseFragment = gql`
  ${brandConnectionFragment}
  ${pageDataFragment}
  fragment GetAllBrandsResponse on GetAllBrandsResponse {
    page {
      ...BrandConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * getAllBrands(
before: String
after: String
first: Int
last: Int
): GetAllBrandsResponse!
 */
const getAllBrandsQuery = gql`
  ${getAllBrandsResponseFragment}
  query GetAllBrands($before: String, $after: String, $first: Int, $last: Int) {
    getAllBrands(before: $before, after: $after, first: $first, last: $last) {
      ...GetAllBrandsResponse
    }
  }
`;

export const useLazyGetAllBrandsQuery = (variables: GetAllBrandsVariables) =>
  useLazyQuery<GetAllBrands, GetAllBrandsVariables>(getAllBrandsQuery, {
    client: clientEcommerce,
    variables,
  });

export const useGetAllBrandsQuery = (variables: GetAllBrandsVariables) =>
  useQuery<GetAllBrands, GetAllBrandsVariables>(getAllBrandsQuery, {
    client: clientEcommerce,
    variables,
  });

export const useGetSpecificBrandsQuery = (id: string) =>
  useQuery<GetSpecificBrand, GetSpecificBrandVariables>(getSpecificBrandQuery, {
    variables: { id },
    client: clientEcommerce,
  });
