import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import clientClientService from '../utils/clientClientService';
import {
  GetAllOrganizations,
  GetAllOrganizationsVariables,
} from './types/GetAllOrganizations';
import {
  GetSpecificOrganization,
  GetSpecificOrganizationVariables,
} from './types/GetSpecificOrganization';

/**
 * type Organization {
_id: ID!
activeSubRolePermission: PermissionInput!
authenticationInstance: AuthenticationInstance!
authenticationSubInstance: AuthenticationSubInstance!
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
description: String
name: String!
orgAddress: Address!
orgContactPerson: Person
orgProfile: String!
role: Roles!
subRolePermissions: SubRolePermissions!
type: OrgTypes!
updatedAt: DateTime
}
 */
const organizationFragment = gql`
  fragment Organization on Organization {
    _id

    createdAt
    deleteAt
    deleteStatus
    description
    name
    orgContactPerson {
      _id
      createdAt
      deleteAt
      deleteStatus
      description
      name
      address {
        city
        country
        postcode
        srpc
        street
        unit
        addressLocation {
          addressLatitude
          addressLongitude
        }
      }
      contact {
        email
        phone
        social
      }
    }

    orgProfile
    orgAddress {
      unit
      street
      city
      srpc
      country
      postcode
      addressLocation {
        addressLatitude
        addressLongitude
      }
    }
    type
    updatedAt
  }
`;
/**
 * type OrganizationEdge {
cursor: String
node: Organization
}
 */
const organizationEdgeFragment = gql`
  ${organizationFragment}
  fragment OrganizationEdge on OrganizationEdge {
    cursor
    node {
      ...Organization
    }
  }
`;
/**
 * type OrganizationPageInfo {
endCursor: String
hasNextPage: Boolean!
hasPreviousPage: Boolean!
startCursor: String
}
 */
const organizationPageInfoFragment = gql`
  fragment OrganizationPageInfo on OrganizationPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
/**
 * type OrganizationConnection {
edges: [OrganizationEdge!]
pageInfo: OrganizationPageInfo
}
 */
const organizationConnectionFragment = gql`
  ${organizationPageInfoFragment}
  ${organizationEdgeFragment}
  fragment OrganizationConnection on OrganizationConnection {
    edges {
      ...OrganizationEdge
    }
    pageInfo {
      ...OrganizationPageInfo
    }
  }
`;
/**
 * type GetAllOrganizationsResponse {
page: OrganizationConnection!
pageData: PageData
}
 */
const getAllOrganizationsResponseFragment = gql`
  ${organizationConnectionFragment}
  fragment GetAllOrganizationsResponse on GetAllOrganizationsResponse {
    page {
      ...OrganizationConnection
    }
    pageData {
      count
      limit
      offset
    }
  }
`;
/**
 * getAllOrganizations(
after: String
before: String
first: Int
last: Int
): GetAllOrganizationsResponse!
 */
const getAllOrganizationsQuery = gql`
  ${getAllOrganizationsResponseFragment}
  query GetAllOrganizations(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllOrganizations(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllOrganizationsResponse
    }
  }
`;

/**
 * 
 * getSpecificOrganization(
orgId: ID!
): Organization
 */
const getSpecificOrganizationQuery = gql`
  ${organizationFragment}
  query GetSpecificOrganization($orgId: ID!) {
    getSpecificOrganization(orgId: $orgId) {
      ...Organization
    }
  }
`;

export const useGetSpecificOrganizationQuery = (
  variables: GetSpecificOrganizationVariables
) =>
  useQuery<GetSpecificOrganization, GetSpecificOrganizationVariables>(
    getSpecificOrganizationQuery,
    {
      variables,
      client: clientClientService,
    }
  );

export const useLazyGetAllOrganizationsQuery = (
  variables: GetAllOrganizationsVariables
) =>
  useLazyQuery<GetAllOrganizations>(getAllOrganizationsQuery, {
    variables,
    client: clientClientService,
  });

export const useGetAllOrganizationsQuery = (
  variables: GetAllOrganizationsVariables
) =>
  useQuery<GetAllOrganizations>(getAllOrganizationsQuery, {
    variables,
    client: clientClientService,
  });
