import CardWrapper from '@components/Card/CardWrapper';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import OvsTable from '@components/ReactTable/OvsTable';
import React, { useContext } from 'react';
import { Column } from 'react-table';
import { format2DP } from 'utils';
import { accountActivitiesData } from '../constants/itemTableData';
import { AssetAccountContext } from '../context/AssetAccountProvider';

interface IProps {}

const AccountActivitiesTable: React.FC<IProps> = () => {
  const { item } = useContext(AssetAccountContext);
  return (
    <GridContainer>
      <GridItem md={6}>
        <div></div>
        <h6>
          <b>Account Status: </b>
          {item?.credit?.accountStatus}
        </h6>

        <h6>
          <b>Account Balance: </b>
          {format2DP(item?.credit?.balance || 0)}
        </h6>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={accountActivitiesData as Column[]}
            data={item?.credit?.activities || []}
            checkedIds={[]}
            isLoading={false}
            addItemBtnText='Add'
            hideRightToolbar
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AccountActivitiesTable;
