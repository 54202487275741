import DateCell from '@components/ReactTable/DateCell';
import DescriptionCell from '@components/ReactTable/DescriptionCell';
import React from 'react';
import { Row } from 'react-table';
import CustomTableActions from '../components/CustomTableActions';
import ItemTableActions from '../components/ItemTableActions';

interface ITableData {
  CheckboxComponent?: React.ReactNode;
  custom?: boolean
}

const thresholdWidth = 600;
let header:any = []

if (window.innerWidth <= thresholdWidth) {
  header = [
    {
      Header: 'oemItemID',
      accessor: 'node.oemItemID',
    },
    {
      Header: 'sellerItemID',
      accessor: 'node.sellerItemID',
    },
    { Header: 'ID', accessor: 'node._id' },
    {
      Header: 'oemID',
      accessor: 'node.oemID',
    },
    {
      Header: 'sellerID',
      accessor: 'node.sellerID',
    },
    {
      Header: 'batch ID',
      accessor: 'node.itemBatch._id',
    },
    {
      Header: 'batchNumber',
      accessor: 'node.itemBatch.batchNumber',
    },
    { Header: 'skuID', accessor: 'node.itemBatch.itemSKU._id' },
    { Header: 'skuName', accessor: 'node.itemSKU.skuName' },
    { Header: 'itemFirmware', accessor: 'node.itemFirmware.version' },
    { Header: 'codeGenerator', accessor: 'node.codeGenerator._id' },
    { Header: 'hashRoot', accessor: 'node.codeGenerator.hashRoot' },
    { Header: 'hashTop', accessor: 'node.codeGenerator.hashTop' },
    { Header: 'hashTopInitial', accessor: 'node.codeGenerator.hashTopInitial' },
    { Header: 'hashIndex', accessor: 'node.codeGenerator.hashIndex' },
    { Header: 'codeCount', accessor: 'node.codeGenerator.codeCount' },
    {
      Header: 'lifeCycle',
      accessor: 'node.lifeCycle',
    },
    {
      Header: 'Fleet',
      accessor: 'node.itemFleet.fleetName',
    },
    {
      Header: 'type',
      accessor: 'node.type',
    },
    { Header: 'actionScope', accessor: 'node.actionScope' },
    {
      Header: 'Profile',
      accessor: 'node.profile',
    },
    {
      Header: 'idType',
      accessor: 'node.idType',
    },
    {
      Header: ' Description',
      accessor: 'node.description',
      Cell: DescriptionCell,
    },

    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
  ]
} else {
  header = [
    {
      Header: 'oemItemID',
      accessor: 'node.oemItemID',
    },
    {
      Header: 'AccountNumber',
      accessor: 'node.sellerItemID',
    },

    {
      Header: 'Fleet',
      accessor: 'node.itemFleet.fleetName',
    },
    {
      Header: 'sellerID',
      accessor: 'node.sellerID',
    },
    {
      Header: 'oemID',
      accessor: 'node.oemID',
    },
    {
      Header: 'batch ID',
      accessor: 'node.itemBatch._id',
    },
    
    { Header: 'ID', accessor: 'node._id' },
    {
      Header: 'batchNumber',
      accessor: 'node.itemBatch.batchNumber',
    },
    { Header: 'skuID', accessor: 'node.itemBatch.itemSKU._id' },
    { Header: 'skuName', accessor: 'node.itemSKU.skuName' },
    { Header: 'itemFirmware', accessor: 'node.itemFirmware.version' },
    { Header: 'codeGenerator', accessor: 'node.codeGenerator._id' },
    { Header: 'hashRoot', accessor: 'node.codeGenerator.hashRoot' },
    { Header: 'hashTop', accessor: 'node.codeGenerator.hashTop' },
    { Header: 'hashTopInitial', accessor: 'node.codeGenerator.hashTopInitial' },
    { Header: 'hashIndex', accessor: 'node.codeGenerator.hashIndex' },
    {
      Header: 'lifeCycle',
      accessor: 'node.lifeCycle',
    },

    {
      Header: 'type',
      accessor: 'node.type',
    },
    { Header: 'actionScope', accessor: 'node.actionScope' },
    {
      Header: 'Profile',
      accessor: 'node.profile',
    },
    {
      Header: 'idType',
      accessor: 'node.idType',
    },
    {
      Header: 'Asset Account',
      accessor: 'node.assetAccount._id',
    },
    {
      Header: ' Description',
      accessor: 'node.description',
      Cell: DescriptionCell,
    },
    { Header: 'codeHistory', accessor: 'node.codeGenerator.codeHistory', Cell: DescriptionCell, },
    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
  ]
}

export const itemTableData = ({ custom = false }: ITableData) => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <>
          {custom ? <CustomTableActions  {...row.getToggleRowExpandedProps()} row={row} /> : <ItemTableActions {...row.getToggleRowExpandedProps()} row={row} />}
        </>

      ),
    },
    ...header
  ],
});
