import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import { tigStackRoutes as itemRoutes } from "./constants";
import TigStack from "./containers/TigStack";

interface IProps {}

export const routes = {
  path: itemRoutes.main,
  element: <DefaultOutlet />,
  children: [{ index: true, element: <TigStack /> }],
};

const TigStackIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default TigStackIndex;
