import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';
import { DistributorRegisterDistributorStaffInput } from '../../types/clientServiceGlobalTypes';
import { makeStyles } from '@material-ui/core/styles'
import CustomSelect from '@components/CustomSelect/CustomSelect';
import { Field } from 'formik';
import PhoneNumberInput from '@components/PhoneNumber/PhoneNumber';
const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      color: '#cccccc', // Default label color
      fontSize: '14px', // Default label font size
    },
    '& .MuiInput-underline:not(.Mui-disabled):before': {
      borderBottomColor: '1px solid #cccccc', // Change this to your desired underline color
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#cccccc', // Change this to your desired hover color
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: primaryColor[0], // Change this to your desired focus color
    },
    '& .MuiInputBase-input': {
      fontSize: '14px', // Change this to your desired font size
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#cccccc', // Focused label color (same as default)
    },
  },
});
const ItemForm: React.FC<{
  formBag: FormikProps<DistributorRegisterDistributorStaffInput>;
  disableLoginButton: boolean;
  actionButtonName?: string;
}> = (props) => {
  const { formBag, disableLoginButton, actionButtonName = 'Add' } = props;

  const classes = useStyles();
  const roleOptions = [
    {
      _id: "MALE",
      name: "MALE"
    },
    {
      _id: "FEMALE",
      name: "FEMALE"
    }
  ]
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridHalfWrapper>
              <TextInput
                label='First Name'
                name={itemFields.firstName}
                required
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Last Name'
                name={itemFields.lastName}
                required
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Email'
                name={itemFields.email}
                type='email'
                required
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <CustomSelect
                selectOptions={roleOptions}
                onChange={(e: any) => {
                  formBag.setFieldValue(itemFields.gender, e.target.value);
                }}
                label={'Gender'}
                value={formBag.values.gender || ''}
                name={itemFields.gender}
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Age'
                name={itemFields.age}
                type='number'
                required
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
            <Field name={itemFields.phone}>
                {({ field, form }: { field: { value: string }; form:any }) => (
                  <PhoneNumberInput
                    label="Phone"
                    value={field.value}
                    onChange={(phone) =>
                      formBag.setFieldValue(itemFields.phone, phone)
                    }
                    error={
                      form.errors?.staffContact?.phone && form.touched?.staffContact?.phone
                        ? form.errors?.staffContact?.phone
                        : ''
                    } // Show error if touched and error exists
                  />
                  
                )}
              </Field>
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Social' name={itemFields.social} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Office City' name={itemFields.officeCity} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Office Country' name={itemFields.officeCountry} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Office Postcode' name={itemFields.officePostcode} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Office srpc' name={itemFields.officeSrpc} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Office Street' name={itemFields.officeStreet} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Office Unit' name={itemFields.officeUnit} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Office Longitude'
                name={itemFields.officeAddressLongitude}
                type='number'
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Office Latitude'
                name={itemFields.officeAddressLatitude}
                type='number'
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Staff City' name={itemFields.staffCity} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Staff Country' name={itemFields.staffCountry} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Staff Postcode' name={itemFields.staffPostcode} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Staff srpc' name={itemFields.staffSrpc} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Staff Street' name={itemFields.staffStreet} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Staff Unit' name={itemFields.staffUnit} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Staff Longitude'
                name={itemFields.staffAddressLongitude}
                type='number'
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Staff Latitude'
                name={itemFields.staffAddressLatitude}
                type='number'
              />
            </GridHalfWrapper>
            <GridHalfWrapper>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.root}
                  style={{ width: '100%', marginTop: "14.3px" }}
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="hire-date-picker"
                  label="Hire Date"
                  value={formBag.values.hireDate}  // Assuming "hireDate" is in initialValues
                  onChange={(date) => formBag.setFieldValue('hireDate', date)}
                  error={formBag.touched.hireDate && Boolean(formBag.errors.hireDate)}
                  helperText={formBag.touched.hireDate && typeof formBag.errors.hireDate === 'string' && formBag.errors.hireDate}
                />
                <p style={{ color: "red", fontSize: "14px", marginTop: "0.7rem" }} >{formBag.errors.hireDate ? "Field is required" : null}</p>
              </MuiPickersUtilsProvider>
            </GridHalfWrapper>
          </GridContainer>

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
