import DateCell from "@components/ReactTable/DateCell";
import DescriptionCell from "@components/ReactTable/DescriptionCell";
import TotalItemsCell from "@components/ReactTable/TotalItemsCell";
import { Row } from "react-table";
import SkuTableActions from "../components/TableActions";

export const itemTableData = () => ({
  header: [
    {
      Header: "Actions",
      id: "expander", // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <SkuTableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: "fleetName",
      accessor: "node.fleetName",
      Cell: DescriptionCell
    },
    
    {
      Header: "distributor",
      accessor: "node.distributor.orgContactPerson.name",
    },
    {
      Header: "Description",
      accessor: "node.description",
      Cell: DescriptionCell,
    },
    {
      Header: "Free Code Count",
      accessor: "node.freeCodeCount",
    },
    {
      Header: "Reset Code Count",
      accessor: "node.resetCodeCount",
    },
    {
      Header: "Day Code Count Limit",
      accessor: "node.dayCodeCountLimit",
    },
    {
      Header: "Total Code Count Limit",
      accessor: "node.totalCodeCountLimit",
    },
    {
      Header: "Code Interval",
      accessor: "node.codeGenInterval",
    },
    {
      Header: "Max Credit Stack Days",
      accessor: "node.maxCreditStackDays",
    },
    {
      Header: "Max Credit Stack Code Events",
      accessor: "node.maxCreditStackCodeEvents",
    },
    {
      Header: "actionScope",
      accessor: "node.actionScope",
    },
    {
      Header: "actorName",
      accessor: "node.actorName",
    },
    {
      Header: "assignDate",
      accessor: "node.assignDate",
      Cell: DateCell,
    },
    {
      Header: "type",
      accessor: "node.type",
    },
    {
      Header: "distributorID",
      accessor: "node.distributor._id",
    },
    

    {
      Header: "Items assigned",
      accessor: "node.itemList",
      Cell: TotalItemsCell,
    },

    {
      Header: "Profile",
      accessor: "node.profile",
    },
    {
      Header: "Date Created",
      accessor: "node.createdAt",
      Cell: DateCell,
    },
    {
      Header: "Last Updated",
      accessor: "node.updatedAt",
      Cell: DateCell,
    },
    { Header: "ID", accessor: "node._id" },
  ],
});
