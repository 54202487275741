import { useMutation } from "@apollo/client";
import useNotifier from "hooks/useNotifier";
import React from "react";
import { useNavigate } from "react-router-dom";
import { handleGQLErrors } from "utils/gqlErrors";
import AddItem from "../components/AddItem";
import { defaultItem, itemRoutes } from "../constants";
import { CustomerContext } from "../context/CustomerProvider";
import {
  ClientRegisterCustomer,
  ClientRegisterCustomerVariables,
} from "../types/ClientRegisterCustomer";
import { clientRegisterCustomerMutation } from "../mutations";
import clientClientService from "../../utils/clientClientService";
import { UpdateClientPersonInput } from "../../types/clientServiceGlobalTypes";

const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(CustomerContext);

  // create mutations
  const [createItem, createItemOpts] = useMutation<
    ClientRegisterCustomer,
    ClientRegisterCustomerVariables
  >(clientRegisterCustomerMutation, {
    client: clientClientService,
    onCompleted: async (data) => {
      if (!data) {
        return notify({
          status: "error",
          text: "Something went wrong!",
        });
      }
      notify({
        status: "success",
        text: "Created successfully.",
      });

      if (refetch) await refetch();
      history(itemRoutes.list);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleSubmit = (input: UpdateClientPersonInput) => {
    const address = input.clientPersonAddress;
    createItem({
      variables: {
        clientCustomerInput: {
          clientContactPerson:
            input.clientContactPerson || defaultItem.clientContactPerson,
          clientPersonAddress: {
            city: address?.city || "",
            country: address?.country || "",
            postcode: address?.postcode || "",
            srpc: address?.srpc || "",
            street: address?.street || "",
            unit: address?.unit || "",
            locationAddressDto: {
              inputAddressLatitude: parseFloat(
                address?.locationAddressDto?.inputAddressLatitude?.toString() ||
                  "0.0"
              ),
              inputAddressLongitude: parseFloat(
                address?.locationAddressDto?.inputAddressLongitude?.toString() ||
                  "0.0"
              ),
            },
          },
          // input.clientPersonAddress || defaultItem.clientPersonAddress,
          clientPersonName: input.clientPersonName || "",
          clientPersonDescription: input.clientPersonDescription || "",
          clientPersonType: input.clientPersonType,
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
