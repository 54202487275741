import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useContext, useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import {
  GetAllDistributors,
  GetAllDistributorsVariables,
  GetAllDistributors_getAllDistributors_page_edges,
} from '../types/GetAllDistributors';
import { useLazyGetAllDistributorsQuery } from '../queries';
import {
  DeleteDistributor,
  DeleteDistributorVariables,
} from '../types/DeleteDistributor';
import { deleteDistributorMutation } from '../mutations';
import clientSimulator from 'utils/clientSimulator';
import { Row } from 'react-table';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import useNotifier from 'hooks/useNotifier';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';

export interface IDistributorContext {
  items: GetAllDistributors_getAllDistributors_page_edges[];
  setItems: (
    itemSKUs: GetAllDistributors_getAllDistributors_page_edges[]
  ) => void;
  loading: boolean;
  getItems: () => void;
  tableActions: ITableActions[];
  refetchItems:
  | ((
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllDistributors>>)
  | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
}

export const DistributorContext = React.createContext<IDistributorContext>(
  {} as IDistributorContext
);

const DistributorProvider: React.FC<IProps> = ({ children }) => {
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  const [items, setItems] = React.useState<
    GetAllDistributors_getAllDistributors_page_edges[]
  >([]);
  const {
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();
  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllDistributorsQuery({
    first: pageListLimit,
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
 

  const notify = useNotifier();

  useEffect(() => {
    setItems(data?.getAllDistributors?.page?.edges || []);
    setPaging({
      ...data?.getAllDistributors?.pageData,
      ...data?.getAllDistributors?.page?.pageInfo,
    }); // eslint-disable-next-line
  }, [data]);

  const [deleteItem] = useMutation<
    DeleteDistributor,
    DeleteDistributorVariables
  >(deleteDistributorMutation, {
    client: clientSimulator,
    onCompleted: (data) => { },
  });
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllDistributorsVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(
        _data?.data?.getAllDistributors?.page?.edges || []
      );

      setPaging({
        ..._data?.data?.getAllDistributors?.pageData,
        ..._data?.data?.getAllDistributors?.page?.pageInfo,
      });
    }

  };

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }

    const variables: GetAllDistributorsVariables = {
      first: pageListLimit,
    };
    if (searchQuery) {
      variables['search'] = searchQuery;
    }
    setIsLoadingMore(true);
    if (fetchMore) {
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllDistributors?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllDistributors?.pageData,
        ..._data?.data?.getAllDistributors?.page?.pageInfo,
      });
    }



  }


  useDebouncedEffect(search, [searchQuery], 1000);


  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            distributorId: item.values['node._id'],
          },
        })
      )
    ).then(async (res) => {
      notify({
        status: 'success',
        text: 'Deleted successfully',
      });
      if (refetchItems) {
        const itemsFetched = await refetchItems({
          first: pageListLimit,
        });
        setItems(itemsFetched?.data?.getAllDistributors?.page?.edges || items);
        setTableActionLoading(false);
      } else {
        getItems();
        setTableActionLoading(false);
      }
    });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading: loading || isLoadingMore,
      getItems,
      refetchItems, paging,
      goTo, searchQuery, setSearchQuery, setPageLimit,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} customer(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading, searchQuery, paging, isLoadingMore]
  );

  return (
    <DistributorContext.Provider value={value}>
      {children}
    </DistributorContext.Provider>
  );
};

export default DistributorProvider;
