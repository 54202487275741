import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';

import {
  GetAllItemEvents,
  GetAllItemEventsVariables,
  GetAllItemEvents_getAllItemEvents_page_edges,
} from '../types/GetAllItemEvents';
import { useLazyGetAllItemEventsQuery } from '../queries';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';
export interface IEventContext {
  items: GetAllItemEvents_getAllItemEvents_page_edges[];
  setItems: (item: GetAllItemEvents_getAllItemEvents_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  activeStage: number;
  setActiveStage: (stage: number) => void;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<GetAllItemEventsVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllItemEvents>>)
    | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr

}

export const ItemEventContext = React.createContext<IEventContext>(
  {} as IEventContext
);

const ItemEventProvider: React.FC<IProps> = ({ children }) => {
  const [activeStage, setActiveStage] = useState(0);
  const [item, setItem] = React.useState(
    {} as GetAllItemEvents_getAllItemEvents_page_edges
  );
  const [items, setItems] = React.useState<
    GetAllItemEvents_getAllItemEvents_page_edges[]
  >([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  /// hooks
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,
    setPageListLimit,
    pageListLimit
  } = useOVSPagination();
  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllItemEventsQuery({
    first: pageListLimit,
  });

  useEffect(() => {
    setItems(data?.getAllItemEvents?.page?.edges || []);
    setPaging({
      ...data?.getAllItemEvents?.pageData,
      ...data?.getAllItemEvents?.page?.pageInfo,
    }); // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllItemEventsVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllItemEvents?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemEvents?.pageData,
        ..._data?.data?.getAllItemEvents?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }
  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }

    const variables: GetAllItemEventsVariables = {
      first: pageListLimit,
    };
    if (searchQuery) {
      variables['search'] = searchQuery;
    }
    setIsLoadingMore(true);
    if (fetchMore) {
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllItemEvents?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemEvents?.pageData,
        ..._data?.data?.getAllItemEvents?.page?.pageInfo,
      });
    }

  }


  useDebouncedEffect(search, [searchQuery], 1000);

  const value = React.useMemo(
    () => ({
      item,
      setItem,
      items,
      setItems,
      goTo,
      paging,
      loading: loading,
      getItems: getItems,
      refetchItems,
      setPageLimit,
      searchQuery,
      setSearchQuery,
      tableActions: [],
      activeStage,
      setActiveStage,
    }), // eslint-disable-next-line
    [items, loading, paging, searchQuery]
  );

  return (
    <ItemEventContext.Provider value={value}>{children}</ItemEventContext.Provider>
  );
};

export default ItemEventProvider;
