import React, { useEffect, useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import CustomChip from '@components/CustomChip/CustomChip';


  export interface IFilter {
    id: string; // Unique identifier for each filter
    field: string; // The column or field name
    // operator: string; // Operator used in the filter (e.g., '=', '>', '<', 'between', 'contains')
    value: any; // The value(s) of the filter
  }

interface IFilterChipsProps {
  initialFilters: IFilter[];
  handleResetFilterWithDates?: any;
  handleResetFilter?: (filter: IFilter) => void;
}

const FilterChips: React.FC<IFilterChipsProps> = ({ initialFilters, handleResetFilterWithDates }) => {
  const [filters, setFilters] = useState<IFilter[]>(initialFilters);
  useEffect(() => {
    setFilters(initialFilters)
  },[initialFilters])
  const handleDelete = (filterToDelete: IFilter) => {
    handleResetFilterWithDates(filterToDelete.field)
    // setFilters((prevFilters) => prevFilters.filter(filter => filter.type !== filterToDelete.type));
    // Remove the specific filter (chip) that was clicked

    setFilters((prevFilters) => prevFilters.filter(filter => {
      return filter.id !== filterToDelete.id;
    }));
  };
  const getLabel = (filter: IFilter): string => {
    const { field, value } = filter;
    let formattedValue = '';

     if (field === "date") {
      formattedValue = `From: ${moment(value.from).format('MMMM Do YYYY')} - To: ${moment(value.to).format('MMMM Do YYYY')}`
    } else {
      formattedValue = value.toString();
    }
    return formattedValue
  };

  return (
    <div>
      {filters.map((filter) => (
        <CustomChip
         key={filter.id}
          label={getLabel(filter)}
          onDelete={() => handleDelete(filter)}
          deleteIcon={<CancelIcon />}
          color='primary'
          background

        />
      ))}
    </div>
  );
};

export default FilterChips;
