import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import { Formik, FormikProps } from 'formik';
import { itemSKUFields } from '../constants';
import { addItemSKUValidationSchema } from 'apps/things/app/validations';
import ItemSKUForm from './ItemSKUForm';
import CardWrapper from '@components/Card/CardWrapper';
import { GetSpecificItemSKUs_getSpecificItemSKU } from '../types/GetSpecificItemSKUs';
import {
  CreateItemSKUInput,
  UpdateItemSKUInput,
} from '../../types/thingsGlobalTypes';

interface IRTable {
  title?: string;
  bulkDeleteProducts?: () => void;
  handleUpdate: (payload: UpdateItemSKUInput) => void;
  isLoading: boolean;
  itemSKU: GetSpecificItemSKUs_getSpecificItemSKU;
}

const EditItemSKU: React.FC<IRTable> = ({
  isLoading,
  handleUpdate,
  itemSKU,
}) => {
  const { addItemSKU } = itemSKUFields;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) => {
              return handleUpdate({
                itemSKUId: itemSKU._id,
                skuName: values.skuName,
                oemDescription: values.oemDescription,
                productBase: values.productBase,
                gatt: values.gatt,
                gattName: values.gatt?.name
              });
            }}
            initialValues={{
              [addItemSKU.skuName]: itemSKU.skuName,
              [addItemSKU.oemDescription]: itemSKU.oemDescription,
              [addItemSKU.productBase]: itemSKU.productBase,
              [addItemSKU.gatt]: itemSKU.properties,
            }}
            validationSchema={addItemSKUValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemSKUForm
                formBag={
                  (formBag as unknown) as FormikProps<CreateItemSKUInput>
                }
                disableLoginButton={isLoading}
                actionButtonName='Update Item SKU'
                isEdit={true}
                itemSkuId={itemSKU._id}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default EditItemSKU;
