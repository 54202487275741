import gql from 'graphql-tag';

/**
 * createSupplier(
createSupplierInput: CreateSupplierInput!
): Supplier!
 */
export const createSupplierMutation = gql`
  mutation CreateSupplier($createSupplierInput: CreateSupplierInput!) {
    createSupplier(createSupplierInput: $createSupplierInput) {
      _id
    }
  }
`;

/**
 * updateSupplier(
updateSupplierInput: UpdateSupplierInput!
)
 */
export const updateSupplierMutation = gql`
  mutation UpdateSupplier($updateSupplierInput: UpdateSupplierInput!) {
    updateSupplier(updateSupplierInput: $updateSupplierInput) {
      _id
    }
  }
`;

/**
 * deleteSupplier(
supplierId: String!
)
 */
export const deleteSupplierMutation = gql`
  mutation DeleteSupplier($supplierId: String!) {
    deleteSupplier(supplierId: $supplierId)
  }
`;
