import * as React from 'react';
import { useSnackbar } from 'notistack';

import * as _ from 'lodash';
import { api } from '../../services/api';
import { ProvisionDataContext } from '../context/ProvisionDataContext';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CSVReader from '@components/CSVUploader/CSVReader';
import { useStateCallback } from 'utils';
import BackDrop from '@components/Loaders/BackDrop';
import { ProvisionActions } from 'store/actions/provisionActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/epics/userPrefEpics';
import Alert from '@material-ui/lab/Alert';
import useNotifier from 'hooks/useNotifier';
const withHeader = true;
const CSVUploader = () => {
  const papaparseOptions = {
    header: withHeader,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: string) =>
      header.toLowerCase().replace(/\W/g, '_'),
  };

  const dispatch = useDispatch();
  const unresolvedItems = useSelector(
    (store: RootState) => store.provision.unresolvedItems
  );

  const { setData, data, setFileName } = React.useContext(ProvisionDataContext);
  const { enqueueSnackbar } = useSnackbar();
  const notify = useNotifier();

  const [serverData, setServerData] = React.useState<
    { id: number; OEM_SN: string }[]
  >([]);

  const [loadingCSV, setLoadingCSV] = useStateCallback(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const [resolvedItems, setResolvedItems] = useStateCallback(0);
  const [progress, setProgress] = useStateCallback(0);

  React.useEffect(() => {
    setProgress(
      (prev: any) =>
        (100 * parseInt(resolvedItems.toString())) / totalItems || 0
    ); // eslint-disable-next-line
  }, [resolvedItems]);

  const onStartLoad = () => {
    setLoadingCSV((prev: any) => true);
  };

  const retry = () => {
    onStartLoad();
    handleForce(data, undefined);
  };

  const RenderLoadingProgress = () => {
    return (
      <div>
        <span>
          Resolving product item IDS. {parseInt(progress.toString())}% complete,{' '}
          {resolvedItems} of {totalItems}
        </span>
      </div>
    );
  };

  const handleForce = async (csvData: any, fileInfo: any) => {
    // validate file size
    if (fileInfo && fileInfo?.size > 800000) {
      setLoadingCSV((prev: any) => false);
      return notify({
        status: 'error',
        text: 'CSV file cannot be more than 800KB',
      });
    }
    if (fileInfo?.name) {
      setFileName(fileInfo?.name);
    }
    enqueueSnackbar(
      'Successfully uploaded CSV. Please review before submitting.',
      {
        variant: 'success',
      }
    );
    if (csvData.length === 0) return;
    let _csvData: any = [];
    if (!withHeader && !csvData[0]?.productitemoem_sn) {
      _csvData = csvData.map((item: any) => {
        return {
          productitemoem_sn: item[0],
          product_qid: item[21],
        };
      });
    } else {
      _csvData = csvData;
    }

    setData([]);
    _.pullAllBy(_csvData, [{ productitemoem_sn: null }], 'productitemoem_sn');

    const _first = 5;
    const first = _csvData.length < _first ? csvData.length : _first;

    setTotalItems(_csvData.length);

    setData(
      _csvData.map((item: any, index: number) => ({
        ...item,
        index: index + 1,
      }))
    );
    let tempData = [
      ..._csvData.map((item: any, index: number) => ({
        ...item,
        index: index + 1,
        // product_qid: resolveQID(item.productitemoem_sn || ''),
      })),
    ];

    Promise.all(
      Array.from(
        Array(parseInt((tempData.length / first).toString())).keys()
      ).map((i, index) => {
        const formData = tempData
          .filter((item: any) => !item.productitemid)
          .slice(index * first, first * (i + 1))
          .map((item: any) => ({
            productitemoem_sn: item.productitemoem_sn,
            ...item,
          }));

        if (formData.length === 0) return [];
        return api
          .post('products', formData)
          .then(({ data }) => {
            setServerData([...serverData, ...data]);
            setResolvedItems((prev: any) => first * (index + 1));
            return data;
          })
          .catch((err) => {
            console.error(err);
            setResolvedItems(
              (prev: any) => first + resolvedItems,
              (s: any) => console.log('I am called after setState, state:', s)
            );
          });
      })
    )
      .then((res) => {
        let arr: { id: number; OEM_SN: string }[] = [];
        res.map((item) => {
          return item.map((i: { id: number; OEM_SN: string }) => arr.push(i));
        });

        // update all items
        arr.map((instance: any) => {
          let index = _.findIndex(tempData, {
            productitemoem_sn: instance.OEM_SN,
          });
          if (index !== -1) {
            let tempInstance = {
              ...tempData[index],
              productitemid: instance.id,
              angaza_id: instance.angaza_id,
              hash_top: instance.hash_top,
              hash_root: instance.hash_root,
              otp_count: instance.otp_count,
              hash_index: instance.hash_index,
              productitempayg_sn: instance.PAYG_SN,
              firmwareversion: instance.firmwareVersion?.replace(',', ''),
              lifecyclestatus: instance.lifeCycleStatus.replace(',', ''),
              productbatchid: instance.productBatchID,
              productbatchnumber: instance.productBatchNumber,
              productmodelname: instance.productModelName?.replace(',', ''),
              codevalue: instance.codeValue?.replace(',', ''),
            };
            tempData.splice(index, 1, tempInstance);
          }
          return tempData;
        });

        setLoadingCSV((prev: any) => false);
        setData(tempData);
        dispatch(ProvisionActions.setProvisionItems(tempData));

        dispatch(
          ProvisionActions.setUnresolvedItems(
            _.filter(tempData, (item) => !item.productitemid)
          )
        );
      })
      .catch((err) => {
        setLoadingCSV((prev: any) => false);
      });
  };

  const handleDarkSideForce = (err: any) => {};

  return (
    <div style={{ paddingLeft: 44, paddingRight: 44 }}>
      <GridContainer className='container notice'>
        <BackDrop loading={loadingCSV}>
          <RenderLoadingProgress />
        </BackDrop>
        <GridItem xs={12} sm={6}>
          <div className='left-content'>
            <Tooltip id='Import CSV' title='Upload CSV' placement='top'>
              <label
                htmlFor='csv-file'
                className='py-2 px-3 MuiButtonBase-root MuiIconButton-root border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-indigo-500 hover:border-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-800 transition duration-150 ease-in-out'
              >
                {loadingCSV ? (
                  <CircularProgress size={18} />
                ) : (
                  <>
                    <CloudUploadIcon color='inherit' />
                    &nbsp; Migrate
                  </>
                )}
              </label>
            </Tooltip>
            <CSVReader
              cssClass='csv-reader-input'
              label=''
              onFileLoaded={handleForce}
              onError={handleDarkSideForce}
              parserOptions={papaparseOptions}
              inputId='csv-file'
              inputStyle={{ color: 'black' }}
              onStartLoad={onStartLoad}
            />
          </div>
          <div>
            <p>.csv, .tsv spreadsheet accepted</p>
          </div>
          {unresolvedItems.data.length > 0 && (
            <Alert
              severity='warning'
              action={
                <Button onClick={retry} color='inherit' size='small'>
                  RETRY
                </Button>
              }
            >
              {unresolvedItems.data.length} we're not resolved. Want to retry?
            </Alert>
          )}
        </GridItem>
        <GridItem xs={12} sm={6}>
          <div className='content description'>
            You can upload any .csv, .tsv file with any set of columns as long
            as it has 1 record per row. The next step will allow you to preview
            your spreadsheet columns to the right data points. You'll be able to
            confirm the information before submiting.
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CSVUploader;
