import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientSimulator from '../utils/clientClientService';
import {
  GetAllServicers,
  GetAllServicersVariables,
} from './types/GetAllServicers';
import {
  GetSpecificServicer,
  GetSpecificServicerVariables,
} from './types/GetSpecificServicer';

/**
 * type Servicer {
_id: ID!
activeSubRolePermission: PermissionInput!
authenticationInstance: AuthenticationInstance!
authenticationSubInstance: AuthenticationSubInstance!
createdAt: DateTime
deleteAt: DateTime
deleteStatus: Boolean
description: String
name: String!
orgAddress: Address!
orgContactPerson: Person
role: Roles!
services: String!
subRolePermissions: SubRolePermissions!
type: OrgTypes!
updatedAt: DateTime
}
 */
const servicerFragment = gql`
  fragment Servicer on Servicer {
    activeSubRolePermission
    description
    orgContactPerson {
      contact {
        email
        phone
        social
      }
      _id
    }
    authenticationInstance {
      _id
      name
    }
    createdAt
    deleteAt
    deleteStatus
    name
    orgAddress {
      city
      country
      postcode
      srpc
      street
      unit
      addressLocation {
        addressLatitude
        addressLongitude
      }
    }

    role {
      _id
      name
    }
    services
    type
    updatedAt
    clientExclusiveService {
      _id
    }
    _id
  }

`;
/**
 * type ServicerEdge {
cursor: String
node: Servicer
}
 */
const servicerEdgeFragment = gql`
  ${servicerFragment}
  fragment ServicerEdge on ServicerEdge {
    cursor
    node {
      ...Servicer
    }
  }
`;
/**
 * type ServicerConnection {
edges: [ServicerEdge!]
pageInfo: ServicerPageInfo
}
 */
const servicerConnectionFragment = gql`
  ${servicerEdgeFragment}
  fragment ServicerConnection on ServicerConnection {
    edges {
      ...ServicerEdge
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
`;
/**
 * type GetAllServicersResponse {
page: ServicerConnection!
pageData: PageData
}
 */
const getAllServicersResponseFragment = gql`
  ${servicerConnectionFragment}
  fragment GetAllServicersResponse on GetAllServicersResponse {
    page {
      ...ServicerConnection
    }
    pageData {
      count
      limit
      offset
    }
  }
`;

/**
 * getAllServicers(
after: String
before: String
first: Int
last: Int
): GetAllServicersResponse!
 */
const getAllServicersQuery = gql`
  ${getAllServicersResponseFragment}
  query GetAllServicers(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllServicers(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      ...GetAllServicersResponse
    }
  }
`;

export const useLazyGetAllServicersQuery = (
  variables: GetAllServicersVariables
) =>
  useLazyQuery<GetAllServicers, GetAllServicersVariables>(
    getAllServicersQuery,
    {
      client: clientSimulator,
      variables,
    }
  );

/**
   * getSpecificServicer(
id: ID!
): Servicer
   */
const getSpecificServicerQuery = gql`
  ${servicerFragment}
  query GetSpecificServicer($id: ID!) {
    getSpecificServicer(id: $id) {
      ...Servicer
    }
  }
`;

export const useGetSpecificServicerQuery = (
  variables: GetSpecificServicerVariables
) =>
  useQuery<GetSpecificServicer, GetSpecificServicerVariables>(
    getSpecificServicerQuery,
    {
      client: clientSimulator,
      variables,
    }
  );
