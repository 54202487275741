import React, { FC, useContext, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import Button from '@components/CustomButtons/Button';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Formik } from 'formik';
import OVSForm from '@components/Form/OVSForm';
import { archiveSignalValidationSchema } from 'apps/things/app/validations';
import { SignalContext } from '../context/SignalProvider';
import useArchiveSignal from '../hooks/useArchiveSignal';
import useUnArchiveSignal from '../hooks/useUnArchiveSignal';

interface IProps {}

const ArchiveSignal: React.FC<IProps> = () => {
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [unArchiveModalOpen, setUnArchiveModalOpen] = useState(false);

  const { refetchItems: refetch } = useContext(SignalContext);

  const {
    mutation: archiveMutation,
    mutationOpts: archiveOpts,
  } = useArchiveSignal({
    onComplete: async () => {
      if (refetch) await refetch();
    },
  });

  const {
    mutation: unArchiveMutation,
    mutationOpts: unarchiveOpts,
  } = useUnArchiveSignal({
    onComplete: async () => {
      if (refetch) await refetch();
    },
  });

  const archive = async (values: any) => {
    await archiveMutation({
      variables: {
        start: values?.start,
        end: values?.end,
      },
    });
    setArchiveModalOpen(false);
  };

  const unarchive = async (values: any) => {
    await unArchiveMutation({
      variables: {
        start: values?.start,
        end: values?.end,
      },
    });
    setUnArchiveModalOpen(false);
  };

  interface IRenderForm {
    action: (values: any) => void;
    loading: boolean;
  }

  const RenderForm: FC<IRenderForm> = ({ action, loading }) => {
    return (
      <Formik
        onSubmit={(values: any, { resetForm }) => {
          action(values);
          resetForm();
        }}
        initialValues={{
          end: '',
          start: '',
        }}
        validationSchema={archiveSignalValidationSchema}
      >
        {(formBag) => {
          return (
            <GridContainer>
              <GridItem sm={12}>
                <OVSForm
                  formFieldsData={[
                    {
                      name: '',
                      fields: [
                        {
                          md: 12,
                          type: 'date',
                          name: 'start',
                          label: 'Start Date',
                          value: formBag.values.start,
                          onChange: (e: any) => {
                            formBag.setFieldValue('start', e);
                          },
                          error: formBag.errors.start as string,
                        },
                        {
                          md: 12,
                          type: 'date',
                          name: 'End Date',
                          label: 'Time',
                          value: formBag.values.end,
                          onChange: (e: any) => {
                            formBag.setFieldValue('end', e);
                          },
                          error: formBag.errors.end as string,
                        },
                      ],
                    },
                  ]}
                />
                <Button
                  color='primary'
                  onClick={() => {
                    formBag.submitForm();
                  }}
                  style={{ marginLeft: 12 }}
                  disabled={loading}
                >
                  Save
                </Button>
              </GridItem>
            </GridContainer>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <Tooltip
        id='Archive'
        title='Archive'
        placement='top'
        onClick={() => {
          setArchiveModalOpen(true);
        }}
      >
        <label className='py-2 px-3 MuiButtonBase-root MuiIconButton-root border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-indigo-500 hover:border-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-800 transition duration-150 ease-in-out'>
          <ArchiveIcon color='inherit' />
        </label>
      </Tooltip>
      <Tooltip
        id='Unarchive'
        title='Unarchive'
        placement='top'
        onClick={() => {
          setUnArchiveModalOpen(true);
        }}
      >
        <label className='py-2 px-3 MuiButtonBase-root MuiIconButton-root border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-indigo-500 hover:border-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-800 transition duration-150 ease-in-out'>
          <UnarchiveIcon color='inherit' />
        </label>
      </Tooltip>

      <DrawerSidebar
        isModalOpen={archiveModalOpen}
        toggleModal={setArchiveModalOpen}
        title='Archive'
        styles={{ maxWidth: '30vw', minWidth: '400px' }}
      >
        <RenderForm action={archive} loading={archiveOpts.loading} />
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={unArchiveModalOpen}
        toggleModal={setUnArchiveModalOpen}
        title='Unarchive'
        styles={{ maxWidth: '30vw', minWidth: '400px' }}
      >
        <RenderForm action={unarchive} loading={unarchiveOpts.loading} />
      </DrawerSidebar>
    </>
  );
};

export default ArchiveSignal;
