import React from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

interface PhoneNumberInputProps extends PhoneInputProps {
  label?: string;
  error?: string;  // Add error prop to show validation message
}

const useStyles = makeStyles(() => ({
  root: {
    // Add your custom styles here
    '& .react-tel-input': {
      width: '100%',
      // More styles can be added here
    },

    ' & .react-tel-input .form-control': {
      borderTop: "0px !important",
      borderRight: "0px !important",
      borderLeft: "0px !important",
      borderRadius: "0px !important",
      width: "100%",

      '&:focus': {
        borderBottom: `2px solid ${primaryColor[0]}`,  // Add any hover styles you want here
      }
    },

    '& .react-tel-input .flag-dropdown': {
      borderTop: "0px !important",
      borderRight: "0px !important",
      borderLeft: "0px !important",
      borderRadius: "0px !important",

      '&:focus': {
        borderBottom: '2px solid blue',  // Add any hover styles you want here
      }
    },
    '& input': {
      fontSize: '16px',
      padding: '10px',
      // Add more custom input styles here
    },
    '& label': {
      fontSize: "10px"
    },

    '& .error-message': {
      color: 'red',
      fontSize: '14px',
      marginTop: '1rem',
    }
  },
}));

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  label,
  error,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{marginTop: "4.2px"}}>
      {label && <label>{label}</label>}
      <PhoneInput
        country={'us'}
        {...props} // Pass down any other props like value, onChange, etc.
      />
      {error && <div className="error-message">{error}</div>}  {/* Display error message */}
    </div>
  );
};

export default PhoneNumberInput;
