import { Form, FormikProps } from "formik";
// @material-ui/icons
import Typography from "@material-ui/core/Typography";

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
import React, { useState } from "react";
import { codeGenerator } from "../utils";
import {
    InitializeCodeGenInput,
} from "apps/things/app/types/thingsGlobalTypes";
import DaysGenInput from "./DaysGenInput";
import { CodeGeneratorContext } from "../context/CodeGeneratorProvider";
import { useAuth } from "admin/auth/context/AuthProvider";
import ActionModal from "@components/Modal/ActionModal";
import useNotifier from "hooks/useNotifier";
import Button from "@components/CustomButtons/Button";

const CodeGenerationRequestForm: React.FC<{
    formBag: FormikProps<InitializeCodeGenInput>;
    loading: boolean;
    handleDaysCode: (days: number) => void;
    handleSendSms: (telephone: string, message:string) => void;
}> = (props) => {
    const {
        loading,
        handleDaysCode,
        handleSendSms
    } = props;

    const {
        codeDays,
        setCodeDays,
        otpDec,
        item, 
        // remDays,
    } = React.useContext(CodeGeneratorContext);
    const [codeGenErrors, setCodeGenErrors] = useState("");
    const { isDistributor} = useAuth();
    const notify = useNotifier();
    const [confirmDayCode, setConfirmDayCode] = useState(false);


    const handleDaysChange = () => {
        if (codeGenErrors) return;
        handleDaysCode(codeDays);
    };

    const handleSetDayCode = (e: number) => {
        if (e > codeGenerator.codeLimit || e === 0) {
            setCodeGenErrors(`Maximum day code is ${codeGenerator.codeLimit}.`);
            return;
        }
        setCodeGenErrors("");
        setCodeDays(e);
    };

    const handleSendMessage = () => {
        let phone_no = item?.assetAccount?.credit?.owner?.contact?.phone
        if(phone_no?.charAt(0) !== '+') {
            phone_no = `+${item?.assetAccount?.credit?.owner?.contact?.phone}`
        }
        let message = `
            Dear Customer, Solarworks received payment of ${codeDays} day(s) 
            for Acc. ${item.sellerItemID}. Token is: ${otpDec} for ${codeDays} day(s).`
        handleSendSms(phone_no, message)
    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Form>
                    <GridContainer>
                        <GridItem md={isDistributor ? 12 : 6} xs={12}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Typography variant="h5" gutterBottom>
                                        Code Generator
                                    </Typography>
                                </GridItem>
                                <GridItem md={9} xs={12}>
                                    <DaysGenInput
                                        onChange={handleSetDayCode}
                                        onBtnClick={() => {
                                            if (!codeDays) {
                                                notify({
                                                    status: "error",
                                                    text: "OTP code days should be more than 0",
                                                });
                                                return;
                                            }
                                            setConfirmDayCode(true);
                                        }}
                                        loading={loading}
                                        errors={codeGenErrors}
                                    />
                                </GridItem>
                                <GridItem sm={3}>
                                    <Button
                                    color="success"
                                    onClick={() => {
                                    handleSendMessage()
                                    }}
                                    style={{ marginTop: 25}}
                                    size="sm"
                                    disabled={!otpDec}
                                    >SEND</Button>
                                </GridItem>
                                <GridItem xs={12}></GridItem>

                                <GridItem xs={12}>
                                    <p> <b>Acccount No:</b> <span>{item.sellerItemID}</span></p>
                                    <hr></hr>
                                    <p>Day Code: <span>{otpDec}</span></p>
                                    <hr></hr>
                                    <p>Phone Number: <span>{item?.assetAccount?.credit?.owner?.contact?.phone}</span></p>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </Form>

                <ActionModal
                    isModalOpen={confirmDayCode}
                    toggleModal={setConfirmDayCode}
                    handleOnClickOkey={() => {
                        handleDaysChange();
                        setConfirmDayCode(false);
                    }}
                    handleOnClickCancel={() => setConfirmDayCode(false)}
                    okText={"Confirm"}
                >
                    <div>
                        Are you sure you want to generate day code? This action is not
                        reversible.
                    </div>
                </ActionModal>
            </GridItem>
        </GridContainer>
    );
};

export default CodeGenerationRequestForm;
