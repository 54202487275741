import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import React, { useContext } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useMutation } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import useNotifier from 'hooks/useNotifier';
import { LoginUserContext } from '../context/LoginUserContext';
import {
  DeleteLoginUser,
  DeleteLoginUserVariables,
} from '../types/DeleteLoginUser';
import { deleteLoginUserMutation } from '../mutations';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const { getItems: refetch } = useContext(LoginUserContext);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [deleteItem] = useMutation<DeleteLoginUser, DeleteLoginUserVariables>(
    deleteLoginUserMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        refetch();
        setIsModalOpen(false);
      },
    }
  );
  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
        <Tooltip
          id='expander'
          title='Edit'
          placement='top'
          classes={{ tooltip: classes.tooltip }}

        >
          <IconButton
            id='expander'
            aria-label='Edit'
            className={classes.tableActionButton}
            style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
          >
            <Edit
              className={classes.tableActionButtonIcon + ' ' + classes.edit}
            />
          </IconButton>
        </Tooltip>
        {(true) && (
          <span className={classes.stopSign} role="img" aria-label="Stop">
            🚫
          </span>
        )}
      </div>
      <HoverDropdown
        isDistributor={true}
        menus={[
          {
            name: "",
            action: () => {
            },
          },
        ]}
      />
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deleteItem({
            variables: { userId: row.values['node._id'] },
          })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this user?</div>
      </ActionModal>
    </div>
  );
};

export default TableActions;
