import React, { useContext } from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { AssetAccountContext } from '../../apps/accounts/app/assetAccount/context/AssetAccountProvider'

interface IProps {
  title?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    arrow: {
      cursor: 'pointer',
      marginRight: 20,
    },
  })
);
const GoBackHeader: React.FC<IProps> = ({ title }) => {
  const classes = useStyles();
  const history = useNavigate();
  const value = useContext(AssetAccountContext);

  
  return (
    <div className={classes.wrapper}>
      <div className={classes.arrow} title='go back'>
        <KeyboardBackspaceIcon
          onClick={() => {
            history(-1);
            value.resetItemContext()
          }}
        />
      </div>
      <h4 style={{ visibility: 'hidden' }}>{title}</h4>
    </div>
  );
};

export default GoBackHeader;
