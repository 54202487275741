import DescriptionCell from '@components/ReactTable/DescriptionCell';
import ImageCell from 'apps/ecommerce/app/products/components/TableCells/ImageCell';
import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyTableActions';

export const legacyTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <Actions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    { Header: 'distributorID', accessor: 'distributorID' },
    {
      Header: 'customerName',
      accessor: 'customerName',
    },
    {
      Header: 'Migrated',
      accessor: 'distributorAccountNo',
    },
    {
      Header: 'contactLastName',
      accessor: 'contactLastName',
    },
    {
      Header: 'contactFirstName',
      accessor: 'contactFirstName',
    },
    {
      Header: 'contactEmail',
      accessor: 'contactEmail',
    },
    {
      Header: 'distributorProfileURL',
      accessor: 'distributorProfileURL'
    },
    {
      Header: 'phone',
      accessor: 'phone',
    },
    {
      Header: 'addressLine1',
      accessor: 'addressLine1',
      Cell: DescriptionCell,
    },
    {
      Header: 'city',
      accessor: 'city',
    },
    {
      Header: 'state',
      accessor: 'state',
    },
    {
      Header: 'postalCode',
      accessor: 'postalCode',
    },
    {
      Header: 'country',
      accessor: 'country',
    },

    {
      Header: '',
      accessor: 'flagImage',
      Cell: ImageCell,
    },
    {
      Header: 'creditLimit',
      accessor: 'creditLimit',
    },
  ],
};
