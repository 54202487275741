/**
 * type ItemFirmware {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
type: ActorTypes!
actionScope: ActionScope!
actorName: String!
profile: String!
version: VersionNumbers!
supportedCodes: [CodeTypes!]!
description: String
}
 */

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import clientSimulator from 'utils/clientSimulator';
import { pageDataFragment } from '../sku/queries';
import {
  GetAllItemFirmwares,
  GetAllItemFirmwaresVariables,
} from './types/GetAllItemFirmwares';
import {
  GetSpecificItemFirmware,
  GetSpecificItemFirmwareVariables,
} from './types/GetSpecificItemFirmware';

export const itemFirmwareFragment = gql`
  fragment ItemFirmware on ItemFirmware {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    type
    actionScope
    codeSystem
    actorName
    profile
    version
    description
  }
`;

/**
 * type ItemFirmwareEdge {
cursor: String
node: ItemFirmware
}
 */
export const itemFirmwareEdgeFragment = gql`
  ${itemFirmwareFragment}
  fragment ItemFirmwareEdge on ItemFirmwareEdge {
    cursor
    node {
      ...ItemFirmware
    }
  }
`;
/**
 * type ItemFirmwarePageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
export const ItemFirmwarePageInfoFragment = gql`
  fragment ItemFirmwarePageInfo on ItemFirmwarePageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type ItemFirmwareConnection {
edges: [ItemFirmwareEdge!]
pageInfo: ItemFirmwarePageInfo
}
 */

export const itemFirmwareConnectionFragment = gql`
  ${itemFirmwareEdgeFragment}
  ${ItemFirmwarePageInfoFragment}
  fragment ItemFirmwareConnection on ItemFirmwareConnection {
    edges {
      ...ItemFirmwareEdge
    }
    pageInfo {
      ...ItemFirmwarePageInfo
    }
  }
`;

/**
 * type GetAllItemFirmwaresResponse {
page: ItemFirmwareConnection!
pageData: PageData
}
 */
export const getAllItemFirmwaresResponseFragment = gql`
  ${itemFirmwareConnectionFragment}
  ${pageDataFragment}
  fragment GetAllItemFirmwaresResponse on GetAllItemFirmwaresResponse {
    page {
      ...ItemFirmwareConnection
    }
    pageData {
      ...PageData
    }
  }
`;

/**
 * query {
  getAllItemFirmwares {
    _id
  }
}
 */
export const getAllItemFirmwaresQuery = gql`
  ${getAllItemFirmwaresResponseFragment}
  query GetAllItemFirmwares(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $search: String
  ) {
    getAllItemFirmwares(
      before: $before
      after: $after
      first: $first
      last: $last
      search: $search
    ) {
      ...GetAllItemFirmwaresResponse
    }
  }
`;
export const getAllItemFirmwaresSimpleQuery = gql`
  query GetAllFirmwareSimple(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllItemFirmwares(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      page {
        edges {
          node {
            _id
            version
          }
        }
      }
      __typename
    }
  }
`;
/**
getSpecificItemFirmware(
id: String!
): ItemFirmware!
 */
export const getSpecificItemFirmwareQuery = gql`
  ${itemFirmwareFragment}
  query GetSpecificItemFirmware($id: String!) {
    getSpecificItemFirmware(id: $id) {
      ...ItemFirmware
    }
  }
`;

export const useGetAllItemFirmwaresQuery = (
  variables: GetAllItemFirmwaresVariables
) =>
  useQuery<GetAllItemFirmwares>(getAllItemFirmwaresQuery, {
    variables,
    client: clientSimulator,
  });

export const useLazyGetAllItemFirmwaresQuery = (
  variables: GetAllItemFirmwaresVariables
) =>
  useLazyQuery<GetAllItemFirmwares>(getAllItemFirmwaresQuery, {
    variables,
    client: clientSimulator,
  });

export const useGetSpecificItemFirmwareQuery = (
  variables: GetSpecificItemFirmwareVariables
) =>
  useQuery<GetSpecificItemFirmware, GetSpecificItemFirmwareVariables>(
    getSpecificItemFirmwareQuery,
    {
      client: clientSimulator,
      variables,
    }
  );
