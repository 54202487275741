import CardWrapper from '@components/Card/CardWrapper';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import React from 'react';
import ContentLoader from 'react-content-loader';

const CardGridLoader = () => {
  return (
    <CardWrapper>
      <GridContainer justify='center'>
        {Array.from(Array(1)).map((item) => (
          <GridItem key={item + '-loader'} md={12}>
            <ContentLoader
              viewBox='0 0 400 160'
              height={'100%'}
              width={400}
              backgroundColor='transparent'
            >
              <circle cx='150' cy='86' r='8' />
              <circle cx='194' cy='86' r='8' />
              <circle cx='238' cy='86' r='8' />
            </ContentLoader>
          </GridItem>
        ))}
      </GridContainer>
    </CardWrapper>
  );
};

export default CardGridLoader;
