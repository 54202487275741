import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
interface IProps { addItemPageLink?: string; }

const useStyles = makeStyles({
  paragraph: {
    color: '#777',
    paddingHorizontal: 30
  },
});
const NoDataFound: React.FC<IProps> = ({ addItemPageLink }) => {
  const history = useNavigate();
  const classes = useStyles();
  return (
    <div>
      <p className={classes.paragraph}>
        This table has no data.
      </p>
      {addItemPageLink ? (
        <div>
          <Button
            onClick={() => addItemPageLink && history(addItemPageLink)}
          >
            <AddIcon />
            &nbsp; Add
          </Button>
        </div>) : null}
    </div>

  );
};

export default NoDataFound;
