import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import clientSimulator from 'utils/clientSimulator';
import {
  GetAllLoginUsers,
  GetAllLoginUsers_getAllLoginUsers_page_edges,
} from '../types/GetAllLoginUsers';
import { useLazyGetAllLoginUsersQuery } from '../queries';
import {
  DeleteLoginUser,
  DeleteLoginUserVariables,
} from '../types/DeleteLoginUser';
import { deleteLoginUserMutation } from '../mutations';
import { PAGE_LIST_LIMIT } from '@constants';

export interface ILoginUserContext {
  items: GetAllLoginUsers_getAllLoginUsers_page_edges[];
  setItems: (itemSKUs: GetAllLoginUsers_getAllLoginUsers_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllLoginUsers>>)
    | undefined;
}

export const LoginUserContext = React.createContext<ILoginUserContext>(
  {} as ILoginUserContext
);

const OrganizationProvider: React.FC<IProps> = ({ children }) => {
  const [items, setItems] = React.useState<
    GetAllLoginUsers_getAllLoginUsers_page_edges[]
  >([] as GetAllLoginUsers_getAllLoginUsers_page_edges[]);

  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  /// hooks
  const notify = useNotifier();
  const [
    getItems,
    { refetch: refetchItems, data, loading },
  ] = useLazyGetAllLoginUsersQuery({
    first: PAGE_LIST_LIMIT,
  });

  useEffect(() => {
    setItems(data?.getAllLoginUsers?.page?.edges || []);
  }, [data]);

  const [deleteItem] = useMutation<DeleteLoginUser, DeleteLoginUserVariables>(
    deleteLoginUserMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {},
    }
  );

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            userId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllLoginUsers?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(itemsFetched?.data?.getAllLoginUsers?.page?.edges || items);
          setTableActionLoading(false);
        } else {
          getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      getItems,
      refetchItems,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} user(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items]
  );

  return (
    <LoginUserContext.Provider value={value}>
      {children}
    </LoginUserContext.Provider>
  );
};

export default OrganizationProvider;
