import CardGridLoader from '@components/Loaders/CardGridLoader';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { UpdateServicerInput } from '../../types/clientServiceGlobalTypes';
import EditItem from '../components/EditItem';
import { itemRoutes } from '../constants';
import { ServicerContext as Context } from '../context/ServicerProvider';
import { useUpdateServicerMutation } from '../mutation';
import { useGetSpecificServicerQuery } from '../queries';
import * as _ from 'lodash'

const EditItemContainer = () => {
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(Context);

  const [item, setItem] = React.useState({} as UpdateServicerInput);

  const { data, loading } = useGetSpecificServicerQuery({ id: itemId });



  useEffect(() => {
    const address = data?.getSpecificServicer?.orgAddress
    setItem({
      contactRole: data?.getSpecificServicer?.services,
      servicerAddress: {
        city: _.get(address, 'city'),
        country: _.get(address, 'country'),
        postcode: _.get(address, 'postcode'),
        street: _.get(address, 'street'),
        srpc: _.get(address, 'srpc'),
        unit: _.get(address, 'unit'),
        UpdateLocationAddressDto: {
          lat: _.get(address, 'addressLocation.addressLatitude'),
          lng: _.get(address, 'addressLocation.addressLongitude'),
        }
      },
      servicerDescription: data?.getSpecificServicer?.description,
      servicerId: data?.getSpecificServicer?._id || '',
      servicerName: data?.getSpecificServicer?.name,
      servicerType: data?.getSpecificServicer?.type,

    }); // eslint-disable-next-line
  }, [data]);

  const { mutation, loading: isUpdating } = useUpdateServicerMutation({
    callbackFn: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateServicerInput) => {
    mutation({
      variables: {
        updateServicerInput: {
          contactRole: input?.contactRole,
          servicerAddress: input?.servicerAddress,
          servicerContactPerson: input?.servicerContactPerson,
          servicerDescription: input?.servicerDescription,
          servicerId: itemId || '',
          servicerName: input?.servicerName,
          servicerType: input?.servicerType
        },
      },
    });
  };

  if (!item?.servicerId || loading || isUpdating) return <CardGridLoader />;
  return <EditItem
    handleSubmit={handleSubmit}
    isLoading={isUpdating}
    item={item}
  />
}

export default EditItemContainer

