import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/itemTableData';
import CardWrapper from '@components/Card/CardWrapper';
import { itemsRoutes } from '../constants';
import useClientItem from '../hooks/useClientIFleettem';

interface IRTable {
  title?: string;
  itemFleetId?: string;
}

const DistributorItemsPage: React.FC<IRTable> = () => {
  const { items, loading } = useClientItem();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              itemTableData({}).header as Column[]
            }
            data={items}
            checkedIds={[]}
            tableActions={[]}
            isLoading={loading}
            addItemPageLink={itemsRoutes.add}
            addItemBtnText='Add'
            hideRightToolbar
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
export default DistributorItemsPage;
