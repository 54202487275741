import React from 'react';
import ItemPage from '../components/ItemPage';

const Item = (props: any) => {
  return (
      <ItemPage />
  );
};

export default Item;
