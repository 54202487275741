import moment from "moment";

export const formatListDate = (
  row: { node: { createdAt: string; updatedAt: string } },
  key: "createdAt" | "updatedAt"
) => {
  if (!row) return;
  return moment(row.node[key]).format("MM-DD-YYYY");
};
export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const getDefaultDate = () => {
  const d = new Date();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  let monthString = String(month);
  if (String(month).length < 2) {
    monthString = `0${month}`;
  }
  return String(year).slice(-2) + monthString;
};

export const defaultProductType = "AH12";
export function generateStringList(start=0) {
  const result = [];

  for (let i = 0; i <= 999900; i++) {
    const paddedNumber = i.toString().padStart(6, "0"); // Pad with zeros to ensure six digits
    result.push(paddedNumber);
  }

  return result;
}
export const getDefaultSequence = (
  itemNumber: string,
  defaultLength = 4,
  maxSequence = 9999
) => {
  if (!itemNumber) return defaultLength === 4 ? "0001" : "000001";
  let result = "";

  const lastSequence = itemNumber.slice(-defaultLength);
  let lastSequenceNum = parseInt(lastSequence) + 1;
  if (isNaN(lastSequenceNum)) {
    return "0001";
  }

  if (lastSequenceNum > maxSequence) {
    lastSequenceNum = 0;
  }

  let lastSequenceString = lastSequenceNum.toString();
  result = lastSequenceString;

  for (
    let index = 0;
    index < defaultLength - lastSequenceString.length;
    index++
  ) {
    result = "0" + result;
  }

  return result;
};

export const getBatchNumber = ({
  productType,
  date,
  sequence = "001",
  usedIn
}: {
  productType: string;
  date: string;
  sequence: string;
  usedIn?: string
}) => {

  if(usedIn === "items") {
    let length = 14 - (productType.length + date.length)
    sequence = 'x'.repeat(length)
  }
  return `${productType}${date}${sequence}`;
};
export function countTrailingXs(str:string) {
  let count = 0;
  for (let i = str.length - 1; i >= 0; i--) {
      if (str[i] === 'x') {
          count++;
      } else {
          break;
      }
  }
  return count;
}

export const secondsToDHMS = (seconds: number) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
};
