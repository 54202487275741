import DateCell from '@components/ReactTable/DateCell';
import React from 'react';
import { Row } from 'react-table';
import TableActions from '../components/TableActions';

export const itemTableData = () => ({
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      Cell: ({ row }: { row: Row }) => (
        <TableActions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    {
      Header: 'Name',
      accessor: 'node.name',
    },

    {
      Header: ' URL',
      accessor: 'node.url',
    },
    {
      Header: ' Port',
      accessor: 'node.port',
    },
    {
      Header: ' Password',
      accessor: 'node.password',
    },
    {
      Header: ' Login',
      accessor: 'node.login',
    },

    {
      Header: 'Date Created',
      accessor: 'node.createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated',
      accessor: 'node.updatedAt',
      Cell: DateCell,
    },
    { Header: 'ID', accessor: 'node._id' },
  ],
});
