import { gql, useMutation } from '@apollo/client';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { successFragment } from '../common/queries';
import { categoryFragment } from './queries';
import {
  CreateCategory,
  CreateCategoryVariables,
} from './types/CreateCategory';
import {
  DeleteCategory,
  DeleteCategoryVariables,
} from './types/DeleteCategory';
import {
  UpdateCategory,
  UpdateCategoryVariables,
} from './types/UpdateCategory';

/**
 * 
Mutation.createCategory(
createCategoryInput: CreateCategoryInput!
): Category!
 */
export const createCategoryMutation = gql`
  ${categoryFragment}
  mutation CreateCategory($createCategoryInput: CreateCategoryInput!) {
    createCategory(createCategoryInput: $createCategoryInput) {
      ...Category
    }
  }
`;

/**
 * deleteCategory(
categoryId: String!
): Success!
 */
export const deleteCategoryMutation = gql`
  ${successFragment}
  mutation DeleteCategory($categoryId: String!) {
    deleteCategory(categoryId: $categoryId) {
      ...Success
    }
  }
`;

/**
 * updateCategory(
updateCategoryInput: UpdateCategoryInput!
): Category!
 */
const updateCategoryMutation = gql`
  ${categoryFragment}
  mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput!) {
    updateCategory(updateCategoryInput: $updateCategoryInput) {
      ...Category
    }
  }
`;

export const useUpdateCategory = (callBack: () => void) => {
  const [updateCategory, updateCategoryOpts] = useMutation<
    UpdateCategory,
    UpdateCategoryVariables
  >(updateCategoryMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    updateCategory,
    updateCategoryOpts,
  };
};

export const useCreateCategory = (callBack: (success?: boolean) => void) => {
  const [createCategory, createCategoryOpts] = useMutation<
    CreateCategory,
    CreateCategoryVariables
  >(createCategoryMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack(true);
    },
    onError: (err) => {
      console.error(err);
      callBack && callBack(false);
    },
  });

  return {
    createCategory,
    createCategoryOpts,
  };
};

export const useDeleteCategory = (callBack: () => void) => {
  const [deleteCategory, deleteCategoryOpts] = useMutation<
    DeleteCategory,
    DeleteCategoryVariables
  >(deleteCategoryMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    deleteCategory,
    deleteCategoryOpts,
  };
};
