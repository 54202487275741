import { AddressDto } from '../../types/clientServiceGlobalTypes';

export const itemFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  age: 'age',
  hireDate: 'hireDate',
  email: 'email',
  phone: 'agentContact.phone',
  social: 'agentContact.social',
  agentCity: 'agentAddress.city',
  agentCountry: 'agentAddress.country',
  agentPostcode: 'agentAddress.postcode',
  agentSrpc: 'agentAddress.srpc',
  agentStreet: 'agentAddress.street',
  agentUnit: 'agentAddress.unit',
  agentAddressLongitude:
    'agentAddress.locationAddressDto.inputAddressLongitude',
  staffAddressLatitude:
    'agentAddress.locationAddressDto.inputAddressLatitude',
    officeCity: 'officeAddress.city',
    officeCountry: 'officeAddress.country',
    officePostcode: 'officeAddress.postcode',
    officeSrpc: 'officeAddress.srpc',
    officeStreet: 'officeAddress.street',
    officeUnit: 'officeAddress.unit',
    officeAddressLongitude:
      'officeAddress.locationAddressDto.inputAddressLongitude',
    officeAddressLatitude:
      'officeAddress.locationAddressDto.inputAddressLatitude',

  typeOfAgent: 'typeOfAgent',
  agentType: 'agentType'
};

export const defaultAddress = {
  city: '',
  country: '',
  locationAddressDto: {
    inputAddressLatitude: 0,
    inputAddressLongitude: 0,
  },
  postcode: '',
  srpc: '',
  street: '',
  unit: '',
};

export const defaultItem = {
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  age: "",
  hireDate: new Date(),
  agentContact: { phone: '', social: '' },
  officeAddress: defaultAddress as AddressDto,
  agentAddress: defaultAddress as AddressDto,
  typeOfAgent: "",
  agentType: ""
};

export const itemRoutes = {
  list: '/admin/agents',
  add: '/admin/agents/add',
  edit: '/admin/customers/:itemId',
  getEditLink: (itemId: string) => `/admin/customers/${itemId}`,
};
