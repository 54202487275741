import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LegacyFamilyContext as Context } from '../context/LegacyFamilyContext';
import { legacyRoutes } from '../constants';
import LegacyAddItem from '../components/LegacyAddItem';
import { IProductFamily as ItemType } from '../types';
import * as actions from '../redux/actions';

const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();

  const { getItems, loading } = React.useContext(Context);

  const handleSubmit = (createItemInput: ItemType) => {
    actions.LegacyActions.createLegacyRequest({
      ...createItemInput,
    }).then(async (res) => {
      notify({
        status: 'success',
        text: `Created successfully.`,
      });
      getItems();
      history(legacyRoutes.list);
    });
  };

  if (loading) return <GridLoader />;

  return (
    <LegacyAddItem
      initialValues={{
        productFamilyID: '',
        productFamilyName: '',
        productCategoryID: '',
        productFamilyDescription: '',
        image: '',
      }}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
};

export default AddItemContainer;
