import { PAGE_LIST_LIMIT } from '@constants';
import { userPrefTypes } from 'store/actionTypes/userPrefTypes';

let INITIAL_STATE = {
  isProgress: false,
  columns: {},
  rowsPerPage: PAGE_LIST_LIMIT,
};

export function userPrefReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case userPrefTypes.TOGGLE_COLUMN:
      return {
        ...state,
        columns: { ...state.columns, ...action.payload.body },
      };
    case userPrefTypes.SET_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.payload };
    default:
      return state;
  }
}
