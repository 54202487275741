import { gql, useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import clientEcommerce from 'apps/ecommerce/app/utils/clientEcommerce';
import { handleGQLErrors } from 'utils/gqlErrors';
import { successFragment } from '../common/queries';
import { productSelectorFragment } from './queries';
import {
  CreateProductSelector,
  CreateProductSelectorVariables,
} from './types/CreateProductSelector';
import {
  DeleteProductSelector,
  DeleteProductSelectorVariables,
} from './types/DeleteProductSelector';
import {
  UpdateProductSelector,
  UpdateProductSelectorVariables,
} from './types/UpdateProductSelector';

/**
 * deleteProductSelector(
productSelectorId: String!
): Success!
 */
const deleteProductSelectorMutation = gql`
  ${successFragment}
  mutation DeleteProductSelector($productSelectorId: String!) {
    deleteProductSelector(productSelectorId: $productSelectorId) {
      ...Success
    }
  }
`;

/**
 * updateProductSelector(
updateProductSelectorInput: UpdateProductSelectorInput!
): ProductSelector!
 */
const updateProductSelectorMutation = gql`
  ${productSelectorFragment}
  mutation UpdateProductSelector(
    $updateProductSelectorInput: UpdateProductSelectorInput!
  ) {
    updateProductSelector(
      updateProductSelectorInput: $updateProductSelectorInput
    ) {
      ...ProductSelector
    }
  }
`;

/**
 * createProductSelector(
createProductSelectorInput: CreateProductSelectorInput!
): ProductSelector!
 */
const createProductSelectorMutation = gql`
  ${productSelectorFragment}
  mutation CreateProductSelector(
    $createProductSelectorInput: CreateProductSelectorInput!
  ) {
    createProductSelector(
      createProductSelectorInput: $createProductSelectorInput
    ) {
      ...ProductSelector
    }
  }
`;

export const useCreateProductSelector = (
  callBack: (success?: boolean) => void
) => {
  const [createProductSelector, createProductSelectorOpts] = useMutation<
    CreateProductSelector,
    CreateProductSelectorVariables
  >(createProductSelectorMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack(true);
    },
    onError: (err) => {
      console.error(err);
      callBack && callBack(false);
    },
  });

  return {
    createProductSelector,
    createProductSelectorOpts,
  };
};

export const useUpdateProductSelector = (callBack: () => void) => {
  const notify = useNotifier();
  const [updateProductSelector, updateProductSelectorOpts] = useMutation<
    UpdateProductSelector,
    UpdateProductSelectorVariables
  >(updateProductSelectorMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
      err && handleGQLErrors(notify, err);
    },
  });

  return {
    updateProductSelector,
    updateProductSelectorOpts,
  };
};

export const useDeleteProductSelector = (callBack: () => void) => {
  const [deleteProductSelector, deleteProductSelectorOpts] = useMutation<
    DeleteProductSelector,
    DeleteProductSelectorVariables
  >(deleteProductSelectorMutation, {
    client: clientEcommerce,
    onCompleted: (data) => {
      callBack && callBack();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    deleteProductSelector,
    deleteProductSelectorOpts,
  };
};
