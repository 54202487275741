import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import clientSimulator from 'utils/clientSimulator';
import { pageDataFragment } from '../sku/queries';
import {
  GetAllCodeSystems,
  GetAllCodeSystemsVariables,
} from './types/GetAllCodeSystems';

/**
 * type CodeSystem {
_id: ID!
deleteStatus: Boolean
deleteAt: DateTime
createdAt: DateTime
updatedAt: DateTime
system: CodeSystemType!
hashChainLength: Int!
hashMaxJump: Int!
freeCodeJump: Int!
resetCodeJump: Int!
codeRange: [CodeTypes!]!
}
 */
const codeSystemFragment = gql`
  fragment CodeSystem on CodeSystem {
    _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    system
    hashChainLength
    hashMaxJump
    freeCodeJump
    resetCodeJump
    codeRange
  }
`;
/**
 * type CodeSystemEdge {
cursor: String
node: CodeSystem
}
 */
const codeSystemEdgeFragment = gql`
  ${codeSystemFragment}
  fragment CodeSystemEdge on CodeSystemEdge {
    cursor
    node {
      ...CodeSystem
    }
  }
`;

/**
 * type CodeSystemPageInfo {
startCursor: String
endCursor: String
hasPreviousPage: Boolean!
hasNextPage: Boolean!
}
 */
const codeSystemPageInfoFragment = gql`
  fragment CodeSystemPageInfo on CodeSystemPageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
/**
 * type CodeSystemConnection {
edges: [CodeSystemEdge!]
pageInfo: CodeSystemPageInfo
}
 */
const codeSystemConnectionFragment = gql`
  ${codeSystemEdgeFragment}
  ${codeSystemPageInfoFragment}
  fragment CodeSystemConnection on CodeSystemConnection {
    edges {
      ...CodeSystemEdge
    }
    pageInfo {
      ...CodeSystemPageInfo
    }
  }
`;
/**
 * type GetAllCodeSystemsResponse {
page: CodeSystemConnection!
pageData: PageData
}
 */
const getAllCodeSystemsResponseFragment = gql`
  ${codeSystemConnectionFragment}
  ${pageDataFragment}
  fragment GetAllCodeSystemsResponse on GetAllCodeSystemsResponse {
    page {
      ...CodeSystemConnection
    }
    pageData {
      ...PageData
    }
  }
`;
/**
 * getAllCodeSystems(
before: String
after: String
first: Int
last: Int
): GetAllCodeSystemsResponse!
 */
const getAllCodeSystemsQuery = gql`
  ${getAllCodeSystemsResponseFragment}
  query GetAllCodeSystems(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    getAllCodeSystems(
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...GetAllCodeSystemsResponse
    }
  }
`;

export const useGetAllCodeSystemsQuery = () =>
  useQuery<GetAllCodeSystems, GetAllCodeSystemsVariables>(
    getAllCodeSystemsQuery,
    {
      client: clientSimulator,
      variables: { first: 100 },
    }
  );
