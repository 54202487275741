import Button from "@components/CustomButtons/Button";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import GridContainer from "@components/Grid/GridContainer";
import GridHalfWrapper from "@components/Grid/GridHalfWrapper";
import { ButtonGroup } from "@material-ui/core";
import { PairAssetAccountInput } from "apps/accounts/app/types/accountsGlobalTypes";
import { FormikProps } from "formik";
import useNotifier from "hooks/useNotifier";
import React, { useContext, useEffect } from "react";
import { accountStatuses } from "../../constants";
import { AssetAccountContext } from "../../context/AssetAccountProvider";
import useActivateAssetAccount from "../../hooks/useActivateAssectAccount";
import { Payment } from "../../types/Payment";
import AccountActivitiesTable from "../AccountActivitiesTable";
import CreditAccountForm from "../CreditAccountForm";
import DebitAccountForm from "../DebitAccountForm";
import { useAuth } from "admin/auth/context/AuthProvider";



interface IProps {
  formBag: FormikProps<PairAssetAccountInput>;
  disableLoginButton: boolean;
  setCodeGenModalOpen: (open: boolean) => void;
  refetchItem?: () => void;
  paymentHistory?: Payment[];
  status?: string;
  assetAccountId: string;
  showActivities?: boolean;
  active: boolean;
}

const CreditActions: React.FC<IProps> = ({
  formBag,
  disableLoginButton,
  setCodeGenModalOpen,
  paymentHistory = [],
  refetchItem,
  status = "",
  showActivities,
  active,
  assetAccountId,
}) => {
  const { refetchItems, item, refetchClientItems } =
    useContext(AssetAccountContext);
  const { isDistributor } = useAuth();
  const [payHistoryModalOpen, setPayHistoryModalOpen] = React.useState(false);
  const [creditFormOpen, setCreditFormOpen] = React.useState(false);
  const [debitFormOpen, setDebitFormOpen] = React.useState(false);
  const notify = useNotifier();
  const { mutation: activateMutation, mutationOpts: activateMutationOpts } =
    useActivateAssetAccount({
      onComplete: () => {
        notify({ text: "Accounted activated successfully", status: "success" });
      },
    });
  useEffect(() => {
    if (status === accountStatuses.inactive) {
      activateMutation({
        variables: {
          assetAccountId,
        },
      });
    } // eslint-disable-next-line
  }, [status]);

  return (
    <>
      {item?._id && !showActivities ? (
        <GridContainer>
          <GridHalfWrapper>
            <ButtonGroup
              disableElevation
              color="primary"
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setCreditFormOpen(true);
                }}
                disabled={disableLoginButton || !active}
                style={{ width: "100%" }}
              >
                CREDIT
              </Button>
            </ButtonGroup>
          </GridHalfWrapper>
          <GridHalfWrapper>
            <ButtonGroup
              disableElevation
              color="primary"
              style={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setDebitFormOpen(true);
                }}
                disabled={
                  disableLoginButton || !active || activateMutationOpts.loading
                }
                style={{ width: "100%" }}
              >
                DEBIT
              </Button>
            </ButtonGroup>
          </GridHalfWrapper>
        </GridContainer>
      ) : null}
      {item?._id ? (
        <GridContainer>
          <GridHalfWrapper>
            <ButtonGroup
              disableElevation
              orientation="vertical"
              color="primary"
              style={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setPayHistoryModalOpen(true);
                }}
                disabled={disableLoginButton || !active}
              >
                Account Activities
              </Button>
            </ButtonGroup>
          </GridHalfWrapper>
        </GridContainer>
      ) : null}
      <DrawerSidebar
        isModalOpen={debitFormOpen}
        toggleModal={setDebitFormOpen}
        title="Debit"
        styles={{ maxWidth: "370px" }}
      >
        <DebitAccountForm
          assetAccountId={item?._id || ""}
          onComplete={(balance: number) => {
            if (!isDistributor) refetchItems && refetchItems();
            if (isDistributor) refetchClientItems && refetchClientItems();
            refetchItem && refetchItem();
          }}
          openCodeGenModal={() => {
            setCodeGenModalOpen(true);
          }}
          onGenerateCodeButton={() => setDebitFormOpen(false)}
          status={item?.credit?.accountStatus || ""}
        />
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={creditFormOpen}
        toggleModal={setCreditFormOpen}
        title="Credit"
        styles={{ maxWidth: "370px" }}
      >
        <CreditAccountForm
          customerName={""}
          assetAccountId={item?._id || ""}
          onComplete={(balance: number) => {
            setCreditFormOpen(false);
            formBag.setFieldValue("credit.balance", balance);
            if (!isDistributor) refetchItems && refetchItems();
            if (isDistributor) refetchClientItems && refetchClientItems();
            refetchItem && refetchItem();
          }}
          status={item?.credit?.accountStatus}
        />
      </DrawerSidebar>
      <DrawerSidebar
        isModalOpen={payHistoryModalOpen}
        toggleModal={setPayHistoryModalOpen}
        title={"Account Activities"}
        styles={{ maxWidth: "80vw" }}
      >
        <AccountActivitiesTable />
      </DrawerSidebar>
    </>
  );
};

export default CreditActions;
