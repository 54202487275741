import React from "react";
import { useNavigate } from "react-router-dom";
import AddItem from "../components/AddItem";
import { defaultItem, itemRoutes } from "../constants";

import useLogEvent from "../hooks/useLogEvent";
import {
  ActionTypes,
  ActorTypes,
  LocationInputDto,
  UpdateEventInput,
} from "../../types/eventsGlobalTypes";
import { EventContext } from "../context/EventProvider";

const AddItemContainer = () => {
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(EventContext);

  // update when client Id is provided
  const { mutation: createItem, mutationOpts: createItemOpts } = useLogEvent({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateEventInput) => {
    createItem({
      variables: {
        createEventInput: {
          action: {
            type: input.action?.type || ActionTypes.CLIENT,
            description: input.action?.description || "",
            label: input.action?.label || "",
          },
          actor: {
            type: input.actor?.type || ActorTypes.DEVICE,
            description: input.actor?.description || "",
            name: input.actor?.name || "",
          },
          location:
            (input.location as LocationInputDto) || defaultItem.location,
          signal: input.signal || false,
          time: input.time,
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
