import DescriptionCell from "@components/ReactTable/DescriptionCell";
export const itemTableData = [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
    },
    {
      Header: 'Name',
      accessor: 'node.name', 
    },    
    {
      Header: 'Phone Number',
      accessor: 'node.contact.phone', 
    },
    {
      Header: 'Country',
      accessor: 'node.address.country', 
    },
    {
      Header: 'City',
      accessor: 'node.address.city', 
    },
    {
      Header: 'Street',
      accessor: 'node.address.street', 
    },
    {
      Header: 'Srpc',
      accessor: 'node.address.srpc', 
    },
    {
      Header: 'Email',
      accessor: 'node.contact.email', 
    },
    {
      Header: 'Descrption',
      accessor: 'node.description',
      Cell: DescriptionCell 
    },
    {
      Header: 'Distributor',
      accessor: 'node.distributor.name', 
    },
    {
      Header: 'Created At',
      accessor: 'node.createdAt', 
    },
  ];
  