import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik } from 'formik';
import { itemFirmwareFields } from '../constants';
import { CreateItemFirmwareInput } from 'apps/things/app/types/thingsGlobalTypes';
import { itemFirmwareFieldsValidationSchema } from 'apps/things/app/validations';
import ItemFirmwareForm from './ItemFirmwareForm';
import CardWrapper from '@components/Card/CardWrapper';
interface IRTable {
  handleSubmit: (payload: CreateItemFirmwareInput) => void;
  isLoading: boolean;
}

const AddItemFirmware: React.FC<IRTable> = ({ handleSubmit, isLoading }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) =>
              handleSubmit({
                description: values.description,
                codeSystem: values.codeSystem,
                version: values.version,
              })
            }
            initialValues={{
              [itemFirmwareFields.codeSystem]: 'ACP1',
              [itemFirmwareFields.description]: '',
              [itemFirmwareFields.version]: 'ABCE1234',
            }}
            validationSchema={itemFirmwareFieldsValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemFirmwareForm
                formBag={formBag}
                disableLoginButton={isLoading}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItemFirmware;
