import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import { agentValidationSchema } from 'apps/things/app/validations';
import ItemForm from './ItemForm';
import CardWrapper from '@components/Card/CardWrapper';

import { DistributorStaffRegisterAgentInput } from '../../types/clientServiceGlobalTypes';
interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
  handleSubmit: (payload: DistributorStaffRegisterAgentInput) => void;
  isLoading: boolean;
  item: any
}

const AddItem: React.FC<IRTable> = ({ handleSubmit, isLoading, item }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
            <Formik
              onSubmit={async (values: any) => handleSubmit(values)}
              initialValues={item}
              validationSchema={agentValidationSchema}
              validateOnChange={true}
            >
              {(formBag) => (
                <ItemForm
                  formBag={
                    (formBag as unknown) as FormikProps<DistributorStaffRegisterAgentInput>
                  }
                  disableLoginButton={isLoading}
                />
              )}
            </Formik>

        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItem;
