import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { AvatarEdge_node } from 'apps/things/app/avatar/types/AvatarEdge';
import { GetAllItems, GetAllItemsVariables, GetAllItems_getAllItems_page_edges } from 'apps/things/app/item/types/GetAllItems';
import { ItemEdge_node } from 'apps/things/app/item/types/ItemEdge';
import { ENABLED, PAYG } from '../constants';
import { GetAllCodeGenerators_getAllCodeGenerators_page_edges } from '../types/GetAllCodeGenerators';
import useOVSPagination from 'hooks/useOVSPagination';
import { useLazyGetAllItemsQuery } from '../../item/queries';
import { QueryOrder } from '../../types/thingsGlobalTypes';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { ApolloQueryResult } from '@apollo/client';
import { funNumberAgr } from '@types';

export interface ICodeGeneratorContext {
  codeGenerators: GetAllCodeGenerators_getAllCodeGenerators_page_edges[];
  setCodeGenerators: (
    codeGenerators: GetAllCodeGenerators_getAllCodeGenerators_page_edges[]
  ) => void;
  loading: boolean;

  item: ItemEdge_node;
  setItem: (item: ItemEdge_node) => void;

  decHashTop: string;
  setDecHashTop: (hash: string) => void;

  remDays: string;
  setRemDays: (days: string) => void;

  avatar: AvatarEdge_node;
  setAvatar: (avatar: AvatarEdge_node) => void;

  items: GetAllItems_getAllItems_page_edges[];
  setItems: (items: GetAllItems_getAllItems_page_edges[]) => void;

  oemItemID: string;
  setOemItemID: (oemItemId: string) => void;

  itemID: string;
  setItemID: (itemId: string) => void;

  otpCount: number;
  setOtpCount: (otpCount: number) => void;

  hashIndex: number;
  setHashIndex: (hashIndex: number) => void;

  hashChainLength: number;
  setHashChainLength: (hashChainLength: number) => void;

  maxHashJump: number;

  otpHex: string;
  setOtpHex: (otpHex: string) => void;

  otpDec: string;
  setOtpDec: (otpDec: string) => void;

  hashTop: string;
  setHashTop: (hashTop: string) => void;

  hashRoot: string;
  setHashRoot: (hashRoot: string) => void;

  codeDays: number;
  setCodeDays: (codeDays: number) => void;

  otpDecEntry: string;
  setOtpDecEntry: (otpDecEntry: string) => void;

  daysPassed: number;
  setDaysPassed: (daysPassed: number) => void;

  payGoState: string;
  setPayGoState: (payGoState: string) => void;

  deviceState: string;
  setDeviceState: (deviceState: string) => void;

  codeAccepted: boolean | undefined;
  setCodeAccepted: (codeAccepted: boolean) => void;

  daysRemaining: number;
  setDaysRemaining: (daysRemaining: number) => void;

  resetContext: () => void;

  // pagination
  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  getItems: () => void;
  refetchItems: ((variables?: Partial<GetAllItemsVariables> | undefined) => Promise<ApolloQueryResult<GetAllItems>>) | undefined
  setPageLimit: funNumberAgr
}

export const CodeGeneratorContext = React.createContext<ICodeGeneratorContext>(
  {} as ICodeGeneratorContext
);

const CodeGeneratorProvider: React.FC<IProps> = ({ children }) => {
  const [otpDecEntry, setOtpDecEntry] = useState('');
  const [daysPassed, setDaysPassed] = useState(0);
  const [payGoState, setPayGoState] = useState(PAYG);
  const [deviceState, setDeviceState] = useState(ENABLED);
  const [codeAccepted, setCodeAccepted] = useState<undefined | boolean>(
    undefined
  );
  const [daysRemaining, setDaysRemaining] = useState(0);

  const [otpCount, setOtpCount] = useState(0);
  const [hashIndex, setHashIndex] = useState(100000);
  const [hashChainLength, setHashChainLength] = useState(100000);
  const [maxHashJump] = useState(1096);

  const [otpHex, setOtpHex] = useState('');
  const [otpDec, setOtpDec] = useState('');
  const [hashTop, setHashTop] = useState('');
  const [hashRoot, setHashRoot] = useState('');
  const [codeDays, setCodeDays] = useState(0);

  const [codeGenerators, setCodeGenerators] = React.useState<
    GetAllCodeGenerators_getAllCodeGenerators_page_edges[]
  >([]);
  const [item, setItem] = React.useState({} as ItemEdge_node);
  const [decHashTop, setDecHashTop] = useState('');
  const [remDays, setRemDays] = useState('');
  const [avatar, setAvatar] = useState({} as AvatarEdge_node);
  const [items, setItems] = React.useState<
    GetAllItems_getAllItems_page_edges[]
  >([] as GetAllItems_getAllItems_page_edges[]);
  const [oemItemID, setOemItemID] = useState('');
  const [itemID, setItemID] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();
  const [
    getItems,
    { refetch: refetchItems, fetchMore, data: itemData, loading: itemLoading },
  ] = useLazyGetAllItemsQuery({
    first: pageListLimit,
    queryorder: QueryOrder.DESC,
    search: 'TEST'
  });

  useEffect(() => {
    if (itemData) {
      setItems(
        itemData?.getAllItems?.page?.edges ||
        ({} as GetAllItems_getAllItems_page_edges[])
      );

      setPaging({
        ...itemData?.getAllItems?.pageData,
        ...itemData?.getAllItems?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [itemData]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllItemsVariables = {
      first: pageListLimit,
      queryorder: QueryOrder.DESC,
      ...initPagAction(next),
    };

    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllItems?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItems?.pageData,
        ..._data?.data?.getAllItems?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    if (!fetchMore) {
      getItems({ variables: { search: searchQuery, queryorder: QueryOrder.DESC, first: pageListLimit }})
    }
    if (fetchMore) {
      const variables: GetAllItemsVariables = {
        first: 20,
        queryorder: QueryOrder.DESC,
      };
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllItems?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItems?.pageData,
        ..._data?.data?.getAllItems?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }

  };

  useDebouncedEffect(search, [searchQuery], 1000);


  const resetContext = () => {
    setOemItemID('')
    setAvatar({} as AvatarEdge_node)
    setRemDays('')
    setDecHashTop('')
    setCodeDays(0)
    setHashRoot('')
    setHashTop('')
    setOtpDec('')
    setOtpHex('') 
    setHashChainLength(100000)
    setHashIndex(100000)
    setOtpCount(0)
    setDaysRemaining(0)
    setCodeAccepted(undefined);
    setDeviceState(ENABLED);
    setOtpDecEntry('');
    setDaysPassed(0);
    setPayGoState(PAYG);
  };

  const value = React.useMemo(
    () => ({
      codeGenerators,
      codeAccepted,
      setCodeAccepted,
      daysRemaining,
      setDaysRemaining,
      setCodeGenerators,
      deviceState,
      setDeviceState,
      payGoState,
      setPayGoState,
      loading: itemLoading || isLoadingMore,
      item,
      setItem,
      decHashTop,
      setDecHashTop,
      remDays,
      setRemDays,
      avatar,
      setAvatar,
      items,
      setItems,
      itemID,
      setItemID,
      oemItemID,
      setOemItemID,
      otpCount,
      setOtpCount,
      hashIndex,
      setHashIndex,
      hashChainLength,
      setHashChainLength,
      maxHashJump,
      otpHex,
      setOtpHex,
      otpDec,
      setOtpDec,
      hashTop,
      setHashTop,
      hashRoot,
      setHashRoot,
      codeDays,
      setCodeDays,
      otpDecEntry,
      setOtpDecEntry,
      setDaysPassed,
      daysPassed,
      resetContext,
      paging,
      goTo,
      endCursorStack,
      searchQuery,
      setSearchQuery,
      getItems,
      refetchItems, 
      setPageLimit
    }), // eslint-disable-next-line
    [
      paging,
      searchQuery,
      endCursorStack,
      itemData,
      isLoadingMore,
      itemLoading,
      otpDecEntry,
      maxHashJump,
      otpDec,
      deviceState,
      codeAccepted,
      daysRemaining,
      daysPassed,
      payGoState,
      codeGenerators,
      item,
      decHashTop,
      remDays,
      avatar,
      items,
      itemID,
      oemItemID,
      otpCount,
      hashIndex,
      hashChainLength,
      otpHex,
      hashTop,
      hashRoot,
      codeDays,
    ]
  );

  return (
    <CodeGeneratorContext.Provider value={value}>
      {children}
    </CodeGeneratorContext.Provider>
  );
};

export default CodeGeneratorProvider;
