import { Badge } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { SocketContext } from '../../Sockets/context/SocketContext';

interface IProps {
  row: Row;
}

const LegacyTableActions: React.FC<IProps> = ({ row }) => {
  const [total, setTotal] = useState(parseInt(row.values['total']));
  const [complete, setComplete] = useState(parseInt(row.values['completed']));
  const [failed, setFailed] = useState(parseInt(row.values['failed']));
  const progress =
    (100 *
      (parseInt(row.values['completed']) + parseInt(row.values['failed']))) /
    row.values['total'];

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    socket?.emit('room', JSON.stringify(row.values['meta']));

    socket?.on('message', function (data) {
      // update only if data sent bears same id as the row
      if (parseInt(data?.id) === parseInt(row.values['id'])) {
        setTotal(data.total || total);
        setComplete(data.completed || complete);
        // setProgress(data.progress || progress);
        setFailed(data.failed || failed);
      }
    }); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTotal(row.values['total']); // eslint-disable-next-line
  }, [row.values['total']]);

  const ProgressContent = () => {
    return (
      <div className='actions-right' style={{ display: 'flex' }}>
        <span>
          {isNaN(progress) ? '--' : parseInt(progress.toString()) + '%'}{' '}
        </span>
      </div>
    );
  };

  return (
    <>
      {row.values['failed'] ? (
        <div title={`${row.values['failed']} failed. ${row.values['description']}`}>
          <Badge color='secondary' badgeContent={row.values['failed']}>
            <ProgressContent />
          </Badge>
        </div>
      ) : (
        <ProgressContent />
      )}
    </>
  );
};

export default LegacyTableActions;
