import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const viewPortWidth = window.innerWidth
const thresholdWidth = 600
const useStyles = makeStyles({
    container: {
        marginBottom: 30,
    },
    table: {
        minWidth: viewPortWidth <= thresholdWidth ? 280 : 320,
    },
    td: { fontSize: viewPortWidth <= thresholdWidth ? "10px" : "16px", padding: viewPortWidth <= thresholdWidth ? "16px 5px" : "16px" },
    th: { width: viewPortWidth <= thresholdWidth ? 100 : 200, fontWeight: 'bold', fontSize: viewPortWidth <= thresholdWidth ? "10px" : "16px", padding: viewPortWidth <= thresholdWidth ? "16px 5px" : "16px" },
    btn: { padding: 0, marginLeft: 15 },
    thWithButton: { paddingTop: 7, paddingBottom: 7 },
});


interface IProps {
    assetAccountPaymentPlan?: any;
}

const AssetAccountPaymentPlan: React.FC<IProps> = ({
    assetAccountPaymentPlan
}) => {
    const classes = useStyles();
    let planDetails = assetAccountPaymentPlan?.planDetails

    return (
        <TableContainer component={Paper} className={classes.container}>
            <Table className={classes.table} aria-label="simple table">
                {planDetails ? (
                    <TableBody>
                        {planDetails.map((item: any, index: any) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" className={classes.th}>
                                    {item.pName}
                                </TableCell>
                                <TableCell className={classes.td}>{item.pValue}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                ) : (
                    <TableRow>
                    <TableCell component="th" scope="row" className={classes.th}>
                        -
                    </TableCell>
                    <TableCell className={classes.td}>No Payment Plan</TableCell>
                </TableRow>
                )}
            </Table>
        </TableContainer>
    );
};

export default AssetAccountPaymentPlan;
