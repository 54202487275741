import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import RemoveIcon from '@material-ui/icons/Remove';
import { IAdvancedQuery } from './types';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      width: 50,
    },
  })
);

interface IProps {
  // formBag: FormikProps<IAdvancedQueryForm>;
  index: number;
  removeItem: (i: number) => void;
  queries: IAdvancedQuery[];
  setQueries?: (queries: IAdvancedQuery[]) => void;
  columns: { value: string; label: string }[];
  hidden: boolean;
}
const KeyValueInput: React.FC<IProps> = ({
  // formBag,
  index,
  removeItem,
  queries,
  setQueries,
  columns,
  hidden,
}) => {
  const classes = useStyles();
  const [column, setColumn] = React.useState(queries[index].column);
  const [value, setValue] = React.useState(queries[index].value);
  const [hide, setHide] = useState(hidden);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColumn(event.target.value);
    let _queries = [...queries];
    _queries.splice(index, 1, { value, column: event.target.value });
    setQueries && setQueries(_queries);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useDebouncedEffect(
    () => {
      let _queries = [...queries];
      _queries.splice(index, 1, { value, column });
      setQueries && setQueries(_queries);
    },
    [value, column],
    1000
  );

  const remove = () => {
    setHide(true);
    let _queries = [...queries];
    _queries.splice(index, 1, { value, column, hidden: true });
    setQueries && setQueries(_queries);
  };

  useEffect(() => {
    setHide(hidden);
  }, [hidden]);

  return (
    <div style={hide ? { display: 'none' } : {}}>
      <FormControl className={classes.formControl}>
        <GridContainer>
          <GridItem xs={12} sm={2} md={2}>
            <Button
              title='remove'
              className={classes.selectEmpty}
              onClick={() => remove()}
            >
              <RemoveIcon />
            </Button>
          </GridItem>
          <GridItem xs={12} sm={5} md={5}>
            <TextField
              id='standard-select-currency'
              select
              label={index === 0 ? 'Column' : ' '}
              name={`queries[${index}].column`}
              value={column || ''}
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              {columns.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={5} md={5}>
            <TextField
              label={index === 0 ? 'Criteria' : ' '}
              name={`queries[${index}].value`}
              size='small'
              value={value}
              onChange={handleValueChange}
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl className={classes.formControl}></FormControl>
    </div>
  );
};

export default KeyValueInput;
