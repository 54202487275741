import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React from 'react';
import { itemFirmwareFields } from '../constants';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import { codeSystemTypeOptions } from 'apps/things/app/codeGenerator/constants';

const ItemFirmwareForm: React.FC<{
  formBag: FormikProps<any>;
  disableLoginButton: boolean;
  actionButtonName?: string;
}> = (props) => {
  const {
    formBag,
    disableLoginButton,
    actionButtonName = 'Add Item Firmware',
  } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <CustomSelect
            selectOptions={codeSystemTypeOptions()}
            onChange={(e: any) => {
              formBag.setFieldValue(
                itemFirmwareFields.codeSystem,
                e.target.value
              );
            }}
            label={'Code System'}
            value={formBag.values.codeSystem}
            name={itemFirmwareFields.codeSystem}
            selectTitle='Choose code system'
          />
          {/* <CustomSelect
            selectOptions={versionNumbersOptions()}
            onChange={(e: any) => {
              formBag.setFieldValue(
                itemFirmwareFields.version,
                e.target.value
              );
            }}
            label={'Version'}
            value={formBag.values.version}
            name={itemFirmwareFields.version}
            selectTitle='Choose a version'
          /> */}
          <TextInput label='Version' name={itemFirmwareFields.version} />
          <TextInput
            label='Description'
            name={itemFirmwareFields.description}
            multiline
          />

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemFirmwareForm;
