import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import React, { useContext, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { Row } from "react-table";
import ActionModal from "@components/Modal/ActionModal";
import { useMutation } from "@apollo/client";
import clientSimulator from "utils/clientSimulator";
import useNotifier from "hooks/useNotifier";
import { useNavigate } from "react-router-dom";
import { DeleteItem, DeleteItemVariables } from "../types/DeleteItem";
import { deleteItemMutation } from "../mutations";
import { itemsRoutes } from "../constants";
import { itemCodeEventsRoutes } from "../../codeEvent/constants";
import { useAuth } from "admin/auth/context/AuthProvider";
import { ItemContext } from "../context/ItemProvider";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import CodeEventContainer from "../../codeEvent/containers/CodeEventContainer";
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import { useGetSpecificAvatarForItemQuery } from "../../avatar/queries";
import ItemPage from "../../avatar/components/ItemPage";
import AddCodeGeneratorContainer from "../../codeGenerator/containers/AddCodeGeneratorContainer";
import { Formik } from "formik";
import Simulator from "../../codeGenerator/components/Simulator";

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const SkuTableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();

  const { isDistributor, isServicer, isSuperAdmin,loggedInUserId, isDistributorStaff } = useAuth();
  const { refetchItems: refetch, refetchClientItems } = useContext(ItemContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [codeEventModalOpen, setCodeEventModalOpen] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [codeGenModalOpen, setCodeGenModalOpen] = React.useState(false);
  const [simulatorOpen, setSimulatorOpen] = React.useState(false);
  const [deleteItemSKU] = useMutation<DeleteItem, DeleteItemVariables>(
    deleteItemMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {
        notify({
          status: "success",
          text: "Item deleted successfully",
        });
        refetch && refetch();
        setIsModalOpen(false);
      },
    }
  );
  const [getItemAvatar, { loading, data }] = useGetSpecificAvatarForItemQuery({
    itemId: row.values["node._id"],
  });

  return (
    <div className="actions-right" style={{ display: "flex" }}>
        <>

        <div className={`${classes.iconWrapper} ${isDistributor ? classes.iconWrapperHover : ''}`}>
    <Tooltip
      title="Delete"
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton
        id="expander"
        aria-label="Delete"
        className={classes.tableActionButton}
        onClick={() => setIsModalOpen(true)}
        style={isDistributor ? { opacity: 0.3, pointerEvents: "none" } : {}}
      >
        <Delete className={classes.tableActionButtonIcon + " " + classes.delete} />
      </IconButton>
    </Tooltip>
    {isDistributor && (
      <span className={classes.stopSign} role="img" aria-label="Stop">
        🚫
      </span>
    )}
  </div>
  <div className={`${classes.iconWrapper} ${isDistributor ? classes.iconWrapperHover : ''}`}>
    <Tooltip
      id="expander"
      onClick={() => {
        history(itemsRoutes.getEditLink(row.values["node._id"]));
      }}
      title="Edit"
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton
        id="expander"
        aria-label="Edit"
        className={classes.tableActionButton}
        style={isDistributor ? { opacity: 0.3, pointerEvents: "none" } : {}}
      >
        <Edit className={classes.tableActionButtonIcon + " " + classes.edit} />
      </IconButton>
    </Tooltip>
    {isDistributor && (
      <span className={classes.stopSign} role="img" aria-label="Stop">
        🚫
      </span>
    )}
  </div>
  <HoverDropdown

        menus={[
          {
            name: "Code History",
            action: () => {
              // setCodeEventModalOpen(true);
              history(itemCodeEventsRoutes.getCodeEventsLink(row.values["node._id"]));
            },
          },
          {
            name: "Avatar",
            action: () => {
              setAvatarModalOpen(true);
              getItemAvatar();
            },
          },
          {
            name: "Code generator",
            hidden: loggedInUserId === "662901b8082c0b1a656894d2" || isDistributorStaff,
            action: () => {
              setCodeGenModalOpen(true);
            },
          },

          {
            name: "Simulator",
            hidden: !isSuperAdmin,
            action: () => {
              setSimulatorOpen(true);
            },
          },
        ]}
      />
</>
      {/* ) : null} */}

      {isModalOpen ? (
        <ActionModal
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          handleOnClickOkey={() =>
            deleteItemSKU({ variables: { itemId: row.values["node._id"] } })
          }
          handleOnClickCancel={() => setIsModalOpen(false)}
          okText="Delete"
        >
          <div>Are you sure you want to delete this item?</div>
        </ActionModal>
      ) : null}

      <DrawerSidebar
        isModalOpen={codeEventModalOpen}
        toggleModal={setCodeEventModalOpen}
        title="Code Events"
        styles={{ minWidth: "80vw", maxWidth: "80vw" }}
      >
        {codeEventModalOpen && (
          <CodeEventContainer itemId={row.values["node._id"]} />
        )}
      </DrawerSidebar>

      {avatarModalOpen ? (
        <DrawerSidebar
          isModalOpen={avatarModalOpen}
          toggleModal={setAvatarModalOpen}
          title="Avatar"
        >
          <ItemPage
            data={[{ node: data?.getSpecificAvatarforItem }]}
            isLoading={loading}
          />
        </DrawerSidebar>
      ) : null}
      {codeGenModalOpen ? (
        <DrawerSidebar
          isModalOpen={codeGenModalOpen}
          toggleModal={setCodeGenModalOpen}
          title="Generate Code"
          styles={{ minWidth: "80vw", maxWidth: "80vw" }}
        >
          {codeGenModalOpen && row.values["node._id"] && (
            <AddCodeGeneratorContainer
              generateDayCodeCallback={() => {
                setCodeGenModalOpen(false);
                if (
                  (isServicer && refetchClientItems) ||
                  (isDistributor && refetchClientItems)
                ) {
                  return refetchClientItems();
                }
                if (refetch) {
                  refetch();
                }
              }}
              itemID={row.values["node._id"] || ""}
            />
          )}
        </DrawerSidebar>
      ) : null}

      {simulatorOpen ? (
        <DrawerSidebar
          isModalOpen={simulatorOpen}
          toggleModal={setSimulatorOpen}
          title="Simulator"
        >
          <Formik
            initialValues={{
              hashTop: row.values["node.codeGenerator.hashTop"],
              hashRoot: row.values["node.codeGenerator.hashRoot"],
              hashIndex: row.values["node.codeGenerator.hashIndex"],
              codeCount: row.values["node.codeGenerator.codeCount"],
              codeDays: 0,
              oemItemId: row.values["node.oemItemID"],
              itemID: row.values["node.itemID"],
              otpHex: '',
              otpDec: '',
            }}
            onSubmit={() => {}}
          >
            {(formBag) => <Simulator formBag={formBag} />}
          </Formik>
        </DrawerSidebar>
      ) : null}
    </div>
  );
};

export default SkuTableActions;
