import { AddressDto } from '../../types/clientServiceGlobalTypes';

export const itemFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  age: 'age',
  hireDate: 'hireDate',
  email: 'email',
  phone: 'staffContact.phone',
  social: 'staffContact.social',
  staffCity: 'staffAddress.city',
  staffCountry: 'staffAddress.country',
  staffPostcode: 'staffAddress.postcode',
  staffSrpc: 'staffAddress.srpc',
  staffStreet: 'staffAddress.street',
  staffUnit: 'staffAddress.unit',
  staffAddressLongitude:
    'staffAddress.locationAddressDto.inputAddressLongitude',
  staffAddressLatitude:
    'staffAddress.locationAddressDto.inputAddressLatitude',
    officeCity: 'officeAddress.city',
    officeCountry: 'officeAddress.country',
    officePostcode: 'officeAddress.postcode',
    officeSrpc: 'officeAddress.srpc',
    officeStreet: 'officeAddress.street',
    officeUnit: 'officeAddress.unit',
    officeAddressLongitude:
      'officeAddress.locationAddressDto.inputAddressLongitude',
    officeAddressLatitude:
      'officeAddress.locationAddressDto.inputAddressLatitude',

};

export const defaultAddress = {
  city: '',
  country: '',
  locationAddressDto: {
    inputAddressLatitude: 0,
    inputAddressLongitude: 0,
  },
  postcode: '',
  srpc: '',
  street: '',
  unit: '',
};

export const defaultItem = {
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  age: "",
  hireDate: new Date(),
  staffContact: { phone: '', social: '' },
  officeAddress: defaultAddress as AddressDto,
  staffAddress: defaultAddress as AddressDto,
};

export const itemRoutes = {
  list: '/admin/distributor-staff',
  add: '/admin/distributor-staff/add',
  edit: '/admin/customers/:itemId',
  getEditLink: (itemId: string) => `/admin/customers/${itemId}`,
};
