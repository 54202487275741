
import { useLazyGetTotalItemsInItemBatchQuery } from "../queries";
import {useMemo} from 'react'
import * as _ from "lodash";

export const useGetTotalItemsInBatch = ({
    batchNumber
  }: {
    batchNumber:string
  }) => {
    const [getItems, { data, loading}] = useLazyGetTotalItemsInItemBatchQuery({
        search: batchNumber
    });
  
    return useMemo(
      () => ({
        getItems,
        total: _.get(data, "getAllItems.pageData.count"),
        loading
      }), // eslint-disable-next-line
      [data, loading]
    );
  };