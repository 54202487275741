import React, { useContext, useEffect } from "react";

// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";

import CardWrapper from "@components/Card/CardWrapper";
import { LegacyItemContext } from "../context/LegacyItemContext";
import { legacyTableData } from "../constants/data";
import { legacyRoutes } from "../constants";
import { getParameterByName } from "apps/migration/app/utils";
import useNotifier from "hooks/useNotifier";

interface IRTable {
  bulkDeleteProducts?: () => void;
  isLoading: boolean;
}

const LegacyPage: React.FC<IRTable> = () => {
  const {
    items,
    getItems,
    loading,
    setSearchQuery,
    searchQuery,
    goTo,
    paging,
    advancedSearch,
    queries,
    setQueries,
    setOffset,
    tableActions,
  } = useContext(LegacyItemContext);

  const notify = useNotifier();
  useEffect(() => {
    const search = getParameterByName("productBatchID");
    if (search || searchQuery) {
      notify({ status: "info", text: "Please refresh this page." });
    } else {
      getItems();
    } // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={legacyTableData.header as Column[]}
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={legacyRoutes.add}
            addItemBtnText="Add"
            setSearchQuery={setSearchQuery}
            goTo={goTo}
            paging={paging}
            advancedSearch={advancedSearch}
            searchQuery={searchQuery}
            queries={queries}
            setQueries={setQueries}
            refetchData={getItems}
            setOffset={setOffset}
            tableActions={tableActions}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
export default LegacyPage;
