import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import List from "./components/ItemPage";
import { itemRoutes } from './constants';

interface IProps { }

export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
  ],
};
const ClientServicePermissionIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet />
  );
};

export default ClientServicePermissionIndex;
