import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@components/CustomButtons/Button';
import OpenTokenCodeEvent from './codeEvents';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  th: { width: 180, fontWeight: 'bold' },
  btn: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
    paddingRight: 2,
  },
  thWithButton: { paddingTop: 7, paddingBottom: 7 },
});

interface IProps {
  decoderState: any
  oemItemId: string
}
const thresholdWidth = 600;
const drawerWidthPaymentPlanDesktop = { minWidth: "80vw", maxWidth: "80vw" }
const drawerWidthMobile = { minWidth: "100vw", maxWidth: "100vw" }
const DecoderState: React.FC<IProps> = ({ decoderState, oemItemId }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (

    <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableBody>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Oem Item Id
            </TableCell>
            <TableCell component='td'>
              {oemItemId || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Token
            </TableCell>
            <TableCell>
              {decoderState.token}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Token Type
            </TableCell>
            <TableCell>
              {decoderState.token_type}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Token Value
            </TableCell>
            <TableCell>
              {decoderState.token_value}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Used Count
            </TableCell>
            <TableCell>
              {decoderState.used_count}
            </TableCell>
          </TableRow>
          <TableRow>

            <TableCell component='th' className={classes.th} scope='row'>
              Max Count
            </TableCell>
            <TableCell>
              {decoderState.max_count}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Starting Code
            </TableCell>
            <TableCell>
              {decoderState.starting_code}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Secret Key
            </TableCell>
            <TableCell>
              {decoderState.secret_key}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component='th' className={classes.th} scope='row'>
              Accepted Codes
            </TableCell>
            <TableCell className={classes.thWithButton}>
              <Button size="sm" onClick={() => setIsModalOpen(true)}>
                View Code History
              </Button>
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
        <DrawerSidebar
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        title="Accepted Tokens"
        styles={window.innerWidth <= thresholdWidth ? drawerWidthMobile : drawerWidthPaymentPlanDesktop}
      >
        <OpenTokenCodeEvent data={decoderState} />
      </DrawerSidebar>
      </>
  );
};

export default DecoderState;
