import { AttributeDto } from '../../types/thingsGlobalTypes';

const url = 'avatar';

export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};
export const itemFields = {
  att: 'att',
  cmd: 'cmd',
  dia: 'dia',
  dta: 'dta',
  sts: 'sts',
  itemAlive: 'itemAlive',
  lastPub: 'lastPub',
  lastSub: 'lastSub',
  lastSync: 'lastSync',
  shadow: 'shadow',
};
const defaultAttribute = {
  meta: '',
  prop: '',
  value: '',
};
export const defaultItem = {
  itemId: '',
  att: [] as AttributeDto[],
  cmd: [defaultAttribute] as AttributeDto[],
  dia: [defaultAttribute] as AttributeDto[],
  dta: [defaultAttribute] as AttributeDto[],
  sts: [defaultAttribute] as AttributeDto[],
  itemAlive: false,
  lastPub: '',
  lastSub: '',
  lastSync: new Date(),
  shadow: '',
};
