import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import CheckIn from "./components/CheckIn";
import CheckInOutContainer from "./components/CheckInOut";
import { itemsRoutes } from "./constants";

interface IProps {}
export const routes = {
  path: itemsRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <CheckInOutContainer /> },
    { path: itemsRoutes.checkIn, element: <CheckIn /> },
    { path: itemsRoutes.checkInAuth, element: <CheckIn /> },
    { path: itemsRoutes.checkInAdmin, element: <CheckIn /> },
  ],
};
const CheckInOutIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default CheckInOutIndex;
