// @ts-nocheck.
import { FormControl, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const ITEM_HEIGHT = 48;
interface IProps {
  label?: string;
  searchPromise: (
    arg: string
  ) => PromiseLike<{ value: string; label: string }[]>;
  options: { value: string; label: string }[];
  placeholder?: string;
  onChange?: (e: any) => void
  initialValue?: { value: string, label: string } | {}
  error?: string
  isDisabled?: boolean
}
// const Option = (props: any) => {
//   const handleClick = (event: any) => {
//     props.selectOption(props.data, event);
//   };

//   const { children, isFocused, isSelected, onFocus } = props;
//   return (
//     <MenuItem
//       onFocus={onFocus}
//       selected={isFocused}
//       onClick={handleClick}
//       component='div'
//       style={{
//         fontWeight: isSelected ? 500 : 400,
//       }}
//     >
//       {children}
//     </MenuItem>
//   );
// };
const AsyncSeachableSelect: React.FC<IProps> = ({
  searchPromise,
  options,
  placeholder,
  onChange,
  initialValue,
  error,
  isDisabled = false,
  label
}) => {
  const [selectedOption, setOption] = useState(
    initialValue as { value: string; label: string }
  );
  const handleChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    selectedOption && setOption(selectedOption);
    onChange && onChange(selectedOption?.value)
  };

  let timeout: ReturnType<typeof setTimeout> | null = null


  const promiseOptions = (inputValue: string) => {
    if (timeout) { 
      clearTimeout(timeout); 
    }
    if (inputValue.length > 1) {
      
      return new Promise<{ value: string; label: string }[]>((resolve) => {
        resolve(searchPromise(inputValue || ''));
      });
    }
  };

  return (
    <div style={{ width: '100%', flexGrow: 1 }}>
      <InputLabel className='async-select-label'>{label}</InputLabel>
      <AsyncSelect
        value={
          Object.keys(selectedOption).length === 0 ? undefined : selectedOption
        }
        onChange={handleChange}
        defaultOptions={options}
        cacheOptions={options}
        loadOptions={promiseOptions}
        placeholder={placeholder}
        isDisabled={isDisabled}
        // @ts-ignore
        styles={customStyles}
        className='async-select-wrapper'
      // @ts-ignore
      // components={{
      //   Option: Option,
      //   DropdownIndicator: <ArrowDropDownIcon color="red"/>,
      // }}
      />
      <FormControl>
        {error ? (
          <div className='error-message'>{error}</div>
        ) : null}
      </FormControl>
    </div>
  );
};
const customStyles = {
  container: (base: any) => ({
    ...base,
    border: 0,
    borderColor: '#D2D2D2',
    borderStyle: 'solid'
    , borderBottomWidth: 1
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    background: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  menu: () => ({
    backgroundColor: 'white',
    boxShadow: '1px 2px 6px #888888', // should be changed as material-ui
    position: 'absolute',
    left: 0,
    top: `calc(100% + 1px)`,
    width: '100%',
    zIndex: 2,
    maxHeight: ITEM_HEIGHT * 4.5,
  }),
  menuList: () => ({
    maxHeight: ITEM_HEIGHT * 4.5,
    overflowY: 'auto',
  }),
};
export default AsyncSeachableSelect;
