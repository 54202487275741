import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { PAGE_LIST_LIMIT } from '@constants';
import { itemRoutes } from 'apps/clientService/app/clientService/constants';
import { assignDistributorToServicer } from 'apps/things/app/validations';
import { Formik, FormikProps } from 'formik';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientServiceContext } from '../../clientService/context/ClientServiceProvider';
import { AssignDistributorClientExclusiveServiceTypeInput } from '../../types/clientServiceGlobalTypes';
import AssignDistributorForm from '../components/AssignDistributorForm';
import { clientExclusiveServiceTypesOptions } from '../constants';

import { useAssignDistributorClientExclusiveServiceTypeMutation } from '../mutation';

interface IProps { }

const AssignDistributorToServicer: React.FC<IProps> = () => {
	const { refetchItems } = useContext(ClientServiceContext)
	const history = useNavigate()
	const { mutation, loading } = useAssignDistributorClientExclusiveServiceTypeMutation({
		callbackFn: async () => {
			if (refetchItems) {
				await refetchItems({ first: PAGE_LIST_LIMIT })

			}
			history(itemRoutes.list)

		}
	})
	const handleSubmit = (values: AssignDistributorClientExclusiveServiceTypeInput) => {
		mutation({
			variables: {
				assignDistributorClientExclusiveServiceTypeInput: {
					distributorId: values.distributorId,
					servicerId: values.servicerId,
					serviceType: values.serviceType
				}
			}
		})
	}

	return (
		<GridContainer>

			<GridItem xs={12} sm={12} md={12}>

				<Formik
					onSubmit={async (values: any) => handleSubmit(values)}
					initialValues={{
						distributorId: '',
						serviceType: clientExclusiveServiceTypesOptions[0]._id,
						servicerId: '',
					}}
					validationSchema={assignDistributorToServicer}

				>
					{(formBag) => (
						<AssignDistributorForm
							formBag={(formBag as unknown) as FormikProps<AssignDistributorClientExclusiveServiceTypeInput>}
							loading={loading}
						/>
					)}

				</Formik>
			</GridItem>
		</GridContainer>
	)
}

export default AssignDistributorToServicer