import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { useMemo } from 'react';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import clientSimulator from 'utils/clientSimulator';
import {
  GetAllClientCustomers,
  GetAllClientCustomersVariables,
  GetAllClientCustomers_getAllClientCustomers_page_edges,
} from '../types/GetAllClientCustomers';
import { useLazyGetAllClientCustomersQuery } from '../queries';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { DeletePerson, DeletePersonVariables } from '../types/DeletePerson';
import { deletePersonMutation } from '../mutations';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';
import GridContainer from '@components/Grid/GridContainer';
import { Formik } from 'formik';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import GridItem from '@components/Grid/GridItem';
import Button from '@components/CustomButtons/Button';
import { CustomAgentContext } from '../../agents/context/CustomAgentProvider';
// import { AssignItemToAgent, AssignItemToAgentVariables } from '../../agents/types/AssignItemToAgent';
import { AssignCustomerToAgent, AssignCustomerToAgentVariables } from '../../agents/types/AssignCustomerToAgent';
import { assignCustomerToAgentMutation } from "../../agents/mutation"
import OVSForm from '@components/Form/OVSForm';
export interface ICustomerContext {
  items: GetAllClientCustomers_getAllClientCustomers_page_edges[];
  setItems: (
    item: GetAllClientCustomers_getAllClientCustomers_page_edges[]
  ) => void;
  loading: boolean;
  getItems: () => void;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllClientCustomers>>)
    | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr;
  clientError: ApolloError | undefined
}

export const CustomerContext = React.createContext<ICustomerContext>(
  {} as ICustomerContext
);

const CustomerProvider: React.FC<IProps> = ({ children }) => {
  const { userPref } = useAuth();
  const [items, setItems] = React.useState<
    GetAllClientCustomers_getAllClientCustomers_page_edges[]
  >([] as GetAllClientCustomers_getAllClientCustomers_page_edges[]);

  const { tableActionLoading, setTableActionLoading } = useContext(GlobalSimulatorContext);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [assignToAgentModalOpen, setAssignToAgentModalOpen] = useState(false);
  const [agentId, setAgentId] = useState<any>();
  const [selectedItems, _setSelectedItems] = useState([] as Row[]);

  const setSelectedItemsForAgent = (itemsToAssign: Row[]) => {
    _setSelectedItems(itemsToAssign)
    setAssignToAgentModalOpen(true);
    getCustomAgents()
  }
  /// hooks
  const notify = useNotifier();
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit,
  } = useOVSPagination();
  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore, error: clientError },
  ] = useLazyGetAllClientCustomersQuery({
    first: pageListLimit,
    clientId: userPref._id,
  });

  useEffect(() => {
    setItems(data?.getAllClientCustomers?.page?.edges || []);
    setPaging({
      ...data?.getAllClientCustomers?.pageData,
      ...data?.getAllClientCustomers?.page?.pageInfo,
    }); // eslint-disable-next-line
  }, [data]);

  const [deleteItem] = useMutation<DeletePerson, DeletePersonVariables>(
    deletePersonMutation,
    {
      client: clientSimulator,
      onCompleted: (data) => {},
    }
  );
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllClientCustomersVariables = {
      first: pageListLimit,
      clientId: userPref._id,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllClientCustomers?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllClientCustomers?.pageData,
        ..._data?.data?.getAllClientCustomers?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  const search = async () => {
    let variables: GetAllClientCustomersVariables = {
      first: pageListLimit,
      clientId: userPref._id,
    };
    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllClientCustomers?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllClientCustomers?.pageData,
        ..._data?.data?.getAllClientCustomers?.page?.pageInfo,
      });
    }
  };
  useDebouncedEffect(search, [searchQuery], 1000);
  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            personId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllClientCustomers?.page?.edges || items
          );
          setTableActionLoading(false);
        } else {
          getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItems) {
          const itemsFetched = await refetchItems({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllClientCustomers?.page?.edges || items
          );
          setTableActionLoading(false);
        } else {
          getItems();
          setTableActionLoading(false);
        }
      });
  };


  const {
    getItems: getCustomAgents,
    items: agentItems,
    refetchItems: refetchAgent
  } = useContext(CustomAgentContext);


  const [assignToAgent, { loading: agentLoading }] = useMutation<
  AssignCustomerToAgent,
  AssignCustomerToAgentVariables
>(assignCustomerToAgentMutation, {
  client: clientSimulator,
  onCompleted: async (data) => { 
    setAssignToAgentModalOpen(false)
    setAgentId("")
    setTableActionLoading(false)
    refetchItems && refetchItems()
    notify({
      text: "Customers Assigned to Agent Succesfully",
      status: "success"
    })
  },
  onError: async (err) => {
    notify({
      text: "There was a Problem assigning items",
      status: "error"
    })
  }
});
const assignItemsToAgent = (selectedItems: Row[]) => {
  if(!agentId) {
    notify({
      text: "Please select an Agent",
      title: "",
      status: "error",
    });
    return;
  }
  const customerIds = selectedItems.map(item => item.values["node._id"]);

  setTableActionLoading(true)
  assignToAgent({
    variables: {
      assignCustomerToAgentInput: {
        agentId: agentId,
        customers: customerIds
      }
    }
  })
}

const searchAgent = async (search: string) => {
  if (!refetchAgent) {
    return Promise.resolve(
      agentItems?.map((it) => ({
        name: it?.node?.contact.email,
        value: it?.node?._id,
      }))
    );
  }
  const refetchData = await refetchAgent({
    first: pageListLimit,
    search,
  });

  const items = refetchData?.data?.getAllAgentsForSpecificDistributorStaff?.page?.edges?.map(
    (item) => {
      return {
        label: item?.node?.contact.email || "",
        value: item.node?._id || "",
      };
    }
  );
  return Promise.resolve(items as { value: string; label: string }[]);
};

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      getItems,
      refetchItems,
      goTo,
      paging,
      searchQuery,
      setSearchQuery, 
      setPageLimit, 
      clientError,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} customer(s)?`,
          showConfirmationModal: true,
        },
        {
          action: setSelectedItemsForAgent,
          title: "Assign Items to Agent",
          description: (arg: string) => "",
          showConfirmationModal: false,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading, paging, searchQuery, clientError]
  );

  const agentFieldsData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "select-async",
            name: "agentId",
            label: "Agent",
            options: agentItems?.map((it) => ({
              _id: it?.node?._id || "",
              name: it?.node?.contact.email || "",
            })),
            onChange: (e: any) => {
              setAgentId(e);
            },
            value: agentId || "",
            searchPromise: searchAgent as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [agentItems, agentId]
  );

  return (
    <CustomerContext.Provider value={value}>
      <>
      {children}

      {assignToAgentModalOpen ? (
          <DrawerSidebar
            isModalOpen={assignToAgentModalOpen}
            toggleModal={setAssignToAgentModalOpen}
            title="Assign Selected Items to Agent"
            styles={{ maxWidth: "30vw", minWidth: "400px" }}
          >
            <GridContainer>
              <Formik onSubmit={(e) => { }} initialValues={{}}>
                {(formBag) => (
                  <>
                    <OVSForm formFieldsData={agentFieldsData} />
                    <GridItem sm={12}>
                      <Button
                        color="primary"
                        onClick={() => {
                          assignItemsToAgent(selectedItems);
                        }}
                        style={{ marginLeft: 12 }}
                        disabled={tableActionLoading}
                      >
                        {agentLoading ? "Loading..." : "Save"}
                      </Button>
                    </GridItem>
                  </>
                )}
              </Formik>
            </GridContainer>
          </DrawerSidebar>
        ) : null}
        </>
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
