import { Grid, InputLabel } from "@material-ui/core";
import { Form, FormikProps } from "formik";
import _ from "lodash";
// @material-ui/icons
import Datetime from "react-datetime";
// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
import Button from "@components/CustomButtons/Button";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CreateItemInput,
  QueryOrder,
} from "apps/things/app/types/thingsGlobalTypes";
import TextInput from "@components/CustomInput/TextInput";
import GoBackHeader from "@components/GoBackHeader/GoBackHeader";
import { itemFields } from "../constants";
import { useGetAllItemBatches } from "apps/things/app/batch/queries";
import { useGetAllItemFirmwaresQuery } from "apps/things/app/firmware/queries";
import GridHalfWrapper from "@components/Grid/GridHalfWrapper";
import {
  defaultProductType,
  getBatchNumber,
  getDefaultDate,
} from "apps/things/app/utils";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import AsyncSeachableSelect from "@components/CustomSelect/AsyncSeachableSelect";
import DrawerSidebar from "@components/Drawer/DrawerSidebar";
import useNotifier from "hooks/useNotifier";
// @ts-ignoref noqa:
const useStyles = makeStyles(styles);
const ItemForm: React.FC<{
  formBag: FormikProps<
    CreateItemInput & {
      itemSKUId?: string;
      totalItems?: number;
      itemBatchNumber?: string;
    }
  >;
  disableLoginButton: boolean;
  actionButtonName?: string;
  isUpdate?: boolean;
  sequence?: string;
}> = (props) => {
  const {
    formBag,
    disableLoginButton,
    actionButtonName = "Add Items",
    isUpdate,
    sequence = "xxxxxx",
  } = props;
  const { addItem } = itemFields;
  const classes = useStyles();

  // item batch
  const {
    data: dataItemBatchs,
    loading: itemBatchLoading,
    refetch,
  } = useGetAllItemBatches({ first: 100, queryorder: QueryOrder.DESC });

  const [itemBatchOptions, setItemBatchOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const notify = useNotifier();

  const searchItemBatch = async (search: string) => {
    const refetchData = await refetch({ first: 100, search });
    const items = refetchData?.data?.getAllItemBatches?.page?.edges?.map(
      (item) => {
        return {
          label: item.node?.batchNumber || "",
          value: item.node?._id || "",
        };
      }
    );
    return items as { value: string; label: string }[];
  };

  React.useEffect(() => {
    if (!itemBatchLoading && dataItemBatchs) {
      const items = dataItemBatchs?.getAllItemBatches?.page?.edges?.map(
        (item) => {
          return {
            label: item.node?.batchNumber || "",
            value: item.node?._id || "",
          };
        }
      );

      setItemBatchOptions(items || []);
    } // eslint-disable-next-line
  }, [dataItemBatchs, itemBatchLoading]);

  // item firmware
  const { data: dataItemFirmware, loading: itemFirmwareLoading } =
    useGetAllItemFirmwaresQuery({ first: 100 });

  const [itemFirmwareOptions, setItemFirmwareOptions] = useState<
    { label: string; value: string }[]
  >([]);

  React.useEffect(() => {
    if (!itemFirmwareLoading && dataItemFirmware) {
      const items = dataItemFirmware?.getAllItemFirmwares?.page?.edges?.map(
        (item) => {
          return {
            label: item.node?.version || "",
            value: item.node?._id || "",
          };
        }
      );

      setItemFirmwareOptions(items || []);
    } // eslint-disable-next-line
  }, [dataItemFirmware, itemFirmwareLoading]);

  useEffect(() => {
    const item = _.find(
      dataItemBatchs?.getAllItemBatches?.page?.edges,
      function (o) {
        return o?.node?._id === formBag.values.itembatchId;
      }
    );
    const productBase = item?.node?.itemSKU?.productBase;
    if (productBase) {
      setProductType(productBase);
      formBag.setFieldValue(
        itemFields.addItem.oemItemID,
        getBatchNumber({ productType: productBase, date, sequence, usedIn: "items" })
      );
    } // eslint-disable-next-line
  }, [formBag.values.itembatchId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState(getDefaultDate());
  const [productType, setProductType] = useState(defaultProductType);

  const setBatchNumber = (batchNumber: string) => {
    formBag.setFieldValue(itemFields.addItem.oemItemID, batchNumber);
  };
  const searchItemFirmware = async(search:string) => {
    const items = itemFirmwareOptions.filter(item => item.label.includes(search));
    return items as { value: string; label: string }[];
  }
  const { values } = formBag;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer>
                <GridHalfWrapper>
                  <AsyncSeachableSelect
                    onChange={(e: any) => {
                      formBag.setFieldValue(addItem.itembatchId, e);
                    }}
                    placeholder="Select item batch"
                    options={itemBatchOptions}
                    searchPromise={searchItemBatch}
                    initialValue={
                      formBag.values.itemBatchNumber
                        ? {
                          value: "",
                          label: formBag.values.itemBatchNumber,
                        }
                        : {}
                    }
                    error={formBag.errors.itembatchId}
                  />
                </GridHalfWrapper>

                {!isUpdate && (
                  <GridHalfWrapper>
                    <TextInput
                      name="totalItems"
                      type="number"
                      label="Total Items"
                      value={
                        (values.totalItems && values.totalItems.toString()) ||
                        ""
                      }
                    />
                  </GridHalfWrapper>
                )}
                <GridHalfWrapper>
                  <AsyncSeachableSelect
                    onChange={(e: any) => {
                      formBag.setFieldValue(addItem.itemfirmwareId, e);
                    }}
                    placeholder="Item Firmware"
                    options={itemFirmwareOptions}
                    searchPromise={searchItemFirmware}
                    initialValue={
                      formBag.values.itemfirmwareId
                        ? {
                          value: "",
                          label: formBag.values.itemfirmwareId,
                        }
                        : {}
                    }
                    error={formBag.errors.itemfirmwareId}
                  />
                </GridHalfWrapper>
                <GridHalfWrapper>
                  <div onClick={() => setIsModalOpen(true)}>
                    <TextInput
                      label="Item OEM"
                      name="oemItemID"
                      value={
                        formBag.values.oemItemID ||
                        getBatchNumber({ productType, date, sequence, usedIn: "items" })
                      }
                      disabled
                      onClick={() => setIsModalOpen(true)}
                    />
                  </div>
                </GridHalfWrapper>
              </GridContainer>
            </GridItem>
          </GridContainer>

          <Grid md={12}>
            <Button
              color="primary"
              onClick={() => {
                formBag.submitForm();
                formBag.validateForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
        <DrawerSidebar
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          title=" Generate oemItem"
        >
          <GridContainer>
            <GridItem sm={12}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                <br />
                {getBatchNumber({ productType, date, sequence, usedIn: "items" })}
              </InputLabel>
            </GridItem>
            <GridItem sm={12}>
              <TextInput
                name="undefined"
                label="Product Base"
                value={productType || ""}
                onChange={(e: any) => {
                  setProductType(e.target.value);
                  if (e.target.value?.length > 6) {
                    return notify({
                      text: "Product Base should be less than six.",
                      status: "error",
                    });
                  }
                  setProductType(e.target.value);
                  setBatchNumber(
                    getBatchNumber({
                      productType: e.target.value,
                      date,
                      sequence,
                      usedIn: "items"
                    })
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
                id="label-left"
              >
                Date {`<YYMMDD>`}
              </InputLabel>
              <Datetime
                timeFormat={false}
                dateFormat="YYMM"
                onChange={(moment) => {
                  if (typeof moment === "object") {
                    //@ts-ignore
                    setDate(moment.format("YYMM"));
                    setBatchNumber(
                      getBatchNumber({
                        productType,
                        date: moment.format("YYMM"),
                        sequence,
                        usedIn: "items"
                      })
                    );
                  }
                }}
                inputProps={{ placeholder: "Date Picker Here" }}
              />
            </GridItem>
          </GridContainer>
        </DrawerSidebar>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
