import React from 'react';
import LegacyModelPage from '../components/LegacyModelPage';

interface IProps {}

const LegacyModel: React.FC<IProps> = () => {
  return <LegacyModelPage isLoading={false} />;
};

export default LegacyModel;
