import { getToken } from 'admin/auth/utils';
import axios from 'axios';
import { OVS_PRODUCTS_ENDPOINT } from 'config';

export const api = axios.create({
  baseURL: OVS_PRODUCTS_ENDPOINT,
  timeout: 60000,
  headers: {
    //   'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken().auth}`,
  },
});
