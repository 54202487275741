import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useMemo } from 'react';

import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';
import OVSForm from '@components/Form/OVSForm';
import { UpdateBrokerInput } from 'apps/events/app/types/eventsGlobalTypes';

const ItemForm: React.FC<{
  formBag: FormikProps<UpdateBrokerInput>;
  disableLoginButton: boolean;
}> = (props) => {
  const { formBag, disableLoginButton } = props;

  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            type: 'text',
            name: itemFields.name,
            label: 'Name',
          },
          {
            type: 'text',
            name: itemFields.url,
            label: 'URL',
          },
          {
            type: 'text',
            name: itemFields.login,
            label: 'Login',
          },
          {
            type: 'text',
            name: itemFields.password,
            label: 'Password',
          },
        ],
      },
    ], // eslint-disable-next-line
    [formBag.values]
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader />
        <Form>
          <GridContainer>
            <OVSForm formFieldsData={formFieldsData} />
          </GridContainer>
          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
