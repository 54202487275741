import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LegacyBatchContext } from '../context/LegacyBatchContext';
import { legacyBatchRoutes } from '../constants';
import LegacyAddItem from '../components/LegacyAddItem';
import { IProductBatch } from '../types';
import * as actions from '../redux/actions';
import HandleSocketIO from './HandleSocketIO';

const EditItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();

  const [loadingOne, setLoading] = useState(true);
  const { itemId = '' } = useParams();

  const { getItems, loading } = React.useContext(LegacyBatchContext);
  const [item, setItem] = useState({} as IProductBatch);

  useEffect(() => {
    actions.LegacyBatchActions.getOneItem(itemId)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false);
        setItem(res);
      })
      .catch((error) => {
        setLoading(false);
      }); // eslint-disable-next-line
  }, []);

  const handleSubmit = (createItemInput: IProductBatch, totalItems: number) => {
    actions.LegacyBatchActions.updateLegacyItem(itemId, {
      ...createItemInput,
      productBatchID: itemId,
    }).then(async (res) => {
      notify({
        status: 'success',
        text: `Updated successfully.`,
      });
      getItems();
      history(legacyBatchRoutes.list);
    });
  };

  if (loadingOne || !item.productBatchID) return <GridLoader />;

  var today = new Date();
  var date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  var time =
    today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  var dateTime = date + ' ' + time;

  return (
    <>
      <HandleSocketIO productBatchID={itemId} />
      <LegacyAddItem
        initialValues={{
          ...item,
          productBatchDate: item.productBatchDate || dateTime,
        }}
        handleSubmit={handleSubmit}
        isLoading={loading}
      />
    </>
  );
};

export default EditItemContainer;
