import CardGridLoader from '@components/Loaders/CardGridLoader';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { UpdatePublisherInput as Input } from '../../types/eventsGlobalTypes';
import AddItem from '../components/AddItem';
import { itemRoutes } from '../constants';
import { PublisherContext as Context } from '../context/PublisherProvider';
import useUpdateEvent from '../hooks/useUpdateItem';

import { useViewPublisherQuery } from '../queries';
import { Publisher } from '../types/Publisher';

const EditItemContainer = () => {
  const { itemId = '' } = useParams();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(Context);

  const [item, setItem] = React.useState({} as Publisher);

  const { data, loading } = useViewPublisherQuery({ id: itemId });

  useEffect(() => {
    setItem({
      ...(data?.viewPublisher || ({} as Publisher)),
    }); // eslint-disable-next-line
  }, [data]);

  const { mutation, mutationOpts } = useUpdateEvent({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: Input) => {
    mutation({
      variables: {
        updatePublisherInput: {
          publisherId: input.publisherId,
          topic: input.topic,
          broker: input.broker,
        },
      },
    });
  };

  if (!item?._id || loading) return <CardGridLoader />;

  return (
    <AddItem
      handleSubmit={handleSubmit}
      isLoading={mutationOpts.loading}
      item={{
        publisherId: item?._id,
        topic: item?.topic,
        broker: item.broker?._id || '',
      }}
    />
  );
};

export default EditItemContainer;
