import { gql } from '@apollo/client';

/**
 * uploadAuthenticationInstanceDockerStandalone(
file: Upload!
): String!
 */
export const uploadAuthenticationInstanceDockerStandaloneMutation = gql`
  mutation UploadAuthenticationInstanceDockerStandalone($file: Upload!) {
    uploadAuthenticationInstanceDockerStandalone(file: $file)
  }
`;

/**
 * uploadCodeEventsDockerStandalone(
file: Upload!
): String!
 */
export const uploadCodeEventsDockerStandaloneMutation = gql`
  mutation UploadCodeEventsDockerStandalone($file: Upload!) {
    uploadCodeEventsDockerStandalone(file: $file)
  }
`;

/**
 * uploadCodeGensDockerStandalone(
file: Upload!
): String!
 */
// export const uploadCodeGensDockerStandaloneMutation = gql`
//   mutation UploadCodeGensDockerStandalone($file: Upload!) {
//     uploadCodeGensDockerStandalone(file: $file)
//   }
// `;

/**
 * uploadCodeSystemsDockerStandalone(
file: Upload!
): String!
 */
export const uploadCodeSystemsDockerStandaloneMutation = gql`
  mutation UploadCodeSystemsDockerStandalone($file: Upload!) {
    uploadCodeSystemsDockerStandalone(file: $file)
  }
`;

/**
 * uploadCustomerDetailsDockerStandalone(
file: Upload!
): String!
 */
export const uploadCustomerDetailsDockerStandaloneMutation = gql`
  mutation UploadCustomerDetailsDockerStandalone($file: Upload!) {
    uploadCustomerDetailsDockerStandalone(file: $file)
  }
`;

/**
 * uploadDistributorDetailsDockerStandalone(
file: Upload!
): String!
 */
export const uploadDistributorDetailsDockerStandaloneMutation = gql`
  mutation UploadDistributorDetailsDockerStandalone($file: Upload!) {
    uploadDistributorDetailsDockerStandalone(file: $file)
  }
`;

/**
 * uploadItemBatchesDockerStandalone(
file: Upload!
): String!
 */
export const uploadItemBatchesDockerStandaloneMutation = gql`
  mutation UploadItemBatchesDockerStandalone($file: Upload!) {
    uploadItemBatchesDockerStandalone(file: $file)
  }
`;

/**
 * uploadItemFirmwaresDockerStandalone(
file: Upload!
): String!
 */
export const uploadItemFirmwaresDockerStandaloneMutation = gql`
  mutation UploadItemFirmwaresDockerStandalone($file: Upload!) {
    uploadItemFirmwaresDockerStandalone(file: $file)
  }
`;

/**
 * uploadItemFleetsDockerStandalone(
file: Upload!
): String!
 */
export const uploadItemFleetsDockerStandaloneMutation = gql`
  mutation UploadItemFleetsDockerStandalone($file: Upload!) {
    uploadItemFleetsDockerStandalone(file: $file)
  }
`;

/**
 * uploadItemSKUsDockerStandalone(
file: Upload!
): String!
 */
export const uploadItemSKUsDockerStandaloneMutation = gql`
  mutation UploadItemSKUsDockerStandalone($file: Upload!) {
    uploadItemSKUsDockerStandalone(file: $file)
  }
`;

/**
 * uploadItemStocksDockerStandalone(
file: Upload!
): String!
 */
export const uploadItemStocksDockerStandaloneMutation = gql`
  mutation UploadItemStocksDockerStandalone($file: Upload!) {
    uploadItemStocksDockerStandalone(file: $file)
  }
`;

/**
 * uploadItemsDockerStandalone(
file: Upload!
): String!
 */
export const uploadItemsDockerStandaloneMutation = gql`
  mutation UploadItemsDockerStandalone($file: Upload!) {
    uploadItemsDockerStandalone(file: $file)
  }
`;

/**
 * uploadRolesDockerStandalone(
file: Upload!
): String!
 */
export const uploadRolesDockerStandaloneMutation = gql`
  mutation UploadRolesDockerStandalone($file: Upload!) {
    uploadRolesDockerStandalone(file: $file)
  }
`;

/**
 * uploadRolesPermissionsDockerStandalone(
file: Upload!
): String!
 */
export const uploadRolesPermissionsDockerStandaloneMutation = gql`
  mutation UploadRolesPermissionsDockerStandalone($file: Upload!) {
    uploadRolesPermissionsDockerStandalone(file: $file)
  }
`;
