import React from 'react';
import { format2DP,  } from 'utils';

interface IProps {
	value: string;
}

const Format2DPCell: React.FC<IProps> = ({ value }) => {

	return <div>{format2DP(parseFloat(value) as number || 0)}</div>;
};

export default Format2DPCell;
