import React from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import { Formik, FormikProps } from 'formik';
import { createEventValidationSchema } from 'apps/things/app/validations';
import ItemForm from './ItemForm';
import CardWrapper from '@components/Card/CardWrapper';
import { UpdateEventInput } from '../../types/eventsGlobalTypes';

interface IRTable {
  handleSubmit: (payload: UpdateEventInput) => void;
  isLoading: boolean;
  item: UpdateEventInput;
}

const AddItem: React.FC<IRTable> = ({ handleSubmit, isLoading, item }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <Formik
            onSubmit={async (values: any) => handleSubmit(values)}
            initialValues={{
              ...item,
            }}
            validationSchema={createEventValidationSchema}
            validateOnChange={true}
          >
            {(formBag) => (
              <ItemForm
                formBag={(formBag as unknown) as FormikProps<UpdateEventInput>}
                disableLoginButton={isLoading}
              />
            )}
          </Formik>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default AddItem;
