import React from 'react';

interface IProps {
  condition: boolean;
  children: React.ReactNode;
}

const IF: React.FC<IProps> = ({ condition, children }) => {
  if (!condition) {
    return null;
  }
  return <>{children}</>;
};

export default IF;
