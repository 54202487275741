import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import { dashboardRoutes as itemRoutes } from "./constants";
import Dashboard from "./containers/Dashboard";

interface IProps {}

export const routes = {
  path: itemRoutes.main,
  element: <DefaultOutlet />,
  children: [{ index: true, element: <Dashboard /> }],
};

const DashboardIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default DashboardIndex;
