import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { Row } from 'react-table';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';

import { useEffect } from 'react';
import { GetAllCategoriesSelectorResponse_page_edges } from '../types/GetAllCategoriesSelectorResponse';
import { useLazyGetAllCategoriesSelectorQuery } from '../queries';
import { useDeleteCategorySelector } from '../mutations';
import {
  GetAllCategorySelectorSelectors,
  GetAllCategorySelectorSelectorsVariables,
} from '../types/GetAllCategorySelectorSelectors';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';



export interface ICategorySelectorContext {
  items: GetAllCategoriesSelectorResponse_page_edges[];
  setItems: (items: GetAllCategoriesSelectorResponse_page_edges[]) => void;
  loading: boolean;
  tableActions: ITableActions[];
  refetch:
    | ((
        variables?:
          | Partial<GetAllCategorySelectorSelectorsVariables>
          | undefined
      ) => Promise<ApolloQueryResult<GetAllCategorySelectorSelectors>>)
    | undefined;
  getItems: () => void;
}

export const CategorySelectorContext = React.createContext<
  ICategorySelectorContext
>({} as ICategorySelectorContext);

const CategorySelectorProvider: React.FC<IProps> = ({ children }) => {
  // state
  const [items, setItems] = React.useState<
    GetAllCategoriesSelectorResponse_page_edges[]
  >([] as GetAllCategoriesSelectorResponse_page_edges[]);

  // context
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  // hooks
  const notify = useNotifier();
  const [
    getItems,
    { data, refetch, loading },
  ] = useLazyGetAllCategoriesSelectorQuery({
    first: 100,
  });

  useEffect(() => {
    if (data)
      setItems(data?.getAllCategorySelectorSelectors?.page?.edges || []);
  }, [data]);

  const { deleteCategory } = useDeleteCategorySelector(() => {});

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteCategory({
          variables: {
            categorySelectorId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Category selector deleted successfully',
        });
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllCategorySelectorSelectors?.page?.edges ||
              items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllCategorySelectorSelectors?.page?.edges ||
              items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      refetch,
      getItems,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} category selector(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading]
  );

  return (
    <CategorySelectorContext.Provider value={value}>
      {children}
    </CategorySelectorContext.Provider>
  );
};

export default CategorySelectorProvider;
