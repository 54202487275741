
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
import Datetime from 'react-datetime';
import { makeStyles } from '@material-ui/core';
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import { useGetAllDistributorsQuery } from 'apps/clientService/app/distributor/queries';
import { useLazyGetAllItemBatches } from 'apps/things/app/batch/queries';
import { ItemBatchContext } from 'apps/things/app/batch/context/BatchProvider';
import { PAGE_LIST_LIMIT } from '@constants';
import OVSForm from '@components/Form/OVSForm';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';
import {
  QueryOrder,
  UpdateItemFleetInput,
} from 'apps/things/app/types/thingsGlobalTypes';

import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleColumnCheckbox from '@components/ReactTable/ToggleColumnCheckbox';

const useStyles = makeStyles({
    formControl: {marginTop: "1.7rem", marginLeft: "-2rem"}
})
const ItemForm: React.FC<{
  formBag: FormikProps<UpdateItemFleetInput>;
  disableLoginButton: boolean;
  actionButtonName?: string;
  productBase?: string;
  itemList: string[];
  setItemList: (args: string[]) => void;
  editForm?: boolean;
}> = (props) => {
  const classes = useStyles()
  const { formBag, disableLoginButton, actionButtonName = 'Add' } = props;
  const { itemBatches: items, setItemBatches: setItems } = useContext(
    ItemBatchContext
  );

  const [getItems, { data: itemsData }] = useLazyGetAllItemBatches({
    first: 100,
    queryorder: QueryOrder.DESC,
  });

  useEffect(() => {
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!itemsData) {
      return;
    }
    setItems(itemsData?.getAllItemBatches?.page?.edges || []); // eslint-disable-next-line
  }, [itemsData]);

  const [distributorOptions, setDistributorOptions] = useState<
    { _id: string; name: string }[]
  >([]);

  const { data, refetch } = useGetAllDistributorsQuery({
    first: PAGE_LIST_LIMIT,
  });

  useEffect(() => {
    setDistributorOptions(
      data?.getAllDistributors?.page?.edges?.map((item) => ({
        _id: item?.node?._id || '',
        name: item?.node?.orgContactPerson?.contact?.email || '',
      })) || []
    ); // eslint-disable-next-line
  }, [data]);
  
  const searchItem = async (search: string) => {
    if (!refetch) {
      return Promise.resolve(
        distributorOptions?.map((it) => ({ name: it.name, value: it._id }))
      );
    }
    const refetchData = await refetch({
      first: PAGE_LIST_LIMIT,
      search,
    });

    const items = refetchData?.data?.getAllDistributors?.page?.edges?.map(
      (item) => {
        return {
          label: item?.node?.orgContactPerson?.contact?.email || '',
          value: item.node?._id || '',
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  };
  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            md: 5,
            type: 'select-async',
            name: itemFields.clientId,
            label: 'Distributor',
            options: distributorOptions,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.clientId, e);
            },
            value: formBag.values.clientId || '',
            searchPromise: searchItem as (
              skuName: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [formBag.values, distributorOptions]
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='fleetName'
                name={itemFields.fleetName}
                multiline
              />
            </GridItem>           
            <GridItem xs={1} sm={1} md={1} >
             
             </GridItem>
            <OVSForm formFieldsData={formFieldsData} />
            <GridItem xs={1} sm={1} md={1} >
             
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <InputLabel
                htmlFor='simple-select'
                id='label-left'
                style={{ marginBottom: 10 }}
              >
                assignDate
              </InputLabel>
              <Datetime
                timeFormat={false}
                dateFormat='DD-MM-YY'
                value={formBag.values.assignDate}
                onChange={(moment) => {
                  if (typeof moment === 'object') {
                    formBag.setFieldValue(
                      'assignDate',
                      moment.format('DD-MM-YY')
                    );
                  }
                }}
                inputProps={{ placeholder: '' }}
              />
              {formBag.errors.assignDate ? (
                <div className='error-message'>{JSON.stringify(formBag.errors.assignDate)}</div>
              ) : null}
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
             
             </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Description'
                name={itemFields.description}
                multiline
              />
            </GridItem>

            <GridItem xs={1} sm={1} md={1} >
             
            </GridItem>

             <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Day Code Days Limit (Past 24 hrs)'
                name={itemFields.dayCodeCountLimit}
                type="number"
                disabled={!formBag.values.disableDayCodeCountLimit}
              />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableDayCodeCountLimit} />} label="" />
            </GridItem>

            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Total Code Days Limit (Lifetime)'
                name={itemFields.totalCodeCountLimit}
                type="number"
                disabled={!formBag.values.disableTotalCodeCountLimit}
              />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableTotalCodeCountLimit} />} label="" />
            </GridItem>

            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Reset Code Count'
                name={itemFields.resetCodeCount}
                type="number"
                disabled={!formBag.values.disableResetCodeCount}
              />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableResetCodeCount} />} label="" />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Free Code Count'
                name={itemFields.freeCodeCount}
                type="number"
                disabled={!formBag.values.disableFreeCode}
              />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableFreeCode} />} label="" />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Max day codes generated (duration algorithm)'
                name={itemFields.minimumDayCodesGenerated}
                type="number"
                disabled={!formBag.values.disableMinimumDayCodesGenerated}
              />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableMinimumDayCodesGenerated} />} label="" />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Days to check duration'
                name={itemFields.daysToCheckDuration}
                type="number"
                disabled={!formBag.values.disableDaysToCheckDuration}
              />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableDaysToCheckDuration} />} label="" />
            </GridItem>

            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Max Credit Stack Days (Stack Algorithm)'
                name={itemFields.maxCreditStackDays}
                type="number"
                disabled={!formBag.values.disableMaxCreditStackDays}
              />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableMaxCreditStackDays} />} label="" />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Max Credit Stack Code Events'
                name={itemFields.maxCreditStackCodeEvents}
                type="number"
                disabled={!formBag.values.disableMaxCreditStackCodeEvents}
              />
            </GridItem> 
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableMaxCreditStackCodeEvents} />} label="" />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <TextInput
                label='Code Generation Interval (milliseconds)'
                name={itemFields.codeGenInterval}
                type="number"
                disabled={!formBag.values.disableCodeGenInterval}
              />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <FormControlLabel className={classes.formControl} control={<ToggleColumnCheckbox name={itemFields.disableCodeGenInterval} />} label="" />
            </GridItem>

          </GridContainer>
          <Grid>
            <Button
              color='primary'
              onClick={() => {
              formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
