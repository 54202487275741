import CardWrapper from "@components/Card/CardWrapper";
import Button from "@components/CustomButtons/Button";
import TextInput from "@components/CustomInput/TextInput";
import GridContainer from "@components/Grid/GridContainer";
import GridItem from "@components/Grid/GridItem";
import { ButtonGroup, IconButton, InputAdornment } from "@material-ui/core";
import { FormikProps } from "formik";
import SyncIcon from "@material-ui/icons/Sync";
import codeGenerator from "../utils";

const RefreshButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onClick}
        onMouseDown={() => {}}
      >
        <SyncIcon />
      </IconButton>
    </InputAdornment>
  );
};
const Simulator: React.FC<{
  formBag: FormikProps<{
    hashTop: string;
    hashRoot: string;
    hashIndex: string;
    codeDays: number;
    oemItemId: string;
    itemID: string;
    otpHex: string;
    codeCount: number;
    otpDec: string;
  }>;
}> = ({ formBag }) => {
  const { values, setFieldValue } = formBag;
  const hashTopGenerateCode = () => {
    const hashTop = values.hashTop;
    const hashRoot = values.hashRoot;
    const hashIndex = codeGenerator.get_hash_index(hashRoot, hashTop);
    const otpDec = codeGenerator.dec_str_padding_low_high(hashTop);
    setFieldValue("hashIndex", hashIndex);
    setFieldValue("otpDec", otpDec);
  };

  const hashIndexGenerateCode = () => {
    const hashRoot = values.hashRoot;
    const hashIndex = parseInt(values.hashIndex);
    const hashTop = codeGenerator.OTP_hex(hashRoot, hashIndex, 0);
    const otpDec = codeGenerator.dec_str_padding_low_high(hashTop);
    setFieldValue("hashTop", hashTop);
    setFieldValue("otpDec", otpDec);
  };

  const daysCodeGenerator = (days: number) => {
    const hashRoot = values.hashRoot;
    const hashIndex = parseInt(values.hashIndex);
    const hashTop = codeGenerator.OTP_hex(hashRoot, hashIndex, days);
    const otpDec = codeGenerator.dec_str_padding_low_high(hashTop);
    setFieldValue("hashTop", hashTop);
    setFieldValue("hashIndex", hashIndex - values.codeDays);
    setFieldValue("otpDec", otpDec);
  };

  const otpDecCodeGenerator = () => {
    const hashRoot = values.hashRoot;
    const hashTop = codeGenerator.padded_decimal_to_hex(values.otpDec.trim());

    setFieldValue("hashTop", hashTop);
    const hashIndex = codeGenerator.get_hash_index(hashRoot, hashTop);
    setFieldValue("hashIndex", 100000 - hashIndex);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <TextInput
            placeholder={"HashRoot"}
            label={"HashRoot"}
            autoComplete="off"
            name="hashRoot"
            type={"text"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue("HashRoot", e.target.value);
            }}
            disabled
            value={values.hashRoot}
          />
          <TextInput
            placeholder={"HashTop"}
            label={"HashTop"}
            autoComplete="off"
            name="hashTop"
            type={"text"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue("hashTop", e.target.value);
            }}
            value={values.hashTop}
            endAdornment={<RefreshButton onClick={hashTopGenerateCode} />}
          />

          <TextInput
            placeholder={"HashIndex"}
            label={"HashIndex"}
            autoComplete="off"
            name="hashIndex"
            type={"text"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue("hashIndex", e.target.value);
            }}
            endAdornment={<RefreshButton onClick={hashIndexGenerateCode} />}
            value={values.hashIndex}
          />

          <TextInput
            placeholder={"codeDays"}
            label={"codeDays"}
            autoComplete="off"
            name="hashIndex"
            type={"number"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue("codeDays", e.target.value);
            }}
            value={values.codeDays}
          />
          <TextInput
            placeholder={"otpDec"}
            label={"otpDec"}
            autoComplete="off"
            name="otpDec"
            type={"text"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue("otpDec", e.target.value);
            }}
            value={values.otpDec}
            endAdornment={<RefreshButton onClick={otpDecCodeGenerator} />}
          />

          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button onClick={() => daysCodeGenerator(values.codeDays)}>
              Day Code
            </Button>
            <Button onClick={() => daysCodeGenerator(2192)}>Reset Code</Button>
            <Button onClick={() => daysCodeGenerator(1096)}>Free Code</Button>
          </ButtonGroup>
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default Simulator;
