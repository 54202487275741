import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState } from 'react';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';

import { useLazyGetAllPublishersQuery } from '../queries';
import useOVSPagination from 'hooks/useOVSPagination';
import { GetAllPublishersResponse_page_edges } from '../types/GetAllPublishersResponse';
import {
  GetAllPublishers,
  GetAllPublishersVariables,
} from '../types/GetAllPublishers';
import { funNumberAgr } from '@types';
export interface IPublisherContext {
  items: GetAllPublishersResponse_page_edges[];
  setItems: (item: GetAllPublishersResponse_page_edges[]) => void;
  loading: boolean;
  getItems: () => void;
  activeStage: number;
  setActiveStage: (stage: number) => void;
  tableActions: ITableActions[];
  refetchItems:
    | ((
        variables?: Partial<GetAllPublishersVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllPublishers>>)
    | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  setPageLimit: funNumberAgr
}

export const PublisherContext = React.createContext<IPublisherContext>(
  {} as IPublisherContext
);

const PublisherProvider: React.FC<IProps> = ({ children }) => {
  const [activeStage, setActiveStage] = useState(0);
  const [item, setItem] = React.useState(
    {} as GetAllPublishersResponse_page_edges
  );
  const [items, setItems] = React.useState<
    GetAllPublishersResponse_page_edges[]
  >([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging,
    setPageListLimit,
    pageListLimit
  } = useOVSPagination();
  /// hooks
  const [
    getItems,
    { refetch: refetchItems, data, loading, fetchMore },
  ] = useLazyGetAllPublishersQuery({
    first: pageListLimit,
  });

  useEffect(() => {
    setItems(data?.getAllPublishers?.page?.edges || []);
    setPaging({
      ...data?.getAllPublishers?.pageData,
      ...data?.getAllPublishers?.page?.pageInfo,
    }); // eslint-disable-next-line
  }, [data]);

  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllPublishersVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      setIsLoadingMore(true);
      const _data = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllPublishers?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllPublishers?.pageData,
        ..._data?.data?.getAllPublishers?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };
  
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);
  }

  const value = React.useMemo(
    () => ({
      item,
      setItem,
      items,
      setItems,
      goTo,
      paging,
      loading: loading,
      getItems: getItems,
      refetchItems,
      tableActions: [],
      activeStage,
      setActiveStage,
      setPageLimit
    }), // eslint-disable-next-line
    [items, loading, paging]
  );

  return (
    <PublisherContext.Provider value={value}>
      {children}
    </PublisherContext.Provider>
  );
};

export default PublisherProvider;
