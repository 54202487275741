import { legacyApi } from 'apps/migration/app/services/legacyApi';
import { legacyTypes } from './constants';
export class LegacyActions {
  static listLegacy(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY,
      payload: { data },
    };
  }

  static listLegacySuccess(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY_SUCCESS,
      payload: { data },
    };
  }
  static listLegacyFail(data: any) {
    return {
      type: legacyTypes.LIST_LEGACY_FAIL,
      payload: { data },
    };
  }

  static getDistributorsLegacy(params: string) {
    return legacyApi.get(`distributors?${params}`);
  }

  static migrateDistributor(distributorID: string) {
    return legacyApi.post(`distributors/migrate/${distributorID}`);
  }

  static migrateDistributorCodeHistory(distributorID: string) {
    return legacyApi.post(`distributor/migrate-code-history/${distributorID}`);
  }

  static migrateDistributorBulkCodeHistory(distributorID: string) {
    return legacyApi.post(`distributor/bulk-migrate-code-history/${distributorID}`);
  }

  static migrateDistributorCodeGenerator(distributorID: string) {
    return legacyApi.post(
      `distributor/migrate-code-generator/${distributorID}`
    );
  }

  static bulkMigrateDistributorCodeGenerator() {
    return legacyApi.post(`distributor/bulk-migrate-code-generator`);
  }

  static bulkMigrateDistributor() {
    return legacyApi.post(`/distributors/bulk-migrate`);
  }
  static bulkMigrateAssignedItems() {
    return legacyApi.post(`/distributors/bulk-migrate-assigned-items`);
  }
  static migrateAssignedItems(distributorID: string) {
    return legacyApi.post(
      `distributors/assigned-items-migration/${distributorID}`
    );
  }
}
