import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useNavigate } from 'react-router-dom';
import { legacyRoutes } from '../constants'
import { LegacyActions } from '../redux/actions';
import { LegacyCategoryContext } from '../context/LegacyCategoryContext';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);


const LegacyTableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const history = useNavigate()
  const { getItems } = useContext(LegacyCategoryContext)

  const deleteItem = () => {
    LegacyActions.deleteItem(row.values['productCategoryID']).then((res) => {
      getItems();
      setIsModalOpen(false);
    });
  };

  return (
    <div className='actions-right' style={{ display: 'flex' }}>
      <Tooltip
        id='expander'
        onClick={() => {
          history(
            legacyRoutes.getEditLink(row.values['productCategoryID'])
          );
        }}
        title='Edit '
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title='Delete '
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={deleteItem}
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete?</div>
      </ActionModal>
    </div>
  );
};

export default LegacyTableActions;
