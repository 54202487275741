import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { deleteEventMutation } from '../mutations';
import { DeleteEvent, DeleteEventVariables } from '../types/DeleteEvent';

const useDeleteEvent = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    DeleteEvent,
    DeleteEventVariables
  >(deleteEventMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Event deleted successfully', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useDeleteEvent;
