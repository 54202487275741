import React, { useEffect } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import { itemTableData } from '../constants/itemTableData';
import { useItemState } from '../context/ItemProvider';
import CardWrapper from '@components/Card/CardWrapper';
import { itemsRoutes } from '../constants';
import { useAuth } from 'admin/auth/context/AuthProvider';
import useServicerPermission from 'hooks/useServicerPermission';

interface IRTable {
}

const ItemPageComponent: React.FC<IRTable> = () => {
  const { isDistributor, isServicer, isSuperAdmin, isDistributorStaff } = useAuth();
  const {
    items,
    tableActions,
    clientItems,
    getClientItems,
    loading,
    getItems,
    goTo,
    paging,
    setSearchQuery,
    reload,
    setReload,
    setPageLimit,
    clientError,
    searchQuery
  } = useItemState();


  useServicerPermission({ loading, clientError })

  useEffect(() => {
    if (isDistributor && getClientItems) {
      if (!loading) {
        getClientItems();
      }
    } else {
      if (!isDistributor && !getItems) {
        return
      }
      if (reload) {
        if (!isDistributor) getItems();
      } else if (items?.length === 0 || !loading) {
        if (!isDistributor) getItems();
      }
    }

    return () => { setReload(true) }

    // eslint-disable-next-line
  }, []);

  const columnsToRemove = [
    'hashRoot',
    'codeGenerator',
    'Profile',
    'actionScope',
    'skuID',
    'BatchID',
    'idType'
  ];
  
  const columns = itemTableData({}).header?.filter(item => {
    if ((isDistributor || isServicer) && columnsToRemove.includes(item.Header)) {
      return false;
    }
    return true;
  }).map((item: any) => {
    if (isSuperAdmin && item.Header === "AccountNumber") {
      return {
        Header: 'SellerItemId',
        accessor: item.accessor 
      };
    }
    return item; 
  });

  return (
    <GridContainer> 
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              columns as Column[]
            }
            paging={paging}
            goTo={goTo}
            data={(isDistributor || isServicer) ? clientItems || [] : items || []}
            checkedIds={[]}
            tableActions={tableActions}
            isLoading={loading}
            addItemPageLink={itemsRoutes.add}
            addItemBtnText='Add Items'
            hideRightToolbar={isDistributor}
            isDistributorStaff={isDistributorStaff}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
const ItemPage = React.memo(ItemPageComponent);
export default ItemPage;


