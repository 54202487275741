import React, { useContext, useEffect } from "react";

// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";

import CardWrapper from "@components/Card/CardWrapper";
import { LegacyAssignedItemContext } from "../context/LegacyAssignedItemsContext";
import { legacyTableData } from "../constants/data";

interface IRTable {
  bulkDeleteProducts?: () => void;
  isLoading: boolean;
}

const LegacyPage: React.FC<IRTable> = () => {
  const {
    items,
    getItems,
    loading,
    setSearchQuery,
    searchQuery,
    goTo,
    paging,
    advancedSearch,
    queries,
    setQueries,
    setOffset,
  } = useContext(LegacyAssignedItemContext);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={legacyTableData.header as Column[]}
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={"/"}
            addItemBtnText="Add"
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            goTo={goTo}
            paging={paging}
            advancedSearch={advancedSearch}
            queries={queries}
            setQueries={setQueries}
            refetchData={getItems}
            setOffset={setOffset}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
export default LegacyPage;
