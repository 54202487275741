const range = (len: any) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newPersonEmpty = (index: number) => {
  return {
    index: index + 1,
    test_type: '',
    angaza_id: '',
    hash_top: '',
    hash_root: '',
    hash_index: '',
    otp_count: '',
  };
};

export default function makeData(...lens: any) {
  const makeDataLevel = (depth = 0): any => {
    const len = lens[depth];
    return range(len).map((d, index) => {
      return {
        ...newPersonEmpty(index),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      };
    });
  };

  return makeDataLevel();
}
