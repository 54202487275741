import Button from '@components/CustomButtons/Button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js';

// @ts-ignore noqa
const useStyle = makeStyles(styles);

interface IProps {
  isModalOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  okText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  handleOnClickOkey?: () => void;
  handleOnClickCancel?: () => void;
  loading?: boolean;
}

const DefaultModalContent = () => <h5>No content found.</h5>;

const DefaultModal: React.FC<IProps> = ({
  isModalOpen,
  toggleModal,
  children,
}) => {
  const classes = useStyle();
  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={isModalOpen}
        keepMounted
        onClose={() => console.warn('close modal')}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={() => toggleModal(!isModalOpen)}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id='small-modal-slide-description'
          className={classes.modalBody + ' ' + classes.modalSmallBody}
        >
          {!children && <DefaultModalContent />}
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DefaultModal;
