import { IProps } from '@components/messages/types';
import { IAdvancedQuery } from '@components/ReactTable/TableToolBar/AdvancedSearch/types';
import { generateQueryParams } from 'apps/migration/app/utils';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/epics/userPrefEpics';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { LegacyActions } from '../redux/actions';

export interface ILegacyItem {
  productCategoryID: string;
  productCategoryName: string;
  productCategoryDescription: string;
  image: string;
}
export interface ILegacyCodeHistoryContext {
  items: ILegacyItem[];
  setItems: (item: any) => void;
  loading: boolean;
  getItems: () => void;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  advancedSearch: (queries: IAdvancedQuery[]) => void;
  queries: IAdvancedQuery[];
  setQueries: (queries: IAdvancedQuery[]) => void;
  setOffset: (offset: string | number) => void;
}

export const LegacyCodeHistoryContext = createContext<
  ILegacyCodeHistoryContext
>({} as ILegacyCodeHistoryContext);

const LegacyCodeHistoryProvider: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const itemsSelector = useSelector(
    (state: RootState) => state.legacyCodeHistory.items
  );
  const isProgress = useSelector(
    (state: RootState) => state.legacyCodeHistory.isProgress
  );
  const [items, setItems] = useState<ILegacyItem[]>([]);
  const [paging, setPaging] = useState({});
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [queries, setQueries] = useState<IAdvancedQuery[]>([
    {
      column: '',
      value: '',
      hidden: false,
    },
  ]);

  useEffect(() => {
    setItems(itemsSelector.data);
    setPaging(itemsSelector.paging);
  }, [itemsSelector]);
  // context

  const goTo = (next = true) => {
    let params = '';
    if (searchQuery !== undefined) {
      params += 'search=' + searchQuery;
    }
    const q = generateQueryParams(queries);
    if (q) {
      params += `&${q}`
    }
    if (next) {
      params += '&' + itemsSelector.paging?.next;
    } else {
      params += '&' + itemsSelector.paging?.prev;
    }
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy(params)
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };
  const setOffset = (offset: number | string) => {
    let params = '';
    params += `offset=${offset}`;
    if (searchQuery !== undefined) {
      params += '&search=' + searchQuery;
    }
   

    const q = generateQueryParams(queries);
    if (q) {
      params += `&${q}`;
    }
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy(params)
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        dispatch(LegacyActions.listLegacyFail(err));
      });
  };
  const search = () => {
    if (searchQuery === undefined) return;
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy('search=' + searchQuery)
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  const advancedSearch = (_queries: IAdvancedQuery[]) => {
    const q = generateQueryParams(_queries);
    if (!q) return;
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy(q)
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const getItems = () => {
    dispatch(LegacyActions.listLegacy({}));
    LegacyActions.getLegacy('')
      .then((res) => {
        dispatch(LegacyActions.listLegacySuccess(res.data));
      })
      .catch((err) => {
        dispatch(LegacyActions.listLegacyFail(err));
      });
    return [];
  };

  const value = useMemo(
    () => ({
      items,
      setItems,
      loading: isProgress,
      getItems,
      setSearchQuery,
      searchQuery,
      goTo,
      paging,
      advancedSearch,
      queries,
      setQueries,
      setOffset,
    }), // eslint-disable-next-line
    [items, isProgress, searchQuery, queries]
  );

  return (
    <LegacyCodeHistoryContext.Provider value={value}>
      {children}
    </LegacyCodeHistoryContext.Provider>
  );
};

export default LegacyCodeHistoryProvider;
