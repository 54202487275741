import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import _ from 'lodash';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useMemo } from 'react';

import { UpdateEventInput } from '../../types/eventsGlobalTypes';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { actionTypeOptions, actorTypeOptions, itemFields } from '../constants';
import OVSForm from '@components/Form/OVSForm';

const ItemForm: React.FC<{
  formBag: FormikProps<UpdateEventInput>;
  disableLoginButton: boolean;
}> = (props) => {
  const { formBag, disableLoginButton } = props;

  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
         
          {
            type: 'date',
            name: itemFields.time,
            label: 'Time',
            value: formBag.values.time,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.time, e);
            },
            error: formBag.errors.time as string,
          },
          {
            type: 'switch',
            name: itemFields.signal,
            label: 'Generate a signal?',
            value: formBag.values.signal,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.signal, e.target.checked);
            },
            error: formBag.errors.signal as string,
          },
        ],
      },
      {
        name: 'Action',
        fields: [
          {
            type: 'text',
            name: itemFields.actionLabel,
            label: 'Action Label',
          },
          {
            type: 'select',
            name: itemFields.actionType,
            label: 'Action Type',
            options: actionTypeOptions,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.actionType, e.target.value);
            },
            value: _.get(formBag.values, itemFields.actionType) || '',
          },
          {
            type: 'textarea',
            name: itemFields.actionDescription,
            label: 'Action Description',
          },
        ],
      },
      {
        name: 'Actor',
        fields: [
          {
            type: 'text',
            name: itemFields.actorName,
            label: 'Actor Name',
          },
          {
            type: 'select',
            name: itemFields.actorType,
            label: 'Actor Type',
            options: actorTypeOptions,
            onChange: (e: any) => {
              formBag.setFieldValue(itemFields.actorType, e.target.value);
            },
            value: _.get(formBag.values, itemFields.actorType) || '',
          },
          {
            type: 'textarea',
            name: itemFields.actorDescription,
            label: 'Actor Description',
          },
        ],
      },
      {
        name: 'Location',
        fields: [
          {
            type: 'number',
            name: itemFields.latitude,
            label: 'Latitude',
          },
          {
            type: 'number',
            name: itemFields.longitude,
            label: 'Longitude',
          },
          {
            type: 'text',
            name: itemFields.placeName,
            label: 'Place Name',
          },
        ],
      },
    ], // eslint-disable-next-line
    [formBag.values]
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader />
        <Form>
          <GridContainer>
            <OVSForm formFieldsData={formFieldsData} />
          </GridContainer>
          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
