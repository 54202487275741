import { useMutation } from '@apollo/client';
import clientAccount from 'apps/accounts/app/utils/clientAccount';
import {
  RestoreSignalClassWithTimeStamp,
  RestoreSignalClassWithTimeStampVariables,
} from 'apps/events/app/signal/types/RestoreSignalClassWithTimeStamp';
import useNotifier from 'hooks/useNotifier';
import { handleGQLErrors } from 'utils/gqlErrors';
import { restoreSignalClassWithTimeStampMutation } from '../mutations';

const useUnArchiveSignal = ({ onComplete }: { onComplete?: () => void }) => {
  const notify = useNotifier();
  const [mutation, mutationOpts] = useMutation<
    RestoreSignalClassWithTimeStamp,
    RestoreSignalClassWithTimeStampVariables
  >(restoreSignalClassWithTimeStampMutation, {
    client: clientAccount,
    onCompleted: (data) => {
      if (onComplete) {
        onComplete();
      }
      notify({ text: 'Success', status: 'success' });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  return {
    mutation,
    mutationOpts,
  };
};

export default useUnArchiveSignal;
