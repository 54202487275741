import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyModelTableActions';

export const legacyModelTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <Actions {...row.getToggleRowExpandedProps()} row={row} />
      ),
    },
    { Header: 'versionID', accessor: 'versionID' },
    { Header: 'protocolVersion', accessor: 'protocolVersion' },
    { Header: 'firmwareVersion', accessor: 'firmwareVersion' },
    { Header: 'allowed_codes', accessor: 'allowed_codes' },
  ],
};
