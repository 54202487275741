import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Row } from "react-table";
import ActionModal from "@components/Modal/ActionModal";
import { useMutation } from "@apollo/client";
import clientSimulator from "utils/clientSimulator";
import useNotifier from "hooks/useNotifier";
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import { ReverseCodeEvent, ReverseCodeEventVariables } from "../types/ReverseCodeEvent";
import { reverseCodeEventMutation } from "../mutations";
import { CodeEventContext } from "../context/CodeEventProvider";
import { useAuth } from "admin/auth/context/AuthProvider";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const CodeEventTableActions: React.FC<IProps> = ({ row }) => {

  const classes = useStyles();
  const notify = useNotifier();
  const { itemIdd } = useParams();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { refetch } = useContext(CodeEventContext)
  const { isSuperAdmin } = useAuth()
  const [reverseCodeEvents] = useMutation<ReverseCodeEvent, ReverseCodeEventVariables>(
    reverseCodeEventMutation,
    {
      client: clientSimulator,
      onCompleted: async (data) => {
        notify({
          status: "success",
          text: "Code Events reversed successfully",
        });
        refetch && refetch()
      },
      onError: async (data) => {
        notify({
          status: "error",
          text: "There was a problem reversing Code Events",
        });
      }
    }
  );

  return (
    <div className="actions-right" style={{ display: "flex" }}>
      <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
        <Tooltip
          title="Delete"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            id="expander"
            aria-label="Delete"
            className={classes.tableActionButton}
            style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
          >
            <Delete
              className={classes.tableActionButtonIcon + " " + classes.delete}
            />
          </IconButton>
        </Tooltip>
        {(true) && (
          <span className={classes.stopSign} role="img" aria-label="Stop">
            🚫
          </span>
        )}
      </div>
      <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
        <Tooltip
          id='expander'
          title='Edit'
          placement='top'
          classes={{ tooltip: classes.tooltip }}

        >
          <IconButton
            id='expander'
            aria-label='Edit'
            className={classes.tableActionButton}
            style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
          >
            <Edit
              className={classes.tableActionButtonIcon + ' ' + classes.edit}
            />
          </IconButton>
        </Tooltip>
        {(true) && (
          <span className={classes.stopSign} role="img" aria-label="Stop">
            🚫
          </span>
        )}
      </div>
        <HoverDropdown
        isDistributor={!isSuperAdmin }
          menus={[
            {
              name: "Reverse Code Events",
              action: () => {
                // setCodeEventModalOpen(true);
                setIsModalOpen(true)
              },
            },

          ]}
        />
      {isModalOpen ? (
        <ActionModal
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          handleOnClickOkey={() =>

            reverseCodeEvents({
              variables: {
                reverseCodeEventInput: {
                  // @ts-ignore
                  itemId: itemIdd,
                  codeEventID: row.values["_id"]
                }
              },
            })
          }
          handleOnClickCancel={() => setIsModalOpen(false)}
          okText="Reverse"
        >
          <div>Are you sure you want to reverse this code event?</div>
        </ActionModal>
      ) : null}
    </div>
  );
};

export default CodeEventTableActions;
