import { NetworkStatus, useMutation } from '@apollo/client';
import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateItemFirmwareInput } from 'apps/things/app/types/thingsGlobalTypes';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import AddItemFirmware from '../components/AddItemFirmware';
import { itemFirmwareRoutes } from '../constants';
import { createItemFirmwareMutation } from '../mutations';
import { useGetAllItemFirmwaresQuery } from '../queries';
import {
  CreateItemFirmware,
  CreateItemFirmwareVariables,
} from '../types/CreateItemFirmware';

const AddItemFirmwareContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const { refetch, networkStatus } = useGetAllItemFirmwaresQuery({
    first: 100,
  });

  const [createItemFirmware, createItemFirmwareOpts] = useMutation<
    CreateItemFirmware,
    CreateItemFirmwareVariables
  >(createItemFirmwareMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
      notify({
        status: 'success',
        text: 'Item firmware created successfully.',
      });
      await refetch();
      history(itemFirmwareRoutes.list);
    },
    onError: (err) => {
      const messages = err?.graphQLErrors || [];
      // @ts-ignore
      const msg = JSON.stringify(messages?.map(item => item?.extensions?.response?.message));
      notify({ text: msg, status: 'error' })
      handleGQLErrors(notify, err);
    },
  });

  const handleSubmit = (createItemFirmwareInput: CreateItemFirmwareInput) => {
    createItemFirmware({
      variables: { createItemFirmwareInput },
    });
  };

  if (networkStatus === NetworkStatus.refetch) return <GridLoader />;

  return (
    <AddItemFirmware
      handleSubmit={handleSubmit}
      isLoading={createItemFirmwareOpts.loading}
    />
  );
};

export default AddItemFirmwareContainer;
