
import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { IconProps } from '@material-ui/core/Icon';
import classNames from 'classnames';
import styles from "assets/jss/material-dashboard-pro-react/components/customChipStyle";


interface ICustomChipProps {
  label: string;
  onDelete: () => void;
  deleteIcon?: React.ReactElement<SvgIconProps | IconProps>;
  color: Color;
  background?: boolean;
  classes: { [key: string]: any };
  icon?: React.ReactElement<SvgIconProps | IconProps>;
  accountNumber?: boolean;
}

const CustomChip = (props: ICustomChipProps) => {
  const {
    color,
    classes,
    background,
    icon,
    accountNumber
  } = props;

  const chipClasses = classNames({
    [classes.chip]: true,
    [classes[color]]: background,
    [classes[`${color}NoBackground`]]: !background,
    [classes[`${color}Icon`]]: true,
    [classes.accountNumber]: accountNumber
  });
  const iconClasses = classNames({
    [classes[`${color}Icon`]]: !accountNumber,
    [classes[`${color}AccountNumber`]]: accountNumber
  });

  return (
    <Chip
      label={props.label}
      onDelete={props.onDelete}
      deleteIcon={props.deleteIcon}
      variant="outlined"
      className={chipClasses}
      icon={icon && React.cloneElement(icon, { className: iconClasses  })}
      style={{marginRight: "5px"}}
    />
  );
};

enum Color {
  primary = 'primary',
  info = 'info',
  success = 'success',
  warning = 'warning',
  danger = 'danger'
}

export default withStyles(styles as any)(CustomChip as any) as any;

