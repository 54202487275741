import gql from 'graphql-tag';
import { itemFragment } from '../item/queries';

/**
 * Mutation.initializeCodeGenerator(
initializeCodeGenInput: InitializeCodeGenInput!
): CodeGenerator!
 */

export const initializeCodeGeneratorMutation = gql` 
  mutation InitializeCodeGenerator(
    $initializeCodeGenInput: InitializeCodeGenInput!
  ) {
    initializeCodeGen(initializeCodeGenInput: $initializeCodeGenInput) {
      _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    type
    actionScope
    actorName
    profile
    codeSystem {
       _id
    deleteStatus
    deleteAt
    createdAt
    updatedAt
    system
    hashChainLength
    hashMaxJump
    freeCodeJump
    resetCodeJump
    codeRange
    }
    hashRoot
    hashTop
    codeCount
    hashIndex
    codeReversalCount
   
    }
  }
`;

/**
 * Mutation.deleteCodeGenerator(
CodeGenId: String!
): String!
 */
export const deleteCodeGeneratorMutation = gql`
  mutation DeleteCodeGenerator($CodeGenId: String!) {
    deleteCodeGenerator(CodeGenId: $CodeGenId)
  }
`;

/**
 * Mutation.assignCodeGeneratorToItem(
assignCodeGeneratorToItemInput: AssignCodeGeneratorToItemInput!
): Item!
 */

export const assignCodeGeneratorToItemMutation = gql`
  ${itemFragment}
  mutation AssignCodeGeneratorToItem(
    $assignCodeGeneratorToItemInput: AssignCodeGeneratorToItemInput!
  ) {
    assignCodeGeneratorToItem(
      assignCodeGeneratorToItemInput: $assignCodeGeneratorToItemInput
    ) {
      ...Item
    }
  }
`;

/**
 * type CodeResponse {
codeType: CodeTypes!
codeHex: String!
codeDec: String!
}
 */
const codeResponseFragment = gql`
  fragment CodeResponse on CodeResponse {
    codeType
    codeHex
    codeDec
  }
`;
/**
 * Mutation.generateDaysCode(
generateDaysCodeInput: GenerateDaysCodeInput!
): CodeResponse!
 */
export const generateDaysCodeMutation = gql`
  ${codeResponseFragment}
  mutation GenerateDaysCode($generateDaysCodeInput: GenerateDaysCodeInput!) {
    generateDaysCode(generateDaysCodeInput: $generateDaysCodeInput) {
      ...CodeResponse
    }
  }
`;

/**
Mutation.generateResetCode(
generateResetCodeInput: GenerateCodeInput!
): CodeResponse!
 */
export const generateResetCodeMutation = gql`
  ${codeResponseFragment}
  mutation GenerateResetCode($generateResetCodeInput: GenerateCodeInput!) {
    generateResetCode(generateResetCodeInput: $generateResetCodeInput) {
      ...CodeResponse
    }
  }
`;

/**
 * Mutation.generateFreeCode(
generateFreeCodeInput: GenerateCodeInput!
): CodeResponse!
 */
export const generateFreeCodeMutation = gql`
  ${codeResponseFragment}
  mutation GenerateFreeCode($generateFreeCodeInput: GenerateCodeInput!) {
    generateFreeCode(generateFreeCodeInput: $generateFreeCodeInput) {
      ...CodeResponse
    }
  }
`;

/**
 * 
distributorIncreaseFreeCodeCountForItem
Mutation.distributorIncreaseFreeCodeCountForItem(
itemId: ID!
freeCodeCount: Int!
): CodeGenerator!
 */
export const distributorIncreaseFreeCodeCountForItemMutation = gql`
  mutation DistributorIncreaseFreeCodeCountForItem(
    $itemId: ID!
    $freeCodeCount: Int!
  ) {
    distributorIncreaseFreeCodeCountForItem(
      itemId: $itemId
      freeCodeCount: $freeCodeCount
    ) {
      _id
    }
  }
`;

/**
 * distributorIncreaseResetCodeCountForItem(
itemId: ID!
resetCodeCount: Int!
): CodeGenerator!
 */
export const distributorIncreaseResetCodeCountForItemMutation = gql`
  mutation DistributorIncreaseResetCodeCountForItem(
    $itemId: ID!
    $resetCodeCount: Int!
  ) {
    distributorIncreaseResetCodeCountForItem(
      itemId: $itemId
      resetCodeCount: $resetCodeCount
    ) {
      _id
    }
  }
`;

/**
 * sendSms(
telephone: String!
message: String!
): String!
 */

export const sendSmsMutation = gql `
mutation SendSms(
  $telephone:String! 
  $message: String!
  ) {  
    sendSms(telephone: $telephone, message:$message )
}
`
