import React from "react";
import DefaultOutlet from "routes/DefaultOutlet";
import { messageTemplateRoutes as itemRoutes } from "./constants";
import List from "./containers/MessageTemplateContainer";
import EditMessageTemplateContainer from "./containers/EditMessageTemplateContainer";

interface IProps {}

export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: itemRoutes.edit, element: <EditMessageTemplateContainer /> },
    
  ],
};

const MessageTemplateIndex: React.FC<IProps> = () => {
  return <DefaultOutlet />;
};

export default MessageTemplateIndex;
