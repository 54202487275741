import { useContext, useEffect, useState } from 'react';
import { useGetAllClientItemsInItemFleetQuery } from '../queries';
import { useAuth } from 'admin/auth/context/AuthProvider';
import { IGetAllClientItemsInItemFleet_getAllClientItemsInItemFleet_page_edges } from '../types/IGetAllClientItemsInItemFleet';
import { ItemContext } from '../context/ItemProvider';
import { PAGE_LIST_LIMIT } from '@constants';

const useClientItem = () => {
  const { userPref } = useAuth();
  const { itemFleetId } = useContext(ItemContext);
  const [items, setItems] = useState<
    IGetAllClientItemsInItemFleet_getAllClientItemsInItemFleet_page_edges[]
  >([]);

  const { data, loading, refetch } = useGetAllClientItemsInItemFleetQuery({
    first: PAGE_LIST_LIMIT,
    igetAllClientItemsInItemFleet: {
      clientId: userPref?._id,
      itemFleetId,
    },
  });

  useEffect(() => {
    refetch &&
      refetch({
        first: 100,
        igetAllClientItemsInItemFleet: {
          clientId: userPref?._id,
          itemFleetId,
        },
      }); // eslint-disable-next-line
  }, [itemFleetId]);

  useEffect(() => {
    setItems(data?.getAllClientItemsInItemFleet?.page?.edges || []); // eslint-disable-next-line
  }, [data]);

  return {
    items,
    loading,
  };
};

export default useClientItem;
