import { LegacyCodeHistoryContext } from 'apps/migration/app/LegacyCodeHistory/context/LegacyCodeHistoryContext';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  value: string;
}

const LinkCell: React.FC<IProps> = ({ value }) => {
  const { setQueries, advancedSearch } = useContext(LegacyCodeHistoryContext);

  const onClick = () => {
    setQueries([{ column: 'productItemID', value: value }]);
    advancedSearch([{ column: 'productItemID', value: value }]);
  };
  return (
    <Link
      onClick={onClick}
      to={`/admin/legacy-code-history?productItemID=${value}`}
    >
      {value}
    </Link>
  );
};

export default LinkCell;
