import { ApolloQueryResult } from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext } from 'react';
import { Row } from 'react-table';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';

import { useEffect } from 'react';

import { useLazyGetAllGetAllApplicationSiteQuery } from '../queries';
import { useDeleteApplicationSite } from '../mutations';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GetAllApplicationSitesResponse_page_edges } from '../types/GetAllApplicationSitesResponse';
import {
  GetAllApplicationSite,
  GetAllApplicationSiteVariables,
} from '../types/GetAllApplicationSite';

export interface IApplicationSiteContext {
  items: GetAllApplicationSitesResponse_page_edges[];
  setItems: (items: GetAllApplicationSitesResponse_page_edges[]) => void;
  loading: boolean;
  tableActions: ITableActions[];
  refetch:
    | ((
        variables?: Partial<GetAllApplicationSiteVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllApplicationSite>>)
    | undefined;
  getItems: () => void;
}

export const ApplicationSiteContext = React.createContext<
  IApplicationSiteContext
>({} as IApplicationSiteContext);

const ApplicationSiteProvider: React.FC<IProps> = ({ children }) => {
  // state
  const [items, setItems] = React.useState<
    GetAllApplicationSitesResponse_page_edges[]
  >([] as GetAllApplicationSitesResponse_page_edges[]);

  // context
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  // hooks
  const notify = useNotifier();
  const [
    getItems,
    { data, refetch, loading },
  ] = useLazyGetAllGetAllApplicationSiteQuery({
    first: 100,
  });

  useEffect(() => {
    if (data) setItems(data?.getAllApplicationSite?.page?.edges || []);
  }, [data]);

  const { deleteApplicationSite } = useDeleteApplicationSite(() => {});

  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteApplicationSite({
          variables: {
            applicationSiteId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Application site deleted successfully',
        });
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllApplicationSite?.page?.edges || items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetch) {
          const itemsFetched = await refetch({
            first: 100,
          });
          setItems(
            itemsFetched?.data?.getAllApplicationSite?.page?.edges || items
          );
          setTableActionLoading(false);
        } else {
          await getItems();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading,
      refetch,
      getItems,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} application(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [items, loading]
  );

  return (
    <ApplicationSiteContext.Provider value={value}>
      {children}
    </ApplicationSiteContext.Provider>
  );
};

export default ApplicationSiteProvider;
