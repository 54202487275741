import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { itemRoutes } from '../payPlanTemplate/constants';
import List from "../payPlanTemplate/containers/Item";
import Add from "../payPlanTemplate/containers/AddItemContainer"
import Edit from "../payPlanTemplate/containers/EditItemCotainer"

interface IProps { }
export const routes = {
  path: itemRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <List /> },
    { path: itemRoutes.add, element: <Add /> },
    { path: itemRoutes.edit, element: <Edit /> },
  ]

};
const ItemEventIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet
     
    />
  );
};

export default ItemEventIndex;
