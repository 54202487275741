import _ from 'lodash'
import { PermissionInput } from '../../types/clientServiceGlobalTypes';
const url = 'client-permissions';
export const itemRoutes = {
  list: `/admin/${url}`,
  add: `/admin/${url}/add`,
  edit: `/admin/${url}/:itemId`,
  getEditLink: (itemId: string) => `/admin/${url}/${itemId}`,
};

export const itemFields = {
  permissions: [],
  clientExclusiveServiceId: '',
  distributorId: '',
  authenticationInstanceId: ''
}

export const permissionOptions = _.map(Object.values(PermissionInput), (item) => ({
  _id: item,
  label: item
}));