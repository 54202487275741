import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { FleetContext } from 'apps/things/app/fleet/context/FleetProvider';
import {
  useLazygetAllAssetAccountActivitiesQuery,
  useLazyGetAllAssetAccountActivitiesCreditAmountQuery
} from '../queries';
// import {useLazy}
import {
  GetAllAssetAccountActivities,
  GetAllAssetAccountActivitiesVariables,
  GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges
} from '../types/GetAllAssetAccountActivities';
import { useLazydownloadAllAssetAccountActivitiesQuery } from '../queries';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';
import { originalFilterQuery } from '../constants';
import { IFilter } from '@components/ReactTable/FilterChips';
import { PAGE_LIST_LIMIT } from '@constants';

export interface IPaymentsContext {
  items: GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges[];
  allAssetAccounts: any;
  setItems: (
    itemSKUs: GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges[]
  ) => void;
  setTotalCreditAmount: (
    totalCreditAmount: number
  ) => void;
  totalCount: number
  loading: boolean;
  refetchItems:
  | ((
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllAssetAccountActivities>>)
  | undefined;
  getItems: () => void;
  allAssets: any;
  setCsvData: (csvData: any) => void;
  csvData: any;
  getDownloadCreditActivities: (variables: any) => void;

  getTotalCreditAmount: () => void;
  goTo: (nextPrev: boolean) => void;
  paging: any;
  filterDates?: any;
  filterFleets?: IFilter;
  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
  handleFilterWithDates?: (fromDate: any, toDate: any) => any
  handleResetFilterWithDates?: (columnName?: string) => any;
  query?: any;
  downloadLoading?: boolean
  distributorFleetData?: any
}

export const PaymentsContext = React.createContext<IPaymentsContext>(
  {} as IPaymentsContext
);

const PaymentsProvider: React.FC<IProps> = ({ children }) => {
  const [items, setItems] = React.useState<
    GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges[]
  >([] as GetAllAssetAccountActivities_getAllAssetAccountActivities_page_edges[]);
  const [totalCount, setTotalCreditAmount] = React.useState<number>(0);
  const [loading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filterDates, setFilterDates] = useState<IFilter>();
  const [filterFleets, setFilterFleets] = useState<IFilter>()
  const [query, setQuery] = useState(originalFilterQuery);
  const [allAssets, setAllAssets] = useState<any>()
  const [csvData, setCsvData] = useState(null);
  const [fleetId, setFleetId] = useState<any>()
  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();
  const {
    distributorItems,
    getDistributorItems,
    refetchDistributorItems,
  } = useContext(FleetContext);

  const originalQuery = originalFilterQuery
  const [
    getItems,
    { refetch: refetchItems, fetchMore, data, loading: dataLoading },
  ] = useLazygetAllAssetAccountActivitiesQuery({
    first: pageListLimit,
    query: originalQuery
  });
  const [getTotalCreditAmount,
    { refetch: refetchCreditAmount, data: creditData },
  ] = useLazyGetAllAssetAccountActivitiesCreditAmountQuery({
    query: originalQuery
  })

  const [getDownloadCreditActivities,
    { data: allAssetAccounts, loading: downloadLoading },
  ] = useLazydownloadAllAssetAccountActivitiesQuery({
    query: originalQuery
  })

  useEffect(() => {
    if (data && !dataLoading) {
      setItems(data?.getAllAssetAccountActivities?.page?.edges || items);
      setTotalCreditAmount(creditData?.getAllAssetAccountActivitiesCreditAmount || 0)
      setPaging({
        ...data?.getAllAssetAccountActivities?.pageData,
        ...data?.getAllAssetAccountActivities?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [data, creditData]);

  useEffect(() => {
    setAllAssets(allAssetAccounts?.downloadAllAssetAccountActivities)
  }, [allAssetAccounts, filterFleets, filterDates])

  useEffect(() => {
    getDistributorItems()
    // eslint-disable-next-line
  }, [])
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItems && refetchItems()
    }, 100);

  }
  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllAssetAccountActivitiesVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllAssetAccountActivities } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItems(_data?.data?.getAllAssetAccountActivities?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccountActivities?.pageData,
        ..._data?.data?.getAllAssetAccountActivities?.page?.pageInfo,
      });
    }
  };
  function convertDateString(originalDateString: any, dateType: String) {
    const date = new Date(originalDateString);
    if (dateType === "fromDate") {
      date.setHours(0, 0, 0, 0);
    }
    else if (dateType === "toDate") {
      date.setHours(23, 59, 59, 999);
    }
    const isoString = date.toISOString();
    return isoString;
  }

  const handleFilterWithDates = (fromDate: Date, toDate: Date) => {
    setCsvData(null)
    setAllAssets([])
    let isoToDate = convertDateString(toDate, "toDate")
    let isoFromDate = convertDateString(fromDate, "fromDate")
    let query = `credit.activities.action:CREDIT AND credit.balance:>=0 AND credit.activities.datetime:>=${isoFromDate} AND credit.activities.datetime:<=${isoToDate}`

    refetchItems({
      first: pageListLimit,
      query: query
    })
    refetchCreditAmount({
      query: query
    })
    let dateFilter: IFilter | undefined = undefined
    if (isoFromDate) {
      dateFilter = {
        id: `credit.activities.datetime-between-${isoFromDate}-${isoToDate}`,
        field: 'date',
        value: { from: isoFromDate, to: isoToDate }
      };
    }

    let fleetFilter: IFilter | undefined = undefined
    if (fleetId) {
      fleetFilter = {
        id: `fleet-${isoFromDate}-${isoToDate}`,
        field: 'fleet',
        value: fleetId
      };
    }

    setFilterDates(dateFilter)
    setFilterFleets(fleetFilter)
    setQuery(query)

  }

  const handleResetFilterWithDates = (columnName?: string) => {
    setCsvData(null)
    setAllAssets([])
    
    if(columnName === "date") {
      setFilterDates(undefined)
    }else if(columnName === "fleet") {
      setFilterFleets(undefined)
      setFleetId("")
    }
    else if(columnName === 'reset') {
      setFilterDates(undefined)
      setFilterFleets(undefined)
      setFleetId("")
    }
    setQuery(originalQuery)
    refetchItems({
      first: pageListLimit,
      query: originalQuery
    })
    refetchCreditAmount({
      query: originalFilterQuery
    })
  }

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    if (fetchMore) {
      const variables: GetAllAssetAccountActivitiesVariables = {
        first: pageListLimit,
      };
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllAssetAccountActivities } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      refetchCreditAmount({
        search: searchQuery,
        query: query
      })

      setItems(_data?.data?.getAllAssetAccountActivities?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllAssetAccountActivities?.pageData,
        ..._data?.data?.getAllAssetAccountActivities?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const searchClientFleets = async (search: string) => {
    if (!refetchDistributorItems) {
      return Promise.resolve(
        distributorItems?.map((it) => ({
          name: it?.node?.fleetName,
          value: it?.node?.fleetName,
        }))
      );
    }
    const refetchData = await refetchDistributorItems({
      first: PAGE_LIST_LIMIT,
      search
    });

    const items = refetchData?.data?.getAllAuthenticationInstances?.page?.edges?.map(
      (item: any) => {
        return {
          label: item?.node?.fleetName || '',
          value: item.node?.fleetName || '',
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  };

  const distributorFleetData = useMemo(
    () => [
      {
        name: "",
        fields: [
          {
            md: 12,
            type: "select-async",
            name: "fleetId",
            label: "Fleet",
            options: distributorItems?.map((it) => ({
              _id: it?.node?.fleetName || "",
              name: it?.node?.fleetName || "",
            })),
            onChange: (e: any) => {
              setFleetId(e);
            },
            value: fleetId || "",
            searchPromise: searchClientFleets as (
              arg: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [distributorItems, fleetId]
  );
  const value = React.useMemo(
    () => ({
      items,
      setItems,
      loading: loading || dataLoading,
      refetchItems,
      getItems,
      getTotalCreditAmount,
      setTotalCreditAmount,
      goTo,
      paging,
      setSearchQuery,
      searchQuery, setPageLimit,
      handleFilterWithDates,
      handleResetFilterWithDates,
      filterDates,
      totalCount,
      getDownloadCreditActivities,
      allAssetAccounts,
      setCsvData,
      csvData,
      allAssets,
      query,
      downloadLoading,
      distributorFleetData,
      filterFleets
    }), // eslint-disable-next-line
    [items, paging, searchQuery, filterDates, filterFleets,
      totalCount, allAssetAccounts, csvData, allAssets, query,
      downloadLoading, distributorFleetData]
  );

  return (
    <PaymentsContext.Provider value={value}>
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsProvider;
