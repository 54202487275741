import { useMutation } from "@apollo/client";
import _ from "lodash";
import GridLoader from "@components/Loaders/GridLoader";
import useNotifier from "hooks/useNotifier";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createAvatarMutation,
  updateAvatarByItemIDMutation,
} from "apps/things/app/avatar/mutations";
import { useGetSpecificAvatarForItemQuery } from "apps/things/app/avatar/queries";
import {
  CreateAvatar,
  CreateAvatarVariables,
} from "apps/things/app/avatar/types/CreateAvatar";
import {
  UpdateAvatarByItemID,
  UpdateAvatarByItemIDVariables,
} from "apps/things/app/avatar/types/UpdateAvatarByItemID";
import { useGetSpecificItemQuery } from "apps/things/app/item/queries";
import {
  GetSpecificItem_getSpecificItem,
  GetSpecificItem_getSpecificItem_codeGenerator,
} from "apps/things/app/item/types/GetSpecificItem";
import {
  CreateAvatarInput,
  GenerateCodeInput,
  UpdateAvatarByItemIDInput,
} from "apps/things/app/types/thingsGlobalTypes";
import clientSimulator from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import AddCodeGenerator from "../components/AddCodeGenerator";
import { CodeGeneratorContext } from "../context/CodeGeneratorProvider";
import {
  generateDaysCodeMutation,
  generateFreeCodeMutation,
  generateResetCodeMutation,
  initializeCodeGeneratorMutation,
} from "../mutations";

import {
  InitializeCodeGenerator,
  InitializeCodeGeneratorVariables,
} from "../types/InitializeCodeGenerator";
import {
  GenerateDaysCode,
  GenerateDaysCodeVariables,
} from "../types/GenerateDaysCode";
import { DISABLED } from "../constants";
import {
  GenerateFreeCode,
  GenerateFreeCodeVariables,
} from "../types/GenerateFreeCode";
import {
  GenerateResetCode,
  GenerateResetCodeVariables,
} from "../types/GenerateResetCode";
import { CodeSystemType } from "admin/types/globalTypes";
import { useGetAllCodeSystemsQuery } from "../../codeSystem/queries";
import { GetAllCodeSystems_getAllCodeSystems_page_edges } from "../../codeSystem/types/GetAllCodeSystems";
import { useCodeEventProvider } from "../../codeEvent/context/CodeEventProvider";

interface IProps {
  itemID?: string;
  generateDayCodeCallback?: () => void;
}
const AddCodeGeneratorContainer: React.FC<IProps> = ({
  itemID = "",
  generateDayCodeCallback,
}) => {
  const notify = useNotifier();
  const { itemId: _itemId = "" } = useParams();
  const [itemId] = useState(itemID || _itemId);
  const [codeSystems, setCodeSystems] = useState<
    GetAllCodeSystems_getAllCodeSystems_page_edges[]
  >([]);

  const handleSetCodeSystems = useCallback(
    (value: GetAllCodeSystems_getAllCodeSystems_page_edges[]) =>
      setCodeSystems(value),
    []
  );
  const { data: codeSystemsData } = useGetAllCodeSystemsQuery();
  useEffect(() => {
    if (codeSystemsData) {
      handleSetCodeSystems(
        _.get(codeSystemsData, "getAllCodeSystems.page.edges", [])
      );
    }
  }, [codeSystemsData, handleSetCodeSystems]);

  const getCodeSystemID = (codeSystemType: CodeSystemType): string => {
    const system = codeSystems?.filter(
      (it) => it?.node?.system === codeSystemType
    );
    return _.get(system, "[0].node._id") || "";
  };
  const {
    item,
    avatar,
    setItem,
    setItemID,
    setOemItemID,
    setAvatar,
    setHashRoot,
    setHashTop,
    setHashChainLength,
    setHashIndex,
    setOtpCount,
    setDaysRemaining,
    setPayGoState,
    setDaysPassed,
    setDeviceState,
    setDecHashTop,
    setOtpDec,
    setOtpHex,
    resetContext,
  } = React.useContext(CodeGeneratorContext);

  const [getItem, { data: itemNode, loading: getItemLoading }] =
    useGetSpecificItemQuery({
      itemId,
    });

  const [
    getItemAvatar,
    {
      loading: isGetAvatarLoading,
      data: itemAvatarData,
      refetch: refetchAvatar,
    },
  ] = useGetSpecificAvatarForItemQuery({
    itemId: itemId,
  });

    useEffect(() => {
    if (!isGetAvatarLoading && itemAvatarData) {
      setAvatar(itemAvatarData?.getSpecificAvatarforItem || avatar);
      const paygS = _.find(
        itemAvatarData?.getSpecificAvatarforItem?.sts,
        function (o) {
          return o.prop === "paygS";
        }
      );
      const remDays = _.find(
        itemAvatarData?.getSpecificAvatarforItem?.sts,
        function (o) {
          return o.prop === "remDays";
        }
      );

      const _daysPassed = _.find(
        itemAvatarData?.getSpecificAvatarforItem?.sts,
        function (o) {
          return o.prop === "daysPassed";
        }
      );

      const _deviceState = _.find(
        itemAvatarData?.getSpecificAvatarforItem?.sts,
        function (o) {
          return o.prop === "deviceState";
        }
      );

      if (_daysPassed) {
        setDaysPassed(parseInt(_daysPassed?.value));
      }

      if (_deviceState) {
        setDeviceState(_deviceState?.value);
      }

      if (remDays?.value) {
        setDaysRemaining(parseInt(remDays?.value.toString()));
        setPayGoState(paygS?.value || "");
      }
    } // eslint-disable-next-line
  }, [isGetAvatarLoading, itemAvatarData]);

  useEffect(() => {
    resetContext();
    getItem({ variables: { itemId } });
    getItemAvatar({ variables: { itemId } });
    // eslint-disable-next-line
  }, [itemId]);

  useEffect(() => {
    if (!getItemLoading && itemNode) {
      setItem(
        itemNode?.getSpecificItem || ({} as GetSpecificItem_getSpecificItem)
      );
      setItemID(itemNode?.getSpecificItem?._id || "");
      setOemItemID(itemNode?.getSpecificItem?.oemItemID || "");
      handleSetGenerator();
    }
    // eslint-disable-next-line
  }, [itemNode]);

  const [updateAvatar, updateAvatarOpts] = useMutation<
    UpdateAvatarByItemID,
    UpdateAvatarByItemIDVariables
  >(updateAvatarByItemIDMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({ status: "success", text: "Avatar updated successfully." });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const [createAvatar, createAvatarOpts] = useMutation<
    CreateAvatar,
    CreateAvatarVariables
  >(createAvatarMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: "success",
        text: `Avatar for item with id ${item?._id} created successfully.`,
      });
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const [createDayCode, createDayCodeOpts] = useMutation<
    GenerateDaysCode,
    GenerateDaysCodeVariables
  >(generateDaysCodeMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
      setDecHashTop(data?.generateDaysCode?.codeDec);
      setOtpDec(data?.generateDaysCode?.codeDec);
      setHashTop(data?.generateDaysCode?.codeHex);
      setOtpHex(data?.generateDaysCode?.codeHex);
      try {
        notify({ text: "Day code generated successfully", status: "success" });

        await handleRefetchData();
        generateDayCodeCallback && generateDayCodeCallback();
      } catch (error) {
        console.warn("Error handling create daycode callback");
      }
    },
    onError: (error) => {
      try {
        handleGQLErrors(notify, error);
      } catch (error) {
        notify({ text: "Error", status: "error" });
      }
    },
  });


  const [createFreeCode, createFreeCodeOpts] = useMutation<
    GenerateFreeCode,
    GenerateFreeCodeVariables
  >(generateFreeCodeMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
      setDecHashTop(data?.generateFreeCode?.codeDec);
      setOtpDec(data?.generateFreeCode?.codeDec);
      setHashTop(data?.generateFreeCode?.codeHex);
      setOtpHex(data?.generateFreeCode?.codeHex);
      notify({ text: "Free code generated successfully", status: "success" });
      await handleRefetchData();

      generateDayCodeCallback && generateDayCodeCallback();
    },
    onError: (error) => {
      handleGQLErrors(notify, error);
    },
  });
  const [createResetCode, createResetCodeOpts] = useMutation<
    GenerateResetCode,
    GenerateResetCodeVariables
  >(generateResetCodeMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
      setDecHashTop(data?.generateResetCode?.codeDec);
      setOtpDec(data?.generateResetCode?.codeDec);
      setHashTop(data?.generateResetCode?.codeHex);
      setOtpHex(data?.generateResetCode?.codeHex);
      notify({ text: "Reset code generated successfully", status: "success" });
      await handleRefetchData();
      generateDayCodeCallback && generateDayCodeCallback();
    },
    onError: (error) => {
      handleGQLErrors(notify, error);
    },
  });

  const [initializeCodeGen, initializeCodeGenOpts] = useMutation<
    InitializeCodeGenerator,
    InitializeCodeGeneratorVariables
  >(initializeCodeGeneratorMutation, {
    client: clientSimulator,
    onCompleted: (data) => {
      notify({
        status: "success",
        text: "Code Generator initialized successfully.",
      });
      setHashRoot(data?.initializeCodeGen?.hashRoot);
      setHashTop(data?.initializeCodeGen?.hashTop);
      setOtpCount(data?.initializeCodeGen?.codeCount);
      setHashIndex(data?.initializeCodeGen?.hashIndex);
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const { refetch } = useCodeEventProvider();

  const handleRefetchData = async () => {
    // TODO: refetch code events
    if (refetch) refetch();
    // await refetchCodeEvents();
  };

  const handleCreateCodeEvent = (generateCodeGenInput: GenerateCodeInput) => {
    // createCodeEvent({ variables: { generateCodeGenInput } });
  };

  const handleUpdateAvatar = async (
    updateAvatarByItemID: UpdateAvatarByItemIDInput
  ) => {
    await updateAvatar({
      variables: {
        updateAvatarByItemID,
      },
    });
  };

  const handleDaysCode = async (codeDays: number) => {
    await createDayCode({
      variables: {
        generateDaysCodeInput: {
          itemId,
          codeDays: parseInt(codeDays.toString()),
        },
      },
    });
  };

  const handleFreeCode = async () => {
    await createFreeCode({
      variables: {
        generateFreeCodeInput: {
          itemId,
        },
      },
    });
  };

  const handleResetCode = async () => {
    await createResetCode({
      variables: {
        generateResetCodeInput: {
          itemId,
        },
      },
    });
  };

  const handleCreateAvatar = async (createAvatarInput: CreateAvatarInput) => {
    await createAvatar({ variables: { createAvatarInput } });
  };

  const handleCreateOrUpdateAvatar = async (
    remDays: string,
    payGoState: string,
    deviceState: string,
    decHashTop: string,
    daysPassed: string
  ) => {
    const input = {
      sts: [
        { prop: "paygS", value: payGoState, meta: "" },
        { prop: "hashT", value: decHashTop, meta: "" },
        { prop: "remDays", value: remDays, meta: "" },
        { prop: "daysPassed", value: daysPassed + "d", meta: "" },
        { prop: "deviceState", value: deviceState, meta: "" },
      ],
      lastPub: Date(),
      lastSync: Date(),
      lastSub: Date(),
    };
    if (avatar?._id) {
      await handleUpdateAvatar({ ...input, itemId: item._id });
    } else {
      await handleCreateAvatar({ ...input, shadow: item._id });
    }

    if (refetchAvatar) {
      const updatedAvatar = await refetchAvatar({ itemId });
      setAvatar(updatedAvatar?.data?.getSpecificAvatarforItem || avatar);
    }
  };

  const handleSetGenerator = async () => {
    const itemCodeGenerator: GetSpecificItem_getSpecificItem_codeGenerator =
      itemNode?.getSpecificItem
        ?.codeGenerator as GetSpecificItem_getSpecificItem_codeGenerator;

    if (itemCodeGenerator) {
      // assign the item to an initialized code gen
      setHashRoot(itemCodeGenerator?.hashRoot || "");
      setHashTop(itemCodeGenerator?.hashTop || "");
      setOtpCount(itemCodeGenerator?.codeCount || 0);
      setHashChainLength(itemCodeGenerator?.hashIndex);
      setHashIndex(itemCodeGenerator.hashIndex);

      // TODO: set code history
    } else {
      // initialize code generator
      setDeviceState(DISABLED); // device state should be disabled
      await initializeCodeGen({
        variables: {
          initializeCodeGenInput: {
            codeSystemId:
              item?.itemFirmware?.codeSystem === CodeSystemType.ACP2
                ? getCodeSystemID(CodeSystemType.ACP2)
                : getCodeSystemID(CodeSystemType.ACP1), // '6092568a40b418900023e25d': '60925d07e894e83a1c66c309',
            itemId,
          },
        },
      });
    }
  };

  if (getItemLoading) return <GridLoader />;

  return (
    <AddCodeGenerator
      handleCreateCodeEvent={handleCreateCodeEvent}
      handleCreateOrUpdateAvatar={handleCreateOrUpdateAvatar}
      handleResetCode={handleResetCode}
      handleDaysCode={handleDaysCode}
      handleFreeCode={handleFreeCode}
      oemItemID={item?.oemItemID || ""}
      itemId={itemId}
      isLoading={
        initializeCodeGenOpts.loading ||
        createAvatarOpts.loading ||
        updateAvatarOpts.loading ||
        createDayCodeOpts.loading ||
        createResetCodeOpts.loading ||
        createFreeCodeOpts.loading
      }
    />
  );
};

export default AddCodeGeneratorContainer;
