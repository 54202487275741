import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React, { useContext, useState } from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import ActionModal from '@components/Modal/ActionModal';
import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import { useNavigate } from 'react-router-dom';
import { itemRoutes } from '../constants';
import { CustomerContext } from '../context/CustomerProvider';
import { DeletePerson, DeletePersonVariables } from '../types/DeletePerson';
import { deletePersonMutation } from '../mutations';
import clientClientService from '../../utils/clientClientService';
import HoverDropdown from '@components/CustomDropdown/HoverDropdown';
import IF from './IF';
import AssetAccountTable from './AssetAccountTable';
import DefaultModal from '@components/Modal/DefaultModal';

interface IProps {
  row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const notify = useNotifier();
  const history = useNavigate();
  const { getItems: refetch } = useContext(CustomerContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [customerId, setCustomerId] = useState('');
  const [assetAccountModalOpen, setAssetAccountModalOpen] = useState(false);
  const [deleteItem] = useMutation<DeletePerson, DeletePersonVariables>(
    deletePersonMutation,
    {
      client: clientClientService,
      onCompleted: (data) => {
        notify({
          status: 'success',
          text: 'Deleted successfully',
        });
        refetch();
        setIsModalOpen(false);
      },
    }
  );
  return (
    <div className='actions-right' style={{ display: 'flex' }}>
            <Tooltip
        title='Delete'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Delete'
          className={classes.tableActionButton}
          onClick={() => setIsModalOpen(true)}
        >
          <Delete
            className={classes.tableActionButtonIcon + ' ' + classes.delete}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id='expander'
        onClick={() => {
          history(itemRoutes.getEditLink(row.values['node._id']));
        }}
        title='Edit'
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          id='expander'
          aria-label='Edit'
          className={classes.tableActionButton}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
      </Tooltip>

      <HoverDropdown
        menus={[
          {
            name: 'Asset Accounts',
            hidden: true,
            action: () => {
              setCustomerId(row.values['node._id']);
              setAssetAccountModalOpen(true);
            },
          },
        ]}
      />
      <DefaultModal
        isModalOpen={assetAccountModalOpen}
        toggleModal={setAssetAccountModalOpen}
        handleOnClickOkey={() => console.log('closed')}
        handleOnClickCancel={() => setAssetAccountModalOpen(false)}
        okText=''
      >
        <IF condition={!!customerId}>
          <AssetAccountTable
            customerId={customerId}
            customerName={row.values['node.name']}
          />
        </IF>
      </DefaultModal>
      <ActionModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        handleOnClickOkey={() =>
          deleteItem({
            variables: { personId: row.values['node._id'] },
          })
        }
        handleOnClickCancel={() => setIsModalOpen(false)}
        okText='Delete'
      >
        <div>Are you sure you want to delete this customer?</div>
      </ActionModal>
    </div>
  );
};

export default TableActions;
