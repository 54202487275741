import DescriptionCell from '@components/ReactTable/DescriptionCell';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { Payment } from '../types/Payment';

interface IProps {
  paymentHistory: Payment[];
}
const useStyles = makeStyles({
  container: {
    marginBottom: 30,
  },
  table: {
    minWidth: 650,
  },
  th: { width: 180, fontWeight: 'bold' },
  btn: { padding: 0, marginLeft: 15 },
  thWithButton: { paddingTop: 7, paddingBottom: 7 },
});
const AssetHistoryTable: React.FC<IProps> = ({ paymentHistory }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Amount</TableCell>
            <TableCell>Datetime</TableCell>
            <TableCell>Instruction</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentHistory.map((row, index) => (
            <TableRow key={row.datetime + index}>
              <TableCell component='th' scope='row'>
                {row.amount}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.datetime}
              </TableCell>
              <TableCell component='th' scope='row'>
                <DescriptionCell value={row.instruction} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetHistoryTable;
