import DateCell from "@components/ReactTable/DateCell";
import DescriptionCell from "@components/ReactTable/DescriptionCell";

export const legacyTableData = {
  header: [
    { Header: "id", accessor: "_id" },
    {
      Header: "scanningLogic",
      accessor: "scanningLogic",
    },
    {
      Header: "numberOfIssuesRemaining",
      accessor: "numberOfIssuesRemaining",
      Cell: DescriptionCell,
    },
    {
      Header: "batchNumber",
      accessor: "batchNumber",
    },
    {
      Header: "scanningResult",
      accessor: "scanningResult",
      Cell: DescriptionCell,
    },
    {
      Header: "totalNumberOfIssuesDetected",
      accessor: "totalNumberOfIssuesDetected",
    },
    {
      Header: "numberOfItemsInBatch",
      accessor: "numberOfItemsInBatch",
    },
    {
      Header: "batchScanned",
      accessor: "batchScanned",
      Cell: DescriptionCell,
    },
    {
      Header: "createdAt",
      accessor: "createdAt",
      Cell: DateCell,
    },
    {
      Header: "updatedAt",
      accessor: "updatedAt",
      Cell: DateCell,
    },
  ],
};
