import { useMutation } from "@apollo/client";
import CardGridLoader from "@components/Loaders/CardGridLoader";
import useNotifier from "hooks/useNotifier";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import clientSimulator from "utils/clientSimulator";
import { handleGQLErrors } from "utils/gqlErrors";
import { UpdateDistributorSettingInput } from "../../types/clientServiceGlobalTypes";
import EditCodeGeneratorSetting from "../components/EditCodeGeneratorSettings";
import { itemRoutes } from "../constants";
import { updateDistributorSettingMutation } from "../mutations";
import { useLazyGetSpecificDistributorSettingQuery } from "../queries";
import { UpdateDistributorSetting, UpdateDistributorSettingVariables } from "../types/UpdateDistributorSetting"
import { DistributorSetting } from "../types/DistributorSetting";
import { useLocation } from "react-router-dom";
import { useAuth } from "admin/auth/context/AuthProvider";
const EditCodeGeneratorContainer = () => {
    const [id, setId] = useState("")
    const notify = useNotifier();
    const { loggedInUserId, isSuperAdmin } = useAuth()
    const location = useLocation()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        let distributorId = searchParams.get('distributorId');
        if (!distributorId) {
            distributorId = loggedInUserId
        }
        if (distributorId) {
            setId(distributorId)
        }
        setTimeout(() => {
            fetchData()
        }, 500)
        // eslint-disable-next-line
    }, [])

    const history = useNavigate();
    const [fetchData, { loading, data, refetch }] = useLazyGetSpecificDistributorSettingQuery({
        id: id
    });
    const [item, setItem] = React.useState({} as DistributorSetting);
    React.useEffect(() => {
        if (data?.getSpecificDistributorSetting) {
            setItem(data.getSpecificDistributorSetting);
        }
    }, [data]);

    const [updateItem, updateItemOpts] = useMutation<
        UpdateDistributorSetting,
        UpdateDistributorSettingVariables
    >(updateDistributorSettingMutation, {
        client: clientSimulator,
        onCompleted: (data) => {
            notify({
                status: "success",
                text: "Updated successfully",
            });
            refetch && refetch()
            isSuperAdmin && history(itemRoutes.list);
        },
        onError: (err) => {
            handleGQLErrors(notify, err);
        },
    });

    const handleUpdate = (updateDistributorSettingInput: UpdateDistributorSettingInput) => {
        updateItem({
            variables: {
                updateDistributorSettingInput: {
                    distributorId: id,
                    resetCodeCount: updateDistributorSettingInput.resetCodeCount,
                    freeCodeCount: updateDistributorSettingInput.freeCodeCount,
                    codeGenInterval: updateDistributorSettingInput.codeGenInterval,
                    dayCodeCountLimit: updateDistributorSettingInput.dayCodeCountLimit,
                    totalCodeCountLimit: updateDistributorSettingInput.totalCodeCountLimit,
                    maxCreditStackDays: updateDistributorSettingInput.maxCreditStackDays,
                    maxCreditStackCodeEvents: updateDistributorSettingInput.maxCreditStackCodeEvents
                }
            }
        })
    };

    if (!data?.getSpecificDistributorSetting?._id) return <CardGridLoader />;

    return (
        <EditCodeGeneratorSetting
            handleSubmit={handleUpdate}
            isLoading={updateItemOpts.loading || loading}
            item={item}
        />
    );
};

export default EditCodeGeneratorContainer;
