import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateSignalInput } from '../../types/thingsGlobalTypes';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';

import { SignalContext } from '../context/SignalProvider';
import useCreateSignal from '../hooks/useCreateSignal';

const AddItemContainer = () => {
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(SignalContext);

  // update when client Id is provided
  const {
    mutation: createItem,
    mutationOpts: createItemOpts,
  } = useCreateSignal({
    onComplete: async () => {
      if (refetch) await refetch();
      history(itemRoutes.list);
    },
  });

  const handleSubmit = (input: UpdateSignalInput) => {
    createItem({
      variables: {
        createSignalInput: {
          publisher: input.publisher || '',
          jmp: input.jmp,
          time: input.time,
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
    />
  );
};

export default AddItemContainer;
