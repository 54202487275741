import { PAGE_LIST_LIMIT } from '@constants';
import { IProduct } from 'apps/ecommerce/app/common/products';

export const productFields = {
  sku: 'sku',
  name: 'name',
  price: 'price',
  stock: 'stock',
  ratings: 'ratings',
  discount: 'discount',
  discountDuration: 'discountDuration',
  badge: 'badge',
  totalSale: 'totalSale',
  productType: 'productType',
  vendor: 'vendor',
  previewImages: 'previewImages',
  description: 'description',
  excerpt: 'excerpt',
  categoriesList: 'categoriesList',
  relatedProducts: 'relatedProducts',
  tags: 'tags',
  variationsList: 'variationsList',
  thumbs: 'thumbs',
  type: 'type',
};

export const MATERIAL = {
  slug: '',
  thumb: '',
  name: '',
  price: 0,
  quantity: 1,
};

export const INITIAL_PRODUCT: IProduct = {
  sku: '',
  name: '',
  ratings: 0,
  discount: '0',
  discountDuration: 'null',
  badge: 'New',
  totalSale: 0,
  price: 0,
  stock: 0,
  vendor: 'null',
  description: '',
  previewImages: [],
  excerpt: '',
  categoriesList: [],
  relatedProducts: [],
  tags: ['electronics'],
  variationsList: [
    {
      title: '',
      color: {
        name: '',
        thumb: '',
        code: '',
      },
      materials: [MATERIAL],
    },
  ],
  thumbs: [],
  type: ['electronics'],
  feature: false,
};
export const productRoutes = {
  list: '/admin/ecommerce-products',
  add: '/admin/ecommerce-products/add',
  edit: '/admin/ecommerce-products/:itemId',
  getEditLink: (itemId: string) => `/admin/ecommerce-products/${itemId}`,
};

export const defaultQueryVariables = {
  first: PAGE_LIST_LIMIT,
};
