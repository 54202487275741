import React from 'react';
import TitleCaseCell from '@components/ReactTable/TitleCaseCell';
import Format2DPCell from '@components/ReactTable/Format2DPCell';
import DateCell from '@components/ReactTable/DateCell';
import StatusCell from '@components/ReactTable/StatusCell';
import TableActions from '../components/TableActions';
import { Row } from 'react-table';
export const itemTableData = [
  {
    Header: 'Actions',
    id: 'expander', // It needs an ID
    Cell: ({ row }: { row: Row }) => (
      <TableActions {...row.getToggleRowExpandedProps()} row={row} />
    ),
  },
  {
    Header: 'AccountNumber',
    accessor: 'SellerItemId', 
    Cell: ({ value, column, row }: { value: any, column: any, row: any }) => {
      const accountStatus = row.original.Status;
      return <StatusCell value={value} header={column.Header} accountStatus={accountStatus} />;
    },
  },

  {
    Header: 'CodeEvent',
    accessor: 'codeEvent',
  },
  
  {
    Header: 'CodeDays',
    accessor: 'codeDays',
  },
  {
    Header: 'codeEventCreatedAt',
    accessor: 'codeEventCreatedAt',
    Cell: DateCell
  },
  {
    Header: 'Paid At',
    accessor: 'datetime',
    Cell: DateCell
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: Format2DPCell
  },
  {
    Header: 'Currency',
    accessor: 'Currency',
  },
  {
    Header: 'Customer Name',
    accessor: 'CustomerName', 
    Cell: TitleCaseCell
  },
  {
    Header: 'Phone Number',
    accessor: 'Phone',
  },
  {
    Header: 'Fleet Name',
    accessor: 'FleetName',
  },
  {
    Header: 'Plan Name',
    accessor: 'PlanName',
  },
  {
    Header: 'Street',
    accessor: 'street',
  },
  {
    Header: 'City',
    accessor: 'City',
  },
  {
    Header: 'SRPC',
    accessor: 'SRPC',
  },
  {
    Header: 'Country',
    accessor: 'Country',
  },
  {
    Header: 'PostCode',
    accessor: 'PostCode',
  },
  {
    Header: 'AddressLatitude',
    accessor: 'AddressLatitude',
  },
  {
    Header: 'AddressLongitude',
    accessor: 'AddressLongitude',
  },
  {
    Header: 'OEMItemID',
    accessor: 'OemItemId'
  },
  {
    Header: 'Account Stage',
    accessor: 'AccountStage'
  },
  {
    Header: 'Account Status',
    accessor: 'Status',
    Cell: ({ value, column, row }: { value: any, column: any, row: any }) => {
      const accountStatus = row.original.Status;
      return <StatusCell value={value} header={column.Header} accountStatus={accountStatus} />;
    },
  }
];
