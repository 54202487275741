import gql from 'graphql-tag';

/**
 * createAvatar(
createAvatarInput: CreateAvatarInput!
): Avatar!
 */
export const createAvatarMutation = gql`
  mutation CreateAvatar($createAvatarInput: CreateAvatarInput!) {
    createAvatar(createAvatarInput: $createAvatarInput) {
      _id
    }
  }
`;

/**
 * updateAvatarByItemID(
updateAvatarByItemID: UpdateAvatarByItemIDInput!
): Avatar!
 */
export const updateAvatarByItemIDMutation = gql`
  mutation UpdateAvatarByItemID(
    $updateAvatarByItemID: UpdateAvatarByItemIDInput!
  ) {
    updateAvatarByItemID(updateAvatarByItemID: $updateAvatarByItemID) {
      _id
    }
  }
`;

/**
 * activateAvatar(
avatarId: String!
): Avatar!
 */
export const activateAvatarMutation = gql`
  mutation ActivateAvatar($avatarId: String!) {
    activateAvatar(avatarId: $avatarId) {
      _id
    }
  }
`;

/**
 * Mutation.deactivateAvatar(
avatarId: String!
): Avatar!
 */
export const deactivateAvatarMutation = gql`
  mutation DeactivateAvatar($avatarId: String!) {
    deactivateAvatar(avatarId: $avatarId) {
      _id
    }
  }
`;

/**
 * Mutation.deleteAvatar(
avatarId: String!
): String!
 */
export const deleteAvatarMutation = gql`
  mutation DeleteAvatar($avatarId: String!) {
    deleteAvatar(avatarId: $avatarId)
  }
`;
