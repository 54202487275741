import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RoleContext } from 'admin/Role/context/RoleContext';
import { Row } from 'react-table';
import PermissionsTransfer from './PermissionsTransfer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  header: {
    background: '#FAF9F9'
  },
  text: {
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
interface IProps {
  row: Row;
}
const AuthenticationInstanceList: React.FC<IProps> = ({ row }) => {
  const classes = useStyles();
  const { authenticationInstances } = React.useContext(RoleContext);

  return (
    <div className={classes.root}>
      {authenticationInstances?.page?.edges?.map((instance, index) => (
        <ExpansionPanel
          key={instance?.node?.name || '' + index + row.values['node.name']}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon className={classes.text} />}
            aria-controls='panel1c-content'
            id='panel1c-header'
            className={classes.header}
          >
            <div className={classes.column}>
              <Typography className={classes.heading}>
                Authentication Instance #{index + 1}
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}>
                {instance?.node?.name} ({row.values['node.name']})
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <PermissionsTransfer row={row} authenticationInstanceId={instance?.node?._id || ''} />
        </ExpansionPanel>
      ))}
    </div>
  );
};

export default AuthenticationInstanceList;
