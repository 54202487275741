import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { dashboardRoutes } from './constants';
import DashboardReports from './components/reportsComponet';

interface IProps { }
export const routes = {
  path: dashboardRoutes.list,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <DashboardReports /> },
  ]

};
const DashboardReportIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet
    />
  );
};

export default DashboardReportIndex;
