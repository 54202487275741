import { provisionTypes } from 'store/actionTypes/provisionTypes';

export class ProvisionActions {
  static setProvisionItems(payload: any) {
    return {
      type: provisionTypes.SET_PROVISION_ITEMS,
      payload,
    };
  }

  static setUnresolvedItems(payload: any) {
    return {
      type: provisionTypes.SET_UNRESOLVED_ITEMS,
      payload,
    };
  }

  static setProgress(payload: any) {
    return {
      type: provisionTypes.SET_PROGRESS,
      payload,
    };
  }

  static setRawItems(payload: any) {
    return {
      type: provisionTypes.SET_RAW_ITEMS,
      payload,
    };
  }
}
