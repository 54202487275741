import { useMutation } from '@apollo/client';
import useNotifier from 'hooks/useNotifier';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clientSimulator from 'utils/clientSimulator';
import { handleGQLErrors } from 'utils/gqlErrors';
import AddItem from '../components/AddItem';
import { defaultItem, itemRoutes } from '../constants';
import { UpdateItemFleetInput } from 'apps/things/app/types/thingsGlobalTypes';
import { FleetContext } from '../context/FleetProvider';
import {
  CreateItemFleet,
  CreateItemFleetVariables,
} from '../types/CreateItemFleet';
import {
  createItemFleetFragmentMutation,
  updateItemFleetMutation,
} from '../mutations';
import {
  UpdateItemFleet,
  UpdateItemFleetVariables,
} from '../types/UpdateItemFleet';

const AddItemContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const { refetchItems: refetch } = React.useContext(FleetContext);
  const [itemList, setItemList] = useState([] as string[]);
  const [clientId, setClientId] = useState('');

  // update when client Id is provided
  const [updateItem] = useMutation<UpdateItemFleet, UpdateItemFleetVariables>(
    updateItemFleetMutation,
    {
      client: clientSimulator,
      onCompleted: async (data) => {
        if (refetch) await refetch();
        history(itemRoutes.list);
      },
      onError: (err) => {
        handleGQLErrors(notify, err);
      },
    }
  );

  // create mutations
  const [createItem, createItemOpts] = useMutation<
    CreateItemFleet,
    CreateItemFleetVariables
  >(createItemFleetFragmentMutation, {
    client: clientSimulator,
    onCompleted: async (data) => {
      notify({
        status: 'success',
        text: 'Created successfully.',
      });
      // assign to distributor
      if (clientId) {
        updateItem({
          variables: {
            updateItemFleetInput: {
              itemFleetId: data?.createItemFleet?._id,
              clientId: clientId,
              maxCreditStackCodeEvents: 5,
              maxCreditStackDays: 5
            },
          },
        });
      } else {
        refetch && refetch()
        history(itemRoutes.list);
      }
    },
    onError: (err) => {
      handleGQLErrors(notify, err);
    },
  });

  const handleSubmit = (input: UpdateItemFleetInput) => {
    if (input.clientId) {
      setClientId(input.clientId);
    }
  
    createItem({
      variables: {
        createItemFleetInput: {
          assignDate: input.assignDate,
          description: input.description || '',
          fleetName: input.fleetName || '',
          resetCodeCount: input.resetCodeCount,
          dayCodeCountLimit: input.dayCodeCountLimit,
          totalCodeCountLimit: input.totalCodeCountLimit,
          codeGenInterval: input.codeGenInterval,
          maxCreditStackDays: input.maxCreditStackDays,
          maxCreditStackCodeEvents: input.maxCreditStackCodeEvents,
          freeCodeCount: input.freeCodeCount,
          daysToCheckDuration: input.daysToCheckDuration,
          minimumDayCodesGenerated: input.minimumDayCodesGenerated,
          disableResetCodeCount: !input.disableResetCodeCount,
          disableDayCodeCountLimit: !input.disableDayCodeCountLimit,
          disableTotalCodeCountLimit: !input.disableTotalCodeCountLimit,
          disableCodeGenInterval: !input.disableCodeGenInterval,
          disableMaxCreditStackDays: !input.disableMaxCreditStackDays,
          disableMaxCreditStackCodeEvents: !input.disableMaxCreditStackCodeEvents,
          disableDaysToCheckDuration: !input.disableDaysToCheckDuration,
          disableMinimumDayCodesGenerated: !input.disableMinimumDayCodesGenerated,
          disableFreeCode: !input.disableFreeCode
        },
      },
    });
  };

  return (
    <AddItem
      handleSubmit={handleSubmit}
      item={defaultItem}
      isLoading={createItemOpts.loading}
      itemList={itemList}
      setItemList={setItemList}
    />
  );
};

export default AddItemContainer;
