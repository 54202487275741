import React, { useContext, useEffect, useState } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';
import { itemTableData } from '../constants/itemTableData';
import { MessageTemplateContext } from '../context/MessageTemplateProvider';
import CardWrapper from '@components/Card/CardWrapper';
import TablePreview from '@components/Loaders/TablePreview';
interface IRTable {
  title?: string;
  TableAction?: React.ReactNode;
  bulkDeleteProducts?: () => void;
}

const MessageTemplatePage: React.FC<IRTable> = () => {
  const {
    items,
    getItems,
    loading,
    goTo,
    setSearchQuery,
    searchQuery,
    paging, setPageLimit
  } = useContext(MessageTemplateContext);
  const [headers, setHeaders] = useState<Column[]>([]);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      setHeaders([...itemTableData ]);
    }
  }, [items]);
  if (headers.length === 0) {
    return <TablePreview items={items} loading={loading} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={
              headers as Column[]
            }
            data={items}
            checkedIds={[]}
            isLoading={loading}           
            paging={paging}
            goTo={goTo}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setPageLimit={setPageLimit}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};

export default MessageTemplatePage;
