import { combineReducers } from 'redux';
import { itemsReducer } from './itemsReducer';
import { userPrefReducer } from './userPrefReducer';
import { provisionReducer } from './provisionReducer';
import { legacyBatchReducer } from '../../apps/migration/app/LegacyBatch/redux/reducers';
import { legacyModelReducer } from 'apps/migration/app/LegacyModel/redux/reducers';
import { legacyCategoryReducer } from 'apps/migration/app/LegacyCategory/redux/reducers';
import { legacyFamilyReducer } from 'apps/migration/app/LegacyFamily/redux/reducers';
import { legacyItemReducer } from 'apps/migration/app/LegacyItem/redux/reducers';
import { legacyAssignedItemsReducer } from 'apps/migration/app/LegacyAssignedItems/redux/reducers';
import { legacyCodeHistoryReducer } from 'apps/migration/app/LegacyCodeHistory/redux/reducers';
import { legacyDistributorReducer } from 'apps/migration/app/LegacyDistributor/redux/reducers';
import { legacyJobReducer } from 'apps/migration/app/LegacyJob/redux/reducers';
import { legacyProtocolVersionReducer } from 'apps/migration/app/LegacyProtocolVersion/redux/reducers';
const rootReducer = combineReducers({
  userPref: userPrefReducer,
  provision: provisionReducer,
  items: itemsReducer,
  legacyBatch: legacyBatchReducer,
  legacyModel: legacyModelReducer,
  legacyCategory: legacyCategoryReducer,
  legacyFamily: legacyFamilyReducer,
  legacyItem: legacyItemReducer,
  legacyAssignedItems: legacyAssignedItemsReducer,
  legacyCodeHistory: legacyCodeHistoryReducer,
  legacyDistributor: legacyDistributorReducer,
  legacyJob: legacyJobReducer,
  legacyProtocolVersion: legacyProtocolVersionReducer
});

export default rootReducer;
