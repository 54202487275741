import { useState } from "react";
import { Form, Formik } from "formik";
// @material-ui/icons
import Typography from "@material-ui/core/Typography";

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
import React, { useEffect } from "react";
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import DecoderState from "./Decoder";
import EncoderState from "./Encoder";
import CardWrapper from "@components/Card/CardWrapper";
import AsyncSeachableSelect from "@components/CustomSelect/AsyncSeachableSelect";
import TextInput from "@components/CustomInput/TextInput";
import Button from "@components/CustomButtons/Button";
import { useParams } from "react-router-dom";
import { useLazyGetItembyOemItemIdQuery } from "../queries";
import { OpenTokenCodeType } from "../../types/thingsGlobalTypes";
import { encoderFields, decoderFields } from "../constants";
import { updateOpenTokenCodeGenStateMutation } from "../mutation";
import { updateOpenTokenCodeDecoderStateMutation } from "../mutation";
import { 
    UpdateOpenTokenCodeGenState, 
    UpdateOpenTokenCodeGenStateVariables } from "../types/UpdateOpenTokenCodeGenState";
import { 
    UpdateOpenTokenCodeDecoderState, 
    UpdateOpenTokenCodeDecoderStateVariables } from "../types/UpdateOpenTokenCodeDecoderState";
    import { UpdateTokenDataInput } from "../../types/thingsGlobalTypes";
    import { useMutation } from "@apollo/client";
    import clientSimulator from "utils/clientSimulator";
    import useNotifier from "hooks/useNotifier";
    import { handleGQLErrors } from "utils/gqlErrors";
const EncoderDecoder: React.FC<{

}> = (props) => {
    const notify = useNotifier()
    const { itemId = '' } = useParams();
    const [encoderState, setEncoderState] = useState<any>({})
    const [decoderState, setDecoderState] = useState<any>({})
    const [submitType, setSubmitType] = useState('');
    const [getItem, { data: item, loading: getItemLoading, refetch }] = useLazyGetItembyOemItemIdQuery(
        { oemItemId: itemId })

        const [updateOpenTokenData, updateOpenTokenDataOpts] = useMutation<
        UpdateOpenTokenCodeGenState, 
        UpdateOpenTokenCodeGenStateVariables
      >(updateOpenTokenCodeGenStateMutation, {
        client: clientSimulator,
        onCompleted: async (data) => {
          notify({
            status: 'success',
            text: 'Encoder State Updated successfully.',
          });

            refetch && refetch()
          },
          onError: (err) => {
            handleGQLErrors(notify, err);
          }
      });

      const [updateOpenTokenDecoderData, updateOpenTokenDecoderDataOpts] = useMutation<
      UpdateOpenTokenCodeDecoderState, 
      UpdateOpenTokenCodeDecoderStateVariables
    >(updateOpenTokenCodeDecoderStateMutation, {
      client: clientSimulator,
      onCompleted: async (data) => {
        notify({
          status: 'success',
          text: 'Decoder State Updated successfully.',
        });

          refetch && refetch()
        },
        onError: (err) => {
          handleGQLErrors(notify, err);
        }
    });
    const handleSubmit = (values:any) => {
        if (submitType === 'encode') {
            encodeFunction(values);
        } else if (submitType === 'decode') {
            decodeFunction(values);
        }
    };


    const encodeFunction = (values:UpdateTokenDataInput) => {
        // Your encode function logic
        let payload = {
            token_value: values.token_value,
            token_type: values.token_type,      
            secret_key: encoderState?.secret_key, 
            starting_code: encoderState?.starting_code, 
            max_count: encoderState.max_count}


            updateOpenTokenData({
                variables: {
                    updateTokenDataInput: {
                        ...payload,
                        oem_item_id: itemId,
                    }
                }
            })

        console.log('Encode Function:', payload);
    };

    const decodeFunction = (values:any) => {
        // Your decode function logic
        let payload = {
            token: values.token,   
            secret_key: decoderState?.secret_key, 
            starting_code: decoderState?.starting_code,
            max_count: decoderState?.max_count,
            used_count: decoderState?.used_count
        }
        updateOpenTokenDecoderData({
            variables: {
                updateOpenTokenDecoderInput: {
                    ...payload,
                    oem_item_id: itemId
                }
            }
        })
        console.log('Decode Function:', payload);
    };

    const tokenTypes = [
        {label: "ADD_TIME", value: OpenTokenCodeType.ADD_TIME},
        {label: "SET_TIME", value: OpenTokenCodeType.SET_TIME},
        {label: "DISABLE_PAYG", value: OpenTokenCodeType.DISABLE_PAYG},
        {label: "COUNTER_SYNC", value: OpenTokenCodeType.COUNTER_SYNC}
    ]
    useEffect(() => {
        getItem()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setEncoderState(item?.getItembyOemItemId?.openTokencodeGen || {})
        setDecoderState(item?.getItembyOemItemId.openTokenCodeDecoder || {})
    }, [item])


    const searchItem = async(search:string) => {
        const items = tokenTypes.filter(item => item.label.includes(search));
        return items as { value: string; label: string }[];
      }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <CardWrapper>
                    <Formik
                        initialValues={{

                        }}
                        onSubmit={(values) => {
                            handleSubmit(values)
                        }}
                    >
                        {(formBag) => (
                            <Form>
                                <GridContainer>
                                    <GridItem md={6} xs={12}>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <GoBackHeader />
                                                <Typography variant="h5" gutterBottom>
                                                    Code Generator
                                                </Typography>
                                            </GridItem>
                                            <GridItem md={6} >
                                                <AsyncSeachableSelect
                                                    onChange={(e: any) => {
                                                        formBag.setFieldValue(encoderFields.tokenType, e)
                                                    }}
                                                    placeholder={"Select Token Type"}
                                                    options={tokenTypes}
                                                    searchPromise={searchItem}
                                                    isDisabled={false}
                                                    initialValue={{}}
                                                />
                                            </GridItem>
                                            <GridItem md={6}>
                                                <TextInput
                                                    label="Token Value"
                                                    name= {encoderFields.tokenValue}
                                                    type="number"
                                                    disabled={false}
                                                />
                                            </GridItem>


                                            <GridItem xs={12}></GridItem>
                                            <GridItem md={6}>

                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        setSubmitType('encode');
                                                        formBag.submitForm();
    
                                                    }}
                                                    disabled={
                                                        updateOpenTokenDataOpts.loading || getItemLoading
                                                    }
                                                    style={{ marginBottom: "1rem" }}
                                                >
                                                    {updateOpenTokenDataOpts.loading? "Loading...": "Encode"}
                                                </Button>
                                            </GridItem>
                                            <GridItem xs={12}></GridItem>
                                            <GridItem xs={12}>
                                                <EncoderState
                                                    encoderState={encoderState}
                                                    oemItemId={itemId}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} md={6}>
                                        <GridItem xs={12}>
                                            <div style={{ visibility: 'hidden', marginBottom: '0.6rem' }}>
                                                Position
                                            </div>
                                            <Typography variant="h5" gutterBottom>
                                                Device
                                            </Typography>
                                        </GridItem>
                                        <GridItem md={12} >

                                            <TextInput
                                                label="Token Input"
                                                name={decoderFields.token}
                                                disabled={false}
                                            />
                                        </GridItem>

                                        <GridItem xs={12}></GridItem>
                                        <GridItem md={6}>

                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    setSubmitType('decode');
                                                    formBag.submitForm();

                                                }}
                                                disabled={
                                                    updateOpenTokenDecoderDataOpts.loading || getItemLoading
                                                }
                                                style={{ marginBottom: "1rem" }}
                                            >
                                                {updateOpenTokenDecoderDataOpts.loading? "Loading...": "Decode"}
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12}></GridItem>
                                        <GridItem xs={12}>
                                            <DecoderState
                                                decoderState={decoderState}
                                                oemItemId={itemId}
                                            />
                                        </GridItem>
                                    </GridItem>
                                </GridContainer>
                            </Form>
                        )}
                    </Formik>
                </CardWrapper>
            </GridItem>
        </GridContainer>
    );
};

export default EncoderDecoder;
