import Card from '@components/Card/Card';
import CardBody from '@components/Card/CardBody';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import OvesTable from '@components/ReactTable/OvsTable';
import TableCheckbox from 'admin/product/components/TableCheckbox';
import React, { ReactNode, useEffect } from 'react';
import { Column, Row } from 'react-table';
import { roleTableData } from 'admin/Role/constants/roleTableData';
import { RoleContext } from 'admin/Role/context/RoleContext';
import AuthenticationInstanceList from './AuthenticationInstanceList';

interface IProps { }

const RolePage: React.FC<IProps> = () => {
  const { items: roles, loading, getItems } = React.useContext(RoleContext);
  const [data, setData] = React.useState(roles || []);

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [])

  const renderRowSubComponent = React.useCallback(({ row }: { row: Row }) => {
    return (
      <div style={{ padding: 20 }}>
        <AuthenticationInstanceList row={row} />
      </div>
    );
  }, []);

  React.useEffect(() => {
    const rolesData = roles || [];
    setData(
      rolesData.map((item: any, index: number) => ({
        checkbox: <TableCheckbox id={item.node?._id} />,
        id: index + 1,
        ...item,
      }))
    ); // eslint-disable-next-line
  }, [roles]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>

          <CardBody>
            <OvesTable
              columns={
                roleTableData()
                  .header as Column[]
              }
              data={data}
              tableTitle='Roles List'
              isLoading={loading}
              renderRowSubComponent={renderRowSubComponent as ({ row }: { row?: Row<{}> | undefined; }) => ReactNode}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default RolePage;
