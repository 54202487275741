import { Grid, InputLabel } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import _ from 'lodash';
import moment from 'moment';
// @material-ui/icons
import { DatePicker } from '@material-ui/pickers';
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import {
  defaultProductType,
  getBatchNumber,
  getDefaultDate,
} from 'apps/things/app/utils';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';
import { LegacyBatchContext } from 'apps/migration/app/LegacyBatch/context/LegacyBatchContext';
import { LegacyModelContext } from 'apps/migration/app/LegacyModel/context/LegacyModelContext';
import { ProductItemInput } from '../types';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import { LegacyItemContext } from '../context/LegacyItemContext';
import AsyncSeachableSelect from '@components/CustomSelect/AsyncSeachableSelect';
import { LegacyBatchActions } from '../../LegacyBatch/redux/actions';
import useNotifier from 'hooks/useNotifier';
import OVSForm from '@components/Form/OVSForm';
import { LegacyActions } from '../../LegacyModel/redux/actions';
// @ts-ignoref noqa:
const useStyles = makeStyles(styles);

const LegacyForm: React.FC<{
  formBag: FormikProps<ProductItemInput>;
  disableLoginButton: boolean;
  actionButtonName?: string;
  isUpdate?: boolean;
  sequence?: string;
}> = (props) => {
  const {
    formBag,
    disableLoginButton,
    actionButtonName = 'Add Items',
    isUpdate,
    sequence = 'xxxxxx',
  } = props;
  const { addItem } = itemFields;
  const classes = useStyles();
  const notify = useNotifier();

  const { getItems: getBatches, items: dataItemBatchs } = useContext(
    LegacyBatchContext
  );
  const { getProtocolVersions, protocolVersions } = useContext(
    LegacyItemContext
  );
  const [_date, changeDate] = useState(new Date());

  useEffect(() => {
    if (protocolVersions.length === 0) {
      getProtocolVersions();
    } // eslint-disable-next-line
  }, []);

  // item model
  const { items: dataProductModel, getItems: getLegacyModel } = useContext(
    LegacyModelContext
  );
  const { items: batchOptions } = useContext(LegacyBatchContext);
  const { items: modelOptions } = useContext(LegacyModelContext);
  const searchPromise = async (query: string) => {
    return LegacyBatchActions.getLegacyBatch('search=' + query)
      .then((res) => {
        return (
          res?.data?.results?.map((item: any) => ({
            label: item?.productBatchNumber,
            value: item?.productBatchID,
          })) || []
        );
      })
      .catch((err) => {
        return batchOptions.map((item) => ({
          label: item?.productBatchNumber,
          value: item?.productBatchID,
        }));
      });
  };

  const modelSearchPromise = async (search: string) => {
    const _res = await LegacyActions.getLegacyBatch(`search=${search}`).then(
      (res) => {
        return res;
      }
    );
    return _res?.data?.map((it: any) => ({
      value: it.productModelID,
      label: it.productModelName,
    }));
  };

  // load data options
  useEffect(() => {
    if (dataItemBatchs.length === 0) {
      getBatches();
    }
    if (dataProductModel.length === 0) {
      getLegacyModel();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    _.find(dataItemBatchs, function (o) {
      return o?.productBatchID === formBag.values.productBatchID;
    });
    // eslint-disable-next-line
  }, [formBag.values.productBatchID]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState(getDefaultDate());
  const [productType, setProductType] = useState(defaultProductType);

  const setBatchNumber = (batchNumber: string) => {
    formBag.setFieldValue(itemFields.addItem.productItemOEM_SN, batchNumber);
  };

  const firmwareSearchPromise = async (search: string) => {
    const res = protocolVersions
      ?.filter((it) => {
        return it?.name?.toLocaleLowerCase().includes(search);
      })
      .map((it) => {
        return {
          value: it._id,
          label: it.name,
        };
      });
    return res;
  };

  const { values } = formBag;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer>
                <GridHalfWrapper>
                  <AsyncSeachableSelect
                    onChange={(e: any) => {
                      formBag.setFieldValue(addItem.productBatchID, e);
                      const no = _.find(batchOptions, (o) => {
                        return o.productBatchID === e;
                      });
                      formBag.setFieldValue(
                        addItem.itemBatchNumber,
                        no?.productBatchNumber
                      );
                    }}
                    placeholder='Select product batch'
                    options={batchOptions.map((item) => ({
                      label: item?.productBatchNumber,
                      value: item?.productBatchID,
                    }))}
                    searchPromise={searchPromise}
                    initialValue={
                      formBag.values.productBatchID
                        ? {
                            value: formBag.values.productBatchID,
                            label: formBag.values.itemBatchNumber,
                          }
                        : {}
                    }
                    error={formBag.errors.productBatchID}
                  />
                </GridHalfWrapper>

                {!isUpdate && (
                  <GridHalfWrapper>
                    <TextInput
                      name='totalItems'
                      type='number'
                      label='Total Product Items'
                      value={
                        (values.totalItems && values.totalItems.toString()) ||
                        ''
                      }
                    />
                  </GridHalfWrapper>
                )}
                <OVSForm
                  formFieldsData={[
                    {
                      name: '',
                      fields: [
                        {
                          type: 'select-async',
                          name: addItem.productModelID,
                          label: 'Product Model',
                          options: modelOptions.map((it) => ({
                            _id: it.productModelID,
                            name: it.productModelName,
                          })),
                          onChange: (e: any) => {
                            formBag.setFieldValue(addItem.productModelID, e);
                          },
                          value: formBag.values.productModelID || '',
                          searchPromise: modelSearchPromise,
                        },
                      ],
                    },
                  ]}
                />

                <GridHalfWrapper>
                  <TextInput
                    label='productItemOEM_SN'
                    name='productItemOEM_SN'
                    value={
                      formBag.values.productItemOEM_SN ||
                      getBatchNumber({ productType, date, sequence })
                    }
                    disabled
                    onClick={() => setIsModalOpen(true)}
                  />
                </GridHalfWrapper>
                <GridHalfWrapper>
                  <TextInput
                    label='lifeCycleStatus'
                    name='lifeCycleStatus'
                    value={formBag.values.lifeCycleStatus}
                    disabled
                  />
                </GridHalfWrapper>
                <GridHalfWrapper>
                  <TextInput
                    label='productItemPAYG_SN'
                    name='productItemPAYG_SN'
                    value={formBag.values.productItemPAYG_SN}
                    disabled
                  />
                </GridHalfWrapper>
                <OVSForm
                  formFieldsData={[
                    {
                      name: '',
                      fields: [
                        {
                          type: 'select-async',
                          name: addItem.firmwareVersion,
                          label: 'firmware Version',
                          options: protocolVersions.map((it) => ({
                            _id: it._id,
                            name: it.name,
                          })),
                          onChange: (e: any) => {
                            formBag.setFieldValue(addItem.firmwareVersion, e);
                          },
                          value: formBag.values.firmwareVersion || '',
                          searchPromise: firmwareSearchPromise,
                        },
                      ],
                    },
                  ]}
                />

                <GridHalfWrapper>
                  <TextInput
                    label='timerScheme'
                    name='timerScheme'
                    value={formBag.values.timerScheme}
                  />
                </GridHalfWrapper>
              </GridContainer>
            </GridItem>
          </GridContainer>

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
                formBag.validateForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
        <DrawerSidebar
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          title='Generate oemItemID'
          styles={{ maxWidth: '370px' }}
        >
          <GridContainer>
            <GridItem sm={12}>
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
              >
                <br />
                <br />
                {getBatchNumber({ productType, date, sequence })}
              </InputLabel>
            </GridItem>
            <GridItem sm={12}>
              <TextInput
                name='undefined'
                label='Product Base'
                value={productType || ''}
                onChange={(e: any) => {
                  formBag.setFieldValue(addItem.productType, e.target.value);
                  if (e.target.value?.length > 4) {
                    return notify({
                      text: 'Product Base should be less than four.',
                      status: 'error',
                    });
                  }
                  setProductType(e.target.value);
                  setBatchNumber(
                    getBatchNumber({
                      productType: e.target.value,
                      date,
                      sequence,
                    })
                  );
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12}>
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
                id='label-left'
              >
                Date
              </InputLabel>
              <DatePicker
                autoOk
                variant='static'
                format='yymm'
                value={_date}
                // @ts-ignore
                onChange={(date) => {
                  const newDate = moment(date || '')?.format('YYMM');
                  setDate(newDate);
                  changeDate(date || new Date());
                  setBatchNumber(
                    getBatchNumber({
                      productType,
                      date: newDate,
                      sequence,
                    })
                  );
                }}
              />
            </GridItem>
          </GridContainer>
        </DrawerSidebar>
      </GridItem>
    </GridContainer>
  );
};

export default LegacyForm;
