import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { IProps } from '@components/messages/types';
import useNotifier from 'hooks/useNotifier';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { ITableActions } from 'apps/things/app/item/context/ItemProvider';
import { GlobalSimulatorContext } from 'apps/things/app/provider/SimulatorAppProvider';
import clientSimulator from 'utils/clientSimulator';
import { deleteItemFirmwareMutation } from '../mutations';
import { useLazyGetAllItemFirmwaresQuery } from '../queries';
import {
  DeleteItemFirmware,
  DeleteItemFirmwareVariables,
} from '../types/DeleteItemFirmware';
import {
  GetAllItemFirmwares,
  GetAllItemFirmwaresVariables,
  GetAllItemFirmwares_getAllItemFirmwares_page_edges,
} from '../types/GetAllItemFirmwares';
import useOVSPagination from 'hooks/useOVSPagination';
import { useDebouncedEffect } from 'utils/useDebouncedEffect';
import { funNumberAgr } from '@types';
import { PAGE_LIST_LIMIT } from '@constants';

export interface IFirmwareContext {
  itemFirmwares: GetAllItemFirmwares_getAllItemFirmwares_page_edges[];
  setItemFirmwares: (
    itemSKUs: GetAllItemFirmwares_getAllItemFirmwares_page_edges[]
  ) => void;
  loading: boolean;
  tableActions: ITableActions[];
  refetchItemFirmwares:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<ApolloQueryResult<GetAllItemFirmwares>>)
    | undefined;
  getItemFirmwares: () => void;

  goTo: (nextPrev: boolean) => void;
  paging: any;

  setSearchQuery: (query: string) => void;
  searchQuery: string | undefined;
  setPageLimit: funNumberAgr
}

export const ItemFirmwareContext = React.createContext<IFirmwareContext>(
  {} as IFirmwareContext
);

const ItemFirmwareProvider: React.FC<IProps> = ({ children }) => {
  const [itemFirmwares, setItemFirmwares] = React.useState<
    GetAllItemFirmwares_getAllItemFirmwares_page_edges[]
  >([] as GetAllItemFirmwares_getAllItemFirmwares_page_edges[]);
  const [loading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const {
    endCursorStack,
    initPagAction,
    setPaging,
    paging, pageListLimit, setPageListLimit
  } = useOVSPagination();

  // hooks
  // context
  const { setTableActionLoading } = useContext(GlobalSimulatorContext);

  const notify = useNotifier();
  const [
    getItemFirmwares,
    { refetch: refetchItemFirmwares, fetchMore, data, loading: dataLoading },
  ] = useLazyGetAllItemFirmwaresQuery({
    first: pageListLimit,
  });

  useEffect(() => {
    if (data && !dataLoading) {
      setItemFirmwares(data?.getAllItemFirmwares?.page?.edges || itemFirmwares);
      setPaging({
        ...data?.getAllItemFirmwares?.pageData,
        ...data?.getAllItemFirmwares?.page?.pageInfo,
      });
    } // eslint-disable-next-line
  }, [data]);
  const setPageLimit = (limit: number) => {
    setPageListLimit(limit)
    setTimeout(() => {
      refetchItemFirmwares && refetchItemFirmwares()
    }, 100);

  }
  const goTo = async (next = true) => {
    if (isLoadingMore) return;

    let variables: GetAllItemFirmwaresVariables = {
      first: pageListLimit,
      ...initPagAction(next),
    };

    if (fetchMore) {
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllItemFirmwares } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItemFirmwares(_data?.data?.getAllItemFirmwares?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemFirmwares?.pageData,
        ..._data?.data?.getAllItemFirmwares?.page?.pageInfo,
      });
    }
  };

  const search = async () => {
    if (searchQuery === undefined) {
      return;
    }
    if (fetchMore) {
      const variables: GetAllItemFirmwaresVariables = {
        first: pageListLimit,
      };
      if (searchQuery) {
        variables['search'] = searchQuery;
      }
      setIsLoadingMore(true);
      const _data: { data: GetAllItemFirmwares } = await fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoadingMore(false);
          if (!fetchMoreResult) {
            return previousResult;
          }
          return {
            ...fetchMoreResult,
          };
        },
      });

      setItemFirmwares(_data?.data?.getAllItemFirmwares?.page?.edges || []);

      setPaging({
        ..._data?.data?.getAllItemFirmwares?.pageData,
        ..._data?.data?.getAllItemFirmwares?.page?.pageInfo,
        hasPreviousPage: endCursorStack.length > 0,
      });
    }
  };

  useDebouncedEffect(search, [searchQuery], 1000);

  const [deleteItem] = useMutation<
    DeleteItemFirmware,
    DeleteItemFirmwareVariables
  >(deleteItemFirmwareMutation, {
    client: clientSimulator,
    onCompleted: (data) => {},
  });
  // functions
  const multiDeleteItems = (itemsToDelete: Row[]) => {
    setTableActionLoading(true);
    Promise.all(
      itemsToDelete.map((item) =>
        deleteItem({
          variables: {
            itemFirmwareId: item.values['node._id'],
          },
        })
      )
    )
      .then(async (res) => {
        notify({
          status: 'success',
          text: 'Items deleted successfully',
        });
        if (refetchItemFirmwares) {
          const itemsFetched = await refetchItemFirmwares({
            first: PAGE_LIST_LIMIT,
          });

          setItemFirmwares(
            itemsFetched?.data?.getAllItemFirmwares?.page?.edges ||
              itemFirmwares
          );
          setTableActionLoading(false);
        } else {
          await getItemFirmwares();
          setTableActionLoading(false);
        }
      })
      .catch(async (err) => {
        if (refetchItemFirmwares) {
          const itemsFetched = await refetchItemFirmwares({
            first: pageListLimit,
          });
          setItemFirmwares(
            itemsFetched?.data?.getAllItemFirmwares?.page?.edges ||
              itemFirmwares
          );
          setTableActionLoading(false);
        } else {
          await getItemFirmwares();
          setTableActionLoading(false);
        }
      });
  };

  const value = React.useMemo(
    () => ({
      itemFirmwares,
      setItemFirmwares,
      loading: loading || dataLoading,
      refetchItemFirmwares,
      getItemFirmwares,
      goTo,
      paging,
      setSearchQuery,
      searchQuery, setPageLimit,
      tableActions: [
        {
          action: multiDeleteItems,
          title: 'Delete',
          description: (arg: string) =>
            `Are you sure you want to delete ${arg} item(s)?`,
          showConfirmationModal: true,
        },
      ],
    }), // eslint-disable-next-line
    [itemFirmwares, paging, searchQuery]
  );

  return (
    <ItemFirmwareContext.Provider value={value}>
      {children}
    </ItemFirmwareContext.Provider>
  );
};

export default ItemFirmwareProvider;
