import React from 'react';
import DefaultOutlet from 'routes/DefaultOutlet';
import { peopleViewRoutes as itemRoutes } from './constants';
import CodeEventContainer from './containers/CodeEventContainer';

interface IProps { }
export const routes = {
  path: itemRoutes.people,
  element: <DefaultOutlet />,
  children: [
    { index: true, element: <CodeEventContainer /> },

  ],
}
const PeopleViewIndex: React.FC<IProps> = () => {
  return (
    <DefaultOutlet />

  );
};

export default PeopleViewIndex;
