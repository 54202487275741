import { Typography } from '@material-ui/core';
import React from 'react';

interface IProps { message: string }

const EmptyState: React.FC<IProps> = ({ message='No data.'}) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'darkgray' }}>
			<Typography variant="body1" gutterBottom>
				{message}
			</Typography>
		</div>
	)
}

export default EmptyState

