import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useContext, useEffect, useState } from 'react';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import { itemFields } from '../constants';
import { RoleContext } from 'admin/Role/context/RoleContext';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import { AdminRegisterDistributorInput } from 'apps/clientService/app/types/clientServiceGlobalTypes';
import { USER_TYPES } from 'admin/auth/constants/auth';

const ItemForm: React.FC<{
  formBag: FormikProps<AdminRegisterDistributorInput>;
  disableLoginButton: boolean;
  actionButtonName?: string;
}> = (props) => {
  const { formBag, disableLoginButton, actionButtonName = 'Add' } = props;

  const { items, getItems, loading } = useContext(RoleContext);

  useEffect(() => {
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);

  const [roleOptions, setRoleOptions] = useState<
    { _id: string; name: string }[]
  >([]);

  useEffect(() => {
    setRoleOptions(
      items.filter((item) => item?.node?.name === USER_TYPES.DISTRIBUTOR).map((item) => ({
        _id: item?.node?._id || '',
        name: item?.node?.name || '',
      })) || []
    ); // eslint-disable-next-line
  }, [items]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridHalfWrapper>
              <TextInput
                label='Email'
                name={itemFields.email}
                type='email'
                required
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <CustomSelect
                selectOptions={roleOptions}
                loading={loading}
                onChange={(e: any) => {
                  formBag.setFieldValue(itemFields.roleId, e.target.value);
                }}
                label={'Select Role'}
                value={formBag.values.roleId || ''}
                name={itemFields.roleId}
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='contactRole' name={itemFields.contactRole} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='city' name={itemFields.city} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Country' name={itemFields.country} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='Postcode' name={itemFields.postcode} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='srpc' name={itemFields.srpc} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='street' name={itemFields.street} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput label='unit' name={itemFields.unit} />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='inputAddressLongitude'
                name={itemFields.inputAddressLongitude}
                type='number'
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='inputAddressLatitude'
                name={itemFields.inputAddressLatitude}
                type='number'
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Description'
                name={itemFields.distributorDescription}
                multiline
              />
            </GridHalfWrapper>
          </GridContainer>

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default ItemForm;
