export const messageTemplateRoutes = {
  list: '/admin/message-template',
  edit: '/admin/message-template/:itemId',
  getEditLink: (itemId: string) => `/admin/message-template/${itemId}`
};


export const messageTemplateFields = {
  name: "name",
  description: "description",
  messageBody: "messageBody",
  distributor: "distributor",
  intent: "intent",
  messageCourier: "messageCourier"
}


