import React, { useEffect, useState } from 'react';

// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import CardWrapper from '@components/Card/CardWrapper';
import { itemRoutes } from '../constants';
import { PAGE_LIST_LIMIT } from '@constants';
import { useLazyGetItemFleetsForClientQuery } from 'apps/things/app/fleet/queries';
import { itemTableData } from 'apps/things/app/fleet/constants/itemTableData';
import { GetItemFleetsForClient_getItemFleetsForClient_page_edges } from 'apps/things/app/fleet/types/GetItemFleetsForClient';

interface IRTable { clientId: string }

const FleetsPage: React.FC<IRTable> = ({ clientId }) => {
	const [data, setData] = useState<GetItemFleetsForClient_getItemFleetsForClient_page_edges[]>([]);
	const [
		getDistributorItems,
		{
			data: distributorData,
			loading
		},
	] = useLazyGetItemFleetsForClientQuery({
		first: PAGE_LIST_LIMIT,
		clientId
	});

	useEffect(() => {
		getDistributorItems() // eslint-disable-next-line
	}, []);

	useEffect(() => {
		setData(distributorData?.getItemFleetsForClient?.page?.edges || [])
	}, [distributorData])

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<CardWrapper>
					<OvsTable
						columns={
							itemTableData().header as Column[]
						}
						data={data}
						checkedIds={[]}
						isLoading={loading}
						addItemPageLink={itemRoutes.add}
						addItemBtnText='Add'
						hideRightToolbar={true}
					/>
				</CardWrapper>
			</GridItem>

		</GridContainer>
	);
};

export default FleetsPage;
