import useNotifier from 'hooks/useNotifier';
import { useEffect } from 'react';
import { handleGQLErrors } from 'utils/gqlErrors';
import {
  useLazyDownloadClientCreds,
  useLazyDownloadDistributorCodeGens,
  useLazyDownloadDistributorDetails,
  useLazyDownloadDistributorFleet,
  useLazyDownloadDistributorItems,
} from '../queries';

export const useDownloadCredentials = () => {
  const [query, { data, loading, error }] = useLazyDownloadClientCreds({
    roleId: '',
  });

  const notify = useNotifier();

  useEffect(() => {
    if (error) {
      handleGQLErrors(notify, error);
    }  // eslint-disable-next-line
  }, [error]);

  return {
    query,
    data,
    loading,
  };
};

export const useDownloadDistributorCodeGens = () => {
  const [query, { data, loading, error }] = useLazyDownloadDistributorCodeGens({
    fleetId: '',
  });

  const notify = useNotifier();

  useEffect(() => {
    if (error) {
      handleGQLErrors(notify, error);
    } // eslint-disable-next-line
  }, [error]);

  return {
    query,
    data,
    loading,
  };
};

export const useDownloadDistributorDetails = () => {
  const [query, { data, loading, error }] = useLazyDownloadDistributorDetails({
    clientId: '',
  });
  const notify = useNotifier();

  useEffect(() => {
    if (error) {
      handleGQLErrors(notify, error);
    } // eslint-disable-next-line
  }, [error]);
  return {
    query,
    data,
    loading,
  };
};

export const useDownloadDistributorFleet = () => {
  const [query, { data, loading, error }] = useLazyDownloadDistributorFleet({
    clientId: '',
  });
  const notify = useNotifier();

  useEffect(() => {
    if (error) {
      handleGQLErrors(notify, error);
    } // eslint-disable-next-line
  }, [error]);

  return {
    query,
    data,
    loading,
  };
};

export const useDownloadDistributorItems = () => {
  const [query, { data, loading, error }] = useLazyDownloadDistributorItems({
    fleetId: '',
  });
  const notify = useNotifier();

  useEffect(() => {
    if (error) {
      handleGQLErrors(notify, error);
    } // eslint-disable-next-line
  }, [error]);
  return {
    query,
    data,
    loading,
  };
};
