import DescriptionCell from '@components/ReactTable/DescriptionCell';
import ImageCell from 'apps/ecommerce/app/products/components/TableCells/ImageCell';
import React from 'react';
import { Row } from 'react-table';
import Actions from '../components/LegacyModelTableActions';

export const legacyModelTableData = {
  header: [
    {
      Header: 'Actions',
      id: 'expander', // It needs an ID
      // sticky: 'left',
      Cell: ({ row }: { row: Row }) => (
        <Actions
          {...row.getToggleRowExpandedProps()}
          row={row}
        />
      ),
    },
    { Header: 'productModelID', accessor: 'productModelID' },
    {Header: 'productModelName', accessor: 'productModelName'},
    {Header: 'quantityInStock', accessor: 'quantityInStock'},
    {Header: 'productModelCost', accessor: 'productModelCost'},
    { Header: 'productFamilyID', accessor: 'productFamilyID' },
   
    {
      Header: 'productFamilyName',
      accessor: 'productFamilyName',
    },
    {
      Header: 'productModelDescription',
      accessor: 'productModelDescription',
      Cell: DescriptionCell,
    },
    {
      Header: 'Image',
      accessor: 'image',
      Cell: ImageCell
    },
    {
      Header: 'productVendor',
      accessor: 'productVendor'
    }
  ],
};
