import React from 'react';
import DashboardPage from '../components/DashboardPage';

const Dashboard = (props: any) => {
  return (
      <DashboardPage />
  );
};

export default Dashboard;
