import { PAGE_LIST_LIMIT } from "@constants";
import { useContext, useMemo } from "react";
import { FleetContext } from "../context/FleetProvider";
import { useLazyGetTotalItemsInFleetQuery } from "../queries";
import * as _ from "lodash";

export const useGetTotalItemsInFleet = ({
  clientId,
  itemFleetId,
}: {
  clientId: string;
  itemFleetId: string;
}) => {
  const [getItems, { data, loading }] = useLazyGetTotalItemsInFleetQuery({
    clientId,
    itemFleetId,
  });

  return useMemo(
    () => ({
      getItems,
      total: _.get(data, "getAllClientItemsInItemFleet.pageData.count"),
      loading,
    }), // eslint-disable-next-line
    [data, loading]
  );
};

const useFleet = () => {
  const { items: data, loading } = useContext(FleetContext);

  return useMemo(
    () => ({
      options:
        data?.map((item) => ({
          _id: item?.node?._id || "",
          name: item?.node?.fleetName || "",
        })) || [],
      loading,
    }), // eslint-disable-next-line
    [data]
  );
};

export const useLazyFleet = () => {
  const {
    items: data,
    getItems,
    loading,
    refetchItems: refetchFleets,
  } = useContext(FleetContext);

  const searchFleet = async (search: string) => {
    if (!refetchFleets) {
      return Promise.resolve(
        data?.map((it) => ({ name: it?.node?.fleetName, value: it?.node?._id }))
      );
    }
    const refetchData = await refetchFleets({
      first: PAGE_LIST_LIMIT,
      search,
    });

    const items = refetchData?.data?.getAllItemFleets?.page?.edges?.map(
      (item) => {
        return {
          label: item?.node?.fleetName || "",
          value: item.node?._id || "",
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  };

  return useMemo(
    () => ({
      options:
        data?.map((item) => ({
          _id: item?.node?._id || "",
          name: item?.node?.fleetName || "",
        })) || [],
      loading,
      getItems,
      searchFleet,
      data,
    }), // eslint-disable-next-line
    [data, loading]
  );
};
export default useFleet;
