import React, { useContext, useEffect } from "react";

// @material-ui/core components

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import OvsTable from "@components/ReactTable/OvsTable";
import { Column } from "react-table";

import CardWrapper from "@components/Card/CardWrapper";
import { LegacyBatchContext } from "../context/LegacyBatchContext";
import { legacyBatchTableData } from "../constants/data";
import { legacyBatchRoutes } from "../constants";

interface IRTable {
  bulkDeleteProducts?: () => void;
  isLoading: boolean;
}

const LegacyBatchPage: React.FC<IRTable> = () => {
  const {
    items,
    getItems,
    loading,
    setSearchQuery,
    goTo,
    paging,
    setOffset,
    tableActions,
    searchQuery,
  } = useContext(LegacyBatchContext);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardWrapper>
          <OvsTable
            columns={legacyBatchTableData.header as Column[]}
            data={items}
            checkedIds={[]}
            isLoading={loading}
            addItemPageLink={legacyBatchRoutes.add}
            addItemBtnText="Add"
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            goTo={goTo}
            paging={paging}
            setOffset={setOffset}
            tableActions={tableActions}
          />
        </CardWrapper>
      </GridItem>
    </GridContainer>
  );
};
export default LegacyBatchPage;
