/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AgentTypes {
  COLLECTION = "COLLECTION",
  SALES = "SALES",
  SERVICE = "SERVICE",
}

export enum ClientExclusiveServiceTypes {
  PAYGO = "PAYGO",
  PAYMENTS = "PAYMENTS",
}

export enum OrgTypes {
  DISTRIBUTOR = "DISTRIBUTOR",
  FINANCIAL = "FINANCIAL",
  GOVERNMENT = "GOVERNMENT",
  INSTITUTION = "INSTITUTION",
  INVESTOR = "INVESTOR",
  SERVICE = "SERVICE",
  SERVICER = "SERVICER",
  SUPPLIER = "SUPPLIER",
}

export enum PermissionInput {
  ACCOUNTS_SERVICE_CUSTOMERS = "ACCOUNTS_SERVICE_CUSTOMERS",
  ACTIVATE_ASSETACCOUNT = "ACTIVATE_ASSETACCOUNT",
  ACTIVATE_AVATAR = "ACTIVATE_AVATAR",
  ACTIVATE_CREDIT_ACCOUNT = "ACTIVATE_CREDIT_ACCOUNT",
  ADMIN_ASSIGN_PERMISSION_TO_SUBROLE = "ADMIN_ASSIGN_PERMISSION_TO_SUBROLE",
  ADMIN_GET_ALL_AGENTS_FOR_DISTRIBUTOR = "ADMIN_GET_ALL_AGENTS_FOR_DISTRIBUTOR",
  ADMIN_REGISTER_DISTRIBUTOR = "ADMIN_REGISTER_DISTRIBUTOR",
  ADMIN_REGISTER_SERVICER = "ADMIN_REGISTER_SERVICER",
  ARCHIVE_SIGNAL_CLASS_BY_TIMESTAMP = "ARCHIVE_SIGNAL_CLASS_BY_TIMESTAMP",
  ASSIGIN_PUBLISHER_TO_SIGNAL = "ASSIGIN_PUBLISHER_TO_SIGNAL",
  ASSIGN_ASSET_ACCOUNT_TO_AGENT_BY_DISTRIBUTOR_STAFF = "ASSIGN_ASSET_ACCOUNT_TO_AGENT_BY_DISTRIBUTOR_STAFF",
  ASSIGN_ASSET_ACCOUNT_TO_DISTRIBUTOR_STAFF_BY_DISTRIBUTOR = "ASSIGN_ASSET_ACCOUNT_TO_DISTRIBUTOR_STAFF_BY_DISTRIBUTOR",
  ASSIGN_BULK_ITEMS_TO_ITEMFLEET = "ASSIGN_BULK_ITEMS_TO_ITEMFLEET",
  ASSIGN_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS = "ASSIGN_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS",
  ASSIGN_CODEGENERATOR_TO_ITEM = "ASSIGN_CODEGENERATOR_TO_ITEM",
  ASSIGN_CREDIT_ACCOUNT_TO_CUSTOMER = "ASSIGN_CREDIT_ACCOUNT_TO_CUSTOMER",
  ASSIGN_CUSTOMER_TO_AGENT = "ASSIGN_CUSTOMER_TO_AGENT",
  ASSIGN_DEFAULT_LOGINUSER_PERMISSIONS_TO_SUBROLE = "ASSIGN_DEFAULT_LOGINUSER_PERMISSIONS_TO_SUBROLE",
  ASSIGN_ITEMBATCH_TO_ITEMFLEET = "ASSIGN_ITEMBATCH_TO_ITEMFLEET",
  ASSIGN_ITEMFLEET_TO_DISTRIBUTOR = "ASSIGN_ITEMFLEET_TO_DISTRIBUTOR",
  ASSIGN_ITEM_TO_AGENT = "ASSIGN_ITEM_TO_AGENT",
  ASSIGN_ITEM_TO_ITEMFLEET = "ASSIGN_ITEM_TO_ITEMFLEET",
  ASSIGN_PAYMENT_PLAN_TO_ASSETACCOUNT = "ASSIGN_PAYMENT_PLAN_TO_ASSETACCOUNT",
  ASSIGN_PERMISSIONS_TO_AGENT = "ASSIGN_PERMISSIONS_TO_AGENT",
  ASSIGN_PERMISSION_TO_ROLE = "ASSIGN_PERMISSION_TO_ROLE",
  ASSIGN_ROLE_PERMISSIONS_TO_SUBROLE = "ASSIGN_ROLE_PERMISSIONS_TO_SUBROLE",
  ASSIGN_ROLE_TO_USER = "ASSIGN_ROLE_TO_USER",
  ASSIGN_SUBROLE_TO_ROLE = "ASSIGN_SUBROLE_TO_ROLE",
  ASSIGN_TO_SERVICER_DISTRIBUTOR_CLIENT_EXCLUSIVE_SERVICE_TYPE = "ASSIGN_TO_SERVICER_DISTRIBUTOR_CLIENT_EXCLUSIVE_SERVICE_TYPE",
  BATCHCODE = "BATCHCODE",
  BATCH_FACTORY_EXPORT = "BATCH_FACTORY_EXPORT",
  BATCH_FACTORY_PARTIAL_EXPORT = "BATCH_FACTORY_PARTIAL_EXPORT",
  BATCH_INITIALIZE = "BATCH_INITIALIZE",
  CHANGE_AGENT_SUB_ROLE = "CHANGE_AGENT_SUB_ROLE",
  CHANGE_LOGIN_USER_AUTHENTICATION_INSTANCE = "CHANGE_LOGIN_USER_AUTHENTICATION_INSTANCE",
  CHANGE_ROLE_LOGIN_USER_ASSIGN_PERMISSION_STATUS = "CHANGE_ROLE_LOGIN_USER_ASSIGN_PERMISSION_STATUS",
  CHANGE_SUBROLE_LOGIN_USER_SUBROLE = "CHANGE_SUBROLE_LOGIN_USER_SUBROLE",
  CHECK_DISTRIBUTOR_EMAIL_FOR_RESET_PASSWORD = "CHECK_DISTRIBUTOR_EMAIL_FOR_RESET_PASSWORD",
  CHECK_OVES_PERSONEL_EMAIL_FOR_RESET_PASSWORD = "CHECK_OVES_PERSONEL_EMAIL_FOR_RESET_PASSWORD",
  CLIENT_REGISTER_CUSTOMER = "CLIENT_REGISTER_CUSTOMER",
  CLOSE_ASSETACCOUNT = "CLOSE_ASSETACCOUNT",
  COPY_LEGACY_CODEEVENT_PARAMETERS = "COPY_LEGACY_CODEEVENT_PARAMETERS",
  COPY_LEGACY_CODEEVENT_PARAMETERS_IN_BULK = "COPY_LEGACY_CODEEVENT_PARAMETERS_IN_BULK",
  COPY_LEGACY_CODEGEN_PARAMETERS = "COPY_LEGACY_CODEGEN_PARAMETERS",
  COPY_LEGACY_ITEMS_AND_PROVISION_ITEMS = "COPY_LEGACY_ITEMS_AND_PROVISION_ITEMS",
  CREATE_ADDRESS = "CREATE_ADDRESS",
  CREATE_AMAZON_PRODUCT_CATEGORY = "CREATE_AMAZON_PRODUCT_CATEGORY",
  CREATE_APPLICATION_SITE = "CREATE_APPLICATION_SITE",
  CREATE_ARTICLE = "CREATE_ARTICLE",
  CREATE_ASSET_ACCOUNT = "CREATE_ASSET_ACCOUNT",
  CREATE_ASSET_ACCOUNTS = "CREATE_ASSET_ACCOUNTS",
  CREATE_AUTHENTICATION_INSTANCE = "CREATE_AUTHENTICATION_INSTANCE",
  CREATE_AUTHENTICATION_SUBINSTANCE = "CREATE_AUTHENTICATION_SUBINSTANCE",
  CREATE_AVATAR = "CREATE_AVATAR",
  CREATE_BRAND = "CREATE_BRAND",
  CREATE_BRAND_SELECTOR = "CREATE_BRAND_SELECTOR",
  CREATE_BROKER = "CREATE_BROKER",
  CREATE_CATEGORY = "CREATE_CATEGORY",
  CREATE_CATEGORY_SELECTOR = "CREATE_CATEGORY_SELECTOR",
  CREATE_DISPATCHER = "CREATE_DISPATCHER",
  CREATE_ECOMMERCE_PRODUCT = "CREATE_ECOMMERCE_PRODUCT",
  CREATE_EVENT = "CREATE_EVENT",
  CREATE_ITEM = "CREATE_ITEM",
  CREATE_ITEMBATCH = "CREATE_ITEMBATCH",
  CREATE_ITEMFIRMWARE = "CREATE_ITEMFIRMWARE",
  CREATE_ITEMFLEET = "CREATE_ITEMFLEET",
  CREATE_ITEMSKU = "CREATE_ITEMSKU",
  CREATE_ITEMSKUS_IN_BULK = "CREATE_ITEMSKUS_IN_BULK",
  CREATE_ITEMSTOCK = "CREATE_ITEMSTOCK",
  CREATE_ITEMS_IN_BULK = "CREATE_ITEMS_IN_BULK",
  CREATE_ITEM_EVENT = "CREATE_ITEM_EVENT",
  CREATE_LOGIN_USER = "CREATE_LOGIN_USER",
  CREATE_MATERIAL = "CREATE_MATERIAL",
  CREATE_MEDIA_META = "CREATE_MEDIA_META",
  CREATE_MESSAGE = "CREATE_MESSAGE",
  CREATE_MESSAGE_GROUP = "CREATE_MESSAGE_GROUP",
  CREATE_MESSAGE_GROUP_BY_ASSET_ACCOUNT_SEARCH_QUERY = "CREATE_MESSAGE_GROUP_BY_ASSET_ACCOUNT_SEARCH_QUERY",
  CREATE_MESSAGE_GROUP_BY_CUSTOMER_SEARCH_QUERY = "CREATE_MESSAGE_GROUP_BY_CUSTOMER_SEARCH_QUERY",
  CREATE_MESSAGE_TEMPLATE = "CREATE_MESSAGE_TEMPLATE",
  CREATE_MULTIPLE_SUBINSTANCES_UNDER_INSTANCE = "CREATE_MULTIPLE_SUBINSTANCES_UNDER_INSTANCE",
  CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
  CREATE_PACK = "CREATE_PACK",
  CREATE_PART = "CREATE_PART",
  CREATE_PAYPLAN = "CREATE_PAYPLAN",
  CREATE_PERMISSION = "CREATE_PERMISSION",
  CREATE_PLACE = "CREATE_PLACE",
  CREATE_PRODUCT = "CREATE_PRODUCT",
  CREATE_PRODUCT_SELECTOR = "CREATE_PRODUCT_SELECTOR",
  CREATE_ROLE = "CREATE_ROLE",
  CREATE_ROUTE = "CREATE_ROUTE",
  CREATE_SERVICER = "CREATE_SERVICER",
  CREATE_SHIPMENT = "CREATE_SHIPMENT",
  CREATE_SIGNAL = "CREATE_SIGNAL",
  CREATE_SINGLE_ITEM = "CREATE_SINGLE_ITEM",
  CREATE_SUBROLE = "CREATE_SUBROLE",
  CREATE_SUBSCRIBER = "CREATE_SUBSCRIBER",
  CREATE_SUPPLIER = "CREATE_SUPPLIER",
  CREATE_THING_BROKER = "CREATE_THING_BROKER",
  CREATE_VARIATION = "CREATE_VARIATION",
  CREDIT_CREDIT_ACCOUNT = "CREDIT_CREDIT_ACCOUNT",
  DEACTIVATE_AVATAR = "DEACTIVATE_AVATAR",
  DEBIT_CREDIT_ACCOUNT = "DEBIT_CREDIT_ACCOUNT",
  DEFAULT_PERMISSIONS = "DEFAULT_PERMISSIONS",
  DELETE_ADDRESS = "DELETE_ADDRESS",
  DELETE_AGENT = "DELETE_AGENT",
  DELETE_AMAZON_PRODUCT_CATEGORY = "DELETE_AMAZON_PRODUCT_CATEGORY",
  DELETE_APPLICATION_SITE = "DELETE_APPLICATION_SITE",
  DELETE_ARTICLE = "DELETE_ARTICLE",
  DELETE_AUTHENTICATION_INSTANCE = "DELETE_AUTHENTICATION_INSTANCE",
  DELETE_AUTHENTICATION_SUBINSTANCE = "DELETE_AUTHENTICATION_SUBINSTANCE",
  DELETE_AVATAR = "DELETE_AVATAR",
  DELETE_BRAND = "DELETE_BRAND",
  DELETE_BRAND_SELECTOR = "DELETE_BRAND_SELECTOR",
  DELETE_BROKER = "DELETE_BROKER",
  DELETE_BUYER = "DELETE_BUYER",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  DELETE_CATEGORY_SELECTOR = "DELETE_CATEGORY_SELECTOR",
  DELETE_CLIENT_EXCLUSIVE_SERVICE = "DELETE_CLIENT_EXCLUSIVE_SERVICE",
  DELETE_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS = "DELETE_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS",
  DELETE_CODEEVENT = "DELETE_CODEEVENT",
  DELETE_CODEGENERATOR = "DELETE_CODEGENERATOR",
  DELETE_DISPATCHER = "DELETE_DISPATCHER",
  DELETE_DISTRIBUTOR = "DELETE_DISTRIBUTOR",
  DELETE_DISTRIBUTOR_STAFF = "DELETE_DISTRIBUTOR_STAFF",
  DELETE_ECOMMERCE_PRODUCT = "DELETE_ECOMMERCE_PRODUCT",
  DELETE_EVENT = "DELETE_EVENT",
  DELETE_GATT_PROPERTIES_FROM_ITEMSKU = "DELETE_GATT_PROPERTIES_FROM_ITEMSKU",
  DELETE_ITEM = "DELETE_ITEM",
  DELETE_ITEMBATCH = "DELETE_ITEMBATCH",
  DELETE_ITEMFIRMWARE = "DELETE_ITEMFIRMWARE",
  DELETE_ITEMFLEET = "DELETE_ITEMFLEET",
  DELETE_ITEMSKU = "DELETE_ITEMSKU",
  DELETE_ITEMSTOCK = "DELETE_ITEMSTOCK",
  DELETE_LOGIN_USER = "DELETE_LOGIN_USER",
  DELETE_MATERIAL = "DELETE_MATERIAL",
  DELETE_MEDIA_META = "DELETE_MEDIA_META",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  DELETE_MESSAGE_GROUP = "DELETE_MESSAGE_GROUP",
  DELETE_MESSAGE_TEMPLATE = "DELETE_MESSAGE_TEMPLATE",
  DELETE_ORGANIZATION = "DELETE_ORGANIZATION",
  DELETE_OVES_ADMIN = "DELETE_OVES_ADMIN",
  DELETE_PACK = "DELETE_PACK",
  DELETE_PART = "DELETE_PART",
  DELETE_PAYPLAN = "DELETE_PAYPLAN",
  DELETE_PERMISSION = "DELETE_PERMISSION",
  DELETE_PERMISSIONS_FROM_AGENT = "DELETE_PERMISSIONS_FROM_AGENT",
  DELETE_PERSON = "DELETE_PERSON",
  DELETE_PLACE = "DELETE_PLACE",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  DELETE_PRODUCT_SELECTOR = "DELETE_PRODUCT_SELECTOR",
  DELETE_PUBLISHER = "DELETE_PUBLISHER",
  DELETE_ROLE = "DELETE_ROLE",
  DELETE_ROLE_PERMISSION = "DELETE_ROLE_PERMISSION",
  DELETE_ROUTE = "DELETE_ROUTE",
  DELETE_SERVICER = "DELETE_SERVICER",
  DELETE_SHIPMENT = "DELETE_SHIPMENT",
  DELETE_SIGNAL = "DELETE_SIGNAL",
  DELETE_SUBROLE = "DELETE_SUBROLE",
  DELETE_SUBROLE_PERMISSIONS = "DELETE_SUBROLE_PERMISSIONS",
  DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER",
  DELETE_SUPPLIER = "DELETE_SUPPLIER",
  DELETE_THING_BROKER = "DELETE_THING_BROKER",
  DELETE_VARIATION = "DELETE_VARIATION",
  DISTRIBUTOR_INCREASE_FREECODE_COUNT_FOR_ITEM = "DISTRIBUTOR_INCREASE_FREECODE_COUNT_FOR_ITEM",
  DISTRIBUTOR_INCREASE_RESETCODE_COUNT_FOR_ITEM = "DISTRIBUTOR_INCREASE_RESETCODE_COUNT_FOR_ITEM",
  DISTRIBUTOR_REGISTER_DISTRIBUTOR_STAFF = "DISTRIBUTOR_REGISTER_DISTRIBUTOR_STAFF",
  DISTRIBUTOR_STAFF_REGISTER_AGENT = "DISTRIBUTOR_STAFF_REGISTER_AGENT",
  DOWNLOAD_CLIENT_ASSET_ACCOUNTS = "DOWNLOAD_CLIENT_ASSET_ACCOUNTS",
  DOWNLOAD_CLIENT_CREDS = "DOWNLOAD_CLIENT_CREDS",
  DOWNLOAD_DISTRIBUTOR_CODE_GENS = "DOWNLOAD_DISTRIBUTOR_CODE_GENS",
  DOWNLOAD_DISTRIBUTOR_DETAILS = "DOWNLOAD_DISTRIBUTOR_DETAILS",
  DOWNLOAD_DISTRIBUTOR_FLEET = "DOWNLOAD_DISTRIBUTOR_FLEET",
  DOWNLOAD_DISTRIBUTOR_ITEMS = "DOWNLOAD_DISTRIBUTOR_ITEMS",
  EMIT_SIGNAL = "EMIT_SIGNAL",
  FREEZE_CREDIT_ACCOUNT = "FREEZE_CREDIT_ACCOUNT",
  GENERATE_AGENT_SIGNUP_TOKEN = "GENERATE_AGENT_SIGNUP_TOKEN",
  GENERATE_CODEGENERATOR = "GENERATE_CODEGENERATOR",
  GENERATE_DAYSCODE = "GENERATE_DAYSCODE",
  GENERATE_DISTRIBUTOR_MQTT_PASSWORD = "GENERATE_DISTRIBUTOR_MQTT_PASSWORD",
  GENERATE_FREESCODE = "GENERATE_FREESCODE",
  GENERATE_LOGIN_USER_SUBROLE_SIGNUP_TOKEN = "GENERATE_LOGIN_USER_SUBROLE_SIGNUP_TOKEN",
  GENERATE_RESETCODE = "GENERATE_RESETCODE",
  GENERATE_ROLE_USER_SIGN_UP_TOKEN = "GENERATE_ROLE_USER_SIGN_UP_TOKEN",
  GENERATE_USER_ROLE_SIGNUP_TOKEN = "GENERATE_USER_ROLE_SIGNUP_TOKEN",
  GET_ALL_ADDRESSES = "GET_ALL_ADDRESSES",
  GET_ALL_AGENTS = "GET_ALL_AGENTS",
  GET_ALL_AGENTS_FOR_DISTRIBUTOR = "GET_ALL_AGENTS_FOR_DISTRIBUTOR",
  GET_ALL_AGENTS_FOR_SPECIFIC_DISTRIBUTOR_STAFF = "GET_ALL_AGENTS_FOR_SPECIFIC_DISTRIBUTOR_STAFF",
  GET_ALL_AGENT_ITEMS = "GET_ALL_AGENT_ITEMS",
  GET_ALL_AMAZON_PRODUCT_CATEGORIES = "GET_ALL_AMAZON_PRODUCT_CATEGORIES",
  GET_ALL_APPLICATION_SITES = "GET_ALL_APPLICATION_SITES",
  GET_ALL_ARTICLES = "GET_ALL_ARTICLES",
  GET_ALL_ASSET_ACCOUNTS = "GET_ALL_ASSET_ACCOUNTS",
  GET_ALL_ASSET_ACCOUNTS_FOR_AGENT = "GET_ALL_ASSET_ACCOUNTS_FOR_AGENT",
  GET_ALL_ASSET_ACCOUNTS_FOR_CLIENT = "GET_ALL_ASSET_ACCOUNTS_FOR_CLIENT",
  GET_ALL_ASSET_ACCOUNTS_FOR_CUSTOMER = "GET_ALL_ASSET_ACCOUNTS_FOR_CUSTOMER",
  GET_ALL_ASSET_ACCOUNTS_FOR_SPECIFIC_DISTRIBUTOR_STAFF = "GET_ALL_ASSET_ACCOUNTS_FOR_SPECIFIC_DISTRIBUTOR_STAFF",
  GET_ALL_ASSET_ACCOUNTS_TEMP_DATA = "GET_ALL_ASSET_ACCOUNTS_TEMP_DATA",
  GET_ALL_AUTHENTICATION_INSTANCE = "GET_ALL_AUTHENTICATION_INSTANCE",
  GET_ALL_AUTHENTICATION_SUBINSTANCE = "GET_ALL_AUTHENTICATION_SUBINSTANCE",
  GET_ALL_AVATARS = "GET_ALL_AVATARS",
  GET_ALL_BRANDS = "GET_ALL_BRANDS",
  GET_ALL_BRAND_SELECTOR_SELECTORS = "GET_ALL_BRAND_SELECTOR_SELECTORS",
  GET_ALL_BROKERS = "GET_ALL_BROKERS",
  GET_ALL_BUYERS = "GET_ALL_BUYERS",
  GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES",
  GET_ALL_CATEGORY_SELECTOR_SELECTORS = "GET_ALL_CATEGORY_SELECTOR_SELECTORS",
  GET_ALL_CLIENT_CUSTOMERS = "GET_ALL_CLIENT_CUSTOMERS",
  GET_ALL_CLIENT_EXCLUSIVE_SERVICES = "GET_ALL_CLIENT_EXCLUSIVE_SERVICES",
  GET_ALL_CLIENT_EXCLUSIVE_SERVICES_FOR_DISTRIBUTOR = "GET_ALL_CLIENT_EXCLUSIVE_SERVICES_FOR_DISTRIBUTOR",
  GET_ALL_CLIENT_EXCLUSIVE_SERVICES_FOR_SERVICER_GLOBAL = "GET_ALL_CLIENT_EXCLUSIVE_SERVICES_FOR_SERVICER_GLOBAL",
  GET_ALL_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS = "GET_ALL_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS",
  GET_ALL_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS_FOR_CLIENT_EXCLUSIVE_PERMISSION = "GET_ALL_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS_FOR_CLIENT_EXCLUSIVE_PERMISSION",
  GET_ALL_CLIENT_ITEMS = "GET_ALL_CLIENT_ITEMS",
  GET_ALL_CLIENT_ITEMS_IN_ITEMFLEET = "GET_ALL_CLIENT_ITEMS_IN_ITEMFLEET",
  GET_ALL_CODEGENERATORS = "GET_ALL_CODEGENERATORS",
  GET_ALL_CODE_EVENTS = "GET_ALL_CODE_EVENTS",
  GET_ALL_CODE_EVENTS_FOR_SPECIFIC_ITEM_BY_DISTRIBUTOR = "GET_ALL_CODE_EVENTS_FOR_SPECIFIC_ITEM_BY_DISTRIBUTOR",
  GET_ALL_CODE_SYSTEMS = "GET_ALL_CODE_SYSTEMS",
  GET_ALL_DISPATCHERS = "GET_ALL_DISPATCHERS",
  GET_ALL_DISTRIBUTORS = "GET_ALL_DISTRIBUTORS",
  GET_ALL_DISTRIBUTOR_STAFF = "GET_ALL_DISTRIBUTOR_STAFF",
  GET_ALL_DISTRIBUTOR_STAFF_FOR_SPECIFIC_DISTRIBUTOR = "GET_ALL_DISTRIBUTOR_STAFF_FOR_SPECIFIC_DISTRIBUTOR",
  GET_ALL_ECOMMERCE_PRODUCTS = "GET_ALL_ECOMMERCE_PRODUCTS",
  GET_ALL_EVENTS = "GET_ALL_EVENTS",
  GET_ALL_ITEMBATCHES = "GET_ALL_ITEMBATCHES",
  GET_ALL_ITEMFIRMWARES = "GET_ALL_ITEMFIRMWARES",
  GET_ALL_ITEMFLEETS = "GET_ALL_ITEMFLEETS",
  GET_ALL_ITEMS = "GET_ALL_ITEMS",
  GET_ALL_ITEMSKUS = "GET_ALL_ITEMSKUS",
  GET_ALL_ITEMSTOCKS = "GET_ALL_ITEMSTOCKS",
  GET_ALL_ITEMS_FOR_ITEMBATCH_IN_DESCENDING_ORDER = "GET_ALL_ITEMS_FOR_ITEMBATCH_IN_DESCENDING_ORDER",
  GET_ALL_ITEM_EVENTS = "GET_ALL_ITEM_EVENTS",
  GET_ALL_ITEM_IN_ITEM_FLEETS = "GET_ALL_ITEM_IN_ITEM_FLEETS",
  GET_ALL_LOGIN_USERS = "GET_ALL_LOGIN_USERS",
  GET_ALL_MATERIALS = "GET_ALL_MATERIALS",
  GET_ALL_MEDIA_METAS = "GET_ALL_MEDIA_METAS",
  GET_ALL_MESSAGES = "GET_ALL_MESSAGES",
  GET_ALL_MESSAGE_TEMPLATES = "GET_ALL_MESSAGE_TEMPLATES",
  GET_ALL_ORGANIZATIONS = "GET_ALL_ORGANIZATIONS",
  GET_ALL_OVESADMINS = "GET_ALL_OVESADMINS",
  GET_ALL_OVES_ADMINS = "GET_ALL_OVES_ADMINS",
  GET_ALL_PACKS = "GET_ALL_PACKS",
  GET_ALL_PARTS = "GET_ALL_PARTS",
  GET_ALL_PAYPLANS = "GET_ALL_PAYPLANS",
  GET_ALL_PAY_PLAN_TEMPLATES = "GET_ALL_PAY_PLAN_TEMPLATES",
  GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS",
  GET_ALL_PERMISSIONS_FOR_AGENT = "GET_ALL_PERMISSIONS_FOR_AGENT",
  GET_ALL_PERSONS = "GET_ALL_PERSONS",
  GET_ALL_PERSONS_BY_IDS = "GET_ALL_PERSONS_BY_IDS",
  GET_ALL_PLACES = "GET_ALL_PLACES",
  GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS",
  GET_ALL_PRODUCT_SELECTOR_SELECTORS = "GET_ALL_PRODUCT_SELECTOR_SELECTORS",
  GET_ALL_PUBLISHERS = "GET_ALL_PUBLISHERS",
  GET_ALL_ROLES = "GET_ALL_ROLES",
  GET_ALL_ROUTES = "GET_ALL_ROUTES",
  GET_ALL_SERVICERS = "GET_ALL_SERVICERS",
  GET_ALL_SHIPMENT = "GET_ALL_SHIPMENT",
  GET_ALL_SIGNALS = "GET_ALL_SIGNALS",
  GET_ALL_SPECIFIC_ARTICLE = "GET_ALL_SPECIFIC_ARTICLE",
  GET_ALL_SUBROLES = "GET_ALL_SUBROLES",
  GET_ALL_SUBROLE_LOGINUSERS_FOR_ROLE_LOGINUSER = "GET_ALL_SUBROLE_LOGINUSERS_FOR_ROLE_LOGINUSER",
  GET_ALL_SUBSCRIBERS = "GET_ALL_SUBSCRIBERS",
  GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS",
  GET_ALL_THING_BROKERS = "GET_ALL_THING_BROKERS",
  GET_ALL_UNPROCESSED_ITEM_EVENTS = "GET_ALL_UNPROCESSED_ITEM_EVENTS",
  GET_ALL_VARIATIONS = "GET_ALL_VARIATIONS",
  GET_CODE_EVENT_BY_CODEGENERATORIDS = "GET_CODE_EVENT_BY_CODEGENERATORIDS",
  GET_DISTRIBUTOR_TOKEN = "GET_DISTRIBUTOR_TOKEN",
  GET_ECOMMERCE_PRODUCTS_BY_CATEGORY = "GET_ECOMMERCE_PRODUCTS_BY_CATEGORY",
  GET_ITEMFLEETS_FOR_CLIENT = "GET_ITEMFLEETS_FOR_CLIENT",
  GET_PERMISSIONS_FOR_SUBROLE = "GET_PERMISSIONS_FOR_SUBROLE",
  GET_SPECIFIC_ADDRESS = "GET_SPECIFIC_ADDRESS",
  GET_SPECIFIC_AGENT = "GET_SPECIFIC_AGENT",
  GET_SPECIFIC_AMAZON_PRODUCT_CATEGORY = "GET_SPECIFIC_AMAZON_PRODUCT_CATEGORY",
  GET_SPECIFIC_APPLICATION_SITE = "GET_SPECIFIC_APPLICATION_SITE",
  GET_SPECIFIC_ASSET_ACCOUNTS = "GET_SPECIFIC_ASSET_ACCOUNTS",
  GET_SPECIFIC_ASSET_ACCOUNT_CREDIT_BALANCE = "GET_SPECIFIC_ASSET_ACCOUNT_CREDIT_BALANCE",
  GET_SPECIFIC_AUTHENTICATION_INSTANCE = "GET_SPECIFIC_AUTHENTICATION_INSTANCE",
  GET_SPECIFIC_AUTHENTICATION_SUBINSTANCE = "GET_SPECIFIC_AUTHENTICATION_SUBINSTANCE",
  GET_SPECIFIC_AVATAR = "GET_SPECIFIC_AVATAR",
  GET_SPECIFIC_AVATAR_FOR_ITEM = "GET_SPECIFIC_AVATAR_FOR_ITEM",
  GET_SPECIFIC_BRAND = "GET_SPECIFIC_BRAND",
  GET_SPECIFIC_BRAND_SELECTOR = "GET_SPECIFIC_BRAND_SELECTOR",
  GET_SPECIFIC_BROKER = "GET_SPECIFIC_BROKER",
  GET_SPECIFIC_BUYER = "GET_SPECIFIC_BUYER",
  GET_SPECIFIC_CATEGORY = "GET_SPECIFIC_CATEGORY",
  GET_SPECIFIC_CATEGORY_SELECTOR = "GET_SPECIFIC_CATEGORY_SELECTOR",
  GET_SPECIFIC_CLIENT_EXCLUSIVE_SERVICE = "GET_SPECIFIC_CLIENT_EXCLUSIVE_SERVICE",
  GET_SPECIFIC_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS = "GET_SPECIFIC_CLIENT_EXCLUSIVE_SERVICE_PERMISSIONS",
  GET_SPECIFIC_CLIENT_ITEM_IN_ITEMFLEET = "GET_SPECIFIC_CLIENT_ITEM_IN_ITEMFLEET",
  GET_SPECIFIC_CODEGENERATOR = "GET_SPECIFIC_CODEGENERATOR",
  GET_SPECIFIC_CODE_EVENT = "GET_SPECIFIC_CODE_EVENT",
  GET_SPECIFIC_CODE_SYSTEM = "GET_SPECIFIC_CODE_SYSTEM",
  GET_SPECIFIC_CUSTOMER_OR_PERSON = "GET_SPECIFIC_CUSTOMER_OR_PERSON",
  GET_SPECIFIC_DISPATCHER = "GET_SPECIFIC_DISPATCHER",
  GET_SPECIFIC_DISTRIBUTOR = "GET_SPECIFIC_DISTRIBUTOR",
  GET_SPECIFIC_DISTRIBUTOR_SETTING = "GET_SPECIFIC_DISTRIBUTOR_SETTING",
  GET_SPECIFIC_DISTRIBUTOR_STAFF = "GET_SPECIFIC_DISTRIBUTOR_STAFF",
  GET_SPECIFIC_ECOMMERCE_PRODUCT = "GET_SPECIFIC_ECOMMERCE_PRODUCT",
  GET_SPECIFIC_EVENT = "GET_SPECIFIC_EVENT",
  GET_SPECIFIC_ITEM = "GET_SPECIFIC_ITEM",
  GET_SPECIFIC_ITEMBATCH = "GET_SPECIFIC_ITEMBATCH",
  GET_SPECIFIC_ITEMFIRMWARE = "GET_SPECIFIC_ITEMFIRMWARE",
  GET_SPECIFIC_ITEMFLEET = "GET_SPECIFIC_ITEMFLEET",
  GET_SPECIFIC_ITEMSKU = "GET_SPECIFIC_ITEMSKU",
  GET_SPECIFIC_ITEMSTOCK = "GET_SPECIFIC_ITEMSTOCK",
  GET_SPECIFIC_LOGIN_USER = "GET_SPECIFIC_LOGIN_USER",
  GET_SPECIFIC_MATERIAL = "GET_SPECIFIC_MATERIAL",
  GET_SPECIFIC_MEDIA_META = "GET_SPECIFIC_MEDIA_META",
  GET_SPECIFIC_MESSAGE = "GET_SPECIFIC_MESSAGE",
  GET_SPECIFIC_MESSAGE_GROUP = "GET_SPECIFIC_MESSAGE_GROUP",
  GET_SPECIFIC_MESSAGE_TEMPLATE = "GET_SPECIFIC_MESSAGE_TEMPLATE",
  GET_SPECIFIC_ORGANIZATION = "GET_SPECIFIC_ORGANIZATION",
  GET_SPECIFIC_OVESADMIN = "GET_SPECIFIC_OVESADMIN",
  GET_SPECIFIC_OVES_ADMIN = "GET_SPECIFIC_OVES_ADMIN",
  GET_SPECIFIC_PACK = "GET_SPECIFIC_PACK",
  GET_SPECIFIC_PART = "GET_SPECIFIC_PART",
  GET_SPECIFIC_PAYPLAN = "GET_SPECIFIC_PAYPLAN",
  GET_SPECIFIC_PAYPLAN_TEMPLATE = "GET_SPECIFIC_PAYPLAN_TEMPLATE",
  GET_SPECIFIC_PERMISSION = "GET_SPECIFIC_PERMISSION",
  GET_SPECIFIC_PLACE = "GET_SPECIFIC_PLACE",
  GET_SPECIFIC_PRODUCT = "GET_SPECIFIC_PRODUCT",
  GET_SPECIFIC_PRODUCT_SELECTOR = "GET_SPECIFIC_PRODUCT_SELECTOR",
  GET_SPECIFIC_ROLE = "GET_SPECIFIC_ROLE",
  GET_SPECIFIC_ROLE_PERMISSIONS = "GET_SPECIFIC_ROLE_PERMISSIONS",
  GET_SPECIFIC_ROLE_PERMISSION_BY_PERMISSION_NAME = "GET_SPECIFIC_ROLE_PERMISSION_BY_PERMISSION_NAME",
  GET_SPECIFIC_ROUTE = "GET_SPECIFIC_ROUTE",
  GET_SPECIFIC_SERVICER = "GET_SPECIFIC_SERVICER",
  GET_SPECIFIC_SHIPMENT = "GET_SPECIFIC_SHIPMENT",
  GET_SPECIFIC_SIGNAL = "GET_SPECIFIC_SIGNAL",
  GET_SPECIFIC_SUBROLE = "GET_SPECIFIC_SUBROLE",
  GET_SPECIFIC_SUBROLE_PERMISSIONS_BY_PERMISSION_NAME = "GET_SPECIFIC_SUBROLE_PERMISSIONS_BY_PERMISSION_NAME",
  GET_SPECIFIC_SUBSCRIBER = "GET_SPECIFIC_SUBSCRIBER",
  GET_SPECIFIC_SUPPLIER = "GET_SPECIFIC_SUPPLIER",
  GET_SPECIFIC_THING_BROKER = "GET_SPECIFIC_THING_BROKER",
  GET_SPECIFIC_VARIATION = "GET_SPECIFIC_VARIATION",
  GET_SUBROLES_FOR_LOGINUSER_ROLE = "GET_SUBROLES_FOR_LOGINUSER_ROLE",
  INITIALIZE_CODEGENERATOR = "INITIALIZE_CODEGENERATOR",
  ITEMSKU_GATT_MANIPULATION = "ITEMSKU_GATT_MANIPULATION",
  LOAD_CODE_EVENTS_DATA_TO_ELASTIC_SEARCH = "LOAD_CODE_EVENTS_DATA_TO_ELASTIC_SEARCH",
  LOAD_CODE_GENS_DATA_TO_ELASTIC_SEARCH = "LOAD_CODE_GENS_DATA_TO_ELASTIC_SEARCH",
  LOAD_CODE_SYSTEMS_DATA_TO_ELASTIC_SEARCH = "LOAD_CODE_SYSTEMS_DATA_TO_ELASTIC_SEARCH",
  LOAD_ITEMS_DATA_TO_ELASTIC_SEARCH = "LOAD_ITEMS_DATA_TO_ELASTIC_SEARCH",
  LOAD_ITEM_BATCHES_DATA_TO_ELASTIC_SEARCH = "LOAD_ITEM_BATCHES_DATA_TO_ELASTIC_SEARCH",
  LOAD_ITEM_SKUS_DATA_TO_ELASTIC_SEARCH = "LOAD_ITEM_SKUS_DATA_TO_ELASTIC_SEARCH",
  LOG_EVENT = "LOG_EVENT",
  LOG_PUBLISHER = "LOG_PUBLISHER",
  MAKE_AUTHENTICATION_INSTANCE_ACTIVE = "MAKE_AUTHENTICATION_INSTANCE_ACTIVE",
  MAKE_AUTHENTICATION_SUBINSTANCE_ACTIVE = "MAKE_AUTHENTICATION_SUBINSTANCE_ACTIVE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PAIR_ASSETACCOUNT = "PAIR_ASSETACCOUNT",
  PAY_TO_CODE = "PAY_TO_CODE",
  PROVISION_BATCH = "PROVISION_BATCH",
  PROVISION_PARTIAL = "PROVISION_PARTIAL",
  REASSIGN_BATCH_ITEMS_FROM_ITEM_FLEET = "REASSIGN_BATCH_ITEMS_FROM_ITEM_FLEET",
  REASSIGN_FLEET_ITEMS_FROM_FLEET = "REASSIGN_FLEET_ITEMS_FROM_FLEET",
  REASSIGN_ITEMS_FROM_ITEM_FLEET = "REASSIGN_ITEMS_FROM_ITEM_FLEET",
  REMOVE_ASSET_ACCOUNT_FROM_AGENT_BY_DISTRIBUTOR_STAFF = "REMOVE_ASSET_ACCOUNT_FROM_AGENT_BY_DISTRIBUTOR_STAFF",
  REMOVE_CUSTOMER_FROM_AGENT = "REMOVE_CUSTOMER_FROM_AGENT",
  REMOVE_ITEM_FROM_AGENT = "REMOVE_ITEM_FROM_AGENT",
  RESET_DISTRIBUTOR_PASSWORD = "RESET_DISTRIBUTOR_PASSWORD",
  RESET_OVES_PERSONEL_PASSWORD = "RESET_OVES_PERSONEL_PASSWORD",
  RESOLVE_CREDIT_ACCOUNT = "RESOLVE_CREDIT_ACCOUNT",
  RESTORE_SIGNAL_CLASS_WITH_TIMESTAMP = "RESTORE_SIGNAL_CLASS_WITH_TIMESTAMP",
  REVERSE_CODE_EVENT = "REVERSE_CODE_EVENT",
  REVERSE_CODE_EVENT_BY_DATE = "REVERSE_CODE_EVENT_BY_DATE",
  RE_ASSIGN_ITEM_TO_ITEMFLEET = "RE_ASSIGN_ITEM_TO_ITEMFLEET",
  SEND_SMS = "SEND_SMS",
  SET_ASSETACCOUNT_STATUS_TO_PAYPLAN_COMPLETED = "SET_ASSETACCOUNT_STATUS_TO_PAYPLAN_COMPLETED",
  SET_CREDIT_ACCOUNT_BALANCE_FOR_ASSET_ACCOUNT = "SET_CREDIT_ACCOUNT_BALANCE_FOR_ASSET_ACCOUNT",
  SUSPEND_CREDIT_ACCOUNT = "SUSPEND_CREDIT_ACCOUNT",
  THING_SERVICE_ITEMS_BY_OEMITEMID = "THING_SERVICE_ITEMS_BY_OEMITEMID",
  TRACK_BULK_CREATE_ITEMS = "TRACK_BULK_CREATE_ITEMS",
  UNASSIGN_ITEM_FROM_FLEET = "UNASSIGN_ITEM_FROM_FLEET",
  UNASSIGN_ITEM_FROM_ITEM_FLEET = "UNASSIGN_ITEM_FROM_ITEM_FLEET",
  UPDATE_ADDRESS = "UPDATE_ADDRESS",
  UPDATE_AGENT = "UPDATE_AGENT",
  UPDATE_AGENT_PASSWORD = "UPDATE_AGENT_PASSWORD",
  UPDATE_AMAZON_PRODUCT_CATEGORY = "UPDATE_AMAZON_PRODUCT_CATEGORY",
  UPDATE_APPLICATION_SITE = "UPDATE_APPLICATION_SITE",
  UPDATE_ARTICLE = "UPDATE_ARTICLE",
  UPDATE_ASSET_ACCOUNT = "UPDATE_ASSET_ACCOUNT",
  UPDATE_ASSIGNED_PERMISSION_TO_ROLE = "UPDATE_ASSIGNED_PERMISSION_TO_ROLE",
  UPDATE_ASSIGNED_ROLE_PERMISSIONS_TO_SUBROLE = "UPDATE_ASSIGNED_ROLE_PERMISSIONS_TO_SUBROLE",
  UPDATE_AUTHENTICATION_INSTANCE = "UPDATE_AUTHENTICATION_INSTANCE",
  UPDATE_AUTHENTICATION_SUBINSTANCE = "UPDATE_AUTHENTICATION_SUBINSTANCE",
  UPDATE_AVATAR = "UPDATE_AVATAR",
  UPDATE_AVATAR_BY_ITEM_ID = "UPDATE_AVATAR_BY_ITEM_ID",
  UPDATE_BRAND = "UPDATE_BRAND",
  UPDATE_BRAND_SELECTOR = "UPDATE_BRAND_SELECTOR",
  UPDATE_BROKER = "UPDATE_BROKER",
  UPDATE_BUYER = "UPDATE_BUYER",
  UPDATE_BUYER_PASSWORD = "UPDATE_BUYER_PASSWORD",
  UPDATE_CATEGORY = "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SELECTOR = "UPDATE_CATEGORY_SELECTOR",
  UPDATE_CLIENT_EXCLUSIVE_SERVICE = "UPDATE_CLIENT_EXCLUSIVE_SERVICE",
  UPDATE_CODEGENERATOR_HASHTOP = "UPDATE_CODEGENERATOR_HASHTOP",
  UPDATE_CODE_GEN_STATE = "UPDATE_CODE_GEN_STATE",
  UPDATE_DISPATCHER = "UPDATE_DISPATCHER",
  UPDATE_DISTRIBUTOR = "UPDATE_DISTRIBUTOR",
  UPDATE_DISTRIBUTOR_PASSWORD = "UPDATE_DISTRIBUTOR_PASSWORD",
  UPDATE_DISTRIBUTOR_SETTING = "UPDATE_DISTRIBUTOR_SETTING",
  UPDATE_DISTRIBUTOR_STAFF = "UPDATE_DISTRIBUTOR_STAFF",
  UPDATE_ECOMMERCE_PRODUCT = "UPDATE_ECOMMERCE_PRODUCT",
  UPDATE_EVENT = "UPDATE_EVENT",
  UPDATE_ITEM = "UPDATE_ITEM",
  UPDATE_ITEMBATCH = "UPDATE_ITEMBATCH",
  UPDATE_ITEMFIRMWARE = "UPDATE_ITEMFIRMWARE",
  UPDATE_ITEMFLEET = "UPDATE_ITEMFLEET",
  UPDATE_ITEMSKU = "UPDATE_ITEMSKU",
  UPDATE_ITEMSTOCK = "UPDATE_ITEMSTOCK",
  UPDATE_ITEM_EVENT = "UPDATE_ITEM_EVENT",
  UPDATE_ITEM_FLEET_CODE_GEN = "UPDATE_ITEM_FLEET_CODE_GEN",
  UPDATE_LEGACY_CODEGEN_PARAMETERS = "UPDATE_LEGACY_CODEGEN_PARAMETERS",
  UPDATE_LOGINUSER_PASSWORD = "UPDATE_LOGINUSER_PASSWORD",
  UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER",
  UPDATE_MATERIAL = "UPDATE_MATERIAL",
  UPDATE_MEDIA_META = "UPDATE_MEDIA_META",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  UPDATE_MESSAGE_GROUP = "UPDATE_MESSAGE_GROUP",
  UPDATE_MESSAGE_TEMPLATE = "UPDATE_MESSAGE_TEMPLATE",
  UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
  UPDATE_OVES_ADMIN = "UPDATE_OVES_ADMIN",
  UPDATE_OVES_ADMIN_PASSWORD = "UPDATE_OVES_ADMIN_PASSWORD",
  UPDATE_PACK = "UPDATE_PACK",
  UPDATE_PART = "UPDATE_PART",
  UPDATE_PAYPLAN = "UPDATE_PAYPLAN",
  UPDATE_PERMISSION = "UPDATE_PERMISSION",
  UPDATE_PERSON = "UPDATE_PERSON",
  UPDATE_PLACE = "UPDATE_PLACE",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SELECTOR = "UPDATE_PRODUCT_SELECTOR",
  UPDATE_PUBLISHER = "UPDATE_PUBLISHER",
  UPDATE_ROLE = "UPDATE_ROLE",
  UPDATE_ROUTE = "UPDATE_ROUTE",
  UPDATE_SERVICER = "UPDATE_SERVICER",
  UPDATE_SHIPMENT = "UPDATE_SHIPMENT",
  UPDATE_SIGNAL = "UPDATE_SIGNAL",
  UPDATE_SUBROLE = "UPDATE_SUBROLE",
  UPDATE_SUBSCRIBER = "UPDATE_SUBSCRIBER",
  UPDATE_SUPPLIER = "UPDATE_SUPPLIER",
  UPDATE_THING_BROKER = "UPDATE_THING_BROKER",
  UPDATE_VARIATION = "UPDATE_VARIATION",
  UPLOAD_ASSET_ACCOUNTS_DOCKER_STANDALONE = "UPLOAD_ASSET_ACCOUNTS_DOCKER_STANDALONE",
  UPLOAD_AUTHENTICATION_INSTANCE_DOCKER_STANDALONE = "UPLOAD_AUTHENTICATION_INSTANCE_DOCKER_STANDALONE",
  UPLOAD_CODE_EVENTS_DOCKER_STANDALONE = "UPLOAD_CODE_EVENTS_DOCKER_STANDALONE",
  UPLOAD_CODE_GENS_DOCKER_STANDALONE = "UPLOAD_CODE_GENS_DOCKER_STANDALONE",
  UPLOAD_CODE_SYSTEMS_DOCKER_STANDALONE = "UPLOAD_CODE_SYSTEMS_DOCKER_STANDALONE",
  UPLOAD_CUSTOMER_DETAILS_DOCKER_STANDALONE = "UPLOAD_CUSTOMER_DETAILS_DOCKER_STANDALONE",
  UPLOAD_DISTRIBUTOR_DETAILS_DOCKER_STANDALONE = "UPLOAD_DISTRIBUTOR_DETAILS_DOCKER_STANDALONE",
  UPLOAD_FILE = "UPLOAD_FILE",
  UPLOAD_ITEMSKUS_DOCKER_STANDALONE = "UPLOAD_ITEMSKUS_DOCKER_STANDALONE",
  UPLOAD_ITEMS_DOCKER_STANDALONE = "UPLOAD_ITEMS_DOCKER_STANDALONE",
  UPLOAD_ITEM_BATCHES_DOCKER_STANDALONE = "UPLOAD_ITEM_BATCHES_DOCKER_STANDALONE",
  UPLOAD_ITEM_FIRMWARES_DOCKER_STANDALONE = "UPLOAD_ITEM_FIRMWARES_DOCKER_STANDALONE",
  UPLOAD_ITEM_FLEETS_DOCKER_STANDALONE = "UPLOAD_ITEM_FLEETS_DOCKER_STANDALONE",
  UPLOAD_ITEM_STOCKS_DOCKER_STANDALONE = "UPLOAD_ITEM_STOCKS_DOCKER_STANDALONE",
  UPLOAD_PAY_PLANS_DOCKER_STANDALONE = "UPLOAD_PAY_PLANS_DOCKER_STANDALONE",
  UPLOAD_ROLES_DOCKER_STANDALONE = "UPLOAD_ROLES_DOCKER_STANDALONE",
  UPLOAD_ROLE_PERMISSIONS_DOCKER_STANDALONE = "UPLOAD_ROLE_PERMISSIONS_DOCKER_STANDALONE",
  VIEW_ACTIVITIES_OF_ASSETACCOUNT = "VIEW_ACTIVITIES_OF_ASSETACCOUNT",
  VIEW_EVENT = "VIEW_EVENT",
  VIEW_PUBLISHER = "VIEW_PUBLISHER",
}

export enum PersonTypes {
  AGENT = "AGENT",
  CONTACT = "CONTACT",
  CUSTOMER = "CUSTOMER",
  DEVELOPER = "DEVELOPER",
  EMPLOYEE = "EMPLOYEE",
  LOGIN = "LOGIN",
}

export interface AddressDto {
  unit: string;
  street: string;
  city: string;
  srpc: string;
  country: string;
  postcode: string;
  locationAddressDto: AddressLocationDto;
}

export interface AddressLocationDto {
  inputAddressLatitude: number;
  inputAddressLongitude: number;
}

export interface AdminRegisterDistributorInput {
  distributorType?: OrgTypes | null;
  distributorName?: string | null;
  distributorDescription?: string | null;
  distributorAddress: AddressDto;
  email: string;
  roleId?: string | null;
  contactRole: string;
}

export interface AdminRegisterServicerInput {
  servicerType?: OrgTypes | null;
  servicerName?: string | null;
  servicerDescription?: string | null;
  servicerAddress: AddressDto;
  email: string;
  roleId: string;
  contactRole: string;
}

export interface AssignAssetAccountToAgentInput {
  assetAccountIds: string[];
  agentId: string;
}

export interface AssignClientExclusiveServicePermissionToClientExclusiveServiceInput {
  permissions: PermissionInput[];
  clientExclusiveServiceId: string;
  distributorId: string;
  authenticationInstanceId?: string | null;
}

export interface AssignCustomerToAgentInput {
  agentId: string;
  customers: string[];
}

export interface AssignDistributorClientExclusiveServiceTypeInput {
  servicerId: string;
  distributorId: string;
  serviceType: ClientExclusiveServiceTypes;
}

export interface AssignItemToAgentInput {
  agentId: string;
  items: string[];
}

export interface ClientRegisterPersonInput {
  clientPersonType?: PersonTypes | null;
  clientPersonName: string;
  clientPersonDescription?: string | null;
  clientContactPerson: PersonContactDto;
  clientPersonAddress: AddressDto;
  clientId?: string | null;
  gender?: string | null;
  details?: DetailDto[] | null;
}

export interface ContactDto {
  phone: string;
  social: string;
}

export interface CreateOrganizationInput {
  orgType?: OrgTypes | null;
  orgName?: string | null;
  orgProfile: string;
  orgDescription?: string | null;
  orgAddress: AddressDto;
  contactRole: string;
}

export interface CreateSupplierInput {
  supplierType?: OrgTypes | null;
  supplierName?: string | null;
  supplierDescription?: string | null;
  supplierAddress: AddressDto;
  contactRole: string;
}

export interface DetailDto {
  name: string;
  value: string;
}

export interface DistributorRegisterDistributorStaffInput {
  subRoleId: string;
  staffID: string;
  hireDate: any;
  officeAddress: AddressDto;
  email: string;
  firstName: string;
  lastName: string;
  gender?: string | null;
  age?: number | null;
  staffContact: ContactDto;
  staffAddress: AddressDto;
}

export interface DistributorStaffRegisterAgentInput {
  firstName: string;
  lastName: string;
  gender?: string | null;
  age?: number | null;
  agentContact: ContactDto;
  agentAddress: AddressDto;
  email: string;
  typeOfAgent: string;
  agentType: string;
  subRoleId: string;
  agentID: string;
  hireDate: any;
  officeAddress: AddressDto;
}

export interface PersonContactDto {
  phone: string;
  email: string;
  social: string;
}

export interface ResetDistributorPasswordInput {
  token: string;
  password: string;
}

export interface SignUpClientInput {
  personType?: PersonTypes | null;
  personName: string;
  personDescription?: string | null;
  phone: string;
  social: string;
  authenticationToken?: string | null;
  email: string;
  password: string;
  address: AddressDto;
}

export interface SignUpDistributorStaffOrAgentInput {
  authenticationToken: string;
  email: string;
  password: string;
}

export interface UpdateAddressDto {
  unit?: string | null;
  street?: string | null;
  city?: string | null;
  srpc?: string | null;
  country?: string | null;
  postcode?: string | null;
  UpdateLocationAddressDto?: UpdateLocationDto | null;
}

export interface UpdateClientExclusiveServiceInput {
  clientExclusiveServiceId: string;
  servicerId?: string | null;
  distributorId?: string | null;
  serviceType?: ClientExclusiveServiceTypes | null;
}

export interface UpdateClientPersonInput {
  personId: string;
  clientPersonType?: PersonTypes | null;
  clientPersonName?: string | null;
  clientPersonDescription?: string | null;
  clientContactPerson?: PersonContactDto | null;
  clientPersonAddress?: AddressDto | null;
  clientId?: string | null;
  details?: DetailDto[] | null;
  gender?: string | null;
}

export interface UpdateContactDto {
  phone?: string | null;
  email?: string | null;
  social?: string | null;
}

export interface UpdateDistributorSettingInput {
  distributorId: string;
  freeCodeCount?: number | null;
  resetCodeCount?: number | null;
  dayCodeCountLimit?: number | null;
  totalCodeCountLimit?: number | null;
  codeGenInterval?: number | null;
  maxCreditStackDays: number;
  maxCreditStackCodeEvents: number;
}

export interface UpdateLocationDto {
  lat?: number | null;
  lng?: number | null;
}

export interface UpdateOrganizationInput {
  orgId: string;
  orgType?: OrgTypes | null;
  orgProfile?: string | null;
  orgName?: string | null;
  orgDescription?: string | null;
  orgAddress?: UpdateAddressDto | null;
  orgContactPerson?: UpdatePersonDto | null;
  contactRole?: string | null;
}

export interface UpdatePersonDto {
  personType?: PersonTypes | null;
  personName?: string | null;
  personDescription?: string | null;
  contact?: UpdateContactDto | null;
  address?: UpdateAddressDto | null;
}

export interface UpdateServicerInput {
  servicerId: string;
  servicerType?: OrgTypes | null;
  servicerName?: string | null;
  servicerDescription?: string | null;
  servicerAddress?: UpdateAddressDto | null;
  servicerContactPerson?: UpdatePersonDto | null;
  contactRole?: string | null;
}

export interface UpdateSupplierInput {
  supplierId: string;
  supplierType?: OrgTypes | null;
  supplierName?: string | null;
  supplierDescription?: string | null;
  supplierAddress?: UpdateAddressDto | null;
  supplierContactPerson?: UpdatePersonDto | null;
  contactRole?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
