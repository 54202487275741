import React, { useContext, useEffect, useState } from 'react';
// @material-ui/core components

// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';

import OvsTable from '@components/ReactTable/OvsTable';
import { Column } from 'react-table';

import CardWrapper from '@components/Card/CardWrapper';
import { ClientServicePermissionContext as Context } from '../context/ClientServicePermissionsProvider';
import { tableHeaderGen } from '@components/ReactTable/tableHeaderGen';
import TablePreview from '@components/Loaders/TablePreview';
import { itemTableData } from '../constants/ItemTableData';
import { itemRoutes } from '../constants';

interface IRTable {
	data?: any
	isLoading?: boolean
}

const ItemPage: React.FC<IRTable> = ({ data, isLoading }) => {
	const { items, tableActions, getItems, loading, goTo, paging, setPageLimit } = useContext(
		Context
	);

	const [headers, setHeaders] = useState<Column[]>([]);

	useEffect(() => {
		if (!data?.length) {
			getItems();
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (items?.length > 0 && !data?.length) {
			setHeaders([...itemTableData, ...tableHeaderGen(items[0])]);
		}  // eslint-disable-next-line
	}, [items]);

	useEffect(() => {
		if (data?.length) {
			setHeaders([...tableHeaderGen(data[0])]);
		}
		// eslint-disable-next-line
	}, [data])
	if (headers.length === 0 || isLoading || loading) {
		return <TablePreview items={items} loading={loading} addItemPageLink={itemRoutes.add} />;
	}

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<CardWrapper>
					<OvsTable
						columns={headers as Column[]}
						data={data || items || []}
						checkedIds={[]}
						isLoading={loading || isLoading}
						addItemPageLink={itemRoutes.add}
						tableActions={tableActions}
						goTo={data ? undefined : goTo}
						paging={data ? undefined : paging}
						hideRightToolbar={data ? true : false}
						setPageLimit={setPageLimit}
					/>
				</CardWrapper>
			</GridItem>
		</GridContainer>
	);
};

export default ItemPage;
