export const encoderFields = {
  token: 'token',
  tokenType: "token_type",
  maxCount: "max_count",
  startingCode: "starting_code",
  secretKey: "secret_key",
  tokenValue: "token_value"
}

export const decoderFields = {
  token: 'token',
  tokenType: "token_type",
  maxCount: "max_count",
  startingCode: "starting_code",
  secretKey: "secret_key",
  tokenValue: "token_value",
  usedCount: "used_count",
  remainingDays: "remaining_days"
}
const url = 'opentoken-simulator'
export const itemsRoutes = {
  list: `/admin/${url}`,
  simulator: `/admin/${url}/simulator/:itemId`,
  codeEvents: `/admin/${url}/code-event/:itemId`,
  getSimulatorLink: (itemId: string) => `/admin/${url}/simulator/${itemId}`,
  getCodeEventsLink: (itemId: string, codeType: string) => `/admin/${url}/code-event/${itemId}?type=${codeType}`,
};


