import React from 'react';
import CustomChip from '@components/CustomChip/CustomChip';
import { Check, Warning, Sync, RadioButtonUnchecked } from '@material-ui/icons';

interface IProps {
  value: string;
  header?: string;
  accountStatus? : string
}

const StatusCell: React.FC<IProps> = ({ value, header, accountStatus}) => {

  const getColor = (value: string, accountStatus?: string) => {
    if (accountStatus === 'ACTIVE') {
        return 'success';
      } else if (accountStatus === 'INACTIVE') {
        return 'danger';
      }
    switch (value) {
      case 'COMPLETE':
        return 'success';
      case 'ONGOING':
        return 'warning';
      case 'FAILED':
        return 'danger';
      default:
        return 'default';
    }
  };

  const getIcon = (value: string, header?:string) => {
    if (header === 'AccountNumber' || header === 'SellerItemId') {
        return <RadioButtonUnchecked />;
    }
    switch (value) {
      case 'COMPLETE':
        return <Check />;
      case 'ONGOING':
        return <Sync />;
      case 'FAILED':
        return <Warning />;
      case 'ACTIVE':
        return <Check/>;
      case 'INACTIVE' :
        return <Warning/>;
          
      default:
        return <Check />;
    }
  };

  return (
    <CustomChip
      label={value}
      color={getColor(value, accountStatus)}
      icon={getIcon(value, header)}
      background= {header === 'AccountNumber' || header === 'SellerItemId'}
      accountNumber = {header === 'AccountNumber' || header === 'SellerItemId' }
    />
  );
};

export default StatusCell;
