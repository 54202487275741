import { Grid } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import _ from 'lodash';
// @material-ui/icons
// core components
import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useContext, useEffect } from 'react';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import { itemFields } from '../constants';

import { IProductFamily as ItemType } from '../types';
import OVSForm from '@components/Form/OVSForm';
import { LegacyCategoryContext } from '../../LegacyCategory/context/LegacyCategoryContext';

const LegacyForm: React.FC<{
  formBag: FormikProps<ItemType>;
  disableLoginButton: boolean;
  actionButtonName?: string;
}> = (props) => {
  const { formBag, disableLoginButton, actionButtonName = 'Add Items' } = props;
  const { getItems, items } = useContext(LegacyCategoryContext);

  useEffect(() => {
    if (items.length === 0) {
      getItems();
    } // eslint-disable-next-line
  }, []);

  const formFieldsData = React.useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            type: 'text',
            name: itemFields.productFamilyName,
            label: 'Product Family Name',
          },
          {
            type: 'textarea',
            name: itemFields.productFamilyDescription,
            label: 'Description',
          },
          {
            type: 'select',
            name: itemFields.productCategoryID,
            label: 'Product Category',
            options: items.map((it) => ({
              _id: it.productCategoryID,
              name: it.productCategoryName,
            })),
            onChange: (e: any) => {
              formBag.setFieldValue(
                itemFields.productCategoryID,
                e.target.value
              );
            },
            value: _.get(formBag.values, itemFields.productCategoryID) || '',
          },
          {
            type: 'text',
            name: itemFields.image,
            label: 'Image URL',
          },
        ],
      },
    ], // eslint-disable-next-line
    [formBag.values, items]
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer>
                <OVSForm formFieldsData={formFieldsData} />
              </GridContainer>
            </GridItem>
          </GridContainer>

          <Grid>
            <Button
              color='primary'
              onClick={() => {
                formBag.submitForm();
                formBag.validateForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </GridItem>
    </GridContainer>
  );
};

export default LegacyForm;
