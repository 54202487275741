import styled from "styled-components";

export const Styles = styled.div`
  padding: 1rem;
  
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      th {
        background: #EAEFF5;
      }
    }    

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
      :first-child {
        width: 10px !important;
        overflow: hidden;
        background: #EAEFF5;
        input {
          width: 30px;
          pointer-events: none;
        }
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
        background: transparent;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
}
` 